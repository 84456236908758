import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';

import authReducer from 'modules/Auth/slice';
import profileReducer from 'modules/Profile/slice';
import customersReducer from 'modules/DashBoard/slice';
import hcpUsersReducer from 'modules/HcpUsers/slice';
import patientsReducer from 'modules/Patients/slice';
import machinesReducer from 'modules/Machines/slice';
import dialogsReducer from 'modules/Dialogs/slice';
import uiReducer from 'modules/Ui/slice';
import permsReducer from 'modules/Permissions/slice';
import offlineReducer from 'modules/Offline/slice';
import syncReducer from 'modules/DataSync/slice';
import emrSlice from 'modules/EMR/slice';
import notiSlice from 'modules/Notifications/slice';
import auditLogsSlice from 'modules/AuditLogs/slice'

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    dialogs: dialogsReducer,
    ui: uiReducer,
    customers: customersReducer,
    hcpUsers: hcpUsersReducer,
    machines: machinesReducer,
    patients: patientsReducer,
    permissions: permsReducer,
    offline: offlineReducer,
    sync: syncReducer,
    emrSettings: emrSlice,
    notifications: notiSlice,
    auditLogs: auditLogsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  devTools: import.meta.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export default store;
