import { BasicTable } from 'components/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchHcpMachines } from 'modules/Machines/slice';
import { fetchPatientSetups, setPrevUrl } from '../../../slice';
import PropTypes from 'prop-types';
import { dateParserWithTimezone } from 'helpers/datetime.js';
import { format } from 'date-fns';

const COLUMNS = [
  {
    title: 'Name',
    dataKey: 'name',
    key: 'column-1',
    width: 0.4,
  },
  {
    title: 'Machines',
    dataKey: 'machine',
    key: 'column-2',
    width: 0.2,
  },
  {
    title: 'Serial Number',
    dataKey: 'serialNumber',
    key: 'column-3',
    width: 0.2,
  },
  {
    title: 'Date & Time',
    dataKey: 'date',
    key: 'column-4',
    width: 0.2,
  },
];

export default function TableSetup({ emptySmg, sessionId, columns = COLUMNS, type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const patientId = id;
  const hcpId = localStorage.getItem('hcpId');

  const patientSetups = useSelector((state) => state.patients.setups);
  const isPatientSetups = useSelector((state) => state.patients.isSetupsFetching);
  const machines = useSelector((state) => state.machines.hcpMachines);

  const [dataSrc, setDataSrc] = useState([]);
  useEffect(() => {
    if (!hcpId) {
      return null;
    }
    dispatch(fetchHcpMachines({ hcpId }));
  }, []);

  useEffect(() => {
    if (!patientSetups) {
      dispatch(fetchPatientSetups({ hcpId, patientId: atob(patientId) }));
    }
  }, [patientSetups]);

  useEffect(() => {
    if (!patientSetups || !machines || (machines && machines.length === 0)) {
      return null;
    }

    const data = [];

    patientSetups.map((patientSetup) => {
      if (!patientSetup.machine_id) {
        return null;
      }

      const localDate = dateParserWithTimezone(patientSetup.initiated_at);
      data.push({
        id: patientSetup.id,
        machineId: patientSetup.machine_id,
        name: patientSetup?.setup_name,
        machine: patientSetup.machine_name,
        type: patientSetup.machine_type,
        serialNumber: patientSetup.u12_id,
        originalMachineData: {
          name: patientSetup.machine_name,
          serial_no: patientSetup.u12_id,
        },
        date: localDate && format(localDate, 'h:mm aa LLL d yyyy'),
        disable: !!patientSetup.machine_deleted_at,
      });
    });
    data.sort((a, b) => new Date(b.date) - new Date(a.date));
    setDataSrc(data);
  }, [patientSetups, machines]);

  if ((dataSrc.length === 0 && !isPatientSetups) || !machines) {
    return <>{emptySmg || ''}</>;
  }

  const onRowClickHandler = (rowData) => {
    dispatch(setPrevUrl(location.pathname + location.search));
    navigate(
      `/patient/${patientId}/machine-setup/summary?setupId=${rowData.id}&isBack=true&mode=2&sessionId=${sessionId}&type=${type}&machineId=${rowData.machineId}&isBack=false&machineType=${rowData.type}&isCloseDuplicate=false&tab=1`
    );
  };

  return <BasicTable onRowClick={onRowClickHandler} headers={columns} data={dataSrc} />;
}

TableSetup.propTypes = {
  emptySmg: PropTypes.string,
  columns: PropTypes.array,
  sessionId: PropTypes.string,
  type: PropTypes.string,
};
