import ENV_VARIABLES from 'config/variables';

export const DETECTED_MACHINE_STATUS = {
  FAILED: -1,
  READY: 0,
  CONFIGURING: 1,
  COMPLETED: 2,
};

export const HCP_USER_STATUS = {
  ACTIVE: 1,
  PENDING: 0
};

export const LIST_MODE_AUDIT_LOG = [
  {
    id: 1,
    name: 'Online',
    value: 'online'
  },
  {
    id: 2,
    name: 'Offline',
    value: 'offline'
  },
]

export const LIST_MODULE_AUDIT_LOG = [
  {
    id: 1,
    name: 'Authentication',
    value: 'authentication'
  },
  {
    id: 2,
    name: 'Patient management',
    value: 'patient_management'
  },
  {
    id: 3,
    name: 'User management',
    value: 'user_management'
  },
  {
    id: 4,
    name: 'Roles and permissions',
    value: 'roles_and_permissions'
  },
  {
    id: 5,
    name: 'Machine management',
    value: 'machine_management'
  },
  {
    id: 6,
    name: 'Sync data',
    value: 'sync_data'
  },
  {
    id: 7,
    name: 'Download data',
    value: 'download_data'
  },
  {
    id: 8,
    name: 'EMR Integration',
    value: 'emr_integration'
  },
  {
    id: 9,
    name: 'Account settings',
    value: 'account_settings',
  }
];

export const LIST_ACTION_AUDIT_LOG = [
  {
    id: 1,
    name: 'Successfully log in',
  },
  {
    id: 2,
    name: 'Failed to log in',
  },
  {
    id: 3,
    name: 'Account has been locked',
  },
  {
    id: 4,
    name: 'Session timeout',
  },
  {
    id: 5,
    name: 'Log out',
  },
  {
    id: 7,
    name: 'Add patient',
  },
  {
    id: 8,
    name: 'Edit patient information',
  },
  {
    id: 9,
    name: 'Create new patient session',
  },
  {
    id: 10,
    name: 'End patient session',
  },
  {
    id: 11,
    name: 'Start activity',
  },
  {
    id: 12,
    name: 'End activity',
  },
  {
    id: 13,
    name: 'Edit Patient Machine Setup',
  },
  {
    id: 15,
    name: 'Edit Health Care Information',
  },
  {
    id: 16,
    name: 'Edit Demographic Information',
  },
  {
    id: 17,
    name: 'Add new user',
  },
  {
    id: 18,
    name: 'User accepted invite',
  },
  {
    id: 19,
    name: 'Edit user',
  },
  {
    id: 20,
    name: 'Archive suspended user',
  },
  {
    id: 21,
    name: 'Suspend user',
  },
  {
    id: 22,
    name: 'Restore user',
  },
  {
    id: 23,
    name: 'Edit customer information',
  },
  {
    id: 24,
    name: 'Add new role',
  },
  {
    id: 25,
    name: 'Edit permission',
  },
  {
    id: 26,
    name: 'Remove role',
  },
  {
    id: 27,
    name: 'Machine calibration - Potentionmeter',
  },
  {
    id: 28,
    name: 'Machine calibration - Strain Gauge',
  },
  {
    id: 29,
    name: 'Machine calibration - Machine Debug',
  },
  {
    id: 30,
    name: 'Machine calibration - Machine Specification Setting',
  },
  {
    id: 31,
    name: 'Edit machine',
  },
  {
    id: 32,
    name: 'Update software',
  },
  {
    id: 33,
    name: 'Retry update',
  },
  {
    id: 34,
    name: 'Access local address',
  },
  {
    id: 35,
    name: 'Sync data',
  },
  {
    id: 36,
    name: 'Download data',
  },
  {
    id: 37,
    name: 'Add EMR Integration Settings',
  },
  {
    id: 38,
    name: 'Edit EMR Integration Settings',
  },
  {
    id: 39,
    name: 'Edit profile',
  },
  {
    id: 40,
    name: 'Change password',
  }
];

export const END_POINT = {
  getExamples: {
    url: (exampleId) => `${ENV_VARIABLES.API_SERVER}examples?example_id=${exampleId}`,
  },
  requestExportAuditLog: {
    url: `${ENV_VARIABLES.API_SERVER}logging/export-excel`,
    method: 'GET'
  }
};
