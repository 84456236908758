import { useSelector, useDispatch } from 'react-redux';
import SearchPatientDialog from 'modules/Patients/components/Dialogs/SearchPatientDialog';

import { closeDialog } from 'helpers/dialog';
import { DIALOGS } from 'modules/Dialogs/constants';
import { searchPatient } from 'modules/Patients/slice';
import { format } from 'date-fns';

export default function SearchPatient() {
  const dispatch = useDispatch();
  const searchResults = useSelector((state) => state.patients.searchResults);
  const openSearchPatientDialog = useSelector((state) => state.dialogs[DIALOGS.SEARCH_PATIENT_DIALOG]);
  const patientError = useSelector((state) => state.patients.error);

  const handleCloseSearchPatientDialog = () => {
    closeDialog(dispatch, DIALOGS.SEARCH_PATIENT_DIALOG);
  };

  const getDate = (dateTime) => {
    return format(dateTime, 'yyyy-MM-dd');
    // const month = dateTime.getMonth() + 1;
    // const day = dateTime.getDate();
    // const year = dateTime.getFullYear();
    // return `${year}-${month}-${day}`;
  };

  const onSubmitSearchPatientDialog = (data) => {
    const patient = {
      hcpId: localStorage.getItem('hcpId'),
      patientId: data.patientId,
      fname: data.firstName.trim().replace(/\s+/g, ' '),
      lname: data.lastName.trim().replace(/\s+/g, ' '),
      dob: data.dob ? getDate(data.dob) : '',
      gender: data.gender,
      address: data.address.trim().replace(/\s+/g, ' '),
      city: data.city && data.city.id ? data.city.name : '',
      state: data.state && data.state.iso2 ? data.state.name : '',
      country: data.country && data.country.name,
      email: data.email.toLowerCase(),
      zip_code: data.zipCode.trim().replace(/\s+/g, ' '),
      mobile_no: data.mobile.trim(),
      home_no: data.homePhone,
    };
    dispatch(searchPatient(patient));
  };

  return (
    <SearchPatientDialog
      searchResults={searchResults}
      onSubmitSearchPatientDialog={onSubmitSearchPatientDialog}
      error={patientError}
      openSearchPatientDialog={openSearchPatientDialog}
      handleCloseSearchPatientDialog={handleCloseSearchPatientDialog}
    />
  );
}
