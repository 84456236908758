import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';

import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SessionInfo } from 'modules/Patients/components/Info';
import SideBar from 'modules/Patients/components/Machines/TestSideBar';
import AlertDialog from 'components/AlertDialog/index.jsx';
import { useDispatch, useSelector } from 'react-redux';
import PatientAdjustments from './Steps/DynamicWarmUp/PatientAdjustments';
import PreSetAngles from './Steps/PracticeTest/PreSetAngles';
import ActualPreSetAngles from './Steps/ActualTest/ActualPreSetAngles';
import { clearActivities, fetchMachineSetup, putActivityNote } from '../../../../slice';
import AngleTest from './Steps/PracticeTest/AngleTest';
import ActualAngleTest from './Steps/ActualTest/AngleTest';

import IsometricPracticeSummary from './Steps/PracticeTest/IsometricPracticeSummary';

import DynamicWarmUpInstructions from './Steps/DynamicWarmUp/Instructions';
import SetAngle from './Steps/DynamicWarmUp/SetAngle';
import Exercise from './Steps/DynamicWarmUp/Exercise';
import TestingInstructions from './Steps/TestingInstructions';
import Questions from './Steps/Questions';
import Preparation from './Steps/SetupReview/Preparation';
import Instruction from './Steps/SetupReview/Instruction';

import TightenRestraints from './Steps/SetupReview/TightenRestraints';
import { PracticeTestInstruction } from './Steps/PracticeTest/PracticeTestInstruction';
import ActualTestInstruction from './Steps/ActualTest/Instructions';
import { actualTestLeft, actualTestRight, practiceTestDefault, warmUpDefaultData } from './defaultData';
import OneSideSummary from './Steps/ActualTest/OneSideSummary';
import { MotionLimiter } from '../DynamicTest/Steps/SetupReview/MotionLimiter';
import Seating from '../DynamicTest/Steps/SetupReview/Seating';
import HeadAlignment from '../DynamicTest/Steps/SetupReview/HeadAlignment';
import ActivityQuestion from 'modules/Patients/containers/Machines/ActivityQuestion/index.jsx';
import { openDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export default function Index({
  patient,
  machine,
  handleNext,
  handleBack,
  handleSelectItem,
  handleSkipItem,
  step,
  subStep,
  setupId,
  navigationItems,
  defaultAnglesLeft,
  defaultAnglesRight,
  setDefaultAnglesLeft,
  setDefaultAnglesRight,
  handleRemoveSkip,
  testStack,
  isFirstStepSkipped,
  isSecondStepSkipped,
  socketContext,
  firstSide,
  setFirstSide,
  testMode,
  setTestMode,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const setupMachineData = useSelector((state) => state.patients.setup);
  const machineId = Number(searchParams.get('machineId'));
  const sessionId = searchParams.get('sessionId');
  const machineType = searchParams.get('machineType');
  const arrMachinesConfigured = useSelector((state) => state.machines.hcpConfiguredMachines);

  const [openAlert, setOpenAlert] = useState(false);
  const [stepData, setStepData] = useState([]);
  const [wuLeftData, setWuLeftData] = useState(warmUpDefaultData);
  const [wuRightData, setWuRightData] = useState(warmUpDefaultData);
  const [preSetAnglePractice, setPreSetAnglePractice] = useState(practiceTestDefault);
  const [actualTestForLeft, setActualTestForLeft] = useState(actualTestLeft);
  const [limiterLeft, setLimiterLeft] = useState(null);
  const [limiterRight, setLimiterRight] = useState(null);
  const [disablePracticePreSetAngle, setDisablePracticePreSetAngle] = useState(false);
  const [disableActualPreSetAngleLeft, setDisableActualPreSetAngleLeft] = useState(false);
  const [disableActualPreSetAngleRight, setDisableActualPreSetAngleRight] = useState(false);

  const [actualTestForRight, setActualTestForRight] = useState(actualTestRight);

  const patientId = id;

  useEffect(() => {
    const data = {
      hcpId: localStorage.getItem('hcpId'),
      patientId: atob(patientId),
      machineId: Number(machineId),
    };
    if (!setupMachineData && setupId) {
      dispatch(fetchMachineSetup({ ...data, setupId: setupId }));
    }
  }, []);

  useEffect(() => {
    if (!isNil(machineId) && !isNil(machineType) && !isEmpty(arrMachinesConfigured)) {
      socketContext.handleFilterMachine({ machineId, machineType, machines: arrMachinesConfigured });
    }
  }, [machineId, machineType, arrMachinesConfigured]);



  useEffect(() => {
    return () => {
      socketContext.handleResetMachine();
    }
  }, []);


  const handleCancel = () => {
    setOpenAlert(true);
  };

  const handleSubmitSecondaryAction = () => {
    const hcpId = localStorage.getItem('hcpId');
    const activityId = localStorage.getItem('activity_id');
    localStorage.removeItem('activity_id');
    dispatch(
      putActivityNote({
        hcpId,
        patientId: atob(id),
        sessionId: sessionId,
        activitiesId: activityId,
        data: { test_duration: 0 },
      })
    );
    navigate(`/patient/${btoa(patient.patient_id)}&tab=1`);
  };

  const handleSubmitAlertDialog = () => {
    setOpenAlert(false);
  };

  const onDataChange = (data) => {
    if (!data) {
      return null;
    }

    //temporarily save data of steps into the state as an array
    const newValue = stepData.filter((s) => s.step !== data.step && s.subStep !== data.subStep);
    newValue.push(data);

    setStepData(newValue);
  };

  const isSummary = (step, subStep) => `${step}-${subStep}` === '5-11';

  const printRef = useRef();

  const finishQuestionHandler = (question) => {
    const activityId = localStorage.getItem('activity_id');
    dispatch(
      putActivityNote({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(id),
        sessionId,
        activitiesId: activityId,
        data: question,
      })
    );
    localStorage.removeItem('activity_id');
    navigate(
      `/patient/${id}/isometric-test/summary?setupId=${setupId}&mode=1&sessionId=${sessionId}&machineType=${machineType}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=false`
    );
  };

  function getStep() {
    let nextStep = `${step}`;

    if (subStep !== '0') {
      nextStep = `${step}-${subStep}`;
    }

    const props = {
      step,
      subStep,
      handleCancel,
      handleBack,
      handleNext,
      machine,
      onDataChange,
      testStack,
      setupMachineData: setupMachineData ? setupMachineData.data ?? {} : {},
      socketContext,
      firstSide,
      setFirstSide,
      testMode,
      setTestMode,
      setWuLeftData,
      setWuRightData,
      preSetAnglePractice,
      setPreSetAnglePractice,
      disablePracticePreSetAngle,
      setDisablePracticePreSetAngle,
      sessionId: setupMachineData ? setupMachineData.session_id : '',
    };

    switch (nextStep) {
      case '1':
        return <Questions {...props} />;
      case '2-1':
        return <Preparation {...props} />;
      case '2-2':
        return <MotionLimiter {...{ ...props, limiter: limiterLeft, setLimiter: setLimiterLeft }} />;
      case '2-3':
        return <MotionLimiter {...{ ...props, limiter: limiterRight, setLimiter: setLimiterRight }} />;
      case '2-4':
        return <Instruction {...props} />;
      case '2-5':
        return <Seating {...props} />;
      case '2-6':
        return <HeadAlignment {...props} />;
      case '2-7':
        return <TightenRestraints {...props} handleRemoveSkip={handleRemoveSkip} />;
      case '3-1':
        return <DynamicWarmUpInstructions {...{ ...props, disableBack: isFirstStepSkipped }} />;
      case '3-2':
        return <PatientAdjustments {...{ ...props, defaultValue: wuLeftData, setData: setWuLeftData }} />;
      case '3-3':
        return <SetAngle {...{ ...props, defaultValue: wuLeftData, setData: setWuLeftData }} />;
      case '3-4':
        return (
          <Exercise
            {...{ ...props, defaultValue: wuLeftData, setData: setWuLeftData }}
            handleRemoveSkip={handleRemoveSkip}
          />
        );
      case '3-5':
        return <PatientAdjustments {...{ ...props, defaultValue: wuRightData, setData: setWuRightData }} />;
      case '3-6':
        return <SetAngle {...{ ...props, defaultValue: wuRightData, setData: setWuRightData }} />;
      case '3-7':
        return (
          <Exercise
            {...{ ...props, defaultValue: wuRightData, setData: setWuRightData }}
            handleRemoveSkip={handleRemoveSkip}
          />
        );
      case '4':
        return <TestingInstructions {...props} disableBack={isFirstStepSkipped && isSecondStepSkipped} />;
      case '5-1':
        return <PreSetAngles {...{ ...props, machineSetup: setupMachineData }} />;
      case '5-2':
        return <PracticeTestInstruction {...props} />;
      case '5-3':
        return <AngleTest key={`practice-test-${step}-${subStep}`} {...props} />;
      case '5-4':
        return <AngleTest key={`practice-test-${step}-${subStep}`} {...props} />;
      case '5-5':
        return <AngleTest key={`practice-test-${step}-${subStep}`} {...props} />;
      case '5-6':
        return <IsometricPracticeSummary {...props} handleRemoveSkip={handleRemoveSkip} />;

      case '6-1':
        return (
          <ActualPreSetAngles
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              setDefaultAngles: firstSide === 1 ? setDefaultAnglesLeft : setDefaultAnglesRight,
              setDisableActualPreSetAngle:
                firstSide === 1 ? setDisableActualPreSetAngleLeft : setDisableActualPreSetAngleRight,
              disableActualPreSetAngle: firstSide === 1 ? disableActualPreSetAngleLeft : disableActualPreSetAngleRight,
            }}
          />
        );
      case '6-2':
        return <ActualTestInstruction {...props} />;
      case '6-3':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 1 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '6-4':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 1 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '6-5':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 1 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '6-6':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 1 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '6-7':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 1 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '6-8':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 1 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '6-9':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 1 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '6-10':
        return (
          <OneSideSummary
            {...{
              ...props,
              defaultAngles: firstSide === 1 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 1 ? actualTestForLeft : actualTestForRight,
              wuData: firstSide === 2 ? wuLeftData : wuRightData,
              practiceTest: preSetAnglePractice,
            }}
          />
        );
      case '7':
        openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
        break;

      case '7-1':
        return (
          <ActualPreSetAngles
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              setDefaultAngles: firstSide === 2 ? setDefaultAnglesLeft : setDefaultAnglesRight,
              setDisableActualPreSetAngle:
                firstSide === 2 ? setDisableActualPreSetAngleLeft : setDisableActualPreSetAngleRight,
              disableActualPreSetAngle: firstSide === 2 ? disableActualPreSetAngleLeft : disableActualPreSetAngleRight,
            }}
          />
        );
      case '7-2':
        return <ActualTestInstruction {...props} />;
      case '7-3':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 2 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 2 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '7-4':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 2 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 2 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '7-5':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 2 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 2 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '7-6':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 2 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 2 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '7-7':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 2 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 2 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '7-8':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 2 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 2 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '7-9':
        return (
          <ActualAngleTest
            {...{
              ...props,
              defaultAngles: firstSide === 2 ? defaultAnglesLeft : defaultAnglesRight,
              defaultData: firstSide === 2 ? actualTestForLeft : actualTestForRight,
              setDefaultData: firstSide === 2 ? setActualTestForLeft : setActualTestForRight,
            }}
          />
        );
      case '8':
        openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
        break;

      default:
        return <></>;
    }
  }

  return (
    <Box ref={printRef}>
      <SessionInfo
        machine={machine}
        patient={patient}
        title={!isSummary(step, subStep) ? 'Isometric Test' : 'Isometric Test Index'}
      />
      <Box
        sx={{
          display: 'flex',
          pt: 5,
        }}
      >
        {!isSummary(step, subStep) && (
          <SideBar
            navigationItems={navigationItems}
            step={step}
            subStep={subStep}
            handleSelectItem={handleSelectItem}
            handleSkipItem={handleSkipItem}
          />
        )}
        {/* main content */}
        <Box sx={!isSummary(step, subStep) ? { width: '60%', ml: 4 } : { width: '100%' }}>
          <Box>{getStep()}</Box>
        </Box>
        <AlertDialog
          secondaryActionText='End'
          primaryActionText='Return'
          handleSubmitAlertDialog={handleSubmitAlertDialog}
          handleSubmitSecondaryAction={handleSubmitSecondaryAction}
          openAlertDialog={openAlert}
          message={
            <Box sx={{ maxWidth: '315px' }}>
              Are you sure you want to cancel this Isometric Test? All data will be deleted.
            </Box>
          }
        />
        <ActivityQuestion onFinish={finishQuestionHandler} />
      </Box>
    </Box>
  );
}

Index.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSelectItem: PropTypes.func,
  handleSkipItem: PropTypes.func,
  handleRemoveAngle: PropTypes.func,
  removedAngles: PropTypes.array,
  setRemovedAngles: PropTypes.func,
  handleUndoAngle: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  setIsModalSaved: PropTypes.func,
  isModalSaved: PropTypes.bool,
  navigationItems: PropTypes.array,
  defaultAnglesLeft: PropTypes.array,
  defaultAnglesRight: PropTypes.array,
  step: PropTypes.string,
  subStep: PropTypes.string,
  setupId: PropTypes.string || PropTypes.number,
  testStack: PropTypes.array,
  isFirstStepSkipped: PropTypes.bool,
  isSecondStepSkipped: PropTypes.bool,
  socketContext: PropTypes.object,
  firstSide: PropTypes.number,
  setFirstSide: PropTypes.func,
  testMode: PropTypes.number,
  setTestMode: PropTypes.func,
  setDefaultAnglesLeft: PropTypes.func,
  setDefaultAnglesRight: PropTypes.func,
};
