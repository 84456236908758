import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Actions from 'modules/Patients/components/Machines/Actions';

const Instructions = ({ step, subStep, handleCancel, handleBack, handleNext, disableBack }) => {
  return (
    <>
      <Typography variant='body1' component='div'>
        1. If patient is already warmed up, then skip to <b style={{ color: '#ED9A00' }}>step 4</b>.
        <br />
        2. Provide the following dynamic warm-up instructions:
        <div style={{ padding: '0 20px' }}>
          • Exercise through the full range of motion.
          <br />
          • Exercise is both positive and negative.
          <br />
          • Perform each repetition slowly (2-1-4) second count.
          <br />
          • Visual feedback is provided on the monitor.
          <br />
          • You will get an audible warning when you have completed the full range.
          <br />
          • Demonstrate how to rotate and push against the resistance pad.
          <br />
          • Avoid holding your breath, exhale when building force.
          <br />
          • Maintain hands resting in your lap.
          <br />
          • Maintain contact with the seatback.
          <br />
          • Exert force only when clinician instructs to do so.
          <br />• Turn your head in the opposite direction of the applied force and position your head in a neutral
          forward position during essentric movement.
        </div>
      </Typography>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handleBack={handleBack}
        disableBack={disableBack}
      />
    </>
  );
};

Instructions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disableBack: PropTypes.bool,
};

export default Instructions;
