import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export default function Reps({ value, title }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{title}</div>

      <div
        style={{
          borderRadius: '100px',
          background: '#F7F7F7',
          width: '170px',
          height: '170px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #D4D4D4',
        }}
      >
        <span style={{ fontSize: '72px', fontWeight: 'bold' }}> {value}</span>
      </div>
    </Box>
  );
}

Reps.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
};
