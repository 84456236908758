import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

export default function SkeletonPatientActivities() {
    return (
        <Grid container justifyContent='flex-start' spacing={3}>
            {[0, 1, 2, 3, 4, 5].map((item, index) => (
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2} key={index}>
                    <Skeleton variant='rectangular' height={50} />
                </Grid>
            ))}
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item, index) => (
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2} key={index}>
                    <Skeleton variant='rectangular' height={80} />
                </Grid>
            ))}
        </Grid>
    );
}

// xs={6} sm={6} md={2} lg={3} xl={2}