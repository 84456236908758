import { useSearchParams } from 'react-router-dom';
import LumbarIsometricTest from 'modules/Patients/containers/Machines/Lumbar/IsometricTest';
import CervicalIsometricTest from 'modules/Patients/containers/Machines/Cervical/IsometricTest';
import { useEffect } from 'react';
import { clearActivities } from '../../slice';
import { useDispatch } from 'react-redux';
import TorsoIsometrictTest from './Torso/IsometrictTest';
import CervicalRotationIsometricTest from './CervicalRotation/IsometrictTest';
const Isometric = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const machineType = searchParams.get('machineType');

  useEffect(() => {
    return () => {
      localStorage.removeItem('activity_id');
      dispatch(clearActivities());
    };
  }, []);

  switch (machineType) {
    case 'Lumbar':
      return <LumbarIsometricTest />;
    case 'Cervical':
      return <CervicalIsometricTest />;
    case 'Knee':
      return 'developing';
    case 'Torso Rotation':
      return <TorsoIsometrictTest />;
    case 'Cervical Rotation':
      return <CervicalRotationIsometricTest />;
    default:
      return null;
  }
};

export default Isometric;
