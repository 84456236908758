import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';
import * as _ from 'lodash';
import ENV_VARIABLES from '../../config/variables';
export const PatientsSlice = createSlice({
  name: 'patientsSlice',
  initialState: {
    error: null,
    sessions: [],
    session: null,
    patient: null,
    setup: null,
    isInitSetups: false,

    setups: null,
    isSetupsFetching: false,
    isFetchPatientSession: false,

    createdSession: null,
    createdPatient: null,
    searchResults: null,
    patientsAngle: {
      maxExtension: '',
      maxFlexion: '',
      topDeathCenter: '',
      counterBalance: '',
    },
    normData: null,
    isUpdatingSetup: false,

    activities: null,
    isUpdatingActivity: false,
    putActivityError: null,
    prevUrl: '',
    isFetchAllIsoActivities: false,
    arrAllIsoActivities: [],
    activeTested: true,
    activeNet: false,

    socketUrl: ENV_VARIABLES.WS_SERVER,
  },
  reducers: {
    requestAddPatient: (state) => {
      state.error = null;
    },
    requestAddPatientSuccess: (state, action) => {
      state.createdPatient = action.payload.data;
    },
    requestAddPatientFailure: (state, action) => {
      state.error = action.payload;
    },
    requestEditPatient: (state) => {
      state.error = null;
    },
    requestEditPatientSuccess: (state, action) => {
      state.createdPatient = action.payload;
    },
    requestEditPatientFailure: (state, action) => {
      state.error = action.payload;
    },
    fetchPatient: (state) => {
      state.patient = null;
      state.error = null;
    },
    fetchPatientSuccess: (state, action) => {
      state.patient = action.payload.data;
    },
    fetchPatientFailure: (state, action) => {
      state.patient = null;
      state.error = action.payload.code;
    },
    archivePatient: (state) => {
      state.error = null;
    },
    archivePatientSuccess: () => { },
    archivePatientFailure: (state, action) => {
      state.patient = null;
      state.error = action.payload.code;
    },
    searchPatient: (state) => {
      state.searchResults = null;
      state.error = null;
    },
    searchPatientSuccess: (state, action) => {
      state.searchResults = action.payload;
    },
    fetchMachineSetup: (state) => {
      state.setup = null;
      state.error = null;
    },
    fetchMachineSetupSuccess: (state, action) => {
      state.setup = action.payload.message;
    },
    fetchMachineSetupFailure: (state, action) => {
      state.setup = null;
      state.error = action.payload.code;
    },
    setPrevUrl: (state, action) => {
      state.prevUrl = action.payload;
    },

    setSocketUrl: (state, action) => {
      state.socketUrl = action.payload;
    },

    putActivityNote: (state) => {
      state.isUpdatingActivity = true;
      state.putActivityError = null;
    },
    putActivityNoteSuccess: (state, action) => {
      state.activities = action.payload;
      state.isUpdatingActivity = false;
      state.putActivityError = false;
    },
    putActivityNoteFailure: (state, action) => {
      state.isUpdatingActivity = false;
      state.putActivityError = action.payload;
    },

    resetActivityProperties: (state) => {
      state.putActivityError = null;
      state.isUpdatingActivity = false;
    },

    fetchNormData: (state) => {
      state.normData = null;
    },
    fetchNormDataSuccess: (state, action) => {
      state.normData = action.payload;
    },
    fetchNormDataFailure: (state) => {
      state.normData = null;
    },

    fetchPatientSetups: (state) => {
      state.setups = null;
      state.isSetupsFetching = true;
    },
    fetchPatientSetupSuccess: (state, action) => {
      const data = _.sortBy(action.payload.data, (r) => r.id);
      state.setups = data.reverse();
      state.isSetupsFetching = false;
    },
    fetchPatientSetupFailure: (state, action) => {
      state.setups = null;
      state.error = action.payload.code;
      state.isSetupsFetching = false;
    },
    clearPatientSetup: (state) => {
      state.setups = null;
      state.isSetupsFetching = false;
    },

    requestInitSetup: (state) => {
      state.error = null;
      state.isInitSetups = true;
    },
    requestInitSetupSuccess: (state, action) => {
      state.setup = action.payload;
      state.isInitSetups = false;
    },
    requestInitSetupFailure: (state, action) => {
      state.error = action.payload;
      state.isInitSetups = false;
    },

    requestInitActivity: (state) => {
      state.error = null;
    },
    requestInitActivitySuccess: (state, action) => {
      state.activities = action.payload;
      state.error = null;
    },
    requestInitActivityFailure: (state, action) => {
      state.error = action.payload;
    },

    requestSaveSetup: (state) => {
      state.error = null;
      state.isUpdatingSetup = true;
    },
    requestSaveSetupSuccess: (state, action) => {
      state.isUpdatingSetup = false;
      state.setup = action.payload.data;
    },
    requestSaveSetupFailure: (state, action) => {
      state.error = action.payload;
      state.isUpdatingSetup = false;
    },
    requestDeleteSetup: (state) => {
      state.error = null;
    },
    requestDeleteSetupSuccess: () => { },
    requestDeleteSetupFailure: (state, action) => {
      state.error = action.payload;
    },
    searchPatientFailure: (state, action) => {
      state.searchResults = null;
      state.error = action.payload.code;
    },
    fetchPatientSessions: (state) => {
      state.error = null;
    },
    fetchPatientSessionsSuccess: (state, action) => {
      state.sessions = action.payload.sessions;
    },
    fetchPatientSessionsFailure: (state, action) => {
      state.sessions = [];
      state.error = action.payload.code;
    },
    requestCreateSession: (state) => {
      state.error = null;
    },
    requestCreateSessionSuccess: (state, action) => {
      state.createdSession = action.payload.data;
    },
    requestCreateSessionFailure: (state, action) => {
      state.error = action.payload;
    },
    fetchPatientSession: (state) => {
      state.error = null;
      state.isFetchPatientSession = true;
    },
    fetchPatientSessionSuccess: (state, action) => {
      state.session = action.payload.data;
      state.isFetchPatientSession = false;
    },
    fetchPatientSessionFailure: (state, action) => {
      state.session = null;
      state.error = action.payload;
      state.isFetchPatientSession = false;
    },
    requestEndSession: (state) => {
      state.error = null;
    },
    requestEndSessionSuccess: () => { },
    requestEndSessionFailure: (state, action) => {
      state.error = action.payload;
    },
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdPatient = null;
    },
    clearMachineSetup: (state) => {
      state.setup = null;
    },
    clearPatientSessions: (state) => {
      state.sessions = [];
    },
    updatePatientAngle: (state, action) => {
      state.patientsAngle = { ...state.patientsAngle, ...action.payload };
    },

    updateDemoGraphicQuestion: (state, action) => { },

    fetchActivities: (state) => {
      state.activities = null;
    },
    fetchActivitiesSuccess: (state, action) => {
      state.activities = action.payload;
    },
    fetchActivitiesFailure: (state) => {
      state.activities = null;
    },
    clearActivities: (state) => {
      state.activities = null;
    },
    clearAllIsoActivities: (state) => {
      state.arrAllIsoActivities = [];
    },
    requestUpdatePrevIsoActivity: (state, action) => {
      state.dataPrevActivity = action.payload
    },
    requestUpdateFirstIsoActivity: (state, action) => {
      state.dataFirstActivity = action.payload
    },
    requestUpdateListDataMachineSetup: (state, action) => {
      state.listDataMachineSetup = action.payload
    },
    requestUpdatePrevRomPain: (state, action) => {
      state.dataPrevRomPain = action.payload
    },
    requestUpdateFirstRomPain: (state, action) => {
      state.dataFirstRomPain = action.payload
    },
    requestUpdateCurrentData: (state, action) => {
      state.currentDataActivity = action.payload
    },
    requestFetchAllIsoActivities: (state, action) => {
      state.error = null;
      state.isFetchAllIsoActivities = true;
    },
    requestFetchAllIsoActivitiesSuccess: (state, action) => {
      state.arrAllIsoActivities = action.payload;
      state.isFetchAllIsoActivities = false;
    },
    requestFetchAllIsoActivitiesFailed: (state, action) => {
      state.error = action.payload;
      state.isFetchAllIsoActivities = false;
    },
    requestFetchPrevIsoActivity: (state, action) => {
      // state.dataFetchPrevActivity = action.payload
      state.error = null;
      state.isFetchPrevActivity = true;
    },
    requestFetchPrevIsoActivitySuccess: (state, action) => {
      // state.dataFetchPrevActivity = action.payload
      state.dataFetchPrevActivity = action.payload;
      state.isFetchPrevActivity = false;
    },
    requestFetchPrevIsoActivityFailed: (state, action) => {
      // state.dataFetchPrevActivity = action.payload
      state.error = action.payload;
      state.isFetchPrevActivity = false;
    },
    requestFetchFirstIsoActivity: (state, action) => {
      // state.dataFetchPrevActivity = action.payload
      state.error = null;
      state.isFetchFirstActivity = true;
    },
    requestFetchFirstIsoActivitySuccess: (state, action) => {
      // state.dataFetchPrevActivity = action.payload
      state.dataFetchFirstActivity = action.payload;
      state.isFetchFirstActivity = true;
    },
    requestFetchFirstIsoActivityFailed: (state, action) => {
      // state.dataFetchPrevActivity = action.payload
      state.error = action.payload;
      state.isFetchFirstActivity = false;
    },
    requestActiveTested: (state, action) => {
      state.activeTested = true;
      state.activeNet = false;
    },
    requestActiveNet: (state, action) => {
      state.activeNet = true;
      state.activeTested = false;
    }
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.error = null;
      state.createdPatient = null;
      // state.patient = null;
      state.searchResults = null;
    },
  },
});
export const {
  clearSuccessAndError,
  clearMachineSetup,
  clearPatientSessions,
  requestAddPatient,
  requestAddPatientSuccess,
  requestAddPatientFailure,
  requestEditPatient,
  requestEditPatientSuccess,
  requestEditPatientFailure,
  fetchPatient,
  fetchPatientSuccess,
  fetchPatientFailure,
  archivePatient,
  archivePatientSuccess,
  archivePatientFailure,
  searchPatient,
  searchPatientSuccess,
  searchPatientFailure,
  fetchMachineSetup,
  fetchMachineSetupSuccess,
  fetchMachineSetupFailure,
  requestInitSetup,
  requestInitSetupSuccess,
  requestInitSetupFailure,
  requestSaveSetup,
  requestSaveSetupSuccess,
  requestSaveSetupFailure,
  requestDeleteSetup,
  requestDeleteSetupSuccess,
  requestDeleteSetupFailure,
  requestCreateSession,
  requestCreateSessionSuccess,
  requestCreateSessionFailure,
  requestEndSession,
  requestEndSessionSuccess,
  requestEndSessionFailure,
  updatePatientAngle,
  fetchPatientSessions,
  fetchPatientSessionsSuccess,
  fetchPatientSessionsFailure,
  fetchPatientSetups,
  fetchPatientSetupSuccess,
  fetchPatientSetupFailure,
  fetchPatientSession,
  fetchPatientSessionSuccess,
  fetchPatientSessionFailure,
  requestInitActivity,
  requestInitActivityFailure,
  requestInitActivitySuccess,
  fetchNormData,
  fetchNormDataSuccess,
  fetchNormDataFailure,
  fetchActivitiesSuccess,
  fetchActivitiesFailure,
  fetchActivities,
  clearActivities,
  clearPatientSetup,
  putActivityNoteSuccess,
  putActivityNoteFailure,
  putActivityNote,
  resetActivityProperties,
  setPrevUrl,
  updateDemoGraphicQuestion,
  requestUpdatePrevIsoActivity,
  requestUpdateFirstIsoActivity,
  requestUpdateCurrentData,
  requestFetchAllIsoActivities,
  requestFetchAllIsoActivitiesSuccess,
  requestFetchAllIsoActivitiesFailed,
  clearAllIsoActivities,
  requestFetchPrevIsoActivity,
  requestFetchPrevIsoActivitySuccess,
  requestFetchPrevIsoActivityFailed,
  requestFetchFirstIsoActivity,
  requestFetchFirstIsoActivitySuccess,
  requestFetchFirstIsoActivityFailed,
  requestUpdatePrevRomPain,
  requestUpdateFirstRomPain,
  requestUpdateListDataMachineSetup,
  requestActiveTested,
  requestActiveNet
} = PatientsSlice.actions;

export default PatientsSlice.reducer;
