import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import calveRestraint from 'assets/images/knee/calve restraint..svg';
import seatBelt from 'assets/images/knee/Seat belt adjustment.svg';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useRef, useState } from 'react';
import { SingleImageStep } from 'helpers';
import { requestSaveSetup } from '../../../../../slice';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function Restraints({ step, handleCancel, handleNext, subStep, handleBack, setupMachineData }) {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const patientId = id;
  const setupIdParams = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const isUpdate = useRef(null);

  const [value, setValue] = useState(1);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    const knee = setupMachineData.knee_test_mode;

    if (knee > 3) {
      setValue(knee === 4 ? 1 : 2);
      return;
    }

    if (knee !== 3) {
      setValue(knee);
    }
  }, [setupMachineData]);

  useEffect(() => {
    if (!isUpdate.current) {
      return null;
    }

    if (isUpdating) {
      return null;
    }

    handleNext();
  }, [isUpdating]);

  const kneeSelectHandler = (v) => {
    setValue(v.target.value);
  };

  const nextHandler = () => {
    const knee = setupMachineData.knee_test_mode;

    if (knee !== 3) {
      handleNext();
      return null;
    }

    const setupId = localStorage.getItem('setupId') || setupIdParams;
    isUpdate.current = true;
    dispatch(
      requestSaveSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(patientId),
        machineId: machineId,
        finished: false,
        setupId,
        data: {
          knee_test_mode: value === 1 ? 4 : 5,
        },
      })
    );
  };

  const selectItemRender = () => {
    const knee = setupMachineData.knee_test_mode;
    if (knee === 3) {
      return (
        <Select size={'small'} style={{ minWidth: '100px' }} value={value} onChange={kneeSelectHandler}>
          <MenuItem value={1}>Left</MenuItem>
          <MenuItem value={2}>Right</MenuItem>
        </Select>
      );
    }

    let v = knee;
    if (knee > 3) {
      v = knee === 4 ? 1 : 2;
    }

    return (
      <Select disabled={true} size={'small'} style={{ minWidth: '100px' }} value={v}>
        <MenuItem value={1}>Left</MenuItem>
        <MenuItem value={2}>Right</MenuItem>
      </Select>
    );
  };

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box variant='body1' sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <span> 1. Which leg would you like to set up first:</span>

            {selectItemRender()}
            <br />
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={calveRestraint} />
            </Box>
            <Box>
              2. Attach calve restraint to involved leg.
              <br />
              3. Calf restraint should be tightened and snugly as possible. Avoid over tightening which might restrict
              blood flow.
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={seatBelt} />
            </Box>
            <Box>
              4. Have patient fasten the seatbelt.
              <br />
              5. With the seatbelt adjustment, tighten the seatbelt to restrict hip and upper body movement. Turn the
              crank clockwise to tighten the seatbelt, counterclockwise to loosen the seatbelt.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={nextHandler}
        nextLoading={isUpdating}
      />
    </Box>
  );
}

Restraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
