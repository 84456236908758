export const oneLegNavigateItems = [
  {
    id: '1',
    name: 'Questions',
  },
  {
    id: '2',
    hasSkip: true,
    name: 'Patient Setup',
    children: [
      {
        id: '2-1',
        name: 'Preparation',
      },
      {
        id: '2-2',
        name: 'Instructions 1',
      },
      {
        id: '2-3',
        name: 'Restraints',
      },
      {
        id: '2-4',
        name: 'Instructions 2',
      },
    ],
  },

  {
    id: '3',
    name: 'Exercise Instructions',
  },
  {
    id: '4',
    name: 'Machine Setup',
  },
  {
    id: '5',
    name: 'Patient Adjustments',
  },
  {
    id: '6',
    name: 'Set Angle Selector',
  },
  {
    id: '7',
    name: 'Dynamic Exercise',
  },
];

const getPhase2Navigate = (phase2leg) => {
  return [
    {
      id: '1',
      hasSkip: true,
      name: `Patient Setup - ${phase2leg}`,
      children: [
        {
          id: '1-1',
          name: 'Equipment Swap',
        },
        {
          id: '1-2',
          name: 'Instructions',
        },
      ],
    },

    {
      id: '2',
      name: 'Exercise Instructions',
    },
    {
      id: '3',
      name: 'Machine Setup',
    },
    {
      id: '4',
      name: 'Patient Adjustments',
    },
    {
      id: '5',
      name: 'Set Angle Selector',
    },
    {
      id: '6',
      name: 'Dynamic Exercise',
    },
  ];
};

export const getDynamicNavigate = ({ kneeTestMode, phase, phase2leg, testMode }) => {
  if (kneeTestMode < 3) {
    return oneLegNavigateItems;
  }

  // testMode === 1 Left testMode===2 right testMode===3 both
  if (testMode === 1 || testMode === 2) {
    return oneLegNavigateItems;
  }

  if (phase === 1) {
    const phase1 = JSON.parse(JSON.stringify(oneLegNavigateItems));
    phase1.push({
      id: '8',
      name: 'Exercise Summary',
    });
    return phase1;
  }

  return getPhase2Navigate(phase2leg);
};
