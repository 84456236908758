import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getRoles: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/roles`,
  },
  createRole: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/roles`,
  },
  editRole: {
    url: (hcpId, roleId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/roles/${roleId}`,
  },
  removeRole: {
    url: (hcpId, roleId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/roles/${roleId}`,
  },
};

export const PERMISSIONS = [
  { id: 'BILLING', name: 'Billing' },
  { id: 'VIEW_CUSTOMER_INFORMATION', name: 'View Customer Information' },
  { id: 'EDIT_CUSTOMER_INFORMATION', name: 'Edit Customer Information' },
  { id: 'ACCOUNT_MANAGEMENT', name: 'Account Management' },
  { id: 'PATIENT_MANAGEMENT', name: 'Patient Management' },
  { id: 'EXECUTE_ACTIVITIES', name: 'Execute Activities' },
  { id: 'VIEW_PATIENT_HISTORY', name: 'View Patient History' },
  { id: 'VIEW_PATIENT_ACTIVITIES', name: 'View Patient Activity Data/Results' },
  { id: 'SEARCH_AND_VIEW_PATIENT', name: 'Search And View Patient Information' },
  { id: 'VIEW_ROLES', name: 'View Roles' },
  { id: 'MANAGE_ROLES', name: 'Manage Roles' },
  { id: 'MANAGE_MACHINES', name: 'Manage Machines' },
  { id: 'MANAGE_AUDIT_LOG', name: 'Manage Audit Log' },
];

export const NEW_ROLE_PERMISSIONS = [
  { id: 'BILLING', name: 'Billing' },
  { id: 'VIEW_CUSTOMER_INFORMATION', name: 'View Customer Information' },
  { id: 'EDIT_CUSTOMER_INFORMATION', name: 'Edit Customer Information', dependents: ['VIEW_CUSTOMER_INFORMATION'] },
  { id: 'ACCOUNT_MANAGEMENT', name: 'Account Management' },
  { id: 'PATIENT_MANAGEMENT', name: 'Patient Management', dependents: ['SEARCH_AND_VIEW_PATIENT'] },
  {
    id: 'EXECUTE_ACTIVITIES',
    name: 'Execute Activities',
    dependents: ['VIEW_PATIENT_HISTORY', 'SEARCH_AND_VIEW_PATIENT', 'VIEW_PATIENT_ACTIVITIES'],
  },
  { id: 'VIEW_PATIENT_HISTORY', name: 'View Patient History', dependents: ['SEARCH_AND_VIEW_PATIENT'] },
  {
    id: 'VIEW_PATIENT_ACTIVITIES',
    name: 'View Patient Activity Data/Results',
    dependents: ['VIEW_PATIENT_HISTORY', 'SEARCH_AND_VIEW_PATIENT'],
  },
  { id: 'SEARCH_AND_VIEW_PATIENT', name: 'Search And View Patient Information' },
  { id: 'MANAGE_MACHINES', name: 'Manage Machines' },
  { id: 'MANAGE_AUDIT_LOG', name: 'Manage Audit Log' },
];
