import * as ProsType from 'prop-types';

import Box from '@mui/material/Box';
import { ContentContainer } from 'modules/DataSync/Modal/styled';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import { uploadFile } from 'modules/DataSync/slice';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

export const SelectFile = ({ setPage, isClose }) => {
  const dispatch = useDispatch();

  const isFetching = useSelector((state) => state.sync.isFetching);
  const error = useSelector((state) => state.sync.error);
  const requestKey = useSelector((state) => state.sync.requestKey);
  const fileInput = useRef(null);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  useEffect(() => {
    if (isFetching === null) {
      return;
    }
    if (isFetching) {
      return;
    }
    if (error) {
      return;
    }
    if (!isUploaded) {
      return;
    }
    if (requestKey === '') {
      return;
    }
    setPage(3);
  }, [isFetching, requestKey]);

  const onFileChangeHandler = (e) => {
    if (!e.target.value || e.target.files.length === 0) {
      return;
    }

    let name = e.target.files[0].name;

    if (name.length > 25) {
      name = `${name.substring(0, 11)}...${name.substring(name.length - 11)}`;
    }
    setFile(e.target.files[0]);
    setFileName(name);
  };

  const onSyncHandler = () => {
    const formData = new FormData();
    formData.append('file', file);
    setIsUploaded(true);
    const hcpId = localStorage.getItem('hcpId');
    dispatch(uploadFile({ hcpId, data: formData }));
  };

  const linkTextRender = () => {
    if (!fileName) {
      return (
        <Box>
          <span
            style={{ cursor: 'pointer', fontWeight: 700 }}
            onClick={() => fileInput.current && fileInput.current.click()}
          >
            Select File
          </span>
          &nbsp;to upload
        </Box>
      );
    }

    return (
      <Box>
        <span
          style={{ cursor: 'pointer', fontWeight: 700 }}
          onClick={() => fileInput.current && fileInput.current.click()}
        >
          Select Other File
        </span>
      </Box>
    );
  };

  return (
    <ContentContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={() => fileInput.current && fileInput.current.click()}>
            <svg width='49' height='60' viewBox='0 0 49 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M21.5 38.475V48C21.5 48.85 21.7875 49.5625 22.3625 50.1375C22.9375 50.7125 23.65 51 24.5 51C25.35 51 26.0625 50.7125 26.6375 50.1375C27.2125 49.5625 27.5 48.85 27.5 48V38.475L30.2 41.175C30.5 41.475 30.8375 41.7 31.2125 41.85C31.5875 42 31.9625 42.0625 32.3375 42.0375C32.7125 42.0125 33.075 41.925 33.425 41.775C33.775 41.625 34.1 41.4 34.4 41.1C34.95 40.5 35.2375 39.8 35.2625 39C35.2875 38.2 35 37.5 34.4 36.9L26.6 29.1C26.3 28.8 25.975 28.5875 25.625 28.4625C25.275 28.3375 24.9 28.275 24.5 28.275C24.1 28.275 23.725 28.3375 23.375 28.4625C23.025 28.5875 22.7 28.8 22.4 29.1L14.6 36.9C14 37.5 13.7125 38.2 13.7375 39C13.7625 39.8 14.075 40.5 14.675 41.1C15.275 41.65 15.975 41.9375 16.775 41.9625C17.575 41.9875 18.275 41.7 18.875 41.1L21.5 38.475ZM6.5 60C4.85 60 3.4375 59.4125 2.2625 58.2375C1.0875 57.0625 0.5 55.65 0.5 54V6C0.5 4.35 1.0875 2.9375 2.2625 1.7625C3.4375 0.5875 4.85 0 6.5 0H28.025C28.825 0 29.5875 0.15 30.3125 0.45C31.0375 0.75 31.675 1.175 32.225 1.725L46.775 16.275C47.325 16.825 47.75 17.4625 48.05 18.1875C48.35 18.9125 48.5 19.675 48.5 20.475V54C48.5 55.65 47.9125 57.0625 46.7375 58.2375C45.5625 59.4125 44.15 60 42.5 60H6.5ZM27.5 18C27.5 18.85 27.7875 19.5625 28.3625 20.1375C28.9375 20.7125 29.65 21 30.5 21H42.5L27.5 6V18Z'
                fill='#CED4DA'
              />
            </svg>
          </Box>

          <Box>
            <input
              onChange={onFileChangeHandler}
              type='file'
              ref={fileInput}
              style={{ display: 'none' }}
              accept={'.gz'}
            />
            {linkTextRender()}
          </Box>
          <Box>{fileName}</Box>
        </Box>

        <Button
          sx={{
            width: '100%',
            color: 'black',
            boxShadow: 'none',
            textTransform: 'none',
          }}
          disabled={!fileName}
          variant={'contained'}
          onClick={() => (!isClose ? onSyncHandler() : setOpenConfirm(true))}
        >
          <div style={{ fontSize: '14px' }}>Start Synchronization </div>
        </Button>
      </Box>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' sx={{ fontWeight: 700, fontSize: '18px' }}>
          {'Warning!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 600, fontSize: '16px', color: '#212529' }} id='alert-dialog-description'>
            The data will be updated. Are you sure to sync new data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='text' sx={{ textTransform: 'none', color: '#212529' }} onClick={() => setOpenConfirm(false)}>
            No, Cancel
          </Button>
          <Button
            variant={'contained'}
            sx={{ textTransform: 'none', color: '#212529', boxShadow: 'none' }}
            onClick={() => onSyncHandler()}
            autoFocus
          >
            Yes, Sync Data
          </Button>
        </DialogActions>
      </Dialog>
    </ContentContainer>
  );
};

SelectFile.propTypes = {
  setPage: ProsType.func,
  isClose: ProsType.bool,
};
