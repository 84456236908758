import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from 'components/Layout/index.jsx';
import { AppRoute } from 'components/AppRoute';
import { fetchUserInfoFailure } from 'modules/Auth/slice';
import { SnackbarProvider } from 'notistack';
import { END_POINT } from 'modules/Auth/constants';
import { useState, useEffect } from 'react';
import WebSocketProvider from 'components/WebSocketProvider/index.jsx';
import AuthDataProvider from 'components/AuthProvider/index.jsx';
import NotAvailablePage from 'components/NotAvailablePage/index.jsx';
import routes from './routes.jsx';
import theme from './binonex-theme';
import store from './store';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import configAxios from './config/axios';
import axios from 'axios';

function App() {
  configAxios(store.dispatch, fetchUserInfoFailure);

  const [subdomainExists, setSubdomainExists] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const subdomain = url.hostname.split('.')[0];
    try {
      axios.get(`${END_POINT.checkSubdomain.url(encodeURIComponent(subdomain.trim()))}`).then((response) => {
        // axios.get(`${END_POINT.checkSubdomain.url('haunguyen')}`).then((response) => {
        if (response.data.code === 200) {
          setSubdomainExists(true);
        } else {
          setSubdomainExists(false);
        }
      });
    } catch (error) {
      console.error('subdomain check error', error);
      setSubdomainExists(false);
    }
  }, []);

  if (subdomainExists === null) {
    return <div>Checking subdomain...</div>;
  } else if (subdomainExists === false) {
    return <NotAvailablePage />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={10}>
              <AuthDataProvider>
                <WebSocketProvider>
                  <AppLayout>
                    <AppRoute routes={routes} />
                  </AppLayout>
                </WebSocketProvider>
              </AuthDataProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
