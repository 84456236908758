import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';

export const emrSlice = createSlice({
  name: 'emrSlice',
  initialState: {
    errorCode: 0,
    error: null,
    isGettingEMRSettings: false,
    isUpdatingEMRSettings: false,
    isPushing: false,
    emr: [],
  },
  reducers: {
    getEMRSettings: (state, action) => {
      state.isGettingEMRSettings = true;
    },
    getEMRSettingsSuccess: (state, action) => {
      state.isGettingEMRSettings = false;
      state.emr = action.payload;
    },
    getEMRSettingsFailure: (state, action) => {
      state.isGettingEMRSettings = false;
    },

    postEMRSettings: (state, action) => {
      state.isUpdatingEMRSettings = true;
    },
    postEMRSettingsSuccess: (state, action) => {
      state.isUpdatingEMRSettings = false;
      state.emr = [
        {
          system: action.payload.emr_name,
          token: action.payload.settings.endpoint_url,
          clientId: action.payload.settings.client_id,
          clientSecret: action.payload.settings.client_secret,
          autoPush: action.payload.auto_push,
        },
      ];
    },
    postEMRSettingsFailure: (state, action) => {
      state.isUpdatingEMRSettings = false;
    },

    putSingleActivity: (state, action) => {
      state.isPushing = true;
    },
    putSingleActivitySuccess: (state, action) => {
      state.isPushing = false;
    },
    putSingleActivityFailure: (state, action) => {
      state.isPushing = false;
    },

    putAllActivity: (state, action) => {
      state.isPushing = true;
    },
    putAllActivitySuccess: (state, action) => {
      state.isPushing = false;
    },
    putAllActivityFailure: (state, action) => {
      state.isPushing = false;
    },
  },
});
export const {
  getEMRSettings,
  getEMRSettingsFailure,
  getEMRSettingsSuccess,
  postEMRSettings,
  postEMRSettingsFailure,
  postEMRSettingsSuccess,
  putSingleActivityFailure,
  putSingleActivitySuccess,
  putSingleActivity,
  putAllActivityFailure,
  putAllActivitySuccess,
  putAllActivity,
} = emrSlice.actions;

export default emrSlice.reducer;
