// https://medium.com/trabe/implementing-private-routes-with-react-router-and-hooks-ed38d0cf93d5
import { createContext, useState, useContext } from 'react';

export const AuthDataContext = createContext(null);

const AuthDataProvider = (props) => {
  const initAuthData = {
    fetchingUser: false,
    currentUser: {},
    loginFunc: null,
    logoutFunc: null,
  };

  const token = localStorage.getItem('access_token');
  if (token) {
    initAuthData.token = token;
  }

  const [authData, setAuth] = useState(initAuthData);

  const resetData = () => {
    setAuth({ fetchingUser: false });
  };

  const setData = (data) => {
    setAuth({ ...authData, ...data });
  };

  const login = (data) => {
    if (authData.loginFunc) {
      authData.loginFunc(data);
    }
  };

  const logOut = () => {
    if (authData.logoutFunc) {
      authData.logoutFunc(token);
    }
    resetData();
  };

  return <AuthDataContext.Provider value={{ ...authData, setData, resetData, login, logOut }} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
