import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';
import { useEffect, useRef, useState } from 'react';
import socketActions from 'components/WebSocketProvider/constants.js';
import { SingleImageStep } from 'helpers';
import noImg from 'assets/images/png/noImage.png';
import Actions from 'modules/Patients/components/Machines/Actions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { clearMessageQueueHandler } from 'helpers';
export default function SetAngle({
  setupMachineData,
  machine,
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  defaultValue,
  setData,
  firstSide,
  socketContext,
}) {
  const [v, setValue] = useState(0);
  const [isDisableControl, setDisable] = useState(false);

  const isRequesting = useRef(false);
  const [currentSide, setCurrentSide] = useState(0);

  useEffect(() => {
    if (!defaultValue || !setupMachineData) {
      return;
    }

    if (defaultValue.angle !== '') {
      setValue(defaultValue.angle);
    } else {
      setValue(0);
      getData();
    }
  }, [setupMachineData, defaultValue, step, subStep]);

  useEffect(() => {
    if (firstSide === 1) {
      setCurrentSide(subStep === '3' ? 1 : 2);
      return null;
    }

    setCurrentSide(subStep === '3' ? 2 : 1);
  }, [firstSide]);

  useEffect(() => {
    return () => {
      socketContext.clearMessageHistory();
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, [step, subStep]);

  useEffect(() => {
    if (!isRequesting.current) {
      return;
    }
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (message.degree === undefined) {
      return;
    }

    setValue(message.degree);

    if (message.done) {
      setDisable(false);
      isRequesting.current = false;
    }
  }, [socketContext.messageHistory]);

  const getData = () => {
    setDisable(true);
    if (isRequesting.current) {
      return;
    }
    if (!machine) {
      return;
    }
    socketContext.clearMessageHistory();
    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    isRequesting.current = true;
  };

  const nextHandler = () => {
    const payload = {
      angle: v,
    };
    setData((preState) => ({ ...preState, ...payload }));

    handleNext();
  };

  const backHandler = () => {
    socketContext.clearMessageHistory();
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    handleBack();
  };
  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);

    setTimeout(() => {
      isRequesting.current = false;
      getData();
    }, 2000);
  };

  const gaugeSliderRender = () => {
    if (!setupMachineData) {
      return null;
    }

    let targetValue = Number(setupMachineData.range_motion_min);

    switch (firstSide) {
      case 1:
        if (subStep === '6') {
          targetValue = Number(setupMachineData.range_motion_max);
        }
        break;
      case 2:
        if (subStep === '3') {
          targetValue = Number(setupMachineData.range_motion_max);
        }
        break;
    }

    return (
      <div style={{ maxHeight: 270 }}>
        <GaugeSlider
          value={v}
          targetValue={targetValue}
          minValue={-96}
          maxValue={96}
          isLock={isDisableControl !== undefined ? !isDisableControl : false}
          bottomInfo={`Range of Motion: ${setupMachineData.range_motion_min ?? 0}° - ${
            setupMachineData.range_motion_max ?? 0
          }°`}
        />
      </div>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Box sx={{ display: 'flex' }}>
        <SingleImageStep img={noImg} />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography variant='body1' component='div'>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span> 1. Let’s execute a dynamic warm-up on the:</span>
              <Select value={currentSide} disabled={true}>
                <MenuItem value={currentSide}>{`${currentSide === 1 ? 'Left' : 'Right'}`}</MenuItem>
              </Select>
            </Box>
            2. Tighten chain tightener. Caution: Do not over tighten!
            <br />
            3. Disengage the angle selector and palpitate 3-6 times through pain-free ROM.
            <br />
            4. Place movement arm at the target angle.
            <br />
            5. Select “Exercise” on the angle selector.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <SingleImageStep img={noImg} />
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>{gaugeSliderRender()}</Box>
      </Box>
      <br />

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        handleBack={backHandler}
        disableNext={isDisableControl}
        handleUndo={redoHandler}
      />
    </Box>
  );
}

SetAngle.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  defaultValue: PropTypes.object,
  setData: PropTypes.func,
  firstSide: PropTypes.number,
  socketContext: PropTypes.object,
};
