import { useSearchParams } from 'react-router-dom';

import IsometricSummary from './IsometricSummary';
import TorsorIsometrictSummary from './TorsoSummary';
import CervicalRotationIsometricSummary from './CervicalRotationIsometricSummary';
import { useEffect, useState } from 'react';
import { getEMRSettings } from 'modules/EMR/slice.js';
import { useDispatch } from 'react-redux';
const IsometricTestSummary = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const machineType = searchParams.get('machineType');
  const [value, setValue] = useState(0)
  useEffect(() => {
    const hcpId = localStorage.getItem('hcpId');
    dispatch(getEMRSettings({ hcpId }));
  }, []);
  const activitiesId = searchParams.get('activitiesId');

  useEffect(() => {
    setValue(value + 1);
  }, [activitiesId]);

  switch (machineType) {
    case 'Lumbar':
      return <IsometricSummary activitiesId={activitiesId}/>;
    case 'Cervical':
      return <IsometricSummary activitiesId={activitiesId}/>;
    case 'Torso Rotation':
      return <TorsorIsometrictSummary activitiesId={activitiesId}/>;
    case 'Cervical Rotation':
      return <CervicalRotationIsometricSummary activitiesId={activitiesId}/>;
    case 'Knee':
      return 'developing';

    default:
      return null;
  }
};

export default IsometricTestSummary;
