import styled from '@emotion/styled';

const Container = styled('div')`
  .container {
    height: calc(100vh - 68px);
  }
  .container .header {
    height: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    font-size: 24px;
    font-weight: 600;
    color: #212529;
    padding: 32px;
  }
  .container .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    height: calc(100vh - 164px);
    overflow: hidden;
    background-color: #e6e6e6;
    padding: 32px;
  }

  .container .field-name {
    color: #212529;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    min-width: 160px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }

  .row {
    flex-direction: column;
    display: flex;
    gap: 4px;
    width: 100%;
  }
  .row .label {
    font-size: 16px;
    font-weight: 600;
  }
  .group-btn {
    display: flex;
    width: 100%;
    justify-content: end;
    gap: 8px;
  }
  .emr-details {
    padding: 32px;
    background-color: white;
    border-radius: 8px;
    width: 600px;
  }
`;

export const modalBaseStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 610,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '4px',
  outline: 'none',
};

export default Container;
