import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { forceLogout } from 'modules/Auth/slice';
import { ACCESS_TOKEN } from 'config/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EXCLUDED_PATHS = ['/users/accept-invite', '/customers/accept-invite', '/hcps/accept-invite', '/hcps/password'];

const PublicRoute = ({ children, authed, route, dispatch }) => {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();

  if (route.public) {
    return children ?? <Outlet />;
  }
  if (authed && EXCLUDED_PATHS.includes(route.path)) {
    localStorage.removeItem(ACCESS_TOKEN);
    dispatch(forceLogout());
    setTimeout(() => {
      navigator(`${route.path}?token=${searchParams.get('token')}`);
    }, 500);
    return <></>;
  }
  if (authed) {
    return <Navigate to='/' replace />;
  }
  return children ?? <Outlet />;
};

PublicRoute.propTypes = {
  redirectPath: PropTypes.string,
  authed: PropTypes.bool,
  route: PropTypes.object,
  children: PropTypes.element,
  dispatch: PropTypes.func,
};

export default PublicRoute;
