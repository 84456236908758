import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Box from '@mui/material/Box';
import { SingleImageStep } from 'helpers';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import weightStackChainTightener from 'assets/images/SVG/Cervical/Weight-stack-chain-tightener.svg';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import movementArmLock from 'assets/images/SVG/Cervical/Movement-arm-lock.svg';
import { allowDecimalNumber } from 'helpers';

import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Actions from 'modules/Patients/components/Machines/Actions';

const seatData = [
  {
    id: 0,
    value: 0,
  },
  {
    id: 1,
    value: 1,
  },
  {
    id: 2,
    value: 2,
  },
];
export default function CheckPositioning({ handleCancel, handleBack, handleNext, onDataChange, value, step, subStep }) {
  const defaultValues = {
    seat_pos: '',
    seat_pad: '',
  };

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  const { t } = useTranslation();

  const [stepData, setStepData] = useState(defaultValues);

  useEffect(() => {
    if (!value) {
      return;
    }

    setValue('seat_pos', value.seat_pos === null ? '' : value.seat_pos);
    setValue('seat_pad', value.seat_pad === null ? '' : value.seat_pad);

    setStepData({
      seat_pos: value.seat_pos,
      seat_pad: value.seat_pad,
    });
  }, [value]);

  const handleSeatChange = (value) => {
    setStepData((prevState) => ({ ...prevState, seat_pad: value }));
  };

  const inputSeatHeightHandler = (event, onchange) => {
    if (!event.target.value) {
      onchange(event);
      setStepData((prevState) => ({
        ...prevState,
        seat_pos: event.target.value,
      }));
      return;
    }

    onchange(event);
    setStepData((prevState) => ({
      ...prevState,
      seat_pos: event.target.value,
    }));
  };

  const isDisable = () => {
    if (!stepData || !!errors.seat_pos) {
      return true;
    }

    if (
      stepData.seat_pos === null ||
      stepData.seat_pos === '' ||
      stepData.seat_pos === undefined ||
      stepData.seat_pad === null ||
      stepData.seat_pad === '' ||
      stepData.seat_pad === undefined
    ) {
      return true;
    }

    return false;
  };

  // hi-jack default handle next to save data
  const handleNextStep = () => {
    if (stepData.seat_pad === value?.seat_pad && stepData.seat_pos === value?.seat_pos) {
      handleNext();
      return;
    }
    onDataChange({
      seat_pad: stepData.seat_pad,
      seat_pos: stepData.seat_pos,
      femur_restraint: null,
      range_motion_min: null,
      range_motion_max: null,
      dead_center: null,
      counterbalance_angle: null,
      counterbalance_torque: null,
      counterbalance_gauge: null,
    });
    handleNext();
  };

  const getPosErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
  };

  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 3,
            }}
          >
            <Box>
              <SingleImageStep img={weightStackChainTightener} />
            </Box>

            <Box>
              1. Select a light weight on the weight stack: females = 20 inch pounds, males = 90 in lbs.
              <br />
              2. Tighten the chain tightener.
              <br />
              {/* 3. Instructed patient to bring their head forward as far as possible, “Chin to chest.” */}
              3. Disengage angle selector.
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 3,
            }}
          >
            <Box>
              <SingleImageStep img={angleSelector} />
            </Box>

            <Box>
              {/* 4. Instructed patient to bring their head forward as far as possible, “Chin to chest.” */}
              4. Instructed patient to bring their head forward as far as possible, “Chin to chest.”
              <br />
              5. When patient reaches their most flexed position, engage angle selector.
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 3,
            }}
          >
            <Box>
              <SingleImageStep img={movementArmLock} />
            </Box>

            <Box>
              6. Release the movement arm lock (to down position) and instruct patient to move back/slowly through their
              pain free range of motion.
              <br />
              7. As the patient moves, watch slowly for movement of the patient’s head against the resistance pad.
              <br />
              8. Adjust seat height until both patient and clinician are satisfied that there is no slipping against the
              pad.
              <br />
              <br />
              <Box sx={{ display: 'flex', gap: 5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  <label>
                    <strong>Manually enter seat height.</strong>
                    <br />
                    Enter the entire number on the gauge as a whole number.
                  </label>
                  <Controller
                    name='seat_pos'
                    rules={{
                      required: true,
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ maxWidth: 150, mt: 1 }}>
                        <TextField
                          onKeyPress={(e) => {
                            allowDecimalNumber(e);
                          }}
                          error={!!errors.seat_pos}
                          helperText={getPosErrorMessage(errors.seat_pos)}
                          placeholder='---'
                          value={value}
                          type={'text'}
                          onChange={(event) => {
                            inputSeatHeightHandler(event, onChange);
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  <label>
                    <strong>Manually enter seat pad.</strong>
                  </label>
                  <Controller
                    name='seat_pad'
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ maxWidth: 150, mt: 1 }}>
                        <Select
                          value={value}
                          onChange={(event) => {
                            onChange(event);
                            handleSeatChange(event.target.value);
                          }}
                        >
                          <MenuItem value=''>
                            <em>--</em>
                          </MenuItem>
                          {seatData.map((s) => (
                            <MenuItem key={s.id} value={s.id}>
                              {s.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNextStep}
        disableNext={isDisable()}
      />
    </>
  );
}

CheckPositioning.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  value: PropTypes.object,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  onDataChange: PropTypes.func,
};
