import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined.js';

const FlipSwitch = () => {
  return (
    <span style={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}>
      <b style={{ color: '#ED9A00' }}>FLIP SWITCH</b>
      <Tooltip
        title={`You can also use F10 key (for Mac OS) or Ctrl +F10 key (for Windows) as Flip Switch.`}
        arrow={true}
      >
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </span>
  );
};

export default FlipSwitch;
