import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import step1 from 'assets/images/png/physical-positioning/3-1.png';
import Box from '@mui/material/Box';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

const SeatingPosition = ({ step, setupMachineData, subStep, handleCancel, handleBack, handleNext }) => {
  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <div>1. Check seat data to see if patient needs a seat pad. Ad pad, if needed.</div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 500 }}> Patient Setup Seat Pad:</span>

          <div
            style={{
              padding: 2,
              border: '1px solid green',
              fontWeight: 500,
              borderRadius: '8px',
              width: '100px',
              textAlign: 'center',
              color: 'green',
            }}
          >{`${setupMachineData.seat_pad ?? 'No Pad'}`}</div>
        </div>
      </Box>
      <Box sx={{ display: 'flex', gap: 3, paddingTop: 5 }}>
        <Box>
          <SingleImageStep img={step1} />
        </Box>

        <Typography variant='body1' component='div'>
          2. Have patient sit in the chair feet tucked under the seat and their back snug against the back rest.
          <br />
          3. Have the patience to sit upright in the machine with the low back area against the pelvic restraint.
        </Typography>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </>
  );
};

SeatingPosition.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default SeatingPosition;
