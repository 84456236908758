import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { formatDate } from 'helpers/datetime.js';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { genderRender } from 'helpers';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { END_POINT } from 'modules/Patients/constants';
import { allowIntNumber } from 'helpers';
import { allowAlphaNumericSpace } from 'helpers';

import debounce from 'lodash/debounce';

export default function SearchPatientDialog({
  searchResults,
  error,
  onSubmitSearchPatientDialog,
  openSearchPatientDialog,
  handleCloseSearchPatientDialog,
}) {
  const defaultValues = {
    patientId: '',
    address: '',
    firstName: '',
    lastName: '',
    dob: null,
    gender: '',
    mobile: '',
    homePhone: '',
    email: '',
    country: null,
    state: null,
    city: null,
    zipCode: '',
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);

  const [countries, setCountryOptions] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();

  const [selectedState, setSelectedState] = useState();
  const [states, setStatesOptions] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [stateKeyword, setSearchStateKeyword] = useState();

  const [cities, setCitiesOptions] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [cityKeyword, setSearchCityKeyword] = useState();

  const [searchDone, setSearchDoneState] = useState(false);
  const [patient, setPatient] = useState(false);

  const [customError, setCustomError] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: 'all', defaultValues });

  const resultContentRef = useRef(null);

  useEffect(() => {
    if (customError) {
      setError(customError.field, {
        type: customError.type,
      });
    }
  }, [customError]);

  //when search result is returned
  useEffect(() => {
    // when search result is 0 or more than 2
    if (searchResults && searchResults.count >= 0) {
      setSearchDoneState(true);
      setTimeout(() => {
        resultContentRef.current.scrollIntoView(false);
      }, 50);
    } else if (searchResults && searchResults.data && searchResults.data.patient_id) {
      // when search result is exactly 1
      setPatient(searchResults.data);
    }
  }, [searchResults]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getStates();
    }
  }, [selectedCountry, stateKeyword]);

  useEffect(() => {
    if (selectedState) {
      getCities();
    }
  }, [selectedState, cityKeyword]);

  const resetForm = () => {
    setSearchDoneState(false);
    reset(defaultValues);
  };

  const onClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
  };

  const closeModal = () => {
    if (handleCloseSearchPatientDialog) {
      handleCloseSearchPatientDialog();
    }
    resetForm();
  };

  const viewPatient = () => {
    startOver();
    closeModal();

    navigate(`/patient/${btoa(patient.patient_id)}`);
  };

  // story said do not reset form when start over
  const startOver = () => {
    setPatient(null);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    const submitData = { ...data };
    if (onSubmitSearchPatientDialog) {
      onSubmitSearchPatientDialog(submitData);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  const getCountries = () => {
    setLoadingCountries(true);

    const keyword = '';
    axios.get(`${END_POINT.getCountries.url}&name=${encodeURIComponent(keyword.trim())}`).then((resp) => {
      const countries = resp.data;
      setCountryOptions(countries);
      setLoadingCountries(false);
    });
  };

  const getStates = () => {
    setLoadingStates(true);

    const keyword = stateKeyword || '';
    axios
      .get(`${END_POINT.getRegions.url(selectedCountry.iso2)}&name=${encodeURIComponent(keyword.trim())}`)
      .then((resp) => {
        let states = [{ name: 'None', iso2: -1 }];
        if (resp.data) {
          states = [...states, ...resp.data];
        }

        setStatesOptions(states);
        setLoadingStates(false);
      });
  };

  const getCities = () => {
    if (selectedState.iso2 === -1) return setCitiesOptions([{ name: 'None', id: -1 }]);

    setLoadingCities(true);

    const keyword = cityKeyword || '';
    axios
      .get(
        `${END_POINT.getCitiesFromRegions.url(selectedCountry.iso2, selectedState.iso2)}&name=${encodeURIComponent(
          keyword.trim()
        )}`
      )
      .then((resp) => {
        let cities = [{ name: 'None', id: -1 }];
        if (resp.data) {
          cities = [...cities, ...resp.data];
        }
        setCitiesOptions(cities);
        setLoadingCities(false);
      });
  };

  const getDate = (dateTime) => {
    if (!dateTime) return '';
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const handleCountryChange = (item) => {
    setValue('state', null, { shouldValidate: false });
    setValue('city', null, { shouldValidate: false });
    setSelectedState(null);

    setSelectedCountry(item);
  };

  const handleStateChange = (item) => {
    setValue('city', null, { shouldValidate: false });

    setSelectedState(item);
  };

  const handleStateSearch = (e, value, reason) => {
    if (reason === 'reset') return;
    setSearchStateKeyword(value);
  };

  const debouncedhandleStateTextChange = debounce(handleStateSearch, 1000);

  const handleCitySearch = (e, value, reason) => {
    if (reason === 'reset') return;
    setSearchCityKeyword(value);
  };

  const debouncedhandleCityTextChange = debounce(handleCitySearch, 1000);

  const getDateErrorMessage = (error) => {
    if (error?.type === 'pattern') return t('customer:addPatientDialog.errors.invalidDatePattern');
    if (error?.type === 'dob_future') {
      return t('customer:addPatientDialog.errors.futureDate');
    }
  };

  const getResultMessage = (searchResults) => {
    if (searchResults && searchResults.count === 0) {
      return t('common:errors.noResults');
    }
    if (searchResults && searchResults.error === 'Patient not found') {
      return t('common:errors.noResults');
    }
    if (searchResults && searchResults.count) {
      return `${searchResults.count} matching patients found. Enter more search criteria to refine your results.`;
    }
  };

  const getMobileNumber = (patient) => {
    let mobileNo;
    if (formatPhoneNumberIntl(`+${patient.mobile_no}`)) {
      mobileNo = formatPhoneNumberIntl(`+${patient.mobile_no}`);
    } else if (patient.mobile_no) {
      mobileNo = patient.mobile_no;
    } else {
      mobileNo = 'None';
    }
    return mobileNo;
  };

  const getHomeNumber = (patient) => {
    let homeNo;
    if (formatPhoneNumberIntl(`+${patient.home_no}`)) {
      homeNo = formatPhoneNumberIntl(`+${patient.home_no}`);
    } else if (patient.home_no) {
      homeNo = patient.home_no;
    } else {
      homeNo = 'None';
    }
    return homeNo;
  };

  const renderPatient = () => {
    const mobileNo = getMobileNumber(patient);
    const homeNo = getHomeNumber(patient);
    return (
      <>
        <DialogTitle> {t('customer:searchPatientDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Is this your patient? Double check that you have the correct patient before continuing.
          </DialogContentText>
          <Box sx={{ display: 'flex', mt: 3 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              Patient ID:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {patient.patient_id}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 3 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              {t('customer:searchPatientDialog.patientName')}:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {patient.fname} {patient.lname}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              {t('common:dob')}:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {formatDate(patient.dob)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              {t('common:gender')}:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {genderRender(patient?.gender)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              {t('common:address')}:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {patient.address}
              {patient.city !== 'None' ? `, ${patient.city}` : ''}
              {patient.state !== 'None' ? `, ${patient.state}` : ''}, {patient.country}, {patient.zip_code}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              {t('common:mobilePhone')}:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {mobileNo}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              {t('common:homePhone')}:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {homeNo}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ wordBreak: 'break-all', width: '30%' }} variant='body1' component='div'>
              {t('common:email')}:
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-all', fontWeight: 'bold', width: '70%' }}
              variant='body1'
              component='div'
            >
              {patient.email ? patient.email : 'None'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ mr: 2 }} variant='outlined' onClick={startOver}>
            Start Over
          </Button>
          <Button variant='contained' onClick={viewPatient}>
            {t('customer:searchPatientDialog.myPatient')}
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderDialogContent = () => (
    <OverlayScrollbarsComponent options={{ scrollbars: { autoHide: 'scroll' } }} style={{ maxHeight: '100vh' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle> {t('customer:searchPatientDialog.title')}</DialogTitle>
        <DialogContent>
          <OverlayScrollbarsComponent
            options={{
              scrollbars: { autoHide: 'scroll' },
              paddingAbsolute: true,
            }}
            style={{ maxHeight: '60vh' }}
          >
            <DialogContentText>{t('customer:searchPatientDialog.subtitle')}</DialogContentText>
            <Controller
              name='patientId'
              control={control}
              rules={{
                validate: {
                  minLength: (s) => {
                    if (!s) return true;
                    if (s.trim().split('').length < 2) return false;
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onInput={(e) => {
                    const value = e.target.value;
                    const numbers = value.replace(/[^0-9]/g, '');
                    setTimeout(() => {
                      onChange(numbers);
                    });
                  }}
                  onKeyPress={(e) => {
                    allowIntNumber(e);
                  }}
                  margin='normal'
                  fullWidth
                  id='patientId'
                  value={value}
                  label={t('customer:searchPatientDialog.patientId')}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 14,
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ width: '48%' }}>
                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    validate: {
                      minLength: (s) => {
                        if (!s) return true;
                        if (s.trim().split('').length < 2) return false;
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      margin='normal'
                      fullWidth
                      id='firstName'
                      value={value}
                      label={t('customer:searchPatientDialog.firstName')}
                      onChange={onChange}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '48%' }}>
                <Controller
                  name='lastName'
                  control={control}
                  rules={{
                    validate: {
                      minLength: (s) => {
                        if (!s) return true;
                        return s.trim().split('').length >= 2;
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      margin='normal'
                      fullWidth
                      value={value}
                      id='lastName'
                      label={t('customer:searchPatientDialog.lastName')}
                      onChange={onChange}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  width: '48%',
                  '.MuiTextField-root': {
                    width: '100%',
                  },
                }}
              >
                <Controller
                  name='dob'
                  control={control}
                  rules={{
                    validate: {
                      pattern: (v) => {
                        const s = getDate(v);
                        if (!s) return true;
                        if (s === 'NaN-NaN-NaN') return false;
                        return s.split('-')[2].length === 4;
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        defaultValue={null}
                        id='dob'
                        label={t('customer:addPatientDialog.dob')}
                        value={value}
                        onChange={onChange}
                        minDate={new Date('1900-01-01')}
                        onError={(error) => {
                          if (error === 'disableFuture') {
                            setCustomError({
                              field: 'dob',
                              type: 'dob_future',
                            });
                          }
                        }}
                        disableFuture
                        renderInput={(params) => (
                          <TextField
                            helperText={getDateErrorMessage(errors.dob)}
                            margin='normal'
                            {...params}
                            error={!!errors.dob}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Box>
              <Box sx={{ width: '48%' }}>
                <Controller
                  name='gender'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: '100%' }} margin='normal'>
                      <InputLabel>{t('customer:searchPatientDialog.gender')}</InputLabel>
                      <Select value={value} label={t('customer:searchPatientDialog.gender')} onChange={onChange}>
                        <MenuItem value={'1'}>Male</MenuItem>
                        <MenuItem value={'0'}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            <Controller
              name='address'
              control={control}
              rules={{
                validate: {
                  minLength: (s) => {
                    if (!s) return true;
                    return s.trim().split('').length >= 2;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin='normal'
                  fullWidth
                  id='address'
                  value={value}
                  label={t('customer:searchPatientDialog.address')}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^[a-zA-Z0-9 .&,\-]*$/.test(input)) {
                      onChange(input);
                    }
                  }}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ width: '48%' }}>
                <Controller
                  name='country'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      getOptionLabel={(option) => (option ? `${option.name}` : '')}
                      isOptionEqualToValue={(option, value) =>
                        value === undefined || value === '' || option.iso2 === value.iso2
                      }
                      renderOption={(props, option) => (
                        <Box component='li' {...props} key={option.iso2}>
                          {option.name}
                        </Box>
                      )}
                      value={value}
                      onChange={(event, item) => {
                        onChange(item);
                        handleCountryChange(item);
                      }}
                      options={countries}
                      noOptionsText={t('common:noDataFound')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='country'
                          margin='normal'
                          label={t('customer:searchPatientDialog.country')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingCountries ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '48%' }}>
                <Controller
                  name='state'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      getOptionLabel={(option) => (option ? `${option.name}` : '')}
                      isOptionEqualToValue={(option, value) =>
                        value === undefined || value === '' || option.iso2 === value.iso2
                      }
                      renderOption={(props, option) => (
                        <Box component='li' {...props} key={option.iso2}>
                          {option.name}
                        </Box>
                      )}
                      disabled={!selectedCountry}
                      value={value}
                      onInputChange={debouncedhandleStateTextChange}
                      onChange={(event, item) => {
                        onChange(item);
                        handleStateChange(item);
                      }}
                      options={states}
                      noOptionsText={t('common:noDataFound')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='state'
                          margin='normal'
                          label={t('customer:searchPatientDialog.state')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingStates ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  width: '48%',
                }}
              >
                <Controller
                  name='city'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      getOptionLabel={(option) => (option ? `${option.name}` : '')}
                      isOptionEqualToValue={(option, value) =>
                        value === undefined || value === '' || option.id === value.id
                      }
                      renderOption={(props, option) => (
                        <Box component='li' {...props} key={option.id}>
                          {option.name}
                        </Box>
                      )}
                      disabled={!selectedState}
                      noOptionsText={t('common:noDataFound')}
                      options={cities}
                      value={value}
                      onInputChange={debouncedhandleCityTextChange}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='city'
                          margin='normal'
                          label={t('customer:searchPatientDialog.city')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingCities ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  width: '48%',
                }}
              >
                <Controller
                  name='zipCode'
                  control={control}
                  rules={{
                    validate: {
                      minLength: (s) => {
                        if (!s) return true;
                        return s.trim().split('').length >= 2;
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onKeyPress={(e) => {
                        allowAlphaNumericSpace(e);
                      }}
                      margin='normal'
                      fullWidth
                      id='zipCode'
                      value={value}
                      label={t('customer:searchPatientDialog.zipCode')}
                      onChange={onChange}
                      inputProps={{
                        maxLength: 15,
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <FormControl
              fullWidth
              sx={{
                mt: 2,

                '&:focus-within': {
                  '.special-label': {
                    color: '#ED9A00',
                  },
                },

                '.form-control:focus': {
                  boxShadow: '0 0 0 1px #ED9A00 !important',
                  borderColor: '#ED9A00 !important',
                },

                '.search-box': {
                  height: '40px',
                  width: '250px',
                },
              }}
            >
              <Controller
                name='mobile'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    inputStyle={{
                      width: '100%',
                      height: '56px',
                      fontSize: '15px',
                      paddingLeft: '60px',
                      borderRadius: '4px',
                    }}
                    id='mobile'
                    country={'us'}
                    value={value}
                    enableSearch
                    onChange={onChange}
                    searchNotFound={'No data found.'}
                    specialLabel={t('customer:searchPatientDialog.mobile')}
                    placeholder={''}
                  />
                )}
              />
            </FormControl>
            <FormControl
              fullWidth
              sx={{
                mt: 2,
                '&:focus-within': {
                  '.special-label': {
                    color: '#ED9A00',
                  },
                },
                '.form-control:focus': {
                  boxShadow: '0 0 0 1px #ED9A00 !important',
                  borderColor: '#ED9A00 !important',
                },
                '.search-box': {
                  height: '40px',
                  width: '250px',
                },
              }}
            >
              <Controller
                name='homePhone'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    inputStyle={{
                      width: '100%',
                      height: '56px',
                      fontSize: '15px',
                      paddingLeft: '60px',
                      borderRadius: '4px',
                    }}
                    id='homePhone'
                    country={'us'}
                    value={value}
                    enableSearch
                    onChange={onChange}
                    searchNotFound={'No data found.'}
                    specialLabel={t('customer:searchPatientDialog.homePhone')}
                    placeholder={''}
                  />
                )}
              />
            </FormControl>
            <Controller
              name='email'
              control={control}
              rules={{
                validate: {
                  minLength: (s) => {
                    if (!s) return true;
                    return s.trim().split('').length >= 2;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin='normal'
                  fullWidth
                  id='email'
                  value={value}
                  label={t('common:emailAddress')}
                  inputProps={{
                    maxLength: 255,
                  }}
                  onChange={onChange}
                />
              )}
            />
          </OverlayScrollbarsComponent>
        </DialogContent>
        <DialogActions>
          <Button sx={{ mr: 'auto' }} startIcon={<ClearIcon />} variant='outlined' onClick={resetForm}>
            {t('common:clearAll')}
          </Button>
          <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <LoadingButton
            type='submit'
            disabled={!isValid || !isDirty}
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            variant='contained'
          >
            {t('customer:searchPatientDialog.findPatient')}
          </LoadingButton>
        </DialogActions>
        <Box ref={resultContentRef}>
          {searchDone && (
            <Box
              sx={{
                display: 'flex',
                color: '#d32f2f',
                px: 3,
                py: 3,
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  fontSize: '1.1rem',
                  textAlign: 'center',
                }}
              >
                {getResultMessage(searchResults)}
              </Typography>
            </Box>
          )}
        </Box>
      </form>
    </OverlayScrollbarsComponent>
  );

  return (
    <Dialog
      // sx={{
      //   '& .MuiDrawer-paper': {
      //     display: 'flex',
      //     flexDirection: 'column',
      //     alignItems: 'center',
      //     boxSizing: 'border-box',
      //     width: drawerWidth,
      //   },
      // }}
      fullWidth
      maxWidth='sm'
      open={openSearchPatientDialog}
      onClose={onClose}
    >
      {patient ? renderPatient() : renderDialogContent()}
    </Dialog>
  );
}

SearchPatientDialog.propTypes = {
  patient: PropTypes.object,
  searchResults: PropTypes.object,
  anchor: PropTypes.string,
  error: PropTypes.any,
  openSearchPatientDialog: PropTypes.bool,
  handleCloseSearchPatientDialog: PropTypes.func,
  onSubmitSearchPatientDialog: PropTypes.func,
};
