import { StatusCodes } from 'http-status-codes';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthDataContext } from 'components/AuthProvider/index.jsx';
import { fetchUserInfo } from 'modules/Auth/slice';
import { ACCESS_TOKEN } from 'config/constants';
import { useEffect } from 'react';

export default function CurrentUser() {
  const context = useAuthDataContext();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const errorCode = useSelector((state) => state.auth.errorCode);
  const forcedLogout = useSelector((state) => state.auth.forcedLogout);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!currentUser && localStorage.getItem(ACCESS_TOKEN)) {
      dispatch(fetchUserInfo());
    } else if (currentUser) {
      context.setData({ currentUser });
    }
  }, [currentUser]);

  useEffect(() => {
    if (errorCode === StatusCodes.UNAUTHORIZED) {
      context.resetData();
      localStorage.removeItem(ACCESS_TOKEN);
      navigator(`/sign-in?redirect_url=${location.pathname}`);
    }
  }, [errorCode]);

  useEffect(() => {
    if (forcedLogout) {
      context.resetData();

      navigator(`/sign-out?redirect_url=${location.pathname}`);
    }
  }, [forcedLogout]);

  return null;
}
