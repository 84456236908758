export const generateYAxisArray = (value, length, space = 25) => {
  const newValue = Number(value);
  const delta = space - (newValue % space);
  const maxValue = delta + newValue;
  const yAxis = [0];
  const step = maxValue / length;
  for (let i = 0; i < length - 1; i++) {
    yAxis.push(Math.floor(yAxis[i] + step));
  }
  yAxis.push(maxValue);
  return yAxis;
};
