import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import step1 from 'assets/images/png/machine-setup/6a.png';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

const instructions = [
  {
    id: 1,
    text: '',
    img: step1,
  },
];
const LevelAndLock = ({ step, subStep, handleCancel, handleBack, handleNext }) => {
  return (
    <>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box>{getInstructions(instructions)}</Box>

        <Typography variant='body1' component='div'>
          1. Confirm bubble level is in the center and lock Counterweight lock.
          <br />
        </Typography>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
};

LevelAndLock.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
};

export default LevelAndLock;
