import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';

const TestingInstructions = ({ step, handleCancel, handleBack, handleNext, subStep, disableBack }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Typography variant='body1' component='div'>
          1. Provide instruction to the Patient on the following:
          <div style={{ padding: '0 20px' }}>
            • Tests will be conducted at multiple positions throughout the ROM.
            <br />
            • A brief rest period (approx. 10 seconds) will follow each test.
            <br />
            • Demonstrate how to push against the Torso pad.
            <br />
            • Visual feedback is provided by the monitor.
            <br />
            • Build maximum force slowly (3-1-3 second count).
            <br />
            • Avoid holding breath. Exhale when building force.
            <br />
            • Maintain hands resting in your lap.
            <br />
            • Maintain contact with the seatback.
            <br />• Exert force only when clinician instructs to do so.
          </div>
        </Typography>
      </Box>

      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
        disableBack={disableBack}
      />
    </>
  );
};

TestingInstructions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disableBack: PropTypes.bool,
};

export default TestingInstructions;
