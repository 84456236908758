import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Actions from 'modules/Patients/components/Machines/Actions';
export function Instructions({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box>
        <Typography variant='body1' component='div'>
          <div>1. Provide instruction to the Patient on the following:</div>

          <div style={{ padding: '0 20px' }}>
            • Keep hands relaxed in lap or resting loosely on gate assembly.
            <br />
            • Keep feet crossed and refrain from swinging legs.
            <br />
            • Maintain contact between head and resistance pad.
            <br />• Breathe normally, avoid holding breath.
          </div>
        </Typography>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
Instructions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
