import Box from '@mui/material/Box';
import { ContentContainer } from 'modules/DataSync/Modal/styled';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

const SyncOption = ({ setPage }) => {
  const syncDataFromFileHandler = () => {
    setPage(2);
  };

  const onSyncDataFromFileHandler = () => {
    syncDataFromFileHandler();
  };
  return (
    <ContentContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
        }}
      >
        <Box className={'btn'} onClick={() => onSyncDataFromFileHandler()}>
          <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.00004 12.8326C8.68085 12.8326 8.41146 12.7228 8.19189 12.5032C7.97233 12.2837 7.86254 12.0143 7.86254 11.6951V4.80217L6.12504 6.53967C5.90113 6.76359 5.6369 6.87654 5.33237 6.87852C5.02785 6.88052 4.7553 6.76123 4.51472 6.52065C4.2908 6.29673 4.18201 6.02834 4.18834 5.71547C4.19469 5.40262 4.30349 5.14058 4.51472 4.92935L8.20439 1.23967C8.31634 1.1277 8.43962 1.0469 8.57422 0.997271C8.70882 0.947638 8.85076 0.922821 9.00004 0.922821C9.14933 0.922821 9.29127 0.947638 9.42587 0.997271C9.56047 1.0469 9.68374 1.1277 9.79569 1.23967L13.4854 4.92935C13.7093 5.15325 13.8181 5.42163 13.8117 5.7345C13.8054 6.04736 13.6966 6.30941 13.4854 6.52065C13.2615 6.74456 12.9931 6.85969 12.6802 6.86602C12.3674 6.87237 12.099 6.76359 11.875 6.53967L10.1375 4.80217V11.6951C10.1375 12.0143 10.0278 12.2837 9.80819 12.5032C9.58863 12.7228 9.31924 12.8326 9.00004 12.8326ZM2.88047 17.3826C2.25077 17.3826 1.71418 17.1609 1.27069 16.7174C0.827211 16.2739 0.605469 15.7373 0.605469 15.1076V12.8804C0.605469 12.5612 0.715252 12.2918 0.934819 12.0723C1.15439 11.8527 1.42377 11.7429 1.74297 11.7429C2.06217 11.7429 2.33155 11.8527 2.55112 12.0723C2.77069 12.2918 2.88047 12.5612 2.88047 12.8804V15.1076H15.1076V12.8804C15.1076 12.5612 15.2174 12.2918 15.437 12.0723C15.6566 11.8527 15.926 11.7429 16.2452 11.7429C16.5644 11.7429 16.8338 11.8527 17.0533 12.0723C17.2729 12.2918 17.3827 12.5612 17.3827 12.8804V15.1076C17.3827 15.7373 17.1609 16.2739 16.7174 16.7174C16.274 17.1609 15.7374 17.3826 15.1076 17.3826H2.88047Z' />
          </svg>
          <div>Update Data File From Computer</div>
        </Box>
      </Box>
    </ContentContainer>
  );
};

SyncOption.propTypes = {
  setPage: PropTypes.func,
  file: PropTypes.string,
  setFile: PropTypes.func,
  setStatus: PropTypes.func,
};

export default SyncOption;
