import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchCustomers,
  fetchCustomersSuccess,
  fetchCustomersFailure,
  filterCustomers,
  filterCustomersSuccess,
  filterCustomersFailure,
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailure,
  fetchStates,
  fetchStatesSuccess,
  fetchStatesFailure,
  requestDeleteCustomer,
  requestDeleteCustomerSuccess,
  requestDeleteCustomerFailure,
  requestSuspendCustomer,
  requestSuspendCustomerSuccess,
  requestSuspendCustomerFailure,
  requestRestoreCustomer,
  requestRestoreCustomerSuccess,
  requestRestoreCustomerFailure,
  fetchHcpInfoFailure,
  fetchHcpInfo,
  fetchHcpInfoSuccess,
  updateHcpInfoFailure,
  updateHcpInfoSuccess,
  updateHcpInfo,
  fetchHcpRolesFailure,
  fetchHcpRolesSuccess,
  fetchHcpRoles,
  createHcpUser,
  createHcpUserFailure,
  createHcpSuccess,
  updateHcpUserFailure,
  updateHcpUser,
  updateHcpSuccess,
  archiveHcpUser,
  archiveHcpUserSuccess,
  archiveHcpUserFailure,
} from './slice';

import { END_POINT } from './constants';
import { requestToast } from 'modules/Ui/slice';
import { TOAST_TYPE } from 'modules/Ui/constants';
import { fetchHcpUsers } from '../HcpUsers/slice';

function* getCustomers(action) {
  const query = action.payload || {};
  const filterStatus = query.filterStatus >= -1 ? query.filterStatus : '';
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const itemPerPage = query.itemPerPage || '';

  let queryString = [];
  if (itemPerPage) {
    queryString.push(`item_per_page=${itemPerPage}`);
  }
  if (orderBy) {
    queryString.push(`order_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterStatus >= -1) {
    queryString.push(`filter_by=status&filter_value=${filterStatus}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(`${END_POINT.getCustomers.url}${queryString ? `?${queryString}` : ''}`);
    if (data.data) {
      yield put(fetchCustomersSuccess(data.data));
    } else {
      yield put(fetchCustomersFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchCustomersFailure(e.message));
  }
}

function* getFilteredCustomers(action) {
  const query = action.payload || {};
  const filterStatuses = query.filterStatuses;
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const mergedFilters = [];
  let mergedValues = [];

  let queryString = [];
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterStatuses.length) {
    for (let i = 0; i < filterStatuses.length; i++) {
      mergedFilters.push('status');
    }
    mergedValues = [...mergedValues, ...filterStatuses];
  }

  if (mergedFilters.length > 0) {
    queryString.push(`filter_by=${mergedFilters.join(',')}&filter_value=${mergedValues.join(',')}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(`${END_POINT.getFilteredCustomers.url}${queryString ? `?${queryString}` : ''}`);
    if (data.data) {
      yield put(filterCustomersSuccess(data.data));
    } else {
      yield put(filterCustomersFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(filterCustomersFailure(e.message));
  }
}

function* getCities() {
  try {
    const { data } = yield axios.get(END_POINT.getCities.url);
    if (data) {
      yield put(fetchCitiesSuccess(data));
    } else {
      yield put(fetchCitiesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchCitiesFailure(e.message));
  }
}

function* getStates() {
  try {
    const { data } = yield axios.get(END_POINT.getStates.url);
    yield put(fetchStatesSuccess(data));
  } catch (e) {
    yield put(fetchStatesFailure(e.message));
  }
}

function* getHcpInfo(action) {
  try {
    const { data } = yield axios.get(END_POINT.hcpInfo.url(action.payload));
    if (data) {
      const hcpInfo = {
        firstName: data.data.owner_info.first_name,
        lastName: data.data.owner_info.last_name,
        email: data.data.owner_info.email,
        phoneNumber: data.data.owner_info.phone_number,

        businessName: data.data.hcp_name,
        address: data.data.address,
        state: data.data.state,
        city: data.data.city,
        zipCode: data.data.zip_code,
        domainName: data.data.sub_domain,
        offices: data.data.offices,
      };
      yield put(fetchHcpInfoSuccess(hcpInfo));
    } else {
      yield put(fetchHcpInfoFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpInfoFailure(e.message));
  }
}

function* requestUpdateHcpInfo(action) {
  try {
    const { data } = yield axios.put(END_POINT.hcpInfo.url(action.payload.hcpId), action.payload.data);
    if (data.code === 200) {
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'Customer information updated successfully.',
        })
      );

      const hcpInfo = {
        firstName: data.data.owner_info.first_name,
        lastName: data.data.owner_info.last_name,
        email: data.data.owner_info.email,
        phoneNumber: data.data.owner_info.phone_number,

        businessName: data.data.hcp_name,
        address: data.data.address,
        state: data.data.state,
        city: data.data.city,
        zipCode: data.data.zip_code,
        domainName: data.data.sub_domain,
        offices: data.data.offices,
      };

      yield put(updateHcpInfoSuccess(hcpInfo));
    } else {
      yield put(
        requestToast({
          type: TOAST_TYPE.ERROR,
          message: 'Customer information updated Failure.',
        })
      );
      yield put(updateHcpInfoFailure(data));
    }
  } catch (e) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: 'Customer information updated Failure.',
      })
    );
    yield put(updateHcpInfoFailure(e.message));
  }
}

function* deleteCustomer(action) {
  try {
    const { data } = yield axios.put(END_POINT.manageCustomer.url(action.payload.customerId), {
      action: 'delete',
    });
    if (data.code === 200) {
      yield put(requestDeleteCustomerSuccess(data));
      yield put(fetchCustomers());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" deleted.`,
        })
      );
    } else {
      yield put(requestDeleteCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestDeleteCustomerFailure(e.message));
  }
}

function* suspendCustomer(action) {
  try {
    const { data } = yield axios.put(END_POINT.manageCustomer.url(action.payload.customerId), action.payload);
    if (data.code === 200 || data.code === 202) {
      yield put(requestSuspendCustomerSuccess(data));
      yield put(fetchCustomers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.hcp_name}" suspended.`,
        })
      );
    } else {
      yield put(requestSuspendCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestSuspendCustomerFailure(e.message));
  }
}

function* restoreCustomer(action) {
  try {
    const { data } = yield axios.put(END_POINT.manageCustomer.url(action.payload.customerId), action.payload);
    if (data.code === 200) {
      yield put(requestRestoreCustomerSuccess(data));
      yield put(fetchCustomers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.hcp_name}" restored.`,
        })
      );
    } else {
      yield put(requestRestoreCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestRestoreCustomerFailure(e.message));
  }
}

function* getHcpRoles(action) {
  try {
    const { data } = yield axios.get(END_POINT.hcpRoles.url(action.payload));
    if (data) {
      const hcpRoles = data.data.map((role) => ({
        name: role.name,
        id: role.id,
        permissionGroups: role.permission_groups,
      }));
      yield put(fetchHcpRolesSuccess(hcpRoles));
    } else {
      yield put(fetchHcpRolesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpRolesFailure(e.message));
  }
}

function* requestCreateHcpUser(action) {
  try {
    const { data } = yield axios.post(END_POINT.hcpUsers.url(action.payload.id), action.payload.data);

    if (data.code === 200) {
      yield put(createHcpSuccess());
      yield put(fetchHcpUsers({ hcpId: action.payload.id }));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `New customer user has been created. Invitation sent.`,
        })
      );
    } else {
      yield put(createHcpUserFailure(data.error));
    }
  } catch (e) {
    requestToast({
      type: TOAST_TYPE.ERROR,
      message: 'Create User Failure.',
    });
    yield put(createHcpUserFailure(e.message));
  }
}

function* requestUpdateUser(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.updateUsers.url(action.payload.customerId, action.payload.userId),
      action.payload.data
    );
    if (data.code < 300) {
      yield put(updateHcpSuccess(data));
      yield put(fetchHcpUsers({ hcpId: action.payload.customerId }));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: action.payload.toastSmg,
        })
      );
    } else {
      if (data.error?.subcode === 105) {
        yield put(
          requestToast({
            type: TOAST_TYPE.ERROR,
            message: data.error?.message,
          })
        );
        yield put(fetchHcpUsers({ hcpId: action.payload.customerId }));
      } else {
        yield put(createHcpUserFailure(data.error));
        yield put(
          requestToast({
            type: TOAST_TYPE.ERROR,
            message: data.error,
          })
        );
      }
    }
  } catch (e) {
    yield put(updateHcpUserFailure(e.message));
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: e.message,
      })
    );
  }
}

function* requestArchiveHcpUser(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.archiveHcpUser.url(action.payload.customerId, action.payload.userId),
      action.payload.data
    );
    if (data.code < 300) {
      yield put(archiveHcpUserSuccess(data));
      yield put(fetchHcpUsers({ hcpId: action.payload.customerId }));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: action.payload.toastSmg,
        })
      );
    } else {
      yield put(archiveHcpUserFailure(data.error));
    }
  } catch (e) {
    yield put(archiveHcpUserFailure(e.message));
  }
}

function* customersSaga() {
  yield takeLatest(fetchCustomers, getCustomers);
  yield takeLatest(filterCustomers, getFilteredCustomers);
  yield takeLatest(fetchCities, getCities);
  yield takeLatest(fetchStates, getStates);
  yield takeLatest(requestDeleteCustomer, deleteCustomer);
  yield takeLatest(requestSuspendCustomer, suspendCustomer);
  yield takeLatest(requestRestoreCustomer, restoreCustomer);
  yield takeLatest(fetchHcpInfo, getHcpInfo);
  yield takeLatest(updateHcpInfo, requestUpdateHcpInfo);
  yield takeLatest(fetchHcpRoles, getHcpRoles);
  yield takeLatest(createHcpUser, requestCreateHcpUser);
  yield takeLatest(updateHcpUser, requestUpdateUser);
  yield takeLatest(archiveHcpUser, requestArchiveHcpUser);
}

export default customersSaga;
