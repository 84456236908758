import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { TextareaAutosize } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { clearMachineSetup, fetchMachineSetup, fetchPatient, requestInitSetup } from '../../../slice';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputText } from 'components/InputText';
import styled from '@emotion/styled';
import { fetchHcpMachines } from '../../../../Machines/slice';
import { SessionInfo } from '../../../components/Info';
import { isValidBase64 } from 'helpers';
import Container from '@mui/material/Container';
import SummaryControl from '../../SummaryControl';

const SummaryContainer = styled('div')`
  & .notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & .notes:hover {
    border-color: black;
  }
  & .notes:focus {
    outline-color: #ed9a00;
  }
`;
const requireSmg = '*This field is required.';
const divisionSmg = '*The angle value should be divided by 6.';

export const KneeEditSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();

  const sessionId = searchParams.get('sessionId');
  const mode = searchParams.get('mode');
  const isBack = searchParams.get('isBack');
  const hcpId = localStorage.getItem('hcpId');
  const machineType = searchParams.get('machineType');
  const type = searchParams.get('type');

  const patient = useSelector((state) => state.patients.patient);
  const machines = useSelector((state) => state.machines.hcpMachines);
  const isInitSetups = useSelector((state) => state.patients.isInitSetups);
  const setupMachineData = useSelector((state) => state.patients.setup);

  const [setupName, setSetupName] = useState('');
  const [error, setError] = useState(false);
  const [notes, setNote] = useState('');
  const [requestingSetup, setRequestingSetup] = useState(false);
  const [errorFrom, setErrorForm] = useState({});
  const [data, setData] = useState({
    seat_pos: '',
    left_range_motion_max: '',
    left_range_motion_min: '',
    right_range_motion_max: '',
    right_range_motion_min: '',
  });

  const { id, machineId, setupId } = useParams();
  const patientId = id;

  useEffect(() => {
    dispatch(fetchHcpMachines({ hcpId }));
    dispatch(
      fetchMachineSetup({
        hcpId: hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
      })
    );
    if (patientId && isValidBase64(patientId) && !patient) {
      const data = {
        patientId: atob(id),
        hcpId,
      };
      dispatch(fetchPatient(data));
    }

    return () => {
      dispatch(clearMachineSetup());
      localStorage.removeItem('setupId');
    };
  }, []);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }

    setSetupName(setupMachineData.setup_name + '-copy' || '');
    setNote(setupMachineData.notes ?? '');
    const { seat_pos, left_range_motion_max, left_range_motion_min, right_range_motion_max, right_range_motion_min } =
      setupMachineData.data;
    setData({ seat_pos, left_range_motion_max, left_range_motion_min, right_range_motion_max, right_range_motion_min });
  }, [setupMachineData]);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }
    if (setupMachineData.data.knee_test_mode !== 2) {
      if (!data.left_range_motion_max) {
        setErrorForm((prevState) => ({
          ...prevState,
          left_range_motion_max: requireSmg,
        }));
      } else {
        setErrorForm((prevState) => ({
          ...prevState,
          left_range_motion_max: Number(data.left_range_motion_max) % 6 === 0 ? undefined : divisionSmg,
        }));
      }

      if (!data.left_range_motion_min) {
        setErrorForm((prevState) => ({
          ...prevState,
          left_range_motion_min: requireSmg,
        }));
      } else {
        setErrorForm((prevState) => ({
          ...prevState,
          left_range_motion_min: Number(data.left_range_motion_min) % 6 === 0 ? undefined : divisionSmg,
        }));
      }
    }

    if (setupMachineData.data.knee_test_mode !== 1) {
      if (!data.right_range_motion_max) {
        setErrorForm((prevState) => ({
          ...prevState,
          right_range_motion_max: requireSmg,
        }));
      } else {
        setErrorForm((prevState) => ({
          ...prevState,
          right_range_motion_max: Number(data.right_range_motion_max) % 6 === 0 ? undefined : divisionSmg,
        }));
      }

      if (!data.right_range_motion_min) {
        setErrorForm((prevState) => ({
          ...prevState,
          right_range_motion_min: requireSmg,
        }));
      } else {
        setErrorForm((prevState) => ({
          ...prevState,
          right_range_motion_min: Number(data.right_range_motion_min) % 6 === 0 ? undefined : divisionSmg,
        }));
      }
    }

    if (!data.seat_pos) {
      setErrorForm((prevState) => ({
        ...prevState,
        seat_pos: requireSmg,
      }));
    } else {
      setErrorForm((prevState) => ({
        ...prevState,
        seat_pos: undefined,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (requestingSetup && !isInitSetups) {
      const setup_id = localStorage.getItem('setupId');

      if (setup_id && patientId) {
        navigator(
          `/patient/${id}/machine-setup/summary?setupId=${setup_id}&type=${type}&isBack=true&machineType=${machineType}&type=${type}&sessionId=${sessionId}&machineId=${machineId}&isCloseDuplicate=false&mode=${mode}`
        );
      }
    }
  }, [isInitSetups, requestingSetup, setupMachineData]);
  const sessionInfoRender = () => {
    const machine = machines.find((m) => Number(m.machine.id) === Number(machineId));
    return <SessionInfo machine={machine} patient={patient} title={t('customer:patientSession.machineSetupSummary')} />;
  };
  const onDuplicateHandler = () => {
    let isError = false;

    if (!setupMachineData) {
      return null;
    }

    Object.keys(errorFrom).map((e) => {
      if (errorFrom[e]) {
        isError = true;
      }
    });

    if (isError) {
      return null;
    }
    if (error) {
      return null;
    }
    let payload;
    switch (setupMachineData.data.knee_test_mode) {
      case 1:
        payload = {
          seat_pos: data.seat_pos,
          left_range_motion_max: data.left_range_motion_max,
          left_range_motion_min: data.left_range_motion_min,
          knee_test_mode: 1,
        };
        break;
      case 2:
        payload = {
          right_range_motion_max: data.right_range_motion_max,
          right_range_motion_min: data.right_range_motion_min,
          seat_pos: data.seat_pos,
          knee_test_mode: 2,
        };
        break;
      default:
        payload = {
          seat_pos: data.seat_pos,
          left_range_motion_max: data.left_range_motion_max,
          left_range_motion_min: data.left_range_motion_min,
          right_range_motion_max: data.right_range_motion_max,
          right_range_motion_min: data.right_range_motion_min,
          knee_test_mode: setupMachineData.data.knee_test_mode,
        };
        break;
    }

    payload.setup_name = setupName;
    payload.notes = notes;

    dispatch(
      requestInitSetup({
        data: payload,
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(id),
        machineId,
        setupId,
      })
    );
    setRequestingSetup(true);
  };
  const dataRender = () => {
    if (!setupMachineData) {
      return null;
    }

    if (!setupMachineData.data) {
      return null;
    }

    const d = setupMachineData.data;

    return (
      <Box sx={{ display: 'flex', gap: 10, justifyContent: 'start' }}>
        <Box sx={d.knee_test_mode === 2 ? { color: 'gray' } : {}}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <span>
              <b>LEFT LEG</b>
            </span>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span style={{ paddingBottom: '20px' }}> 1. Max Flexion Angle: </span>
              <span style={{ width: '150px' }}>
                <InputText
                  isPositive={true}
                  onChange={(v) => setData((prevState) => ({ ...prevState, left_range_motion_max: v }))}
                  type={'number'}
                  disable={d.knee_test_mode === 2}
                  value={data.left_range_motion_max}
                  placeholder={d.knee_test_mode === 2 ? 'N/A' : ''}
                  numberRange={[6, 108]}
                  error={!!errorFrom.left_range_motion_max}
                  errorSmg={errorFrom.left_range_motion_max}
                />
              </span>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span style={{ paddingBottom: '20px' }}> 2. Max Extension Angle: </span>
              <span style={{ width: '150px' }}>
                <InputText
                  isPositive={true}
                  onChange={(v) => setData((prevState) => ({ ...prevState, left_range_motion_min: v }))}
                  type={'number'}
                  disable={d.knee_test_mode === 2}
                  placeholder={d.knee_test_mode === 2 ? 'N/A' : ''}
                  value={data.left_range_motion_min}
                  numberRange={[6, 108]}
                  error={!!errorFrom.left_range_motion_min}
                  errorSmg={errorFrom.left_range_motion_min}
                />
              </span>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span style={{ paddingBottom: '20px' }}> 3. Seat Position: </span>
              <span style={{ width: '150px' }}>
                <InputText
                  isPositive={true}
                  onChange={(v) => setData((prevState) => ({ ...prevState, seat_pos: v }))}
                  type={'number'}
                  disable={d.knee_test_mode === 2}
                  value={data.seat_pos}
                  error={!!errorFrom.seat_pos}
                  errorSmg={errorFrom.seat_pos}
                />
              </span>
            </Box>
          </Box>
        </Box>

        <Box sx={d.knee_test_mode === 1 ? { color: 'gray' } : {}}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <span>
              <b>RIGHT LEG</b>
            </span>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span style={{ paddingBottom: '20px' }}> 1. Max Flexion Angle: </span>
              <span style={{ width: '150px' }}>
                <InputText
                  type={'number'}
                  isPositive={true}
                  onChange={(v) => setData((prevState) => ({ ...prevState, right_range_motion_max: v }))}
                  placeholder={d.knee_test_mode === 1 ? 'N/A' : ''}
                  disable={d.knee_test_mode === 1}
                  value={data.right_range_motion_max}
                  numberRange={[6, 108]}
                  error={!!errorFrom.right_range_motion_max}
                  errorSmg={errorFrom.right_range_motion_max}
                />
              </span>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span style={{ paddingBottom: '20px' }}> 2. Max Extension Angle: </span>
              <span style={{ width: '150px' }}>
                <InputText
                  type={'number'}
                  isPositive={true}
                  placeholder={d.knee_test_mode === 1 ? 'N/A' : ''}
                  disable={d.knee_test_mode === 1}
                  onChange={(v) => setData((prevState) => ({ ...prevState, right_range_motion_min: v }))}
                  value={data.right_range_motion_min}
                  numberRange={[6, 108]}
                  error={!!errorFrom.right_range_motion_min}
                  errorSmg={errorFrom.right_range_motion_min}
                />
              </span>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span style={{ paddingBottom: '20px' }}> 3. Seat Position: </span>
              <span style={{ width: '150px' }}>
                <InputText
                  isPositive={true}
                  onChange={(v) => setData((prevState) => ({ ...prevState, seat_pos: v }))}
                  type={'number'}
                  disable={d.knee_test_mode === 1}
                  value={data.seat_pos}
                  error={!!errorFrom.seat_pos}
                  errorSmg={errorFrom.seat_pos}
                />
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const machineNameRender = () => {
    if (!setupMachineData) {
      return null;
    }

    const changeHandler = (value) => {
      if (!value || value.length === 0) {
        setError(true);
      } else {
        setError(false);
      }

      setSetupName(value);
    };

    return (
      <Box sx={{ display: 'flex', width: '50%', gap: 5, justifyContent: 'space-between', alignItems: 'baseline' }}>
        <InputText
          onChange={changeHandler}
          value={setupName}
          required={true}
          size={'small'}
          maxLength={255}
          errorSmg={'This field is required.'}
          isWidthFull={true}
          error={error}
        />
      </Box>
    );
  };

  const footerRender = () => {
    const machine = machines.find((m) => Number(m.machine.id) === Number(machineId));
    return (
      <SummaryControl
        isBack={isBack === 'true'}
        machine={machine}
        patientId={id}
        setupId={setupId}
        sessionId={sessionId}
        type={'MACHINE_SETUP'}
        setupValue={setupMachineData}
      />
    );
  };

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <Box>{sessionInfoRender()}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, padding: '50px 0' }}>
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                  Name
                </Typography>
              </Box>
              {machineNameRender()}

              <Box sx={{ paddingTop: '20px' }}>
                <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                  Test Type
                </Typography>
                <div>Patient Machine Setup</div>
              </Box>
            </Box>

            {dataRender()}

            <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column', width: '50%' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                  Notes
                </Typography>
              </Box>
              <Box>
                <SummaryContainer>
                  <TextareaAutosize
                    onChange={(v) => {
                      setNote(v.target.value);
                    }}
                    className={'notes'}
                    value={notes}
                    minRows={5}
                    maxLength={3000}
                    style={{
                      width: '100%',
                      borderColor: '#DDDFE1',
                      borderRadius: 5,
                      fontFamily: 'Roboto, Montserrat, sans-serif',
                      fontSize: 16,
                    }}
                  />
                </SummaryContainer>
              </Box>
            </Box>
          </Box>
          {footerRender()}
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '15px 0 15px 0' }}></Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}>
              <Button onClick={() => navigator(-1)} variant='outlined'>
                {t('common:cancel')}
              </Button>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton loading={isInitSetups} onClick={onDuplicateHandler} variant='contained'>
                  {t('common:save')}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default KneeEditSummary;
