import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';
import Reps from '../../Reps';
import { useEffect, useRef, useState } from 'react';
import IsometricRechart from 'components/Charts/IsometricReChart';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useSearchParams } from 'react-router-dom';
import Actions from 'modules/Patients/components/Machines/Actions';
import { getActualData } from './ultil';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';
import { clearMessageQueueHandler } from 'helpers';
import FlipSwitch from 'components/FlipSwitch';

const AngleTest = ({
  step,
  handleCancel,
  handleBack,
  handleNext,
  subStep,
  machine,
  socketContext,
  firstSide,
  testMode,
  defaultData,
  setDefaultData,
  defaultAngles,
  sessionId,
  setupMachineData,
}) => {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const [xAxis, setXAxis] = useState([]);
  const [currentTarget, setCurrentTarget] = useState(0);
  const [currentGaugeValue, setCurrentGaugeValue] = useState(0);
  const [peak, setPeak] = useState(0);
  const [state, setState] = useState(undefined);
  const [isLock, setLock] = useState(false);
  const elementTop = useRef(null);
  const elementBottom = useRef(null);

  const [currentSide, setCurrentSide] = useState(0);

  const [isDisableControl, setDisableControl] = useState(false);

  useEffect(() => {
    return () => {
      clearMessageQueueHandler(socketContext, machine);
    };
  }, [subStep, step]);

  useEffect(() => {
    if (testMode < 3) {
      setCurrentSide(firstSide);
      return null;
    }

    if (step === '6') {
      setCurrentSide(firstSide);
      return null;
    }
    setCurrentSide(firstSide === 1 ? 2 : 1);
  }, [firstSide, step]);

  useEffect(() => {
    if (!defaultAngles || !subStep || !defaultData) {
      return null;
    }

    setXAxis(defaultAngles);
    const [chart, currentData] = getActualData({
      pureData: defaultData,
      subStep: subStep,
      angles: defaultAngles,
    });
    setData(chart);
    setCurrentGaugeValue(currentData.targetValue === undefined ? 0 : currentData.targetValue);

    setCurrentTarget(currentData.name);
    setState(currentData.targetValue === undefined ? 1 : undefined);
    setPeak(currentData.peak);
    setLock(currentData.targetValue !== undefined);
    setDisableControl(currentData.targetValue === undefined);
  }, [defaultAngles, defaultData, step, subStep]);

  useEffect(() => {
    if (!state) {
      return null;
    }
    if (!currentSide) {
      return null;
    }

    if (state === 1 && !isLock) {
      if (elementBottom.current) {
        elementBottom.current.scrollIntoView();
      }
      socketContext.sendJsonMessage({
        request: socketActions.GET_DEGREE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
      return null;
    }

    if (state === 2) {
      if (elementTop.current) {
        setTimeout(() => {
          elementTop.current.scrollIntoView();
        }, 500);
      }
      getIsometricTestHandler();
      return null;
    }
    if (state === 3) {
      const cloneData = JSON.parse(JSON.stringify(data));
      cloneData.map((data) => {
        if (data?.active) {
          data.stored = data.active;
          delete data.active;
        }
      });
      setData(cloneData);
    }

    if (state > 3) {
      setTimeout(() => {
        if (elementBottom.current) {
          elementBottom.current.scrollIntoView();
        }
      }, 500);

      const cloneData = JSON.parse(JSON.stringify(data));

      cloneData.map((data) => {
        if (Number(data.name) === Number(currentTarget)) {
          if (data?.active) {
            delete data.active;
          }

          if (data?.max) {
            data.peak = data.max;
            delete data.max;
          }
        }
      });

      setData(cloneData);
      setDisableControl(false);
      return null;
    }
  }, [state, isLock, currentSide]);

  useEffect(() => {
    if (!state) {
      return;
    }

    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message) {
      return;
    }
    if (message.degree !== undefined) {
      setCurrentGaugeValue(Number(message.degree));
    } else if (message.pound) {
      const d = JSON.parse(JSON.stringify(data));

      switch (state) {
        case 2:
          for (let i = 0; i < d.length; i += 1) {
            if (Number(d[i].name) === Number(currentTarget)) {
              d[i].active = Number(Math.abs(message.pound));
            }
          }
          setData(d);
          break;
        case 3:
          setPeak(Math.abs(message.max_pound));
          for (let i = 0; i < d.length; i += 1) {
            if (Number(d[i].name) === Number(currentTarget)) {
              d[i].max = Math.abs(Number(message.max_pound));
              d[i].active = Math.abs(Number(message.pound));
            }
          }
          setData(d);
          break;

        default:
          break;
      }
    }
    if (message.done) {
      setLock(true);
      setState(state + 1);
    }
  }, [socketContext.messageHistory]);

  const nextHandler = () => {
    const newValue = JSON.parse(JSON.stringify(data));

    let value;
    for (const key in newValue) {
      if (key) {
        if (newValue[key].index === subStep) {
          value = newValue[key];
          break;
        }
      }
    }

    if (value?.active) {
      delete value.active;
    }

    if (value?.max) {
      value.peak = value.max;
      delete value.max;
    }

    if (value.name === currentTarget) {
      value.targetValue = currentGaugeValue;
    }

    switch (subStep) {
      case '3':
        setDefaultData((prevState) => ({
          ...prevState,
          s1Angle: { ...value },
        }));
        break;
      case '4':
        setDefaultData((prevState) => ({
          ...prevState,
          s2Angle: value,
        }));
        break;
      case '5':
        setDefaultData((prevState) => ({
          ...prevState,
          s3Angle: value,
        }));
        break;
      case '6':
        setDefaultData((prevState) => ({
          ...prevState,
          s4Angle: value,
        }));
        break;
      case '7':
        setDefaultData((prevState) => ({
          ...prevState,
          s5Angle: value,
        }));
        break;
      case '8':
        setDefaultData((prevState) => ({
          ...prevState,
          s6Angle: value,
        }));
        break;
      case '9':
        setDefaultData((prevState) => ({
          ...prevState,
          s7Angle: value,
        }));
        break;
    }

    handleNext();
  };

  const backHandler = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
    handleBack();
  };

  const undoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);

    setTimeout(() => {
      setState(undefined);
      const newValue = JSON.parse(JSON.stringify(data));

      let value;
      for (const key in newValue) {
        if (key) {
          if (newValue[key].index === subStep) {
            value = newValue[key];
            break;
          }
        }
      }

      const resetData = {
        name: value.name,
        targetValue: undefined,
        stored: 0,
        peak: 0,
        index: value.index,
      };

      switch (subStep) {
        case '3':
          setDefaultData((prevState) => ({
            ...prevState,
            s1Angle: resetData,
          }));
          break;
        case '4':
          setDefaultData((prevState) => ({
            ...prevState,
            s2Angle: resetData,
          }));
          break;
        case '5':
          setDefaultData((prevState) => ({
            ...prevState,
            s3Angle: resetData,
          }));
          break;
        case '6':
          setDefaultData((prevState) => ({
            ...prevState,
            s4Angle: resetData,
          }));
          break;
        case '7':
          setDefaultData((prevState) => ({
            ...prevState,
            s5Angle: resetData,
          }));
          break;
        case '8':
          setDefaultData((prevState) => ({
            ...prevState,
            s6Angle: resetData,
          }));
          break;
        case '9':
          setDefaultData((prevState) => ({
            ...prevState,
            s7Angle: resetData,
          }));
          break;
      }
    }, 2000);
  };

  const lastTestContentRender = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, currentData] = getActualData({
      pureData: defaultData,
      subStep: subStep,
      angles: defaultAngles,
    });

    if (defaultAngles[defaultAngles.length - 1] === Number(currentData.name)) {
      return (
        <Box>
          8. Disengage angle selector and move to 0°.
          <br />
          9. Engage angle selector and quickly loosen torso and footboard restraint.
        </Box>
      );
    }

    return '';
  };

  const getIsometricTestHandler = () => {
    const activityId = localStorage.getItem('activity_id');
    if (!machine) {
      return;
    }
    if (state > 3) {
      return;
    }
    if (!currentSide) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.ISOMETRIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: sessionId,
      activity_id: activityId,
      angle_degree: currentTarget,

      torso_test_mode: currentSide,
    });
  };

  const chartRender = () => {
    if (!data || !xAxis) {
      return;
    }

    if (data.length === 0 || xAxis.length === 0) {
      return;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <Box sx={{ alignSelf: 'center' }}>
          <b>{currentSide === 1 ? 'Left Side' : 'Right Side'}</b>
        </Box>
        <IsometricRechart
          yAxisRange={ISOMETRIC_YAXIS.ROTARY_TORSO}
          xAxisRange={xAxis}
          data={data}
          reversed={currentSide === 2}
        />
      </div>
    );
  };

  const orderRender = () => {
    const ordinalNumber = Number(subStep) - 2;
    let ordinals = '';

    switch (ordinalNumber) {
      case 1:
        ordinals = 'st';
        break;
      case 2:
        ordinals = 'nd';
        break;
      case 3:
        ordinals = 'rd';
        break;
      default:
        ordinals = 'th';
        break;
    }

    return `${ordinalNumber}${ordinals} actual`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box ref={elementTop}> {chartRender()}</Box>

      <Box>
        <Typography variant='body1' component='div'>
          1. Disengage the angle selector and palpitate 3-6 times through pain-free ROM. <br />
          2. Engage the angle selector at {orderRender()} test angle. <br />
          3. <FlipSwitch /> - to capture current angle.
          <br />
          4. Ask patient to relax and take a deep breath.
          <br />
          5. <FlipSwitch /> - to capture stored energy while patient is relaxed.
          <br />
          6. Ask patient to slowly begin to build force: push 25%, 50%, 75%, 100% and relax.
          <br />
          7. <FlipSwitch /> - to capture peak torque.
          <br />
          {lastTestContentRender()}
          <br />
          <br />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ maxHeight: 270 }}>
          <GaugeSlider
            value={currentGaugeValue}
            targetValue={currentTarget}
            minValue={-60}
            maxValue={60}
            isLock={isLock}
          />
        </div>

        <div>
          <Reps value={peak} title={'Peak Torque'} />
        </div>
      </Box>
      <Box ref={elementBottom}>
        <Actions
          step={step}
          subStep={subStep}
          handleCancel={handleCancel}
          handleNext={nextHandler}
          handleBack={backHandler}
          disableNext={isDisableControl}
          handleUndo={undoHandler}
        />
      </Box>
    </Box>
  );
};

AngleTest.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  testStack: PropTypes.array,
  socketContext: PropTypes.object,
  firstSide: PropTypes.number,
  testMode: PropTypes.number,
  defaultData: PropTypes.object,
  setDefaultData: PropTypes.func,
  defaultAngles: PropTypes.array,
  sessionId: PropTypes.string,
};

export default AngleTest;
