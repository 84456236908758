import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import NormTable from './NormTable';
import RomPainTable from './RomPainTable';


const StandardTestActivity = ({ data, currentMaxExtension, currentMaxFlexion }) => {

  const activeNet = useSelector((state) => state.patients.activeNet);
  const activeTested = useSelector((state) => state.patients.activeTested);

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ width: '77%' }}>
        <NormTable activeNet={activeNet} activeTested={activeTested} data={data || []}></NormTable>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <RomPainTable currentMaxExtension={currentMaxExtension} currentMaxFlexion={currentMaxFlexion} activeTested={activeTested}></RomPainTable>
      </Box>
    </Box>
  );
};

StandardTestActivity.propTypes = {
  data: PropTypes.any,
  currentMaxExtension: PropTypes.any,
  currentMaxFlexion: PropTypes.any,
};

export default StandardTestActivity;