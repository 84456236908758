import { createSlice } from '@reduxjs/toolkit';

export const offlineSlice = createSlice({
  name: 'offlineSlice',
  initialState: {
    isFetching: false,
  },
  reducers: {
    requestDownloadFile: (state) => {
      state.isFetching = true;
    },
    requestDownloadFileSuccess: (state) => {
      state.isFetching = false;
    },
    requestDownloadFileFailure: (state) => {
      state.isFetching = false;
    },
  },
});

export const { requestDownloadFile, requestDownloadFileFailure, requestDownloadFileSuccess } = offlineSlice.actions;

export default offlineSlice.reducer;
