import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled('div')`
  table,
  td,
  th {
    border: 1px solid;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;


const TableCurrentActivity = ({ data, activeTested, activeNet }) => {
  return (
    <>
      <Container>
        <table style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr style={{ color: 'black' }}>
              <th style={{ width: '275px', textAlign: 'left', paddingLeft: 5, fontSize: '14px', fontWeight: 600, background: activeTested ? '#F1AE33' : '#fff' }} key={`peak-name}`}>Tested Functional Torque</th>
              {data.map((d, index) => (
                <th style={{ color: '#343A40', textAlign: 'center', paddingRight: 5, fontSize: '14px', fontWeight: 400, background: activeTested ? '#FAE1B2' : '#fff' }} key={`peak-${index}`}>{`${d?.peak ?? 0}`}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr style={{ color: 'black' }}>
              <td style={{ width: '275px', textAlign: 'left', paddingLeft: 5, fontSize: '14px', fontWeight: 600 }} key={`stored-name}`}>Stored Energy</td>
              {data.map((d, index) => (
                <td style={{ color: '#343A40', textAlign: 'center', paddingRight: 5, fontSize: '14px', fontWeight: 400 }} key={`peak-${index}`}>{`${d?.stored ?? 0}`}</td>
              ))}
            </tr>
            <tr style={{ color: 'black' }}>
              <td style={{ width: '275px', textAlign: 'left', paddingLeft: 5, fontSize: '14px', fontWeight: 600, background: activeNet ? '#F1AE33' : '#fff' }} key={`net-name}`}>Net Muscular Torque</td>
              {data.map((d, index) => (
                <td style={{ color: '#343A40', textAlign: 'center', paddingRight: 5, fontSize: '14px', fontWeight: 400, background: activeNet ? '#FAE1B2' : '#fff' }} key={`peak-${index}`}>{`${(d?.peak - d?.stored) ?? 0}`}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </Container>
    </>
  );
};

TableCurrentActivity.propTypes = {
  data: PropTypes.object,
};

export default TableCurrentActivity;