import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import step1 from 'assets/images/png/preparation/step1.png';
import step2 from 'assets/images/png/preparation/step2.png';
import step3 from 'assets/images/png/preparation/step3.png';
import Actions from 'modules/Patients/components/Machines/Actions';
import { getInstructions } from 'helpers';

const instructions = [
  {
    id: 1,
    text: '4. Place angle selector at 12°.',
    img: step1,
  },
  {
    id: 2,
    text: '5. Walk over to the other side of the machine and unlock counterweight.',
    img: step2,
  },
  {
    id: 3,
    text: '6. Loosen chain tightener.',
    img: step3,
  },
];

export default function Preparation({ step, subStep, handleCancel, handleNext }) {
  return (
    <Box>
      <Typography variant='body1' component='div'>
        1. Before starting, make sure you have all medical information about the patient updated.
        <br />
        2. Ask patient if they need to use the restroom as the bladder area will be compressed.
        <br />
        3. Have patient remove belts or restrictive garments and empty object from pockets.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>{getInstructions(instructions)}</Box>
      <br />
      7. Place patient in Lumbar machine.
      <Actions step={step} subStep={subStep} handleCancel={handleCancel} handleNext={handleNext} />
    </Box>
  );
}

Preparation.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
