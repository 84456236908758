import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import TableRomPainCurrentActivity from './TableRomPainCurrentActivity';
import TableCurrentActivity from './TableCurrentActivity';
import { useSelector } from 'react-redux';

const CurrentActivityCompare = ({ data, currentPainLevel, currentMaxExtension, currentMaxFlexion }) => {

  const activeNet = useSelector((state) => state.patients.activeNet);
  const activeTested = useSelector((state) => state.patients.activeTested);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: activeNet ? 1 : 0 }}>
      <Box>
        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          Current Isometric Test
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '77%' }}>
          <TableCurrentActivity activeNet={activeNet} activeTested={activeTested} data={data || []}></TableCurrentActivity>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <TableRomPainCurrentActivity currentPainLevel={currentPainLevel} currentMaxExtension={currentMaxExtension} currentMaxFlexion={currentMaxFlexion} activeTested={activeTested} activeNet={activeNet} ></TableRomPainCurrentActivity>
        </Box>
      </Box>
    </Box>
  );
};

CurrentActivityCompare.propTypes = {
  data: PropTypes.object,
  currentPainLevel: PropTypes.any,
  currentMaxExtension: PropTypes.any,
  currentMaxFlexion: PropTypes.any
};

export default CurrentActivityCompare;