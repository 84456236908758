const socketActions = {
  LISTEN: 'LISTEN',
  STOP: 'STOP',

  PING: 'PING',
  PONG: 'PONG',

  MACHINE_DETECTED: 'MACHINE_DETECTED',
  MACHINE_CONFIGURED: 'MACHINE_CONFIGURED',
  MACHINE_DISCONNECTED: 'MACHINE_DISCONNECTED',

  NEW_MACHINE_DETECTED: 'NEW_MACHINE_DETECTED',
  NEW_MACHINE_CONFIGURED: 'NEW_MACHINE_CONFIGURED',
  NEW_MACHINE_DISCONNECTED: 'NEW_MACHINE_DISCONNECTED',

  CONFIGURATION_FAILED: 'CONFIGURATION_FAILED',

  OPEN_DEBUG_MACHINE: 'OPEN_DEBUG_MACHINE',
  CLOSE_DEBUG_MACHINE: 'CLOSE_DEBUG_MACHINE',
  GET_DEGREE_BY_SWITCH: 'GET_DEGREE_BY_SWITCH',
  GET_POUND_BY_SWITCH: 'GET_POUND_BY_SWITCH',
  STOP_GET_VALUE_BY_SWITCH: 'STOP_GET_VALUE_BY_SWITCH',
  POT_RANGE_CALIBRATION: 'POT_RANGE_CALIBRATION',
  ISOMETRIC_TEST: 'ISOMETRIC_TEST',
  DYNAMIC_TEST: 'DYNAMIC_TEST',
  GET_MANUAL_CALIBRATION: 'GET_MANUAL_CALIBRATION',
  MANUAL_CALIBRATION: 'MANUAL_CALIBRATION',
  LODE_CELL_CALIBRATION: 'LODE_CELL_CALIBRATION',
  CLEAR_MESSAGE_QUEUE: 'CLEAR_MESSAGE_QUEUE',
  AUTO_CALIBRATION_TOURQUE: 'AUTO_CALIBRATION_TOURQUE',
  FLIP_SWITCH: 'FLIP_SWITCH',
  COUNTER_BALANCE: 'COUNTER_BALANCE',
};

export default socketActions;
