import Container from './styled.js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { getLabel } from 'helpers';
import AddEMRSetting from '../components/AddEMRModal';
import IconButton from '@mui/material/IconButton';
import BorderColor from '@mui/icons-material/BorderColor';
import { useDispatch, useSelector } from 'react-redux';
import { getEMRSettings, postEMRSettings } from 'modules/EMR/slice.js';
import { SYSTEM_OPTIONS } from 'modules/EMR/constants.js';
import { requestToast } from 'modules/Ui/slice.js';
import { TOAST_TYPE } from 'modules/Ui/constants.js';
import useCurrentUser from 'hooks/useCurrentUser.js';
import { useNavigate } from 'react-router-dom';

const EMRSetting = () => {
  const [data, setData] = useState({
    system: 'CASPIO',
    token: '',
    clientId: '',
    clientSecret: '',
    autoPush: false,
  });
  const hcpId = localStorage.getItem('hcpId');
  const { user } = useCurrentUser();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  const { emr, isGettingEMRSettings, isUpdatingEMRSettings } = useSelector((state) => state.emrSettings);

  useEffect(() => {
    dispatch(getEMRSettings({ hcpId }));
  }, []);

  useEffect(() => {
    if (user && (!user.is_owner || !user?.hcp?.enabled_feature?.emr?.caspio)) {
      // window.location.href = '/403';
      navigator('/403');
      return;
    }
  }, [user]);

  useEffect(() => {
    if (emr.length === 0) {
      setData({
        system: 'CASPIO',
        token: '',
        clientId: '',
        clientSecret: '',
      });
      return;
    }

    setData(emr[0]);
  }, [emr]);

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onAddHandler = () => {
    setIsEdit(false);
    setOpen(true);
  };

  const onEditHandler = () => {
    setIsEdit(true);
    setOpen(true);
  };

  const onSaveHandler = (payload) => {
    dispatch(
      postEMRSettings({
        hcpId,
        body: {
          emr_name: payload.system,
          settings: {
            endpoint_url: payload.token,
            client_id: payload.clientId,
            client_secret: payload.clientSecret,
          },
          auto_push: payload.autoPush,
        },
        callback: () => {
          dispatch(
            requestToast({
              type: TOAST_TYPE.SUCCESS,
              message: isEdit
                ? 'The EMR Integration setup has been updated.'
                : 'The EMR Integration setup has been created.',
            })
          );

          setOpen(false);
        },
      })
    );
  };
  const emrDetailsRender = () => {
    if (emr.length === 0) {
      return <span>No EMR Integrate setup.</span>;
    }

    return (
      <Box className={'emr-details'}>
        <div className={'field-name'} style={{ width: '100%' }}>
          EMR Integrate Information
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '24px 0' }}>
          <div style={{ display: 'flex', gap: '60px' }}>
            <div className={'field-name'}>System</div>
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
              {getLabel(data.system, SYSTEM_OPTIONS)}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '60px' }}>
            <div className={'field-name'}>Token Endpoint URL</div>
            <div style={{ whiteSpace: 'nowrap', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data.token}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '60px' }}>
            <div className={'field-name'}>Client ID</div>
            <div style={{ whiteSpace: 'nowrap', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data.clientId}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '60px' }}>
            <div className={'field-name'}>Client Secret</div>
            <div style={{ whiteSpace: 'nowrap', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data.clientSecret}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '45px' }}>
            <div className={'field-name'}>Automatically push data</div>
            <div style={{ whiteSpace: 'nowrap', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data.autoPush ? 'Yes' : 'No'}
            </div>
          </div>
        </Box>
        <div style={{ padding: '24px 0 0 0' }}>
          <Button
            color='secondary'
            onClick={onEditHandler}
            sx={{ padding: 0 }}
            startIcon={
              <span style={{ fontSize: 24 }}>
                <BorderColor fontSize='inherit' />
              </span>
            }
            disableRipple
          >
            <span style={{ fontWeight: 500, textTransform: 'capitalize', fontSize: 16 }}>Edit</span>
          </Button>
        </div>
      </Box>
    );
  };

  return (
    <Container>
      <Box className={'container'}>
        <Box className={'header'}>Integrate Settings</Box>
        <Box className={'content'}>
          <Button
            sx={{ textTransform: 'none', maxWidth: '253px' }}
            variant={'contained'}
            startIcon={<AddIcon />}
            onClick={onAddHandler}
            disabled={emr.length !== 0}
          >
            Add EMR Integrate Settings
          </Button>
          {emrDetailsRender()}
        </Box>
        <AddEMRSetting
          open={open}
          onClose={onCloseHandler}
          isEdit={isEdit}
          editableData={data}
          onSave={onSaveHandler}
          loading={isGettingEMRSettings || isUpdatingEMRSettings}
        />
      </Box>
    </Container>
  );
};

export default EMRSetting;
