import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import counterbalanceAdjuster from 'assets/images/SVG/Cervical/Counter-Weight.svg';
import { SingleImageStep } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { useSelector } from 'react-redux';
import Actions from 'modules/Patients/components/Machines/Actions';
import { useTranslation } from 'react-i18next';
import { allowDecimalNumber } from 'helpers';

export default function EnterGaugeReading({ handleCancel, handleBack, handleNext, onDataChange, step, subStep }) {
  const { t } = useTranslation();
  const setupMachineData = useSelector((state) => state.patients.setup);
  const defaultValues = {
    gaugeReading: '',
  };

  const {
    reset,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ mode: 'all', defaultValues });

  const [stepData, setStepData] = useState(defaultValues);

  useEffect(() => {
    if (!setupMachineData) {
      return <></>;
    }

    setValue('gaugeReading', setupMachineData.counterbalance_gauge ?? '');

    setStepData({
      gaugeReading: setupMachineData.counterbalance_gauge ?? '',
    });
  }, [setupMachineData]);

  const handleInputChange = (event, onchange) => {
    setStepData(event.target.value);
    onchange(event);
  };

  const handleRedo = () => {
    reset(defaultValues);
  };

  // hi-jack default handle next to save data
  const handleNextStep = () => {
    onDataChange({
      counterbalance_gauge: stepData,
    });

    handleNext();
  };

  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <SingleImageStep img={counterbalanceAdjuster} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                <b> 1. Enter Counterweight Gauge Reading: </b>
                Manually enter gauge reading data. This reading is found on the counterweight position indicator.
              </Typography>
              <br />
              <label>
                <strong>Counterweight Gauge Reading.</strong>
                <br />
                Enter the number with decimal point.
              </label>
              <Box sx={{ mb: 2, maxWidth: 200 }}>
                <Controller
                  name='gaugeReading'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onKeyPress={(e) => {
                        allowDecimalNumber(e);
                      }}
                      sx={{ mt: 1 }}
                      error={!!errors.gaugeReading}
                      helperText={errors.gaugeReading?.type === 'required' && t('common:errors.required')}
                      placeholder='--'
                      margin='normal'
                      id='gaugeReading'
                      value={value}
                      type={'text'}
                      onChange={(event) => {
                        handleInputChange(event, onChange);
                      }}
                    />
                  )}
                />
              </Box>
              <Typography variant='body1' component='div'>
                2. Once counterweight data has been entered, you’re done.
                <br />
                Patient can step out of cervical machine.
              </Typography>
            </Box>
          </Box>
          <br />
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleUndo={handleRedo}
        disableRedo={stepData.gaugeReading === '' || !isDirty}
        handleNext={handleNextStep}
        disableNext={!!errors.gaugeReading || !isDirty}
      />
    </>
  );
}

EnterGaugeReading.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  onDataChange: PropTypes.func,
};
