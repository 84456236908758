import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchHcpUsers,
  fetchHcpUsersSuccess,
  fetchHcpUsersFailure,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailure,
  requestInviteHcpUser,
  requestInviteHcpUserSuccess,
  requestInviteHcpUserFailure,
  requestEditHcpUser,
  requestEditHcpUserSuccess,
  requestEditHcpUserFailure,
  requestDeleteHcpUser,
  requestDeleteHcpUserSuccess,
  requestDeleteHcpUserFailure,
  requestSuspendHcpUser,
  requestSuspendHcpUserSuccess,
  requestSuspendHcpUserFailure,
  requestRestoreHcpUser,
  requestRestoreHcpUserSuccess,
  requestRestoreHcpUserFailure,
} from './slice';

import { requestDialog } from 'modules/Dialogs/slice';
import { END_POINT } from './constants';
import { requestToast } from 'modules/Ui/slice';
import { TOAST_TYPE } from 'modules/Ui/constants';
import { DIALOGS } from 'modules/Dialogs/constants';
import { HCP_USER_STATUS } from 'modules/AuditLogs/constants';

function* getHcpUsers(action) {
  const hcpId = action.payload.hcpId;
  const isAuditLog = action.payload.isAuditLog
  const query = action.payload || {};
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const itemPerPage = query.itemPerPage || '';

  let queryString = [];
  if (itemPerPage) {
    queryString.push(`item_per_page=${itemPerPage}`);
  }
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(`${END_POINT.getHcpUsers.url(hcpId)}${queryString ? `?${queryString}` : ''}`);
    if (data.data) {
      let d;
      if (isAuditLog) {
        d = data.data.filter((d) => d.status !== HCP_USER_STATUS.PENDING);
      } else {
        d = data.data.filter((d) => d.role.name.toLowerCase() !== 'owner');
      }
      yield put(fetchHcpUsersSuccess(d));
    } else {
      yield put(fetchHcpUsersFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpUsersFailure(e.message));
  }
}

function* getRoles() {
  try {
    const { data } = yield axios.get(END_POINT.getRoles.url);
    yield put(fetchRolesSuccess(data.data));
  } catch (e) {
    yield put(fetchRolesFailure(e.message));
  }
}

function* inviteHcpUser(action) {
  try {
    const { data } = yield axios.post(END_POINT.inviteHcpUser.url(action.payload.hcpId), {
      first_name: action.payload.firstName.trim(),
      last_name: action.payload.lastName.trim(),
      email: action.payload.email,
      phone_number: action.payload.phoneNumber,
      role_id: action.payload.role,
      status: 0,
    });
    if (data.code === 200) {
      yield put(requestInviteHcpUserSuccess(data));
      yield put(fetchHcpUsers(action.payload.sortData));
    } else {
      yield put(requestInviteHcpUserFailure(data));
    }
  } catch (e) {
    yield put(requestInviteHcpUserFailure(e.message));
  }
}

function* editHcpUser(action) {
  try {
    const hcpUser = {
      first_name: action.payload.firstName.trim(),
      last_name: action.payload.lastName.trim(),
      email: action.payload.email,
      phone_number: action.payload.phoneNumber,
      role_id: action.payload.role,
      status: action.payload.status,
    };
    const { data } = yield axios.put(END_POINT.editHcpUser.url(action.payload.hcpId, action.payload.id), hcpUser);

    if (data.code === 200) {
      yield put(fetchHcpUsers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'Customer user information updated successfully.',
        })
      );
      yield put(requestDialog({ type: DIALOGS.INVITE_HCP_USERS_DIALOG, open: false }));
      yield put(requestEditHcpUserSuccess(data));
    } else {
      yield put(requestEditHcpUserFailure(data));
    }
  } catch (e) {
    yield put(requestEditHcpUserFailure(e.message));
  }
}

function* deleteHcpUser(action) {
  try {
    const { data } = yield axios.delete(END_POINT.editHcpUser.url(action.payload.hcpId, action.payload.id));

    if (data.code === 200) {
      yield put(requestDeleteHcpUserSuccess(data));
      yield put(fetchHcpUsers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" deleted.`,
        })
      );
    } else {
      yield put(requestDeleteHcpUserFailure(data));
    }
  } catch (e) {
    yield put(requestDeleteHcpUserFailure(e.message));
  }
}

function* suspendHcpUser(action) {
  try {
    const hcpUser = {
      status: action.payload.status,
    };
    const { data } = yield axios.put(END_POINT.editHcpUser.url(action.payload.hcpId, action.payload.id), hcpUser);
    if (data.code === 200) {
      yield put(requestSuspendHcpUserSuccess(data));
      yield put(fetchHcpUsers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" suspended.`,
        })
      );
    } else {
      yield put(requestSuspendHcpUserFailure(data));
    }
  } catch (e) {
    yield put(requestSuspendHcpUserFailure(e.message));
  }
}

function* restoreHcpUser(action) {
  try {
    const hcpUser = {
      status: action.payload.status,
    };
    const { data } = yield axios.put(END_POINT.editHcpUser.url(action.payload.hcpId, action.payload.id), hcpUser);
    if (data.code === 200) {
      yield put(requestRestoreHcpUserSuccess(data));
      yield put(fetchHcpUsers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" restored.`,
        })
      );
    } else {
      yield put(requestRestoreHcpUserFailure(data));
    }
  } catch (e) {
    yield put(requestRestoreHcpUserFailure(e.message));
  }
}

function* hcpUsersSaga() {
  yield takeLatest(fetchHcpUsers, getHcpUsers);
  yield takeLatest(fetchRoles, getRoles);
  yield takeLatest(requestInviteHcpUser, inviteHcpUser);
  yield takeLatest(requestEditHcpUser, editHcpUser);
  yield takeLatest(requestDeleteHcpUser, deleteHcpUser);
  yield takeLatest(requestSuspendHcpUser, suspendHcpUser);
  yield takeLatest(requestRestoreHcpUser, restoreHcpUser);
}

export default hcpUsersSaga;
