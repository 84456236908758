import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Questions = ({
  step,
  handleCancel,
  handleNext,
  subStep,
  setupMachineData,
  firstKnee,
  setFirstKnee,
  testMode,
  setTestMode,
}) => {
  const [leftCheck, setLeftCheck] = useState(false);
  const [rightCheck, setRightCheck] = useState(false);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    if (setupMachineData.knee_test_mode === 1) {
      setLeftCheck(true);
      setRightCheck(false);
      setDisable(true);
      setFirstKnee(1);
      return null;
    }

    if (setupMachineData.knee_test_mode === 2) {
      setLeftCheck(false);
      setRightCheck(true);
      setDisable(true);
      setFirstKnee(2);
      return null;
    }

    setLeftCheck(false);
    setRightCheck(false);
    setDisable(false);
    return null;
  }, [setupMachineData]);

  useEffect(() => {
    if (disable) {
      return;
    }
    if (!leftCheck && !rightCheck) {
      setFirstKnee(0);
      return null;
    }

    if (leftCheck === false && firstKnee === 1) {
      setFirstKnee(rightCheck ? 2 : 0);
      return null;
    }

    if (rightCheck === false && firstKnee === 2) {
      setFirstKnee(leftCheck ? 1 : 0);
      return null;
    }

    if (firstKnee === 1 && leftCheck) {
      return null;
    }

    if (firstKnee === 2 && rightCheck) {
      return null;
    }

    setFirstKnee(leftCheck ? 1 : 2);
  }, [leftCheck, rightCheck]);

  useEffect(() => {
    if (testMode !== 0) {
      setDisable(true);
      if (testMode === 1) {
        setLeftCheck(true);
      }
      if (testMode === 2) {
        setRightCheck(true);
      }
      if (testMode === 3) {
        setRightCheck(true);
        setLeftCheck(true);
      }
    }
  }, [testMode]);

  const nextHandler = () => {
    if (leftCheck && rightCheck) {
      setTestMode(3);
    } else if (leftCheck) {
      setTestMode(1);
    } else {
      setTestMode(2);
    }

    handleNext();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          1. Please select the exercise(s) you would like to perform today?
          <br />
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <label style={{ width: '80px' }}>
                <strong>Left Leg:</strong>
              </label>
              <Checkbox disabled={disable} onClick={() => setLeftCheck(!leftCheck)} checked={leftCheck} />
            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <label style={{ width: '80px' }}>
                <strong>Right Leg:</strong>
              </label>
              <Checkbox disabled={disable} onClick={() => setRightCheck(!rightCheck)} checked={rightCheck} />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>2. Which leg shall we test first?</Box>
          <br />
          <Box sx={{ paddingLeft: '20px' }}>
            <Select
              disabled={disable || (!leftCheck && !rightCheck)}
              style={{ minWidth: '100px' }}
              value={firstKnee}
              onChange={(v) => setFirstKnee(Number(v.target.value))}
            >
              {leftCheck && <MenuItem value={1}>Left</MenuItem>}
              {rightCheck && <MenuItem value={2}>Right</MenuItem>}
              {!leftCheck && !rightCheck && (
                <MenuItem value={0}>
                  <em>--</em>
                </MenuItem>
              )}
            </Select>
          </Box>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleNext={nextHandler}
        handleCancel={handleCancel}
        disableNext={!leftCheck && !rightCheck}
      />
    </>
  );
};

Questions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
  firstKnee: PropTypes.number,
  setFirstKnee: PropTypes.func,
  testMode: PropTypes.number,
  setTestMode: PropTypes.func,
};

export default Questions;
