import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Single from 'assets/images/knee/Single Leg Restraint.svg';
import support from 'assets/images/knee/adjustable leg support.svg';
import Thigh from 'assets/images/knee/Leg-Thigh restraint.svg';

const instructions = [
  {
    id: 1,
    text: '',
    img: Single,
  },
  {
    id: 2,
    text: '',
    img: support,
  },
  {
    id: 3,
    text: '',
    img: Thigh,
  },
];
const EquipmentSwap = ({ step, subStep, handleCancel, handleNext, firstKnee }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <span> 1. Let’s now test</span>

            <Select disabled={true} value={firstKnee}>
              <MenuItem value={1}>Right Extension</MenuItem>
              <MenuItem value={2}> Left Extension</MenuItem>
            </Select>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[0]])}</Box>
            <Typography variant='body1' component='div'>
              <b> 2. Single Leg Restraint:</b>

              <Box sx={{ padding: '0 20px' }}>
                • If you have a single leg restraint pad, please switch it to the opposite side, so we can test the
                other leg.
                <br />
                • Make sure to adequately tighten the leg restraint pad bolt.
                <br />
                • Attach calve restraint to involved leg.
                <br />• Calf restraint should be tightened and snugly as possible. Avoid over tightening which might
                restrict blood flow.
              </Box>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[1]])}</Box>
            <Typography variant='body1' component='div'>
              3. Engage the adjustable leg support to accommodate the leg not involved in the patient setup.
              <br />
              4. Have patient position their non involved leg on top of the adjustable leg support.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[2]])}</Box>
            <Typography variant='body1' component='div'>
              5. Attach thigh restraint to the leg your testing. Make sure thigh restraint is snug.
            </Typography>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      {/* actions */}

      <Actions step={step} subStep={subStep} handleNext={handleNext} handleCancel={handleCancel} />
    </>
  );
};

EquipmentSwap.propTypes = {
  step: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
  firstKnee: PropTypes.number,
};

export default EquipmentSwap;
