import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import TableFirstActivity from './TableFirstActivity';
import TableRomPainFirstActivity from './TableRomPainFirstActivity';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import useBoolean from 'hooks/useBoolean';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { dateParserWithTimezone } from 'helpers/datetime';
import { format } from 'date-fns';

const FirstActivityCompare = ({ onFetchFirstActivity, firstSelectedActivityId, currentPainLevel, currentMaxExtension, currentMaxFlexion, nearestActivity }) => {
  const { onOpen, onClose, open } = useBoolean(false);
  const activeNet = useSelector((state) => state.patients.activeNet);
  const activeTested = useSelector((state) => state.patients.activeTested);
  const dataFirstActivity = useSelector((state) => state.patients.dataFirstActivity);
  const currentDataActivity = useSelector((state) => state.patients.currentDataActivity);
  const dataFirstRomPain = useSelector((state) => state.patients.dataFirstRomPain);
  const listDataMachineSetup = useSelector((state) => state.patients.listDataMachineSetup);

  const formattedDate = (originalDate) => {
    if (!isNil(originalDate)) {
      const localDate = dateParserWithTimezone(originalDate);
      return format(localDate, "MMM d yyyy, hh:mm a");
    }
    return;
  };

  const nameOfTest = isNil(nearestActivity) ? 1 : 2;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: activeNet ? 1 : 0 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          {`Test Comparison ${nameOfTest}: ${formattedDate(dataFirstActivity?.initTime)} `}
        </Typography>
        <Box sx={{ ml: 1 }} onClick={onOpen}>
          <ModeEditIcon sx={{ cursor: 'pointer', color: '#000', fontSize: '22px' }} />
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '77%' }}>
          <TableFirstActivity onFetchFirstActivity={onFetchFirstActivity} onOpen={onOpen} onClose={onClose} open={open} activeNet={activeNet} activeTested={activeTested} dataFirstActivity={dataFirstActivity} currentDataActivity={currentDataActivity} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <TableRomPainFirstActivity currentPainLevel={currentPainLevel} currentMaxExtension={currentMaxExtension} currentMaxFlexion={currentMaxFlexion} firstSelectedActivityId={firstSelectedActivityId} activeNet={activeNet} activeTested={activeTested} dataFirstActivity={dataFirstActivity} dataFirstRomPain={dataFirstRomPain} listDataMachineSetup={listDataMachineSetup} />
        </Box>
      </Box>
    </Box>
  );
};

FirstActivityCompare.propTypes = {
  firstSelectedActivityId: PropTypes.any,
  onFetchFirstActivity: PropTypes.func,
  currentPainLevel: PropTypes.any,
  currentMaxExtension: PropTypes.any,
  currentMaxFlexion: PropTypes.any,
  nearestActivity: PropTypes.any
};

export default FirstActivityCompare;