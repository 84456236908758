import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

const InputDateContainer = styled('div')`
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 2px;
  }
  .css-151esv7-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: #d4d4d4;
  }
  .css-151esv7-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #d4d4d4;
  }
  .css-z6ef1u.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #d4d4d4;
  }
`;

export default function InputDate({ value, field, width, onChange }) {
  useEffect(() => {
    return () => {
      onChange(field, '');
    };
  }, []);
  const onChangeHandler = (value) => {
    const date = new Date(value);
    if (date instanceof Date && !isNaN(date)) {
      onChange(field, value);
      return;
    }
    onChange(field, '');
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        slotProps={{ textField: { variant: 'filled' } }}
        onChange={onChangeHandler}
        renderInput={(params) => (
          <InputDateContainer>
            <TextField InputLabelProps={{ shrink: false }} sx={{ width: width }} margin='none' {...params} />
          </InputDateContainer>
        )}
      />
    </LocalizationProvider>
  );
}

InputDate.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.number,
};
