import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import weightStack from 'assets/images/png/dynamic-test/2.png';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
import { allowIntNumber } from 'helpers';

export default function MachineSetup({
  step,
  handleCancel,
  handleBack,
  handleNext,
  onDataChange,
  value,
  disableFirstStep,
  subStep,
}) {
  const defaultValues = {
    weight: '',
  };

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  const [stepData, setStepData] = useState(defaultValues.weight);

  useEffect(() => {
    if (value) {
      setValue('weight', value.weight !== null ? value.weight : '');
      setStepData(value.weight !== null ? value.weight : '');
    }
  }, [value]);

  const handleInputChange = (event, onchange) => {
    if (parseInt(event.target.value, 10) > 1000) {
      setStepData(1000);
      onchange(1000);
    } else {
      onchange(event);
      setStepData(event.target.value);
    }
  };

  const handleNextStep = () => {
    onDataChange({
      weight: stepData,
    });

    handleNext();
  };
  return (
    <>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box>
          <SingleImageStep img={weightStack} />
        </Box>

        <Box>
          <Typography variant='body1' component='div'>
            1. The initial exercise weight (work load) is typically determined by calculating 50% of the peak torque
            from the initial Isometric test, if they completed and Isometric test. This is the default value below.
            <br />
            2. Select the weight on the weight stack.
            <br />
            3. Tighten chain tightener. Caution: Do not over tighten!
          </Typography>
          <Box sx={{ padding: '20px 0' }}>
            <b>Manually enter weight, and press enter.</b>
            <Box sx={{ maxWidth: 200 }}>
              <Controller
                name='weight'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowIntNumber(e);
                    }}
                    sx={{ mt: 1 }}
                    error={!!errors.weight}
                    placeholder='--'
                    margin='normal'
                    id='weight'
                    value={value}
                    onChange={(event) => {
                      handleInputChange(event, onChange);
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNextStep}
        disableBack={disableFirstStep}
      />
    </>
  );
}

MachineSetup.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  onDataChange: PropTypes.func,
  value: PropTypes.object,
  disableFirstStep: PropTypes.bool,
};
