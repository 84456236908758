import { createSlice } from '@reduxjs/toolkit';
import { DIALOGS } from 'modules/Dialogs/constants';
import { clearStoreError } from 'modules/Dialogs/actions';

export const DialogSlice = createSlice({
  name: 'dialogSlice',
  initialState: {
    fromNavbar: false,
    [DIALOGS.CHANGE_PASSWORD]: false,
    [DIALOGS.CHANGE_PROFILE]: false,
    [DIALOGS.CHANGE_HCP_USER_PROFILE]: false,
    [DIALOGS.INVITE_USER]: false,
    [DIALOGS.CONFIRM_INVITE_USER]: false,
    [DIALOGS.CONFIRM_DELETE_DIALOG]: false,
    [DIALOGS.CONFIRM_SUSPEND_DIALOG]: false,
    [DIALOGS.CONFIRM_RESTORE_DIALOG]: false,
    [DIALOGS.INVITE_CUSTOMER]: false,
    [DIALOGS.CONFIRM_DELETE_CUSTOMER_DIALOG]: false,
    [DIALOGS.CONFIRM_SUSPEND_CUSTOMER_DIALOG]: false,
    [DIALOGS.CONFIRM_RESTORE_CUSTOMER_DIALOG]: false,
    [DIALOGS.INVITE_HCP_USERS_DIALOG]: false,
    [DIALOGS.CONFIRM_DELETE_HCP_USER_DIALOG]: false,
    [DIALOGS.CONFIRM_ARCHIVE_HCP_USER_DIALOG]: false,
    [DIALOGS.CONFIRM_ARCHIVE_PATIENT_DIALOG]: false,
    [DIALOGS.CONFIGURE_MACHINE_DIALOG]: false,
    [DIALOGS.ASSIGN_MACHINE_DIALOG]: false,
    [DIALOGS.ADD_MACHINE_DIALOG]: false,
    [DIALOGS.CONFIRM_REMOVE_MACHINE_DIALOG]: false,
    [DIALOGS.ADD_PATIENT_DIALOG]: false,
    [DIALOGS.EDIT_PATIENT_DIALOG]: false,
    [DIALOGS.SEARCH_PATIENT_DIALOG]: false,
    [DIALOGS.DEBUG_MACHINE_DIALOG]: false,
    [DIALOGS.NEW_ROLE]: false,
    [DIALOGS.CONFIRM_REMOVE_ROLE_DIALOG]: false,
  },
  reducers: {
    requestDialog: () => {},
    requestDialogSuccess: (state, action) => {
      state.fromNavbar = action.payload.fromNavbar;
      state[action.payload.type] = action.payload.open;
    },
    // request close all Dialogs
    requestCloseAllDialogs: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = false;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStoreError, () => {});
  },
});
export const { requestDialog, requestDialogSuccess, requestCloseAllDialogs } = DialogSlice.actions;

export default DialogSlice.reducer;
