import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';

import calveRestraint from 'assets/images/knee/calve restraint..svg';
import SeatBeltAdjustment from 'assets/images/knee/Seat belt adjustment.svg';
import LegThighRestraint from 'assets/images/knee/Leg-Thigh restraint.svg';
const instructions = [
  {
    id: 1,
    text: '',
    img: calveRestraint,
  },
  {
    id: 2,
    text: '',
    img: SeatBeltAdjustment,
  },
  {
    id: 3,
    text: '',
    img: LegThighRestraint,
  },
];
const Restraints = ({ step, subStep, handleCancel, handleBack, handleNext }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[0]])}</Box>
            <Typography variant='body1' component='div'>
              1. Attach calf restraint to involved leg.
              <br />
              2. Calf restraint should be tightened and snugly as possible. Avoid over tightening which might restrict
              blood flow.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[1]])}</Box>
            <Typography variant='body1' component='div'>
              3. Have patient fasten the seatbelt.
              <br />
              4. With the seatbelt adjustment, tighten the seatbelt to restrict hip and upper body movement. Turn the
              crank clockwise to tighten the seatbelt, counterclockwise to loosen the seatbelt.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[2]])}</Box>
            <Typography variant='body1' component='div'>
              5. Attach thigh restraint. Make sure thigh restraint is snug.
            </Typography>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      {/* actions */}

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
};

Restraints.propTypes = {
  step: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default Restraints;
