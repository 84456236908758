import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useEffect, useRef, useState } from 'react';
import DynamicRechart from 'components/Charts/DynamicReChart';
import { useParams, useSearchParams } from 'react-router-dom';
import { putActivityNote, resetActivityProperties } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'modules/Patients/components/Machines/Actions';
import { generateYAxisArray } from 'components/Charts/utils';
import pip from 'assets/audio/bip.mp3';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import { openDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import ActivityQuestion from 'modules/Patients/containers/Machines/ActivityQuestion/index.jsx';
import FlipSwitch from 'components/FlipSwitch';
import isNil from 'lodash/isNil';

const DynamicExercise = ({
  step,
  handleCancel,
  handleBack,
  handleNext,
  setupMachineData,
  machine,
  value,
  onDataChange,
  subStep,
  sessionId,
}) => {
  const socketContext = useSocketDataContext();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { id } = useParams();
  const a = useRef(null);
  const active = useRef(0);

  const [readyForNext, setReadyForNext] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isExtension, setExtension] = useState(false);
  const timeCounter = useRef(null);
  const [pounds, setPounds] = useState(0);
  const [disableNext, setDisableNext] = useState(true);
  const putActivityError = useSelector((state) => state.patients.putActivityError);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);
  const [x, setX] = useState(null);
  const [point, setPoint] = useState(0);

  const [data, setData] = useState([]);
  const [time, setTime] = useState(0);
  const [reps, setReps] = useState(0);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);

  useEffect(() => {
    active.current = 0;
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    return () => {
      socketContext.clearMessageHistory();
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, [step]);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (value.data.length === 0 && value.time === 0) {
      if (timeCounter.current) {
        clearInterval(timeCounter.current);
        timeCounter.current = null;
      }
      setPounds(0);
      setDisableNext(true);
      setTime(0);
      setReps(0);
      setData([]);
      if (value.weight !== null) {
        setYAxis(generateYAxisArray(value.weight, 6));
      }
      if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
        requestStartExercise();
      }
      return null;
    }

    setPounds(Math.abs(value.pounds));
    setDisableNext(false);
    setTime(value.time);
    setReps(value.reps);
    setData(value.data);
  }, [value]);
  useEffect(() => {
    if (isNext && readyForNext && !isUpdatingActivity) {
      socketContext.clearMessageHistory();
      dispatch(resetActivityProperties());
      handleNext();
    }
  }, [readyForNext, isNext, isUpdatingActivity]);

  useEffect(() => {
    if (putActivityError === false && isUpdatingActivity === false) {
      active.current = 0;
      if (timeCounter.current) {
        clearInterval(timeCounter.current);
        timeCounter.current = null;
      }
      onDataChange({
        data,
        time,
        reps,
        pounds,
      });

      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }
    if (putActivityError && isUpdatingActivity === false) {
      setIsNext(false);
    }
  }, [putActivityError, isUpdatingActivity]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};

    setReadyForNext(message?.saved === undefined ? true : message?.saved);

    if (!message || (message && Object.keys(message).length === 0) || !active.current) {
      return;
    }

    if (!message?.angle_degrees || (message?.angle_degrees && !message.angle_degrees.length === 0)) {
      return;
    }
    if (!message?.pounds || (message?.pounds && message.pounds.length === 0)) {
      return;
    }
    if (timeCounter.current === null) {
      timeCounter.current = setInterval(() => {
        setTime((prevState) => {
          return prevState + 1;
        });
      }, 1000);
    }

    const index = message.angle_degrees.length - 1;
    setPounds(Math.abs(message.pounds[index]));
    setReps(message.reps);

    let newValue;

    let degree = message.angle_degrees[index];
    if (degree > 126) {
      degree = 126;
    }
    if (degree < 0) {
      degree = 0;
    }

    if (message?.is_flexion) {
      setExtension(false);
      newValue = {
        name: degree,
        flexion: Math.abs(message.pounds[index]),
      };
    } else {
      setExtension(true);
      newValue = {
        name: degree,
        extension: Math.abs(message.pounds[index]),
      };
    }

    setX(degree);

    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      setData((prevState) => {
        return [newValue, ...prevState];
      });
    }

    if (message.done || message.signal === 'PONG') {
      setDisableNext(false);
      clearInterval(timeCounter.current);
    }
  }, [socketContext.messageHistory]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    setPoint(data[0].name);
  }, [isExtension]);

  useEffect(() => {
    if (x === null || !disableNext || !data.length || !setupMachineData) {
      return;
    }

    if (point <= setupMachineData.range_motion_min - 3) {
      if (x >= setupMachineData.range_motion_min - 3) {
        a.current.play();
        setPoint(x);
        return;
      }
    }
    if (point >= setupMachineData.range_motion_min + 3) {
      if (x <= setupMachineData.range_motion_min + 3) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point >= setupMachineData.range_motion_max + 3) {
      if (x <= setupMachineData.range_motion_max + 3) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point <= setupMachineData.range_motion_max - 3) {
      if (x >= setupMachineData.range_motion_max - 3) {
        a.current.play();
        setPoint(x);
      }
    }
  }, [x, point, isExtension]);

  const requestStartExercise = () => {
    const activityId = localStorage.getItem('activity_id');
    if (!activityId) {
      return null;
    }

    if (!setupMachineData) {
      return null;
    }
    if (value.angle === undefined) {
      return null;
    }
    active.current = 1;

    // add filter socket here
    socketContext.sendJsonMessage({
      request: socketActions.DYNAMIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: sessionId,
      activity_id: activityId,
      target_angle: !isNil(setupMachineData.range_motion_max) ? setupMachineData.range_motion_max : 0,
      min_angle: !isNil(setupMachineData.range_motion_min) ? setupMachineData.range_motion_min : 0,
      weight: !isNil(value.weight) ? value.weight : '0',
    });
  };

  const backHandler = () => {
    active.current = 0;
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    setReps(0);
    setPounds(0);
    setTime(0);
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
    handleBack();
  };

  const nextHandler = () => {
    openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
  };

  const finishQuestionHandler = (question) => {
    const hcpId = localStorage.getItem('hcpId');
    const activityId = localStorage.getItem('activity_id');
    const payload = {
      ...{
        test_duration: time,
      },
      ...question,
    };

    dispatch(
      putActivityNote({
        hcpId,
        patientId: atob(id),
        sessionId: sessionId,
        activitiesId: activityId,
        data: payload,
      })
    );
    setIsNext(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box>
          <Typography variant='body1' component='div'>
            1. Instruct the patient to slowly begin exerting backward force against the resistance pad.
            <br />
            2. <FlipSwitch /> - When the patient begins to start the exercise and exert force.
            <br />
            3. Remind the patient to exercise through their full ROM and maintain proper speed.
            <br />
            4. When unable to complete another repetition, have the patient return to the fully flexed position.
            <br />
            5. <FlipSwitch /> - to end exercise.
            <br />
            6. Lock movement arm (to up position). Disengage the angle selector. Move the patient to a comfortable
            upright position, engage the angle selector and loosen restraints.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
          <DynamicRechart
            isShowAxisLabel={true}
            isCervical={true}
            activeLine={disableNext ? (isExtension ? 'extension' : 'flexion') : ''}
            xTicks={[18, 36, 54, 72, 90, 108, 126]}
            tooltip={false}
            yTicks={yAxis}
            data={data}
          />
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '0 35px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Torque (lbf-in): `} </span> {pounds}
            </div>
          </Box>
        </Box>
      </Box>
      <audio style={{ visibility: 'hidden', height: '1px' }} className='my_audio' ref={a} controls preload='none'>
        <source src={pip} type='audio/mpeg' />
      </audio>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={backHandler}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        disableNext={disableNext}
      />
      <ActivityQuestion onFinish={finishQuestionHandler} />
    </Box>
  );
};

DynamicExercise.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  value: PropTypes.object,
  sessionId: PropTypes.string,
};

export default DynamicExercise;
