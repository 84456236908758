import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import socketActions from 'components/WebSocketProvider/constants.js';

import { useEffect, useRef, useState } from 'react';
import DynamicRechart from '../../../../../../../components/Charts/DynamicReChart';
import { useParams, useSearchParams } from 'react-router-dom';
import { putActivityNote, resetActivityProperties } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'modules/Patients/components/Machines/Actions';
import { MACHINE_TESTING_ANGLE } from '../../../../../constants';
import { generateYAxisArray } from 'components/Charts/utils';
import pip from 'assets/audio/bip.mp3';
import { openDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import ActivityQuestion from 'modules/Patients/containers/Machines/ActivityQuestion/index.jsx';
import FlipSwitch from 'components/FlipSwitch';
import isNil from 'lodash/isNil';

const DynamicExercise = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  setupMachineData,
  machine,
  onDataChange,
  firstSide,
  rightSide,
  leftSide,
  rangeOfMotionMode,
  socketContext,
  sessionId,
  testMode,
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isExtension, setExtension] = useState(false);
  const timeCounter = useRef(null);
  const a = useRef(null);
  const active = useRef(0);
  const [pounds, setPounds] = useState(0);
  const [disableNext, setDisableNext] = useState(true);
  const [target, setTarget] = useState(undefined);
  const putActivityError = useSelector((state) => state.patients.putActivityError);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);
  const [rangeMotionMin, setRangeMotionMin] = useState(undefined);
  const [rangeMotionMax, setRangeMotionMax] = useState(undefined);
  const [x, setX] = useState(null);
  const [point, setPoint] = useState(0);
  const [readyForNext, setReadyForNext] = useState(false);
  const [isNext, setIsNext] = useState(false);

  const [data, setData] = useState([]);
  const [isReview, setIsReview] = useState(false);
  const [weight, setWeight] = useState('');
  const [time, setTime] = useState(0);
  const [reps, setReps] = useState(0);
  const [currentSide, setCurrentSide] = useState(0);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);

  useEffect(() => {
    active.current = 0;
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    return () => {
      socketContext.clearMessageHistory();
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, [step]);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    switch (firstSide) {
      case 1:
        setCurrentSide(Number(step) === 4 ? 1 : 2);

        break;

      case 2:
        setCurrentSide(Number(step) === 4 ? 2 : 1);

        break;
    }

    if (rangeOfMotionMode === 1) {
      if (Math.abs(setupMachineData.range_motion_min) > Math.abs(setupMachineData.range_motion_max)) {
        setRangeMotionMin(-(setupMachineData.range_motion_max - 6));
        setRangeMotionMax(setupMachineData.range_motion_max - 6);
      } else {
        setRangeMotionMin(setupMachineData.range_motion_min + 6);
        setRangeMotionMax(Math.abs(setupMachineData.range_motion_min + 6));
      }

      return;
    }

    setRangeMotionMin(setupMachineData.range_motion_min + 6);
    setRangeMotionMax(setupMachineData.range_motion_max - 6);
  }, [setupMachineData, firstSide, step, subStep]);

  useEffect(() => {
    if (!currentSide) {
      return;
    }
    if (currentSide === 1) {
      if (leftSide.dynamicData.length === 0 && leftSide.time === 0) {
        if (timeCounter.current) {
          clearInterval(timeCounter.current);
          timeCounter.current = null;
        }
        setPounds(0);
        setDisableNext(true);
        setTime(0);
        setReps(0);
        setData([]);
        setIsReview(false);
      } else {
        setData(leftSide.dynamicData);
        setPounds(Math.abs(leftSide.torq));
        setDisableNext(false);
        setReps(leftSide.reps);
        setTime(leftSide.time);
        setIsReview(true);
      }
      setWeight(leftSide.weight);
      setTarget(leftSide.angle);
      setYAxis(generateYAxisArray(leftSide.weight, 6));
    }

    if (currentSide === 2) {
      if (rightSide.dynamicData.length === 0 && rightSide.time === 0) {
        if (timeCounter.current) {
          clearInterval(timeCounter.current);
          timeCounter.current = null;
        }
        setPounds(0);
        setDisableNext(true);
        setTime(0);
        setReps(0);
        setData([]);
        setIsReview(false);
      } else {
        setData(rightSide.dynamicData);
        setPounds(Math.abs(rightSide.torq));
        setDisableNext(false);
        setReps(rightSide.reps);
        setTime(rightSide.time);
        setIsReview(true);
      }
      setWeight(rightSide.weight);
      setTarget(rightSide.angle);
      setYAxis(generateYAxisArray(rightSide.weight, 6));
    }
  }, [leftSide, rightSide, currentSide]);

  useEffect(() => {
    if (putActivityError === false && isUpdatingActivity === false) {
      active.current = 0;
      if (timeCounter.current) {
        clearInterval(timeCounter.current);
        timeCounter.current = null;
      }

      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }
    if (putActivityError && isUpdatingActivity === false) {
      setIsNext(false);
    }
  }, [putActivityError, isUpdatingActivity]);

  useEffect(() => {
    if (isNext && readyForNext && !isUpdatingActivity) {
      socketContext.clearMessageHistory();
      dispatch(resetActivityProperties());
      onDataChange(
        {
          dynamicData: data,
          time,
          reps,
          torq: pounds,
        },
        firstSide
      );
      handleNext();
    }
  }, [readyForNext, isNext, isUpdatingActivity]);
  useEffect(() => {
    if (target === undefined) {
      return;
    }
    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      requestStartExercise();
    }
  }, [target]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};

    setReadyForNext(message?.saved === undefined ? true : message?.saved);
    if (!message || (message && Object.keys(message).length === 0) || !active.current) {
      return;
    }
    if (!message?.angle_degrees || (message?.angle_degrees && message.angle_degrees.length === 0)) {
      return;
    }
    if (!message?.pounds || (message?.pounds && message.pounds.length === 0)) {
      return;
    }
    if (timeCounter.current === null) {
      timeCounter.current = setInterval(() => {
        setTime((prevState) => {
          return prevState + 1;
        });
      }, 1000);
    }

    const index = message.angle_degrees.length - 1;
    setPounds(Math.abs(message.pounds[index]));

    setReps(message.reps);

    let newValue;

    let currValue = message.angle_degrees[index];
    if (Math.abs(message.angle_degrees[index]) > 60) {
      if (message.angle_degrees[index] < 0) {
        currValue = -60;
      } else {
        currValue = 60;
      }
    }
    setX(currValue);

    if (message?.is_flexion) {
      setExtension(false);
      newValue = {
        name: currValue,
        flexion: Math.abs(message.pounds[index]),
      };
    } else {
      setExtension(true);
      newValue = {
        name: currValue,
        extension: Math.abs(message.pounds[index]),
      };
    }

    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      setData((prevState) => {
        return [newValue, ...prevState];
      });
    }

    if (message.done || message.signal === 'PONG') {
      setDisableNext(false);
      clearInterval(timeCounter.current);
    }
  }, [socketContext.messageHistory]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    setPoint(data[0].name);
  }, [isExtension]);

  useEffect(() => {
    if (x === null || !disableNext || !data.length || !setupMachineData) {
      return;
    }

    if (point <= rangeMotionMin - 6) {
      if (x >= rangeMotionMin - 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }
    if (point >= rangeMotionMin + 6) {
      if (x <= rangeMotionMin + 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point >= rangeMotionMax + 6) {
      if (x <= rangeMotionMax + 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point <= rangeMotionMax - 6) {
      if (x >= rangeMotionMax - 6) {
        a.current.play();
        setPoint(x);
      }
    }
  }, [x, point, isExtension]);

  const requestStartExercise = () => {
    const activityId = localStorage.getItem('activity_id');

    if (!activityId) {
      return null;
    }
    if (!activityId) {
      return null;
    }
    if (!setupMachineData) {
      return null;
    }
    active.current = 1;
    socketContext.sendJsonMessage({
      request: socketActions.DYNAMIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: sessionId,
      activity_id: activityId,
      target_angle: !isNil(rangeMotionMax) ? rangeMotionMax : 0,
      min_angle: !isNil(rangeMotionMin) ? rangeMotionMin : 0,
      weight: !isNil(value.weight) ? value.weight : '0',
      torso_test_mode: currentSide,
    });
  };

  const backHandler = () => {
    active.current = 0;
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    setReps(0);
    setPounds(0);
    setTime(0);
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
    handleBack();
  };

  const nextHandler = () => {
    if (isReview) {
      handleNext();
      return null;
    }
    if (testMode === 1 || testMode === 2) {
      openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
      return null;
    }

    if (Number(step) === 5) {
      openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
      return null;
    }
    finishQuestionHandler({});
  };
  const finishQuestionHandler = (question) => {
    const hcpId = localStorage.getItem('hcpId');
    const activityId = localStorage.getItem('activity_id');
    let data;
    if (currentSide === 1) {
      data = {
        test_duration_left: time,
      };
    } else {
      data = {
        test_duration_right: time,
      };
    }
    data = { ...data, ...question };

    dispatch(
      putActivityNote({
        hcpId,
        patientId: atob(id),
        sessionId: sessionId,
        activitiesId: activityId,
        data,
      })
    );
    setIsNext(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box>
          <Typography variant='body1' component='div'>
            1. Instruct the patient to slowly begin exerting rotational force against the torso pad.
            <br />
            2. <FlipSwitch /> - When the patient begins to start the exercise and exert force.
            <br />
            3. Remind the patient to exercise through their full ROM and maintain proper speed.
            <br />
            4. Have patient return to fully rotated position.
            <br />
            5. When unable to complete another repetition, have the patient return to the fully flexed position.
            <br />
            6. <FlipSwitch /> - to end exercise.
            <br />
            7. Lock movement arm, then disengage angle selector and move to 0°.
            <br />
            8. Engage angle selector and quickly loosen torso and footboard restraint.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
          <Box sx={{ textAlign: 'center' }}>
            <b>{currentSide === 1 ? 'Left' : 'Right'} Side</b>{' '}
          </Box>

          <DynamicRechart
            activeLine={disableNext ? (isExtension ? 'extension' : 'flexion') : ''}
            xTicks={MACHINE_TESTING_ANGLE.ROTARY_TORSO_TESTING_ANGLE}
            tooltip={false}
            yTicks={yAxis}
            data={data}
            balanceMode={true}
          />
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '0 35px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Torq: `} </span> {pounds}
            </div>
          </Box>
        </Box>
      </Box>
      <audio style={{ visibility: 'hidden', height: '1px' }} className='my_audio' ref={a} controls preload='none'>
        <source src={pip} type='audio/mpeg' />
      </audio>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableNext={disableNext}
        handleCancel={handleCancel}
      />
      <ActivityQuestion onFinish={finishQuestionHandler} />
    </Box>
  );
};

DynamicExercise.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  value: PropTypes.array,
  firstSide: PropTypes.number,

  rightSide: PropTypes.object,
  leftSide: PropTypes.object,
  rangeOfMotionMode: PropTypes.number,
  socketContext: PropTypes.object,
  sessionId: PropTypes.string,
};

export default DynamicExercise;
