import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DynamicRechart from '../../../../../../../components/Charts/DynamicReChart';
import Actions from 'modules/Patients/components/Machines/Actions';
import { MACHINE_TESTING_ANGLE } from '../../../../../constants';
import { generateYAxisArray } from 'components/Charts/utils';

const OneSideSummary = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  setupMachineData,
  rightSide,
  leftSide,
  firstSide,
}) => {
  const [pounds, setPounds] = useState(0);

  const [data, setData] = useState([]);
  const [time, setTime] = useState(0);
  const [reps, setReps] = useState(0);
  const [leftRom, setLeftRom] = useState(null);
  const [rightRom, setRightRom] = useState(null);
  const [seatPosition, setSeatPosition] = useState(null);
  const [currentSide, setCurrentSide] = useState(null);
  const [gatePos, setGatePos] = useState(null);
  const [footboardPosition, setFootboardPosition] = useState(0);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);

  useEffect(() => {
    if (!setupMachineData || !firstSide || !rightSide || !leftSide) {
      return;
    }

    setLeftRom(setupMachineData.range_motion_max);
    setRightRom(setupMachineData.range_motion_min);
    setFootboardPosition(setupMachineData.temporal_adjustment_pos);
    setGatePos(setupMachineData.seat_pad);
    if (firstSide === 1) {
      setCurrentSide(1);
    } else if (firstSide === 2) {
      setCurrentSide(2);
    }
    setSeatPosition(setupMachineData.seat_pos);
  }, [setupMachineData, firstSide, rightSide, leftSide]);

  useEffect(() => {
    if (!currentSide) {
      return;
    }

    if (currentSide === 1) {
      if (leftSide.time === 0) {
        setPounds(0);
        setTime(0);
        setReps(0);
        setData([]);
      } else {
        setData(leftSide.dynamicData);
        setPounds(Math.abs(leftSide.torq));
        setReps(leftSide.reps);
        setTime(leftSide.time);
      }
      if (leftSide.weight !== null) {
        setYAxis(generateYAxisArray(leftSide.weight, 6));
      }
    }

    if (currentSide === 2) {
      if (rightSide.time === 0) {
        setPounds(0);
        setTime(0);
        setReps(0);
        setData([]);
      } else {
        setData(rightSide.dynamicData);
        setPounds(Math.abs(rightSide.torq));
        setReps(rightSide.reps);
        setTime(rightSide.time);
      }
      if (rightSide.weight !== null) {
        setYAxis(generateYAxisArray(rightSide.weight, 6));
      }
    }
  }, [currentSide]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
          <Box sx={{ textAlign: 'center' }}>
            {' '}
            <b>{currentSide === 1 ? 'Left' : 'Right'} Side</b>
          </Box>

          <DynamicRechart
            xTicks={MACHINE_TESTING_ANGLE.CERVICAL_ROTATION_TESTING_ANGLE}
            tooltip={false}
            yTicks={yAxis}
            data={data}
            balanceMode={true}
          />
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '0 35px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Torq: `} </span> {pounds}
            </div>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '0 35px' }}>
            <b style={{ fontSize: '18px' }}>{currentSide === 1 ? 'LEFT' : 'RIGHT'} SIDE</b>
            <span> Max Left ROM: {leftRom !== null ? `${leftRom}°` : 'N/A'}</span>
            <span> Max Right ROM: {rightRom !== null ? `${rightRom}°` : 'N/A'}</span>
            <span> Back Pad: {gatePos !== null ? `${gatePos}` : 'N/A'}</span>
            <span> Temporal Pad Position: {footboardPosition !== null ? `${footboardPosition}` : 'N/A'}</span>
            <span> Seat Position: {seatPosition !== null ? `${seatPosition}` : 'N/A'}</span>
          </Box>
        </Box>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </Box>
  );
};

OneSideSummary.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  value: PropTypes.array,
  firstSide: PropTypes.number,

  rightSide: PropTypes.object,
  leftSide: PropTypes.object,
};

export default OneSideSummary;
