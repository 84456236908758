import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import pip from 'assets/audio/bip.mp3';
import { useEffect, useRef, useState } from 'react';
import socketActions from 'components/WebSocketProvider/constants.js';
import DynamicRechart from 'components/Charts/DynamicReChart';
import Actions from 'modules/Patients/components/Machines/Actions';
import { generateYAxisArray } from 'components/Charts/utils';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import FlipSwitch from 'components/FlipSwitch';
import isNil from 'lodash/isNil';

export default function Exercise({
  setupMachineData,
  machine,
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  value,
  onDataChange,
}) {
  const socketContext = useSocketDataContext();
  const timeCounter = useRef(null);
  const [reps, setReps] = useState(0);
  const [time, setTime] = useState(0);
  const [pounds, setPounds] = useState(0);
  const [isExtension, setIsExtension] = useState(false);
  const [x, setX] = useState(null);
  const [point, setPoint] = useState(0);

  const [dataChart, setDataChart] = useState([]);
  const [isDisableControl, setDisable] = useState(false);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);

  const a = useRef(null);
  const isRequesting = useRef(false);

  useEffect(() => {
    if (!value) {
      return;
    }

    setDataChart(value['3'].dataChart);
    setReps(Number(value['3'].value));
    setPounds(Math.abs(Number(value['3'].pounds)));
    setTime(Number(value['3'].time));
    if (value['3']?.weight !== null) {
      setYAxis(generateYAxisArray(Number(value['3'].weight), 6));
    }
  }, [value]);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }
    if (Number(value['3'].isDone)) {
      return null;
    }
    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
        getData();
      }
    }
  }, [setupMachineData, value]);

  useEffect(() => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    return () => {
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
      socketContext.clearMessageHistory();
    };
  }, []);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (message.angle_degrees === undefined) {
      return;
    }

    if (timeCounter.current === null) {
      timeCounter.current = setInterval(() => {
        setTime((prevState) => {
          return prevState + 1;
        });
      }, 1000);
    }

    const index = message.angle_degrees.length - 1;

    setReps(message?.reps || 0);
    setPounds(Math.abs(message.pounds[index]));

    let newValue;

    let degree = message.angle_degrees[index];
    if (degree > 72) {
      degree = 72;
    }
    if (degree < 0) {
      degree = 0;
    }

    if (message?.is_flexion) {
      setIsExtension(false);

      newValue = {
        name: degree,
        flexion: Math.abs(message.pounds[index]),
      };
    } else {
      setIsExtension(true);

      newValue = {
        name: degree,
        extension: Math.abs(message.pounds[index]),
      };
    }

    setX(degree);

    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      setDataChart((prevState) => {
        return [newValue, ...prevState];
      });
    }

    if (message.done) {
      clearInterval(timeCounter.current);
      setDisable(false);
      isRequesting.current = false;
    }
  }, [socketContext.messageHistory]);

  useEffect(() => {
    if (dataChart.length === 0) {
      return;
    }

    setPoint(dataChart[0].name);
  }, [isExtension]);

  useEffect(() => {
    if (x === null || !isDisableControl || !dataChart.length || !setupMachineData) {
      return;
    }

    if (point <= setupMachineData.range_motion_min - 3) {
      if (x >= setupMachineData.range_motion_min - 3) {
        a.current.play();
        setPoint(x);
        return;
      }
    }
    if (point >= setupMachineData.range_motion_min + 3) {
      if (x <= setupMachineData.range_motion_min + 3) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point >= setupMachineData.range_motion_max + 3) {
      if (x <= setupMachineData.range_motion_max + 3) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point <= setupMachineData.range_motion_max - 3) {
      if (x >= setupMachineData.range_motion_max - 3) {
        a.current.play();
        setPoint(x);
      }
    }
  }, [x, point, isExtension]);

  const getData = () => {
    setDisable(true);
    if (isRequesting.current) {
      return;
    }
    if (!machine) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.DYNAMIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: '',
      activity_id: '',
      target_angle: !isNil(setupMachineData.range_motion_max) ? setupMachineData.range_motion_max : 0,
      min_angle: !isNil(setupMachineData.range_motion_min) ? setupMachineData.range_motion_min : 0,
      weight: '',
    });
    isRequesting.current = true;
  };

  const nextHandler = () => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    onDataChange({
      step: 2,
      subStep: 4,
      value: reps,
      time,
      pounds,
      dataChart,
      isDone: true,
    });
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    handleNext();
  };
  const backHandler = () => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    handleBack();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant='body1' component='div'>
          1. Instruct the patient to slowly begin exerting backward force against the resistance pad.
          <br />
          2. <FlipSwitch /> - When the patient begins to start the exercise and exert force.
          <br />
          3. Remind the patient to exercise through their full ROM and maintain proper speed.
          <br />
          4. Have patient perform a dynamic exercise warm-up for 6-8 reps.
          <br />
          5. <FlipSwitch /> - to end exercise.
          <br />
          6. Lock movement arm. Move the patient to a comfortable upright position, engage the angle selector and loosen
          restraints.
        </Typography>
        <div>
          <DynamicRechart
            isShowAxisLabel={true}
            isLumbar={true}
            activeLine={isDisableControl ? (isExtension ? 'extension' : 'flexion') : ''}
            tooltip={false}
            data={dataChart}
            yTicks={yAxis}
          />
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '10px 50px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Torque (lbf-ft): `} </span> {pounds}
            </div>
          </Box>
        </div>
        <audio style={{ visibility: 'hidden', height: '1px' }} className='my_audio' ref={a} controls preload='none'>
          <source src={pip} type='audio/mpeg' />
        </audio>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableNext={isDisableControl}
      />
    </Box>
  );
}

Exercise.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
};
