import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getActivityQuestionLabel } from 'helpers';
import { Divider } from '@mui/material';
import isNil from 'lodash/isNil';

export default function MachineSetupAndPatientHealthInfo({ setupData, value }) {

  const dataRender = (data) => {
    return (
      <Box sx={{ display: 'flex', padding: '5px 0', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '40%', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: 600, fontSize: '14px', color: '#868E96' }} component='div'>
            {data[0].title}
          </Typography>
          <Typography sx={{ wordBreak: 'break-all', fontSize: '14px', fontWeight: 400 }} variant='p' component='div'>
            {data[0].value ?? 0}
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', width: '40%', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: 600, fontSize: '14px', color: '#868E96' }} component='div'>
            {data[1].title}
          </Typography>

          <Typography sx={{ wordBreak: 'break-all', fontSize: '14px', fontWeight: 400 }} variant='p' component='div'>
            {data[1].value ?? 0}
          </Typography>
        </div>
      </Box>
    );
  };

  const seatRender = () => {
    if (!setupData || (setupData && !setupData.data)) {
      return null;
    }

    if (setupData.machine_type === 'Cervical') {
      return dataRender([
        { value: setupData.data.seat_pos, title: 'Seat Height' },
        { value: setupData.data.seat_pad, title: 'Seat Pad' },
      ]);
    }

    if (setupData.machine_type === 'Lumbar') {
      return dataRender([
        { value: setupData.data.femur_restraint, title: 'Femur Restraint Position' },
        { value: setupData.data.seat_pad, title: 'Seat Pad' },
      ]);
    }
    return null;
  };

  const renderValue = (value) => {
    if (isNil(value)) {
      return `null°`;
    }
    return `${value}°`;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '50%' }}>
        <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
          <Typography sx={{ wordBreak: 'break-all', fontSize: '18px', color: '#343A40', fontWeight: 600 }} variant='p' component='div'>
            Machine Setup Information
          </Typography>
        </Box>

        {seatRender()}

        {dataRender(
          [
            { value: renderValue(setupData.data.range_motion_min), title: 'Max Extension Angle' },
            { value: renderValue(setupData.data.range_motion_max), title: 'Max Flexion Angle' },
          ],
          '°'
        )}
        {dataRender([
          { value: renderValue(setupData.data.dead_center), title: 'TDC at Rest Angle' },
          { value: setupData.data.counterbalance_gauge, title: 'Counterweight Gauge Reading' },
        ])}
      </Box>
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ wordBreak: 'break-all', fontSize: '18px', color: '#343A40', fontWeight: 600, mt: 2 }} variant='p' component='div'>
          Patient Health Information
        </Typography>
        <Box sx={{ display: 'flex', padding: '10px 0', justifyContent: 'flex-start' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ wordBreak: 'break-all', fontWeight: 600, fontSize: '14px', color: '#868E96' }} component='div'>
              The subjective pain level
            </Typography>
            <Typography sx={{ wordBreak: 'break-all', fontSize: '14px', fontWeight: 400, ml: 9 }} variant='p' component='div'>
              {value &&
                (value.answer_1 === null ? 'N/A' : getActivityQuestionLabel('answer_1', value.answer_1))}
            </Typography>
          </div>

          {/* <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ wordBreak: 'break-all', fontWeight: 600, fontSize: '14px', color: '#868E96' }} component='div'>
            </Typography>

            <Typography sx={{ wordBreak: 'break-all', fontSize: '14px', fontWeight: 400 }} variant='p' component='div'>
            </Typography>
          </div> */}
        </Box>

      </Box>
      <Divider sx={{
        borderBottomWidth: '2px',
        borderColor: '#f5f5f5',
        mt: 2,
        width: '70%'
      }} />
    </Box>
  );
}

MachineSetupAndPatientHealthInfo.propTypes = {
  setupData: PropTypes.object,
  value: PropTypes.object,
};
