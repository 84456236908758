import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import noImage from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import { allowIntNumber } from 'helpers';
export default function PatientAdjustments({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  defaultValue,
  setData,
}) {
  const defaultValues = {
    weight: '',
  };

  const [stepData, setStepData] = useState(defaultValues.weight);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (defaultValue) {
      setValue('weight', defaultValue.weight !== null ? defaultValue.weight : '');
      setStepData(defaultValue.weight !== null ? defaultValue.weight : '');
    }
  }, [defaultValue]);

  const handleInputChange = (value) => {
    if (parseInt(value, 10) > 9999) {
      setStepData(9999);
      setValue('weight', 9999);
    } else {
      setStepData(value);
    }
  };

  const handleNextStep = () => {
    setData((preState) => ({ ...preState, weight: stepData }));
    handleNext();
  };

  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImage} />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Typography variant='body1' component='div'>
                1. Select a light weight on the weight stack for dynamic warm-up.
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <label>
                  <strong>Manually enter weight, and press enter.</strong>
                </label>
                <Controller
                  name='weight'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      onKeyPress={(e) => {
                        allowIntNumber(e);
                      }}
                      sx={{ mt: 1, width: '200px' }}
                      error={!!errors.weight}
                      placeholder='--'
                      margin='normal'
                      id='weight'
                      value={value}
                      onChange={(event) => {
                        onChange(event);
                        handleInputChange(event.target.value);
                      }}
                      inputProps={{
                        maxLength: 4,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>lbs</InputAdornment>,
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImage} />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Typography variant='body1' component='div'>
                2. Snug the footboard adjustment while patient squeezes (with knees) pad between their knees.
                <br />
                3. Snug the hip restraint.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImage} />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Typography variant='body1' component='div'>
                4. Snug the torso restraint.
              </Typography>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>

      <div>
        <Actions
          step={step}
          subStep={subStep}
          handleCancel={handleCancel}
          handleNext={handleNextStep}
          handleBack={handleBack}
        />
      </div>
    </>
  );
}

PatientAdjustments.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  defaultValue: PropTypes.object,
  setData: PropTypes.func,
  firstSide: PropTypes.number,
};
