import PropTypes from 'prop-types';
import { Surface } from 'recharts';

const SIZE = 32;

const viewBox = { x: 0, y: 0, width: SIZE, height: SIZE };
const svgStyle = { display: 'inline-block', verticalAlign: 'middle', marginRight: 4 };
const style = {
  fontSize: 14,
  color: '#1A1A1A',
  marginBottom: 4,
  marginTop: 4,
};
const CustomTooltip = ({ active, payload, label }) => {
  const arrNameRemove = ['range', 'rangeNormAbove', 'rangeNormBelow'];
  if (active && payload && payload.length) {
    const payloadFilter = payload?.filter((item) => !arrNameRemove.includes(item.name));
    return (
      <div className='custom-tooltip' key={label}>
        {payloadFilter.map((item, index) => {
          return <div className='label' key={`${item.label}-${index}`} style={style}>
            <Surface width={16} height={16} viewBox={viewBox} svgStyle={svgStyle}>
              <path
                stroke='none'
                fill={item.color}
                d={`M0,${SIZE / 8}h${SIZE}v${(SIZE * 3) / 4}h${-SIZE}z`}
                className='recharts-legend-icon'
              />
            </Surface>{' '}
            <span style={{ verticalAlign: 'top', marginLeft: 8 }}>{`${item.name}: ${item.value}`}</span>
          </div>
        }

        )}
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.any,
};

export default CustomTooltip;
