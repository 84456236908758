import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RedoIcon from '@mui/icons-material/Redo';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';

export default function Actions({
  step,
  subStep,
  handleNext,
  handleCancel,
  handleBack,
  disableControl,
  disableNext,
  handleUndo,
  disableRedo,
  disableBack,
  nextLoading,
}) {
  const { t } = useTranslation();

  const [redoLoading, setRedoLoading] = useState(false);
  const redoClickHandler = () => {
    setRedoLoading(true);
    handleUndo();

    setTimeout(() => {
      setRedoLoading(false);
    }, 2000);
  };

  return (
    <Box
      className={'action-control'}
      key={`${step}-${subStep}`}
      sx={{ display: 'flex', paddingTop: 5, justifyContent: 'space-between' }}
    >
      <Box>
        {handleBack && (
          <Button disabled={disableControl || disableBack} onClick={handleBack} variant='outlined'>
            {t('common:back')}
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {handleCancel && (
          <Button disabled={disableControl} sx={{ mr: 2 }} onClick={handleCancel} variant='outlined'>
            {t('common:cancel')}
          </Button>
        )}
        {handleUndo && (
          <LoadingButton
            sx={{ mr: 2 }}
            onClick={redoClickHandler}
            startIcon={<RedoIcon />}
            disabled={disableRedo || redoLoading}
            variant='outlined'
          >
            {t('common:redo')}
          </LoadingButton>
        )}

        {handleNext && (
          <LoadingButton
            loading={nextLoading}
            id={'next-activity-btn'}
            disabled={disableControl || disableNext}
            onClick={handleNext}
            variant='contained'
          >
            {t('common:next')}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}

Actions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  disableControl: PropTypes.bool,
  disableNext: PropTypes.bool,
  handleUndo: PropTypes.func,
  disableRedo: PropTypes.bool,
  disableBack: PropTypes.bool,
  nextLoading: PropTypes.bool,
};
