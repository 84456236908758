import MachineSetupView from 'modules/Patients/components/Machines/Torso/MachineSetup';

import { useMachineSetup } from 'modules/Patients/hooks/useMachineSetup';
import NoResults from 'modules/Patients/components/NoResults';
import MachineSetupContainer from 'modules/Patients/components/MachineSetupContainer';
export default function MachineSetup() {
  const {
    Provider,
    handleBack,
    handleNext,
    handleSaveData,
    handleSelectItem,
    isLoading,
    machine,
    registerNavigation,
    socketContext,
    patient,
    step,
    subStep,
    handleSetStep,
    handleSetSubStep,
    navigationItems,
  } = useMachineSetup();

  if (!patient || !machine) return <NoResults isLoading={isLoading} />;

  return (
    <Provider value={{ registerNavigation: registerNavigation, navigationItems }}>
      <MachineSetupContainer>
        <MachineSetupView
          patient={patient}
          machine={machine}
          handleSaveData={handleSaveData}
          handleNext={handleNext}
          handleBack={handleBack}
          handleSelectItem={handleSelectItem}
          step={step}
          subStep={subStep}
          handleSetStep={handleSetStep}
          handleSetSubStep={handleSetSubStep}
          socketContext={socketContext}
        />
      </MachineSetupContainer>
    </Provider>
  );
}
