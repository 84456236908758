import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValidBase64 } from 'helpers';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import IsometricTestView from 'modules/Patients/components/Machines/Cervical/IsometricTest';

import { fetchPatient, requestInitActivity } from 'modules/Patients/slice';
import { fetchHcpConfiguredMachines } from 'modules/Machines/slice';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import axios from 'axios';

// 1: means Parent 1
// children: 1-1, 1-2, 1-3,...
const ISOMETRIC_TEST = 1;
const defaultNavigationItems = [
  {
    id: '1',
    name: 'Patient Setup',
    hasSkip: true,
    children: [
      {
        id: '1-1',
        name: 'Instructions',
      },
      {
        id: '1-2',
        name: 'Top Dead Center',
      },
      {
        id: '1-3',
        name: 'Level and Lock',
      },
      {
        id: '1-4',
        name: 'Set Counterbalance',
      },
      {
        id: '1-5',
        name: 'Seating Position',
      },
      {
        id: '1-6',
        name: 'Restraints',
      },
    ],
  },
  {
    id: '2',
    name: 'Dynamic Warm-up',
    hasSkip: false,
    children: [
      {
        id: '2-1',
        name: 'Instructions',
      },
      {
        id: '2-2',
        name: 'Patient Adjustments',
      },
      {
        id: '2-3',
        name: 'Select Angle',
      },
      {
        id: '2-4',
        name: 'Exercise',
      },
    ],
  },
  {
    id: '3',
    name: 'Instruction for Patient',
    hasSkip: false,
  },
  {
    id: '4',
    name: 'Practice Test',
    hasSkip: true,
    children: [
      {
        id: '4-1',
        name: '3 Pre-Set Angles',
      },
      {
        id: '4-2',
        name: 'Instructions',
      },
      {
        id: '4-3',
        name: '1st Angle Test',
      },
      {
        id: '4-4',
        name: '2nd Angle Test',
      },
      {
        id: '4-5',
        name: '3rd Angle Test',
      },
      {
        id: '4-6',
        name: 'Practice Summary',
      },
    ],
  },
  {
    id: '5',
    name: 'Actual Test',
    hasSkip: false,
    children: [
      {
        id: '5-1',
        name: 'Pre-Set Angles',
      },
      {
        id: '5-2',
        name: 'Instructions',
      },
      {
        id: '5-3',
        name: 'First Angle Test',
        shortName: '1st Angle',
        value: 126,
        skipped: false,
      },
      {
        id: '5-4',
        name: 'Second Angle Test',
        shortName: '2nd Angle',
        value: 108,
        skipped: false,
      },
      {
        id: '5-5',
        name: 'Third Angle Test',
        shortName: '3rd Angle',
        value: 90,
        skipped: false,
      },
      {
        id: '5-6',
        name: 'Fourth Angle Test',
        shortName: '4th Angle',
        value: 72,
        skipped: false,
      },
      {
        id: '5-7',
        name: 'Fifth Angle Test',
        shortName: '5th Angle',
        value: 54,
        skipped: false,
      },
      {
        id: '5-8',
        name: 'Sixth Angle Test',
        shortName: '6th Angle',
        value: 36,
        skipped: false,
      },
      {
        id: '5-9',
        name: 'Seventh Angle Test',
        shortName: '7th Angle',
        value: 18,
        skipped: false,
      },
      {
        id: '5-10',
        name: 'Eighth Angle Test',
        shortName: '8th Angle',
        value: 0,
        skipped: false,
      },
      {
        id: '5-11',
        name: 'Actual Test Summary',
      },
    ],
  },
];

export default function IsometricTest() {
  const patient = useSelector((state) => state.patients.patient);
  const machines = useSelector((state) => state.machines.hcpConfiguredMachines);
  const socketContext = useSocketDataContext();
  const { isLoading } = useSelector((state) => state.ui);
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState('1');
  const [subStep, setSubStep] = useState('1');
  const [machine, setMachine] = useState(null);
  const [navigationItems, setNavigationItems] = useState([]);
  const [defaultAngles, setDefaultAngles] = useState([]);
  const [isFirstStepSkipped, setSkipFirstStep] = useState(false);
  const [isSecondStepSkipped, setSkipSecondStep] = useState(false);
  const [testStack, setTestStack] = useState([]);
  const [removedAngles, setRemovedAngles] = useState([]);
  const [isModalSaved, setIsModalSaved] = useState(false);
  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  const patientId = id;

  useEffect(() => {
    const machineId = searchParams.get('machineId');
    const machineType = searchParams.get('machineType');

    if (machineId) {
      dispatch(fetchHcpConfiguredMachines({ hcpId: localStorage.getItem('hcpId'), machineType: machineType }));
    }

    if (defaultNavigationItems && defaultNavigationItems.length) {
      setNavigationItems(defaultNavigationItems);

      // default angles are used for step 5-1
      const angles = defaultNavigationItems.find((n) => n.id === '5').children.filter((c) => c.value >= 0);
      setDefaultAngles(angles);
      const stackValue = [];
      angles.map((a) => {
        stackValue.push(a.value);
      });
      setTestStack(stackValue.sort().reverse());
    }
    if (!patientId || !localStorage.getItem('hcpId') || !sessionId) {
      return;
    }
    if (localStorage.getItem('activity_id')) {
      return;
    }

    dispatch(
      requestInitActivity({
        patientId: atob(patientId),
        hcpId: localStorage.getItem('hcpId'),
        sessionId,
        data: {
          activity_type: ISOMETRIC_TEST,
          name: 'Isometric Test',
          setup_id: setupId,
        },
      })
    );
  }, []);

  // get patient by id
  useEffect(() => {
    if (patientId && isValidBase64(patientId)) {
      const data = {
        patientId: atob(patientId),
        hcpId: localStorage.getItem('hcpId'),
      };
      dispatch(fetchPatient(data));
    }
  }, [patientId]);

  useEffect(() => {
    const machineId = searchParams.get('machineId');

    if (machineId && machines.length > 0) {
      const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));

      setMachine(machine);
    }
  }, [machines]);

  //handle select item in sidebar
  const handleSelectItem = (item) => {
    setStep(item.step);
    setSubStep(item.subStep);
  };

  //handle back
  const handleBack = () => {
    let previousStep = step;
    let previousSubStep = subStep;

    if (step === '1' && subStep === '1') {
      navigate(`/patient/${btoa(patient.patient_id)}&tab=1`);
      return;
    }

    const current = navigationItems.find((item) => item.id === `${step}`);

    if (current && !current.children) {
      previousStep = skipBackSteps(step).previousStep;

      const { previous } = skipBackSteps(step);

      if (previous && previous.children) {
        previousSubStep = previous.children.length;
      } else {
        previousSubStep = '0';
      }
    } else {
      if (subStep === '1') {
        previousStep = skipBackSteps(step).previousStep;
        const { previous } = skipBackSteps(step);

        if (previous && previous.children) {
          previousSubStep = previous.children.length;
        } else {
          previousSubStep = '0';
        }
      } else {
        previousSubStep = skipBackSubSteps(step, subStep);
      }
    }

    setSubStep(previousSubStep.toString());
    setStep(previousStep.toString());
  };

  const skipBackSteps = (step) => {
    let previousStep = parseInt(step, 10) - 1;
    let previous = navigationItems.find((item) => item.id === `${previousStep}`);

    while (previous && previous.skipped) {
      previousStep = parseInt(previousStep, 10) - 1;
      previous = navigationItems.find((item) => item.id === `${previousStep}`);
    }

    return { previousStep, previous };
  };

  const skipBackSubSteps = (step, subStep) => {
    const parent = navigationItems.find((item) => item.id === `${step}`);
    let prevSubStep = parseInt(subStep, 10) - 1;
    let childStep = parent.children.find((item) => item.id === `${step}-${prevSubStep}`);

    while (childStep && childStep.skipped) {
      prevSubStep = parseInt(prevSubStep, 10) - 1;
      childStep = parent.children.find((item) => item.id === `${step}-${prevSubStep}`);
    }

    return prevSubStep;
  };

  const handleNext = () => {
    let nextStep = step;
    let nextSubStep = subStep;

    const current = navigationItems.find((item) => item.id === `${step}`);

    if (current && !current.children) {
      nextStep = skipNextSteps(step).nextStep;
      const { next } = skipNextSteps(step);

      if (next && next.children) {
        nextSubStep = '1';
      } else {
        nextSubStep = '0';
      }
    } else {
      if (subStep === `${current.children.length}`) {
        nextStep = skipNextSteps(step).nextStep;
        if (nextStep > 5) {
          return;
        }
        const { next } = skipNextSteps(step);

        if (next && next.children) {
          nextSubStep = '1';
        } else {
          nextSubStep = '0';
        }
      } else {
        nextSubStep = skipNextSubSteps(step, subStep);
      }
    }

    setStep(nextStep.toString());
    setSubStep(nextSubStep.toString());
  };

  const skipNextSteps = (step) => {
    let nextStep = parseInt(step, 10) + 1;
    let next = navigationItems.find((item) => item.id === `${nextStep}`);

    while (next && next.skipped) {
      nextStep = parseInt(nextStep, 10) + 1;
      next = navigationItems.find((item) => item.id === `${nextStep}`);
    }

    return { nextStep, next };
  };

  const skipNextSubSteps = (step, subStep) => {
    const parent = navigationItems.find((item) => item.id === `${step}`);
    let nextSubStep = parseInt(subStep, 10) + 1;
    let childStep = parent.children.find((item) => item.id === `${step}-${nextSubStep}`);

    while (childStep && childStep.skipped) {
      nextSubStep = parseInt(nextSubStep, 10) + 1;
      childStep = parent.children.find((item) => item.id === `${step}-${nextSubStep}`);
    }

    return nextSubStep;
  };

  //handle skip item in sidebar
  const handleSkipItem = (currentItem) => {
    if (currentItem.id === '1') {
      setSkipFirstStep(true);
    }

    if (currentItem.id === '2') {
      setSkipSecondStep(true);
    }

    navigationItems.forEach((n) => {
      if (n.id === currentItem.id) {
        n.skipped = true;
      }
    });

    let nextSubStep;
    const nextStep = parseInt(currentItem.id, 10) + 1;

    const next = navigationItems.find((item) => item.id === `${nextStep}`);

    if (next && next.children) {
      nextSubStep = '1';
    } else {
      nextSubStep = '0';
    }

    handleSelectItem({
      step: `${nextStep}`,
      subStep: nextSubStep,
    });
  };

  // remove angles in step 5-1
  const handleRemoveAngle = (removedAngles) => {
    const newItems = [...navigationItems];
    newItems.forEach((n) => {
      if (n.id === '5') {
        n.children.forEach((c) => {
          const found = removedAngles.find((r) => r.id === c.id);
          if (found) {
            c.skipped = true;
          }
        });
      }
    });

    let newTestStack = [...testStack];
    removedAngles.map((v) => {
      newTestStack = newTestStack.filter((e) => e !== v.value);
    });
    setTestStack(newTestStack);
    setNavigationItems(newItems);
  };

  const handleUndoAngle = (resetNav = false) => {
    const newItems = [...navigationItems];
    // reset angles and navigation items
    if (resetNav) {
      newItems.forEach((n) => {
        n.skipped = false;
        if (n.id === '1' || n.id === '3' || n.id === '4') {
          n.hasSkip = true;
        }
        if (n.id === '5') {
          n.children.forEach((c) => {
            c.skipped = false;
          });
        }
      });
    } else {
      // reset angles but not navigation items
      newItems.forEach((n) => {
        if (n.id === '5') {
          n.children.forEach((c) => {
            c.skipped = false;
          });
        }
      });
    }

    setNavigationItems(newItems);

    // reset test stack
    const angles = defaultNavigationItems.find((n) => n.id === '5').children.filter((c) => c.value >= 0);
    const stackValue = [];
    angles.map((a) => {
      stackValue.push(a.value);
    });
    setTestStack(stackValue.sort().reverse());
  };

  // disable skip button when substeps of a skippable step have been completed
  const handleRemoveSkip = (step) => {
    const newItems = [...navigationItems];
    newItems.forEach((n) => {
      if (n.id === step) {
        n.hasSkip = false;
      }
    });
  };

  if (!patient || !machine)
    return (
      <Container
        sx={{
          minHeight: 'calc(100vh - 70px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ wordBreak: 'break-all', color: 'gray' }} variant='h5' component='div'>
          {isLoading ? 'Loading...' : t('common:errors.noResults')}
        </Typography>
      </Container>
    );

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <IsometricTestView
            patient={patient}
            machine={machine}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSelectItem={handleSelectItem}
            handleSkipItem={handleSkipItem}
            isFirstStepSkipped={isFirstStepSkipped}
            isSecondStepSkipped={isSecondStepSkipped}
            handleRemoveAngle={handleRemoveAngle}
            handleUndoAngle={handleUndoAngle}
            setRemovedAngles={setRemovedAngles}
            removedAngles={removedAngles}
            setIsModalSaved={setIsModalSaved}
            isModalSaved={isModalSaved}
            handleRemoveSkip={handleRemoveSkip}
            step={step}
            subStep={subStep}
            setupId={setupId}
            navigationItems={navigationItems}
            defaultAngles={defaultAngles}
            testStack={testStack}
            socketContext={socketContext}
          />
        </Box>
      </Box>
    </Container>
  );
}
