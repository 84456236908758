import { createSlice } from '@reduxjs/toolkit';

export const syncDataSlice = createSlice({
  name: 'syncData',
  initialState: {
    syncTables: [],
    isFetching: null,
    isRequestUploadFile: null,
    error: null,
    dataStatus: null,
    requestKey: '',
    isFetchingDownload: false,
  },
  reducers: {
    syncStatus: (state, action) => {},

    syncStatusSuccess: (state, action) => {
      state.syncTables = action.payload.data.status;
    },

    uploadFile: (state, action) => {
      state.isFetching = true;
      state.error = null;
      state.syncTables = null;
      state.requestKey = '';
    },
    uploadFileSuccess: (state, action) => {
      state.isFetching = false;
      state.requestKey = action.payload.data.status_key;
    },
    uploadFileFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.syncTables = null;
    },

    syncData: (state) => {
      state.isFetching = true;
      state.error = null;
      state.syncTables = null;
    },
    syncSuccess: (state) => {
      state.isFetching = false;
    },

    syncFailure: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    requestDownloadFile: (state) => {
      state.isFetching = true;
    },
    requestDownloadFileSuccess: (state) => {
      state.isFetching = false;
    },
    requestDownloadFileFailure: (state) => {
      state.isFetching = false;
    },
  },
});
export const {
  syncStatus,
  syncStatusSuccess,
  uploadFile,
  uploadFileFailure,
  uploadFileSuccess,
  syncFailure,
  syncData,
  syncSuccess,
  requestDownloadFileFailure,
  requestDownloadFile,
  requestDownloadFileSuccess,
} = syncDataSlice.actions;

export default syncDataSlice.reducer;
