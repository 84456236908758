import { createSlice } from '@reduxjs/toolkit';
import unionBy from 'lodash/unionBy'

export const NotificiationSlice = createSlice({
  name: 'notificationSlice',
  initialState: {
    notifications: [],
    unread: 0,
    loading: false,
    totalPage: 1,
    currentPage: 1,
    redirectUrl: '',
  },
  reducers: {
    getNotifications: (state, action) => {
      state.loading = true;
      state.currentPage = action.payload.page;
    },
    getNotificationsSuccess: (state, action) => {
      state.loading = false;
      state.notifications = unionBy(state.notifications,action.payload.list, 'id');
      state.unread = action.payload.unread;
      state.totalPage = action.payload.total_page;
    },
    getNotificationsFailure: (state) => {
      state.loading = false;
    },
    readNotification: (state, action) => {
      state.redirectUrl = '';
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    readNotificationSuccess: (state, action) => {
      if (state.unread > 0 && (action.payload.source !== 'local' || action.payload.status === 0)) {
        state.unread = state.unread - 1;
      }
      state.notifications = state.notifications.map(noti => {
        if (noti.id===action.payload.id) {
          return action.payload
        }
        return noti;
      })
      const {
        activityType,
        patientId,
        setupId,
        machineId,
        machineType,
        activityId,
        sessionId
      } = action.payload.data;
      state.redirectUrl = '';
      const time = Date.now()
      if (activityType === 1) {
        if (!setupId || !machineId) {
          return null;
        }
        state.redirectUrl = `/patient/${btoa(patientId)}/isometric-test/summary?setupId=${setupId}&machineType=${machineType}&sessionId=${sessionId}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=true&time=${time}`;
      } else {
        state.redirectUrl = `/patient/${btoa(patientId)}/dynamic-test/summary?setupId=${setupId}&machineType=${machineType}&sessionId=${sessionId}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=true&time=${time}`;
      }
    }
  }
});
export const {
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailure,
  readNotificationSuccess,
  readNotification,
  setRedirectUrl
} = NotificiationSlice.actions;

export default NotificiationSlice.reducer;