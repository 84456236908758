import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isNaN from 'lodash/isNaN';

const Container = styled('div')`
  table,
  td,
  th {
    border: 1px solid;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;
const RomPainTable = ({ currentMaxExtension, currentMaxFlexion, activeTested }) => {
  const currentRomPain = {
    rom: (!isNaN(currentMaxExtension) && !isNaN(currentMaxFlexion)) ? `${currentMaxExtension}° - ${currentMaxFlexion}°` : '-',
  }
  return (
    <Container>
      <table>
        <thead>
          <tr style={{ background: '#495057', color: 'white' }}>
            <th style={{ fontSize: '12px', fontWeight: 600, height: '23.62px', width: '85px', borderColor: 'black' }}>ROM</th>
            <th style={{ fontSize: '12px', fontWeight: 600, height: '23.62px', minWidth: '78px', borderColor: 'black' }}>Pain Level</th>
          </tr>
        </thead>
        {
          activeTested &&
          <tbody>
            <tr style={{ color: 'black' }}>
              <td style={{ width: '85px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>{currentRomPain.rom}</td>
              <td style={{ minWidth: '78px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
            </tr>
            <tr style={{ color: 'black' }}>
              <td style={{ width: '85px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>{currentRomPain.rom}</td>
              <td style={{ minWidth: '78px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
            </tr>
            <tr style={{ color: 'black' }}>
              <td style={{ width: '85px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>{currentRomPain.rom}</td>
              <td style={{ minWidth: '78px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
            </tr>
          </tbody>
        }
      </table>
    </Container>
  );
};

RomPainTable.propTypes = {
  currentMaxExtension: PropTypes.any,
  currentMaxFlexion: PropTypes.any
};

export default RomPainTable;