
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Modal } from 'components/Modal';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import isNil from 'lodash/isNil';
import { dateParserWithTimezone } from 'helpers/datetime';

export default function ListActivityAvailableCompare({ type, onFetchPrevActivity, onFetchFirstActivity, openModal, onCloseModal, onOpenModal }) {

  const arrAllIsoActivities = useSelector((state) => state.patients.arrAllIsoActivities);
  const dataPrevActivity = useSelector((state) => state.patients.dataPrevActivity);
  const dataFirstActivity = useSelector((state) => state.patients.dataFirstActivity);
  const currentDataActivity = useSelector((state) => state.patients.currentDataActivity);

  const firstActivityId = dataFirstActivity?.activityId;
  const prevActivityId = dataPrevActivity?.activityId

  const defaultSelected = type === 'prev' ? prevActivityId : firstActivityId

  const [selectedRowInModal, setSelectedRowInModal] = useState(null);
  const [disabledSelectBtn, setDisableSelectBtn] = useState(true);

  useEffect(() => {
    return () => {
      setSelectedRowInModal(null);
      setDisableSelectBtn(true);
    }
  }, []);

  const handleCloseModal = () => {
    onCloseModal();
    setSelectedRowInModal(null);
    setDisableSelectBtn(true);
  }


  const formattedDate = (originalDate) => {
    if (!isNil(originalDate)) {
      const localDate = dateParserWithTimezone(originalDate);
      return format(localDate, "MMM d yyyy, hh:mm a");
    }
    return;
  };

  const filterArray = arrAllIsoActivities?.data?.filter(d => d.id !== currentDataActivity?.activitiesId);

  const arrFilterMap = filterArray?.map((isoActivity, index) => {
    return {
      activityId: isoActivity.id,
      activity_type: isoActivity.activity_type,
      sessionName: isoActivity.patient_sessions.name,
      sessionId: isoActivity.patient_sessions.id,
      patientId: isoActivity.patient_sessions.patient_id,
      machineName: isoActivity.machine_name,
      maxExtension: isoActivity.machine_setup_data.range_motion_min,
      maxFlexion: isoActivity.machine_setup_data.range_motion_max,
      rom: `${isoActivity.machine_setup_data.range_motion_min}° - ${isoActivity.machine_setup_data.range_motion_max}°`,
      initAt: formattedDate(isoActivity.initiated_at),
      key: index + 1
    }
  });

  const columns = [
    { id: 'initAt', label: 'Date & Time', minWidth: 245, align: 'left' },
    { id: 'sessionName', label: 'Session', minWidth: 120, align: 'center' },
    { id: 'machineName', label: 'Machine name', minWidth: 200, align: 'left' },
    { id: 'rom', label: 'ROM', minWidth: 145, align: 'left' },
  ];

  const handleRowClick = (row) => {
    const isActivityComparing = (type === 'prev') ? (row?.activityId === firstActivityId) : (row?.activityId === prevActivityId);
    const isActivityHaveSelect = row?.activityId === defaultSelected;

    if (row && !isActivityComparing && !isActivityHaveSelect) {
      setSelectedRowInModal(row);
      disabledSelectBtn && setDisableSelectBtn(false);
      return;
    } else if (isActivityHaveSelect) {
      setSelectedRowInModal(row);
      setDisableSelectBtn(true);
      return
    }
  };

  const handleConfirmSelect = (row) => {
    if (type) {
      if (type === 'prev') {
        onFetchPrevActivity(row);
      } else {
        onFetchFirstActivity(row)
      }
    }
    handleCloseModal();
  }

  const renderTableCell = ({ id, align, index, value, selectedRowCode, columns, code, activityId }) => {
    const isActivityComparing = (type === 'prev') ? (activityId === firstActivityId) : (activityId === prevActivityId);
    const conditionHighlight = selectedRowCode ? code === selectedRowCode : activityId === defaultSelected;

    return <TableCell sx={{
      borderTop: (conditionHighlight) && '1px solid #ED9A00 !important',
      borderBottom: (conditionHighlight) && '1px solid #ED9A00 !important',
      borderLeft: (index === 0 && conditionHighlight) && '1px solid #ED9A00 !important',
      borderRight: (index === columns.length - 1 && conditionHighlight) && '1px solid #ED9A00 !important',
      // borderTopLeftRadius: (index === 0 && code === selectedRow) && '4px',
      // borderBottomLeftRadius: (index === 0 && code === selectedRow) && '4px',
      // borderTopRightRadius: (index === columns.length - 1 && code === selectedRow) && '4px',
      // borderBottomRightRadius: (index === columns.length - 1 && code === selectedRow) && '4px',
    }} key={id} align={align}>
      <Box sx={{ display: 'flex', justifyContent: (id === 'sessionName') ? 'center' : 'space-evenly' }}>
        <Typography sx={{ color: isActivityComparing && '#ADB5BD' }}>{value}</Typography>
        {
          (id !== 'sessionName') && <CheckCircleOutlineIcon
            sx={{
              color: conditionHighlight ? '#ED9A00' : isActivityComparing ? '#CED4DA' : '',
              visibility: ((conditionHighlight || isActivityComparing) && (index === columns.length - 1)) ? 'visible' : 'hidden',
              ml: 1
            }}
          />
        }
      </Box>
    </TableCell>
  }

  const renderContent = () => {
    return <Box>
      <TableContainer sx={{ maxHeight: 516, width: 766 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {arrFilterMap?.map((row) => {
              const isActivityComparing = (type === 'prev') ? (row?.activityId === firstActivityId) : (row?.activityId === prevActivityId);
              const conditionHighlight = selectedRowInModal ? row.key === selectedRowInModal?.key : row?.activityId === defaultSelected;
              return (
                <>
                  {
                    isActivityComparing ? <Tooltip componentsProps={{
                      tooltip: {
                        sx: {
                          color: "#495057",
                          backgroundColor: "#FFFFFF",
                          fontSize: '14px',
                          boxShadow: '1px 1px 1px 1px #888888',
                          padding: 1
                        }
                      }
                    }} title={'Unable to select this test. It is already in use.'}>
                      <TableRow
                        selected={conditionHighlight}
                        sx={{
                          '&.Mui-selected': {
                            background: '#FBEBCC !important'
                          },
                          background: isActivityComparing && '#f8faf9'
                        }}
                        onClick={() => handleRowClick(row)}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.key}
                      >
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return renderTableCell({ id: column.id, align: column.align, index, value, selectedRowCode: selectedRowInModal?.key, columns, code: row.key, activityId: row.activityId });
                        })}
                      </TableRow>
                    </Tooltip>
                      :
                      <TableRow
                        selected={conditionHighlight}
                        sx={{
                          '&.Mui-selected': {
                            background: '#FBEBCC !important'
                          },
                          background: isActivityComparing && '#F8F9FA'
                        }}
                        onClick={() => handleRowClick(row)}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.key}
                      >
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return renderTableCell({ id: column.id, align: column.align, index, value, selectedRowCode: selectedRowInModal?.key, columns, code: row.key, activityId: row.activityId });
                        })}
                      </TableRow>
                  }
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  };

  const renderTitle = () => {
    return <Typography sx={{ fontSize: '24px', fontWeight: 600, color: '#000' }}>{`Select a previous test for comparison`}</Typography>
  }

  return <Modal
    title={renderTitle()}
    isClose={true}
    keep={true}
    onClose={handleCloseModal}
    showModal={openModal}
    content={renderContent}
    extra={[
      <Button disabled={disabledSelectBtn} onClick={() => handleConfirmSelect(selectedRowInModal)} variant='contained' key={'btn-1'}>
        {('Select')}
      </Button>
    ]}
  />
}

ListActivityAvailableCompare.propTypes = {
  type: PropTypes.string,
  openModal: PropTypes.bool,
  onCloseModal: PropTypes.bool,
  selectedRow: PropTypes.any,
  onSetSelectedRow: PropTypes.func,
  onFetchPrevActivity: PropTypes.func,
  onFetchFirstActivity: PropTypes.func
};