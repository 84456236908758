import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TableSetup from './TableSetup';

import useCurrentUser from 'hooks/useCurrentUser';
import InfoIcon from '@mui/icons-material/Info';
import { clearLocalPatientSetupData } from 'modules/Patients/utils';

export default function SelectPatientMachineSetup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { permissions: currentPermissions } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const patientId = id;
  const sessionId = searchParams.get('sessionId');

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions.includes('EXECUTE_ACTIVITIES');
  };
  const onClickHandler = () => {
    navigate(`/patient/${patientId}/patient-detail?sessionId=${sessionId}&tab=1`);
  };

  if (!patientId) {
    return <></>;
  }

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          minHeight: 'calc(100vh - 118px)',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '59px' }} maxWidth='lg'>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ wordBreak: 'break-all' }} variant='h4' component='div'>
              {`Select a Patient Machine Setup for ${type === 'Dynamic'
                  ? t('customer:patientSession:dynamicExercise')
                  : t('customer:patientSession:isometricTest')
                }`}
            </Typography>
            {hasExecuteActivitiesPermission() && (
              <Button
                sx={{ background: 'black', color: '#fff' }}
                onClick={() => {
                  clearLocalPatientSetupData();
                  navigate(`/patient/${patientId}/session/select-machine?sessionId=${sessionId}`);
                }}
                size={'small'}
                variant='contained'
              >
                {t('common:newSetup')}
              </Button>
            )}
          </Box>

          <Box
            sx={{
              width: '100%',
              height: '50vh',
            }}
            maxWidth='lg'
          >
            <Box
              sx={{
                fontSize: '14px',
                textAlign: 'end',
                width: '100%',
                display: 'flex',
                gap: 1,
                justifyContent: 'end',
              }}
            >
              <span>
                <InfoIcon fontSize={'small'} />
              </span>

              <span>All setups belong to the removed machines will be disabled.</span>
            </Box>
            <TableSetup sessionId={sessionId} type={type} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => onClickHandler()} size={'small'} variant='outlined'>
              {t('common:back')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
