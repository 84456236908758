import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { clearPatientSetup, fetchPatientSetups, setPrevUrl } from '../../slice';
import PropTypes from 'prop-types';
import { BasicTable } from 'components/Table/index.js';
import { dateParserWithTimezone } from 'helpers/datetime.js';
import { format } from 'date-fns';
import { FORMAT_DATE } from 'modules/Patients/constants.js';
import { Typography } from '@mui/material';
import { SkeletonPatientMachine } from 'components/Loading';

export default function TableSessionSetups({ emptySmg, columns = [], session, type, machines }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const patientId = id;
  const hcpId = localStorage.getItem('hcpId');
  const isSetupsFetching = useSelector((state) => state.patients.isSetupsFetching);

  const patientSetups = useSelector((state) => state.patients.setups);

  const [dataSrc, setDataSrc] = useState([]);
  useEffect(() => {
    if (!hcpId || !session || !patientId) {
      return null;
    }

    dispatch(fetchPatientSetups({ hcpId, patientId: atob(patientId), sessionId: session.id }));
    return () => {
      clearPatientSetup();
    };
  }, []);

  useEffect(() => {
    if (!patientSetups || !machines || (machines && machines.length === 0) || !session) {
      return null;
    }

    const data = [];
    if (type === 'machine') {
      patientSetups.map((patientSetup) => {
        const localDate = dateParserWithTimezone(patientSetup.initiated_at);

        data.push({
          id: patientSetup.id,
          date: format(localDate, FORMAT_DATE),
          machineId: patientSetup.machine_id,
          name: patientSetup?.setup_name,
          machine: patientSetup.machine_name,
          type: patientSetup.machine_type,
          serialNumber: patientSetup.u12_id,
          originalMachineData: {
            name: patientSetup.machine_name,
            serial_no: patientSetup.u12_id,
          },
          disable: !!patientSetup.machine_deleted_at,
        });
      });
    }
    data.sort((a, b) => new Date(b.date) - new Date(a.date));
    setDataSrc(data);
  }, [patientSetups, machines]);

  const onRowClickHandler = (rowData) => {
    if (type === 'machine') {
      dispatch(setPrevUrl(location.pathname + location.search));
      navigate(
        `/patient/${patientId}/machine-setup/summary?setupId=${rowData.id}&isBack=true&sessionId=${session.id}&machineType=${rowData.type}&machineId=${rowData.machineId}&isCloseDuplicate=false&tab=2`
      );
    }
  };

  if (isSetupsFetching) {
    return <SkeletonPatientMachine />;
  }
  if ((dataSrc?.length === 0) && !isSetupsFetching) {
    return <Typography>{emptySmg || 'No data available'}</Typography>;
  }

  if ((dataSrc?.length > 0) && !isSetupsFetching) {
    return <BasicTable onRowClick={onRowClickHandler} headers={columns} data={dataSrc} />;
  }

  return <></>

}

TableSessionSetups.propTypes = {
  emptySmg: PropTypes.string,
  columns: PropTypes.array,
  session: PropTypes.object,
  tab: PropTypes.string || PropTypes.number,
  disableExtra: PropTypes.bool,
  type: PropTypes.string,
  machines: PropTypes.array
};
