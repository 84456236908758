import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDialog from 'components/ConfirmDialog/index.jsx';
import { DIALOGS } from 'modules/Dialogs/constants';
import { closeDialog, openDialog } from 'helpers/dialog';
import { archivePatient } from '../slice.js';
import useCurrentUser from 'hooks/useCurrentUser.js';
import { formatDate } from 'helpers/datetime.js';
import { genderRender } from 'helpers';

export default function ViewPatient({ patient, onOpenEditPatientDialog }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, permissions: currentPermissions } = useCurrentUser();

  const hasManagePatientPermission = () => {
    return currentPermissions.includes('PATIENT_MANAGEMENT');
  };
  const onArchivePatient = () => {
    openDialog(dispatch, DIALOGS.CONFIRM_ARCHIVE_HCP_USER_DIALOG);
  };

  const openConfirmArchiveDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIRM_ARCHIVE_PATIENT_DIALOG]);
  const handleCloseConfirmArchiveDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIRM_ARCHIVE_HCP_USER_DIALOG);
  };

  const handleSubmitArchivePatient = () => {
    if (!patient) {
      return null;
    }

    dispatch(
      archivePatient({
        hcpId: localStorage.getItem('hcpId'),
        patientId: patient.patient_id,
        toastSmg: 'The Patient has been archived.',
      })
    );

    navigate('/');
    handleCloseConfirmArchiveDialog();
  };

  const formatType = (string) => {
    if (!string) {
      return 'NULL';
    }

    if (string === 'MILITARY') {
      return 'Military';
    }

    if (string === 'CIVILIAN') {
      return 'Civilian';
    }

    if (string === 'ATHLETE') {
      return 'Athlete';
    }

    return string;
  };
  const getMobileNumber = (patient) => {
    let mobileNo;
    if (formatPhoneNumberIntl(`+${patient.mobile_no}`)) {
      mobileNo = formatPhoneNumberIntl(`+${patient.mobile_no}`);
    } else if (patient.mobile_no) {
      mobileNo = patient.mobile_no;
    } else {
      mobileNo = 'None';
    }
    return mobileNo;
  };

  const getHomeNumber = (patient) => {
    let homeNo;
    if (formatPhoneNumberIntl(`+${patient.home_no}`)) {
      homeNo = formatPhoneNumberIntl(`+${patient.home_no}`);
    } else if (patient.home_no) {
      homeNo = patient.home_no;
    } else {
      homeNo = 'None';
    }
    return homeNo;
  };

  const mobileNo = getMobileNumber(patient);
  const homeNo = getHomeNumber(patient);

  return (
    <Box sx={{ bgcolor: 'white', p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ wordBreak: 'break-all' }} variant='h3' component='div'>
          {patient.fname} {patient.lname}
        </Typography>
        {hasManagePatientPermission() && !user.single_sign_on ? (
          <IconButton onClick={onOpenEditPatientDialog} sx={{ m: '20px' }} size='small' aria-label='edit'>
            <EditIcon />
          </IconButton>
        ) : null}
      </Box>
      <Typography sx={{ mt: 2 }} component='div'>
        {t('common:patientId')}: {patient.patient_id}
      </Typography>

      <Box sx={{ display: 'flex', mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '15%' }}>
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:dob')}
          </Typography>
          <Typography component='div'>{formatDate(patient.dob)}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '15%',
          }}
        >
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:gender')}
          </Typography>
          <Typography component='div'>{genderRender(patient?.gender)}</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '15%' }}>
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:type')}
          </Typography>
          <Typography component='div'>{formatType(patient.type)}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:address')}
          </Typography>
          <Typography sx={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>{patient.address}</Typography>
          <Box sx={{ display: 'flex', mt: 1 }}>
            {patient.city !== 'None' && <Typography component='div'>{patient.city},&nbsp;</Typography>}
            {patient.state !== 'None' && <Typography component='div'>{patient.state},&nbsp;</Typography>}
            <Typography component='div'>{patient.zip_code}</Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 1 }}>
            <Typography component='div'>{patient.country}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:mobilePhone')}
          </Typography>
          <Typography component='div'>{mobileNo}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '20%',
          }}
        >
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:homePhone')}
          </Typography>
          <Typography component='div'>{homeNo}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:email')}
          </Typography>
          <Typography component='div'>{patient.email ? patient.email : 'None'}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, fontWeight: 500 }} component='div'>
            {t('common:additionalNotes')}
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} component='div'>
            {patient.notes ? patient.notes : 'None'}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        {hasManagePatientPermission() ? (
          <Button variant='contained' onClick={onArchivePatient}>
            Archive
          </Button>
        ) : (
          <Box></Box>
        )}

        <Button variant='outlined' onClick={() => navigate('/')}>
          Close Patient
        </Button>
      </Box>

      <ConfirmDialog
        title={'Are you sure to Archive this Patient profile? The archived Patient can not be recovered.'}
        confirmText='Archive'
        openConfirmDialog={openConfirmArchiveDialog}
        handleCloseConfirmDialog={handleCloseConfirmArchiveDialog}
        handleSubmitConfirmDialog={handleSubmitArchivePatient}
      />
    </Box>
  );
}

ViewPatient.propTypes = {
  patient: PropTypes.object,
  onOpenEditPatientDialog: PropTypes.func,
};
