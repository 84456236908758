import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import step3 from 'assets/images/png/preparation/step3.png';
import { SingleImageStep } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import handlePad from 'assets/images/png/dynamic-test/4a.png';
import { allowIntNumber } from 'helpers';

export default function PatientAdjustments({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  onDataChange,
  defaultData,
}) {
  const defaultValues = {
    weight: '',
  };

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (defaultData) {
      console.warn('defaultData', defaultData);

      setValue('weight', defaultData.value ?? '');
      setStepData(defaultData.value ?? '');
    }
  }, [defaultData]);

  const [stepData, setStepData] = useState(defaultValues.weight);

  const handleInputChange = (value) => {
    if (parseInt(value, 10) > 1000) {
      setStepData(1000);
      setValue('weight', 1000);
    } else {
      setStepData(value);
    }
  };

  const handleNextStep = () => {
    onDataChange({
      step,
      subStep,
      value: stepData,
    });

    handleNext();
  };

  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step3} />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant='body1' component='div'>
              1. Select a weight of 60 foot-lbs for males and 40 foot-lbs for females on the weight stack for dynamic warm-up.
              <br />
              2. Tighten chain tightener. Caution: Do not over tighten!
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                <strong>Manually enter weight, and press enter.</strong>
              </label>
              <Controller
                name='weight'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowIntNumber(e);
                    }}
                    sx={{ mt: 1, width: '200px' }}
                    error={!!errors.weight}
                    placeholder='--'
                    margin='normal'
                    id='weight'
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      handleInputChange(event.target.value);
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>lbs</InputAdornment>,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <div style={{ padding: '20px 0' }}>
          3. Make sure patient is snug in the machine.
          <br />
          <div style={{ padding: '0 20px' }}>
            • Tighten lap belt adjuster and footrest adjuster.
            <br />• Have patient lean forward and touch toes while watching for movement in lumbar pad, then tighten
            additionally as needed.
          </div>
        </div>

        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={handlePad} />
            </Box>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                4. Have the patient grab operator handles.
              </Typography>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNextStep}
      />
    </>
  );
}

PatientAdjustments.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  onDataChange: PropTypes.func,
  defaultData: PropTypes.object,
};
