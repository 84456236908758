import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { allowDecimalNumber } from 'helpers';
import { useEffect } from 'react';

const InputTextContainer = styled('div')`
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 2px;
  }
  .css-1aw4p1t-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: #d4d4d4;
  }
  .css-p23j8h-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: #d4d4d4;
  }
  textarea {
    padding-right: 10px;
  }
`;
export default function InputText({
  value,
  onChange,
  placeholder,
  width,
  field,
  type,
  min = 0,
  max = 999,
  disable = false,
  maxLength = 256,
  patten,
  maxRows,
}) {
  useEffect(() => {
    return () => {
      onChange(field, '');
    };
  }, []);
  const onChangeHandler = (e) => {
    if (patten) {
      const check = patten.test(e.target.value);
      if (!check && e.target.value !== '') {
        return;
      }
      onChange(field, e.target.value);
      return;
    }
    if (type !== 'number') {
      onChange(field, e.target.value);
      return;
    }

    if (Number(e.target.value) < min) {
      onChange(field, min.toString());
      return;
    }

    if (Number(e.target.value) > max) {
      onChange(field, max.toString());
      return;
    }

    onChange(field, e.target.value);
  };

  const onKeyPress = (e) => {
    if (patten) {
      const check = patten.test(e.target.value);
      if (!check && e.target.value !== '') {
        e.preventDefault();
      }
      return;
    }

    if (type !== 'number') {
      return;
    }

    if (e.target.value[0] === '0' && e.charCode === 48) {
      e.preventDefault();
      return;
    }

    const decimal = e.target.value.split('.')[1];
    if (decimal && decimal.length > 2) {
      e.preventDefault();
      return;
    }

    if (type === 'number') {
      allowDecimalNumber(e);
    }
  };

  return (
    <InputTextContainer>
      <TextField
        disabled={disable}
        type={type}
        onKeyPress={onKeyPress}
        value={value ? value.toString() : ''}
        className={'input-text'}
        onChange={onChangeHandler}
        InputLabelProps={{ shrink: false }}
        margin='none'
        variant='outlined'
        placeholder={placeholder}
        inputProps={{ maxLength: maxLength }}
        multiline={type !== 'number'}
        minrows={1}
        maxRows={maxRows}
        InputProps={{
          style: {
            width: width,
          },
        }}
      />
    </InputTextContainer>
  );
}

InputText.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.number | PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disable: PropTypes.bool,
  maxLength: PropTypes.number,
  patten: PropTypes.string,
  maxRows: PropTypes.number,
};
