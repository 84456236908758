import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import step2 from 'assets/images/SVG/Lumbar/Lumbar_lap_belt.svg';
import step3 from 'assets/images/png/dynamic-test/4c.png';
import Box from '@mui/material/Box';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

const LapBeltAndHeadRest = ({ step, subStep, handleCancel, handleBack, handleNext }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step2} />
          </Box>
          <Typography variant='body1' component='div'>
            1. Have the patient hook the lap-belt into the green loops, belt edge snug against the crease of the hips.
            <br />
            2. Adjust lap belt adjuster.
            <br />
            3. Tighten lap belt, so the patient is comfortable.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step3} />
          </Box>
          <Typography variant='body1' component='div'>
            4. Adjust headrest, appx near the base of the skull, so the patient is comfortable. Move to the patient’s
            liking on the back of head.
          </Typography>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </>
  );
};

LapBeltAndHeadRest.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
};

export default LapBeltAndHeadRest;
