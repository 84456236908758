import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import TablePreviousActivity from './TablePreviousActivity';
import TableRomPainPreviousActivity from './TableRomPainPreviousActivity';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import useBoolean from 'hooks/useBoolean';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { dateParserWithTimezone } from 'helpers/datetime';
import { format } from 'date-fns';

const PreviousActivityCompare = ({ onFetchPrevActivity, prevSelectedActivityId, currentPainLevel, currentMaxExtension, currentMaxFlexion }) => {
  const { onOpen, onClose, open } = useBoolean(false);
  const dataPrevActivity = useSelector((state) => state.patients.dataPrevActivity);
  const activeNet = useSelector((state) => state.patients.activeNet);
  const activeTested = useSelector((state) => state.patients.activeTested);
  const currentDataActivity = useSelector((state) => state.patients.currentDataActivity);
  const dataPrevRomPain = useSelector((state) => state.patients.dataPrevRomPain);
  const listDataMachineSetup = useSelector((state) => state.patients.listDataMachineSetup);

  const formattedDate = (originalDate) => {
    if (!isNil(originalDate)) {
      const localDate = dateParserWithTimezone(originalDate);
      return format(localDate, "MMM d yyyy, hh:mm a");
    }
    return;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: activeNet ? 1 : 0 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          {`Test Comparison 1: ${formattedDate(dataPrevActivity?.initTime)} `}
        </Typography>
        <Box sx={{ ml: 1 }} onClick={onOpen}>
          <ModeEditIcon sx={{ cursor: 'pointer', color: '#000', fontSize: '22px' }} />
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '77%' }}>
          <TablePreviousActivity onFetchPrevActivity={onFetchPrevActivity} open={open} onOpen={onOpen} onClose={onClose} activeNet={activeNet} activeTested={activeTested} dataPrevActivity={dataPrevActivity} currentDataActivity={currentDataActivity} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <TableRomPainPreviousActivity currentPainLevel={currentPainLevel} currentMaxExtension={currentMaxExtension} currentMaxFlexion={currentMaxFlexion} prevSelectedActivityId={prevSelectedActivityId} activeNet={activeNet} activeTested={activeTested} dataPrevActivity={dataPrevActivity} listDataMachineSetup={listDataMachineSetup} dataPrevRomPain={dataPrevRomPain} />
        </Box>
      </Box>
    </Box>
  );
};

PreviousActivityCompare.propTypes = {
  prevSelectedActivityId: PropTypes.any,
  onFetchPrevActivity: PropTypes.func,
  currentPainLevel: PropTypes.any,
  currentMaxExtension: PropTypes.any,
  currentMaxFlexion: PropTypes.any,
};

export default PreviousActivityCompare;