export const defaultNavigateItems = [
  {
    id: '1',
    name: 'Questions',
  },
  {
    id: '2',
    hasSkip: true,
    name: 'Patient Setup',
    children: [
      {
        id: '2-1',
        name: 'Preparation',
      },
      {
        id: '2-2',
        name: 'Instructions',
      },
      {
        id: '2-3',
        name: 'Seating / Footboard',
      },
      {
        id: '2-4',
        name: 'Gate Position',
      },
      {
        id: '2-5',
        name: 'Tighten Restraints',
      },
    ],
  },

  {
    id: '3',
    name: 'Dynamic Warm-Up',
  },
  {
    id: '4',
    name: 'Testing Instructions',
  },

  {
    id: '5',
    name: 'Practice Test',
  },
  {
    id: '6',
    name: 'Actual Test',
  },
];

export const oneSideNavigateItems = (firstSide, availableAngles) => [
  {
    id: '1',
    name: 'Questions',
  },
  {
    id: '2',
    hasSkip: true,
    name: 'Patient Setup',
    children: [
      {
        id: '2-1',
        name: 'Preparation',
      },
      {
        id: '2-2',
        name: 'Instructions',
      },
      {
        id: '2-3',
        name: 'Seating / Footboard',
      },
      {
        id: '2-4',
        name: 'Gate Position',
      },
      {
        id: '2-5',
        name: 'Tighten Restraints',
      },
    ],
  },

  {
    id: '3',
    hasSkip: true,
    name: 'Dynamic Warm-Up',
    children: [
      {
        id: '3-1',
        name: 'Instructions',
      },
      {
        id: '3-2',
        name: 'Patient Adjustments',
      },
      {
        id: '3-3',
        name: `${firstSide === 1 ? 'Left' : 'Right'} - Select Angle`,
      },
      {
        id: '3-4',
        name: `${firstSide === 1 ? 'Left' : 'Right'} - Exercise`,
      },
    ],
  },
  {
    id: '4',
    name: 'Testing Instructions',
  },
  {
    id: '5',
    hasSkip: true,
    name: `${firstSide === 1 ? 'Left' : 'Right'} Side Practice Test`,
    children: [
      {
        id: '5-1',
        name: 'Preset Angles',
      },
      {
        id: '5-2',
        name: 'Instructions',
      },
      {
        id: '5-3',
        name: '1st Angle Test',
      },
      {
        id: '5-4',
        name: '2nd Angle Test',
      },
      {
        id: '5-5',
        name: '3rd Angle Test',
      },
      {
        id: '5-6',
        name: 'Practice Summary',
      },
    ],
  },
  {
    id: '6',
    name: `${firstSide === 1 ? 'Left' : 'Right'} Side Test`,
    children: [
      {
        id: '6-1',
        name: 'Pre-Set Angles',
      },
      {
        id: '6-2',
        name: 'Instructions',
      },
      {
        id: '6-3',
        name: 'First Angle Test',
        shortName: '1st Angle',
        value: firstSide === 1 ? 54 : -54,
        skipped: !availableAngles.includes(firstSide === 1 ? 54 : -54),
      },
      {
        id: '6-4',
        name: 'Second Angle Test',
        shortName: '2nd Angle',
        value: firstSide === 1 ? 36 : -36,
        skipped: !availableAngles.includes(firstSide === 1 ? 36 : -36),
      },
      {
        id: '6-5',
        name: 'Third Angle Test',
        shortName: '3rd Angle',
        value: firstSide === 1 ? 18 : -18,
        skipped: !availableAngles.includes(firstSide === 1 ? 18 : -18),
      },
      {
        id: '6-6',
        name: 'Fourth Angle Test',
        shortName: '4th Angle',
        value: 0,
        skipped: !availableAngles.includes(0),
      },
      {
        id: '6-7',
        name: 'Fifth Angle Test',
        shortName: '5th Angle',
        value: firstSide === 1 ? -18 : 18,
        skipped: !availableAngles.includes(firstSide === 1 ? -18 : 18),
      },
      {
        id: '6-8',
        name: 'Sixth Angle Test',
        shortName: '6th Angle',
        value: firstSide === 1 ? -36 : 36,
        skipped: !availableAngles.includes(firstSide === 1 ? -36 : 36),
      },
      {
        id: '6-9',
        name: 'Seventh Angle Test',
        shortName: '7th Angle',
        value: firstSide === 1 ? -54 : 54,
        skipped: !availableAngles.includes(firstSide === 1 ? -54 : 54),
      },
    ],
  },
];

export const bothSideNavigateItems = (firstSide, availableAnglesLeft, availableAnglesRight) => [
  {
    id: '1',
    name: 'Questions',
  },
  {
    id: '2',
    hasSkip: true,
    name: 'Patient Setup',
    children: [
      {
        id: '2-1',
        name: 'Preparation',
      },
      {
        id: '2-2',
        name: 'Instructions',
      },
      {
        id: '2-3',
        name: 'Seating / Footboard',
      },
      {
        id: '2-4',
        name: 'Gate Position',
      },
      {
        id: '2-5',
        name: 'Tighten Restraints',
      },
    ],
  },

  {
    id: '3',
    hasSkip: true,
    name: 'Dynamic Warm-Up',
    children: [
      {
        id: '3-1',
        name: 'Instructions',
      },
      {
        id: '3-2',
        name: `${firstSide === 1 ? 'Left' : 'Right'} - Patient Adjustments`,
      },
      {
        id: '3-3',
        name: `${firstSide === 1 ? 'Left' : 'Right'} - Select Angle`,
      },
      {
        id: '3-4',
        name: `${firstSide === 1 ? 'Left' : 'Right'} - Exercise`,
      },

      {
        id: '3-5',
        name: `${firstSide === 1 ? 'Right' : 'Left'} - Patient Adjustments`,
      },
      {
        id: '3-6',
        name: `${firstSide === 1 ? 'Right' : 'Left'} - Select Angle`,
      },
      {
        id: '3-7',
        name: `${firstSide === 1 ? 'Right' : 'Left'} - Exercise`,
      },
    ],
  },
  {
    id: '4',
    name: 'Testing Instructions',
  },
  {
    id: '5',
    hasSkip: true,
    name: `${firstSide === 1 ? 'Right' : 'Left'} Side Practice Test`,
    children: [
      {
        id: '5-1',
        name: 'Preset Angles',
      },
      {
        id: '5-2',
        name: 'Instructions',
      },
      {
        id: '5-3',
        name: '1st Angle Test',
      },
      {
        id: '5-4',
        name: '2nd Angle Test',
      },
      {
        id: '5-5',
        name: '3rd Angle Test',
      },
      {
        id: '5-6',
        name: 'Practice Summary',
      },
    ],
  },

  {
    id: '6',
    name: `${firstSide === 1 ? 'Left' : 'Right'} Side Test`,
    children: [
      {
        id: '6-1',
        name: 'Pre-Set Angles',
      },
      {
        id: '6-2',
        name: 'Instructions',
      },
      {
        id: '6-3',
        name: 'First Angle Test',
        shortName: '1st Angle',
        value: firstSide === 1 ? 54 : -54,
        skipped: !(firstSide === 1 ? availableAnglesLeft.includes(54) : availableAnglesRight.includes(-54)),
      },
      {
        id: '6-4',
        name: 'Second Angle Test',
        shortName: '2nd Angle',
        value: firstSide === 1 ? 36 : -36,
        skipped: !(firstSide === 1 ? availableAnglesLeft.includes(36) : availableAnglesRight.includes(-36)),
      },
      {
        id: '6-5',
        name: 'Third Angle Test',
        shortName: '3rd Angle',
        value: firstSide === 1 ? 18 : -18,
        skipped: !(firstSide === 1 ? availableAnglesLeft.includes(18) : availableAnglesRight.includes(-18)),
      },
      {
        id: '6-6',
        name: 'Fourth Angle Test',
        shortName: '4th Angle',
        value: 0,
        skipped: !(firstSide === 1 ? availableAnglesLeft.includes(0) : availableAnglesRight.includes(0)),
      },
      {
        id: '6-7',
        name: 'Fifth Angle Test',
        shortName: '5th Angle',
        value: firstSide === 1 ? -18 : 18,
        skipped: !(firstSide === 1 ? availableAnglesLeft.includes(-18) : availableAnglesRight.includes(18)),
      },
      {
        id: '6-8',
        name: 'Sixth Angle Test',
        shortName: '6th Angle',
        value: firstSide === 1 ? -36 : 36,
        skipped: !(firstSide === 1 ? availableAnglesLeft.includes(-36) : availableAnglesRight.includes(36)),
      },
      {
        id: '6-9',
        name: 'Seventh Angle Test',
        shortName: '7th Angle',
        value: firstSide === 1 ? -54 : 54,
        skipped: !(firstSide === 1 ? availableAnglesLeft.includes(-54) : availableAnglesRight.includes(54)),
      },
      {
        id: '6-10',
        name: 'Test Summary',
      },
    ],
  },

  {
    id: '7',
    name: `${firstSide === 1 ? 'Right' : 'Left'} Side Test`,
    children: [
      {
        id: '7-1',
        name: 'Pre-Set Angles',
      },
      {
        id: '7-2',
        name: 'Instructions',
      },
      {
        id: '7-3',
        name: 'First Angle Test',
        shortName: '1st Angle',
        value: firstSide === 2 ? 54 : -54,
        skipped: !(firstSide === 2 ? availableAnglesLeft.includes(54) : availableAnglesRight.includes(-54)),
      },
      {
        id: '7-4',
        name: 'Second Angle Test',
        shortName: '2nd Angle',
        value: firstSide === 2 ? 36 : -36,
        skipped: !(firstSide === 2 ? availableAnglesLeft.includes(36) : availableAnglesRight.includes(-36)),
      },
      {
        id: '7-5',
        name: 'Third Angle Test',
        shortName: '3rd Angle',
        value: firstSide === 2 ? 18 : -18,
        skipped: !(firstSide === 2 ? availableAnglesLeft.includes(18) : availableAnglesRight.includes(-18)),
      },
      {
        id: '7-6',
        name: 'Fourth Angle Test',
        shortName: '4th Angle',
        value: 0,
        skipped: !(firstSide === 2 ? availableAnglesLeft.includes(0) : availableAnglesRight.includes(0)),
      },
      {
        id: '7-7',
        name: 'Fifth Angle Test',
        shortName: '5th Angle',
        value: firstSide === 2 ? -18 : 18,
        skipped: !(firstSide === 2 ? availableAnglesLeft.includes(-18) : availableAnglesRight.includes(18)),
      },
      {
        id: '7-8',
        name: 'Sixth Angle Test',
        shortName: '6th Angle',
        value: firstSide === 2 ? -36 : 36,
        skipped: !(firstSide === 2 ? availableAnglesLeft.includes(-36) : availableAnglesRight.includes(36)),
      },
      {
        id: '7-9',
        name: 'Seventh Angle Test',
        shortName: '7th Angle',
        value: firstSide === 2 ? -54 : 54,
        skipped: !(firstSide === 2 ? availableAnglesLeft.includes(-54) : availableAnglesRight.includes(54)),
      },
    ],
  },
];

export const getIsometricNavigate = ({ torsoTestMode, firstSide, availableAnglesLeft, availableAnglesRight }) => {
  if (!firstSide || !torsoTestMode) {
    return defaultNavigateItems;
  }

  if (torsoTestMode < 3) {
    return oneSideNavigateItems(firstSide, torsoTestMode === 1 ? availableAnglesLeft : availableAnglesRight);
  }

  return bothSideNavigateItems(firstSide, availableAnglesLeft, availableAnglesRight);
};
