import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  changePassword: {
    url: `${ENV_VARIABLES.API_SERVER}users/password`,
  },
  changeHcpUserPassword: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/me/password`,
  },
  updateProfile: {
    url: `${ENV_VARIABLES.API_SERVER}users/me`,
  },
  updateHcpUserProfile: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/me/profile`,
  },
};
