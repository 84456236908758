import React, { Suspense } from 'react';

import EMRSetting from 'modules/EMR/containers/EMRSetting.jsx';
export default [
  {
    path: 'emr-settings',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <EMRSetting />
      </Suspense>
    ),
    private: true,
  },
];
