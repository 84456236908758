import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { beginLoading, endLoading } from 'modules/Ui/slice';

import { ACCESS_TOKEN } from 'config/constants';
let isLoading = false;

export default (dispatch, action) => {
  axios.interceptors.request.use((request) => {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    if (!isLoading) {
      isLoading = true;
      dispatch(beginLoading());
    }
    if (localStorage.getItem(ACCESS_TOKEN)) {
      request.headers['Authorization'] = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
    }
    return request;
  });
  axios.interceptors.response.use(
    (res) => {
      if (res && res.data && res.data.code === 403 && !res.data.error) {
        window.location.href = '/403';
      }

      if (isLoading) {
        isLoading = false;
        dispatch(endLoading());
      }
      return res;
    },
    (error) => {
      if (isLoading) {
        isLoading = false;
        dispatch(endLoading());
      }

      if (error.response) {
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          if (window.location.href.indexOf('sign-in') === -1) {
            dispatch(action({ code: error.response.status, message: 'Unauthorized' }));
          }
        }
        if (error.response.status === StatusCodes.FORBIDDEN) {
          if (error.response.data.detail === 'Insufficient Clearance') {
            window.location.href = '/403';
          }
        }
      } else {
        // Handle network errors or other unexpected situations here
        console.error('Network error or unexpected situation:', error.message);
      }
      return Promise.reject(error);
    }
  );
};
