import { useCallback, useState } from 'react';

export default function useBoolean(defaultValue) {
  const [open, setValue] = useState(!!defaultValue)

  const onOpen = useCallback(() => {
    setValue(true)
  }, [])

  const onClose = useCallback(() => {
    setValue(false)
  }, [])

  const toggle = useCallback(() => {
    setValue(x => !x)
  }, [])

  return { open, onOpen, onClose, toggle };
}
