import { createContext, useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useAuthDataContext } from 'components/AuthProvider/index.jsx';
import SocketNotification from './SocketNotification.jsx';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import ENV_VARIABLES from 'config/variables.js';
import socketActions from './constants.js';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchHcpConfiguredMachines } from 'modules/Machines/slice';
import isNil from 'lodash/isNil';

export const SocketDataContext = createContext(null);

const IGNORE_SIGNAL = ['PING', 'OPEN_DEBUG_MACHINE', 'FLIP_SWITCH', 'CLEAR_MESSAGE_QUEUE', 'STOP_GET_VALUE_BY_SWITCH', 'CLOSE_DEBUG_MACHINE']

const WebSocketProvider = (props) => {
  const { currentUser, token } = useAuthDataContext();

  const [socketUrl, setSocketUrl] = useState('');

  const [u12Id, setU12Id] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [shouldRefreshMachines, setRefreshMachines] = useState(0);
  const [detectedMachines, setDetectedMachines] = useState([]); // use for machine counts on notification, will be resetted to 0 when notification is closed
  const [openNoti, setOpenNoti] = useState({ open: false, message: '' });
  const [openPersistentNoti, setOpenPersistentNoti] = useState(false);
  const [snackPersistMessage, setPersistSnackMessage] = useState('');
  const [machine, setMachine] = useState(null);
  const intervalInstance = useRef(null);
  const pairPingPongTimeStampRef = useRef(0);
  const accessToken = localStorage.getItem('access_token');
  const hcpId = localStorage.getItem('hcpId');
  const resendMessage = useRef(null);
  const waittingMessage = useRef(null);
  const [toggleWS, setToggleWS] = useState(true);

  const dispatch = useDispatch();

  const isAuthenticated = () => !!(currentUser && currentUser.id);

  if (accessToken && socketUrl === '') {
    setSocketUrl(`${ENV_VARIABLES.WS_SERVER}?access_token=${accessToken}&hcp_id=${hcpId}`);
  }

  const handleResetMachine = () => {
    setMachine(null);
  }

  const pingPongSocketConnection = () => {
    // check ping pong socket connection every 30s
    intervalInstance.current = setInterval(() => {
      sendJsonMessage({ request: socketActions.PING });

      const reachPingTime = (Date.now() - pairPingPongTimeStampRef.current) / 1000;
      if (reachPingTime > 300) {
        console.warn('PING 10 times but server not response');
        pairPingPongTimeStampRef.current = Date.now();
      }
    }, 15000);
  };

  const handelChangeWS = useCallback((url) => {
    if (url !== socketUrl) {
      setSocketUrl(url);
    }
  }, []);

  const closePingPongConnection = () => {
    clearInterval(intervalInstance.current);

    intervalInstance.current = null;
  };

  const { sendJsonMessage, sendMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.warn('onOpen');
      pingPongSocketConnection();
      pairPingPongTimeStampRef.current = Date.now();
    },
    onClose: () => {
      console.warn('onClose', intervalInstance.current);
      closePingPongConnection();
    },
    onError: (event) => {
      console.log('Websocket error');
      console.log(event);
      const currentAccessToken = localStorage.getItem('access_token');
      const currentHcpId = localStorage.getItem('hcpId');
      if (socketUrl.indexOf(currentAccessToken) === -1 || socketUrl.indexOf(currentHcpId) === -1) {
        setSocketUrl(`${ENV_VARIABLES.WS_SERVER}?access_token=${currentAccessToken}&hcp_id=${currentHcpId}`);
      }
    },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    retryOnError: true,
    onReconnectStop: () => {
      setToggleWS(false);
      console.warn('Error to connect WS. Trying refresh connection');
      setTimeout(() => {
        setToggleWS(true);
        const currentAccessToken = localStorage.getItem('access_token');
        const currentHcpId = localStorage.getItem('hcpId');
        if (currentAccessToken && currentHcpId) {
          setSocketUrl(`${ENV_VARIABLES.WS_SERVER}?access_token=${currentAccessToken}&hcp_id=${currentHcpId}`);
        }
      }, 5000);
    }
  }, toggleWS);

  const wrapperSendJsonMessage = (payload) => {
    sendJsonMessage(payload);
    autoResendMessage(payload);
  };

  const autoResendMessage = (payload) => {
    if (resendMessage.current) {
      clearTimeout(resendMessage.current);
      resendMessage.current = null;
    }
    if (payload && payload.request && IGNORE_SIGNAL.indexOf(payload.request) === -1) {
      waittingMessage.current = payload;
      resendMessage.current = setTimeout(() => {
        wrapperSendJsonMessage(payload);
      }, 3000);
    }
  };

  useEffect(() => {
    if (detectedMachines.length > 0) {
      notifyMachineCount(detectedMachines.length);
    } else {
      handleCloseSnackBar();
    }
  }, [detectedMachines]);

  useEffect(() => {
    console.warn('readyState', readyState);

    if (token && !isAuthenticated() && readyState === ReadyState.OPEN) {
      // sendJsonMessage({ request: 'STOP' });
      setOpenNoti({ open: false, message: '' });
      setOpenPersistentNoti(false);
      setMessageHistory([]);
    }
  }, [readyState, currentUser]);

  // useEffect(() => {
  //   if (!messageHistory || (messageHistory && messageHistory.length === 0)) {
  //     if (intervalInstance.current === null) {
  //       pingPongSocketConnection();
  //       pairPingPongTimeStampRef.current = Date.now();
  //     } else {
  //       closePingPongConnection();
  //       pingPongSocketConnection();
  //       pairPingPongTimeStampRef.current = Date.now();
  //     }
  //   } else {
  //     if (intervalInstance.current !== null) {
  //       closePingPongConnection();
  //     }
  //   }
  // }, [messageHistory]);

  useEffect(() => {
    const lastMessage = lastJsonMessage;

    if (lastMessage && lastMessage.signal === socketActions.MACHINE_CONFIGURED) {
      setOpenNoti({ open: true, message: 'Machines configuration completed.' });
      setOpenPersistentNoti(false);
    }

    if (lastMessage && lastMessage.signal === socketActions.CONFIGURATION_FAILED) {
      setOpenNoti({ open: true, message: 'Machines configuration failed.', severity: 'error' });
      setOpenPersistentNoti(false);
    }
    if (waittingMessage.current && lastMessage.signal !== 'PONG') {
      clearTimeout(resendMessage.current);
      waittingMessage.current = null;
      resendMessage.current = null;
    }

    if (
      lastMessage &&
      (lastMessage.signal === socketActions.NEW_MACHINE_DETECTED ||
        lastMessage.signal === socketActions.MACHINE_DETECTED) &&
      !detectedMachines.includes(lastMessage.u12_id)
    ) {
      setDetectedMachines((prev) => prev.concat(lastMessage.u12_id));
      if (!openPersistentNoti) {
        setOpenPersistentNoti(true);
      }
    }

    if (
      lastMessage &&
      (lastMessage.signal === socketActions.NEW_MACHINE_DETECTED ||
        lastMessage.signal === socketActions.MACHINE_DETECTED ||
        lastMessage.signal === socketActions.NEW_MACHINE_DISCONNECTED ||
        lastMessage.signal === socketActions.MACHINE_DISCONNECTED)
    ) {
      setRefreshMachines(() => shouldRefreshMachines + 1);
    }

    if (lastMessage && lastMessage.signal === socketActions.PONG) {
      pairPingPongTimeStampRef.current = Date.now();
      return;
    }

    if (!lastMessage || !lastMessage.u12_id) {
      return;
    }

    if (
      lastMessage.signal === socketActions.NEW_MACHINE_DETECTED ||
      lastMessage.signal === socketActions.MACHINE_DETECTED ||
      lastMessage.signal === socketActions.NEW_MACHINE_DISCONNECTED ||
      lastMessage.signal === socketActions.MACHINE_DISCONNECTED
    ) {
      setRefreshMachines(() => shouldRefreshMachines + 1);
    }

    if (!isNil(machine?.machine)) {
      if (isNil(machine?.machine?.u12_id) && isNil(machine?.machine?.mac_addr)) {
        return;
      };

      if ((machine?.machine?.u12_id != lastMessage?.u12_id) && (machine?.machine?.mac_addr != lastMessage?.mac_address)) {
        return;
      }
    };

    setU12Id(lastMessage.u12_id);
    setMacAddress(lastMessage.mac_address);

    setMessageHistory((prev) => prev.concat(lastMessage));

    return () => {
      if (lastMessage && lastMessage.signal === socketActions.PONG) {
        return;
      }
      setRefreshMachines(() => 0);
      setMessageHistory(() => []);
    };
  }, [lastJsonMessage, machine]);

  useEffect(() => {
    if (detectedMachines.length > 0) {
      notifyMachineCount(detectedMachines.length);
    } else {
      handleCloseSnackBar();
    }
  }, [detectedMachines]);

  const notifyMachineCount = (count) => {
    if (!openPersistentNoti) {
      setOpenPersistentNoti(true);
    }

    setPersistSnackMessage(`${count} new machine${count > 1 ? 's' : ''} detected.`);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNoti({ open: false, message: '', type: openNoti.type });
  };

  const clearMessageHistory = () => {
    setMessageHistory(() => []);
  };

  const handleClosePersistentSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenPersistentNoti(false);
  };

  const handleFilterMachine = ({ machineId, machineType, machines }) => {
    if (machineId && machines.length > 0) {
      const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));
      setMachine(machine);
    };

    if (machineId && machines.length === 0) {
      dispatch(fetchHcpConfiguredMachines({ hcpId: localStorage.getItem('hcpId'), machineType: machineType }));
    }
  };

  return (
    <>
      {token && !isAuthenticated() ? null : (
        <SocketNotification
          openPersistentSnackbar={openPersistentNoti}
          openSnackbar={openNoti}
          setDetectedMachines={setDetectedMachines}
          handleClosePersistentSnackBar={handleClosePersistentSnackBar}
          handleCloseSnackBar={handleCloseSnackBar}
          snackPersistMessage={snackPersistMessage}
        />
      )}
      <SocketDataContext.Provider
        value={{
          messageHistory,
          readyState,
          sendMessage,
          sendJsonMessage: wrapperSendJsonMessage,
          clearMessageHistory,
          shouldRefreshMachines, // a trigger to refresh the machine list
          setSocketUrl: handelChangeWS,
          macAddress,
          u12Id,
          handleFilterMachine,
          handleResetMachine
        }}
        {...props}
      />
    </>
  );
};

export const useSocketDataContext = () => useContext(SocketDataContext);

export default WebSocketProvider;
