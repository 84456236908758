import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isNil from 'lodash/isNil';

const Container = styled('div')`
  table,
  td,
  th {
    border: 1px solid;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;

const TableRomPainCurrentActivity = ({ currentPainLevel, currentMaxExtension, currentMaxFlexion, activeTested, activeNet }) => {

  const currentRomPain = {
    rom: (!isNil(currentMaxExtension) && !isNil(currentMaxFlexion)) ? `${currentMaxExtension}° - ${currentMaxFlexion}°` : '-',
    painLevel: currentPainLevel - 1 >= 0 ? (currentPainLevel - 1) : '-'
  }

  return (
    <Container>
      <table>
        {/* <thead>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </thead> */}
        <thead>
          <tr style={{ background: activeTested ? '#FAE1B2' : '#fff', color: 'black' }}>
            <th style={{ width: '85px', fontSize: '14px', fontWeight: 400 }}>{currentRomPain.rom}</th>
            <th style={{ fontSize: '14px', fontWeight: 400, minWidth: '78px' }}>{currentRomPain.painLevel}</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ color: 'black' }}>
            <td style={{ width: '85px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
            <td style={{ minWidth: '78px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
          </tr>
          <tr style={{ background: activeNet ? '#FAE1B2' : '#fff', color: 'black' }}>
            <td style={{ width: '85px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
            <td style={{ minWidth: '78px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

TableRomPainCurrentActivity.propTypes = {
  currentPainLevel: PropTypes.any,
  currentMaxExtension: PropTypes.any,
  currentMaxFlexion: PropTypes.any
};

export default TableRomPainCurrentActivity;