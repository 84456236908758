import PropTypes from 'prop-types';

import MachineSetupViewContainer from 'modules/Patients/components/Machines/Base/MachineSetupView';
import StepsContainer from 'modules/Patients/components/Machines/Base/StepsContainer';
import Preparation from './Steps/Preparation';
import { BriefPatient } from './Steps/SimpleSteps';
import { TightenRestraints } from './Steps/TightenRestraints';
import { SeatingPosition } from './Steps/SeatingPosition';
import { Restraints } from './Steps/Restraints';
import CheckPositioning from './Steps/CheckPositioning';
import { Instructions } from './Steps/Instructions';
import { SetAngleRender } from './Steps/SetAngle';
import { LevelAndLockCounterWeight } from './Steps/LevelAndLockCounterWeight';
import EnterGaugeReading from './Steps/EnterGaugeReading';
import { Navigate } from 'react-router-dom';
import { useSummaryPage } from 'modules/Patients/hooks/useSummaryPage';
import { useNavigateItems } from 'modules/Patients/hooks/useMachineSetup';

export const navigationItems = [
  {
    id: '1',
    name: 'Preparation',
  },
  {
    id: '2',
    name: 'Brief the Patient',
  },
  {
    id: '3',
    name: 'Physical Positioning',
    children: [
      {
        id: '3-1',
        name: 'Seating Position',
      },
      {
        id: '3-2',
        name: 'Restraints',
      },
      {
        id: '3-3',
        name: 'Check Positioning',
      },
      {
        id: '3-4',
        name: 'Tighten Restraints',
      },
    ],
  },
  {
    id: '4',
    name: 'Instructions',
  },
  {
    id: '5',
    name: 'Find Range of Motion (RoM)',
    children: [
      {
        id: '5-1',
        name: 'Find Max Extension Angle',
      },
      {
        id: '5-2',
        name: 'Find Max Flexion Angle',
      },
    ],
  },
  {
    id: '6',
    name: 'Set Top Dead Center (TDC)',
  },
  {
    id: '7',
    name: 'Set Counterbalance',
    children: [
      {
        id: '7-1',
        name: 'Level and Lock Counterweight',
      },
      {
        id: '7-2',
        name: '18° or Max Extension Angle',
      },
      {
        id: '7-3',
        name: 'Counterweight Adjustment',
      },
    ],
  },
  {
    id: '8',
    name: 'Enter Gauge Reading',
  },
];

export default function MachineSetupView(props) {
  const { url } = useSummaryPage();
  const navigationItemsContext = useNavigateItems();
  const { Step } = StepsContainer;
  const SubStep = Step;

  return (
    <MachineSetupViewContainer {...props} navigationItems={navigationItemsContext.navigationItems}>
      <StepsContainer>
        <Step name={'Preparation'}>
          <Preparation />
        </Step>
        <Step name={'Brief the Patient'}>
          <BriefPatient />
        </Step>
        <Step name={'Physical Positioning'}>
          <SubStep name={'Seating Position'}>
            <SeatingPosition />
          </SubStep>
          <SubStep name='Restraints'>
            <Restraints />
          </SubStep>
          <SubStep name='Check Positioning'>
            <CheckPositioning />
          </SubStep>
          <SubStep name='Tighten Restraints'>
            <TightenRestraints />
          </SubStep>
        </Step>
        <Step name='Instructions'>
          <Instructions />
        </Step>
        <Step name='Find Range of Motion (RoM)'>
          <SubStep name='Find Max Extension Angle'>
            <SetAngleRender page='MAX_EXTENSION' />
          </SubStep>
          <SubStep name='Find Max Flexion Angle'>
            <SetAngleRender page='MAX_FLEXION' />
          </SubStep>
        </Step>
        <Step name='Set Top Dead Center (TDC)'>
          <SetAngleRender page='TOP_DEAD_CENTER' />
        </Step>
        <Step name='Set Counterbalance'>
          <SubStep name='Level and Lock Counterweight'>
            <LevelAndLockCounterWeight />
          </SubStep>
          <SubStep name='18° or Max Extension Angle'>
            <SetAngleRender page='COUNTER_BALANCE' />
          </SubStep>
          <SubStep name='Counterweight Adjustment'>
            <SetAngleRender page='COUNTER_WEIGHT_ADJUST' />
          </SubStep>
        </Step>
        <Step name='Enter Gauge Reading'>
          <EnterGaugeReading />
        </Step>
        <Step>
          <Navigate to={url} />
        </Step>
      </StepsContainer>
    </MachineSetupViewContainer>
  );
}

MachineSetupView.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSaveData: PropTypes.func,
  handleSelectItem: PropTypes.func,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleSetStep: PropTypes.func,
  handleSetSubStep: PropTypes.func,
  socketContext: PropTypes.object,
};
