import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import RedoIcon from '@mui/icons-material/Redo';
import Button from '@mui/material/Button';

function toLetters(num) {
  var mod = num % 26,
    pow = (num / 26) | 0,
    out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  return pow ? toLetters(pow) + out : out;
}

function isFinished(item, selectedItem) {
  const idsA = selectedItem.split('-');
  const parentIdA = idsA[0];
  const childIdA = idsA[1];

  const idsB = item.split('-');
  const parentIdB = idsB[0];
  const childIdB = idsB[1];

  //if both are children
  if (childIdA && childIdB) {
    // if both are siblings
    if (parentIdA === parentIdB) {
      if (parseInt(childIdA, 10) > parseInt(childIdB, 10)) {
        return true;
      } else {
        return false;
      }
    } else {
      // if not siblings
      if (parentIdA > parentIdB) {
        return true;
      } else {
        return false;
      }
    }
  } else if (childIdA && !childIdB) {
    // if A is child and B is parent
    if (parentIdA > parentIdB) {
      return true;
    } else {
      return false;
    }
  } else if (childIdB && !childIdA) {
    // if A is parent and B is child
    if (parentIdA > parentIdB) {
      return true;
    } else {
      return false;
    }
  } else {
    // if both are parents
    if (parentIdA > parentIdB) {
      return true;
    } else {
      return false;
    }
  }
}

// check if the selected item is a child of the current item
function isChildSelected(parent, selectedItem) {
  const idsA = selectedItem.split('-');
  const parentIdA = idsA[0];
  const childIdA = idsA[1];

  if (parentIdA === parent.id && childIdA) {
    return true;
  } else {
    return false;
  }
}

function CustomCheckBoxIcon() {
  return <CheckBoxIcon sx={{ color: 'green' }} />;
}

function ListItem({ item, index, selectedItem, handleSelect, handleSkip }) {
  if (item.children) {
    return (
      <CollapsibleListItem
        item={item}
        index={index}
        selectedItem={selectedItem}
        handleSelect={handleSelect}
        handleSkip={handleSkip}
      />
    );
  }

  const done = isFinished(item.id, selectedItem);
  const isSelected = selectedItem === item.id;
  const classNames = done ? 'finished' : isSelected ? 'selected' : '';
  const onClick = (e, id) => {
    if (e.code === 'Enter') {
      return;
    }
    handleSelect(id);
  };

  // List item without children
  return (
    <ListItemButton
      sx={{
        pointerEvents: isFinished(item.id, selectedItem) ? '' : 'none',
      }}
      className={classNames}
      onClick={(e) => onClick(e, item.id)}
    >
      <ListItemIcon sx={{ opacity: done ? 1 : 0 }}>
        <CustomCheckBoxIcon />
      </ListItemIcon>
      <ListItemText
        sx={{
          pointerEvents: 'none',
          color: done ? '#000' : '',
          span: {
            fontWeight: done ? 'bold' : 'normal',
          },
        }}
        primary={`${index}. ${item.name}`}
      />
    </ListItemButton>
  );
}

ListItem.propTypes = {
  item: PropTypes.object,
  selectedItem: PropTypes.string,
  index: PropTypes.number,
  handleSelect: PropTypes.func,
  handleSkip: PropTypes.func,
};

// List item with children
function CollapsibleListItem({ item, index, selectedItem, handleSelect, handleSkip }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const done = isFinished(item.id, selectedItem);
  const isSelected = selectedItem === item.id;
  const halfDone = isChildSelected(item, selectedItem); // means a child step is selected (in progress)
  const classNames = done ? 'finished' : isSelected ? 'selected' : '';

  // open the parent if a child is selected
  useEffect(() => {
    if (halfDone) {
      setOpen(true);
    }
  }, [halfDone]);

  const onSkip = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.code === 'Enter') {
      return;
    }
    handleSkip(item);
  };

  return (
    <>
      <ListItemButton
        sx={{
          pr: 0,
          '&:hover': {
            button: {
              background: '#E2E4E7',
              border: '1px solid #E2E4E7',
            },
          },
        }}
        disabled={item.skipped}
        className={classNames}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ opacity: done ? 1 : 0 }}>
          <CustomCheckBoxIcon />
        </ListItemIcon>
        <ListItemText
          sx={{
            color: done || halfDone ? '#000' : '',
            span: {
              fontWeight: done || halfDone ? 'bold' : 'normal',
            },
          }}
          primary={`${index}. ${item.name}`}
        />
        {item.hasSkip && (
          <Button
            sx={{
              position: 'absolute',
              right: 0,
              borderRadius: '0 12px 12px 0',
              color: '#666666',
              border: 0,
              '&:hover': {
                border: 0,
              },
              '.MuiButton-endIcon': {
                position: 'relative',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: '70%',
                  right: '50%',
                  width: 3,
                  height: 3,
                  bgcolor: '#666666',
                  borderRadius: '50%',
                },
              },
              '&.Mui-disabled': {
                border: 0,
                '.MuiButton-endIcon': {
                  position: 'relative',
                  '&:before': {
                    bgcolor: 'rgba(0, 0, 0, 0.26)',
                  },
                },
              },
            }}
            disabled={selectedItem.split('-')[0] !== item.id}
            onClick={onSkip}
            endIcon={<RedoIcon />}
            variant='outlined'
          >
            Skip
          </Button>
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {item.children.map((c, index) => (
            <CollapsibleListChildItem
              key={c.id}
              parent={item}
              item={c}
              index={index}
              selectedItem={selectedItem}
              handleSelect={handleSelect}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}

CollapsibleListItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  selectedItem: PropTypes.string,
  handleSelect: PropTypes.func,
  handleSkip: PropTypes.func,
};

// child of CollapsibleListItem
function CollapsibleListChildItem({ item, parent, index, handleSelect, selectedItem }) {
  const done = isFinished(item.id, selectedItem);
  const isSelected = selectedItem === item.id;
  const classNames = done ? 'finished' : isSelected ? 'selected' : '';
  const onClick = (e, id) => {
    if (e?.code === 'Enter') {
      return;
    }
    handleSelect(id);
  };

  return (
    <ListItemButton
      disabled={parent.skipped || item.skipped}
      className={classNames}
      onClick={(e) => onClick(e, item.id)}
      key={item.id}
      sx={{ pl: 4, pointerEvents: isFinished(item.id, selectedItem) ? '' : 'none' }}
    >
      <ListItemIcon sx={{ opacity: done ? 1 : 0 }}>
        <CustomCheckBoxIcon />
      </ListItemIcon>
      <ListItemText
        sx={{
          color: done ? '#666' : '',
          span: {
            fontWeight: done ? 'bold' : 'normal',
          },
        }}
        primary={`${toLetters(index + 1).toLowerCase()}. ${item.name}`}
      />
    </ListItemButton>
  );
}

CollapsibleListChildItem.propTypes = {
  item: PropTypes.object,
  parent: PropTypes.object,
  index: PropTypes.number,
  selectedItem: PropTypes.string,
  handleSelect: PropTypes.func,
};

export default function SideBar({ step, subStep, handleSelectItem, handleSkipItem, navigationItems, topSmg }) {
  const [selectedItem, setSelectedItem] = useState('1');

  useEffect(() => {
    if (subStep && subStep !== '0') {
      setSelectedItem(`${step}-${subStep}`);
    } else {
      setSelectedItem(`${step}`);
    }
  }, [step, subStep]);

  // Only allow selecting item that is before the current selected item
  const handleSelect = (id) => {
    if (isFinished(id, selectedItem)) {
      handleSelectItem({
        step: id.split('-')[0],
        subStep: id.split('-')[1] || '0',
      });
    }
  };

  return (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: { autoHide: 'scroll' },
        paddingAbsolute: true,
      }}
      style={{ minHeight: 'calc(100vh - 225px)' }}
    >
      <List
        sx={{
          pt: 0,
          width: '100%',
          minWidth: 322,
          maxWidth: 360,
          color: '#666666',
          bgcolor: 'background.paper',
          '.MuiListItemIcon-root': { minWidth: 0, mr: 1 },
          '.MuiListItemButton-root': {
            mb: 1,
            py: '2px',
            border: '1px solid transparent',
            borderRadius: '12px',
            '&:hover': { border: '1px solid #D4D4D4', bgcolor: '#FDFDFD' },
            '&.selected': {
              border: '1px solid #D4D4D4',
              bgcolor: '#FDFDFD',
              color: 'primary.main',
              span: { fontWeight: 'bold' },
            },
          },
        }}
        component='nav'
        aria-labelledby='nested-list-subheader'
      >
        {topSmg && <div style={{ paddingBottom: `10px` }}>{topSmg}</div>}
        {navigationItems.map((item, index) => (
          <ListItem
            key={item.id}
            item={item}
            index={index + 1}
            selectedItem={selectedItem}
            handleSkip={handleSkipItem}
            handleSelect={handleSelect}
          />
        ))}
      </List>
    </OverlayScrollbarsComponent>
  );
}

SideBar.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleSelectItem: PropTypes.func,
  handleSkipItem: PropTypes.func,
  navigationItems: PropTypes.array,
  topSmg: PropTypes.string,
};
