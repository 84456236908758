import { useState, useEffect } from 'react';
import axios from 'axios';
import { END_POINT } from 'modules/Auth/constants';
import { useSelector } from 'react-redux';
import { ACCESS_TOKEN } from 'config/constants';

function useCurrentUser() {
  //check for existing data to set as default
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [user, setUser] = useState(currentUser ? currentUser : null);
  const [permissions, setPermissions] = useState(
    currentUser && currentUser.id ? currentUser.role.permission_groups : []
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem(ACCESS_TOKEN);

  useEffect(() => {
    // fetch new data
    async function fetchData() {
      if (token) {
        try {
          const hcpId = localStorage.getItem('hcpId');
          const response = await axios.get(`${END_POINT.customerProfile.url(hcpId)}`);
          const res = response.data.data;
          setUser(res);
          setPermissions(res.role.permission_groups);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      }
    }

    fetchData();
  }, [token]);

  return user ? { user, permissions } : { loading, error };
}

export default useCurrentUser;
