import React, { Suspense } from 'react';

const ManageRolesPermissions = React.lazy(() => import('modules/Permissions/containers/ManageRolesPermissions'));

const route = [
  {
    path: '/manage/perms',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ManageRolesPermissions />
      </Suspense>
    ),
    private: true,
  },
];
export default route;
