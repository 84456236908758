import Typography from '@mui/material/Typography';
import Actions from 'modules/Patients/components/Machines/Actions';
import PropTypes from 'prop-types';

export function PracticeTestInstruction({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Typography variant='body1' component='div'>
        1. Have the patient rest after dynamic warm-up while practice test instructions are explained.
        <br />
        2. Provide following instructions to the patient:
        <br />
        <div style={{ padding: '0 20px' }}>
          • Tests will be conducted at 3 random test angles positions throughout the ROM.
          <br />
          • Only exert force as requested by clinician.
          <br />• Only exert up to 50% max force during practice test to the cadence: push 25%, 50%, and relax.
        </div>
        3. Snug the footboard adjustment while patient squeeze pad between their knees.
        <br />
        4. Snug the hip restraint.
        <br />
        5. Snug the torso restraint.
        <br />
        6. Remind patient to maintain hands resting in their lap and to maintain contact with the seatback.
        <br />
      </Typography>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
PracticeTestInstruction.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
