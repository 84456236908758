import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function Status({ text, color }) {
  return (
    <Button
      sx={{
        justifyContent: 'flex-start',
        padding: '0',
        pointerEvents: 'none',
        border: 0,
        '&:hover': {
          cursor: 'auto',
          border: 0,
          backgroundColor: 'transparent',
        },
      }}
      variant='outlined'
      color={color}
    >
      {text}
    </Button>
  );
}

Status.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};
