import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import { useEffect, useState } from 'react';

import { InputText } from '../../../../../../../../components/InputText';

export default function GatePosition({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData }) {
  const [gatePos, setGatePos] = useState(null);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setGatePos(setupMachineData.torso_pad_pos ?? null);
  }, [setupMachineData]);

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              1. Use the Gate Height Adjustment to raise or lower the Torso Restraints to the correct position.
              <br />
              2. Adjust the Gate Position according to information below:
              <Box>
                <div>
                  <b>Patient Setup Gate Position:</b>
                </div>
                <Box sx={{ width: '200px' }}>
                  <InputText disable={true} value={gatePos} type={'number'} step={'0.25'} numberRange={[0, 8.5]} />
                </Box>
                <br />
                3. Rotate the Gate Lock inwards to lock the gate, and tighten the Torso Restraints. Avoid over
                tightening which might restrict blood flow.
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              4. Have patient turn heels slightly out and toes slightly in on the footboard.
              <br />
              5. Have patient squeeze the pad in between their knees while tightening footboard.
              <br />
              6. Tighten footboard adjustment until patient if very snug and they cannot lift their heels of off
              footboard.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

GatePosition.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
