import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { SingleImageStep } from 'helpers';

import lapBelt from 'assets/images/SVG/Lumbar/Lumbar_lap_belt.svg';
import footRest from 'assets/images/png/dynamic-test/4a.png';
import Actions from 'modules/Patients/components/Machines/Actions';

export function PatientInstruction({ step, subStep, handleCancel, handleBack, handleNext, disableFirstStep }) {
  return (
    <>
      <Typography variant='body1' component='div'>
        1. Move the patient into a comfortable upright position, approximately 12°.
        <br />
        2. Provide the following dynamic warm-up instructions:
        <div style={{ padding: '0 20px' }}>
          • Exercise through the full range of motion.
          <br />
          • Exercise is both positive and negative.
          <br />
          • Perform each repetition slowly (2-1-4) second count.
          <br />
          • Visual feedback is provided on the monitor.
          <br />
          • You will get an audible warning when you have completed the full range.
          <br />
          • Demonstrate how to push against the resistance pad.
          <br />
          • Avoid holding your breath, exhale when building force.
          <br />
          • Maintain a loose grip on the operator handles.
          <br />
          • Maintain contact with the headrest.
          <br />• Exert force only when clinician instructs to do so.
        </div>
      </Typography>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
        disableBack={disableFirstStep}
      />
    </>
  );
}
PatientInstruction.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disableFirstStep: PropTypes.bool,
};

export function PatientAdjustments({ step, subStep, handleCancel, handleBack, handleNext }) {
  // const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 3,
        }}
      >
        <Box>
          <SingleImageStep img={lapBelt} />
        </Box>

        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant='body1' component='div'>
            1. Make sure patient is snug in the machine.
            <br />
            2. Tighten lap belt adjuster and footrest adjuster.
            <br />
            3. Have patient lean forward and touch toes while watching for movement in lumbar pad, then tighten
            additionally as needed.
          </Typography>
        </Box>
      </Box>
      <br />
      <Box
        sx={{
          gap: 3,
          display: 'flex',
        }}
      >
        <Box>
          <SingleImageStep img={footRest} />
        </Box>

        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant='body1' component='div'>
            4. Have the patient grab operator handles.
          </Typography>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </>
  );
}
PatientAdjustments.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
