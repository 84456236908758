import PropTypes from 'prop-types';
import { useAuthDataContext } from 'components/AuthProvider/index.jsx';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrivateRoute from 'components/AppRoute/PrivateRoute.jsx';
import PublicRoute from 'components/AppRoute/PublicRoute.jsx';

export default function AppRoute({ routes }) {
  const dispatch = useDispatch();
  const { currentUser, token } = useAuthDataContext();
  const isAuthenticated = () => !!(currentUser && currentUser.id);

  if (token && !isAuthenticated()) {
    return null;
  }

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          path={route.path}
          key={route.path}
          state={route.state}
          element={
            route.private ? (
              <PrivateRoute authed={isAuthenticated()}>{route.element}</PrivateRoute>
            ) : (
              <PublicRoute authed={isAuthenticated()} route={route} dispatch={dispatch}>
                {route.element}
              </PublicRoute>
            )
          }
        />
      ))}
    </Routes>
  );
}

AppRoute.propTypes = {
  routes: PropTypes.array,
};
