import MachineSetupView from 'modules/Patients/components/Machines/Knee/MachineSetup';

import NoResults from 'modules/Patients/components/NoResults';
import { useMachineSetup } from 'modules/Patients/hooks/useMachineSetup';
import MachineSetupContainer from 'modules/Patients/components/MachineSetupContainer';
import { useEffect } from 'react';
import { getNavigate } from './machineSetupNavigateItems';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function MachineSetup() {
  const {
    Provider,
    handleBack,
    handleNext,
    handleSaveData,
    handleSelectItem,
    isLoading,
    machine,
    registerNavigation,
    socketContext,
    patient,
    step,
    subStep,
    handleSetStep,
    handleSetSubStep,
    navigationItems,
    setupMachineData,
    setupId,
    machineId,
    machineType,
    sessionId,
    setNavigationItems,
  } = useMachineSetup();

  const navigate = useNavigate();
  const isInitSetups = useSelector((state) => state.patients.isInitSetups);

  useEffect(() => {
    if (setupMachineData && setupMachineData.data) {
      setNavigationItems(getNavigate(setupMachineData.data.knee_test_mode));
      return;
    }
    //knee_test_mode 1 left 2 right 3 both 4 both-left 5 both right

    setNavigationItems(getNavigate(undefined));
  }, [setupMachineData]);

  useEffect(() => {
    if (setupMachineData && setupMachineData.data) {
      if (!setupMachineData.data.knee_test_mode && step !== "1") {
        handleSetSubStep("0");
        handleSetStep("1");
      }
    }

  }, [step, subStep, isInitSetups]);

  if (!patient || !machine) return <NoResults isLoading={isLoading} />;

  return (
    <Provider value={{ registerNavigation: registerNavigation, navigationItems }}>
      <MachineSetupContainer>
        <MachineSetupView
          patient={patient}
          machine={machine}
          handleSaveData={handleSaveData}
          handleNext={handleNext}
          handleBack={handleBack}
          handleSelectItem={handleSelectItem}
          step={step}
          subStep={subStep}
          handleSetStep={handleSetStep}
          handleSetSubStep={handleSetSubStep}
          socketContext={socketContext}
        />
      </MachineSetupContainer>
    </Provider>
  );
}
