import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';

export const ProcessBar = ({ percent }) => {
  const barRef = useRef(null);
  useEffect(() => {
    if (!barRef.current || !percent) {
      return;
    }
    barRef.current.style.width = `${percent}%`;
  }, [percent]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '10px',
        overflow: 'hidden',
        borderRadius: '8px',
        background: '#E9ECEF',
      }}
    >
      <Box
        ref={barRef}
        sx={{
          height: '100%',
          width: 0,
          background: '#5FCB97',
          transition: '0.5s',
          borderRadius: '8px',
        }}
      ></Box>
    </Box>
  );
};

ProcessBar.propTypes = {
  percent: PropTypes.number,
};

export default ProcessBar;
