import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditPatientDialog } from 'modules/Patients/components/Dialogs';

import { closeDialog } from 'helpers/dialog';
import { DIALOGS } from 'modules/Dialogs/constants';
import { END_POINT } from 'modules/Patients/constants';
import { requestEditPatient } from 'modules/Patients/slice';

export default function EditPatient() {
  const dispatch = useDispatch();
  const patientError = useSelector((state) => state.patients.error);
  const openEditPatientDialog = useSelector((state) => state.dialogs[DIALOGS.EDIT_PATIENT_DIALOG]);
  const patient = useSelector((state) => state.patients.patient);
  // patient's location
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [defaultState, setDefaultState] = useState(null);
  const [defaultCity, setDefaultCity] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (patient && mounted) {
      // get default country (country of the patient)
      axios.get(`${END_POINT.getCountries.url}&name=${encodeURIComponent(patient.country)}`).then((resp) => {
        if (resp.data) {
          const countries = resp.data;
          setDefaultCountry(countries[0]);
        }
      });
    }

    return () => {
      mounted = false;
      setDefaultCountry(null);
      setDefaultState(null);
      setDefaultCity(null);
    };
  }, [patient]);

  // get default state (state of the patient)
  useEffect(() => {
    let mounted = true;
    if (mounted && patient && defaultCountry) {
      if (patient.state === 'None') {
        setDefaultState({ name: 'None', iso2: -1 });
      } else {
        axios
          .get(`${END_POINT.getRegions.url(defaultCountry.iso2)}&name=${encodeURIComponent(patient.state)}`)
          .then((resp) => {
            if (resp.data) {
              setDefaultState(resp.data.find((state) => state.name === patient.state));
            }
          });
      }
    }

    return () => {
      mounted = false;
      setDefaultState(null);
    };
  }, [defaultCountry]);

  // get default city (city of the patient)
  useEffect(() => {
    let mounted = true;
    if (mounted && patient && defaultState) {
      if (patient.city === 'None') {
        setDefaultCity({ name: 'None', id: -1 });
      } else {
        axios
          .get(
            `${END_POINT.getCitiesFromRegions.url(defaultCountry.iso2, defaultState.iso2)}&name=${encodeURIComponent(
              patient.city
            )}`
          )
          .then((resp) => {
            if (resp.data) {
              setDefaultCity(resp.data[0]);
            }
          });
      }
    }

    return () => {
      mounted = false;
      setDefaultCity(null);
    };
  }, [defaultState]);

  const handleCloseEditPatientDialog = () => {
    closeDialog(dispatch, DIALOGS.EDIT_PATIENT_DIALOG);
  };

  const getDate = (dateTime) => {
    if (dateTime && (typeof dateTime === 'string' || dateTime instanceof String)) {
      const [year, month, day] = dateTime.split('/');
      return `${year}-${month}-${day}`;
    }

    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const onSubmitEditPatientDialog = (data) => {
    const patient = {
      patientId: data.patientId,
      hcpId: localStorage.getItem('hcpId'),
      fname: data.firstName.trim().replace(/\s+/g, ' '),
      lname: data.lastName.trim().replace(/\s+/g, ' '),
      dob: getDate(data.dob),
      gender: data.gender,
      weight: data.weight,
      height: data.height,
      address: data.address.trim().replace(/\s+/g, ' '),
      city: data.city.id !== -1 ? data.city.name : 'None',
      state: data.state.iso2 !== -1 ? data.state.name : 'None',
      country: data.country.name,
      email: data.email.toLowerCase(),
      zip_code: data.zipCode.trim().replace(/\s+/g, ' '),
      mobile_no: data.mobile.trim(),
      home_no: data.homePhone,
      notes: data.note,
      type: data.type,
    };
    dispatch(requestEditPatient(patient));
  };

  if (!defaultCountry || !defaultState || !defaultCity) {
    return <></>;
  }

  return (
    <EditPatientDialog
      defaultCountry={defaultCountry}
      defaultState={defaultState}
      defaultCity={defaultCity}
      patient={patient}
      anchor='right'
      onSubmitEditPatientDialog={onSubmitEditPatientDialog}
      error={patientError}
      openEditPatientDialog={openEditPatientDialog}
      handleCloseEditPatientDialog={handleCloseEditPatientDialog}
    />
  );
}
