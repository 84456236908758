import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ViewPatient from 'modules/Patients/components/ViewPatient';
import PatientSessions from 'modules/Patients/containers/Session/PatientSessions';
import EditPatient from 'modules/Patients/containers/EditPatient';
import useCurrentUser from 'hooks/useCurrentUser.js';
import { PatientInfo } from 'modules/Patients/components/Info';
import { DIALOGS } from 'modules/Dialogs/constants';
import { isValidBase64 } from 'helpers';
import { openDialog } from 'helpers/dialog';
import { fetchPatient, clearPatientSessions } from 'modules/Patients/slice';
import { Container } from './styled.js';

export default function ManagePatient() {
  const patient = useSelector((state) => state.patients.patient);
  const { isLoading } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, setValue] = useState('0');
  const { permissions: currentPermissions } = useCurrentUser();

  const { id } = useParams();
  const patientId = id.split('&tab=')[0];
  const otherParams = id.split('&tab=')[1];
  let tabId = '';
  if (otherParams) {
    const parts = otherParams.split('&');
    if (parts.length === 1) {
      tabId = parts[0];
    } else {
      parts.forEach((part) => {
        if (part.startsWith('setupId=')) {
          tabId = '1';
        }
      });
    }
  }

  const hasViewPermission = () => {
    return currentPermissions && currentPermissions.includes('SEARCH_AND_VIEW_PATIENT');
  };

  const hasViewHistoryPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_HISTORY');
  };

  const handleChangeTab = (event, newValue) => {
    const path = `/patient/${btoa(patient.patient_id)}`;
    navigate(`${path}&tab=${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    if (!hasViewPermission()) {
      window.location.href = '/403';
      return;
    }

    // clear sessions of previous patient from redux
    dispatch(clearPatientSessions());

    if (patientId && isValidBase64(patientId)) {
      const data = {
        patientId: atob(patientId),
        hcpId: localStorage.getItem('hcpId'),
      };
      dispatch(fetchPatient(data));

      const path = `/patient/${patientId}`;
      let tab = '0';
      if (tabId && hasViewHistoryPermission()) {
        tab = tabId;
      }
      navigate(`${path}&tab=${tab}`);
      setValue(tab);
    }
  }, [patientId]);

  const handleOpenEditPatientDialog = () => {
    openDialog(dispatch, DIALOGS.EDIT_PATIENT_DIALOG);
  };

  const renderMessage = (message) => {
    return (
      <Container
        style={{
          minHeight: 'calc(100vh - 70px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ wordBreak: 'break-all', color: 'gray', textAlign: 'center' }} variant='h5' component='div'>
          {message}
        </Typography>
      </Container>
    );
  };

  if (!patient) return renderMessage(isLoading ? 'Loading...' : t('common:errors.noResults'));

  return (
    <Container>
      <TabContext value={value}>
        <Box className={'header'}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box className={'header-name'}>Patient Details</Box>
              <PatientInfo patient={patient} />
            </Box>

            <TabList onChange={handleChangeTab}>
              {hasViewPermission() && <Tab label='Patient Information' value={'0'} />}
              {hasViewHistoryPermission() && <Tab label='Patient Sessions' value={'1'} />}
            </TabList>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            bgcolor: 'grey1',
            width: '100%',
          }}
        >
          <Box
            sx={{
              height: 'calc(100vh - 200px)',
              overflow: 'auto',
              width: '100%',
            }}
          >
            {hasViewPermission() && (
              <Box maxWidth='lg'>
                <TabPanel value={'0'}>
                  <ViewPatient onOpenEditPatientDialog={handleOpenEditPatientDialog} patient={patient} />
                </TabPanel>
              </Box>
            )}
            {hasViewHistoryPermission() && (
              <TabPanel value={'1'}>
                <PatientSessions patient={patient} />
              </TabPanel>
            )}
          </Box>
          <EditPatient />
        </Box>
      </TabContext>
    </Container>
  );
}
