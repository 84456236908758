import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import Actions from 'modules/Patients/components/Machines/Actions';

export function PracticeTestInstruction({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Typography variant='body1' component='div'>
        1. Have the patient rest after dynamic warm-up while practice test instructions are explained.
        <br />
        2. Provide following instructions to the patient:
        <br />
        <div style={{ padding: '0 20px' }}>
          • Tests will be conducted at 3 random test angles positions throughout the ROM.
          <br />
          • Only exert force as requested by clinician.
          <br />• Only exert up to 50% max force during practice test to the cadence: push 25%, 50%, and relax.
        </div>
        3. Make sure the patient is snug in the machine: Tighten lap belt adjuster and footrest adjuster.
        <br />
        4. Have the patient lean forward and touch toes while watching for movement in the lumbar pad, then tighten
        additionally as needed.
        <br />
        5. Have patient grab operator handles.
      </Typography>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
PracticeTestInstruction.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function ActualTestInstruction({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Typography variant='body1' component='div'>
        1. Provide following instructions to the patient:
        <br />
        <div style={{ padding: '0 20px' }}>
          • Tests will be conducted at pre-set test angles positions selected on the previous screen, which are within
          your pain-free ROM.
          <br />
          • Only exert force as requested by clinician.
          <br />• Gradually exert your max force during practice test to the cadence: push 25%, 50%, 75%, 100% and
          relax.
        </div>
        2. Make sure the patient is snug in the machine: Tighten lap belt adjuster and footrest adjuster.
        <br />
        3. Have the patient lean forward and touch toes while watching for movement in the lumbar pad, then tighten
        additionally as needed.
        <br />
        4. Have patient grab operator handles.
      </Typography>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
ActualTestInstruction.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
