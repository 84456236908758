import { Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const NoResults = ({ isLoading }) => {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        minHeight: 'calc(100vh - 70px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ wordBreak: 'break-all', color: 'gray' }} variant='h5' component='div'>
        {isLoading ? 'Loading...' : t('common:errors.noResults')}
      </Typography>
    </Container>
  );
};

NoResults.propTypes = {
  isLoading: PropTypes.bool,
};

export default NoResults;
