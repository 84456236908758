import PropTypes from 'prop-types';
import Actions from 'modules/Patients/components/Machines/Actions';
import IsometricRechart from '../../../../../../../../components/Charts/IsometricReChart';
import { useEffect, useState } from 'react';
import { getActualData } from './ultil';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NormTable from '../../../../../../containers/DataComparison/STDTest/NormTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNormData } from '../../../../../../slice';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';
import { useSearchParams } from 'react-router-dom';
import { preprocessingNormData } from 'helpers';

const OneSideSummary = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  disableBack,
  firstSide,
  testMode,
  defaultData,
  defaultAngles,
  setupMachineData,
}) => {
  const [data, setData] = useState([]);
  const [xAxis, setXAxis] = useState([]);
  const [currentSide, setCurrentSide] = useState(0);
  const [showLine, setShowLine] = useState(false);
  const [showNormLine, setShowNormLine] = useState(false);
  const [showStd, setShowStd] = useState(false);
  const [searchParams] = useSearchParams();
  const setupId = searchParams.get('setupId');
  const dispatch = useDispatch();

  const normData = useSelector((state) => state.patients.normData);

  useEffect(() => {
    const payload = {
      normType: 'absolute',
      testType: 'isometric',
      setup_id: setupId,
    };
    dispatch(fetchNormData(payload));
  }, []);

  useEffect(() => {
    if (testMode < 3) {
      setCurrentSide(firstSide);
      return null;
    }

    if (step === '6') {
      setCurrentSide(firstSide);
      return null;
    }
    setCurrentSide(firstSide === 1 ? 2 : 1);
  }, [firstSide]);

  useEffect(() => {
    if (!normData) {
      return null;
    }
    if (!data) {
      return null;
    }

    if (!currentSide) {
      return null;
    }
    setData(preprocessingNormData(data, normData, currentSide));
  }, [normData, currentSide]);

  useEffect(() => {
    if (!defaultAngles || !subStep || !defaultData) {
      return null;
    }

    setXAxis(defaultAngles);
    const [chart] = getActualData({
      pureData: defaultData,
      subStep: subStep,
      angles: defaultAngles,
    });

    setData(chart);
  }, [defaultAngles, defaultData, subStep]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
            <Box sx={{ alignSelf: 'center', fontWeight: 700 }}>{currentSide === 1 ? 'Left Side' : 'Right Side'}</Box>
            <IsometricRechart
              yAxisRange={ISOMETRIC_YAXIS.ROTARY_TORSO}
              showLine={showLine}
              showNormLine={showNormLine}
              showActive={false}
              xAxisRange={xAxis}
              showStd={showStd}
              reversed={currentSide === 2}
              data={data || []}
            />

            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', padding: '20px 0' }}>
              <FormControlLabel
                control={<Checkbox color={'secondary'} checked={showLine} onChange={() => setShowLine(!showLine)} />}
                label='Overlay Line Graph'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={showNormLine}
                    onChange={() => setShowNormLine(!showNormLine)}
                  />
                }
                label='Normative Data'
              />
              <FormControlLabel
                control={<Checkbox color={'secondary'} checked={showStd} onChange={() => setShowStd(!showStd)} />}
                label='1 Std Deviation Above'
              />
            </Box>

            <Box sx={{ width: '100%' }}>
              <NormTable data={(data && [...data].reverse()) || []}></NormTable>
            </Box>
          </div>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              Max Left ROM:{' '}
              {setupMachineData.range_motion_max !== null ? `${setupMachineData.range_motion_max}°` : 'N/A'}
              <br />
              Max Right ROM:{' '}
              {setupMachineData.range_motion_min !== null ? `${setupMachineData.range_motion_min}°` : 'N/A'}
              <br />
              <span>
                Gate Position: {setupMachineData.torso_pad_pos !== null ? `${setupMachineData.torso_pad_pos}` : 'N/A'}
              </span>
              <br />
              <span>
                {' '}
                Footboard Restraint:{' '}
                {setupMachineData.footboard_pos !== null ? `${setupMachineData.footboard_pos}` : 'N/A'}
              </span>
              <br />
              Seat pad: {setupMachineData.seat_pad}
            </Box>
          </Box>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handleBack={handleBack}
        disableBack={disableBack}
      />
    </>
  );
};

OneSideSummary.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disableBack: PropTypes.bool,
  firstSide: PropTypes.number,
  testMode: PropTypes.number,
  defaultData: PropTypes.object,
  defaultAngles: PropTypes.array,
  machine: PropTypes.object,
  setupMachineData: PropTypes.object,
  wuData: PropTypes.object,
  practiceTest: PropTypes.object,
};

export default OneSideSummary;
