import ManagePatient from 'modules/Patients/containers/ManagePatient';
import MachineSelect from 'modules/Patients/containers/Session/MachineSelect';
import MachineSetup from 'modules/Patients/containers/Machines/MachineSetup';
import SelectPatientMachineSetup from './containers/Session/SelectPatientMachineSetup';
import PatientsSessionDetails from './containers/Session/PatientSessionsDetail';
import MachineSetupSetupSummary from './containers/Machines/SetupSummary';
import IsometricSummary from './containers/Machines/IsometrictSummary';
import DynamicTestSummary from './containers/Machines/DynamicSummary';
import Dynamic from 'modules/Patients/containers/Machines/Dynamic';
import Isometric from 'modules/Patients/containers/Machines/Isometric';
import DuplicateAndEdit from './containers/Machines/DuplicateAndEdit';

export default [
  {
    path: '/patient/:id',
    element: <ManagePatient />,
    private: true,
  },
  {
    path: '/patient/:id/session/select-machine',
    element: <MachineSelect />,
    private: true,
  },
  {
    path: '/patient/:id/session/machine-setup',
    element: <MachineSetup />,
    private: true,
  },
  {
    path: '/patient/:id/session/isometric-test',
    element: <Isometric />,
    private: true,
  },
  {
    path: '/patient/:id/session/dynamic-test',
    element: <Dynamic />,
    private: true,
  },
  {
    path: '/patient/:id/setups',
    element: <SelectPatientMachineSetup />,
    private: true,
  },
  {
    path: '/patient/:id/machine/:machineId/setups/:setupId/summary-edit',
    element: <DuplicateAndEdit />,
    private: true,
  },
  {
    path: '/patient/:id/patient-detail',
    element: <PatientsSessionDetails />,
    private: true,
  },

  {
    path: '/patient/:id/machine-setup/summary',
    element: <MachineSetupSetupSummary />,
    private: true,
  },

  {
    path: '/patient/:id/isometric-test/summary',
    element: <IsometricSummary />,
    private: true,
  },
  {
    path: '/patient/:id/dynamic-test/summary',
    element: <DynamicTestSummary />,
    private: true,
  },
];
