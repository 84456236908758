import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  requestAddPatient,
  requestAddPatientSuccess,
  requestAddPatientFailure,
  requestEditPatient,
  requestEditPatientSuccess,
  requestEditPatientFailure,
  fetchPatient,
  fetchPatientSuccess,
  fetchPatientFailure,
  archivePatient,
  archivePatientSuccess,
  archivePatientFailure,
  searchPatient,
  searchPatientSuccess,
  searchPatientFailure,
  fetchMachineSetup,
  fetchMachineSetupSuccess,
  fetchMachineSetupFailure,
  requestInitSetup,
  requestInitSetupSuccess,
  requestInitSetupFailure,
  requestSaveSetup,
  requestSaveSetupSuccess,
  requestSaveSetupFailure,
  requestDeleteSetup,
  requestDeleteSetupSuccess,
  requestDeleteSetupFailure,
  fetchPatientSessions,
  fetchPatientSessionsSuccess,
  fetchPatientSessionsFailure,
  requestCreateSession,
  requestCreateSessionSuccess,
  requestCreateSessionFailure,
  requestEndSession,
  requestEndSessionSuccess,
  requestEndSessionFailure,
  fetchPatientSetups,
  fetchPatientSetupSuccess,
  fetchPatientSetupFailure,
  fetchPatientSession,
  fetchPatientSessionSuccess,
  fetchPatientSessionFailure,
  requestInitActivity,
  requestInitActivitySuccess,
  requestInitActivityFailure,
  fetchNormData,
  fetchNormDataSuccess,
  fetchNormDataFailure,
  fetchActivitiesSuccess,
  fetchActivitiesFailure,
  fetchActivities,
  putActivityNoteSuccess,
  putActivityNote,
  putActivityNoteFailure,
  updateDemoGraphicQuestion,
  requestFetchAllIsoActivitiesSuccess,
  requestFetchAllIsoActivitiesFailed,
  requestFetchAllIsoActivities,
  requestUpdatePrevIsoActivity,
  requestUpdateFirstIsoActivity,
  requestUpdatePrevRomPain,
  requestUpdateFirstRomPain,
  requestFetchFirstIsoActivitySuccess,
  requestFetchPrevIsoActivitySuccess,
  requestUpdateListDataMachineSetup,
  requestFetchPrevIsoActivity,
  requestFetchFirstIsoActivity,
  requestFetchPrevIsoActivityFailed,
  requestFetchFirstIsoActivityFailed,
} from './slice';
import { requestDialog } from 'modules/Dialogs/slice';
import { requestToast } from 'modules/Ui/slice';
import { TOAST_TYPE } from 'modules/Ui/constants';
import { END_POINT } from './constants';
import { DIALOGS } from 'modules/Dialogs/constants';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { getArrDegreeMatchToCompare } from './utils';

// for Patients

function* addPatient(action) {
  try {
    const { data } = yield axios.post(END_POINT.addPatient.url(action.payload.hcpId), action.payload);
    if (data.code === 201) {
      yield put(requestAddPatientSuccess(data));
    } else {
      yield put(requestAddPatientFailure(data));
    }
  } catch (e) {
    yield put(requestAddPatientFailure(e.message));
  }
}

function* editPatient(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.getPatient.url(action.payload.hcpId, action.payload.patientId),
      action.payload
    );
    if (data.code === 200) {
      yield put(requestDialog({ type: DIALOGS.EDIT_PATIENT_DIALOG, open: false }));
      yield put(requestEditPatientSuccess(data));

      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'Patient information updated successfully.',
        })
      );
      yield put(
        fetchPatient({
          patientId: action.payload.patientId,
          hcpId: action.payload.hcpId,
        })
      );
    } else {
      yield put(requestEditPatientFailure(data));

      if (data?.subcode !== 400.3) {
        yield put(
          requestToast({
            type: TOAST_TYPE.ERROR,
            message: data.error ?? 'Something went wrong!',
          })
        );
      }

    }
  } catch (e) {
    yield put(requestEditPatientFailure(e.message));

    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: e.message ?? 'Something went wrong!',
      })
    );
  }
}

function* getPatient(action) {
  try {
    const { data } = yield axios.get(END_POINT.getPatient.url(action.payload.hcpId, action.payload.patientId));
    if (data.code === 200) {
      yield put(fetchPatientSuccess(data));
    } else {
      yield put(fetchPatientFailure(data));
    }
  } catch (e) {
    yield put(fetchPatientFailure(e.message));
  }
}

function* getSearchPatient(action) {
  const query = action.payload || {};
  const patientId = query.patientId;
  const fname = query.fname;
  const lname = query.lname;
  const dob = query.dob;
  const gender = query.gender;
  const address = query.address;
  const city = query.city;
  const state = query.state;
  const country = query.country;
  const email = query.email;
  const zip_code = query.zip_code;
  const mobile_no = query.mobile_no;
  const home_no = query.home_no;

  let queryString = [];
  if (patientId) {
    queryString.push(`patient_id=${patientId}`);
  }
  if (fname) {
    queryString.push(`fname=${encodeURIComponent(fname)}`);
  }
  if (lname) {
    queryString.push(`lname=${encodeURIComponent(lname)}`);
  }
  if (dob) {
    queryString.push(`dob=${dob}`);
  }
  if (gender) {
    queryString.push(`gender=${gender}`);
  }
  if (address) {
    queryString.push(`address=${encodeURIComponent(address)}`);
  }
  if (city) {
    queryString.push(`city=${city}`);
  }
  if (state) {
    queryString.push(`state=${state}`);
  }
  if (country) {
    queryString.push(`country=${country}`);
  }
  if (email) {
    queryString.push(`email=${encodeURIComponent(email)}`);
  }
  if (zip_code) {
    queryString.push(`zip_code=${zip_code}`);
  }
  if (mobile_no) {
    queryString.push(`mobile_no=${mobile_no}`);
  }
  if (home_no) {
    queryString.push(`home_no=${home_no}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.searchPatient.url(action.payload.hcpId)}${queryString ? `?${queryString}` : ''}`
    );

    if (data.code === 200) {
      yield put(searchPatientSuccess(data));
      return;
    }

    if (data.code === 400 && data.error === 'Patient not found') {
      yield put(
        searchPatientSuccess({
          code: 200,
          count: 0,
        })
      );
      return;
    }

    yield put(searchPatientFailure(data));
  } catch (e) {
    yield put(searchPatientFailure(e.message));
  }
}

function* getMachineSetup(action) {
  try {
    const { data } = yield axios.get(
      END_POINT.getMachineSetup.url(
        action.payload.hcpId,
        action.payload.patientId,
        action.payload.machineId,
        action.payload.setupId
      )
    );
    if (data.code === 200) {
      yield put(fetchMachineSetupSuccess(data));
    } else {
      yield put(fetchMachineSetupFailure(data));
    }
  } catch (e) {
    yield put(fetchMachineSetupFailure(e.message));
  }
}

function* updateActivityNotes(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.updateActivitiesNotes.url(
        action.payload.hcpId,
        action.payload.patientId,
        action.payload.sessionId,
        action.payload.activitiesId
      ),
      action.payload.data
    );
    if (data.code >= 200 && data.code < 300) {
      if (data.code === 200) {
        yield put(putActivityNoteSuccess(data.data));
        if (action.payload.callback) {
          action.payload.callback(true);
        }
      } else {
        yield put(putActivityNoteSuccess(null));
        action.payload.callback(false);
      }
    } else {
      yield put(
        requestToast({
          type: TOAST_TYPE.WARNING,
          message: `Please wait when data is saving`,
        })
      );
      yield put(putActivityNoteFailure(data || 'error'));
      action.payload.callback(false);
    }
  } catch (e) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `"ERROR WHEN SAVE DATA`,
      })
    );
    action.payload.callback(false);
    yield put(putActivityNoteFailure(e.message));
  }
}

function* listMachineSetups(action) {
  const query = action.payload || {};

  // pass in session ID when you want to get setups belong to that session
  const sessionId = query.sessionId || '';

  let queryString = [];
  if (sessionId) {
    queryString.push(`session_id=${sessionId}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.listMachineSetup.url(action.payload.hcpId, action.payload.patientId)}${queryString ? `?${queryString}` : ''
      }`
    );
    if (data.code === 200) {
      yield put(fetchPatientSetupSuccess(data));
      if (action.payload.callback) {
        action.payload.callback(data.data.length);
      }
    } else {
      yield put(fetchPatientSetupFailure(data));
      if (action.payload.callback) {
        action.payload.callback(0);
      }
    }
  } catch (e) {
    yield put(fetchPatientSetupFailure(e.message));
    if (action.payload.callback) {
      action.payload.callback(0);
    }
  }
}

function* initSetup(action) {
  try {
    const { data } = yield axios.post(
      END_POINT.initMachineSetup.url(action.payload.hcpId, action.payload.patientId, action.payload.machineId),
      action.payload.data ?? {}
    );

    if (data.code < 300) {
      localStorage.setItem('setupId', data.data.id);
      yield put(requestInitSetupSuccess(data.data));
      if (action.payload.callback) {
        action.payload.callback(data.data.id);
      }
    } else {
      yield put(requestInitSetupFailure(data));
      if (action.payload.callback) {
        action.payload.callback(undefined);
      }
    }
  } catch (e) {
    yield put(requestInitSetupFailure(e.message));
    if (action.payload.callback) {
      action.payload.callback(undefined);
    }
  }
}

function* saveSetup(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.saveMachineSetup.url(
        action.payload.hcpId,
        action.payload.patientId,
        action.payload.machineId,
        action.payload.setupId,
        action.payload.finished
      ),
      action.payload.data
    );
    if (data.code < 300) {
      yield put(requestSaveSetupSuccess(data));
    } else {
      yield put(requestInitSetupFailure(data));
    }
  } catch (e) {
    yield put(requestSaveSetupFailure(e.message));
  }
}

function* getPatientSessions(action) {
  const query = action.payload || {};
  const page = query.page || 1;
  const itemPerPage = query.itemPerPage || 99999;

  let queryString = [];
  if (page) {
    queryString.push(`page=${page}`);
  }
  if (itemPerPage) {
    queryString.push(`page_size=${itemPerPage}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.getPatientSessions.url(action.payload.hcpId, action.payload.patientId)}${queryString ? `?${queryString}` : ''
      }`
    );
    if (data.code === 200) {
      yield put(fetchPatientSessionsSuccess(data.data));
    } else {
      yield put(fetchPatientSessionsFailure(data.data));
    }
  } catch (e) {
    yield put(fetchPatientSessionsFailure(e.message));
  }
}

function* createSession(action) {
  try {
    const { data } = yield axios.post(
      END_POINT.createPatientSessions.url(action.payload.hcpId, action.payload.patientId),
      {}
    );
    if (data.code === 200) {
      yield put(requestCreateSessionSuccess(data));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'New session has been added.',
        })
      );
      yield put(
        fetchPatientSessions({
          patientId: action.payload.patientId,
          hcpId: action.payload.hcpId,
        })
      );
      if (action.payload.callback) {
        action.payload.callback(data.data.initiated_at, data.data.group_id);
      }
    } else {
      yield put(requestCreateSessionFailure(data));
    }
  } catch (e) {
    yield put(requestCreateSessionFailure(e.message));
  }
}

function* endSession(action) {
  try {
    const { data } = yield axios.post(
      END_POINT.endPatientSession.url(action.payload.hcpId, action.payload.patientId, action.payload.sessionId),
      {}
    );
    if (data.code === 200) {
      yield put(requestEndSessionSuccess(data));
      yield put(
        fetchPatientSessions({
          patientId: action.payload.patientId,
          hcpId: action.payload.hcpId,
        })
      );
    } else {
      yield put(requestEndSessionFailure(data));
    }
  } catch (e) {
    yield put(requestEndSessionFailure(e.message));
  }
}

function* getPatientSession(action) {
  try {
    const { data } = yield axios.get(
      `${END_POINT.getPatientSession.url(action.payload.hcpId, action.payload.patientId, action.payload.sessionId)}`
    );
    if (data.code === 200) {
      yield put(fetchPatientSessionSuccess(data));
      if (action.payload.callback) {
        action.payload.callback(data.data);
      }
    } else {
      yield put(fetchPatientSessionFailure(data));
      if (action.payload.callback) {
        action.payload.callback(null);
      }
    }
  } catch (e) {
    yield put(fetchPatientSessionFailure(e.message));
    if (action.payload.callback) {
      action.payload.callback(null);
    }
  }
}

function* deleteSetup(action) {
  try {
    const { data } = yield axios.delete(
      END_POINT.deleteMachineSetup.url(
        action.payload.hcpId,
        action.payload.patientId,
        action.payload.machineId,
        action.payload.setupId
      )
    );
    if (data.code === 200) {
      yield put(requestDeleteSetupSuccess(data));
    } else {
      yield put(requestDeleteSetupFailure(data));
    }
  } catch (e) {
    yield put(requestDeleteSetupFailure(e.message));
  }
}

function* initActivity(action) {
  try {
    const { data } = yield axios.post(
      END_POINT.initActivity.url(action.payload.hcpId, action.payload.patientId, action.payload.sessionId),
      action.payload.data
    );

    if (data.code < 300) {
      localStorage.setItem('activity_id', data.data.id);
      yield put(requestInitActivitySuccess(data.data));
    } else {
      yield put(requestInitActivityFailure(data));
    }
  } catch (e) {
    yield put(requestInitActivityFailure(e.message));
  }
}

function* getNormData(action) {
  try {
    const { data } = yield axios.get(
      END_POINT.getNormData.url(action.payload.testType, action.payload.normType, action.payload.setup_id)
    );

    if (data.code < 300) {
      yield put(fetchNormDataSuccess(data.data));
    } else {
      yield put(fetchNormDataFailure(data));
    }
  } catch (e) {
    yield put(fetchNormDataFailure(e.message));
  }
}

function* getActivitiesData(action) {
  try {
    const { data } = yield axios.get(
      END_POINT.getActivities.url(
        action.payload.hcpId,
        action.payload.patientId,
        action.payload.sessionId,
        action.payload.activitiesId
      )
    );

    if (data.code < 300) {
      if (data.code === 200) {
        yield put(fetchActivitiesSuccess(data.data));
      } else {
        yield put(fetchActivitiesSuccess(null));
      }
    } else {
      yield put(fetchActivitiesFailure(data));
    }
  } catch (e) {
    yield put(fetchActivitiesFailure(e.message));
  }
}

function* getPrevIsoActivityData(action) {
  try {
    const { data } = yield axios.get(
      END_POINT.getActivities.url(
        action.payload.hcpId,
        action.payload.patientId,
        action.payload.sessionId,
        action.payload.activitiesId
      ),
      action.payload.arrCurrentDegree
    );

    if (data.code < 300) {
      if (data.code === 200) {
        const arrCurrentDegree = action.payload.arrCurrentDegree
        const mapObjFollowDegree = new Map(data?.data?.data?.map(ele => [ele.angle_degree, ele]));
        const arrDataAfterConvert = getArrDegreeMatchToCompare(arrCurrentDegree, mapObjFollowDegree);

        const result = {
          data: arrDataAfterConvert,
          initTime: data?.data?.initiated_at,
          activityId: data?.data?.id
        }

        yield put(requestFetchPrevIsoActivitySuccess(result));
      }
    } else {
      yield put(requestFetchPrevIsoActivityFailed(data));
    }
  } catch (e) {
    yield put(requestFetchPrevIsoActivityFailed(e.message));
  }
}

function* getFirstIsoActivityData(action) {
  try {
    const { data } = yield axios.get(
      END_POINT.getActivities.url(
        action.payload.hcpId,
        action.payload.patientId,
        action.payload.sessionId,
        action.payload.activitiesId
      ));
    if (data.code < 300) {
      if (data.code === 200) {

        const arrCurrentDegree = action.payload.arrCurrentDegree
        const mapObjFollowDegree = new Map(data?.data?.data?.map(ele => [ele.angle_degree, ele]));
        const arrDataAfterConvert = getArrDegreeMatchToCompare(arrCurrentDegree, mapObjFollowDegree);

        const result = {
          data: arrDataAfterConvert,
          initTime: data?.data?.initiated_at,
          activityId: data?.data?.id
        }

        yield put(requestFetchFirstIsoActivitySuccess(result));
      }
    } else {
      yield put(requestFetchFirstIsoActivityFailed(data));
    }
  } catch (e) {
    yield put(requestFetchFirstIsoActivityFailed(e.message));
  }
}

function* requestArchivePatient(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.archivePatient.url(action.payload.hcpId, action.payload.patientId),
      action.payload.data
    );
    if (data.code < 300) {
      yield put(archivePatientSuccess(data));
      yield put(
        fetchPatient({
          patientId: action.payload.patientId,
          hcpId: action.payload.hcpId,
        })
      );
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: action.payload.toastSmg,
        })
      );
    } else {
      yield put(archivePatientFailure(data.error));
    }
  } catch (e) {
    yield put(archivePatientFailure(e.message));
  }
}

function* updateDemoGraphicQuestionHandler(action) {
  try {
    const { data } = yield axios.post(
      END_POINT.postDemographic.url(action.payload.hcpId, action.payload.patientId, action.payload.sessionId),
      action.payload.data
    );

    if (data.code === 200) {
      if (action.payload.callback) {
        action.payload.callback(true);
      }
      return;
    }

    if (action.payload.callback) {
      action.payload.callback(false);
    }
  } catch (e) {
    if (action.payload.callback) {
      action.payload.callback(false);
    }
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: e.message,
      })
    );
    console.warn(e);
  }
}

function* requestGetAllIsoActivities(action) {
  try {
    const { data } = yield axios.get(END_POINT.getAllIsoActivities.url(
      action.payload.hcpId,
      action.payload.patientId,
      action.payload.activityType,
      action.payload.machineType,
    ),
      {
        params: {
          current_activity: action.payload.current_activity
        }
      }
    );

    if (data?.data) {

      const combinedObject = data?.data?.reduce((result, currentObject) => {
        const activityId = currentObject.id;
        result[activityId] = currentObject;
        return result;
      }, {});

      const arrCurrentDegree = action?.payload?.arrCurrentDegree;
      const dataNearestDefault = data?.default_activities?.nearest;
      const dataFirstDefault = data?.default_activities?.first;

      yield put(requestUpdateListDataMachineSetup(combinedObject));

      yield put(requestFetchAllIsoActivitiesSuccess(data));

      if (!isNil(dataNearestDefault)) {
        const mapObjectNearest = new Map(dataNearestDefault?.data?.map(ele => [ele.angle_degree, ele]));
        const resultArrayNearest = getArrDegreeMatchToCompare(arrCurrentDegree, mapObjectNearest);

        yield put(requestUpdatePrevIsoActivity({
          data: resultArrayNearest,
          initTime: dataNearestDefault?.initiated_at,
          activityId: dataNearestDefault?.id
        }));

        yield put(requestUpdatePrevRomPain({
          setup: find(data?.data, { id: dataNearestDefault?.id }).machine_setup_data,
          answer_1: dataNearestDefault?.answer_1
        }))
      }

      if (!isNil(dataFirstDefault)) {
        const mapObjectFirst = new Map(dataFirstDefault?.data?.map(ele => [ele.angle_degree, ele]));
        const resultArrayFirst = getArrDegreeMatchToCompare(arrCurrentDegree, mapObjectFirst);

        yield put(requestUpdateFirstIsoActivity({
          data: resultArrayFirst,
          initTime: dataFirstDefault?.initiated_at,
          activityId: dataFirstDefault?.id
        }));

        yield put(requestUpdateFirstRomPain({
          setup: find(data?.data, { id: dataFirstDefault?.id }).machine_setup_data,
          answer_1: dataFirstDefault?.answer_1
        }))
      }

      yield put(requestFetchAllIsoActivitiesSuccess(data));


    } else {
      yield put(requestFetchAllIsoActivitiesFailed(data));
    }
  } catch (e) {
    yield put(requestFetchAllIsoActivitiesFailed(e.message));
  }
}

function* patientsSaga() {
  yield takeLatest(requestEditPatient, editPatient);
  yield takeLatest(requestAddPatient, addPatient);
  yield takeLatest(fetchPatient, getPatient);
  yield takeLatest(archivePatient, requestArchivePatient);
  yield takeLatest(searchPatient, getSearchPatient);
  yield takeLatest(fetchMachineSetup, getMachineSetup);
  yield takeLatest(fetchPatientSessions, getPatientSessions);
  yield takeLatest(requestInitSetup, initSetup);
  yield takeLatest(requestSaveSetup, saveSetup);
  yield takeLatest(requestCreateSession, createSession);
  yield takeLatest(requestDeleteSetup, deleteSetup);
  yield takeLatest(fetchPatientSession, getPatientSession);
  yield takeLatest(fetchPatientSetups, listMachineSetups);
  yield takeLatest(requestInitActivity, initActivity);
  yield takeLatest(requestEndSession, endSession);
  yield takeLatest(fetchNormData, getNormData);
  yield takeLatest(fetchActivities, getActivitiesData);
  yield takeLatest(putActivityNote, updateActivityNotes);
  yield takeLatest(updateDemoGraphicQuestion, updateDemoGraphicQuestionHandler);
  yield takeLatest(requestFetchAllIsoActivities, requestGetAllIsoActivities);
  yield takeLatest(requestFetchPrevIsoActivity, getPrevIsoActivityData);
  yield takeLatest(requestFetchFirstIsoActivity, getFirstIsoActivityData);
}

export default patientsSaga;