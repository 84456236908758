/* eslint-disable react/prop-types */
import { useState, useEffect, useRef, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
// import Menu from '@mui/material/Menu';
import Skeleton from '@mui/material/Skeleton';
import CircleIcon from '@mui/icons-material/Circle';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import { useSelector, useDispatch } from 'react-redux';
import { getNotifications, readNotification, readNotificationSuccess, setRedirectUrl } from './slice';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import Stack from '@mui/material/Stack';
import useCurrentUser from 'hooks/useCurrentUser';


export default function NotificationBell() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hcpId = localStorage.getItem('hcpId');

  const { permissions: currentPermissions } = useCurrentUser();

  useEffect(() => {
    if (currentPermissions.includes('VIEW_PATIENT_ACTIVITIES')) {
      dispatch(getNotifications({ hcpId, page: 1 }));
    }
  }, []);

  const notifications = useSelector((state) => state.notifications.notifications);
  const unread = useSelector((state) => state.notifications.unread);
  const currentPage = useSelector((state) => state.notifications.currentPage);
  const totalPage = useSelector((state) => state.notifications.totalPage);
  const loading = useSelector((state) => state.notifications.loading);

  const redirectUrl = useSelector((state) => state.notifications.redirectUrl);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const [items, setItems] = useState([]);
  const [itemCount, setItemCount] = useState(10);
  const [height, setHeight] = useState(600);
  // const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    if (totalPage > currentPage) {
      setItemCount(notifications.length + 1);
    } else {
      setItemCount(notifications.length);
    }
    let newHeight = 0;
    if (notifications.length > 5) {
      newHeight = 5 * 125;
    } else {
      newHeight = notifications.length * 125;
    }
    if (newHeight != height) {
      setHeight(newHeight);
    }
    // console.log(notifications);
  }, [totalPage, currentPage, notifications, height])
  // useEffect(() => {
  //   console.log(notifications);
  //   setItems(notifications);
  // }, [notifications])
  const loadMore = () => {
    if (loading || currentPage === totalPage) {
      return;
    }
    if (currentPermissions.includes('VIEW_PATIENT_ACTIVITIES')) {
      dispatch(getNotifications({ hcpId, page: currentPage + 1 }));
    }
  }
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (redirectUrl !== '') {
      navigate(redirectUrl, { relative: 'path' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl]);

  const isItemLoaded = index => currentPage >= totalPage || index < notifications.length;

  const onClickNoti = (noti, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (noti.status === 0) {
      dispatch(readNotification({ hcpId, notiId: noti.id }));
    } else {
      dispatch(readNotificationSuccess({ ...noti, source: 'local' }));
    }
    setOpen(false);
  }

  const renderRow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { index, style, data } = props;
    return (
      <ListItem style={style} divider key={index} component="div" disablePadding>
        {!data[index] ?
          <Skeleton animation="wave" variant="rectangular" width={335} height={60} />
          :
          <ListItemButton onClick={() => onClickNoti(data[index], event)}>
            <Stack direction="row">
              {data[index].status === 0 && <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginLeft: '-12px', marginRight: '4px' }}><CircleIcon sx={{ color: '#2B8A3E', fontSize: 8 }} /></Box>}
              <Box sx={{ width: 305 }}>
                <Typography sx={{ fontWeight: 500 }}>Data pushing</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{format(utcToZonedTime(parseISO(data[index].created_at), timezone), 'H:mm MMM dd, yyyy')}</Typography>
                <Typography sx={{ whiteSpace: 'pre-line', fontSize: '14px', fontWeight: 400 }}>{data[index].content}</Typography>
              </Box>
            </Stack>
          </ListItemButton>}
      </ListItem>
    );
  }
  return (<>
    <IconButton onClick={handleToggle} ref={anchorRef}>
      <Badge badgeContent={unread} color="error" overlap="circular">
        <NotificationsIcon sx={{ fontSize: '32px', color: '#fff' }} />
      </Badge>
    </IconButton>
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-start"
      transition
      sx={{
        zIndex: 999
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper >
            <Stack sx={{ flex: '1' }}>
              <ListItem component="div" divider sx={{ opacity: '1!important', fontWeight: 600, fontSize: '18px' }}>Notification</ListItem>
              <ClickAwayListener onClickAway={handleClose}>
                {!notifications.length ?
                  <Box sx={{ padding: '16px' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>No notification!</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>There are no notifications at the moment</Typography>
                  </Box>
                  :
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMore}
                  >
                    {({ onItemsRendered, ref }) => (
                      <FixedSizeList
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        itemCount={itemCount}
                        width={335}
                        height={height}
                        itemSize={122}
                        itemData={notifications}
                        overscanCount={5}
                      >
                        {renderRow}
                      </FixedSizeList>
                    )}
                  </InfiniteLoader>
                }
              </ClickAwayListener>
            </Stack>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>)
}