import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import seatbackAdjustment from 'assets/images/knee/seatback adjustment.svg';
import seatback from 'assets/images/knee/seatback.svg';
import handlebars from 'assets/images/knee/Handlebars.svg';
import { SingleImageStep } from 'helpers';

export default function BothRetraints({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={seatbackAdjustment} />
            </Box>
            <Box>1. With the seatbelt adjustment, tighten the seatbelt to restrict hip and upper body movement.</Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={seatback} />
            </Box>
            <Box>
              2. Instruct patient to maintain contact with their back and the seatback.
              <br />
              3. Ask a patient to breathe normally, and avoid holding their breath.
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={handlebars} />
            </Box>
            <Box>
              4. Instruction patient to use handlebars on each side of seat to limit upper body movement during all
              testing and exercise procedures. Instruct patient to maintain loose grip on handlebars.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

BothRetraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
