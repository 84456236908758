import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

const Container = styled('div')`
  table,
  td,
  th {
    border: 1px solid;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;

const NormTable = ({ data }) => {
  const activeNet = useSelector((state) => state.patients.activeNet);
  const activeTested = useSelector((state) => state.patients.activeTested);
  return (
    <Container>
      <table style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr style={{ color: 'black' }}>
            <th style={{
              width: '275px', fontSize: '14px', fontWeight: 600,
              color: '#fff', textAlign: 'left', paddingLeft: 5, background: '#495057', borderColor: 'black'
            }} key={`header-name}`}>STD Test Angles</th>
            {data.map((c, index) => (
              <th style={{ fontSize: '14px', fontWeight: 600, textAlign: 'center', color: '#fff', background: '#495057', borderColor: 'black' }} key={`header-${index}`}>{`${c.name}°`}</th>
            ))}
          </tr>
        </thead>
        {
          activeTested &&
          <tbody>
            <tr style={{ color: 'black', background: '#fff' }}>
              <td style={{ width: '275px', fontSize: '14px', fontWeight: 600, textAlign: 'left', paddingLeft: 5 }} key={`std-name}`}>1 Standard Deviation Above</td>
              {data.map((d, index) => (
                <td style={{ fontSize: '14px', fontWeight: 400, textAlign: 'center' }} key={`std-${index}`}>{`${d?.std ?? 0}`}</td>
              ))}
            </tr>

            <tr style={{ color: 'black', background: '#fff' }}>
              <td style={{ width: '275px', fontSize: '14px', fontWeight: 600, textAlign: 'left', paddingLeft: 5 }} key={`norm-name}`}>Absolute Strength Norm</td>
              {data.map((d, index) => (
                <td style={{ fontSize: '14px', fontWeight: 400, textAlign: 'center' }} key={`norm-${index}`}>{`${d?.norm ?? 0}`}</td>
              ))}
            </tr>
            <tr style={{ color: 'black', background: '#fff' }}>
              <td style={{ width: '275px', fontSize: '14px', fontWeight: 600, textAlign: 'left', paddingLeft: 5 }} key={`norm-name}`}>1 Standard Deviation Below</td>
              {data.map((d, index) => (
                <td style={{ fontSize: '14px', fontWeight: 400, textAlign: 'center' }} key={`norm-${index}`}>{`${d?.below ?? 0}`}</td>
              ))}
            </tr>
          </tbody>
        }
      </table>
    </Container>
  );
};

NormTable.propTypes = {
  data: PropTypes.array,
};

export default NormTable;
