import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IsometricRechart from 'components/Charts/IsometricReChart';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Actions from 'modules/Patients/components/Machines/Actions';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';

const IsometricPracticeSummary = ({ step, subStep, handleCancel, handleBack, handleNext, value, machine }) => {
  const [xAxis, setXAxis] = useState([]);
  const [data, setData] = useState(null);
  const [showLine, setShowLine] = useState(true);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (value[step] && value[step].value.length > 0) {
      const xAxisValue = [];
      value[step].value.map((e) => {
        xAxisValue.push(e.name);
      });
      setXAxis(xAxisValue);
    }
    setData(value[step].value);
  }, [machine, value]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        justifyContent: 'space-between',
      }}
    >
      {xAxis && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <IsometricRechart
            yAxisRange={ISOMETRIC_YAXIS.LUMBAR}
            showLine={showLine}
            showActive={false}
            xAxisRange={xAxis}
            data={data || []}
          />

          <FormControlLabel
            control={<Checkbox checked={showLine} onChange={() => setShowLine(!showLine)} />}
            label='Overlay Line Graph'
          />
        </div>
      )}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
};

IsometricPracticeSummary.propTypes = {
  machineSetup: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
};
export default IsometricPracticeSummary;
