import { clearMachineSetup, requestSaveSetup } from 'modules/Patients/slice';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { clearLocalPatientSetupData } from 'modules/Patients/utils/index';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Children, cloneElement, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useNavigateItems } from 'modules/Patients/hooks/useMachineSetup';

const StepContext = createContext();

const StepsContainer = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  onDataChange,
  machine,
  setupMachineData,
  children,
}) => {
  const { id } = useParams();
  const isFinish = useRef(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get('sessionId');
  const setupId = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');
  const machineType = searchParams.get('machineType');
  const [nextStep, setNextStep] = useState('');
  const [invalidStep, setInvalidStep] = useState(true);

  const patientId = id;

  const navigator = useNavigate();
  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);

  useEffect(() => {
    if (!step || !subStep) {
      return;
    }

    let currentStep = `${step}`;
    if (subStep !== '0') {
      currentStep = `${step}-${subStep}`;
    }

    setNextStep(currentStep);
  }, [step, subStep]);

  useEffect(() => {
    return () => {
      clearLocalPatientSetupData();
      dispatch(clearMachineSetup());
    };
  }, []);

  const setupData = setupMachineData ? setupMachineData.data : {};
  const props = {
    step,
    subStep,
    handleCancel,
    handleBack,
    handleNext,
    machine,
    onDataChange,
    setupMachineData: setupData,
    nextStep,
    setInvalidStep,
    invalidStep,
  };
  return (
    <StepContext.Provider value={props}>
      {Children.map(children, (child, index) => {
        return <WrapStep key={index} element={child} indexStep={`${index + 1}`} />;
      })}
    </StepContext.Provider>
  );
};

function WrapStep({ element, indexStep, parentStep }) {
  const stepData = useContext(StepContext);
  const navItems = useNavigateItems();
  const { nextStep, setInvalidStep, invalidStep } = stepData;
  const childCount = Children.count(element.props.children);

  useEffect(() => {
    const nameNav = element.props.name;

    if (!nameNav || parentStep) {
      return;
    }
    // Create a navigation item object
    const navItem = {
      id: indexStep,
      name: nameNav,
      // children: [],
    };

    // If there are children, add child navigation items
    if (childCount > 1) {
      navItem.children = [];
      Children.forEach(element.props.children, (child, index) => {
        const nameChildNav = child.props.name;
        if (nameChildNav) {
          navItem.children.push({
            id: `${indexStep}-${index + 1}`,
            name: nameChildNav,
          });
        }
      });
    }

    // Register the navigation item
    navItems.registerNavigation(navItem);
  }, []);

  useEffect(() => {
    if (nextStep === indexStep) {
      setInvalidStep(false);
    }
  }, [indexStep, nextStep]);

  if (element.type.name != StepItem.name) {
    return <></>;
  }

  if (childCount > 1) {
    return Children.map(element.props.children, (child, index) => {
      return (
        <WrapStep key={index} element={child} indexStep={`${indexStep}-${index + 1}`} parentStep={`${indexStep}`} />
      );
    });
  }
  if (indexStep == 1 && invalidStep) {
    return element;
  }
  return nextStep != indexStep ? <></> : element;
}

function StepItem({ children }) {
  const stepData = useContext(StepContext);
  return Children.map(children, (child, index) => {
    return cloneElement(child, { ...child.props, ...stepData });
  });
}

StepsContainer.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  onDataChange: PropTypes.func,
  setupMachineData: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.element),
};

WrapStep.propTypes = {
  element: PropTypes.element,
  indexStep: PropTypes.string,
  parentStep: PropTypes.string,
};

StepItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

StepsContainer.Step = StepItem;

export default StepsContainer;
