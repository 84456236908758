import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Rectangle,
  Area,
} from 'recharts';
import CustomizedTooltip from './CustomizedTooltip';
import { generateYAxisArray } from './utils';
import isNil from 'lodash/isNil';

const defaultYAxis = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
const defaultXAxis = [0, 12, 24, 36, 48, 60, 72];

const renderColorfulLegendText = (value, entry) => {
  const dataKeysToHide = ['std', 'below', 'range', 'recent', 'first'];
  const dataNamesToHide = ['Peak Torque Line', '1 Standard Deviation Above'];
  if (dataKeysToHide?.includes(entry.dataKey) || dataNamesToHide?.includes(value)) {
    return null;
  }
  return <span style={{ color: '#1A1A1A' }}>{value}</span>;
};

// const dataKeysToHide = ['std'];
// const dataKeysToHide = ['std', 'below', 'norm', 'range'];
// const dataNamesToHide = ['Peak Torque Line'];

// const legendPayloadKeys = [
//   { value: '1 Standard Deviation Above', type: 'square', id: 'std', dataKey: 'std' },
//   { value: 'Absolute Strength Norm', type: 'square', id: 'norm', dataKey: 'norm' },
//   { value: '1 Standard Deviation Below', type: 'square', id: 'below', dataKey: 'below' },
//   { value: 'Area', type: 'square', id: 'range', dataKey: 'range' },
//   // Add other legend items as needed
// ];

// const legendPayloadName = [
//   { value: 'Peak Torque Line', type: 'square', id: 'peak', dataKey: 'peak' }
//   // Add other legend items as needed
// ];

// const dataKeysToKeep = ['peak', ];  // Add the dataKeys you want to keep

// const filteredLegendPayload = [...legendPayloadKeys, ...legendPayloadName]
//   .filter(item => dataKeysToKeep.includes(item.dataKey) || !dataKeysToHide.includes(item.dataKey));

// const filteredLegendPayload = legendPayloadKeys.filter(item => !dataKeysToHide.includes(item.dataKey));

export default function IsometricRechart({
  activeNet = false,
  xAxisRange = defaultXAxis,
  yAxisRange = defaultYAxis,
  showActive = true,
  height = 250,
  width = '100%',
  data = [],
  showCurrentLine = false,
  showNormLine = false,
  showAboveLine = false,
  showBelowLine = false,
  showFirstLine = false,
  showRecentLine = false,
  showArea = false,
  showNormAbove = false,
  showNormBelow = false,
  showCurrentNetLine = false,
  showFirstNetLine = false,
  showRecentNetLine = false,
  reversed = false,
  machineType,
  nearestActivity
}) {
  const renderBackground = ({ height, y, ...rest }) => {
    const { payload } = rest;
    if (payload.active && payload.max) {
      const heightUnit = height / yAxisRange[yAxisRange.length - 1];

      const newHeight = payload.max * heightUnit;
      const newY = height - newHeight + y;
      return <Rectangle y={newY} height={newHeight} {...rest} />;
    } else {
      return null;
    }
  };
  const [yAxis, setYAxis] = useState(yAxisRange);

  useEffect(() => {
    let maxY = 0;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]['std'] && data[i]['std'] > maxY) {
          maxY = data[i]['std'];
        }
        if (data[i]['norm'] && data[i]['norm'] > maxY) {
          maxY = data[i]['norm'];
        }
      }
    }
    if (maxY && maxY > yAxisRange[yAxisRange.length - 1]) {
      setYAxis(generateYAxisArray(maxY, yAxisRange.length));
    }
  }, [data]);

  const isHideBarChart = (showCurrentNetLine || showRecentNetLine || showFirstNetLine || showCurrentLine || showAboveLine || showFirstLine || showRecentLine || showNormLine || showBelowLine);
  const showNameTest = isNil(nearestActivity) ? 'Test 1' : 'Test 2';

  return (
    <ResponsiveContainer width={width} height={height}>
      <ComposedChart
        data={data}
        syncId='isometric'
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} stroke='#E6E6E6' />
        <XAxis
          dataKey='name'
          stroke='#E6E6E6'
          padding={{ left: 40, right: 40 }}
          type='number'
          domain={['dataMin', 'dataMax']}
          reversed={reversed}
          tickFormatter={(value) => `${value}°`}
          tick={{ fill: '#000', fontSize: 16 }}
          tickLine={false}
          ticks={xAxisRange}
        />
        <YAxis
          type='number'
          interval={0}
          stroke='#E6E6E6'
          label={{
            value: `${machineType === 'Cervical' || machineType === 'Cervical Rotation' ? 'lbf-in' : 'lbf-ft'}`,
            position: 'insideLeft',
            angle: -90,
            fontSize: 16,
          }}
          tick={{ fill: '#000', fontSize: 16 }}
          tickLine={false}
          ticks={yAxis}
          axisLine={false}
          domain={['dataMin', 'dataMax']}
        />
        <Tooltip
          wrapperStyle={{
            padding: '22px',
            outline: 'none',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '8px',
          }}
          stroke='none'
          content={<CustomizedTooltip />}
        />
        <Legend
          wrapperStyle={{ fontSize: '14px', color: '#1A1A1A', marginLeft: '40px' }}
          formatter={renderColorfulLegendText}
          align='left'
        // iconType={'square'}
        // payload={filteredLegendPayload}
        />
        {!isHideBarChart && <Bar dataKey='stored' animationDuration={500} name='Stored Energy' fill='#000' barSize={18} />}
        {!showCurrentLine && showActive && (
          <Bar
            dataKey='active'
            animationDuration={500}
            name='Active Torque'
            background={renderBackground}
            stackId='active'
            fill='#ED9A00'
            barSize={18}
          />
        )}

        {!isHideBarChart && <Bar dataKey='peak' name='Tested Functional Torque' stackId='active' fill='#C7C7C7' barSize={18} />}

        {<Area dataKey="range" hide={!showArea} stroke="#343a401f" name='' fill="#343a401f" legendType='none' isAnimationActive={false} />}
        {<Area dataKey="rangeNormAbove" hide={!showNormAbove} stroke="#343a401f" name='' fill="#343a401f" legendType='none' isAnimationActive={false} />}
        {<Area dataKey="rangeNormBelow" hide={!showNormBelow} stroke="#343a401f" name='' fill="#343a401f" legendType='none' isAnimationActive={false} />}
        {<Line dataKey='peak' hide={!showCurrentLine} strokeWidth={3} dot={{ stroke: '#ED9A00', strokeWidth: 2, r: 4, strokeDasharray: '' }} fill='#ED9A00' stroke='#ED9A00' name='Tested Functional Torque' type='square' legendType='none' isAnimationActive={false} />}
        {<Line connectNulls={true} dataKey='recent' hide={!showRecentLine} strokeDasharray="5 5 5 5" strokeWidth={2} dot={{ stroke: '#E03131', strokeWidth: 8, r: 0.5, strokeDasharray: '' }} stroke='#E03131' name='Test 1' type='square' legendType='none' isAnimationActive={false} />}
        {<Line connectNulls={true} dataKey='first' hide={!showFirstLine} strokeDasharray="3 3 3 3" strokeWidth={2} dot={{ stroke: '#2F9E44', strokeWidth: 8, r: 0.5, strokeDasharray: '' }} stroke='#2F9E44' name={showNameTest} type='square' legendType='none' isAnimationActive={false} />}


        {<Line dataKey='net' hide={!showCurrentNetLine} strokeWidth={3} dot={{ stroke: '#ED9A00', strokeWidth: 2, r: 4, strokeDasharray: '' }} fill='#ED9A00' stroke='#ED9A00' name='Net Muscular Torque' type='square' legendType='none' isAnimationActive={false} />}
        {<Line connectNulls={true} dataKey='recentNet' hide={!showRecentNetLine} strokeDasharray="5 5 5 5" strokeWidth={2} dot={{ stroke: '#E03131', strokeWidth: 8, r: 0.5, strokeDasharray: '' }} stroke='#E03131' name='Test 1' type='square' legendType='none' isAnimationActive={false} />}
        {<Line connectNulls={true} dataKey='firstNet' hide={!showFirstNetLine} strokeDasharray="3 3 3 3" strokeWidth={2} dot={{ stroke: '#2F9E44', strokeWidth: 8, r: 0.5, strokeDasharray: '' }} stroke='#2F9E44' name={showNameTest} type='square' legendType='none' isAnimationActive={false} />}

        {<Line dataKey='std' hide={!showAboveLine} strokeWidth={1} dot={false} stroke='#343A40' name='1 Standard Deviation Above' type='square' legendType='none' isAnimationActive={false} />}
        {<Line dataKey='below' hide={!showBelowLine} strokeWidth={1} dot={false} stroke='#343A40' name='1 Standard Deviation Below' type='square' legendType='none' isAnimationActive={false} />}
        {<Line hide={!showNormLine} dataKey='norm' strokeWidth={1.5} dot={false} stroke='#000' name='Absolute Strength Norm' type='square' legendType='none' isAnimationActive={false} />}
        {/* { <Line type="monotone" dataKey="current" strokeWidth={2} stroke="#ff7300" />} */}
        {/* {<Line dataKey='std' strokeWidth={1} dot={false} stroke='#343a401f' name='1 Std Deviation Above' type='square' />}
        {<Line dataKey='below' strokeWidth={1} dot={false} stroke='#343a401f' name='Below' type='square' />}
        {<Line dataKey='norm' dot={true} stroke='#000' name='Normative Data' type='square' />}
        {<Line dataKey='recent' strokeDasharray="5 5" strokeWidth={2} dot={false} stroke='#495057' name='Recent' type='square' />}
        { <Line dataKey='first' strokeDasharray="3 3 3 3" strokeWidth={2} dot={false} stroke='#868E96' name='First' type='monotone' />}
        {<Area dataKey="range" stroke="#343a401f" fill="#343a401f" />} */}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

IsometricRechart.propTypes = {
  yAxisRange: PropTypes.array,
  xAxisRange: PropTypes.array,
  data: PropTypes.array,
  showActive: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  showCurrentLine: PropTypes.bool,
  showNormLine: PropTypes.bool,
  showAboveLine: PropTypes.bool,
  showBelowLine: PropTypes.bool,
  showFirstLine: PropTypes.bool,
  showRecentLine: PropTypes.bool,
  showNormAbove: PropTypes.bool,
  showNormBelow: PropTypes.bool,
  machineType: PropTypes.string,
  reversed: PropTypes.bool,
};