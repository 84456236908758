import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { MultipleSelectContainer } from './MultipleSelect.jsx';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const SingleSelectContainer = styled('div')`
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 2px;
  }

  label {
    color: #737373 !important;
  }
`;

export function SingleSelect({ value, onChange, width, options, field, disable = false }) {
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    return () => {
      setSelectedValue({});
      onChange(field, '');
    };
  }, []);

  useEffect(() => {
    if (value === undefined || value === null || value === '') {
      setSelectedValue({});
      return;
    }
    if (value === selectedValue.value) {
      return;
    }

    const _value = options.find((e) => e.value === value);

    if (!value) {
      return;
    }

    setSelectedValue(() => _value);
  }, [value]);

  const onChangeHandler = (e, v) => {
    setSelectedValue(v);

    onChange(field, v.value);
  };

  return (
    <SingleSelectContainer>
      <Autocomplete
        onKeyDown={field}
        value={selectedValue}
        onChange={onChangeHandler}
        disabled={disable}
        clearIcon={false}
        disableClearable={true}
        getOptionLabel={(option) => (option.label ? option.label : '')}
        sx={{ color: 'black', borderColor: '#d4d4d4', borderRadius: '8px', width: width }}
        options={options}
        renderOption={(props, option) => {
          return <li {...props} aria-selected={option.value === value}>{`${option.label}`}</li>;
        }}
        renderInput={(params) => (
          <MultipleSelectContainer>
            <TextField
              {...params}
              label={value === undefined || value === null || value === '' ? 'Select' : ''}
              InputLabelProps={{ shrink: false }}
              onKeyDown={(e) => e.preventDefault()}
            />
          </MultipleSelectContainer>
        )}
      />
    </SingleSelectContainer>
  );
}

SingleSelect.propTypes = {
  value: PropTypes.string | PropTypes.number,
  onChange: PropTypes.func,
  width: PropTypes.number | PropTypes.string,
  options: PropTypes.array,
  field: PropTypes.string,
  disable: PropTypes.bool,
};
