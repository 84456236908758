import React, { Suspense } from 'react';

const CustomersAcceptInvite = React.lazy(() => import('modules/Auth/containers/AcceptInvite/CustomersAcceptInvite'));
const HcpUsersAcceptInvite = React.lazy(() => import('modules/Auth/containers/AcceptInvite/HcpUsersAcceptInvite'));
const BionexAcceptInvite = React.lazy(() => import('modules/Auth/containers/AcceptInvite/BionexAcceptInvite'));
const HcpUsersForgotPassword = React.lazy(() => import('modules/Auth/containers/ForgotPassword/index.jsx'));
const CustomersChangeEmail = React.lazy(() => import('modules/Auth/containers/ChangeEmail/index.jsx'));
const HcpUsersResetPassword = React.lazy(() => import('modules/Auth/containers/ResetPassword/index.jsx'));
const Sso = React.lazy(() => import('./containers/Sso.jsx'));
const SignOut = React.lazy(() => import('./containers/SignOut'));
const CustomerSignIn = React.lazy(() => import('modules/Auth/containers/SignIn/index.jsx'));
const NoPermissionPage = React.lazy(() => import('components/NoPermissionPage/index.jsx'));
const NotFoundPage = React.lazy(() => import('components/NotFoundPage/index.jsx'));

export default [
  {
    path: '*',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <NotFoundPage />
      </Suspense>
    ),
    public: true,
  },
  {
    path: '/sso',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Sso />
      </Suspense>
    ),
    public: true,
  },
  {
    path: '/403',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <NoPermissionPage />
      </Suspense>
    ),
    public: true,
  },
  {
    path: '/sign-in',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CustomerSignIn />
      </Suspense>
    ),
  },
  {
    path: '/sign-out',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SignOut />
      </Suspense>
    ),
    public: true,
  },

  {
    path: 'hcps/forgot-password',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HcpUsersForgotPassword />
      </Suspense>
    ),
    public: true,
  },
  {
    path: '/customers/accept-invite',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CustomersAcceptInvite />
      </Suspense>
    ),
  },
  {
    path: '/users/accept-invite',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <BionexAcceptInvite />
      </Suspense>
    ),
  },
  {
    path: '/hcps/accept-invite',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HcpUsersAcceptInvite />
      </Suspense>
    ),
  },

  {
    path: '/hcps/password',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HcpUsersResetPassword />
      </Suspense>
    ),
  },
  {
    path: '/customers/change-email',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CustomersChangeEmail />
      </Suspense>
    ),
  },
];
