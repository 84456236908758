import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import step2 from 'assets/images/png/physical-positioning/3c1.png';
import step3 from 'assets/images/png/dynamic-test/4b.png';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

const instructions = [
  {
    id: 1,
    text: '',
    img: step2,
  },
  {
    id: 2,
    text: '',
    img: step3,
  },
];
const LegAndFoot = ({ step, setupMachineData, subStep, handleCancel, handleBack, handleNext, handleRemoveSkip }) => {
  if (!setupMachineData) {
    return null;
  }

  const nextHandler = () => {
    handleRemoveSkip(step);
    handleNext();
  };
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>{getInstructions([instructions[0]])}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='body1' component='div'>
              1. Bring the femur pad up onto the patient’s lap.
              <br />
              2. Adjust Femur pads according to specifications and insert pin:
            </Typography>
            <div>
              <div style={{ fontWeight: 500 }}>Patient Setup Femur Pad:</div>
              <div
                style={{
                  padding: 2,
                  border: '1px solid green',
                  fontWeight: 500,
                  borderRadius: '8px',
                  width: '50px',
                  textAlign: 'center',
                  color: 'green',
                }}
              >{`${setupMachineData.femur_restraint}`}</div>
            </div>
            <Typography variant='body1' component='div'>
              3. Have patient wiggle back into the seat.
              <br />
              4. Ask them to try and touch their toes. They should not be able to lift their buttocks out of the seat.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>{getInstructions([instructions[1]])}</Box>

          <Typography variant='body1' component='div'>
            5. Adjust Footrest with Footrest adjuster, so patient can comfortably rest their feet on Footrest. Heels
            against the heel rest, toes turned in slightly.
          </Typography>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={nextHandler}
      />
    </>
  );
};

LegAndFoot.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default LegAndFoot;
