import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  fetchActivities,
  fetchMachineSetup,
  fetchPatient,
  putActivityNote,
  resetActivityProperties,
} from 'modules/Patients/slice';
import DynamicRechart from '../../../../../components/Charts/DynamicReChart';
import SummaryControl from '../../SummaryControl';
import { SessionInfo } from '../../../components/Info';
import Container from '@mui/material/Container';
import useCurrentUser from 'hooks/useCurrentUser';

import { fetchHcpMachines } from '../../../../Machines/slice';
import { MACHINE_TESTING_ANGLE } from '../../../constants';
import { generateYAxisArray } from 'components/Charts/utils';
import { getActivityQuestionLabel } from 'helpers';
import PushEMRModal from 'modules/EMR/components/PushEMRModal.jsx';
import { dateParseMonthDayYear } from 'helpers/datetime';

const DynamicSummaryContainer = styled('div')`
  & .notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & .notes:hover {
    border-color: black;
  }
  & .notes:focus {
    outline-color: #ed9a00;
  }
`;

const DynamicSummary = ({ activitiesId, isLumbar, isCervical }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const setupData = useSelector((state) => state.patients.setup);
  const value = useSelector((state) => state.patients.activities);
  const patientInfo = useSelector((state) => state.patients.patient);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);
  const { emr } = useSelector((state) => state.emrSettings);
  const machineId = searchParams.get('machineId');
  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  // const activitiesId = searchParams.get('activitiesId');
  const viewOnly = searchParams.get('viewOnly');
  const mode = searchParams.get('mode');
  const machineType = searchParams.get('machineType');

  const [data, setData] = useState([]);
  const [time, setTime] = useState(0);
  const [reps, setReps] = useState(0);
  const [xAxis, setXAxis] = useState([]);
  const [isEditNotes, setIsEditNotes] = useState(false);
  const [notes, setNote] = useState('');
  const [target, setTarget] = useState(undefined);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);

  const [openPushEMR, setOpenPushEMR] = useState(false);
  const [disablePushing, setDisablePushing] = useState(false);

  const printRef = useRef(null);

  const { permissions: currentPermissions, user: { hcp: { enabled_feature: enabledFeature } } } = useCurrentUser();

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('EXECUTE_ACTIVITIES');
  };

  const hasViewActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_ACTIVITIES');
  };

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const imgWidth = 224;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF('p', 'mm');
    doc.addImage(canvas, 'PNG', 20, 20, imgWidth, imgHeight, '', 'FAST');
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, 'PNG', 20, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save('Dynamic Summary Index.pdf');
  };

  useEffect(() => {
    if (!id || !machineId || !setupId) {
      return null;
    }

    const hcpId = localStorage.getItem('hcpId');

    dispatch(
      fetchPatient({
        patientId: atob(id),
        hcpId: hcpId,
      })
    );

    dispatch(
      fetchActivities({
        hcpId,
        patientId: atob(id),
        sessionId,
        activitiesId,
      })
    );
    dispatch(
      fetchMachineSetup({
        hcpId: hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
      })
    );
  }, [activitiesId]);

  useEffect(() => {
    if (!value || (value && !value.data)) {
      return;
    }

    if (!value.emr_status || Object.keys(value.emr_status).length === 0) {
      setDisablePushing(false);
    } else {
      if (value.emr_status.caspio.status === 'Done' || value.emr_status.caspio.status === 'Processing') {
        setDisablePushing(true);
      } else {
        setDisablePushing(false);
      }
    }
    const d = [];
    let reps = 0;
    const maxRange = machineType === 'Lumbar' ? 72 : 126;

    value.data.map((line) => {
      if (line.is_flexion) {
        line.angle_degrees?.map((degree, index) => {
          let dd = degree;
          if (dd > maxRange) {
            dd = maxRange;
          }
          if (dd < 0) {
            dd = 0;
          }

          d.push({
            name: Math.abs(dd),
            flexion: Math.abs(line.pounds[index]),
          });
        });
      } else {
        line.angle_degrees?.map((degree, index) => {
          let dd = degree;
          if (dd > maxRange) {
            dd = maxRange;
          }
          if (dd < 0) {
            dd = 0;
          }

          d.push({
            name: dd,
            extension: Math.abs(line.pounds[index]),
          });
        });
      }
      reps = line.reps > reps ? line.reps : reps;
    });

    setReps(reps);
    setTime(value?.test_duration || 0);
    if (value?.data[0] && value.data[0].weight !== null) {
      setTarget(value.data[0].weight);
      setYAxis(generateYAxisArray(value.data[0].weight, 6));
    }

    setNote(value.notes || '');

    switch (setupData && setupData.machine_type) {
      case 'Lumbar':
        setXAxis(MACHINE_TESTING_ANGLE.LUMBAR_TESTING_ANGLE);
        break;

      case 'Cervical':
        setXAxis(MACHINE_TESTING_ANGLE.CERVICAL_TESTING_ANGLE);
        break;
    }

    setData([...d]);
  }, [value]);

  useEffect(() => {
    if (!isUpdatingActivity) {
      setIsEditNotes(false);
      dispatch(resetActivityProperties());
    }
  }, [isUpdatingActivity]);

  const onNoteCancelHandler = () => {
    setNote(value.notes || '');
    setIsEditNotes(false);
  };

  const onPushDataDoneHandler = () => {
    const hcpId = localStorage.getItem('hcpId');
    dispatch(
      fetchActivities({
        hcpId,
        patientId: atob(id),
        sessionId,
        activitiesId,
      })
    );
    setOpenPushEMR(false);
  };

  const onSaveHandler = () => {
    if (!isUpdating) {
      const hcpId = localStorage.getItem('hcpId');
      dispatch(
        putActivityNote({
          hcpId,
          patientId: atob(id),
          sessionId,
          activitiesId,
          data: {
            notes: notes || '',
          },
        })
      );
    }
  };

  const sessionInfoRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    const formattedDate = dateParseMonthDayYear(value?.initiated_at);

    return (
      <SessionInfo
        isDownloading={isDownloadingPdf}
        machine={{ machine: machine }}
        patient={patientInfo}
        title={'Dynamic Exercise Summary'}
        currentDay={formattedDate}
      />
    );
  };

  const footerRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    return (
      <SummaryControl
        isBack={viewOnly === 'true'}
        machine={{ machine }}
        patientId={id}
        setupId={setupId}
        sessionId={sessionId}
        disableControl={isEditNotes}
        type={'ACTIVITY'}
        setupValue={setupData}
        mode={Number(mode)}
      />
    );
  };

  const dataRender = (data) => {
    return (
      <Box sx={{ display: 'flex', padding: '15px 0' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            {data[0].title}
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data[0].value ?? 0}
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            {data[1].title}
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data[1].value ?? 0}
          </Typography>
        </div>
      </Box>
    );
  };

  if (!setupData || !hasViewActivitiesPermission()) {
    return <></>;
  }

  const seatRender = () => {
    if (!setupData) {
      return null;
    }

    if (setupData.machine_type === 'Cervical') {
      return dataRender([
        { value: setupData.data.seat_pos, title: 'Seat Height' },
        { value: setupData.data.seat_pad, title: 'Seat Pad' },
      ]);
    }

    if (setupData.machine_type === 'Lumbar') {
      return dataRender([
        { value: setupData.data.femur_restraint, title: 'Femur Restraint Position' },
        { value: setupData.data.seat_pad, title: 'Seat Pad' },
      ]);
    }
    return null;
  };

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <Box ref={printRef}>
            {sessionInfoRender()}
            <Box
              sx={{
                // display: 'flex',
                pt: 5,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '40px',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '70%', gap: '10px' }}
                  >
                    <DynamicRechart isLumbar={isLumbar} isCervical={isCervical} isShowAxisLabel={true} yTicks={yAxis} xTicks={xAxis} tooltip={false} data={data} />
                  </div>

                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {!!Object.entries(enabledFeature.emr).filter(([key, value]) => value).length &&
                      <Button
                        onClick={() => setOpenPushEMR(true)}
                        disabled={isEditNotes || disablePushing || !emr || (emr && emr.length === 0)}
                        sx={{ alignSelf: 'baseline' }}
                        variant='outlined'
                        color='secondary'
                      >
                        Push Data
                      </Button>}

                    <Button
                      data-html2canvas-ignore
                      onClick={() => {
                        setIsDownloadingPdf(true);
                        setTimeout(() => {
                          handleDownloadPdf();
                          setIsDownloadingPdf(false);
                        }, 100);
                      }}
                      disabled={isEditNotes}
                      sx={{ alignSelf: 'baseline', color: '#FFF' }}
                      variant='contained'
                    >
                      Export Report
                    </Button>
                  </Box>
                </Box>

                <Box sx={{ width: '70%' }}>
                  <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: '20px', fontSize: '20px', paddingBottom: '20px' }}>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
                      </div>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
                      </div>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{`Weight: `} </span> {target}
                      </div>
                    </Box>
                    <Divider sx={{
                      borderBottomWidth: '2px',
                      borderColor: '#f5f5f5'
                    }} />
                    <Typography variant='h6' sx={{ wordBreak: 'break-all', fontWeight: '500' }} component='div'>
                      Test Type
                    </Typography>
                    <Typography sx={{ wordBreak: 'break-all', fontSize: '20px' }} variant='p' component='div'>
                      Dynamic Exercise Summary
                    </Typography>
                  </Box>
                  {seatRender()}
                  {dataRender(
                    [
                      { value: `${setupData.data.range_motion_min || 0}°`, title: 'Max Extension Angle' },
                      { value: `${setupData.data.range_motion_max || 0}°`, title: 'Max Flexion Angle' },
                    ],
                    '°'
                  )}
                  {dataRender([
                    { value: `${setupData.data.dead_center || 0}°`, title: 'TDC at Rest Angle' },
                    { value: setupData.data.counterbalance_gauge, title: 'Counterweight Gauge Reading' },
                  ])}
                  <Divider sx={{
                    borderBottomWidth: '2px',
                    borderColor: '#f5f5f5'
                  }} />
                  <Box
                    sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1, padding: '20px 0 20px 0' }}
                  >
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        The subjective pain level
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_1 === null ? 'N/A' : getActivityQuestionLabel('answer_1', value.answer_1))}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        The patient perceived exertion rate
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_2 === null ? 'N/A' : getActivityQuestionLabel('answer_2', value.answer_2))}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        How many more reps could you do before failure?
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_3 === null ? 'N/A' : getActivityQuestionLabel('answer_3', value.answer_3))}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{
                    borderBottomWidth: '2px',
                    borderColor: '#f5f5f5'
                  }} />
                  <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Notes
                      </Typography>

                      {hasExecuteActivitiesPermission() && (
                        <IconButton data-html2canvas-ignore onClick={() => setIsEditNotes(true)} fontSize={'small'}>
                          <EditIcon fontSize={'small'} sx={{ color: 'black' }} />
                        </IconButton>
                      )}
                    </Box>
                    {isEditNotes ? (
                      <Box>
                        <DynamicSummaryContainer>
                          <TextareaAutosize
                            onChange={(v) => {
                              setNote(v.target.value);
                            }}
                            className={'notes'}
                            value={notes}
                            minRows={5}
                            maxLength={3000}
                            style={{
                              width: '100%',
                              borderColor: '#DDDFE1',
                              borderRadius: 5,
                              fontFamily: 'Roboto, Montserrat, sans-serif',
                              fontSize: 16,
                            }}
                          />
                        </DynamicSummaryContainer>

                        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={onNoteCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
                              {t('common:cancel')}
                            </Button>

                            <LoadingButton
                              loading={isUpdating}
                              onClick={onSaveHandler}
                              size={'small'}
                              variant='contained'
                            >
                              {t('common:save')}
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Typography sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }} variant='p' component='div'>
                        {notes || '_'}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <PushEMRModal
                  onClose={() => setOpenPushEMR(false)}
                  open={openPushEMR}
                  activityId={activitiesId}
                  onDone={onPushDataDoneHandler}
                />
                <Box data-html2canvas-ignore>{footerRender()}</Box>
                <Box
                  data-html2canvas-ignore
                  sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}
                >
                  <div></div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

DynamicSummary.propTypes = {
  machineSetup: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  machine: PropTypes.object,
  value: PropTypes.object,
  printRef: PropTypes.object,
  onDataChange: PropTypes.func,
  setIsDownloading: PropTypes.func,
  activitiesId: PropTypes.string,
};
export default DynamicSummary;
