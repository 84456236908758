import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import counterWeight from 'assets/images/SVG/Cervical/Counter-Weight.svg';
import chainTightener from 'assets/images/SVG/Cervical/Chair-tightener.svg';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '5. Place angle selector at 36°.',
    img: angleSelector,
  },
  {
    id: 2,
    text: '6. Walk over to the other side of the machine and unlock counterweight.',
    img: counterWeight,
  },
  {
    id: 3,
    text: '7. Loosen chain tightener.',
    img: chainTightener,
  },
];
const Instructions = ({ step, subStep, handleCancel, handleNext }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Typography variant='body1' component='div'>
          1. If patient is already set up in the Cervical machine, then skip to{' '}
          <b style={{ color: '#ED9A00' }}>Step 2</b> - “Instruction for Patient.”
          <br />
          2. Before starting, make sure you have all medical information about the patient updated.
          <br />
          3. Ask patient if they need to use the restroom as the bladder area will be compressed.
          <br />
          4. Have patient remove belt or restrictive garments and empty object from pockets.
          <br />
        </Typography>

        <Box sx={{ display: 'flex', gap: 5, paddingTop: 5, paddingBottom: 5 }}>{getInstructions(instructions)}</Box>
      </OverlayScrollbarsComponent>
      <Actions step={step} subStep={subStep} handleCancel={handleCancel} handleNext={handleNext} />
    </>
  );
};

Instructions.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
};

export default Instructions;
