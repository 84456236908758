import authRoutes from 'modules/Auth/routes.jsx';
import customersRoutes from 'modules/DashBoard/routes.jsx';
import hcpUsersRoutes from 'modules/HcpUsers/routes.jsx';
import machinesRoutes from 'modules/Machines/routes.jsx';
import patientsRoutes from 'modules/Patients/routes.jsx';
import permissionsRoutes from 'modules/Permissions/routes.jsx';
import EMRSetting from 'modules/EMR/routes.jsx';
import auditLogs from 'modules/AuditLogs/routes.jsx';
export default [
  ...authRoutes,
  ...customersRoutes,
  ...hcpUsersRoutes,
  ...machinesRoutes,
  ...patientsRoutes,
  ...permissionsRoutes,
  ...EMRSetting,
  ...auditLogs
];
