import Instructions from './SetupReview/Instructions';
import TopDeadCenter from './SetupReview/TopDeadCenter';
import LevelAndLock from './SetupReview/LevelAndLock';
import SetCounterBalance from './SetupReview/SetCounterBalance';
import SeatingPosition from './SetupReview/SeatingPosition';
import Restraints from './SetupReview/Restraints';
import InstructionForPatient from './InstructionForPatient';
import MachineSetup from './MachineSetup';
import PatientAdjustments from '../PatientAdjustments';
import SetAngleSelector from './SetAngleSelector';
import DynamicExercise from './DynamicExercise';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

const Steps = ({
  step,
  subStep,
  stateData,
  setupMachineData,
  handleCancel,
  handleBack,
  handleNext,
  machine,
  onDataChange,
  resetNav,
  handleRemoveSkip,
  disableFirstStep,
}) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const setupId = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');
  const machineType = searchParams.get('machineType');
  const [nextStep, setNextStep] = useState('');
  const activityId = localStorage.getItem('activity_id');

  useEffect(() => {
    return () => {
      localStorage.removeItem('activity_id');
      resetNav();
    };
  }, []);

  useEffect(() => {
    if (!step || !subStep) {
      return;
    }

    let currentStep = `${step}`;
    if (subStep !== '0') {
      currentStep = `${step}-${subStep}`;
    }

    setNextStep(currentStep);
  }, [step, subStep]);

  const getStep = () => {
    if (!nextStep) {
      return;
    }

    const props = {
      step,
      subStep,
      value: stateData,
      handleCancel,
      handleBack,
      handleNext,
      machine: machine,
      onDataChange,
      setupMachineData: setupMachineData ? setupMachineData.data ?? {} : {},
      sessionId: setupMachineData ? setupMachineData.session_id : '',
    };

    switch (nextStep) {
      case '1-1':
        return <Instructions {...props} />;
      case '1-2':
        return <TopDeadCenter {...props} />;
      case '1-3':
        return <LevelAndLock {...props} />;
      case '1-4':
        return <SetCounterBalance {...props} />;
      case '1-5':
        return <SeatingPosition {...props} />;
      case '1-6':
        return <Restraints {...props} handleRemoveSkip={handleRemoveSkip} />;
      case '2':
        return <InstructionForPatient {...props} disableFirstStep={disableFirstStep} />;
      case '3':
        return <MachineSetup {...props} />;
      case '4':
        return <PatientAdjustments {...props} />;
      case '5':
        return <SetAngleSelector {...props} />;
      case '6':
        return <DynamicExercise {...props} />;
      case '7':
        return (
          <Navigate
            to={`/patient/${id}/dynamic-test/summary?setupId=${setupId}&mode=1&machineType=${machineType}&sessionId=${sessionId}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=false`}
          />
        );
      default:
        return <></>;
    }
  };

  const stepRender = useMemo(() => getStep(), [nextStep]);
  return <> {stepRender}</>;
};

Steps.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  onDataChange: PropTypes.func,
  setupMachineData: PropTypes.object,
  stateData: PropTypes.object,
  resetNav: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  disableFirstStep: PropTypes.bool,
};

export default Steps;
