import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';

export const CustomersSlice = createSlice({
  name: 'customersSlice',
  initialState: {
    error: null,
    customers: [],
    filteredCustomers: [],
    cities: [],
    states: [],
    itemPerPage: 100,
    totalCustomers: 0,
    createdCustomer: null,
    hcpInfo: null,
    isFetchingHcpInfo: false,
    isUpdatingHcpInfo: false,
    hcpRoles: [],
    isFetchingHcpRole: false,
    isCreatingHcpUser: false,
    isUpdatingHcpUser: false,
    hcpActionError: null,
  },
  reducers: {
    fetchCustomers: (state) => {
      state.error = null;
    },
    fetchCustomersSuccess: (state, action) => {
      state.customers = action.payload.hcp;
      state.itemPerPage = action.payload.item_per_page;
      state.totalCustomers = action.payload.total_hcp;
    },
    fetchCustomersFailure: (state, action) => {
      state.customers = [];
      state.error = action.payload.code;
    },
    filterCustomers: (state) => {
      state.error = null;
    },
    filterCustomersSuccess: (state, action) => {
      state.filteredCustomers = action.payload.hcp;
      state.itemPerPage = action.payload.item_per_page;
      state.totalCustomers = action.payload.total_hcp;
    },
    filterCustomersFailure: (state, action) => {
      state.filteredCustomers = [];
      state.error = action.payload.code;
    },
    fetchCities: (state) => {
      state.error = null;
    },
    fetchCitiesSuccess: (state, action) => {
      state.cities = action.payload;
    },
    fetchCitiesFailure: (state, action) => {
      state.cities = [];
      state.error = action.payload.code;
    },
    fetchStates: (state) => {
      state.error = null;
    },
    fetchStatesSuccess: (state, action) => {
      state.states = action.payload;
    },
    fetchStatesFailure: (state, action) => {
      state.states = [];
      state.error = action.payload.code;
    },

    fetchHcpInfo: (state) => {
      state.hcpInfo = null;
      state.isFetchingHcpInfo = true;
    },
    fetchHcpInfoSuccess: (state, action) => {
      state.hcpInfo = action.payload;
      state.isFetchingHcpInfo = false;
    },
    fetchHcpInfoFailure: (state) => {
      state.hcpInfo = null;
      state.isFetchingHcpInfo = false;
    },

    fetchHcpRoles: (state) => {
      state.hcpRoles = [];
      state.isFetchingHcpRole = true;
    },
    fetchHcpRolesSuccess: (state, action) => {
      state.hcpRoles = action.payload;
      state.isFetchingHcpRole = false;
    },
    fetchHcpRolesFailure: (state) => {
      state.hcpRoles = [];
      state.isFetchingHcpRole = false;
    },

    createHcpUser: (state) => {
      state.isCreatingHcpUser = true;
      state.hcpActionError = null;
    },

    createHcpSuccess: (state) => {
      state.isCreatingHcpUser = false;
      state.hcpActionError = null;
    },

    createHcpUserFailure: (state, action) => {
      state.isCreatingHcpUser = false;
      state.hcpActionError = action.payload;
    },

    updateHcpUser: (state) => {
      state.isUpdatingHcpUser = true;
      state.hcpActionError = null;
    },

    updateHcpSuccess: (state) => {
      state.isUpdatingHcpUser = false;
      state.hcpActionError = null;
    },

    updateHcpUserFailure: (state, action) => {
      state.isUpdatingHcpUser = false;
      state.hcpActionError = action.payload;
    },

    archiveHcpUser: (state) => {
      state.isUpdatingHcpUser = true;
      state.hcpActionError = null;
    },

    archiveHcpUserSuccess: (state) => {
      state.isUpdatingHcpUser = false;
      state.hcpActionError = null;
    },

    archiveHcpUserFailure: (state, action) => {
      state.isUpdatingHcpUser = false;
      state.hcpActionError = action.payload;
    },

    updateHcpInfo: (state) => {
      state.isUpdatingHcpInfo = true;
    },
    updateHcpInfoSuccess: (state, action) => {
      state.hcpInfo = action.payload;
      state.isUpdatingHcpInfo = false;
    },
    updateHcpInfoFailure: (state) => {
      state.isUpdatingHcpInfo = false;
    },
    requestDeleteCustomer: (state) => {
      state.error = null;
    },
    requestDeleteCustomerSuccess: () => { },
    requestDeleteCustomerFailure: (state, action) => {
      state.error = action.payload.code;
    },
    requestSuspendCustomer: (state) => {
      state.error = null;
    },
    requestSuspendCustomerSuccess: () => { },
    requestSuspendCustomerFailure: (state, action) => {
      state.error = action.payload.code;
    },
    requestRestoreCustomer: (state) => {
      state.error = null;
    },
    requestRestoreCustomerSuccess: () => { },
    requestRestoreCustomerFailure: (state, action) => {
      state.error = action.payload.code;
    },
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdCustomer = null;
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.error = null;
      state.createdCustomer = null;
    },
  },
});
export const {
  clearSuccessAndError,
  fetchCustomers,
  fetchCustomersSuccess,
  fetchCustomersFailure,
  filterCustomers,
  filterCustomersSuccess,
  filterCustomersFailure,
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailure,
  fetchStates,
  fetchStatesSuccess,
  fetchStatesFailure,
  requestDeleteCustomer,
  requestDeleteCustomerSuccess,
  requestDeleteCustomerFailure,
  requestSuspendCustomer,
  requestSuspendCustomerSuccess,
  requestSuspendCustomerFailure,
  requestRestoreCustomer,
  requestRestoreCustomerSuccess,
  requestRestoreCustomerFailure,
  fetchHcpInfo,
  fetchHcpInfoFailure,
  fetchHcpInfoSuccess,
  updateHcpInfo,
  updateHcpInfoFailure,
  updateHcpInfoSuccess,
  fetchHcpRoles,
  fetchHcpRolesFailure,
  fetchHcpRolesSuccess,
  createHcpSuccess,
  createHcpUser,
  createHcpUserFailure,
  updateHcpSuccess,
  updateHcpUser,
  updateHcpUserFailure,
  archiveHcpUser,
  archiveHcpUserSuccess,
  archiveHcpUserFailure,
} = CustomersSlice.actions;

export default CustomersSlice.reducer;
