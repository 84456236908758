import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValidBase64 } from 'helpers';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MachineSetupView from 'modules/Patients/components/Machines/CervicalRotation/MachineSetup';

import { fetchPatient, requestInitSetup, fetchMachineSetup, requestSaveSetup } from 'modules/Patients/slice';
import { fetchHcpConfiguredMachines } from 'modules/Machines/slice';
import { navigationItems } from './navigateItems';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import axios from 'axios';
import NoResults from 'modules/Patients/components/NoResults';
import { useMachineSetup } from 'modules/Patients/hooks/useMachineSetup';
import MachineSetupContainer from 'modules/Patients/components/MachineSetupContainer';
export default function CervicalRotationMachineSetup() {
  // const { t } = useTranslation();
  // const { id } = useParams();
  // const location = useLocation();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const socketContext = useSocketDataContext();
  // const { pathname } = location;
  // const patientId = id;
  // const setupIdParams = searchParams.get('setupId');
  // const sessionId = searchParams.get('sessionId');
  // const machineId = searchParams.get('machineId');
  // const machineType = searchParams.get('machineType');
  // const setupId = localStorage.getItem('setupId');
  // const patient = useSelector((state) => state.patients.patient);
  // const isInitSetups = useSelector((state) => state.patients.isInitSetups);
  // const machines = useSelector((state) => state.machines.hcpConfiguredMachines);
  // const { isLoading } = useSelector((state) => state.ui);

  // const [step, setStep] = useState('1');
  // const [subStep, setSubStep] = useState('0');
  // const [machine, setMachine] = useState(null);

  // useEffect(() => {
  //   if (!patientId || !machineId) {
  //     return null;
  //   }

  //   const s = searchParams.get('step');
  //   const subS = searchParams.get('subStep');

  //   setStep(s ?? '1');

  //   setSubStep(subS ?? '0');

  //   if (!patientId && !isValidBase64(patientId)) {
  //     navigate(`/`);
  //     return null;
  //   }

  //   if (!sessionId) {
  //     navigate(`/patient/${patientId}`);
  //     return null;
  //   }

  //   const data = {
  //     hcpId: localStorage.getItem('hcpId'),
  //     patientId: atob(patientId),
  //     machineId: Number(machineId),
  //   };

  //   if (setupId || setupIdParams) {
  //     dispatch(fetchMachineSetup({ ...data, setupId: setupId ?? setupIdParams }));
  //   }

  //   dispatch(
  //     fetchPatient({
  //       patientId: atob(patientId),
  //       hcpId: localStorage.getItem('hcpId'),
  //     })
  //   );

  //   if (!setupId && !isInitSetups && !setupIdParams) {
  //     dispatch(requestInitSetup(data));
  //   }

  //   dispatch(fetchHcpConfiguredMachines({ hcpId: localStorage.getItem('hcpId'), machineType: machineType }));
  // }, []);

  // useEffect(() => {
  //   const setupId = setupIdParams || localStorage.getItem('setupId');
  //   if (!setupId) {
  //     return null;
  //   }

  //   const currentLocation = `${pathname}?machineId=${machineId}&machineType=${machineType}&sessionId=${sessionId}&setupId=${setupId}`;

  //   if (step || (subStep && subStep !== '0')) {
  //     navigate(`${currentLocation}&step=${step}&subStep=${subStep}`);
  //   }
  // }, [step, subStep, isInitSetups]);

  // useEffect(() => {
  //   if (machineId && machines.length > 0) {
  //     const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));
  //     setMachine(machine);
  //   }
  // }, [machines]);

  // const handleSelectItem = (item) => {
  //   setStep(item.step);
  //   setSubStep(item.subStep);
  // };

  // const handleBack = () => {
  //   const path = `/patient/${patientId}/session`;

  //   let previousStep = step;
  //   let previousSubStep = subStep;

  //   if (step === '1') {
  //     navigate(`${path}/select-machine?sessionId=${sessionId}`);
  //     return;
  //   }

  //   const current = navigationItems.find((item) => item.id === `${step}`);

  //   if (current && !current.children) {
  //     previousStep = parseInt(step, 10) - 1;

  //     const previous = navigationItems.find((item) => item.id === `${previousStep}`);

  //     if (previous && previous.children) {
  //       previousSubStep = previous.children.length;
  //     } else {
  //       previousSubStep = '0';
  //     }
  //   } else {
  //     if (subStep === '1') {
  //       previousStep = parseInt(step, 10) - 1;
  //       const previous = navigationItems.find((item) => item.id === `${previousStep}`);

  //       if (previous && previous.children) {
  //         previousSubStep = previous.children.length;
  //       } else {
  //         previousSubStep = '0';
  //       }
  //     } else {
  //       previousSubStep = parseInt(subStep, 10) - 1;
  //     }
  //   }

  //   setSubStep(previousSubStep.toString());
  //   setStep(previousStep.toString());
  // };

  // const handleNext = () => {
  //   let nextStep = step;
  //   let nextSubStep = subStep;

  //   const current = navigationItems.find((item) => item.id === `${step}`);

  //   if (current && !current.children) {
  //     nextStep = parseInt(step, 10) + 1;

  //     const next = navigationItems.find((item) => item.id === `${nextStep}`);

  //     if (next && next.children) {
  //       nextSubStep = '1';
  //     } else {
  //       nextSubStep = '0';
  //     }
  //   } else {
  //     if (subStep === `${current.children.length}`) {
  //       nextStep = parseInt(step, 10) + 1;

  //       const next = navigationItems.find((item) => item.id === `${nextStep}`);

  //       if (next && next.children) {
  //         nextSubStep = '1';
  //       } else {
  //         nextSubStep = '0';
  //       }
  //     } else {
  //       nextSubStep = parseInt(subStep, 10) + 1;
  //     }
  //   }

  //   setStep(nextStep.toString());
  //   setSubStep(nextSubStep.toString());
  // };

  // const handleSaveData = (data) => {
  //   if (!setupId && !setupIdParams) {
  //     return null;
  //   }

  //   dispatch(
  //     requestSaveSetup({
  //       hcpId: localStorage.getItem('hcpId'),
  //       patientId: atob(patientId),
  //       machineId: machine.machine.id,
  //       finished: false,
  //       setupId: setupId ?? setupIdParams,
  //       data,
  //     })
  //   );
  // };

  const {
    Provider,
    handleBack,
    handleNext,
    handleSaveData,
    handleSelectItem,
    isLoading,
    machine,
    registerNavigation,
    socketContext,
    patient,
    step,
    subStep,
    navigationItems,
    handleSetStep,
    handleSetSubStep
  } = useMachineSetup();

  if (!patient || !machine) return <NoResults isLoading={isLoading} />;

  return (
    <Provider value={{ registerNavigation: registerNavigation, navigationItems }}>
      <MachineSetupContainer>
        <MachineSetupView
          patient={patient}
          machine={machine}
          handleSaveData={handleSaveData}
          handleNext={handleNext}
          handleBack={handleBack}
          handleSelectItem={handleSelectItem}
          step={step}
          subStep={subStep}
          handleSetStep={handleSetStep}
          handleSetSubStep={handleSetSubStep}
          socketContext={socketContext}
        />
      </MachineSetupContainer>
    </Provider>
  );
}
