import PropTypes from 'prop-types';
import { useAuthDataContext } from 'components/AuthProvider/index.jsx';
import Box from '@mui/material/Box';
import TextLogo from 'assets/images/SVG/BioneX_logo.svg';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

import { AppBar } from './AppBar/AppBar';
import UserAccount from 'modules/Profile/UserAccount';

export default function CustomerHeader({ showDrawerIcon, handleDrawerOpen, open, drawerWidth, notification }) {
  const navigator = useNavigate();
  const { currentUser } = useAuthDataContext();

  if (!currentUser) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ boxShadow: 0 }} drawerwidth={drawerWidth} position='static' open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            backgroundColor: 'black',
          }}
        >
          {showDrawerIcon && (
            <IconButton
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{ color: '#FFF', mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Box
            component='img'
            sx={{
              cursor: 'pointer',
              height: 'auto',
              display: { xs: 'none', md: 'block' },
              maxWidth: '250px',
            }}
            alt='Bionex'
            src={TextLogo}
            onClick={() => navigator('/')}
          />
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                margin: '0 10px',
                padding: '4px 12px',
                borderRadius: '8px',
                border: '1px solid #F1F3F5',
                background: '#CED4DA',
                color: '#212529',
                display: 'flex',
                alignItems: 'center',
                height: '32px'
              }}
            >
              Online mode
            </Box>
            {notification}
            <UserAccount signOutUrl='/sign-out' />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

CustomerHeader.propTypes = {
  showDrawerIcon: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func,
  drawerWidth: PropTypes.number,
  notification: PropTypes.component
};
