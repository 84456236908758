import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchMachines,
  fetchMachinesSuccess,
  fetchMachinesFailure,
  fetchDetectedMachines,
  fetchDetectedMachinesSuccess,
  fetchDetectedMachinesFailure,
  requestAddMachine,
  requestAddMachineSuccess,
  requestAddMachineFailure,
  requestEditMachine,
  requestEditMachineSuccess,
  requestEditMachineFailure,
  requestAssignMachine,
  requestAssignMachineSuccess,
  requestAssignMachineFailure,
  requestRemoveMachine,
  requestRemoveMachineSuccess,
  requestRemoveMachineFailure,
  requestConfigurePreAddedMachine,
  requestConfigurePreAddedMachineSuccess,
  requestConfigurePreAddedMachineFailure,
  fetchHcpConfiguredMachines,
  fetchHcpConfiguredMachinesSuccess,
  fetchHcpConfiguredMachinesFailure,
  fetchHcpMachines,
  fetchHcpMachinesSuccess,
  fetchHcpMachinesFailure,
  requestUpdateSoftware,
  requestUpdateSoftwareSuccess,
  requestUpdateSoftwareFailure,
  requestLoggingRecordAction,
  requestLoggingRecordActionSuccess,
  requestLoggingRecordActionFailed,
} from './slice';

import { requestDialog } from 'modules/Dialogs/slice';
import { END_POINT } from './constants';
import { requestToast } from 'modules/Ui/slice';
import { TOAST_TYPE } from 'modules/Ui/constants';
import { DIALOGS } from 'modules/Dialogs/constants';

function* getMachines(action) {
  const query = action.payload || {};
  const filterType = query.filterType ? query.filterType : '';
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const itemPerPage = query.itemPerPage || '';
  const mergedFilters = [];
  const mergedValues = [];

  let queryString = [];
  if (itemPerPage) {
    queryString.push(`item_per_page=${itemPerPage}`);
  }
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterType) {
    mergedFilters.push('machine_type');
    mergedValues.push(filterType);
  }

  if (mergedFilters.length > 0) {
    queryString.push(`filter_by=${mergedFilters.join(',')}&filter_value=${mergedValues.join(',')}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.getMachines.url(action.payload.hcpId)}${queryString ? `&${queryString}` : ''}`
    );
    if (data.data) {
      yield put(fetchMachinesSuccess(data.data));
    } else {
      yield put(fetchMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchMachinesFailure(e.message));
  }
}

function* getDetectedMachines(action) {
  const query = action.payload || {};
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const itemPerPage = query.itemPerPage || '';

  let queryString = [];
  if (itemPerPage) {
    queryString.push(`item_per_page=${itemPerPage}`);
  }
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }

  try {
    const { data } = yield axios.get(
      `${END_POINT.getDetectedMachines.url(action.payload)}${queryString ? `?${queryString}` : ''}`
    );
    if (data.data) {
      yield put(fetchDetectedMachinesSuccess(data.data));
    } else {
      yield put(fetchDetectedMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchDetectedMachinesFailure(e.message));
  }
}

function* addMachine(action) {
  try {
    const { data } = yield axios.post(END_POINT.addMachine.url, {
      name: action.payload.name.trim(),
      serial_no: action.payload.number.trim(),
      machine_type: action.payload.type,
      u12_id: action.payload.converterId,
      mac_addr: action.payload.macAddress,
      notes: '',
    });
    if (data.code === 201) {
      yield put(requestAddMachineSuccess(data));
      yield put(fetchMachines({ ...action.payload.sortData, hcpId: localStorage.getItem('hcpId') }));
    } else {
      yield put(requestAddMachineFailure(data));
    }
  } catch (e) {
    yield put(requestAddMachineFailure(e.message));
  }
}

function* editMachine(action) {
  try {
    const machine = {
      name: action.payload.name ? action.payload.name.trim() : undefined,
      serial_no: action.payload.number ? action.payload.number.trim() : undefined,
      machine_type: action.payload.type || undefined,
      u12_id: action.payload.converterId,
      mac_addr: action.payload.macAddress,
      lode_cell_calibrated_at: action.payload.lodeCellCalibratedAt || undefined,
      pot_calibrated_at: action.payload.potCalibratedAt || undefined,
    };
    Object.keys(machine).map((key) => {
      if (machine[key] === undefined) {
        delete machine[key];
      }
    });

    const { data } = yield axios.put(END_POINT.editMachine.url(action.payload.id, action.payload.hcpId), machine);

    if (data.code === 200) {
      yield put(fetchMachines({ ...action.payload.sortData, hcpId: localStorage.getItem('hcpId') }));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'Machines information updated successfully.',
        })
      );
      yield put(requestDialog({ type: DIALOGS.ADD_MACHINE_DIALOG, open: false }));
      yield put(requestEditMachineSuccess(data));
    } else {
      yield put(requestEditMachineFailure(data));
    }
  } catch (e) {
    yield put(requestEditMachineFailure(e.message));
  }
}

function* assignMachine(action) {
  try {
    const { data } = yield axios.put(END_POINT.assignMachine.url(action.payload.id), {
      hcp_id: action.payload.hcp.id,
      hcp_allow_config: action.payload.canConfigure === 1 ? true : false,
    });
    if (data.code === 201) {
      yield put(requestAssignMachineSuccess(data));
      yield put(fetchMachines({ ...action.payload.sortData, hcpId: localStorage.getItem('hcpId') }));
    } else {
      yield put(requestAssignMachineFailure(data));
    }
  } catch (e) {
    yield put(requestAssignMachineFailure(e.message));
  }
}

function* removeMachine(action) {
  try {
    const { data } = yield axios.delete(END_POINT.removeMachine.url(action.payload.id));
    if (data.code === 200) {
      yield put(requestRemoveMachineSuccess(data));
      yield put(fetchMachines({ hcpId: localStorage.getItem('hcpId') }));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.serial}" removed.`,
        })
      );
    } else {
      yield put(requestRemoveMachineFailure(data));
    }
  } catch (e) {
    yield put(requestRemoveMachineFailure(e.message));
  }
}

function* configurePreAddedMachine(action) {
  try {
    const { data } = yield axios.post(END_POINT.configureMachine.url(action.payload.id, action.payload.hcpId), {
      name: action.payload.name.trim(),
      serial_no: action.payload.serialNo.trim(),
      machine_type: action.payload.type,
      u12_id: action.payload.converterId,
      mac_address: action.payload.macAddress,
    });
    if (data.code === 200) {
      yield put(requestConfigurePreAddedMachineSuccess());
      //close dialog
      yield put(requestDialog({ type: DIALOGS.CONFIGURE_MACHINE_DIALOG, open: false }));
      //get Machines
      yield put(fetchMachines({ ...action.payload.sortData, hcpId: localStorage.getItem('hcpId') }));
    } else {
      yield put(requestConfigurePreAddedMachineFailure(data));
    }
  } catch (e) {
    yield put(requestConfigurePreAddedMachineFailure(e.message));
  }
}

function* getHcpConfiguredMachines(action) {
  const hcpId = action.payload.hcpId;
  const machineType = action.payload.machineType;

  let queryString = [];
  if (hcpId) {
    queryString.push(`hcp_id=${hcpId}`);
  }
  if (machineType) {
    queryString.push(`machine_type=${machineType}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.getHcpConfiguredMachines.url}${queryString ? `?${queryString}` : ''}`
    );
    if (data.code === 200) {
      yield put(fetchHcpConfiguredMachinesSuccess(data.data));
    } else {
      yield put(fetchHcpConfiguredMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpConfiguredMachinesFailure(e.message));
  }
}

function* getHcpMachines(action) {
  const query = action.payload || {};
  const filterStatuses = query.filterStatuses || [];
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const mergedFilters = [];
  let mergedValues = [];

  let queryString = [];
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterStatuses.length) {
    for (let i = 0; i < filterStatuses.length; i++) {
      mergedFilters.push('status');
    }
    mergedValues = [...mergedValues, ...filterStatuses];
  }

  if (mergedFilters.length > 0) {
    queryString.push(`filter_by=${mergedFilters.join(',')}&filter_value=${mergedValues.join(',')}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.getHcpMachines.url(action.payload.hcpId)}${queryString ? `?${queryString}` : ''}`
    );
    if (data.code === 200) {
      yield put(fetchHcpMachinesSuccess(data.data));
    } else {
      yield put(fetchHcpMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpMachinesFailure(e.message));
  }
}

function* requestUpdateSoftwareHandler(action) {
  try {
    yield axios.get(`${END_POINT.requestUpdateSoftware.url(action.payload.machineId, action.payload.hcpId)}`);
    yield put(requestUpdateSoftwareSuccess());

    yield put(
      requestToast({
        type: TOAST_TYPE.SUCCESS,
        message: `The software update process has been started.`,
      })
    );
  } catch (e) {
    yield put(requestUpdateSoftwareFailure(e));
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Update software error.`,
      })
    );
  }
}

function* requestLoggingRecordActionHandler(action) {
  try {
    yield axios.post(END_POINT.requestLoggingRecordAction.url, {
      ...action.payload
    },
      {
        headers: {
          Authorization: `Bearer ${action.payload.token}`
        }
      }
    );
    yield put(requestLoggingRecordActionSuccess());
  } catch (e) {
    yield put(requestLoggingRecordActionFailed(e));
  }
}

function* machineSaga() {
  yield takeLatest(fetchMachines, getMachines);
  yield takeLatest(fetchDetectedMachines, getDetectedMachines);
  yield takeLatest(requestAddMachine, addMachine);
  yield takeLatest(requestAssignMachine, assignMachine);
  yield takeLatest(requestEditMachine, editMachine);
  yield takeLatest(requestRemoveMachine, removeMachine);
  yield takeLatest(requestConfigurePreAddedMachine, configurePreAddedMachine);
  yield takeLatest(fetchHcpConfiguredMachines, getHcpConfiguredMachines);
  yield takeLatest(fetchHcpMachines, getHcpMachines);
  yield takeLatest(requestUpdateSoftware, requestUpdateSoftwareHandler);
  yield takeLatest(requestLoggingRecordAction, requestLoggingRecordActionHandler);
}

export default machineSaga;
