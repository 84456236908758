import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SingleImageStep } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import lapBelt from 'assets/images/png/physical-positioning/3d1.png';
import footRest from 'assets/images/png/physical-positioning/3d2.png';
import handle from 'assets/images/png/physical-positioning/3d3.png';
import counterweightLock from 'assets/images/png/machine-setup/6a.png';

import headRest from 'assets/images/SVG/Cervical/Head-rest.svg';
import torsoRestraints from 'assets/images/SVG/Cervical/Torso-restraints.svg';
import Button from '@mui/material/Button';
import { useState } from 'react';
import ScrollDialog from 'components/ScrollDialog/index.jsx';
import Actions from 'modules/Patients/components/Machines/Actions';
const manual = () => (
  <Box sx={{ width: '100%', height: '100vh' }}>
    <iframe
      src='https://drive.google.com/file/d/1A47JpU1LGT-ISlRT8kWEMUM00BbyK4Fz/preview'
      width='100%'
      height='100%'
      allow='autoplay'
      frameBorder='0'
    ></iframe>
  </Box>
);
export function BriefPatient({ step, subStep, handleCancel, handleBack, handleNext }) {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box>
        <Typography variant='body1' component='div'>
          1. Have a brief discussion about the process with new patients to help ease anxiety and apprehension.
          <br />
          2. Outline the test or exercise procedures to be employed. This should include the restraint mechanism and how
          and why it will be used.
          <br />
          3. The patient should be informed as to how they should and should not feel physically. Often, new subjects
          experience warming sensation, associated with muscle fatigue, in the neck region after testing or exercise.
          <br />
          4. Listen to the patient. Rely on patient feedback, especially during the stabilization procedures and dynamic
          exercise.
          <br />
          5. Be considerate of patient comfort. If, there is an unexpected, lengthy pause or interruption in testing or
          exercise, the restraint mechanism should be relaxed or loosened.
          <br />
          6. Have patient verbally state their understanding and allow them to ask questions prior to the setup phase.
        </Typography>

        {/* <div style={{ padding: 2 }}>
          <Button onClick={() => setOpenModal(true)} sx={{ ml: 'auto', textDecoration: 'underline' }} variant='text'>
            Link to Manual
          </Button>
        </div> */}
        <ScrollDialog
          fullWidth={true}
          message={manual()}
          title='MANUAL'
          handleCloseModal={handleCloseModal}
          openModal={openModal}
          handleSubmitModal={handleSubmitModal}
        />
      </Box>

      {/* actions */}

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
BriefPatient.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function AngleSelector({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>1. Here is a step by step through setting up your patient in the machine.</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={handle} />
            </Box>

            <Box>2. Have the patient sit upright in the machine with their back against the seatback.</Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={handle} />
            </Box>

            <Box>
              3. Have the patient placed their right hand, palm down across their Adam’s apple to help alignment. Please
              add a seat pad if needed to achieve this height.
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={handle} />
            </Box>

            <Box>
              3. Have the patient placed their right hand, palm down across their Adam’s apple to help alignment.
            </Box>
          </Box>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
AngleSelector.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function SeatLabBeltAdjustment({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>1. Here is a step by step through setting up your patient in the machine.</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={handle} />
            </Box>

            <Box>
              1. Help the patient fasten and snug the seat belt and shoulder restraints.
              <br />
              2. Do not tighten completely at this point, leave enough slack to allow for seat height adjustment.
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={headRest} />
            </Box>

            <Box>
              3. Adjust headrest by swinging headrest downward. <br />
              4. Patient’s head should rest against the headrest.
              <br />
              5. Patient’s hand should be crossed in their lap. Feet should be crossed at the ankles and remain still.
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={torsoRestraints} />
            </Box>

            <Box>
              6. Close the gate and snug the torso restraint pads (Titan clockwise, loosen counter). Rotating pads
              should be positioned to conform with the contour of the patient’s upper.
              <br />
              7. Snug the shoulder restraints once again.
              <br />
              8. <b style={{ color: '#ed9a00' }}>Check</b> : Confirm with patient that they cannot lift their shoulders.
            </Box>
          </Box>
        </Box>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
SeatLabBeltAdjustment.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function TightenRestraints({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={lapBelt} />
            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              Begin to tighten lap belt until patient verbally states that it’s snug, but not uncomfortable.
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={footRest} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography sx={{ flex: 1 }} variant='body1' component='div'>
                Begin to tighten the footboard until the patient verbally states that it is snug, but not uncomfortable.
              </Typography>
              <Typography sx={{ fontSize: '14px', flex: 1 }} variant='body1' component='div'>
                <br />
                <strong>Test:</strong> Ask the patient to lift heels. 1/4 inch lift is allowed. Adjust Footrest adjuster
                as needed.
                <br />
                <br /> <strong>Test:</strong> Ask the patient to touch their toes. <br />
                Patient should not be able to lift butt out of the chair, should have constant contact between posterior
                lumbar and back rest. Also, see if the lumbar roll rotates more than 1/4 turn. Adjust lap belt adjuster
                as needed.
                <br /> If needed, repeat heel lift and toe touch tests, and make sure adjustments achieve goals.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={handle} />
            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              Have the patient hold onto operator handles.
            </Typography>
          </Box>
        </OverlayScrollbarsComponent>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
TightenRestraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function LevelAndLockCounterWeight({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
        }}
      >
        <SingleImageStep img={counterweightLock} />
        <Typography sx={{ flex: 1 }} variant='body1' component='div'>
          Confirm bubble level is in the center and lock Counterweight lock.
        </Typography>
      </Box>
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
LevelAndLockCounterWeight.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
