import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getLabel } from 'helpers';
import {
  ATHLETE_LEVEL_OPTIONS,
  ATHLETE_POSITION_OPTIONS,
  ATHLETE_PRACTICE_SPORT_OPTION,
} from 'modules/DemoGraphic/Shared/options.js';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import BorderColor from '@mui/icons-material/BorderColor';

const AthleteInformation = ({ demoGraphicInformation, onEdit }) => {
  const onEditHandler = () => {
    onEdit(2);
  };
  const contentRender = () => {
    if (!demoGraphicInformation || (demoGraphicInformation && Object.keys(demoGraphicInformation).length === 0)) {
      return (
        <Box sx={{ width: '100%', padding: '0 15px ' }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <span className={'main-title'}>Athlete Information</span>
              <div style={{ cursor: 'pointer' }} onClick={onEditHandler}>
                <IconButton sx={{ padding: 0 }} color='secondary'>
                  <BorderColor />
                </IconButton>
                <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
              </div>
            </Box>
          </Box>

          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
              <span className={'title'}>What sport do you participate?</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
              <span className={'title'}>Position/Event</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
              <span className={'title'}>Current level</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
          </Box>
          <Divider sx={{
            borderBottomWidth: '2px',
            borderColor: '#f5f5f5',
          }}/>

          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>How many years have you been participating in that sport?</span>
                  <span className={'value'}>{'N/A'}</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ width: '100%', padding: '0 15px ' }}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <span className={'main-title'}>Athlete Information</span>
            <div style={{ cursor: 'pointer' }} onClick={onEditHandler}>
              <IconButton sx={{ padding: 0 }} color='secondary'>
                <BorderColor />
              </IconButton>
              <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
            </div>
          </Box>
        </Box>

        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
            <span className={'title'}>What sport do you participate?</span>
            <span className={'value'}>
              {getLabel(demoGraphicInformation.practiceSport, ATHLETE_PRACTICE_SPORT_OPTION)}
            </span>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
            <span className={'title'}>Position/Event</span>
            <span className={'value'}>
              {getLabel(
                demoGraphicInformation.position,
                ATHLETE_POSITION_OPTIONS[demoGraphicInformation.practiceSport]
              )}
            </span>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
            <span className={'title'}>Current level</span>
            <span className={'value'}> {getLabel(demoGraphicInformation.level, ATHLETE_LEVEL_OPTIONS)}</span>
          </Box>
        </Box>
        <Divider sx={{
          borderBottomWidth: '2px',
          borderColor: '#f5f5f5'
        }}/>

        <Box className={'row'}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                <span className={'title'}>How many years have you been participating in that sport?</span>
                <span className={'value'}>{demoGraphicInformation.practiceYear ?? 'N/A'}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return contentRender();
};

AthleteInformation.propTypes = {
  demoGraphicInformation: PropTypes.object,
  onEdit: PropTypes.func,
};

export default AthleteInformation;
