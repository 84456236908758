import styled from '@emotion/styled';

const GaugeContainer = styled('div')`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  & .gauge {
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    width: 400px;
    height: 400px;
    position: relative;
  }
  & .circle-container {
    position: absolute;
    width: 100%;
    height: 80%;
    transform: rotate(-200deg);
    fill: none;
    stroke: white;
  }

  & .circle-container__background {
    fill: none;
    stroke: gainsboro;
    stroke-width: 3px;
    stroke-dasharray: 61.5;
    stroke-linecap: round;
  }

  & .minor-ticks {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .minor-ticks span {
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 2px solid #afafaf;
  }

  & .minor-ticks::after {
    content: '';
    position: absolute;
    width: 87%;
    height: 87%;
    background: #fff;
    border-radius: 50%;
  }

  & .pointer {
    position: relative;
    z-index: 1;
    transform: rotate(-20deg) translate(-50%, -50%);
    width: 40% !important;
    border-top: none !important;
    transition: 0.5s;
  }
  & .pointer div {
    width: 23%;
    height: 15px;
    border-radius: 20px;
    transform: translateY(-50%) !important;
    border: 2px solid #fff;
  }

  & .minor-ticks span div {
    position: absolute;
    font-weight: 700;
    font-size: 1em;
  }

  & .gauge-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: 50%;
    z-index: 1;
  }
  & .gauge-content .gauge-content-value {
    font-size: 5.5rem;
    font-weight: 700;
    text-align: center;
  }
  & .gauge-content .title {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 1.2rem;
  }
  & .target-tag {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 5px;
    font-size: 1rem;
    border-radius: 5px;
  }
  & .target-tag .value {
    font-weight: 700;
  }
`;

export default GaugeContainer;
