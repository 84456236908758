import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { SingleImageStep } from 'helpers';
import adjustable from 'assets/images/knee/adjustable leg support.svg';
import singleLegRestraint from 'assets/images/knee/Single Leg Restraint.svg';

export default function EquipmentSwap({ step, handleCancel, handleNext, subStep, handleBack, setupMachineData }) {
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }
    const knee = setupMachineData.knee_test_mode;

    setValue(knee === 4 ? 2 : 1);
  }, [setupMachineData]);

  const nextHandler = () => {
    handleNext();
  };

  const selectItemRender = () => {
    return (
      <Select disabled={true} size={'small'} style={{ minWidth: '100px' }} value={value}>
        <MenuItem value={1}>Left</MenuItem>
        <MenuItem value={2}>Right</MenuItem>
      </Select>
    );
  };

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box variant='body1' sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <span> 1. Let’s now setup your:</span>

            {selectItemRender()}
            <br />
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={singleLegRestraint} />
            </Box>
            <Box>
              <b> 2. Single Leg Restraint:</b>
              <div style={{ padding: '0 20px' }}>
                • If you have a single leg restraint pad, please switch it to the opposite side, so we can test the
                other leg.
                <br />
                • Make sure to adequately tighten the leg restraint pad bolt.
                <br />• Attach calve restraint to involved leg.
                <br />• Calf restraint should be tightened and snugly as possible. Avoid over tightening which might
                restrict blood flow.
              </div>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={adjustable} />
            </Box>
            <Box>
              3. Engage the adjustable leg support to accommodate the leg not involved in the patient setup.
              <br />
              4. Have patient position their non involved leg on top of the adjustable leg support.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={nextHandler}
      />
    </Box>
  );
}

EquipmentSwap.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,

  setupMachineData: PropTypes.object,
};
