import { useDispatch, useSelector } from 'react-redux';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import { closeDialog } from 'helpers/dialog.js';
import SyncModal from 'modules/DataSync/Modal/index.jsx';

const RequestSyncData = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.dialogs[DIALOGS.OPEN_SYNC_MODAL]);

  const closeModalHandler = () => {
    closeDialog(dispatch, DIALOGS.OPEN_SYNC_MODAL);
  };

  return <SyncModal open={isOpen} onClose={closeModalHandler} />;
};

export default RequestSyncData;
