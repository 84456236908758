import { useParams, useSearchParams } from 'react-router-dom';

export function useSummaryPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const patientId = id;
  const sessionId = searchParams.get('sessionId');
  const setupId = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');
  const machineType = searchParams.get('machineType');
  const url = `/patient/${id}/machine-setup/summary?setupId=${setupId}&mode=1&isBack=false&sessionId=${sessionId}&machineId=${machineId}&isCloseDuplicate=true&machineType=${machineType}`;
  return {
    url: url,
    machineId,
    sessionId,
    setupId,
    machineType,
    patientId
  };
}
