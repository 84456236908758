import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';

import adjustable from 'assets/images/knee/adjustable leg support.svg';
import seatback from 'assets/images/knee/seatback.svg';
import handlebars from 'assets/images/knee/Handlebars.svg';

const instructions = [
  {
    id: 1,
    text: '',
    img: adjustable,
  },
  {
    id: 2,
    text: '',
    img: seatback,
  },
  {
    id: 3,
    text: '',
    img: handlebars,
  },
];
const Instruction2 = ({ step, subStep, handleCancel, handleBack, handleNext, handleRemoveSkip }) => {
  const nextHandler = () => {
    handleRemoveSkip(step);
    handleNext();
  };
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[0]])}</Box>
            <Typography variant='body1' component='div'>
              1. Engage the adjustable leg support to accommodate the leg not involved in the patient setup.
              <br />
              2. Have patient position their non involved leg on top of the adjustable leg support.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[1]])}</Box>
            <Typography variant='body1' component='div'>
              3. Instruct patient to maintain contact with their back and the seatback.
              <br />
              4. Ask a patient to breathe normally, and avoid holding their breath.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[2]])}</Box>
            <Typography variant='body1' component='div'>
              5. Instruction patient to use handlebars on each side of seat to limit upper body movement during all
              testing and exercise procedures. Instruct patient to maintain loose grip on handlebars.
            </Typography>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      {/* actions */}

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={nextHandler}
        handleCancel={handleCancel}
      />
    </>
  );
};

Instruction2.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  handleRemoveSkip: PropTypes.func,
  setupMachineData: PropTypes.object,
};

export default Instruction2;
