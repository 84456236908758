export function clearLocalPatientSetupData() {
  // clear setupId on localstorage
  localStorage.removeItem('setupId');
  localStorage.removeItem('selectedMachineId');
  localStorage.removeItem('selectedMachineTypeId');
}

export function getArrDegreeMatchToCompare(arrCurrentDegree, mapObjectNearest) {
  const resultArray = arrCurrentDegree?.map(degree => {
    const matchingDegree = mapObjectNearest.get(degree);
    return matchingDegree !== undefined ? matchingDegree : null;
  });

  return resultArray
}