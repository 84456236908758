import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchUserInfo,
  fetchUserInfoSuccess,
  forceLogout,
  forceLogoutSuccess,
  fetchUserInfoFailure,
  requestHcpUserLogout,
  requestHcpUserLogoutSuccess,
  requestCustomerLogin,
  requestCustomerLoginSuccess,
  requestCustomerLoginFailure,
  requestCustomerAcceptInvite,
  requestCustomerAcceptInviteSuccess,
  requestCustomerAcceptInviteFailure,
  requestCustomerCheckInviteToken,
  requestCustomerCheckInviteTokenSuccess,
  requestCustomerCheckInviteTokenFailure,
  requestHcpUsersForgotPassword,
  requestHcpUsersForgotPasswordSuccess,
  requestHcpUsersForgotPasswordFailure,
  requestHcpUsersCheckForgotPasswordToken,
  requestHcpUsersCheckForgotPasswordTokenSuccess,
  requestHcpUsersCheckForgotPasswordTokenFailure,
  requestResetHcpUserPassword,
  requestResetHcpUserPasswordSuccess,
  requestResetHcpUserPasswordFailure,
  // for bionex users
  requestAcceptInvite,
  requestAcceptInviteSuccess,
  requestAcceptInviteFailure,
  requestCheckInviteToken,
  requestCheckInviteTokenSuccess,
  requestCheckInviteTokenFailure,
  fetchRequireTwoFaSuccess,
  requestCustomerVerifyOtp,
  requestCustomerResendOtp,
  requestCustomerVerifyOtpFailure,
  requestCustomerResendOtpSuccess,
  requestCustomerResendOtpFailure,
  requestSetOpenModalReachedFirstLimit,
  requestSetOpenModalReachedLastLimit,
  requestCustomerVerifyOtpSuccess,
} from './slice';
import { END_POINT, NUMBER_ATTEMPT } from './constants';
import auth from './authenticator';

import { endLoading, requestToast } from 'modules/Ui/slice';
import { StatusCodes } from 'http-status-codes';
import isNil from 'lodash/isNil';
import { TOAST_TYPE } from 'modules/Ui/constants';

function* customerLogin(action) {
  try {
    const { data } = yield axios.post(END_POINT.customerLogin.url, {
      ...action.payload,
    });
    if (data.token) {
      auth.setAuth(data.token);
      auth.setHcpId(data.hcp_id);
      yield put(fetchUserInfo({ token: data.token, hcpId: data.hcp_id }));
      yield put(requestCustomerLoginSuccess(data.token));
    }
    if (isNil(data.token) && data.device_uuid) {
      auth.setHcpDeviceUuid(data.device_uuid);
      yield put(fetchRequireTwoFaSuccess(data.required_next_step));
    } else {
      yield put(requestCustomerLoginFailure(data));
    }
  } catch (e) {
    console.warn(e);
    //yield put(requestCustomerLoginFailure(e.message));
  }
}

function* customerVerifyOtp(action) {
  try {
    const { data } = yield axios.post(END_POINT.customerVerifyOpt.url, {
      ...action.payload,
    });
    if (data.token) {
      auth.setAuth(data.token);
      auth.setHcpId(data.hcp_id);

      yield put(fetchUserInfo({ token: data.token, hcpId: data.hcp_id }));
      yield put(requestCustomerLoginSuccess(data.token));
      yield put(requestCustomerVerifyOtpSuccess(data.is_success));
    } else {
      switch (data.remaining_attempts) {
        case NUMBER_ATTEMPT.REACHED_FIRST_LIMIT:
          yield put(requestSetOpenModalReachedFirstLimit(true));
          break;

        case NUMBER_ATTEMPT.REACHED_LAST_LIMIT:
          yield put(requestSetOpenModalReachedLastLimit(true));
          break;

        default:
          break;
      }
      yield put(requestCustomerVerifyOtpFailure(data));
    }
  } catch (e) {
    console.warn(e);
    //yield put(requestCustomerLoginFailure(e.message));
  }
}

function* customerResendOtp(action) {
  try {
    const { data } = yield axios.post(END_POINT.customerResendOpt.url, {
      ...action.payload,
    });
    if (data.is_sent) {

      yield put(requestCustomerResendOtpSuccess(data));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'A new code has been sent to your login email address.',
        })
      );
    } else {
      yield put(requestCustomerResendOtpFailure(data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* hcpUserlogout(action) {
  try {
    yield axios.delete(`${END_POINT.hcpUserlogout.url}?hcp_id=${action.payload.hcpId}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    });
    auth.logOut();
    yield put(requestHcpUserLogoutSuccess());
  } catch (e) {
    yield put(requestHcpUserLogoutSuccess());
  }
  yield put(endLoading());
}

function* forceSignOut() {
  yield put(forceLogoutSuccess());
  yield put(endLoading());
}

function* fetchUser(action) {
  if (auth.loggedIn) {
    try {
      let res = {};
      if ((action.payload && action.payload.hcpId) || localStorage.getItem('hcpId')) {
        const hcpId = action.payload ? action.payload.hcpId : localStorage.getItem('hcpId');
        res = yield axios.get(END_POINT.customerProfile.url(hcpId));
      } else {
        res = yield axios.get(END_POINT.profile.url);
      }

      const { data } = res;
      if (data.data) {
        auth.setRole(data.data);
        yield put(fetchUserInfoSuccess(data.data));
      } else {
        yield put(fetchUserInfoFailure({ code: data.code }));
        auth.logOut();
      }
    } catch (error) {
      if (error?.message && error.message.toLowerCase() === 'request failed with status code 403') {
        yield put(fetchUserInfoFailure({ code: StatusCodes.UNAUTHORIZED }));
      } else {
        yield put(fetchUserInfoFailure({ code: error }));
      }

      yield put(forceLogout());
    }
  } else {
    yield put(fetchUserInfoFailure({ code: StatusCodes.UNAUTHORIZED }));
  }
}

function* acceptInvite(action) {
  try {
    const { data } = yield axios.put(END_POINT.acceptInvite.url, {
      ...action.payload,
    });
    if (data.code === 200) {
      yield put(requestAcceptInviteSuccess(data.data));
    } else {
      yield put(requestAcceptInviteFailure(data));
    }
  } catch (e) {
    yield put(requestAcceptInviteFailure(e.message));
  }
}

function* checkInviteToken(action) {
  try {
    const { data } = yield axios.get(END_POINT.checkInviteToken.url(action.payload));
    if (data.code === 200) {
      yield put(requestCheckInviteTokenSuccess(data.data));
    } else {
      yield put(requestCheckInviteTokenFailure(data));
    }
  } catch (e) {
    yield put(requestCheckInviteTokenFailure(e.message));
  }
}

function* checkCustomerInviteToken(action) {
  try {
    const { data } = yield axios.get(END_POINT.customersCheckInviteToken.url(action.payload));
    if (data.code === 200) {
      yield put(requestCustomerAcceptInviteSuccess(data.data));
    } else {
      yield put(requestCustomerAcceptInviteFailure(data));
    }
  } catch (e) {
    yield put(requestCustomerAcceptInviteFailure(e.message));
  }
}

function* customerAcceptInvite(action) {
  try {
    const { data } = yield axios.put(END_POINT.customersAcceptInvite.url, {
      ...action.payload,
    });
    if (data.code === 200) {
      yield put(requestCustomerCheckInviteTokenSuccess(data.data));
    } else {
      yield put(requestCustomerCheckInviteTokenFailure(data));
    }
  } catch (e) {
    yield put(requestCustomerCheckInviteTokenFailure(e.message));
  }
}

function* hcpUsersforgotPassword(action) {
  try {
    const { data } = yield axios.post(END_POINT.hcpUsersforgot.url, {
      ...action.payload,
    });
    if (data.code === 200) {
      yield put(requestHcpUsersForgotPasswordSuccess(data.data));
    } else {
      yield put(requestHcpUsersForgotPasswordFailure(data));
    }
  } catch (e) {
    yield put(requestHcpUsersForgotPasswordFailure(e.message));
  }
}

function* resetHcpUserPassword(action) {
  try {
    const { data } = yield axios.put(END_POINT.resetHcpUserPassword.url(action.payload.token), {
      ...action.payload,
    });
    if (data.code === 200) {
      yield put(
        // passing the email so we can auto fill on login page
        requestResetHcpUserPasswordSuccess({ email: action.payload.email })
      );
    } else {
      yield put(requestResetHcpUserPasswordFailure(data));
    }
  } catch (e) {
    yield put(requestResetHcpUserPasswordFailure(e.message));
  }
}

function* checkHcpUsersForgotPasswordToken(action) {
  try {
    const { data } = yield axios.get(END_POINT.checkHcpUsersForgotPasswordToken.url(action.payload));
    if (data.code === 200) {
      yield put(requestHcpUsersCheckForgotPasswordTokenSuccess(data.email));
    } else {
      yield put(requestHcpUsersCheckForgotPasswordTokenFailure({ code: 404 }));
    }
  } catch (e) {
    yield put(requestHcpUsersCheckForgotPasswordTokenFailure(e.message));
  }
}

function* authSaga() {
  yield takeLatest(requestCustomerLogin, customerLogin);
  yield takeLatest(requestCustomerVerifyOtp, customerVerifyOtp);
  yield takeLatest(requestCustomerResendOtp, customerResendOtp);
  yield takeLatest(forceLogout, forceSignOut);
  yield takeLatest(requestHcpUserLogout, hcpUserlogout);
  yield takeLatest(fetchUserInfo, fetchUser);
  yield takeLatest(requestAcceptInvite, acceptInvite);
  yield takeLatest(requestCheckInviteToken, checkInviteToken);
  yield takeLatest(requestCustomerAcceptInvite, customerAcceptInvite);
  yield takeLatest(requestCustomerCheckInviteToken, checkCustomerInviteToken);
  yield takeLatest(requestHcpUsersForgotPassword, hcpUsersforgotPassword);
  yield takeLatest(requestHcpUsersCheckForgotPasswordToken, checkHcpUsersForgotPasswordToken);
  yield takeLatest(requestResetHcpUserPassword, resetHcpUserPassword);
}

export default authSaga;
