import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import counterbalanceAdjuster from 'assets/images/knee/Counterbalance adjuster.svg';
import { SingleImageStep } from 'helpers';
export default function PatientRelease({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={counterbalanceAdjuster} />
            </Box>
            <Box>
              1. Release Patient:
              <div style={{ padding: '0 20px' }}>
                • Move the patient to a comfortable position, unlock movement arm and use Operator Control handle to
                select 72°. Lock movement arm.
                <br />• Quickly loosen seatbelt with seatbelt adjustment.
              </div>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

PatientRelease.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
