import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

export default function EditHcpUserProfileDialog({
  error,
  currentUser,
  onSubmitEditHcpUserProfileDialog,
  openEditHcpUserProfileDialog,
  handleCloseEditHcpUserProfileDialog,
}) {
  const defaultValues = {
    firstName: currentUser.first_name,
    lastName: currentUser.last_name,
    mobile: currentUser.phone_number,
    email: currentUser.email,
  };

  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    setError,
    formState: { errors, isDirty },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (openEditHcpUserProfileDialog) {
      reset(defaultValues);
    }
  }, [openEditHcpUserProfileDialog]);

  useEffect(() => {
    if (error && error.code === 400 && error.error.subcode === 100) {
      setError('email', {
        type: 'email_exists',
      });
    }
  }, [error]);

  const getEmailErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:addPatientDialog.errors.invalidEmailPattern');
    if (error?.type === 'email_exists') return t('customer:addPatientDialog.errors.emailExists');
  };

  const getPhoneErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
  };

  const getNameErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:addPatientDialog.errors.invalidNamePattern');
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    if (
      data.firstName === currentUser.first_name &&
      data.lastName === currentUser.last_name &&
      data.mobile === currentUser.phone_number &&
      data.email === currentUser.email
    ) {
      handleCloseEditHcpUserProfileDialog();
      return;
    }
    if (onSubmitEditHcpUserProfileDialog) {
      onSubmitEditHcpUserProfileDialog({
        first_name: data.firstName.trim(),
        last_name: data.lastName.trim(),
        phone_number: data.mobile.trim(),
        email: data.email.trim(),
        hcpId: currentUser.hcp.hcp_id,
      });
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  };

  const closeModal = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    if (handleCloseEditHcpUserProfileDialog) {
      handleCloseEditHcpUserProfileDialog();
    }
    reset(defaultValues);
  };
  return (
    <div>
      <Dialog open={openEditHcpUserProfileDialog} onClose={closeModal}>
        <DialogTitle>{t('user:profile.editDialog.title')}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {error && error.code === 400 && !error.error.subcode ? (
              <Alert severity='error'>{error.error.message}</Alert>
            ) : null}
            <Controller
              name='firstName'
              control={control}
              rules={{
                required: true,
                pattern: /^[a-zA-Z0-9 ]*$/,
                validate: {
                  required: (v) => !!v.trim(),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={!!errors.firstName}
                  helperText={getNameErrorMessage(errors.firstName)}
                  margin='normal'
                  fullWidth
                  id='firstName'
                  value={value}
                  label={t('common:firstName')}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              )}
            />
            <Controller
              name='lastName'
              control={control}
              rules={{
                required: true,
                pattern: /^[a-zA-Z0-9 ]*$/,
                validate: {
                  required: (v) => !!v.trim(),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={!!errors.lastName}
                  helperText={getNameErrorMessage(errors.lastName)}
                  margin='normal'
                  fullWidth
                  value={value}
                  id='lastName'
                  label={t('common:lastName')}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              )}
            />
            <FormControl
              error={!!errors.mobile}
              fullWidth
              sx={{
                mt: 2,

                '&:focus-within': {
                  '.special-label': {
                    color: '#ED9A00',
                  },

                  '& .error': {
                    input: {
                      boxShadow: '0 0 0 1px #d32f2f !important',
                    },
                  },
                },

                '.form-control:focus': {
                  boxShadow: '0 0 0 1px #ED9A00 !important',
                  borderColor: '#ED9A00 !important',
                },

                '& .error': {
                  input: {
                    // boxShadow: '0 0 0 1px #d32f2f !important',
                    boxShadow: 'none !important',
                    border: '1px solid #d32f2f !important',
                  },
                  '.special-label': {
                    color: '#d32f2f',
                  },
                },

                '.search-box': {
                  height: '40px',
                  width: '250px',
                },

                '.country-list': {
                  position: 'fixed !important',
                },
              }}
            >
              <Controller
                name='mobile'
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    inputStyle={{
                      width: '100%',
                      height: '56px',
                      fontSize: '15px',
                      paddingLeft: '60px',
                      borderRadius: '4px',
                    }}
                    id='mobile'
                    country={'us'}
                    value={value}
                    containerClass={errors.mobile ? 'error' : ''}
                    enableSearch
                    onChange={onChange}
                    searchNotFound={'No data found.'}
                    specialLabel={t('customer:searchPatientDialog.phoneNumber')}
                    placeholder={''}
                  />
                )}
              />
              <FormHelperText error={!!errors.mobile}>
                {errors.mobile && getPhoneErrorMessage(errors.mobile)}
              </FormHelperText>
            </FormControl>
            <Controller
              name='email'
              rules={{
                required: true,
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={!!errors.email}
                  helperText={getEmailErrorMessage(errors.email)}
                  margin='normal'
                  fullWidth
                  id='email'
                  value={value}
                  label={t('common:emailAddress')}
                  inputProps={{
                    maxLength: 255,
                  }}
                  onChange={onChange}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            disabled={!isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            {t('common:update')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditHcpUserProfileDialog.propTypes = {
  error: PropTypes.object,
  openEditHcpUserProfileDialog: PropTypes.bool,
  handleCloseEditHcpUserProfileDialog: PropTypes.func,
  onSubmitEditHcpUserProfileDialog: PropTypes.func,
  currentUser: PropTypes.object,
};
