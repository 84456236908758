import IsometricRechart from 'components/Charts/IsometricReChart';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NormTable from '../../DataComparison/STDTest/NormTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActivities,
  fetchActivities,
  fetchMachineSetup,
  fetchNormData,
  fetchPatient,
  putActivityNote,
  resetActivityProperties,
} from '../../../slice';
import { useParams, useSearchParams } from 'react-router-dom';
import SummaryControl from '../../SummaryControl';
import Container from '@mui/material/Container';
import { SessionInfo } from '../../../components/Info';
import Divider from '@mui/material/Divider';
import { TextareaAutosize } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';
import { generateYAxisArray } from 'components/Charts/utils';
import { getActivityQuestionLabel, preprocessingNormData } from 'helpers';
import PushEMRModal from 'modules/EMR/components/PushEMRModal.jsx';
import { dateParseMonthDayYear } from 'helpers/datetime';

const SummaryContainer = styled('div')`
  & .notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & .notes:hover {
    border-color: black;
  }
  & .notes:focus {
    outline-color: #ed9a00;
  }
`;

const CervicalRotationIsometricSummary = ({ activitiesId }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { t } = useTranslation();
  const printRef = useRef(null);
  const { permissions: currentPermissions, user: { hcp: { enabled_feature: enabledFeature } } } = useCurrentUser();

  const value = useSelector((state) => state.patients.activities);
  const setupData = useSelector((state) => state.patients.setup);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);
  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const patientInfo = useSelector((state) => state.patients.patient);
  const normData = useSelector((state) => state.patients.normData);
  const { emr } = useSelector((state) => state.emrSettings);

  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const [xLeftAxis, setXLeftAxis] = useState([]);
  const [showLeftLine, setShowLeftLine] = useState(false);
  const [showLeftNormLine, setShowLeftNormLine] = useState(false);
  const [showLeftStd, setShowLeftStd] = useState(false);

  const [xRightAxis, setXRightAxis] = useState([]);
  const [showRightLine, setShowRightLine] = useState(false);
  const [showRightNormLine, setShowRightNormLine] = useState(false);
  const [showRightStd, setShowRightStd] = useState(false);
  const [setupMachineData, setSetupMachineData] = useState(null);
  const [openPushEMR, setOpenPushEMR] = useState(false);
  const [disablePushing, setDisablePushing] = useState(false);

  const [isEditNotes, setIsEditNotes] = useState(false);
  const [notes, setNote] = useState('');

  const machineId = searchParams.get('machineId');
  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  // const activitiesId = searchParams.get('activitiesId');
  const viewOnly = searchParams.get('viewOnly');
  const mode = searchParams.get('mode');
  const machineType = searchParams.get('machineType');
  const [yAxisLeft, setYAxisLeft] = useState(ISOMETRIC_YAXIS.CERVICAL_ROTATION);
  const [yAxisRight, setYAxisRight] = useState(ISOMETRIC_YAXIS.CERVICAL_ROTATION);

  useEffect(() => {
    if (!isUpdatingActivity) {
      setIsEditNotes(false);
      dispatch(resetActivityProperties());
      if (patientInfo) {
        const payload = {
          normType: 'absolute',
          testType: 'isometric',

          setup_id: setupId,
        };
        dispatch(fetchNormData(payload));
      }
    }
  }, [isUpdatingActivity]);

  useEffect(() => {
    if (!id || !machineId || !setupId) {
      return null;
    }

    const hcpId = localStorage.getItem('hcpId');

    dispatch(
      fetchPatient({
        patientId: atob(id),
        hcpId: hcpId,
      })
    );

    dispatch(
      fetchActivities({
        hcpId,
        patientId: atob(id),
        sessionId,
        activitiesId,
      })
    );
    dispatch(
      fetchMachineSetup({
        hcpId: hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
      })
    );

    return () => {
      dispatch(clearActivities());
    };
  }, [activitiesId]);
  useEffect(() => {
    if (!setupData) {
      return;
    }
    setSetupMachineData(setupData.data);
  }, [setupData]);

  useEffect(() => {
    if (!patientInfo || !machineId) {
      return null;
    }

    const payload = {
      normType: 'absolute',
      testType: 'isometric',

      setup_id: setupId,
    };
    dispatch(fetchNormData(payload));
  }, [patientInfo]);

  useEffect(() => {
    if (!normData) {
      return null;
    }
    if (!leftData) {
      return null;
    }
    if (!rightData) {
      return null;
    }

    setLeftData(preprocessingNormData(leftData, normData, 1));
    setRightData(preprocessingNormData(rightData, normData));
  }, [normData]);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (!value.emr_status || Object.keys(value.emr_status).length === 0) {
      setDisablePushing(false);
    } else {
      if (value.emr_status.caspio.status === 'Done' || value.emr_status.caspio.status === 'Processing') {
        setDisablePushing(true);
      } else {
        setDisablePushing(false);
      }
    }

    const left = [];
    const right = [];

    if ((value.data_left ?? []).length > 0) {
      const xAxisLeftValue = [];
      let maxLeft = 0;
      value.data_left.map((e) => {
        xAxisLeftValue.push(e.angle_degree);

        left.push({
          name: e.angle_degree,
          stored: Math.abs(e.stored_energy),
          peak: Math.abs(e.peak_torque),
        });
        if (Math.abs(e.peak_torque) > maxLeft) {
          maxLeft = Math.abs(e.peak_torque);
        }
      });

      xAxisLeftValue.sort((a, b) => a - b);
      left.sort((e, b) => e.name - b.name);
      setXLeftAxis([...xAxisLeftValue]);
      if (maxLeft) {
        setYAxisLeft(generateYAxisArray(maxLeft, ISOMETRIC_YAXIS.ROTARY_TORSO.length));
      }
    }

    if ((value.data_right ?? []).length > 0) {
      const xAxisRightValue = [];
      let maxRight = 0;
      value.data_right.map((e) => {
        xAxisRightValue.push(e.angle_degree);

        right.push({
          name: e.angle_degree,
          stored: Math.abs(e.stored_energy),
          peak: Math.abs(e.peak_torque),
        });
        if (Math.abs(e.peak_torque) > maxRight) {
          maxRight = Math.abs(e.peak_torque);
        }
      });

      xAxisRightValue.sort((a, b) => b - a);
      right.sort((e, b) => b.name - e.name);
      setXRightAxis([...xAxisRightValue]);
      if (maxRight) {
        setYAxisRight(generateYAxisArray(maxRight, ISOMETRIC_YAXIS.ROTARY_TORSO.length));
      }
    }

    setRightData([...right]);
    setLeftData([...left]);
    setNote(value.notes || '');
  }, [value]);

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('EXECUTE_ACTIVITIES');
  };
  const hasViewActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_ACTIVITIES');
  };

  const onPushDataDoneHandler = () => {
    setOpenPushEMR(false);
    setDisablePushing(true);
  };

  const onNoteCancelHandler = () => {
    setNote(value.notes || '');
    setIsEditNotes(false);
  };

  const onSaveHandler = () => {
    if (!isUpdating) {
      const hcpId = localStorage.getItem('hcpId');
      dispatch(
        putActivityNote({
          hcpId,
          patientId: atob(id),
          sessionId,
          activitiesId,
          data: {
            notes: notes,
          },
        })
      );
    }
  };
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF('p', 'mm');
    doc.addImage(canvas, 'PNG', 20, 20, imgWidth, imgHeight, '', 'FAST');
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, 'PNG', 20, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save('Isometric Test Index.pdf');
  };

  const setupDataRender = () => {
    if (!setupMachineData) {
      return (
        <Box>
          Max Left ROM: N/A
          <br />
          Max Right ROM: N/A
          <br />
          Back Pad: N/A
          <br />
          Temporal Pad Position: N/A
          <br />
          Seat Position: N/A
        </Box>
      );
    }

    return (
      <Box>
        Max Left ROM: {setupMachineData.range_motion_max !== null ? `${setupMachineData.range_motion_max}°` : 'N/A'}
        <br />
        Max Right ROM: {setupMachineData.range_motion_min !== null ? `${setupMachineData.range_motion_min}°` : 'N/A'}
        <br />
        Back Pad: {setupMachineData.seat_pad !== null ? setupMachineData.seat_pad : 'N/A'}
        <br />
        Temporal Pad Position:{' '}
        {setupMachineData.temporal_adjustment_pos !== null ? setupMachineData.temporal_adjustment_pos : 'N/A'}
        <br />
        Seat Position: {setupMachineData.seat_pos !== null ? setupMachineData.seat_pos : 'N/A'}
      </Box>
    );
  };
  const leftDataRender = () => {
    if (!leftData || (leftData && leftData.length === 0)) {
      return null;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
            <Box sx={{ alignSelf: 'center', fontWeight: 700 }}>Left Side</Box>
            <IsometricRechart
              yAxisRange={yAxisLeft}
              showLine={showLeftLine}
              showNormLine={showLeftNormLine}
              showActive={false}
              xAxisRange={xLeftAxis}
              showStd={showLeftStd}
              data={leftData || []}
              machineType={machineType}
            />

            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', padding: '20px 0' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={showLeftLine}
                    onChange={() => setShowLeftLine(!showLeftLine)}
                  />
                }
                label='Overlay Line Graph'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={showLeftNormLine}
                    onChange={() => setShowLeftNormLine(!showLeftNormLine)}
                  />
                }
                label='Normative Data'
              />
              <FormControlLabel
                control={
                  <Checkbox color={'secondary'} checked={showLeftStd} onChange={() => setShowLeftStd(!showLeftStd)} />
                }
                label='1 Std Deviation Above'
              />
            </Box>

            <Box sx={{ width: '100%' }}>
              <NormTable data={leftData || []}></NormTable>
            </Box>
          </div>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', gap: 3 }}>{setupDataRender()}</Box>
        </Box>
      </Box>
    );
  };
  const rightDataRender = () => {
    if (!rightData || (rightData && rightData.length === 0)) {
      return null;
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
            <Box sx={{ alignSelf: 'center', fontWeight: 700 }}>Right Side</Box>
            <IsometricRechart
              yAxisRange={yAxisRight}
              showLine={showRightLine}
              showNormLine={showRightNormLine}
              showActive={false}
              xAxisRange={xRightAxis}
              showStd={showRightStd}
              data={rightData || []}
              reversed={true}
              machineType={machineType}
            />

            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', padding: '20px 0' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={showRightLine}
                    onChange={() => setShowRightLine(!showRightLine)}
                  />
                }
                label='Overlay Line Graph'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={showRightNormLine}
                    onChange={() => setShowRightNormLine(!showRightNormLine)}
                  />
                }
                label='Normative Data'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={showRightStd}
                    onChange={() => setShowRightStd(!showRightStd)}
                  />
                }
                label='1 Std Deviation Above'
              />
            </Box>

            <Box sx={{ width: '100%' }}>
              <NormTable data={rightData || []}></NormTable>
            </Box>
          </div>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', gap: 3 }}>{setupDataRender()}</Box>
        </Box>
      </Box>
    );
  };

  const footerRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    return (
      <SummaryControl
        isBack={viewOnly === 'true'}
        machine={{ machine }}
        patientId={id}
        setupId={setupId}
        sessionId={sessionId}
        disableControl={isEditNotes}
        type={'ACTIVITY'}
        mode={Number(mode)}
        setupValue={setupMachineData}
      />
    );
  };
  const sessionInfoRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    const formattedDate = dateParseMonthDayYear(value?.initiated_at);

    return (
      <SessionInfo
        isDownloading={isDownloadingPdf}
        machine={{ machine }}
        patient={patientInfo}
        title={'Isometric Test Summary'}
        currentDay={formattedDate}
      />
    );
  };
  if (!setupData || !hasViewActivitiesPermission()) {
    return <></>;
  }

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <Box ref={printRef}>
            {sessionInfoRender()}

            <Box
              sx={{
                display: 'flex',
                pt: 5,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ alignSelf: 'end', display: 'flex', gap: 1 }}>
                  {!!Object.entries(enabledFeature.emr).filter(([key, value]) => value).length &&
                    <Button
                      onClick={() => setOpenPushEMR(true)}
                      disabled={isEditNotes || disablePushing || !emr || (emr && emr.length === 0)}
                      sx={{ alignSelf: 'baseline' }}
                      variant='outlined'
                      color='secondary'
                    >
                      Push Data
                    </Button>}
                  <Button
                    data-html2canvas-ignore
                    onClick={() => {
                      setIsDownloadingPdf(true);
                      setTimeout(() => {
                        handleDownloadPdf();
                        setIsDownloadingPdf(false);
                      }, 100);
                    }}
                    disabled={isEditNotes}
                    sx={{ alignSelf: 'baseline', color: '#FFF' }}
                    variant='contained'
                  >
                    Export Report
                  </Button>
                </Box>

                <Box sx={{ width: '100%' }}>
                  {leftDataRender()}
                  {leftData.length > 0 && rightData.length > 0 && (
                    <Box sx={{ width: '100%', padding: '20px 0' }}>
                      <Divider />
                    </Box>
                  )}
                  {rightDataRender()}
                  <Divider sx={{
                    borderBottomWidth: '2px',
                    borderColor: '#f5f5f5',
                    paddingTop: '20px'
                  }} />
                  <Box
                    sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1, padding: '20px 0 20px 0' }}
                  >
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        The subjective pain level
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_1 === null ? 'N/A' : getActivityQuestionLabel('answer_1', value.answer_1))}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        The patient perceived exertion rate
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_2 === null ? 'N/A' : getActivityQuestionLabel('answer_2', value.answer_2))}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        How many more reps could you do before failure?
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_3 === null ? 'N/A' : getActivityQuestionLabel('answer_3', value.answer_3))}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{
                    borderBottomWidth: '2px',
                    borderColor: '#f5f5f5'
                  }} />
                  <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Notes
                      </Typography>

                      {hasExecuteActivitiesPermission() && (
                        <IconButton data-html2canvas-ignore onClick={() => setIsEditNotes(true)} fontSize={'small'}>
                          <EditIcon fontSize={'small'} sx={{ color: 'black' }} />
                        </IconButton>
                      )}
                    </Box>
                    {isEditNotes ? (
                      <Box>
                        <SummaryContainer>
                          <TextareaAutosize
                            onChange={(v) => {
                              setNote(v.target.value);
                            }}
                            className={'notes'}
                            value={notes}
                            minRows={5}
                            maxLength={3000}
                            style={{
                              width: '100%',
                              borderColor: '#DDDFE1',
                              borderRadius: 5,
                              fontFamily: 'Roboto, Montserrat, sans-serif',
                              fontSize: 16,
                            }}
                          />
                        </SummaryContainer>

                        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={onNoteCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
                              {t('common:cancel')}
                            </Button>

                            <LoadingButton
                              loading={isUpdatingActivity}
                              onClick={onSaveHandler}
                              size={'small'}
                              variant='contained'
                            >
                              {t('common:save')}
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Typography sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }} variant='p' component='div'>
                        {notes || '_'}
                      </Typography>
                    )}
                  </Box>

                  <PushEMRModal
                    onClose={() => setOpenPushEMR(false)}
                    open={openPushEMR}
                    activityId={activitiesId}
                    onDone={onPushDataDoneHandler}
                  />
                  <Box data-html2canvas-ignore>{footerRender()}</Box>
                  <Box
                    data-html2canvas-ignore
                    sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}
                  >
                    <div></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
CervicalRotationIsometricSummary.propTypes = {
  activitiesId: PropTypes.string,
}

export default CervicalRotationIsometricSummary;
