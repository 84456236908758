import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import step2 from 'assets/images/png/preparation/step2.png';
import step3 from 'assets/images/png/preparation/step3.png';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '5. Walk over to the other side of the machine and unlock counterweight.',
    img: step2,
  },
  {
    id: 2,
    text: '6. Loosen chain tightener.',
    img: step3,
  },
];
const Instructions = ({ step, subStep, handleCancel, handleNext }) => {
  return (
    <>
      <Typography variant='body1' component='div'>
        1. If patient is already set up in the Lumbar machine, then skip to <b style={{ color: '#ed9a00' }}> step 3 </b>
        - “Machine Setup.”
        <br />
        2. Before starting, make sure you have all medical information about the patient updated.
        <br />
        3. Ask patient if they need to use the restroom as the bladder area will be compressed.
        <br />
        4. Have patient remove belt or restrictive garments and empty object from pockets.
        <br />
      </Typography>
      <Box sx={{ display: 'flex', gap: 3, paddingTop: 5, paddingBottom: 5 }}>{getInstructions(instructions)}</Box>

      <Actions step={step} subStep={subStep} handleCancel={handleCancel} handleNext={handleNext} />
    </>
  );
};

Instructions.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
};

export default Instructions;
