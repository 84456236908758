import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IsometricRechart from 'components/Charts/IsometricReChart';
import { generateYAxisArray } from 'components/Charts/utils';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  clearActivities,
  clearAllIsoActivities,
  fetchActivities,
  fetchMachineSetup,
  fetchNormData,
  fetchPatient,
  putActivityNote,
  requestActiveNet,
  requestActiveTested,
  requestFetchAllIsoActivities,
  requestFetchFirstIsoActivity,
  requestFetchPrevIsoActivity,
  requestUpdateCurrentData,
  requestUpdateFirstIsoActivity,
  requestUpdatePrevIsoActivity,
  resetActivityProperties
} from 'modules/Patients/slice';
import Container from '@mui/material/Container';
import SummaryControl from '../../SummaryControl';
import { SessionInfo } from '../../../components/Info';
import useCurrentUser from 'hooks/useCurrentUser';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';
import { preprocessingNormData } from 'helpers';
import PushEMRModal from 'modules/EMR/components/PushEMRModal.jsx';
import { dateParseMonthDayYear, dateParserWithTimezone } from 'helpers/datetime';
import isNil from 'lodash/isNil';
import MachineSetupAndPatientHealthInfo from 'modules/Patients/components/Info/MachineSetupAndPatientHeathInfo';
import CurrentActivityCompare from '../../DataComparison/CurrentActivityCompare';
import FirstActivityCompare from '../../DataComparison/FirstActivityCompare';
import PreviousActivityCompare from '../../DataComparison/PreviousActivityCompare';
import StandardTestActivity from '../../DataComparison/STDTest';
import IconTest01 from 'assets/images/png/icon_test01.png';
import IconTest02 from 'assets/images/png/icon_test02.png';
import IconTestedTorque from 'assets/images/png/icon_tested_torque.png';
import { format } from 'date-fns';

const SummaryContainer = styled('div')`
  & .notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & .notes:hover {
    border-color: black;
  }
  & .notes:focus {
    outline-color: #ed9a00;
  }
`;

const IsometricTestSummary = ({ activitiesId }) => {
  const ISOMETRIC_TEST = 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { permissions: currentPermissions, user: { hcp: { enabled_feature: enabledFeature } } } = useCurrentUser();
  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const patientInfo = useSelector((state) => state.patients.patient);
  const normData = useSelector((state) => state.patients.normData);
  const setupData = useSelector((state) => state.patients.setup);

  const value = useSelector((state) => state.patients.activities);
  const { emr } = useSelector((state) => state.emrSettings);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);
  const currentDataActivity = useSelector((state) => state.patients.currentDataActivity);
  const dataFetchFirstActivity = useSelector((state) => state.patients.dataFetchFirstActivity);
  const dataFetchPrevActivity = useSelector((state) => state.patients.dataFetchPrevActivity);
  const arrAllIsoActivities = useSelector((state) => state.patients.arrAllIsoActivities);
  const dataPrevActivity = useSelector((state) => state.patients.dataPrevActivity);
  const dataFirstActivity = useSelector((state) => state.patients.dataFirstActivity);

  const [xAxis, setXAxis] = useState([]);
  const [data, setData] = useState(null);
  const [showCurrentLine, setShowCurrentLine] = useState(false);
  const [showRecentLine, setShowRecentLine] = useState(false);
  const [showFirstLine, setShowFirstLine] = useState(false);
  const [showArea, setShowArea] = useState(false);
  const [showNormAbove, setShowNormAbove] = useState(false);
  const [showNormBelow, setShowNormBelow] = useState(false);

  const [showCurrentNetLine, setShowCurrentNetLine] = useState(false);
  const [showFirstNetLine, setShowFirstNetLine] = useState(false);
  const [showRecentNetLine, setShowRecentNetLine] = useState(false);

  const [checked, setChecked] = useState([false, false, false]);

  const [isEditNotes, setIsEditNotes] = useState(false);
  const [firstSelectedActivityId, setFirstSelectedActivityId] = useState(null);
  const [prevSelectedActivityId, setPrevSelectedActivityId] = useState(null);
  const isFetchAllIsoActivities = useSelector((state) => state.patients.isFetchAllIsoActivities);
  const activeNet = useSelector((state) => state.patients.activeNet);
  const activeTested = useSelector((state) => state.patients.activeTested);

  const machineType = searchParams.get('machineType');
  const machineId = searchParams.get('machineId');
  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  // const activitiesId = searchParams.get('activitiesId');
  const viewOnly = searchParams.get('viewOnly');
  const mode = searchParams.get('mode');
  const printRef = useRef(null);
  const [notes, setNote] = useState('');
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [yAxis, setYAxis] = useState(machineType === 'Lumbar' ? ISOMETRIC_YAXIS.LUMBAR : ISOMETRIC_YAXIS.CERVICAL);

  const [openPushEMR, setOpenPushEMR] = useState(false);
  const [disablePushing, setDisablePushing] = useState(false);

  const handleChangeParent = (event) => {
    setChecked([event.target.checked, event.target.checked, event.target.checked]);
    if (!!checked[0] || !!checked[2]) {
      setShowArea(false);
    }
    if (!!checked[1]) {
      setShowNormAbove(false);
      setShowNormBelow(false);
    }
  };

  const handleChangeChild1 = (event) => {
    setChecked([event.target.checked, checked[1], checked[2]]);
    if (showArea) {
      setShowArea(false);
    }
    if (showNormAbove) {
      setShowNormAbove(false);
    }
  };

  const handleChangeChild2 = (event) => {
    setChecked([checked[0], event.target.checked, checked[2]]);
    if (showNormAbove) {
      setShowNormAbove(false);
    }
    if (showNormBelow) {
      setShowNormBelow(false);
    }
  };

  const handleChangeChild3 = (event) => {
    setChecked([checked[0], checked[1], event.target.checked]);
    if (showArea) {
      setShowArea(false);
    }
    if (showNormBelow) {
      setShowNormBelow(false);
    }
  };

  const onActiveTested = () => {
    if (activeTested) {
      return;
    }
    dispatch(requestActiveTested());
    setShowCurrentNetLine(false);
    setShowFirstNetLine(false);
    setShowRecentNetLine(false);
  };

  const onActiveNet = () => {
    if (activeNet) {
      return;
    }
    dispatch(requestActiveNet());
    setChecked([false, false, false]);
    setShowCurrentLine(false);
    setShowFirstLine(false);
    setShowRecentLine(false);
    setShowArea(false);
    setShowNormAbove(false);
    setShowNormBelow(false);
  };

  useEffect(() => {
    if (dataFetchPrevActivity) {
      dispatch(requestUpdatePrevIsoActivity({
        data: dataFetchPrevActivity?.data,
        initTime: dataFetchPrevActivity?.initTime,
        activityId: dataFetchPrevActivity?.activityId
      }))
    }
  }, [dataFetchPrevActivity]);

  useEffect(() => {
    if (dataFetchFirstActivity) {
      dispatch(requestUpdateFirstIsoActivity({
        data: dataFetchFirstActivity?.data,
        initTime: dataFetchFirstActivity?.initTime,
        activityId: dataFetchFirstActivity?.activityId
      }))
    }
  }, [dataFetchFirstActivity]);

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('EXECUTE_ACTIVITIES');
  };

  const hasViewActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_ACTIVITIES');
  };

  useEffect(() => {
    if (!id || !machineId || !setupId) {
      return null;
    }

    const hcpId = localStorage.getItem('hcpId');

    dispatch(
      fetchPatient({
        patientId: atob(id),
        hcpId: hcpId,
      })
    );

    dispatch(
      fetchActivities({
        hcpId,
        patientId: atob(id),
        sessionId,
        activitiesId,
      })
    );
    dispatch(
      fetchMachineSetup({
        hcpId: hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
      })
    );

    return () => {
      dispatch(clearActivities());
    };
  }, [activitiesId]);

  useEffect(() => {
    const hcpId = localStorage.getItem('hcpId');
    if (!activitiesId) {
      return null;
    }
    if (!isNil(currentDataActivity)) {
      dispatch(requestFetchAllIsoActivities({
        hcpId: hcpId,
        patientId: atob(id),
        activityType: ISOMETRIC_TEST,
        machineType,
        arrCurrentDegree: currentDataActivity?.degree,
        current_activity: activitiesId
      }))
    }
  }, [activitiesId, currentDataActivity]);

  useEffect(() => {
    return () => {
      dispatch(clearAllIsoActivities());
      dispatch(requestActiveTested());
    };

  }, []);


  useEffect(() => {
    if (!patientInfo || !machineId) {
      return null;
    }

    const payload = {
      normType: 'absolute',
      testType: 'isometric',

      setup_id: setupId,
    };
    dispatch(fetchNormData(payload));
  }, [patientInfo, value]);

  useEffect(() => {
    if (!normData) {
      return null;
    }
    if (!data) {
      return null;
    }

    setData(preprocessingNormData(data, normData));

    if (!isNil(dataFirstActivity) || !isNil(dataPrevActivity)) {
      setData(preprocessingNormData(data, normData, dataFirstActivity, dataPrevActivity));
    }

  }, [normData, dataFirstActivity, dataPrevActivity]);

  useEffect(() => {
    if (isNil(normData)) {
      return;
    }
    dispatch(requestUpdateCurrentData({
      peak: data?.map((ele) => Math.abs(ele.peak)),
      degree: data?.map((ele) => ele.name),
      net: data?.map((ele) => ele.net),
      activitiesId: activitiesId
    }));
  }, [activitiesId, normData]);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (!value.emr_status || Object.keys(value.emr_status).length === 0) {
      setDisablePushing(false);
    } else {
      if (value.emr_status.caspio.status === 'Done' || value.emr_status.caspio.status === 'Processing') {
        setDisablePushing(true);
      } else {
        setDisablePushing(false);
      }
    }

    const a = [];
    if ((value.data ?? []).length > 0) {
      const xAxisValue = [];
      let maxValue = 0;
      value.data.map((e) => {
        xAxisValue.push(e.angle_degree);

        a.push({
          name: e.angle_degree,
          stored: Math.abs(e.stored_energy),
          peak: Math.abs(e.peak_torque),
          net: Math.abs(e.peak_torque) - Math.abs(e.stored_energy)
        });
        if (Math.abs(e.peak_torque) > maxValue) {
          maxValue = Math.abs(e.peak_torque);
        }
      });
      xAxisValue.sort((a, b) => a - b);
      a.sort((e, b) => e.name - b.name);
      setXAxis([...xAxisValue]);
      if (maxValue) {
        setYAxis(generateYAxisArray(maxValue, 6));
      }
    }
    setNote(value.notes || '');
    setData([...a]);
  }, [value]);

  useEffect(() => {
    if (checked[0] && checked[2]) {
      setShowArea(!showArea);
    }
    return () => {
    }
  }, [checked[0], checked[2]]);

  useEffect(() => {
    if (checked[1] && checked[2]) {
      setShowNormBelow(!showNormBelow);
    }
    return () => {
    }
  }, [checked[1], checked[2]]);

  useEffect(() => {
    if (checked[1] && checked[0]) {
      setShowNormAbove(!showNormAbove);
    }
    return () => {
    }
  }, [checked[1], checked[0]]);

  useEffect(() => {
    if (!isUpdatingActivity) {
      setIsEditNotes(false);
      dispatch(resetActivityProperties());
      if (patientInfo) {
        const payload = {
          normType: 'absolute',
          testType: 'isometric',

          setup_id: setupId,
        };
        dispatch(fetchNormData(payload));
      }
    }
  }, [isUpdatingActivity]);

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF('p', 'mm');
    doc.addImage(canvas, 'PNG', 20, 20, imgWidth, imgHeight, '', 'FAST');
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, 'PNG', 20, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save('Isometric Test Index.pdf');
  };

  const onPushDataDoneHandler = () => {
    setOpenPushEMR(false);
    setDisablePushing(true);
  };
  const onNoteCancelHandler = () => {
    setNote(value.notes || '');
    setIsEditNotes(false);
  };

  const onSaveHandler = () => {
    if (!isUpdating) {
      const hcpId = localStorage.getItem('hcpId');
      dispatch(
        putActivityNote({
          hcpId,
          patientId: atob(id),
          sessionId,
          activitiesId,
          data: {
            notes: notes,
          },
        })
      );
    }
  };

  const onFetchPrevActivity = (prevSelected) => {
    setPrevSelectedActivityId(prevSelected.activityId);
    const hcpId = localStorage.getItem('hcpId');
    if (!isNil(prevSelected)) {
      dispatch(
        requestFetchPrevIsoActivity({
          hcpId,
          patientId: atob(id),
          sessionId: prevSelected.sessionId,
          activitiesId: prevSelected.activityId,
          arrCurrentDegree: currentDataActivity?.degree
        })
      );
    }
  };

  const onFetchFirstActivity = (firstSelected) => {
    setFirstSelectedActivityId(firstSelected.activityId);
    const hcpId = localStorage.getItem('hcpId');
    if (!isNil(firstSelected)) {
      dispatch(
        requestFetchFirstIsoActivity({
          hcpId,
          patientId: atob(id),
          sessionId: firstSelected.sessionId,
          activitiesId: firstSelected.activityId,
          arrCurrentDegree: currentDataActivity?.degree
        })
      );
    }
  }

  const sessionInfoRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    const formattedDate = dateParseMonthDayYear(value?.initiated_at);

    return (
      <SessionInfo
        isDownloading={isDownloadingPdf}
        machine={{ machine }}
        patient={patientInfo}
        title={'Isometric Test Summary'}
        currentDay={formattedDate}
      />
    );
  };

  const footerRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    return (
      <SummaryControl
        isBack={viewOnly === 'true'}
        machine={{ machine }}
        patientId={id}
        setupId={setupId}
        sessionId={sessionId}
        disableControl={isEditNotes}
        type={'ACTIVITY'}
        mode={Number(mode)}
        setupValue={setupData}
      />
    );
  };

  if (!setupData || !hasViewActivitiesPermission()) {
    return <></>;
  }

  const createCheckboxRow = (isChecked, onChangeHandler) => {
    return (
      <Box sx={{ height: '23px', mt: '-3px' }}>
        <FormControlLabel
          sx={{ padding: 0 }}
          control={
            <Checkbox sx={{ padding: 0 }} color={'secondary'} checked={isChecked} onChange={onChangeHandler} />
          }
        />
      </Box>
    );
  };

  const parentCheckbox = () => {
    return (
      <Box>
        <table>
          <thead>
            <th>
              <FormControlLabel
                sx={{ padding: 0 }}
                control={
                  <Checkbox
                    sx={{ padding: 0 }}

                    color={'secondary'}
                    checked={checked[0] && checked[1] && checked[2]}
                    // indeterminate={checked[0] !== checked[1] || checked[0] !== checked[2] || checked[1] !== checked[2]}
                    indeterminate={false}
                    onChange={handleChangeParent}
                  />
                }
              />
            </th>
            <th>Normative Data</th>
          </thead>
        </table>
      </Box>
    )
  }

  const checkListLineChart = () => {
    return <Box>
      <table style={{ paddingLeft: activeNet ? '24px' : '0px' }}>
        <thead>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </thead>
        {
          activeTested
          &&
          <tbody>
            <tr>
              {createCheckboxRow(checked[0], handleChangeChild1)}
            </tr>
            <tr>
              {createCheckboxRow(checked[1], handleChangeChild2)}
            </tr>
            <tr>
              {createCheckboxRow(checked[2], handleChangeChild3)}
            </tr>
          </tbody>
        }
      </table>
    </Box>
  };

  const checkCompareRecentChart = () => {
    return <Box>
      <table>
        <thead>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </thead>
        {
          activeTested ?
            <tbody>
              <tr>
                {createCheckboxRow(showRecentLine, () => setShowRecentLine(!showRecentLine))}
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
            :
            <tbody>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                {createCheckboxRow(showRecentNetLine, () => setShowRecentNetLine(!showRecentNetLine))}
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
        }
      </table>
    </Box>
  };

  const checkCompareFirstChart = () => {
    return <Box>
      <table>
        <thead>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </thead>
        {
          activeTested ?
            <tbody>
              <tr>
                {createCheckboxRow(showFirstLine, () => setShowFirstLine(!showFirstLine))}
              </tr>
            </tbody>
            :
            <tbody>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                {createCheckboxRow(showFirstNetLine, () => setShowFirstNetLine(!showFirstNetLine))}
              </tr>
            </tbody>
        }
      </table>
    </Box>
  };

  const checkShowCurrent = () => {
    return <Box>
      <table>
        <thead>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </thead>
        {
          activeTested ?
            <tbody>
              {createCheckboxRow(showCurrentLine, () => setShowCurrentLine(!showCurrentLine))}
            </tbody>
            :
            <tbody>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              {createCheckboxRow(showCurrentNetLine, () => setShowCurrentNetLine(!showCurrentNetLine))}
            </tbody>
        }
      </table>
    </Box>
  }

  const formattedDate = (originalDate) => {
    if (!isNil(originalDate)) {
      const localDate = dateParserWithTimezone(originalDate);
      return format(localDate, "MMM d yyyy, hh:mm a");
    }
    return;
  };

  return (
    <>
      {
        (!isFetchAllIsoActivities && currentDataActivity && normData) &&
        <Container
          sx={{
            backgroundColor: 'grey1',
            p: 3,
          }}
          maxWidth={false}
        >
          <Box
            sx={{
              height: 'calc(100vh - 118px)',
              overflow: 'auto',
              p: 3,
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
              maxWidth='lg'
            >
              <Box ref={printRef}>
                {sessionInfoRender()}
                <Box
                  sx={{
                    // display: 'flex',
                    pt: 5,
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                      justifyContent: 'space-between',
                    }}
                  >
                    {
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}>
                        {xAxis && (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '70%' }}>
                            <IsometricRechart
                              nearestActivity={arrAllIsoActivities?.default_activities?.nearest}
                              activeNet={activeNet}
                              yAxisRange={yAxis}
                              machineType={setupData.machine_type}
                              showNormLine={checked[1]}
                              showCurrentLine={showCurrentLine}
                              showAboveLine={checked[0]}
                              showBelowLine={checked[2]}
                              showFirstLine={showFirstLine}
                              showRecentLine={showRecentLine}
                              showArea={showArea}
                              showNormAbove={showNormAbove}
                              showNormBelow={showNormBelow}
                              showCurrentNetLine={showCurrentNetLine}
                              showFirstNetLine={showFirstNetLine}
                              showRecentNetLine={showRecentNetLine}
                              showActive={false}
                              xAxisRange={xAxis}
                              data={data || []}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '90%', gap: 4, alignItems: 'center' }}>
                              {
                                showCurrentLine &&
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <Box
                                    component='img'
                                    sx={{
                                      maxWidth: '50px',
                                      mb: 1
                                    }}
                                    alt='Icon Test Functional Torque'
                                    src={IconTestedTorque}
                                  />
                                  <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>Tested Functional Torque</Typography>
                                </Box>
                              }
                              {
                                showRecentLine &&
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <Box
                                    component='img'
                                    sx={{
                                      maxWidth: '50px',
                                      mb: 1.5
                                    }}
                                    alt='Icon Test 01'
                                    src={IconTest01}
                                  />
                                  <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{formattedDate(dataPrevActivity?.initTime)}</Typography>
                                </Box>
                              }
                              {
                                showFirstLine &&
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <Box
                                    component='img'
                                    sx={{
                                      maxWidth: '50px',
                                      mb: 1.5
                                    }}
                                    alt='Icon Test 02'
                                    src={IconTest02}
                                  />
                                  <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{formattedDate(dataFirstActivity?.initTime)}</Typography>
                                </Box>
                              }
                            </Box>
                          </div>
                        )}

                        <Box sx={{ display: 'flex', gap: 1 }}>
                          {!!Object.entries(enabledFeature.emr).filter(([key, value]) => value).length &&
                            <Button
                              onClick={() => setOpenPushEMR(true)}
                              disabled={isEditNotes || disablePushing || !emr || (emr && emr.length === 0)}
                              sx={{ alignSelf: 'baseline' }}
                              variant='outlined'
                              color='secondary'
                            >
                              Push Data
                            </Button>}
                          <Button
                            data-html2canvas-ignore
                            onClick={() => {
                              setIsDownloadingPdf(true);
                              setTimeout(() => {
                                handleDownloadPdf();
                                setIsDownloadingPdf(false);
                              }, 100);
                            }}
                            disabled={isEditNotes}
                            sx={{ alignSelf: 'baseline', color: '#FFF' }}
                            variant='contained'
                          >
                            Export Report
                          </Button>
                        </Box>
                      </Box>
                    }

                    <Box sx={{ width: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box onClick={onActiveTested} sx={{ border: '1px solid #CED4DA', color: activeTested ? '#D68C00' : 'black', padding: 1, width: '171px', fontSize: '13px', fontWeight: 500, textAlign: 'center', background: activeTested ? '#FBEBCC' : '#fff', cursor: 'pointer' }}>Tested Functional Torque</Box>
                      <Box onClick={onActiveNet} sx={{ border: '1px solid #CED4DA', color: activeNet ? '#D68C00' : 'black', padding: 1, width: '171px', fontSize: '13px', fontWeight: 500, textAlign: 'center', background: activeNet ? '#FBEBCC' : '#fff', cursor: 'pointer' }}>Net Muscular Torque</Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '70%' }} >
                      <Box sx={{ width: '100%', display: 'flex', mt: 3, pl: 1 }}>
                        {activeTested && parentCheckbox()}
                      </Box>
                      <Box sx={{ width: '100%', display: 'flex', mt: 1, pl: 1 }}>
                        {checkListLineChart()}
                        <StandardTestActivity
                          currentMaxExtension={setupData?.data?.range_motion_min}
                          currentMaxFlexion={setupData?.data?.range_motion_max}
                          data={data || []}
                        />
                      </Box>
                      <Box sx={{ width: '100%', display: 'flex', mt: 3, pl: 1 }}>
                        {checkShowCurrent()}
                        <CurrentActivityCompare
                          currentPainLevel={value?.answer_1}
                          currentMaxExtension={setupData?.data?.range_motion_min}
                          currentMaxFlexion={setupData?.data?.range_motion_max}
                          data={data || []}
                        />
                      </Box>
                      {
                        (isNil(arrAllIsoActivities?.default_activities?.nearest) && isNil(arrAllIsoActivities?.default_activities?.first))
                        &&
                        <Box sx={{ width: '100%', display: 'flex', mt: 3 }}>
                          <Typography sx={{ fontSize: '18px', color: '#343A40', fontWeight: 500 }}>{'The patient needs to have at least two test results to enable this feature.'}</Typography>
                        </Box>
                      }

                      {!isNil(arrAllIsoActivities?.default_activities?.nearest) &&
                        <Box sx={{ width: '100%', display: 'flex', mt: 4, pl: 1 }}>
                          {checkCompareRecentChart()}
                          <PreviousActivityCompare
                            onFetchPrevActivity={onFetchPrevActivity}
                            prevSelectedActivityId={prevSelectedActivityId}
                            currentPainLevel={(value?.answer_1 - 1)}
                            currentMaxExtension={setupData?.data?.range_motion_min}
                            currentMaxFlexion={setupData?.data?.range_motion_max}
                          />
                        </Box>
                      }
                      {!isNil(arrAllIsoActivities?.default_activities?.first)
                        &&
                        <Box sx={{ width: '100%', display: 'flex', mt: 4, pl: 1 }}>
                          {checkCompareFirstChart()}
                          <FirstActivityCompare
                            nearestActivity={arrAllIsoActivities?.default_activities?.nearest}
                            onFetchFirstActivity={onFetchFirstActivity}
                            firstSelectedActivityId={firstSelectedActivityId}
                            currentPainLevel={(value?.answer_1 - 1)}
                            currentMaxExtension={setupData?.data?.range_motion_min}
                            currentMaxFlexion={setupData?.data?.range_motion_max}
                          />
                        </Box>
                      }
                    </Box>

                    <MachineSetupAndPatientHealthInfo
                      setupData={setupData}
                      value={value}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                          Notes
                        </Typography>

                        {hasExecuteActivitiesPermission() && (
                          <IconButton data-html2canvas-ignore onClick={() => setIsEditNotes(true)} fontSize={'small'}>
                            <EditIcon fontSize={'small'} sx={{ color: 'black' }} />
                          </IconButton>
                        )}
                      </Box>
                      {isEditNotes ? (
                        <Box>
                          <SummaryContainer>
                            <TextareaAutosize
                              onChange={(v) => {
                                setNote(v.target.value);
                              }}
                              className={'notes'}
                              value={notes}
                              minRows={5}
                              maxLength={3000}
                              style={{
                                width: '100%',
                                borderColor: '#DDDFE1',
                                borderRadius: 5,
                                fontFamily: 'Roboto, Montserrat, sans-serif',
                                fontSize: 16,
                              }}
                            />
                          </SummaryContainer>

                          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button onClick={onNoteCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
                                {t('common:cancel')}
                              </Button>

                              <LoadingButton
                                loading={isUpdatingActivity}
                                onClick={onSaveHandler}
                                size={'small'}
                                variant='contained'
                              >
                                {t('common:save')}
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Typography sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }} variant='p' component='div'>
                          {notes || '_'}
                        </Typography>
                      )}
                    </Box>

                    <PushEMRModal
                      onClose={() => setOpenPushEMR(false)}
                      open={openPushEMR}
                      activityId={activitiesId}
                      onDone={onPushDataDoneHandler}
                      activeNet={activeNet}
                    />
                    <Box data-html2canvas-ignore>{footerRender()}</Box>
                    <Box
                      data-html2canvas-ignore
                      sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}
                    >
                      <div></div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container >
      }
    </>
  );
};
IsometricTestSummary.propTypes = {
  activitiesId: PropTypes.string,
}
export default IsometricTestSummary;
