import PropTypes from 'prop-types';
import StepsContainer from 'modules/Patients/components/Machines/Base/StepsContainer';
import { useSummaryPage } from 'modules/Patients/hooks/useSummaryPage';
import MachineSetupViewContainer from 'modules/Patients/components/Machines/Base/MachineSetupView';
import Preparation from './Steps/Preparation';
import {
  AngleSelector,
  BriefPatient,
  LevelAndLockCounterWeight,
  SeatLabBeltAdjustment,
  TightenRestraints,
} from './Steps/SimpleSteps';
import LegAndFoot from './Steps/LegAndFoot';
import { SetAngleRender } from './Steps/SetAngle';
import EnterGaugeReading from './Steps/EnterGaugeReading';
import { Navigate } from 'react-router-dom';
import { useNavigateItems } from 'modules/Patients/hooks/useMachineSetup';

export const navigationItems = [
  {
    id: '1',
    name: 'Preparation',
  },
  {
    id: '2',
    name: 'Brief the Patient',
  },
  {
    id: '3',
    name: 'Physical Positioning',
    children: [
      {
        id: '3-1',
        name: 'Seating Position',
      },
      {
        id: '3-2',
        name: 'Lap Belt / Head Rest',
      },
      {
        id: '3-3',
        name: 'Leg and Foot Adjustment',
      },
      {
        id: '3-4',
        name: 'Tighten Restraints',
      },
    ],
  },
  {
    id: '4',
    name: 'Find Range of Motion (RoM)',
    children: [
      {
        id: '4-1',
        name: 'Find Max Extension Angle',
      },
      {
        id: '4-2',
        name: 'Find Max Flexion Angle',
      },
    ],
  },
  {
    id: '5',
    name: 'Set Top Dead Center (TDC)',
  },
  {
    id: '6',
    name: 'Set Counterbalance',
    children: [
      {
        id: '6-1',
        name: 'Level and Lock Counterweight',
      },
      {
        id: '6-2',
        name: 'Max Extension Angle  ',
      },
      {
        id: '6-3',
        name: 'Counterweight Adjustment',
      },
    ],
  },
  {
    id: '7',
    name: 'Enter Gauge Reading',
  },
];

export default function MachineSetupView(props) {
  const { url } = useSummaryPage();
  const navigationItemsContext = useNavigateItems();
  const { Step } = StepsContainer;

  return (
    <MachineSetupViewContainer {...props} navigationItems={navigationItemsContext.navigationItems}>
      <StepsContainer>
        {/* 1 */}
        <Step name={'Preparation'}>
          <Preparation />
        </Step>
        {/* 2 */}
        <Step name={'Brief the Patient'}>
          <BriefPatient />
        </Step>
        {/* 3 */}
        <Step name={'Physical Positioning'}>
          <Step name={'Seating Position'}>
            <AngleSelector />
          </Step>
          <Step name='Lap Belt / Head Rest'>
            <SeatLabBeltAdjustment />
          </Step>
          <Step name='Leg and Foot Adjustment'>
            <LegAndFoot />
          </Step>
          <Step name='Tighten Restraints'>
            <TightenRestraints />
          </Step>
        </Step>
        {/* 4 */}
        <Step name='Find Range of Motion (RoM)'>
          <Step name='Find Max Extension Angle'>
            <SetAngleRender page='MAX_EXTENSION' />
          </Step>
          <Step name='Find Max Flexion Angle'>
            <SetAngleRender page='MAX_FLEXION' />
          </Step>
        </Step>
        {/* 5 */}
        <Step name='Set Top Dead Center (TDC)'>
          <SetAngleRender page='TOP_DEAD_CENTER' />
        </Step>
        {/* 6 */}
        <Step name='Set Counterbalance'>
          <Step name='Level and Lock Counterweight'>
            <LevelAndLockCounterWeight />
          </Step>
          <Step name='Max Extension Angles'>
            <SetAngleRender page='COUNTER_BALANCE' />
          </Step>
          <Step name='Counterweight Adjustment'>
            <SetAngleRender page='COUNTER_WEIGHT_ADJUST' />
          </Step>
        </Step>
        {/* 7 */}
        <Step name='Enter Gauge Reading'>
          <EnterGaugeReading />
        </Step>
        {/* 8 */}
        <Step>
          <Navigate to={url} />
        </Step>
      </StepsContainer>
    </MachineSetupViewContainer>
  );
}

MachineSetupView.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSaveData: PropTypes.func,
  handleSelectItem: PropTypes.func,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleSetStep: PropTypes.func,
  handleSetSubStep: PropTypes.func,
  socketContext: PropTypes.object,
};
