import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { machineTypes } from 'modules/Machines/components/Dialogs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { useSelector } from 'react-redux';

export default function SelectMachineDialog({
  patient,
  openDialog,
  handleCloseDialog,
  backdropProps,
  machines,
  sessionId,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedMachine, setSelectedMachine] = useState(null);
  const isFetchHcpConfigureMachine = useSelector((state) => state.machines.isFetchHcpConfigureMachine);

  const defaultValues = {
    machine: '',
  };

  const {
    reset,
    handleSubmit,
    control,
    setError,
    setValue,
    // getValues,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (openDialog) {
      const machineId = localStorage.getItem('selectedMachineId');
      if (machineId) {
        setValue('machine', machineId);
        setSelectedMachine(machineId);
      } else {
        reset(defaultValues);
        setSelectedMachine(null);
      }
    }
  }, [openDialog]);

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    if (handleCloseDialog) {
      handleCloseDialog();
    }
    localStorage.removeItem('selectedMachineId');
    localStorage.removeItem('selectedMachineTypeId');
  };

  const getMachineName = (data) => {
    const name = `${data.machine.machine_type} ${data.machine.name}`;
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography title={name} variant='body1' component='div'>
          {name.length > 30 ? `${name.substr(0, 30)}...` : name}
        </Typography>
        <Typography sx={{ fontSize: '12px' }} variant='body1' component='div'>
          Serial Number: {data.machine.serial_no}
        </Typography>
      </Box>
    );
  };

  const onSubmit = (data) => {
    if (data.machine === '') {
      setError('machine', { type: 'manual', message: t('customer:patientSession.errors.noMachine') });
    } else {
      // get machine type name
      const selectedMachineTypeId = localStorage.getItem('selectedMachineTypeId');
      const machine = machineTypes.find((t) => t.id === parseInt(selectedMachineTypeId, 10));

      navigate(
        `/patient/${btoa(patient.patient_id)}/session/machine-setup?machineId=${selectedMachine}&machineType=${machine.name
        }&sessionId=${sessionId}`
      );

      // save the selected machine id into localstorage
      localStorage.setItem('selectedMachineId', data.machine);
    }
  };

  const handleMachineSelect = (value) => {
    setSelectedMachine(value);
  };

  if (!machines) return <></>;

  const machineData = machines.find((m) => m.machine.id === parseInt(selectedMachine, 10));

  const selectedMachineTypeId = localStorage.getItem('selectedMachineTypeId');
  const machineType = machineTypes.find((t) => t.id === parseInt(selectedMachineTypeId, 10));
  const isShowEmptyMachine = !machines.length && !isFetchHcpConfigureMachine;

  return (
    <div>
      <Dialog open={openDialog} onClose={handleClose} BackdropProps={backdropProps}>
        <DialogContent sx={{ pb: 0 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl error={!!errors.machine}>
              <FormLabel sx={{ color: '#00000099 !important', fontSize: '14px', mb: 2 }}>
                Select an available {machineType?.name.toLowerCase()} machine
              </FormLabel>
              {isShowEmptyMachine && (
                <Typography variant='body2' component='div'>
                  No machine available.
                </Typography>
              )}

              <Controller
                name='machine'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      handleMachineSelect(event.target.value);
                    }}
                    name='radio-buttons-group'
                  >
                    {machines.map((m) => (
                      <Box key={m.machine.id} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        {m.online ? (
                          <CircleIcon sx={{ fontSize: '14px', mr: 1, color: 'green' }} />
                        ) : (
                          <CancelIcon sx={{ fontSize: '14px', mr: 1, color: 'red' }} />
                        )}
                        <FormControlLabel
                          disabled={!m.online}
                          value={m.machine.id}
                          control={<Radio />}
                          label={getMachineName(m)}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                )}
              />
              <FormHelperText>{errors.machine ? errors.machine.message : ''}</FormHelperText>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions sx={{ py: 2 }}>
          <Button sx={{ color: 'black' }} onClick={handleClose}>
            {t('common:Close')}
          </Button>
          <Button disabled={!selectedMachine || (machineData && !machineData.online)} onClick={handleSubmit(onSubmit)}>
            {t('common:continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SelectMachineDialog.propTypes = {
  patient: PropTypes.object,
  handleCloseDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  backdropProps: PropTypes.object,
  machines: PropTypes.array,
  sessionId: PropTypes.string || PropTypes.number,
};
