import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getSyncStatus: {
    url: (hcpId, requestKey) => `${ENV_VARIABLES.API_SERVER}offline-sync/status-import/${requestKey}?hcp_id=${hcpId}`,
  },
  postUploadFile: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}offline-sync/${hcpId}/import-data`,
  },
  downloadFile: {
    url: `${ENV_VARIABLES.API_SERVER}offline/dumps?hcp_id=4`,
  },
};
