import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { SingleImageStep } from 'helpers';
import femurPad from 'assets/images/png/physical-positioning/3c1.png';
import footRest from 'assets/images/png/physical-positioning/3c2.png';
import Actions from 'modules/Patients/components/Machines/Actions';

const femurData = [
  {
    id: 1,
    value: 1,
  },
  {
    id: 2,
    value: 2,
  },
  {
    id: 3,
    value: 3,
  },
  {
    id: 4,
    value: 4,
  },
  {
    id: 5,
    value: 5,
  },
  {
    id: 6,
    value: 6,
  },
];

export default function LegAndFoot({
  handleCancel,
  step,
  handleBack,
  handleNext,
  onDataChange,
  subStep,
  setupMachineData,
}) {
  const defaultValues = {
    femur_restraint: '',
  };

  const { control, setValue } = useForm({ mode: 'all', defaultValues });

  const [stepData, setStepData] = useState(defaultValues);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }

    setValue('femur_restraint', setupMachineData.femur_restraint === null ? '' : setupMachineData.femur_restraint);

    setStepData({
      femur_restraint: setupMachineData.femur_restraint,
    });
  }, [setupMachineData]);

  const handleFemurChange = (value) => {
    // property name required by BE
    setStepData((prevState) => ({ ...prevState, femur_restraint: value }));
  };

  // hi-jack default handle next to save data
  const handleNextStep = () => {
    if (stepData.femur_restraint === setupMachineData?.femur_restraint) {
      handleNext();
      return;
    }
    onDataChange({
      femur_restraint: stepData.femur_restraint,
      range_motion_min: null,
      range_motion_max: null,
      dead_center: null,
      counterbalance_angle: null,
      counterbalance_torque: null,
      counterbalance_gauge: null,
    });
    handleNext();
  };

  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={femurPad} />
            </Box>

            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                1. Bring the femur pad up onto the patient’s lap.
                <br />
                2. Adjust Femur pads, so the leg angle is parallel to the seat, then insert pin.
                <br />
                <Box>
                  <label>
                    <strong>Manually enter Femur Pad Data.</strong>
                  </label>
                  <br />
                  <Controller
                    name='femur_restraint'
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ width: 150, mt: 1 }}>
                        <Select
                          value={value}
                          displayEmpty
                          onChange={(event) => {
                            onChange(event);
                            handleFemurChange(event.target.value);
                          }}
                        >
                          <MenuItem value=''>
                            <em>--</em>
                          </MenuItem>
                          {femurData.map((f) => (
                            <MenuItem key={f.id} value={f.id}>
                              {f.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
                3. Have patient wiggle back into the seat.
                <br />
                4. Ask them to try and touch their toes. They should not be able to lift their buttocks out of the seat.
              </Typography>
            </Box>
          </Box>
          <br />
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={footRest} />
            </Box>

            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              5. Adjust Footrest with Footrest adjuster, so patient can comfortably rest their feet on Footrest. Heels
              against the heel rest, toes turned in slightly.
            </Box>
          </Box>
          <br />
        </Box>
      </OverlayScrollbarsComponent>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNextStep}
        disableNext={stepData.femur_restraint === null || stepData.femur_restraint === ''}
      />
    </>
  );
}

LegAndFoot.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  values: PropTypes.object,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  onDataChange: PropTypes.func,
  setupMachineData: PropTypes.object,
};
