import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getEMRSetting: { url: (hcpId) => `${ENV_VARIABLES.API_SERVER}emr?hcp_id=${hcpId}` },
  pushSingleActivity: {
    url: (hcpId, activityId, emrName, isometric_type) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/activities/${activityId}/emr?emr_name=${emrName}&isometric_type=${isometric_type}`,
  },
  pushAllActivity: {
    url: (hcpId, sessionId, emrName) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/sessions/${sessionId}/emr?emr_name=${emrName}`,
  },
  postEMRSetting: { url: (hcpId) => `${ENV_VARIABLES.API_SERVER}emr?hcp_id=${hcpId}` },
};

export const SYSTEM_OPTIONS = [{ value: 'CASPIO', label: 'Caspio' }];
