import BaseModal from 'modules/DemoGraphic/Shared/Modal.jsx';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { SingleSelect } from 'modules/DemoGraphic/Shared/SingleSelect.jsx';

export const levelOptions = [
  { value: 11, label: '10 = Extremely High' },
  { value: 10, label: '9' },
  { value: 9, label: '8' },
  { value: 8, label: '7' },
  { value: 7, label: '6' },
  { value: 6, label: '5' },
  { value: 5, label: '4' },
  { value: 4, label: '3' },
  { value: 3, label: '2' },
  { value: 2, label: '1' },
  { value: 1, label: '0 = No Pain' },
];

export const exertionLevel = [
  { value: 10, label: '10 - Maximal' },
  { value: 9, label: '9 - Extremely Hard' },
  { value: 8, label: '8 - Very Hard' },
  { value: 7, label: '7 - Really Hard' },
  { value: 6, label: '6 - Hard' },
  { value: 5, label: '5 - Challenging' },
  { value: 4, label: '4 - Moderate' },
  { value: 3, label: '3 - Easy' },
  { value: 2, label: '2 - Very Easy' },
  { value: 1, label: '1 - Resting' },
];

export const repsOptions = [
  { value: 7, label: '0 max effort' },
  { value: 6, label: '1 more rep' },
  { value: 5, label: '2 more reps' },
  { value: 4, label: '3 more reps' },
  { value: 3, label: '4 to 6 more reps' },
  { value: 2, label: 'Light effort' },
  { value: 1, label: 'Little to no effort' },
];

export default function ActivityQuestion({ onFinish }) {
  const [data, setData] = useState({
    answer_1: undefined,
    answer_2: undefined,
    answer_3: undefined,
  });

  const url = new URL(window.location.href);

  const open = useSelector((state) => state.dialogs[DIALOGS.OPEN_ACTIVITY_QUESTION]);

  useEffect(() => {
    setData({
      answer_1: undefined,
      answer_2: undefined,
      answer_3: undefined,
    });
  }, [open]);

  const onChangeHandler = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onContinueHandler = () => {
    const payload = { ...data };

    if (payload.answer_1 === undefined) {
      delete payload.answer_1;
    }
    if (payload.answer_2 === undefined) {
      delete payload.answer_2;
    }
    if (payload.answer_3 === undefined) {
      delete payload.answer_3;
    }

    onFinish(payload);
  };

  const getActivityType = (url) => {
    const regex = /\/session\/([^/?]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const activityType = getActivityType(url.href);

  const contentRender = () => {

    if (activityType === 'isometric-test') {
      return (
        <Box className={'modal-content'}>
          <Box className={'row'}>
            <span className={'label'}>What is your subjective pain level?</span>
            <SingleSelect
              value={data.answer_1}
              onChange={onChangeHandler}
              width={160}
              field={'answer_1'}
              options={levelOptions}
            />
          </Box>
        </Box>
      )
    }
    return (
      <Box className={'modal-content'}>
        <Box className={'row'}>
          <span className={'label'}>What is your subjective pain level?</span>
          <SingleSelect
            value={data.answer_1}
            onChange={onChangeHandler}
            width={160}
            field={'answer_1'}
            options={levelOptions}
          />
        </Box>
        <Box className={'row'}>
          <span className={'label'}>What is your Rate of Perceived Exertion?</span>
          <SingleSelect
            value={data.answer_2}
            onChange={onChangeHandler}
            width={160}
            field={'answer_2'}
            options={exertionLevel}
          />
        </Box>
        <Box className={'row'}>
          <span className={'label'}>How many more reps could you do before failure?</span>
          <SingleSelect
            value={data.answer_3}
            onChange={onChangeHandler}
            width={160}
            field={'answer_3'}
            options={repsOptions}
          />
        </Box>
      </Box>
    );
  };

  return (
    <BaseModal
      showModal={open}
      title={'Patient Questionnaire'}
      key={'ACTIVITY_QUESTION'}
      content={contentRender}
      submitText={'Finish'}
      onSubmit={onContinueHandler}
    />
  );
}

ActivityQuestion.propTypes = {
  onFinish: PropTypes.func,
};
