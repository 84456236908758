import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import auth from 'locales/en/auth.json';
import user from 'locales/en/user.json';
import customer from 'locales/en/customer.json';
import machine from 'locales/en/machine.json';
import common from 'locales/en/common.json';

const resources = {
  en: {
    auth,
    user,
    customer,
    common,
    machine,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
