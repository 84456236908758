import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchHcpRoles,
  fetchHcpRolesSuccess,
  fetchHcpRolesFailure,
  requestCreateRole,
  requestCreateRoleSuccess,
  requestCreateRoleFailure,
  requestEditRole,
  requestEditRoleSuccess,
  requestEditRoleFailure,
  requestRemoveRole,
  requestRemoveRoleSuccess,
  requestRemoveRoleFailure,
} from './slice';

import { END_POINT } from './constants';
import { requestToast } from 'modules/Ui/slice';
import { TOAST_TYPE } from 'modules/Ui/constants';
import { requestDialog } from 'modules/Dialogs/slice';
import { DIALOGS } from 'modules/Dialogs/constants';

function* getHcpRoles() {
  try {
    const { data } = yield axios.get(END_POINT.getRoles.url(localStorage.getItem('hcpId')));
    if (data.data) {
      yield put(fetchHcpRolesSuccess(data.data));
    } else {
      yield put(fetchHcpRolesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpRolesFailure(e.message));
  }
}

function* createRole(action) {
  try {
    const { data } = yield axios.post(END_POINT.createRole.url(action.payload.hcpId), {
      name: action.payload.roleName,
      permission_groups: action.payload.permissionGroups,
    });
    if (data.code === 201) {
      yield put(requestCreateRoleSuccess(data));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `${action.payload.roleName} has been added.`,
        })
      );
      yield put(requestDialog({ type: DIALOGS.NEW_ROLE, open: false }));
      yield put(fetchHcpRoles());
    } else {
      yield put(requestCreateRoleFailure(data));
    }
  } catch (e) {
    yield put(requestCreateRoleFailure(e.message));
  }
}

function* editRole(action) {
  try {
    const { data } = yield axios.put(END_POINT.editRole.url(action.payload.hcpId, action.payload.roleId), {
      name: action.payload.roleName,
      permission_groups: action.payload.permissionGroups,
    });
    if (data.code === 200) {
      yield put(requestEditRoleSuccess(data));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `${action.payload.roleName} has been updated.`,
        })
      );
      yield put(requestDialog({ type: DIALOGS.NEW_ROLE, open: false }));
      yield put(fetchHcpRoles());
    } else {
      yield put(requestEditRoleFailure(data));
    }
  } catch (e) {
    yield put(requestEditRoleFailure(e.message));
  }
}

function* removeRole(action) {
  try {
    const { data } = yield axios.delete(END_POINT.removeRole.url(action.payload.hcpId, action.payload.roleId));
    if (data.code === 200) {
      yield put(requestRemoveRoleSuccess(data));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `${action.payload.roleName} has been removed.`,
        })
      );
      yield put(fetchHcpRoles());
    } else {
      yield put(requestRemoveRoleFailure(data));
    }
  } catch (e) {
    yield put(requestRemoveRoleFailure(e.message));
  }
}

function* usersSaga() {
  yield takeLatest(fetchHcpRoles, getHcpRoles);
  yield takeLatest(requestCreateRole, createRole);
  yield takeLatest(requestEditRole, editRole);
  yield takeLatest(requestRemoveRole, removeRole);
}

export default usersSaga;
