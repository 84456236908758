import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import noImage from 'assets/images/png/noImage.png';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '',
    img: noImage,
  },
  {
    id: 2,
    text: '',
    img: noImage,
  },
  {
    id: 3,
    text: '',
    img: noImage,
  },
];
const PatientsAdjustment = ({ step, subStep, handleCancel, handleBack, handleNext }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[0]])}</Box>
            <Typography variant='body1' component='div'>
              1. Snug the footboard adjustment while patient squeezes (with knees) pad between their knees.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[1]])}</Box>
            <Typography variant='body1' component='div'>
              2. Snug the hip restraint.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[2]])}</Box>
            <Typography variant='body1' component='div'>
              3. Snug the torso restraint.
            </Typography>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      {/* actions */}

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
};

PatientsAdjustment.propTypes = {
  step: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default PatientsAdjustment;
