import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { requestCloseAllDialogs } from 'modules/Dialogs/slice';
import { useDispatch } from 'react-redux';
import SearchPatient from 'modules/Patients/containers/SearchPatient';

export default function Dialogs() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      dispatch(requestCloseAllDialogs());
    }
  }, [location]);

  return <SearchPatient />;
}
