export const warmUpDefaultData = {
  weight: '',
  angle: '',
  data: [],
  pounds: 0,
  time: '',
  reps: 0,
};

export const practiceTestDefault = {
  s1Angle: {
    name: '',
    targetValue: undefined,
    stored: 0,
    peak: 0,
  },
  s2Angle: {
    name: '',
    targetValue: undefined,
    stored: 0,
    peak: 0,
  },
  s3Angle: {
    name: '',
    targetValue: undefined,
    stored: 0,
    peak: 0,
  },
};
export const actualTestLeft = {
  s1Angle: {
    name: '72',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '3',
  },
  s2Angle: {
    name: '48',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '4',
  },
  s3Angle: {
    name: '24',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '5',
  },
  s4Angle: {
    name: '0',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '6',
  },
  s5Angle: {
    name: '-24',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '7',
  },
  s6Angle: {
    name: '-48',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '8',
  },
  s7Angle: {
    name: '-72',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '9',
  },
};
export const actualTestRight = {
  s1Angle: {
    name: '-72',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '3',
  },
  s2Angle: {
    name: '-48',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '4',
  },
  s3Angle: {
    name: '-24',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '5',
  },
  s4Angle: {
    name: '0',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '6',
  },
  s5Angle: {
    name: '24',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '7',
  },
  s6Angle: {
    name: '48',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '8',
  },
  s7Angle: {
    name: '72',
    targetValue: undefined,
    stored: 0,
    peak: 0,
    index: '9',
  },
};
