import styled from '@emotion/styled';

export const DemoGraphicContainer = styled('div')`
  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 640px;
    background-color: white;
    border-radius: 16px;
    outline: none;
    max-height: calc(100vh - 50px);
  }

  // header
  .modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: start;
    padding: 16px;
    border-bottom: 2px solid #f5f5f5;
  }
  .modal-header .title {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .modal-header .title .top-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    line-height: 16px;
  }

  .modal-header .title .main-title {
    font-size: 22px;
    font-weight: 600;
    color: #262626;
    line-height: 28px;
  }

  .modal-header .title .date-title {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    line-height: 24px;
  }

  // content

  .modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: calc(100vh - 295px);
    overflow-y: auto;
  }
  .modal-content .hr {
    border-bottom: 2px solid #f5f5f5;
  }

  .modal-content .row {
    padding: 5px 0 5px 0;
    display: flex;
    gap: 16px;
    align-items: start;
  }
  .modal-content .row .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    width: 288px;
  }

  // footer
  .modal-body .footer {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #f5f5f5;
  }
`;
