import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import fontFace from 'assets/images/knee/Front of Leg Machine.svg';
import kneeRotation from 'assets/images/knee/knee rotation of axis.svg';
import seatGauge from 'assets/images/knee/seat position gauge.svg';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
import PropTypes from 'prop-types';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { allowDecimalNumber } from 'helpers';

export default function SeatingPosition({
  step,
  handleCancel,
  handleNext,
  subStep,
  handleBack,
  onDataChange,
  setupMachineData,
}) {
  const [seatPos, setSeatPos] = useState();

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setSeatPos(setupMachineData.seat_pos ?? undefined);
  }, [setupMachineData]);
  const onSeatPosChangeHandler = (event) => {
    setSeatPos(event.target.value);
  };
  const nextHandler = () => {
    if (seatPos !== setupMachineData.seat_pos) {
      onDataChange({
        seat_pos: seatPos,
      });
    }

    handleNext();
  };

  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Typography variant='body1' component='div'>
          1. Here is a step by step through setting up your patient in the machine.
        </Typography>

        <Box sx={{ paddingTop: 4, gap: 3, display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={fontFace} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              2. Have the patient sit upright in the machine with back of knees against the front edge of seat.
              <br />
              3. Have the patient lean forward place elbows on their thighs.
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={kneeRotation} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              4. Bring seatback forward until it touches patient’s tailbone.
              <br />
              5. Adjust the seatback to align the knee axis with the machine axis of rotation. Turn the crank clockwise
              to move seatback forward, counterclockwise to move the seatback backward.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={seatGauge} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              <b> 6. Seat Position</b>: Manually enter seat position gauge reading data.
              <div>
                <br />
                <Box>
                  <div>
                    <b>Seat Position Reading</b>
                    <br />
                    Enter the entire number on the gauge as a whole number.
                  </div>

                  <TextField onKeyPress={allowDecimalNumber} value={seatPos} onChange={onSeatPosChangeHandler} />
                </Box>
              </div>
            </Typography>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={nextHandler}
        disableNext={!seatPos}
      />
    </>
  );
}

SeatingPosition.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  knee: PropTypes.string,
  setKnee: PropTypes.func,
  onDataChange: PropTypes.func,
  setupMachineData: PropTypes.object,
};
