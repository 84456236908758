import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  getEMRSettingsFailure,
  postEMRSettingsSuccess,
  postEMRSettingsFailure,
  getEMRSettingsSuccess,
  postEMRSettings,
  getEMRSettings,
  putSingleActivityFailure,
  putSingleActivitySuccess,
  putSingleActivity,
  putAllActivityFailure,
  putAllActivitySuccess,
  putAllActivity,
} from './slice';

import { END_POINT } from './constants';
import { requestToast } from 'modules/Ui/slice';
import { TOAST_TYPE } from 'modules/Ui/constants';

function* getEMRSettingsHandler(action) {
  try {
    const { data } = yield axios.get(END_POINT.getEMRSetting.url(action.payload.hcpId));
    if (data.code === 200) {
      const _data = data.data.map((d) => ({
        system: d.emr_name,
        token: d.settings.endpoint_url,
        clientId: d.settings.client_id,
        clientSecret: d.settings.client_secret,
        autoPush: d.auto_push
      }));
      yield put(getEMRSettingsSuccess(_data));
      return;
    }

    yield put(getEMRSettingsFailure());
  } catch (error) {
    yield put(getEMRSettingsFailure());
  }
}

function* postEMRSettingsHandler(action) {
  try {
    const { data } = yield axios.post(END_POINT.postEMRSetting.url(action.payload.hcpId), action.payload.body);

    if (data.code === 200) {
      yield put(postEMRSettingsSuccess(data.data));
      if (action.payload.callback) {
        action.payload.callback();
      }
      return;
    }

    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Upload failure.`,
      })
    );
    yield put(postEMRSettingsFailure());
  } catch (error) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Upload failure.`,
      })
    );
    yield put(postEMRSettingsFailure());
  }
}

function* putSingleActivityHandler(action) {
  try {
    const { data } = yield axios.post(END_POINT.pushSingleActivity.url(action.payload.hcpId, action.payload.activityId, action.payload.emrName, action.payload.isometric_type));
    if (data.code === 200) {
      yield put(putSingleActivitySuccess());

      if (action.payload.callback) {
        action.payload.callback(true);
      }
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `Data of an activity is pushing.`,
        })
      );

      return;
    }

    yield put(putSingleActivityFailure());
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Can not push data. Please try again.`,
      })
    );
    if (action.payload.callback) {
      action.payload.callback(false);
    }
  } catch (error) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Can not push data. Please try again.`,
      })
    );
    yield put(putSingleActivityFailure());
    if (action.payload.callback) {
      action.payload.callback(false);
    }
  }
}

function* putAllActivityHandler(action) {
  try {
    const { data } = yield axios.post(
      END_POINT.pushAllActivity.url(action.payload.hcpId, action.payload.sessionId, action.payload.emrName)
    );
    if (data.code === 200) {
      yield put(putAllActivitySuccess());

      if (action.payload.callback) {
        action.payload.callback(true);
      }
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `Activities’s data of the session is pushing.`,
        })
      );

      return;
    }

    yield put(putAllActivityFailure());
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Can not push data. Please try again.`,
      })
    );
    if (action.payload.callback) {
      action.payload.callback(false);
    }
  } catch (error) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Can not push data. Please try again.`,
      })
    );
    yield put(putAllActivityFailure());
    if (action.payload.callback) {
      action.payload.callback(false);
    }
  }
}

function* emrSaga() {
  yield takeLatest(getEMRSettings, getEMRSettingsHandler);
  yield takeLatest(postEMRSettings, postEMRSettingsHandler);
  yield takeLatest(putSingleActivity, putSingleActivityHandler);
  yield takeLatest(putAllActivity, putAllActivityHandler);
}

export default emrSaga;
