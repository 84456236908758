import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ScrollDialog from 'components/ScrollDialog/index.jsx';
import PropTypes from 'prop-types';
import Actions from 'modules/Patients/components/Machines/Actions';
import { useState } from 'react';
import Box from '@mui/material/Box';
const manual = () => (
  <Box sx={{ width: '100%', height: '100vh' }}>
    <iframe
      src='https://drive.google.com/file/d/1A47JpU1LGT-ISlRT8kWEMUM00BbyK4Fz/preview'
      width='100%'
      height='100%'
      allow='autoplay'
      frameBorder='0'
    ></iframe>
  </Box>
);
export default function BriefPatient({ step, subStep, handleCancel, handleBack, handleNext }) {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Typography variant='body1' component='div'>
        1. Have a brief discussion about the process with new patients to help ease anxiety and apprehension.
        <br />
        2. Outline the test or exercise procedures to be employed. This should include the restraint mechanism and how
        and why it will be used.
        <br />
        3. The patient should be informed as to how they should and should not feel physically. Often, new subjects
        experience warming sensation, associated with muscle fatigue, in the neck region after testing or exercise.
        <br />
        4. Listen to the patient. Rely on patient feedback, especially during the stabilization procedures and dynamic
        exercise.
        <br />
        5. Be considerate of patient comfort. If, there is an unexpected, lengthy pause or interruption in testing or
        exercise, the restraint mechanism should be relaxed or loosened.
        <br />
        6. Have patient verbally state their understanding and allow them to ask questions prior to the setup phase.
      </Typography>
      {/* <div style={{ padding: 2 }}>
        <Button onClick={() => setOpenModal(true)} sx={{ ml: 'auto', textDecoration: 'underline' }} variant='text'>
          Link to Manual
        </Button>
      </div> */}
      <ScrollDialog
        fullWidth={true}
        message={manual()}
        title='MANUAL'
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        handleSubmitModal={handleSubmitModal}
      />
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
BriefPatient.propTypes = {
  step: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
};
