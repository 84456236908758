import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getLabel } from 'helpers';
import {
  MILITARY_BRANCH_OF_SERVICE_OPTION,
  MILITARY_RANK_OPTION,
  MILITARY_ROLE_OPTION,
} from 'modules/DemoGraphic/Shared/options.js';
import { format } from 'date-fns';
import { dateParserWithTimezone } from 'helpers/datetime.js';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import BorderColor from '@mui/icons-material/BorderColor';

const MilitaryInformation = ({ demoGraphicInformation, onEdit }) => {
  const onEditHandler = () => {
    onEdit(2);
  };
  const contentRender = () => {
    if (!demoGraphicInformation || (demoGraphicInformation && Object.keys(demoGraphicInformation).length === 0)) {
      return (
        <Box sx={{ width: '100%', padding: '0 15px ' }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <span className={'main-title'}> Military Information</span>
              <div style={{ cursor: 'pointer' }} onClick={onEditHandler}>
                <IconButton sx={{ padding: 0 }} color='secondary'>
                  <BorderColor />
                </IconButton>
                <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
              </div>
            </Box>
          </Box>

          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
              <span className={'title'}>Branch of Service</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <span className={'title'}>Join date</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
          </Box>
          <Divider sx={{
            borderBottomWidth: '2px',
            borderColor: '#f5f5f5',
          }}/>

          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Current Role</span>
                  <span className={'value'}>{'N/A'}</span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Current Rank</span>
                  <span className={'value'}>{'N/A'}</span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Years in that Role</span>
                  <span className={'value'}>{'N/A'}</span>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{
            borderBottomWidth: '2px',
            borderColor: '#f5f5f5'
          }}/>

          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <span className={'title'}>Number of Military Free Falls</span>
                <span className={'value'}>{'N/A'}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ width: '100%', padding: '0 15px ' }}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <span className={'main-title'}> Military Information</span>
            <div style={{ cursor: 'pointer' }} onClick={onEditHandler}>
              <IconButton sx={{ padding: 0 }} color='secondary'>
                <BorderColor />
              </IconButton>
              <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
            </div>
          </Box>
        </Box>

        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
            <span className={'title'}>Branch of Service</span>
            <span className={'value'}>
              {getLabel(demoGraphicInformation.branchOfService, MILITARY_BRANCH_OF_SERVICE_OPTION)}
            </span>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <span className={'title'}>Join date</span>
            <span className={'value'}>
              {demoGraphicInformation.enteredDate
                ? format(dateParserWithTimezone(demoGraphicInformation.enteredDate), 'dd/MM/yyyy')
                : 'N/A'}
            </span>
          </Box>
        </Box>
        <Divider sx={{
          borderBottomWidth: '2px',
          borderColor: '#f5f5f5',
        }}/>

        <Box className={'row'}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                <span className={'title'}>Current Role</span>
                <span className={'value'}>
                  {getLabel(demoGraphicInformation.role, MILITARY_ROLE_OPTION[demoGraphicInformation.branchOfService])}
                </span>
              </Box>

              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                <span className={'title'}>Current Rank</span>
                <span className={'value'}>
                  {getLabel(demoGraphicInformation.rank, MILITARY_RANK_OPTION[demoGraphicInformation.branchOfService])}
                </span>
              </Box>

              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                <span className={'title'}>Years in that Role</span>
                <span className={'value'}>{demoGraphicInformation.yearInRole ?? 'N/A'}</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{
          borderBottomWidth: '2px',
          borderColor: '#f5f5f5',
        }}/>
        {demoGraphicInformation.role === 'PILOT' && (
          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Current type of Airframe</span>
                  <span className={'value'}>{demoGraphicInformation.typeOfAirFrame ?? 'N/A'}</span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Hours of flying in this Airframe</span>
                  <span className={'value'}>{demoGraphicInformation.hourFly ?? 'N/A'}</span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Total hours of flying?</span>
                  <span className={'value'}>{demoGraphicInformation.totalHourFly ?? 'N/A'}</span>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box className={'row'}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <span className={'title'}>Number of Military Free Falls</span>
              <span className={'value'}>{demoGraphicInformation.totalFee ?? 'N/A'}</span>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return contentRender();
};

MilitaryInformation.propTypes = {
  demoGraphicInformation: PropTypes.object,
  onEdit: PropTypes.func,
};

export default MilitaryInformation;
