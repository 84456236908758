import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';

export const HcpUsersSlice = createSlice({
  name: 'hcpUsersSlice',
  initialState: {
    error: null,
    hcpUsers: [],
    isFetchingHcpUser: false,
    roles: [],
    createdHcpUser: null,
  },
  reducers: {
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdHcpUser = null;
    },
    // for HCP Users
    fetchHcpUsers: (state) => {
      state.error = null;
      state.isFetchingHcpUser = true;
    },
    fetchHcpUsersSuccess: (state, action) => {
      state.hcpUsers = action.payload;
      state.isFetchingHcpUser = false;
    },
    fetchHcpUsersFailure: (state, action) => {
      state.hcpUsers = [];
      state.error = action.payload;
      state.isFetchingHcpUser = false;
    },
    fetchRoles: (state) => {
      state.error = null;
    },
    fetchRolesSuccess: (state, action) => {
      state.roles = action.payload;
    },
    fetchRolesFailure: (state, action) => {
      state.roles = [];
      state.error = action.payload;
    },
    requestInviteHcpUser: (state) => {
      state.error = null;
    },
    requestInviteHcpUserSuccess: (state, action) => {
      state.createdHcpUser = action.payload.data;
    },
    requestInviteHcpUserFailure: (state, action) => {
      state.error = action.payload;
    },
    requestEditHcpUser: (state) => {
      state.error = null;
    },
    requestEditHcpUserSuccess: (state, action) => {
      state.createdHcpUser = action.payload.data;
    },
    requestSuspendHcpUser: (state) => {
      state.error = null;
    },
    requestSuspendHcpUserSuccess: () => { },
    requestSuspendHcpUserFailure: (state, action) => {
      state.error = action.payload.code;
    },
    requestRestoreHcpUser: (state) => {
      state.error = null;
    },
    requestRestoreHcpUserSuccess: () => { },
    requestRestoreHcpUserFailure: (state, action) => {
      state.error = action.payload.code;
    },
    requestEditHcpUserFailure: (state, action) => {
      state.error = action.payload;
    },
    requestDeleteHcpUser: (state) => {
      state.errorCode = 0;
    },
    requestDeleteHcpUserSuccess: () => { },
    requestDeleteHcpUserFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.error = null;
      state.createdHcpUser = null;
    },
  },
});
export const {
  clearSuccessAndError,
  fetchHcpUsers,
  fetchHcpUsersSuccess,
  fetchHcpUsersFailure,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailure,
  requestInviteHcpUser,
  requestInviteHcpUserSuccess,
  requestInviteHcpUserFailure,
  requestEditHcpUser,
  requestEditHcpUserSuccess,
  requestEditHcpUserFailure,
  requestDeleteHcpUser,
  requestDeleteHcpUserSuccess,
  requestDeleteHcpUserFailure,
  requestSuspendHcpUser,
  requestSuspendHcpUserSuccess,
  requestSuspendHcpUserFailure,
  requestRestoreHcpUser,
  requestRestoreHcpUserSuccess,
  requestRestoreHcpUserFailure,
} = HcpUsersSlice.actions;

export default HcpUsersSlice.reducer;
