import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import step1 from 'assets/images/png/preparation/step1.png';
import Box from '@mui/material/Box';

import GaugeSlider from 'components/GaugeSlider';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

const TopDeadCenter = ({ step, setupMachineData, subStep, handleCancel, handleBack, handleNext }) => {
  if (!setupMachineData) {
    return null;
  }
  return (
    <>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box>
          <SingleImageStep img={step1} />
        </Box>

        <Typography variant='body1' component='div'>
          1. Set Top Dead Center (TDC) by engaging the angle selector to the Target Angle.
          <br />
          <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
            <span style={{ fontWeight: 500 }}> Patient Setup TDC:</span>

            <div
              style={{
                padding: 2,
                border: '1px solid green',
                fontWeight: 500,
                borderRadius: '8px',
                width: '100px',
                textAlign: 'center',
                color: 'green',
              }}
            >{`Angle ${setupMachineData.dead_center}°`}</div>
          </div>
        </Typography>
      </Box>

      <div style={{ height: '300px' }}>
        <GaugeSlider isLock={true} maxValue={72} value={setupMachineData.dead_center}></GaugeSlider>
      </div>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </>
  );
};

TopDeadCenter.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default TopDeadCenter;
