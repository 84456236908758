import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import chairTightener from 'assets/images/SVG/Cervical/Chair-tightener.svg';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
import { getPresetAngle } from 'helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
export default function PreSetAngles({
  step,
  handleCancel,
  handleBack,
  handleNext,
  subStep,
  setupMachineData,
  preSetAnglePractice,
  setPreSetAnglePractice,
  firstSide,
  testMode,
  setDisablePracticePreSetAngle,
  disablePracticePreSetAngle,
}) {
  const [currentSide, setCurrentSide] = useState(0);
  const [angles, setAngles] = useState({
    s1Angle: '',
    s2Angle: '',
    s3Angle: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const defaultValues = {
    s1Angle: 0,
    s2Angle: 0,
    s3Angle: 0,
  };

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    return () => {
      reset({
        s1Angle: 0,
        s2Angle: 0,
        s3Angle: 0,
      });
    };
  }, []);

  useEffect(() => {
    if (testMode === 3) {
      setCurrentSide(firstSide === 1 ? 2 : 1);
      return null;
    }
    setCurrentSide(firstSide);
  }, [firstSide, step, subStep]);

  useEffect(() => {
    if (!preSetAnglePractice || !setupMachineData || !currentSide) {
      return null;
    }

    if (
      preSetAnglePractice.s1Angle.name !== '' &&
      preSetAnglePractice.s2Angle.name !== '' &&
      preSetAnglePractice.s3Angle.name !== ''
    ) {
      reset({
        s1Angle: preSetAnglePractice.s1Angle.name,
        s2Angle: preSetAnglePractice.s2Angle.name,
        s3Angle: preSetAnglePractice.s3Angle.name,
      });
      setAngles({
        s1Angle: preSetAnglePractice.s1Angle.name,
        s2Angle: preSetAnglePractice.s2Angle.name,
        s3Angle: preSetAnglePractice.s3Angle.name,
      });
      return null;
    }

    const [s1, s2, s3] = getPresetAngle(
      setupMachineData.range_motion_min,
      setupMachineData.range_motion_max,
      6,
      currentSide
    );
    reset({
      s1Angle: s1,
      s2Angle: s2,
      s3Angle: s3,
    });
    setAngles({
      s1Angle: s1,
      s2Angle: s2,
      s3Angle: s3,
    });
  }, [setupMachineData, preSetAnglePractice, currentSide, step, subStep]);

  const onSaveHandler = (value) => {
    if (Object.keys(errors).length !== 0) {
      return;
    }

    const prettierValue = { ...value };
    Object.keys(prettierValue).map((key) => {
      prettierValue[key] = Math.round(Number(prettierValue[key]));
    });
    setAngles(prettierValue);
    setIsEdit(false);
  };
  const nextHandler = () => {
    const preSetAngle = preSetAnglePractice;

    preSetAngle.s1Angle.name = angles.s1Angle;
    preSetAngle.s2Angle.name = angles.s2Angle;
    preSetAngle.s3Angle.name = angles.s3Angle;

    setPreSetAnglePractice(preSetAngle);
    setDisablePracticePreSetAngle(true);
    handleNext();
  };
  const backHandler = () => {
    handleBack();
  };

  const onCancelHandler = () => {
    reset({
      s1Angle: angles.s1Angle,
      s2Angle: angles.s2Angle,
      s3Angle: angles.s3Angle,
    });

    clearErrors();
    setIsEdit(false);
  };

  if (!setupMachineData) {
    return null;
  }

  return (
    <Box key={`${step}-${subStep}`}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={chairTightener} />
          </Box>

          <Typography variant='body1' component='div'>
            1. If patient doesn’t need a Practice Test, then skip to <b style={{ color: '#ED9A00' }}>step 6</b>.
            <br />
            2. Loosen chain tightener.
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span> 3. We are now practice testing your: </span>
              <Select value={currentSide} disabled={true}>
                <MenuItem value={currentSide}>{`${currentSide === 1 ? 'Left' : 'Right'}`}</MenuItem>
              </Select>
            </Box>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={angleSelector} />
          </Box>
          <Box>
            <Box>
              4. Machine will automatically select 3 random angles, within the patient’s pain-free ROM (random angles =
              near full extension, middle of range, near full flexion).
            </Box>
            <Box>5. You can manually change these angles if needed.</Box>
            <form onSubmit={handleSubmit(onSaveHandler)}>
              <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Pre-set Angles - {`${currentSide === 1 ? 'Left' : 'Right'} Side`}
                </div>

                <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'space-between', width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <div style={{ fontSize: '20px', fontWeight: 500 }}>1st Angle</div>
                    {isEdit ? (
                      <Controller
                        name='s1Angle'
                        rules={{
                          required: true,
                          validate: {
                            required: (value) =>
                              Number(value) >= Number(setupMachineData.range_motion_min) &&
                              Number(value) <= Number(setupMachineData.range_motion_max),

                            div: (value) => Number(value) % 6 === 0,
                          },
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={!!errors.s1Angle}
                            type={'number'}
                            helperText={
                              <div>
                                {errors.s1Angle?.type === 'required' ? t('common:errors.isometricError') : ''}
                                {errors.s1Angle?.type === 'div' && '*The angle value should be divided by 6.'}
                                <br />
                              </div>
                            }
                            onChange={(v) => {
                              if (Number(v.target.value) < -60 || Number(v.target.value) > 60) {
                                return;
                              }
                              onChange(v);
                            }}
                            value={value}
                          />
                        )}
                      />
                    ) : (
                      <div style={{ fontSize: '24px', fontWeight: 400 }}>{getValues('s1Angle')}°</div>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ fontSize: '20px', fontWeight: 500 }}>2nd Angle</div>
                    {isEdit ? (
                      <Controller
                        name='s2Angle'
                        rules={{
                          required: true,
                          validate: {
                            required: (value) =>
                              Number(value) >= Number(setupMachineData.range_motion_min) &&
                              Number(value) <= Number(setupMachineData.range_motion_max),

                            div: (value) => Number(value) % 6 === 0,
                          },
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={!!errors.s2Angle}
                            type={'number'}
                            helperText={
                              <div>
                                {errors.s2Angle?.type === 'required' && t('common:errors.isometricError')}
                                {errors.s2Angle?.type === 'div' && '*The angle value should be divided by 6.'}
                                <br />
                              </div>
                            }
                            onChange={(v) => {
                              if (Number(v.target.value) < -60 || Number(v.target.value) > 60) {
                                return;
                              }
                              onChange(v);
                            }}
                            value={value}
                          />
                        )}
                      />
                    ) : (
                      <div style={{ fontSize: '24px', fontWeight: 400 }}>{getValues('s2Angle')}°</div>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ fontSize: '20px', fontWeight: 500 }}>3rd Angle</div>
                    {isEdit ? (
                      <Controller
                        name='s3Angle'
                        rules={{
                          required: true,
                          validate: {
                            required: (value) =>
                              Number(value) >= Number(setupMachineData.range_motion_min) &&
                              Number(value) <= Number(setupMachineData.range_motion_max),

                            div: (value) => Number(value) % 6 === 0,
                          },
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={!!errors.s3Angle}
                            type={'number'}
                            helperText={
                              <div>
                                {errors.s3Angle?.type === 'required' && t('common:errors.isometricError')}
                                {errors.s3Angle?.type === 'div' && '*The angle value should be divided by 6.'}
                                <br />
                              </div>
                            }
                            onChange={(v) => {
                              if (Number(v.target.value) < -60 || Number(v.target.value) > 60) {
                                return;
                              }
                              onChange(v);
                            }}
                            value={value}
                          />
                        )}
                      />
                    ) : (
                      <div style={{ fontSize: '24px', fontWeight: 400 }}>{getValues('s3Angle')}°</div>
                    )}
                  </Box>
                </Box>

                {isEdit ? (
                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Button onClick={onCancelHandler} variant='outlined'>
                      {t('common:cancel')}
                    </Button>
                    <Button type={'submit'} variant='contained'>
                      {t('common:save')}
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Button disabled={disablePracticePreSetAngle} onClick={() => setIsEdit(true)} variant='outlined'>
                      EDIT PRE-SET ANGLES
                    </Button>
                  </Box>
                )}
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableControl={isEdit}
        disableNext={isEdit}
      />
    </Box>
  );
}

PreSetAngles.propTypes = {
  machineSetup: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
  setupMachineData: PropTypes.object,
  preSetAnglePractice: PropTypes.object,
  setPreSetAnglePractice: PropTypes.func,
  firstSide: PropTypes.number,
  testMode: PropTypes.number,
  disablePracticePreSetAngle: PropTypes.bool,
  setDisablePracticePreSetAngle: PropTypes.func,
};
