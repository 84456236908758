import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import angleSelector from 'assets/images/png/physical-positioning/3-1.png';
import seatlabBelt from 'assets/images/png/physical-positioning/3-2.png';
import lapBelt from 'assets/images/png/physical-positioning/3d1.png';
import footRest from 'assets/images/png/physical-positioning/3d2.png';
import handle from 'assets/images/png/physical-positioning/3d3.png';
import counterweightLock from 'assets/images/png/machine-setup/6a.png';
import lumbarLapBelt from 'assets/images/SVG/Lumbar/Lumbar_lap_belt.svg';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ScrollDialog from 'components/ScrollDialog/index.jsx';
import Actions from 'modules/Patients/components/Machines/Actions';
import { SingleImageStep } from 'helpers';
import hip from 'assets/images/SVG/Lumbar/Lumbar_hip-midline-alignment.svg';
import { Controller, useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const seatData = [
  {
    id: 0,
    value: 0,
  },
  {
    id: 1,
    value: 1,
  },
  {
    id: 2,
    value: 2,
  },
];

const manual = () => (
  <Box sx={{ width: '100%', height: '100vh' }}>
    <iframe
      src='https://drive.google.com/file/d/1A47JpU1LGT-ISlRT8kWEMUM00BbyK4Fz/preview'
      width='100%'
      height='100%'
      allow='autoplay'
      frameBorder='0'
    ></iframe>
  </Box>
);

export function BriefPatient({ step, subStep, handleCancel, handleBack, handleNext }) {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Typography variant='body1' component='div'>
        1. Have a brief discussion about the process with new patients to help ease anxiety and apprehension.
        <br />
        2. Outline the test or exercise procedures to be employed. This should include the restraint mechanism and how
        and why it will be used.
        <br />
        3. The patient should be informed as to how they should and should not feel physically. Often, new subjects
        experience warming sensation, associated with muscle fatigue, in the lumbar region after testing or exercise.
        <br />
        4. Listen to the patient. Rely on patient feedback, especially during the stabilization procedures and dynamic
        exercise.
        <br />
        5. Be considerate of patient comfort. If, there is an unexpected, lengthy pause or interruption in testing or
        exercise, the restraint mechanism should be relaxed or loosened.
        <br />
        6. Have patient verbally state their understanding and allow them to ask questions prior to the setup phase.
      </Typography>
      {/* <div style={{ padding: 2 }}>
        <Button onClick={() => setOpenModal(true)} sx={{ ml: 'auto', textDecoration: 'underline' }} variant='text'>
          Link to Manual
        </Button>
      </div> */}
      <ScrollDialog
        fullWidth={true}
        message={manual()}
        title='MANUAL'
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        handleSubmitModal={handleSubmitModal}
      />
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
BriefPatient.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function AngleSelector({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData, onDataChange }) {
  const defaultValues = {
    seat_pad: '',
  };
  const { control, setValue } = useForm({ mode: 'all', defaultValues });
  const [stepData, setStepData] = useState(defaultValues);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    setValue('seat_pad', setupMachineData.seat_pad === null ? '' : setupMachineData.seat_pad);

    setStepData({
      seat_pad: setupMachineData.seat_pad,
    });
  }, [setupMachineData]);
  const handleSeatChange = (value) => {
    setStepData((prevState) => ({ ...prevState, seat_pad: value }));
  };

  const nextHandler = () => {
    if (stepData.seat_pad === setupMachineData?.seat_pad) {
      handleNext();
      return;
    }
    onDataChange({
      seat_pad: stepData.seat_pad,
      femur_restraint: null,
      range_motion_min: null,
      range_motion_max: null,
      dead_center: null,
      counterbalance_angle: null,
      counterbalance_torque: null,
      counterbalance_gauge: null,
    });
    handleNext();
  };
  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
        }}
      >
        <Box>
          <SingleImageStep img={angleSelector} />
        </Box>

        <Typography sx={{ flex: 1 }} variant='body1' component='div'>
          1. Have patient sit in the chair feet tucked under the seat and their back snug against the back rest.
          <br />
          2. Have the patient to sit upright in the machine with the low back area against the pelvic restraint.
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 4,
          display: 'flex',
        }}
      >
        <Box>
          <SingleImageStep img={hip} />
        </Box>

        <Typography sx={{ flex: 1 }} variant='body1' component='div'>
          3. Check to see if the patient’s hip height is above the midline of Lumbar roll.
          <br />
          4. If needed add 1 or 2 seat pads to achieve this hip height.
          <br />
          <b> Manually enter Seat Pad data.</b>
          <br />
          <div style={{ fontSize: '12px' }}> If no seat pad, select “0”</div>
          <Controller
            name='seat_pad'
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 150, mt: 1 }}>
                <Select
                  value={value}
                  displayEmpty
                  onChange={(event) => {
                    onChange(event);
                    handleSeatChange(event.target.value);
                  }}
                >
                  <MenuItem value=''>
                    <em>--</em>
                  </MenuItem>
                  {seatData.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Typography>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={nextHandler}
        disableNext={stepData.seat_pad === null || stepData.seat_pad === ''}
      />
    </>
  );
}
AngleSelector.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
  onDataChange: PropTypes.func,
};

export function SeatLabBeltAdjustment({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box
          sx={{
            gap: 3,
            display: 'flex',
          }}
        >
          <Box>
            <SingleImageStep img={lumbarLapBelt} />
          </Box>

          <Typography sx={{ flex: 1 }} variant='body1' component='div'>
            1. Have the patient hook the lap-belt into the green loops (or as close to the green loops as possible),
            belt edge snug against the crease of the hips.
            <br />
            2. Adjust lap belt adjuster. <br /> 3. Tighten lap belt, so the patient is comfortable.
          </Typography>
        </Box>

        <Box
          sx={{
            gap: 3,
            display: 'flex',
          }}
        >
          <Box>
            <SingleImageStep img={seatlabBelt} />
          </Box>

          <Typography sx={{ flex: 1 }} variant='body1' component='div'>
            4. Adjust top of the head rest pad near the base of the skull, so the patient is comfortable. Move to the patient’s
            liking on the back of head.
          </Typography>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
SeatLabBeltAdjustment.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};

export function TightenRestraints({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={lapBelt} />
            </Box>
            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              1. Begin to tighten lap belt until patient verbally states that it’s snug, but not uncomfortable
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={footRest} />
            </Box>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography sx={{ flex: 1 }} variant='body1' component='div'>
                2. Begin to tighten the footboard until the patient verbally states that it is snug, but not
                uncomfortable.
                <br />
                3. <b style={{ color: '#ED9A00' }}>Check</b>: Ask the patient to lift heels. 1/4 inch lift is allowed.
                Adjust Footrest adjuster as needed.
                <br />
                4. <b style={{ color: '#ED9A00' }}>Check</b>: Ask the patient to touch their toes. Patient should not be
                able to lift butt out of the chair. Patient should have constant contact between posterior lumbar and
                back rest.
                <br />
                5. <b style={{ color: '#ED9A00' }}>Check</b>: See if the pad rotates, the restraints should be tightened further. Adjust
                lap belt adjuster as needed.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={handle} />
            </Box>
            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              6. If needed, repeat heel lift and toe touch tests, and make sure adjustments achieve goals.
              <br />
              7. Have the patient hold onto operator handles.
            </Typography>
          </Box>
        </OverlayScrollbarsComponent>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
TightenRestraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function LevelAndLockCounterWeight({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box>
          <SingleImageStep img={counterweightLock} />
        </Box>

        <Typography sx={{ flex: 1 }} variant='body1' component='div'>
          1.Confirm bubble level is in the center and lock Counterweight lock.
        </Typography>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
LevelAndLockCounterWeight.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
