import Box from '@mui/material/Box';
import styled from '@emotion/styled';

const TextStyle = styled('p')`
  font-size: 24px;
  color: #868e96;
  margin: 0;
`;

function NotAvailablePage() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '80px' }}>
      <TextStyle>Page Not Available</TextStyle>
    </Box>
  );
}

export default NotAvailablePage;
