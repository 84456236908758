import Box from '@mui/material/Box';
import { SingleSelect } from 'modules/DemoGraphic/Shared/SingleSelect.jsx';
import InputText from 'modules/DemoGraphic/Shared/InputText.jsx';
import PropTypes from 'prop-types';
import {
  ATHLETE_LEVEL_OPTIONS,
  ATHLETE_POSITION_OPTIONS,
  ATHLETE_PRACTICE_SPORT_OPTION,
} from 'modules/DemoGraphic/Shared/options.js';

export default function AthleteInformation({ data, setData }) {
  const onChangeHandler = (key, value) => {
    if (key === 'practiceSport') {
      setData((prevState) => ({
        ...prevState,
        [key]: value,
        position: '',
      }));

      return;
    }
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Box className={'modal-content'}>
      <Box className={'row'}>
        <span className={'label'}>What sport do you participate?</span>
        <SingleSelect
          value={data.practiceSport}
          onChange={onChangeHandler}
          width={160}
          field={'practiceSport'}
          options={ATHLETE_PRACTICE_SPORT_OPTION}
        />
      </Box>
      <Box className={'row'}>
        <span className={'label'}>What is your position or event?</span>
        <SingleSelect
          disable={!data.practiceSport || (data.practiceSport && !ATHLETE_POSITION_OPTIONS[data.practiceSport])}
          value={data.position}
          onChange={onChangeHandler}
          width={160}
          field={'position'}
          options={data.practiceSport ? ATHLETE_POSITION_OPTIONS[data.practiceSport] ?? [] : []}
        />
      </Box>

      <Box className={'row'}>
        <span className={'label'}>What is your current level in that sport?</span>
        <SingleSelect
          value={data.level}
          onChange={onChangeHandler}
          width={160}
          field={'level'}
          options={ATHLETE_LEVEL_OPTIONS}
        />
      </Box>
      <div className={'hr'} />

      <Box className={'row'}>
        <span className={'label'}>How many years have you been participating in that sport?</span>
        <InputText
          type={'number'}
          max={99999}
          placeholder={'Input'}
          field={'practiceYear'}
          value={data.practiceYear}
          onChange={onChangeHandler}
          width={250}
        />
      </Box>
    </Box>
  );
}

AthleteInformation.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};
