import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';

import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SessionInfo } from 'modules/Patients/components/Info';
import SideBar from 'modules/Patients/components/Machines/TestSideBar';
import AlertDialog from 'components/AlertDialog/index.jsx';

import Questions from './Steps/Question';
import Preparation from './Steps/SetupReview/Preparation';
import Instruction from './Steps/SetupReview/Instruction';

import ExerciseInstructions from './Steps/ExerciseInstructions';
import MachineSetup from './Steps/MachineSetup';
import PatientsAdjustment from './Steps/PatientsAdjustment';

import SeatingAndFootboard from './Steps/SetupReview/SeatingAndFootboard';
import GatePosition from './Steps/SetupReview/GatePosition';
import TightenRestraints from './Steps/SetupReview/TightenRestraints';
import SetAngleSelector from './Steps/SetAngleSelector';
import DynamicExercise from './Steps/DynamicExercise';
import OneSideSummary from './Steps/OneSideSummary';
import { putActivityNote } from 'modules/Patients/slice.js';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export default function Index({
  patient,
  machine,
  handleNext,
  handleBack,
  handleSelectItem,
  step,
  subStep,
  setupId,
  handleSkipItem,
  navigationItems,
  resetNav,
  setupMachineData,
  testMode,
  setTestMode,
  handleRemoveSkip,
  socketContext,
  firstSide,
  setFirstSide,
  rangeOfMotionMode,
  setRangeOfMotionMode,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const arrMachinesConfigured = useSelector((state) => state.machines.hcpConfiguredMachines);
  const printRef = useRef();
  const machineId = Number(searchParams.get('machineId'));
  const machineType = searchParams.get('machineType');
  const sessionId = searchParams.get('sessionId');
  const [openAlert, setOpenAlert] = useState(false);
  const [leftSide, setLeftSide] = useState({
    weight: '',
    angle: undefined,
    dynamicData: [],
    reps: 0,
    time: 0,
    torq: 0,
  });

  const [rightSide, setRightSide] = useState({
    weight: '',
    angle: undefined,
    dynamicData: [],
    reps: 0,
    time: 0,
    torq: 0,
  });

  useEffect(() => {
    if (!isNil(machineId) && !isNil(machineType) && !isEmpty(arrMachinesConfigured)) {
      socketContext.handleFilterMachine({ machineId, machineType, machines: arrMachinesConfigured });
    }
  }, [machineId, machineType, arrMachinesConfigured]);


  useEffect(() => {
    return () => {
      socketContext.handleResetMachine();
    }
  }, []);

  function contentRender() {
    let nextStep = `${step}`;

    if (subStep !== '0') {
      nextStep = `${step}-${subStep}`;
    }
    const props = {
      step,
      subStep,
      handleCancel,
      handleBack,
      handleNext,
      machine,
      onDataChange,
      setupMachineData: setupMachineData ? setupMachineData.data ?? {} : {},
      setFirstSide,
      firstSide,
      testMode,
      setTestMode,
      rangeOfMotionMode,
      setRangeOfMotionMode,
      leftSide,
      rightSide,
      socketContext,
      sessionId: setupMachineData ? setupMachineData.session_id : '',
    };

    const activityId = localStorage.getItem('activity_id');
    switch (nextStep) {
      case '1':
        return <Questions {...props} />;
      case '2-1':
        return <Preparation {...props} />; // p1
      case '2-2':
        return <Instruction {...props} />; // p1
      case '2-3':
        return <SeatingAndFootboard {...props} />; // p1
      case '2-4':
        return <GatePosition {...props} />; // p1
      case '2-5':
        return <TightenRestraints {...props} handleRemoveSkip={handleRemoveSkip} />; // p1
      case '3':
        return <ExerciseInstructions {...props} />;
      case '4-1':
        return <MachineSetup {...props} />; // p1
      case '4-2':
        return <PatientsAdjustment {...props} />; // p1
      case '4-3':
        return <SetAngleSelector {...props} />; // p1
      case '4-4':
        return <DynamicExercise {...props} />; // p1
      case '4-5':
        return <OneSideSummary {...props} />; // p1
      case '5':
        return (
          <Navigate
            to={`/patient/${id}/dynamic-test/summary?setupId=${setupId}&sessionId=${sessionId}&mode=1&machineType=${machineType}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=false`}
          />
        );

      case '5-1':
        return <MachineSetup {...props} />; // p1
      case '5-2':
        return <PatientsAdjustment {...props} />; // p1
      case '5-3':
        return <SetAngleSelector {...props} />; // p1
      case '5-4':
        return <DynamicExercise {...props} />; // p1
      case '6':
        return (
          <Navigate
            to={`/patient/${id}/dynamic-test/summary?setupId=${setupId}&sessionId=${sessionId}&mode=1&machineType=${machineType}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=false`}
          />
        );
      default:
        return <></>;
    }
  }

  const handleCancel = () => {
    setOpenAlert(true);
  };
  const handleSubmitSecondaryAction = () => {
    const hcpId = localStorage.getItem('hcpId');
    const activityId = localStorage.getItem('activity_id');
    resetNav();
    localStorage.removeItem('activity_id');
    dispatch(
      putActivityNote({
        hcpId,
        patientId: atob(id),
        sessionId: sessionId,
        activitiesId: activityId,
        data: { test_duration: 0 },
      })
    );
    navigate(`/patient/${btoa(patient.patient_id)}&tab=1`);
  };

  const handleSubmitAlertDialog = () => {
    setOpenAlert(false);
  };

  const onDataChange = (data, side) => {
    if (!data) {
      return null;
    }
    if (side === 1) {
      setLeftSide((prevState) => ({ ...prevState, ...data }));
    }
    if (side === 2) {
      setRightSide((prevState) => ({ ...prevState, ...data }));
    }
  };

  return (
    <Box ref={printRef}>
      <SessionInfo machine={machine} patient={patient} title={'Dynamic Exercise'} />
      <Box
        sx={{
          display: 'flex',
          pt: 5,
        }}
      >
        <SideBar
          navigationItems={navigationItems}
          step={step}
          subStep={subStep}
          handleSkipItem={handleSkipItem}
          handleSelectItem={handleSelectItem}
        />

        {/* main content */}
        <Box sx={{ width: '60%', ml: 4 }}>
          <Box>{contentRender()}</Box>
        </Box>
        <AlertDialog
          secondaryActionText='End'
          primaryActionText='Return'
          handleSubmitAlertDialog={handleSubmitAlertDialog}
          handleSubmitSecondaryAction={handleSubmitSecondaryAction}
          openAlertDialog={openAlert}
          message={
            <Box sx={{ maxWidth: '315px' }}>
              Are you sure you want to cancel this Dynamic Exercise ? All data will be deleted.
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

Index.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSelectItem: PropTypes.func,
  navigationItems: PropTypes.array,
  handleSkipItem: PropTypes.func,
  resetNav: PropTypes.func,
  step: PropTypes.string,
  subStep: PropTypes.string,
  setupId: PropTypes.string || PropTypes.number,
  handleRemoveSkip: PropTypes.func,
  setupMachineData: PropTypes.object,
  firstSide: PropTypes.number,
  setFirstSide: PropTypes.func,
  testMode: PropTypes.number,
  setTestMode: PropTypes.func,
  socketContext: PropTypes.object,
  setRangeOfMotionMode: PropTypes.func,
  rangeOfMotionMode: PropTypes.number,
};
