import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

export default function PatientInfo({ patient, currentDay }) {
  return (
    <Box className={'patient-info'} sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar sx={{ width: 40, height: 40 }}>
        {patient.fname.split('')[0]}
        {patient.lname.split('')[0]}
      </Avatar>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
        <Typography sx={{ wordBreak: 'break-all', color: '#666666', fontWeight: 500 }} variant='body1' component='div'>
          {patient.fname} {patient.lname}
        </Typography>
        <Typography sx={{ wordBreak: 'break-all', fontSize: '0.8rem', color: 'gray' }} variant='body1' component='div'>
          Patient ID: {patient.patient_id}
        </Typography>
        <Typography sx={{ wordBreak: 'break-all', fontSize: '0.8rem', color: '#666666' }} variant='body1' component='div'>
          {currentDay}
        </Typography>
      </Box>
    </Box>
  );
}

PatientInfo.propTypes = {
  patient: PropTypes.object,
  currentDay: PropTypes.string
};
