import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getLabel } from 'helpers';
import {
  CIVILIAN_EMPLOYMENT_STATUS_OPTION,
  CIVILIAN_FIRER_STATUS,
  CIVILIAN_JOB_FUNC_STATUS,
  CIVILIAN_OCCUPATION_OPTION,
} from 'modules/DemoGraphic/Shared/options.js';
import { format } from 'date-fns';
import { dateParserWithTimezone } from 'helpers/datetime.js';
import Divider  from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import BorderColor from '@mui/icons-material/BorderColor';

const CivilianInformation = ({ demoGraphicInformation, onEdit }) => {
  const onEditHandler = () => {
    onEdit(2);
  };
  const contentRender = () => {
    if (!demoGraphicInformation || (demoGraphicInformation && Object.keys(demoGraphicInformation).length === 0)) {
      return (
        <Box sx={{ width: '100%', padding: '0 15px ' }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <span className={'main-title'}> Civilian Information</span>
              <div style={{ cursor: 'pointer' }} onClick={onEditHandler}>
                <IconButton sx={{ padding: 0 }} color='secondary'>
                  <BorderColor />
                </IconButton>
                <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
              </div>
            </Box>
          </Box>

          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
              <span className={'title'}>Employment status</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
              <span className={'title'}>Occupation</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
              <span className={'title'}>Join date</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
          </Box>
          <Divider sx={{
            borderBottomWidth: '2px',
            borderColor: '#f5f5f5'
          }}/>

          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Job function</span>
                  <span className={'value'}>{'N/A'}</span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Years in that Position or with that Title</span>
                  <span className={'value'}>{'N/A'}</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ width: '100%', padding: '0 15px ' }}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <span className={'main-title'}> Civilian Information</span>
            <div style={{ cursor: 'pointer' }} onClick={onEditHandler}>
              <IconButton sx={{ padding: 0 }} color='secondary'>
                <BorderColor />
              </IconButton>
              <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
            </div>
          </Box>
        </Box>

        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
            <span className={'title'}>Employment status</span>
            <span className={'value'}>
              {getLabel(demoGraphicInformation.employmentStatus, CIVILIAN_EMPLOYMENT_STATUS_OPTION)}
            </span>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
            <span className={'title'}>Occupation</span>
            <span className={'value'}>{getLabel(demoGraphicInformation.occupation, CIVILIAN_OCCUPATION_OPTION)}</span>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
            <span className={'title'}>Join date</span>
            <span className={'value'}>
              {demoGraphicInformation.enteredDate
                ? format(dateParserWithTimezone(demoGraphicInformation.enteredDate), 'dd/MM/yyyy')
                : 'N/A'}
            </span>
          </Box>
        </Box>
        <Divider sx={{
          borderBottomWidth: '2px',
          borderColor: '#f5f5f5',
        }}/>

        <Box className={'row'}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                <span className={'title'}>
                  {demoGraphicInformation.occupation === 'FIREFIGHTER' ? 'Current Firefighter Title' : 'Job function'}
                </span>
                <span className={'value'}>
                  {demoGraphicInformation.occupation === 'FIREFIGHTER'
                    ? getLabel(demoGraphicInformation.FirefighterTitle, CIVILIAN_FIRER_STATUS)
                    : getLabel(demoGraphicInformation.jobFunc, CIVILIAN_JOB_FUNC_STATUS)}
                </span>
              </Box>

              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                <span className={'title'}>Years in that Position or with that Title</span>
                <span className={'value'}>{demoGraphicInformation.yearInPosition ?? 'N/A'}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return contentRender();
};

CivilianInformation.propTypes = {
  demoGraphicInformation: PropTypes.object,
  onEdit: PropTypes.func,
};

export default CivilianInformation;
