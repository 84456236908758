import PropTypes from 'prop-types';
import { useSummaryPage } from 'modules/Patients/hooks/useSummaryPage';
import { useNavigateItems } from 'modules/Patients/hooks/useMachineSetup';
import StepsContainer from '../../Base/StepsContainer';
import MachineSetupViewContainer from 'modules/Patients/components/Machines/Base/MachineSetupView';
import Preparation from './Steps/Preparation';
import BriefPatient from './Steps/BriefPatient';
import SeatingPosition from './Steps/SeatingPosition';
import Restraints from './Steps/Restraints';
import Instructions from './Steps/Instructions';
import { SetAngleRender } from './Steps/SetAngle';
import PatientRelease from './Steps/PatientRelease';
import EquipmentSwap from './Steps/EquipmentSwap';
import BothRetraints from './Steps/BothRetraints';
import { useEffect, useRef } from 'react';
import { requestSaveSetup } from 'modules/Patients/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function MachineSetupView(props) {
  const { url, machineId, setupId, patientId } = useSummaryPage();
  const navigationItemsContext = useNavigateItems();
  const { Step } = StepsContainer;
  const isFinish = useRef(null);
  const { step, subStep, handleSetStep, handleSetSubStep } = props;
  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const dispatch = useDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    if (!step || !subStep) {
      return;
    }

    let currentStep = `${step}`;

    if (currentStep === '6') {
      if (isFinish.current === true || isUpdating) {
        return;
      }
      isFinish.current = true;
      dispatch(
        requestSaveSetup({
          hcpId: localStorage.getItem('hcpId'),
          patientId: atob(patientId),
          machineId: machineId,
          finished: true,
          setupId,
        })
      );
      return;
    }

  }, [step, subStep]);

  useEffect(() => {
    if (isUpdating || !isFinish.current) {
      return;
    }

    navigator(
      url
    );
  }, [isUpdating]);

  return (
    <MachineSetupViewContainer {...props} navigationItems={navigationItemsContext.navigationItems}>
      <StepsContainer>
        {/* 1 */}
        <Step>
          <Preparation />
        </Step>
        {/* 2 */}
        <Step>
          <BriefPatient />
        </Step>
        {/* 3 */}
        <Step>
          {/* 3-1 */}
          <Step>
            <SeatingPosition />
          </Step>
          {/* 3-2 */}
          <Step>
            <Restraints />
          </Step>
        </Step>
        {/* 4 */}
        <Step>
          <Instructions />
        </Step>
        {/* 5 */}
        <Step>
          {/* 5-1 */}
          <Step>
            <SetAngleRender page='MAX_EXTENSION' />
          </Step>
          {/* 5-2 */}
          <Step>
            <SetAngleRender page='MAX_FLEXION' />
          </Step>
          {/* 5-3 */}
          <Step>
            <PatientRelease />
          </Step>
          {/* 5-4 */}
          <Step>
            <EquipmentSwap />
          </Step>
          {/* 5-5 */}
          <Step>
            <BothRetraints />
          </Step>
          {/* 5-6 */}
          <Step>
            <SetAngleRender page='MAX_EXTENSION' />
          </Step>
          {/* 5-7 */}
          <Step>
            <SetAngleRender page='MAX_FLEXION' />
          </Step>
          {/* 5-8 */}
          <Step>
            <PatientRelease />
          </Step>
        </Step>
      </StepsContainer>
    </MachineSetupViewContainer>
  );
}

MachineSetupView.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSaveData: PropTypes.func,
  handleSelectItem: PropTypes.func,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleSetStep: PropTypes.func,
  handleSetSubStep: PropTypes.func,
  navigationItems: PropTypes.array,
  socketContext: PropTypes.object,
};
