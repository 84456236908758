import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import headRest from 'assets/images/SVG/Cervical/Head-rest.svg';
import torsoRestraints from 'assets/images/SVG/Cervical/Torso-restraints.svg';
import seatBelt from 'assets/images/SVG/Cervical/Chair_Seat-belt.svg';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '',
    img: seatBelt,
  },
  {
    id: 2,
    text: '',
    img: headRest,
  },
  {
    id: 3,
    text: '',
    img: torsoRestraints,
  },
];
const Restraints = ({ step, subStep, handleCancel, handleBack, handleNext, handleRemoveSkip }) => {
  const nextHandler = () => {
    handleRemoveSkip(step);
    handleNext();
  };
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[0]])}</Box>
            <Typography variant='body1' component='div'>
              1. Have the patient sit upright in the machine with their back against the seatback.
              <br />
              2. Help the patient fasten and snug the seat belt and shoulder restraints.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[1]])}</Box>
            <Typography variant='body1' component='div'>
              3. Adjust headrest by swinging headrest downward.
              <br />
              4. Patient’s head should rest against the headrest.
              <br />
              5. Patient’s hand should be crossed in their lap. Feet should be crossed at the ankles and remain still.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box>{getInstructions([instructions[2]])}</Box>
            <Typography variant='body1' component='div'>
              6. Close the gate and snug the torso restraint pads (Titan clockwise, loosen counter). Rotating pads
              should be positioned to conform with the contour of the patient’s upper.
              <br />
              7. Snug the shoulder restraints once again.
              <br />
              8. <b style={{ color: '#ED9A00' }}>Check</b>: Confirm with patient that they cannot lift their shoulders.
            </Typography>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>

      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        handleBack={handleBack}
      />
    </>
  );
};

Restraints.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default Restraints;
