import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ScrollDialog from 'components/ScrollDialog/index.jsx';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import chainTightener from 'assets/images/SVG/Cervical/Chair-tightener.svg';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import { SingleImageStep } from 'helpers';

import Actions from 'modules/Patients/components/Machines/Actions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
export default function ActualPreSetAngles({
  defaultAngles,
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  testMode,
  setDefaultAngles,
  firstSide,
  disableActualPreSetAngle,
  setDisableActualPreSetAngle,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [currentSide, setCurrentSide] = useState(0);
  const [renderAngle, setRenderAngle] = useState([]);
  const [angles, setAngles] = useState([]);
  useEffect(() => {
    if (testMode < 3) {
      setCurrentSide(firstSide);
      setRenderAngle(firstSide === 1 ? [54, 36, 18, 0, -18, -36, -54] : [-54, -36, -18, 0, 18, 36, 54]);
      return null;
    }

    if (step === '6') {
      setCurrentSide(firstSide);
      setRenderAngle(firstSide === 1 ? [54, 36, 18, 0, -18, -36, -54] : [-54, -36, -18, 0, 18, 36, 54]);
      return null;
    }
    setCurrentSide(firstSide === 1 ? 2 : 1);
    setRenderAngle(firstSide === 1 ? [-54, -36, -18, 0, 18, 36, 54] : [54, 36, 18, 0, -18, -36, -54]);
  }, [firstSide, step]);

  useEffect(() => {
    setAngles(defaultAngles);
  }, [defaultAngles, currentSide]);

  const handleSubmitModal = () => {
    setDefaultAngles(angles);
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    setAngles(defaultAngles);
  };

  const nextHandler = () => {
    setDisableActualPreSetAngle(true);
    handleNext();
  };
  const backHandler = () => {
    handleBack();
  };
  const undoHandler = () => {
    if (currentSide === 2) {
      setDefaultAngles([-54, -36, -18, 0, 18, 36, 54]);
    } else {
      setDefaultAngles([54, 36, 18, 0, -18, -36, -54]);
    }
  };
  const getShortName = (angle) => {
    let condition = [54, 36, 18, 0, -18, -36, -54];

    if (currentSide === 2) {
      condition = condition.reverse();
    }

    if (angle === condition[0]) {
      return '1st Angle';
    }
    if (angle === condition[1]) {
      return '2nd Angle';
    }
    if (angle === condition[2]) {
      return '3rd Angle';
    }
    if (angle === condition[3]) {
      return '4th Angle';
    }
    if (angle === condition[4]) {
      return '5th Angle';
    }
    if (angle === condition[5]) {
      return '6th Angle';
    }
    if (angle === condition[6]) {
      return '7th Angle';
    }
  };
  const renderAngles = (angle) => {
    let color = undefined;
    if (!angles.includes(angle)) {
      color = 'lightgray';
    }
    return (
      <Box key={`angle-${angle}`} sx={{ minWidth: '100px', mb: 2, mr: 5, color: color }}>
        <Typography sx={{ fontWeight: 400 }} variant='h6'>
          {getShortName(angle)}
        </Typography>
        <Typography sx={{ fontWeight: 400 }} variant='h6'>
          {angle}°
        </Typography>
      </Box>
    );
  };
  const angleRender = () => {
    return renderAngle.map((a) => renderAngles(a));
  };

  const onRemoveAngle = (angle) => {
    setAngles(angles.filter((r) => r !== angle));
  };

  const renderDialogContent = () => {
    return angles.map((angle, index) => (
      <Box key={`angle-${angle}-${index}`}>
        <Box sx={{ display: 'flex', p: 2 }}>
          <Typography sx={{ fontWeight: 400, width: '200px' }} variant='h6'>
            {getShortName(angle)}
          </Typography>
          <Box sx={{ display: 'flex', width: '100px', justifyContent: 'flex-end' }}>
            <Typography sx={{ fontWeight: 400, mr: 2 }} variant='h6'>
              {angle}
              <span>&#176;</span>
            </Typography>
            <IconButton
              disabled={angles.length <= 1}
              onClick={() => onRemoveAngle(angle)}
              sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
              size='small'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider orientation='horizontal' />
      </Box>
    ));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={chainTightener} />
          </Box>

          <Typography variant='div'>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <span> 1. Let’s execute testing on the:</span>
              <Select value={currentSide} disabled={true}>
                <MenuItem value={currentSide}>{`${currentSide === 1 ? 'Left' : 'Right'}`}</MenuItem>
              </Select>
            </Box>
            <br />
            2. Loosen chain tightener, if not already loosened.
            <br />
            3. Machine will automatically select your pre-set test angles, within the patient’s pain-free ROM.
            <br />
            4.You can manually delete any test angle outside of the pain-free ROM.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={angleSelector} />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h6'>Pre-Set Angles - {`${currentSide === 1 ? 'Left' : 'Right'} Side`}</Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{angleRender()}</Box>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Button disabled={disableActualPreSetAngle} onClick={() => setOpenModal(true)} variant='outlined'>
                EDIT
              </Button>
            </Box>
          </Box>
        </Box>

        <ScrollDialog
          hasCloseIcon={false}
          message={renderDialogContent()}
          handleCloseModal={handleCloseModal}
          openModal={openModal}
          secondaryActionText='Cancel'
          primaryActionText='Save'
          handleSubmitModal={handleSubmitModal}
          handleSubmitSecondaryAction={handleCloseModal}
        />
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={backHandler}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        handleUndo={undoHandler}
        disableRedo={disableActualPreSetAngle}
      />
    </Box>
  );
}

ActualPreSetAngles.propTypes = {
  defaultAngles: PropTypes.array,
  removedAngles: PropTypes.array,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
  handleRemoveAngle: PropTypes.func,
  handleUndoAngle: PropTypes.func,
  setRemovedAngles: PropTypes.func,
  isModalSaved: PropTypes.bool,
  setDefaultAngles: PropTypes.func,
  firstSide: PropTypes.number,
  testMode: PropTypes.number,
  disableActualPreSetAngle: PropTypes.bool,
  setDisableActualPreSetAngle: PropTypes.func,
};
