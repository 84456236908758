import React, { Suspense } from 'react';

const ManageMachines = React.lazy(() => import('modules/Machines/containers/ManageMachines'));
export default [
  {
    path: '/hcp/manage/machines',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ManageMachines />
      </Suspense>
    ),
    private: true,
  },
];
