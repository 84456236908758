import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { getInstructions } from 'helpers';
import noImage from 'assets/images/png/noImage.png';
import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const instructions = [
  {
    id: 1,
    text: '4. Disengage angle selector. SingleSelect 0° and engaged angle selector.',
    img: noImage,
  },
  {
    id: 2,
    text: '5. Turn both range of motion limiters clockwise until they stop.',
    img: noImage,
  },
  {
    id: 3,
    text: '6. Loosen chain tightener.',
    img: noImage,
  },
];

const Preparation = ({ step, handleCancel, handleNext, subStep, handleBack }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Typography variant='body1' component='div'>
          1. If patient is already set up in the Rot Neck machine, then skip to <b>step 3</b> - “Exercise Instructions.”
          <br />
          2. Before starting, make sure you have all medical information about the patient updated.
          <br />
          3. Ask patient if they need to use the restroom as the bladder area will be compressed.
          <br />
          4. Have patient remove belts or restrictive garments and empty object from pockets.
          <br />
        </Typography>
        <br />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>{getInstructions(instructions)}</Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        handleBack={handleBack}
        subStep={subStep}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
};

Preparation.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};

export default Preparation;
