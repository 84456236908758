import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SingleImageStep } from 'helpers';
import GaugeSlider from 'components/GaugeSlider';
import extension from 'assets/images/knee/goinometer extension.svg';
import flexion from 'assets/images/knee/goinometer flexion.svg';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useEffect, useRef, useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import { textFieldRender } from 'helpers';
import { clearMessageQueueHandler } from 'helpers';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import FlipSwitch from 'components/FlipSwitch';

const PageRender = ({ page, payload, step, subStep }) => {
  const maxExtensionRender = () => {
    return (
      <div key={page}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Typography variant='body1' component='div'>
            1. Unlock movement arm and slowly move the movement arm with the Operator’s control handle, so the patient
            is freely moving back and forth without any pain or discomfort.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={extension} />
            </Box>
            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              <div style={{ fontWeight: 'bold' }}>2. ROM - Find Max Extension:</div>
              Assist the patient back into extension with the Operator’s handle to Max Extension (most of the time it’s
              6°).
              <br />• Back off 6° and lock the movement arm and verify the angle selected corresponds with the angle
              displayed on the monitor.
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider value={payload.value} minValue={0} maxValue={120} isLock={payload.isLock} />
            </div>

            <Box>
              <Typography sx={{ fontSize: '1rem', pt: 2, fontWeight: 500, whiteSpace: 'nowrap' }} variant='h2'>
                {/*{t('machine:debugDialog.title')}*/}
                <span style={{ margin: 0 }}> Extension Angle:</span>
              </Typography>
              <Box sx={{ mt: 1 }}>{textFieldRender(payload.value, payload.isLock)}</Box>
              <FlipSwitch />
            </Box>
          </div>
        </OverlayScrollbarsComponent>
        <Actions
          step={step}
          subStep={subStep}
          handleCancel={payload.handleCancel}
          handleBack={payload.handleBack}
          handleNext={payload.handleNext}
          disableNext={!payload.isLock}
          handleUndo={payload.handleRedo}
        />
      </div>
    );
  };

  const maxFlexionRender = () => {
    return (
      <div key={page}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Typography variant='body1' component='div'>
            1. Unlock movement arm and slowly move the movement arm with the Operator’s control handle, so the patient
            is freely moving back and forth without any pain or discomfort.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={flexion} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              <div style={{ fontWeight: 'bold' }}>2. ROM - Find Max Flexion:</div>
              Unlock the movement arm and assist the patient’s leg into flexion with the Operator’s handle to Max
              Flexion.
              <br />• Back off 6° and lock the movement arm and verify the angle selected corresponds with the angle
              displayed on the monitor.
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider value={payload.value} minValue={0} maxValue={120} isLock={payload.isLock} />
            </div>

            <Box>
              <Typography sx={{ fontSize: '1rem', pt: 2, fontWeight: 500, whiteSpace: 'nowrap' }} variant='h2'>
                {/*{t('machine:debugDialog.title')}*/}
                <span style={{ margin: 0 }}> Flexion Angle:</span>
              </Typography>
              <Box sx={{ mt: 1 }}>{textFieldRender(payload.value, payload.isLock)}</Box>
              <FlipSwitch />
            </Box>
          </div>
        </OverlayScrollbarsComponent>

        <Actions
          step={step}
          subStep={subStep}
          handleCancel={payload.handleCancel}
          handleBack={payload.handleBack}
          handleNext={payload.handleNext}
          disableNext={!payload.isLock}
          handleUndo={payload.handleRedo}
        />
      </div>
    );
  };

  switch (page) {
    case 'MAX_EXTENSION':
      return maxExtensionRender();
    case 'MAX_FLEXION':
      return maxFlexionRender();
  }
};

PageRender.prototype = {
  page: PropTypes.string,
  payload: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
};

const getCurrentAngle = (page, payload, knee, subStep) => {
  if (!payload) {
    return null;
  }
  switch (page) {
    case 'MAX_EXTENSION':
      if (knee === 1) {
        return payload.left_range_motion_min;
      } else if (knee === 2) {
        return payload.right_range_motion_min;
      } else if (knee === 4) {
        if (subStep === '1') {
          return payload.left_range_motion_min;
        } else {
          return payload.right_range_motion_min;
        }
      } else {
        if (subStep === '1') {
          return payload.right_range_motion_min;
        } else {
          return payload.left_range_motion_min;
        }
      }

    case 'MAX_FLEXION':
      if (knee === 1) {
        return payload.left_range_motion_max;
      } else if (knee === 2) {
        return payload.right_range_motion_max;
      } else if (knee === 4) {
        if (subStep === '2') {
          return payload.left_range_motion_max;
        } else {
          return payload.right_range_motion_max;
        }
      } else {
        if (subStep === '2') {
          return payload.right_range_motion_max;
        } else {
          return payload.left_range_motion_max;
        }
      }
  }
};

export function SetAngleRender({
  step,
  subStep,
  machine,
  handleCancel,
  handleBack,
  handleNext,
  page,
  onDataChange,
  setupMachineData,
}) {
  const socketContext = useSocketDataContext();
  const isRequesting = useRef('');
  const [value, setValue] = useState(0);
  const [isLock, setLock] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const range = [6, 24];

  const handleStopData = () => {
    isRequesting.current = '';
    setIsReady(false);
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
  };

  useEffect(() => {
    handleStopData();

    return () => {
      handleStopData();
    };
  }, [subStep]);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }

    const knee = setupMachineData.knee_test_mode;
    const currAngle = getCurrentAngle(page, setupMachineData, knee, subStep);
    if (currAngle !== null) {
      setValue(currAngle);
      setLock(true);
      setIsReady(false);
      return;
    }
    setValue(0);
    setLock(false);
    getData();
  }, [setupMachineData, page, step, subStep]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (message.degree !== undefined) {
      setValue(message.degree);
    }

    if (message.done) {
      setLock(true);
      isRequesting.current = '';
    }
  }, [socketContext.messageHistory]);

  const getData = () => {
    if (isRequesting.current) {
      return;
    }

    setIsReady(true);
    setLock(false);
    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    isRequesting.current = page;
  };

  const nextHandler = () => {
    const knee = setupMachineData.knee_test_mode;
    const currentAngle = getCurrentAngle(page, setupMachineData, knee, subStep);
    if (currentAngle === value) {
      handleNext();
      return;
    }

    let data;
    if (knee < 3) {
      if (page === 'MAX_EXTENSION') {
        if (knee === 1) {
          data = {
            left_range_motion_min: value,
          };
        } else {
          data = {
            right_range_motion_min: value,
          };
        }
      } else if (page === 'MAX_FLEXION') {
        if (knee === 1) {
          data = {
            left_range_motion_max: value,
          };
        } else {
          data = {
            right_range_motion_max: value,
          };
        }
      }

      onDataChange(data);
      handleNext();

      return null;
    }

    if (knee === 4) {
      if (page === 'MAX_EXTENSION') {
        if (subStep === '1') {
          data = {
            left_range_motion_min: value,
          };
        } else if (subStep === '6') {
          data = {
            right_range_motion_min: value,
          };
        }
      } else if (page === 'MAX_FLEXION') {
        if (subStep === '2') {
          data = {
            left_range_motion_max: value,
          };
        } else if (subStep === '7') {
          data = {
            right_range_motion_max: value,
          };
        }
      }

      onDataChange(data);
      handleNext();

      return null;
    }

    if (knee === 5) {
      if (page === 'MAX_EXTENSION') {
        if (subStep === '1') {
          data = {
            right_range_motion_min: value,
          };
        } else if (subStep === '6') {
          data = {
            left_range_motion_min: value,
          };
        }
      } else if (page === 'MAX_FLEXION') {
        if (subStep === '2') {
          data = {
            right_range_motion_max: value,
          };
        } else if (subStep === '7') {
          data = {
            left_range_motion_max: value,
          };
        }
      }

      onDataChange(data);
      handleNext();

      return null;
    }
  };

  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);

    setTimeout(() => {
      isRequesting.current = '';
      getData();
    }, 2000);
  };

  const backHandler = () => {
    handleStopData();
    handleBack();
  };

  return (
    <Box key={`${page}-${step}-${subStep}`} sx={{ overflow: 'hidden' }}>
      <PageRender
        payload={{
          handleCancel,
          handleBack: backHandler,
          handleNext: nextHandler,
          handleRedo: redoHandler,
          getData,
          value,
          isLock,
          range,
          targetValue: setupMachineData?.range_motion_min,
        }}
        page={page}
        step={step}
        subStep={subStep}
      />
    </Box>
  );
}
SetAngleRender.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  page: PropTypes.string,
  values: PropTypes.object,
  onDataChange: PropTypes.func,

  setupMachineData: PropTypes.object,
  socketContext: PropTypes.object,
};
