import * as React from 'react';
import { TextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export default function InputTextarea({ field, value, onChange, width }) {
  useEffect(() => {
    return () => {
      onChange(field, '');
    };
  }, []);

  const onChangeHandler = (e) => {
    onChange(field, e.target.value);
  };
  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: ${width}px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    padding: 12px;
    border-radius: 8px;
    color: #24292f;
    border: 2px solid #d4d4d4;
    resize:none;

    &:hover {
      border-color: black;
    }
  
    &:focus {
      border-color: #ED9A00;
 
    }

  `
  );

  return (
    <StyledTextarea
      aria-label='minimum height'
      minRows={3}
      defaultValue={value}
      maxLength={256}
      onBlur={onChangeHandler}
      placeholder='Tell us more...'
    />
  );
}

InputTextarea.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.number,
};
