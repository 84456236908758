import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { SingleSelect } from 'modules/DemoGraphic/Shared/SingleSelect.jsx';
import InputDate from 'modules/DemoGraphic/Shared/InputDate.jsx';
import InputText from 'modules/DemoGraphic/Shared/InputText.jsx';
import PropTypes from 'prop-types';
import {
  MILITARY_ROLE_OPTION,
  MILITARY_RANK_OPTION,
  MILITARY_BRANCH_OF_SERVICE_OPTION,
} from 'modules/DemoGraphic/Shared/options.js';

export default function MilitaryInformation({ data, setData }) {
  const onChangeHandler = (key, value) => {
    if (key === 'branchOfService') {
      setData((prevState) => ({
        ...prevState,
        [key]: value,
        rank: '',
        role: '',
      }));
      return;
    }

    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Box className={'modal-content'}>
      <Box className={'row'}>
        <span className={'label'}>Branch of Service?</span>
        <SingleSelect
          value={data.branchOfService}
          onChange={onChangeHandler}
          width={160}
          field={'branchOfService'}
          options={MILITARY_BRANCH_OF_SERVICE_OPTION}
        />
      </Box>

      <Box className={'row'}>
        <span className={'label'}>Date you entered the service?</span>
        <InputDate value={data.enteredDate} onChange={onChangeHandler} width={250} field={'enteredDate'} />
      </Box>
      <Divider sx={{
        borderBottomWidth: '2px',
        borderColor: '#f5f5f5',
      }}/>

      <Box className={'row'}>
        <span className={'label'}>Current Rank?</span>
        <SingleSelect
          value={data.rank}
          onChange={onChangeHandler}
          width={160}
          field={'rank'}
          disable={!data.branchOfService}
          options={data.branchOfService ? MILITARY_RANK_OPTION[data.branchOfService] : []}
        />
      </Box>

      <Box className={'row'}>
        <span className={'label'}>Current Role?</span>
        <SingleSelect
          value={data.role}
          onChange={onChangeHandler}
          width={160}
          field={'role'}
          disable={!data.branchOfService}
          options={data.branchOfService ? MILITARY_ROLE_OPTION[data.branchOfService] : []}
        />
      </Box>

      <Box className={'row'}>
        <span className={'label'}>Years in that Role?</span>
        <InputText
          type={'number'}
          max={99999}
          placeholder={'Input'}
          field={'yearInRole'}
          value={data.yearInRole}
          onChange={onChangeHandler}
          width={250}
        />
      </Box>

      {/*condition*/}

      {data.role === 'PILOT' && (
        <Box className={'row'}>
          <span className={'label'}>Current type of Airframe?</span>
          <SingleSelect
            value={data.typeOfAirFrame}
            onChange={onChangeHandler}
            width={160}
            field={'typeOfAirFrame'}
            options={[
              { value: 'F-22', label: 'F-22' },
              { value: 'F-35', label: 'F-35' },
              { value: 'F/A-18', label: 'F/A-18' },
              { value: 'F-14', label: 'F-14' },
              { value: 'F-15', label: 'F-15' },
              { value: 'F-16', label: 'F-16' },
              { value: 'A-10', label: 'A-10' },
            ]}
          />
        </Box>
      )}

      {data.role === 'PILOT' && (
        <Box className={'row'}>
          <span className={'label'}>Hours of flying in this Airframe?</span>
          <InputText
            type={'number'}
            max={99999}
            placeholder={'Input'}
            field={'hourFly'}
            value={data.hourFly}
            onChange={onChangeHandler}
            width={250}
          />
        </Box>
      )}

      {data.role === 'PILOT' && (
        <Box className={'row'}>
          <span className={'label'}>Total hours of flying?</span>
          <InputText
            type={'number'}
            max={99999}
            placeholder={'Input'}
            field={'totalHourFly'}
            value={data.totalHourFly}
            onChange={onChangeHandler}
            width={250}
          />
        </Box>
      )}
      {/*condition*/}

      <Box className={'row'}>
        <span className={'label'}>Number of Military Free Falls?</span>
        <InputText
          type={'number'}
          max={99999}
          placeholder={'Input'}
          field={'totalFee'}
          value={data.totalFee}
          onChange={onChangeHandler}
          width={250}
        />
      </Box>
    </Box>
  );
}

MilitaryInformation.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};
