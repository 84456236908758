import socketActions from 'components/WebSocketProvider/constants.js';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import TextField from '@mui/material/TextField';
import { Status } from 'components/Table';
import {
  exertionLevel,
  levelOptions,
  repsOptions,
} from 'modules/Patients/containers/Machines/ActivityQuestion/index.jsx';

export const configuredStatus = {
  NO: 0,
  READY: 1,
  YES: 3,
  CONFIGURING: 2,
};

export function isValidBase64(string) {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(string);
}

export const clearMessageQueueHandler = (socketContext, machine) => {
  if (!socketContext || !machine) {
    return null;
  }

  socketContext.sendJsonMessage({
    request: socketActions.CLEAR_MESSAGE_QUEUE,
    u12_id: machine.machine.u12_id,
    mac_address: machine.machine.mac_addr,
  });

  socketContext.clearMessageHistory();
};

export const getCookie = (cname) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const getName = (first, last, email) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar sx={{ width: 40, height: 40 }}>
        {first.split('')[0]}
        {last.split('')[0]}
      </Avatar>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
        <Typography sx={{ wordBreak: 'break-all' }} variant='body1' component='div'>
          {first} {last}
        </Typography>
        <Typography sx={{ wordBreak: 'break-all', fontSize: '0.8rem', color: 'gray' }} variant='body1' component='div'>
          {email}
        </Typography>
      </Box>
    </Box>
  );
};

export const getEmailErrorMessage = (error, t) => {
  if (error?.type === 'required') return t('common:errors.required');
  if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidEmailPattern');
  if (error?.type === 'email_exists') return t('customer:inviteDialog.errors.emailExists');
};

export const getNameErrorMessage = (error, t) => {
  if (error?.type === 'required') return t('common:errors.required');
  if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidNamePattern');
};

export const formatPhoneNumber = (phoneNumber) => {
  let mobileNo;
  if (formatPhoneNumberIntl(`+${phoneNumber}`)) {
    mobileNo = formatPhoneNumberIntl(`+${phoneNumber}`);
  } else if (phoneNumber) {
    mobileNo = phoneNumber;
  } else {
    mobileNo = 'None';
  }
  return mobileNo;
};

export const getPresetAngle = (rangeMotionMin, rangMotionMax, step, mode) => {
  const centerValue = 0;

  let fistAngleStepAvailable = Math.abs(Math.abs(rangeMotionMin) - centerValue) / step;
  let thirdAngleStepAvailable = Math.abs(Math.abs(rangMotionMax) - centerValue) / step;

  if (fistAngleStepAvailable > 1) {
    fistAngleStepAvailable = fistAngleStepAvailable - 1;
  }

  if (thirdAngleStepAvailable > 1) {
    thirdAngleStepAvailable = thirdAngleStepAvailable - 1;
  }

  if (fistAngleStepAvailable > 4) {
    fistAngleStepAvailable = fistAngleStepAvailable - 4;
  }

  if (thirdAngleStepAvailable > 4) {
    thirdAngleStepAvailable = thirdAngleStepAvailable - 4;
  }

  let s1 = Math.abs(rangeMotionMin) - (Math.floor(Math.random() * fistAngleStepAvailable) + 1) * step;
  let s3 = Math.abs(rangMotionMax) - (Math.floor(Math.random() * thirdAngleStepAvailable) + 1) * step;

  if (s1 === 0) {
    s1 += Math.abs(rangeMotionMin);
  }
  if (s3 === 0) {
    s3 += Math.abs(rangMotionMax);
  }

  let s2 = centerValue;
  const operator = Math.floor(Math.random() * 3) + 1;

  if (operator === 1 && Math.abs(centerValue - 6) < s1) {
    s2 = s2 - 6;
  }
  if (operator === 3 && Math.abs(centerValue + 6) < s3) {
    s2 = s2 + 6;
  }

  s1 = -s1;

  return mode === 2 ? [s1, s2, s3] : [s3, s2, s1];
};

export const textFieldRender = (value, isLock) => {
  let content = '';
  if (!isLock) {
    content = '---';
  } else {
    if (value === null || value === undefined) {
      content = '---';
    } else {
      content = `${value}°`;
    }
  }

  return (
    <TextField
      value={content}
      InputProps={{
        readOnly: true,
      }}
    />
  );
};

export const textFieldRenderMaxExtension = (value, isLock) => {
  let content = '';
  if (!isLock) {
    content = '0';
  } else {
    if (value === null || value === undefined) {
      content = '0';
    } else {
      content = `${value}°`;
    }
  }

  return content;
};

export const getElementMatching = (array, nameMatch) => {
  const elementMatching = array.find(ele => {
    const key = Object.keys(ele)[0];
    const elementMatch = nameMatch.toString() === key;

    if (elementMatch) {
      return { key: parseInt(key), value: ele[key] };
    } else {
      return null;
    }
  })

  return elementMatching;
}

export const preprocessingNormData = (data, normData, dataFirstActivity, dataPrevActivity, mode) => {
  const firstData = dataFirstActivity?.data;
  const prevData = dataPrevActivity?.data;

  const norm = Object.keys(normData)
    .filter((key) => key.split('_')[0] === 'Norm')
    .map((key) => {
      return {
        [key.split('_')[1]]: normData[key],
      };
    });

  const std = Object.keys(normData)
    .filter((key) => (key.split('_')[0] === 'STD' && key.split('_')[1] === 'Above'))
    .map((key) => {
      return {
        [key.split('_')[2]]: normData[key]
      };
    });

  const below = Object.keys(normData)
    .filter((key) => (key.split('_')[0] === 'STD' && key.split('_')[1] === 'Below'))
    .map((key) => {
      return {
        [key.split('_')[2]]: normData[key]
      };
    });

  let arrFirstConvert = [];
  let arrPrevConvert = [];
  let arrFirstNetConvert = [];
  let arrPrevNetConvert = [];

  if (firstData) {
    for (let i = 0; i < firstData?.length; i++) {
      if (firstData[i]) {
        let entry = {};
        let entryNet = {};
        entry[firstData[i].angle_degree] = firstData[i].peak_torque;
        entryNet[firstData[i].angle_degree] = firstData[i].stored_energy;
        arrFirstConvert.push(entry);
        arrFirstNetConvert.push(entryNet);
      }
    }
  }

  if (prevData) {
    for (let i = 0; i < prevData?.length; i++) {
      if (prevData[i]) {
        let entry = {};
        let entryNet = {};
        entry[prevData[i].angle_degree] = prevData[i].peak_torque;
        entryNet[prevData[i].angle_degree] = prevData[i].stored_energy;
        arrPrevConvert.push(entry);
        arrPrevNetConvert.push(entryNet)
      }
    }
  }

  const dataPreprocessor = [...data];

  if (mode === 1) {
    dataPreprocessor.map((d) => {
      const currentNormData = norm.find((n) => n[`${-Number(d.name)}`]);
      const currentStd = std.find((n) => n[`${-Number(d.name)}`]);
      const currentBelow = below.find((n) => n[`${-Number(d.name)}`]);
      const currentFirst = getElementMatching(arrFirstConvert, d?.name);
      const currentFirstNet = getElementMatching(arrFirstNetConvert, d?.name);
      const currentPrev = getElementMatching(arrPrevConvert, d?.name);
      const currentPrevNet = getElementMatching(arrPrevNetConvert, d?.name);

      if (currentNormData) {
        d.norm = currentNormData[`${-Number(d.name)}`];
      };

      if (currentStd) {
        d.std = currentStd[`${-Number(d.name)}`];
      };

      if (currentNormData && currentStd) {
        d.rangeNormAbove = [currentNormData[`${-Number(d.name)}`], currentStd[`${-Number(d.name)}`]];
      };

      if (currentBelow) {
        d.below = currentBelow[`${-Number(d.name)}`];
      };

      if (currentNormData && currentBelow) {
        d.rangeNormBelow = [currentBelow[`${-Number(d.name)}`], currentNormData[`${-Number(d.name)}`]];
      };

      if (currentBelow && currentStd) {
        d.range = [currentBelow[`${-Number(d.name)}`], currentStd[`${-Number(d.name)}`]];
      }

      if (currentFirst) {
        d.first = Math.abs(currentFirst[`${d.name}`]);
        d.firstNet = Math.abs(currentFirst[`${d.name}`]) - Math.abs(currentFirstNet[`${d.name}`]);
      } else {
        d.first = null;
        d.firstNet = null;
      };

      if (currentPrev) {
        d.recent = Math.abs(currentPrev[`${d.name}`]);
        d.recentNet = Math.abs(currentPrev[`${d.name}`]) - Math.abs(currentPrevNet[`${d.name}`]);
      } else {
        d.recent = null;
        d.recentNet = null;
      };

    });

    return dataPreprocessor;
  }

  dataPreprocessor.map((d) => {
    const currentNormData = norm.find((n) => n[`${d.name}`]);
    const currentStd = std.find((n) => n[`${d.name}`]);
    const currentBelow = below.find((n) => n[`${d.name}`]);

    const matchingElementCurrentFirst = getElementMatching(arrFirstConvert, d?.name);

    const matchingElementCurrentFirstNet = getElementMatching(arrFirstNetConvert, d?.name);

    const matchingElementCurrentPrev = getElementMatching(arrPrevConvert, d?.name);

    const matchingElementCurrentPrevNet = getElementMatching(arrPrevNetConvert, d?.name);

    if (currentNormData) {
      d.norm = currentNormData[`${d.name}`];
    };

    if (currentStd) {
      d.std = currentStd[`${d.name}`];
    };

    if (currentBelow) {
      d.below = currentBelow[`${d.name}`];
    };

    if (currentNormData && currentStd) {
      d.rangeNormAbove = [currentNormData[`${d.name}`], currentStd[`${d.name}`]];
    };

    if (currentBelow && currentStd) {
      d.range = [currentBelow[`${d.name}`], currentStd[`${d.name}`]];
    };

    if (currentNormData && currentBelow) {
      d.rangeNormBelow = [currentBelow[`${d.name}`], currentNormData[`${d.name}`]];
    };

    if (matchingElementCurrentFirst) {
      d.first = Math.abs(matchingElementCurrentFirst[`${d?.name}`]);
      d.firstNet = Math.abs(matchingElementCurrentFirst[`${d.name}`]) - Math.abs(matchingElementCurrentFirstNet[`${d?.name}`]);
    } else {
      d.first = null;
      d.firstNet = null;
    }

    if (matchingElementCurrentPrev) {
      d.recent = Math.abs(matchingElementCurrentPrev[`${d?.name}`]);
      d.recentNet = Math.abs(matchingElementCurrentPrev[`${d?.name}`]) - Math.abs(matchingElementCurrentPrevNet[`${d?.name}`]);
    } else {
      d.recent = null;
      d.recentNet = null;
    }

  });

  return dataPreprocessor;
};

export default function getStatus(status, t) {
  switch (status) {
    case 2:
      return <Status text={t('common:locked')} color={'error'} />;
    case 1:
      return <Status text={t('common:active')} color={'success'} />;
    case 0:
      return <Status text={t('common:pending')} color={'info'} />;
    default:
      return <Status text={t('common:suspended')} color={'warning'} />;
  }
}

export function getMachineConfigureStatus(status, t) {
  switch (status) {
    case -1:
      return <Typography color={'error'}>{t('common:failed')}</Typography>;
    case 1:
      return <Typography color={'primary.main'}>{t('common:configuring')}</Typography>;
    case 2:
      return <Typography sx={{ color: 'rgb(0 142 60)' }}>{t('common:completed')}</Typography>;
    default:
      return <Typography color={'info'}>{t('common:readyForConfiguration')}</Typography>;
  }
}

export function getConfiguredStatusGeneralMachine(status, t) {
  switch (status) {
    case configuredStatus.READY:
      return t('common:readyForConfiguration');
    case configuredStatus.YES:
      return t('common:yes');
    case configuredStatus.CONFIGURING:
      return t('common:configuring');

    default:
      return t('common:no');
  }
}

export const genderRender = (gender) => {
  if (gender === null) {
    return '';
  }

  if (gender === '1') {
    return 'Male';
  }

  if (gender === '0') {
    return 'Female';
  }
  return gender;
};

export const allowDecimalNumber = (e) => {
  const code = 'charCode' in e ? e.charCode : e.keyCode;
  if (!(code === 13) && !(code > 45 && code < 58)) {
    e.preventDefault();
  }
};

export const allowIntNumber = (e) => {
  const code = 'charCode' in e ? e.charCode : e.keyCode;
  if (!(code === 13) && !(code > 47 && code < 58)) {
    e.preventDefault();
  }
};

export const allowAlphaNumericSpace = (e) => {
  const code = 'charCode' in e ? e.charCode : e.keyCode;
  if (
    !(code === 32) && // space
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123)
  ) {
    // lower alpha (a-z)
    e.preventDefault();
  }
};

export const getInstructions = (instructions) => {
  return instructions.map((instruction) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        key={instruction.id}
      >
        <Box
          sx={{
            width: '200px',
            height: '200px',
            border: '1px solid #D4D4D4',
            borderRadius: '6px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              mb: 1,
              img: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            {instruction.img && <img src={instruction.img} alt={instruction.text} loading='lazy' />}
          </Box>
        </Box>
        <Typography
          sx={{
            width: '200px',
          }}
          variant='body1'
          component='div'
        >
          {instruction.text}
        </Typography>
      </Box>
    );
  });
};

// eslint-disable-next-line react/prop-types
export const SingleImageStep = ({ img }) => {
  return (
    <Box
      sx={{
        mr: 4,
        width: '200px',
        height: '200px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          img: {
            width: '100%',
            height: '100%',
            border: '1px solid #D4D4D4',
            borderRadius: '5px',
          },
        }}
      >
        <img src={img} loading='lazy' />
      </Box>
    </Box>
  );
};

export const removeSensitiveData = (object) => {
  const _object = JSON.parse(JSON.stringify(object));
  Object.keys(_object).map((key) => {
    if (!_object[key] || (Array.isArray(_object[key]) && _object[key].length === 0)) {
      delete _object[key];
    }
  });

  return _object;
};

export const getActivityQuestionLabel = (answer, value) => {
  let _object = [];

  switch (answer) {
    case 'answer_1':
      _object = JSON.parse(JSON.stringify(levelOptions));
      break;
    case 'answer_2':
      _object = JSON.parse(JSON.stringify(exertionLevel));
      break;
    case 'answer_3':
      _object = JSON.parse(JSON.stringify(repsOptions));
      break;
  }

  const _value = _object.find((obj) => obj.value === value);

  if (!_value) {
    return 'N/A';
  }

  return _value.label;
};

export const demoGraphicOptionsLabel = (options, otherText) => {
  if (!options || (options && options.length === 0)) {
    return 'N/A';
  }

  let content = '';
  let otherOption = null;
  options.map((option, index) => {
    if (option.value !== 'OTHER') {
      if (options.length === 1) {
        content += `${option.label}`;
      } else {
        content += `${option.label}, `;
      }
    } else {
      otherOption = option;
    }
  });

  if (options.length > 1) {
    content = content.slice(0, -2);
  }

  if (otherOption) {
    if (options.length === 1 && !otherText) {
      return 'Other';
    }

    if (options.length === 1 && otherText) {
      return `Other: ${otherText}`;
    }

    if (!otherText) {
      content += `, Other`;
      return content;
    }
    content += `, Other: ${otherText}`;

    return content;
  }

  return content;
};

export const getToggleOptionLabel = (value) => {
  if (!value) {
    return 'N/A';
  }

  if (value === 'YES') {
    return 'Yes';
  }

  return 'No';
};

export const getLabel = (value, options) => {
  if (!value) {
    return 'N/A';
  }

  const result = options.find((option) => option.value.toLowerCase() === value.toLowerCase());

  return !result ? 'N/A' : result.label;
};
