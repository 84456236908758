import { Modal as MuiModal } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PropTypes, { bool } from 'prop-types';
import { DemoGraphicContainer } from 'modules/DemoGraphic/Shared/styled.js';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '16px',
  outline: 'none',
};

const buttonStyle = {
  color: '#262626',
  padding: '10px 24px',
  borderRadius: '8px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  borderColor: '#262626',
};

export default function BaseModal({
  key,
  showModal,
  onClose,
  title,
  content,
  date,
  isPrevious,
  submitText,
  onSubmit,
  onPrevious,
  onReuseData,
  isDisabledReuse,
  isLoading = false,
  showReuse,
}) {
  return (
    <MuiModal key={key} open={showModal} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <DemoGraphicContainer>
        <div className={'modal-body'}>
          <Box>
            <Box className={'modal-header'}>
              {date ? (
                <Box className={'title'}>
                  <span className={'top-title'}> Demographic Questions</span>
                  <span className={'main-title'}>{title}</span>
                  <span className={'date-title'}>{date}</span>
                </Box>
              ) : (
                <Box className={'title'}>
                  <span className={'main-title'}>{title}</span>
                </Box>
              )}

              {onClose && (
                <IconButton sx={{ padding: 0 }} onClick={onClose}>
                  <CloseOutlinedIcon />
                </IconButton>
              )}
            </Box>

            <Box sx={{ padding: '24px' }}>
              <div id='child-modal-description'>{content()}</div>
            </Box>

            <Box className={'footer'}>
              {isPrevious ? (
                <Button onClick={onPrevious} sx={{ ...buttonStyle }} variant={'outlined'}>
                  Previous
                </Button>
              ) : showReuse ? (
                <div>
                  <Button disabled={isDisabledReuse} onClick={onReuseData} sx={{ ...buttonStyle }} variant={'outlined'}>
                    Reuse latest data
                  </Button>
                </div>
              ) : (
                <div></div>
              )}

              <LoadingButton loading={isLoading} onClick={onSubmit} sx={{ ...buttonStyle }} variant={'contained'}>
                {submitText}
              </LoadingButton>
            </Box>
          </Box>
        </div>
      </DemoGraphicContainer>
    </MuiModal>
  );
}
BaseModal.propTypes = {
  key: PropTypes.string,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.func,
  date: PropTypes.string,
  isPrevious: PropTypes.bool,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  onPrevious: PropTypes.func,
  onReuseData: PropTypes.func,
  isDisabledReuse: PropTypes.bool,
  isLoading: PropTypes.bool,
  showReuse: PropTypes.bool,
};
