import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';

export default function TightenRestraints({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              1. Close and lock gate. Tighten the Torso Restraints with Torso Restraint Adjustment.
              <Box sx={{ paddingLeft: '20px' }}>
                • Have patient attempt to shrug shoulders.
                <br />
                • Tighten restraints until little or no shoulder elevation is possible.
                <br />• Avoid over tightening which might restrict blood flow.
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              2. Tighten Head Restraint pads, verifying restrained by having patient perform headshake.
              <Box sx={{ paddingLeft: '20px' }}>• Do not over tighten, but confirm head restrain.</Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>

            <Box>
              3. Tighten seat belt.
              <br />
              4. Instruct patient to maintain contact with their back and the seatback.
              <br />
              5. Instruction patient to rest hands in their lap and relax their head.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

TightenRestraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
