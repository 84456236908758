import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';
import socketActions from 'components/WebSocketProvider/constants.js';
import noImage from 'assets/images/png/noImage.png';
import { useEffect, useState } from 'react';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
import { clearMessageQueueHandler } from 'helpers';

const SetAngleSelector = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  machine,
  onDataChange,
  setupMachineData,
  firstSide,
  socketContext,
  leftSide,
  rightSide,
  rangeOfMotionMode,
}) => {
  const [currentAngle, setCurrentAngle] = useState(0);
  const [isLock, setLock] = useState(false);
  const [state, setState] = useState(undefined);
  const [rangeMotionMax, setRangeMotionMax] = useState(0);
  const [rangeMotionMin, setRangeMotionMin] = useState(0);
  const [currentSide, setCurrentSide] = useState(0);

  useEffect(() => {
    return () => {
      socketContext.clearMessageHistory();
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, []);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }

    switch (firstSide) {
      case 1:
        setCurrentSide(Number(step) === 4 ? 1 : 2);
        break;

      case 2:
        setCurrentSide(Number(step) === 4 ? 2 : 1);
        break;
    }

    if (rangeOfMotionMode === 1) {
      if (Math.abs(setupMachineData.range_motion_min) < Math.abs(setupMachineData.range_motion_max)) {
        setRangeMotionMax(Math.abs(setupMachineData.range_motion_min));
        setRangeMotionMin(-Math.abs(setupMachineData.range_motion_min));
      } else {
        setRangeMotionMax(Math.abs(setupMachineData.range_motion_max));
        setRangeMotionMin(-Math.abs(setupMachineData.range_motion_max));
      }
      return;
    }

    setRangeMotionMin(setupMachineData.range_motion_min);
    setRangeMotionMax(setupMachineData.range_motion_max);
  }, [setupMachineData, firstSide, step, rangeOfMotionMode]);

  useEffect(() => {
    if (!leftSide || !rightSide || !currentSide) {
      return null;
    }
    setLock(currentSide === 1 ? leftSide.angle !== undefined : rightSide.angle !== undefined);

    setCurrentAngle(currentSide === 1 ? leftSide.angle ?? 0 : rightSide.angle ?? 0);
    setState(
      currentSide === 1 ? (leftSide.angle !== undefined ? undefined : 1) : rightSide.angle !== undefined ? undefined : 1
    );
    return null;
  }, [leftSide, rightSide, currentSide]);

  useEffect(() => {
    if (!state) {
      return null;
    }

    if (isLock) {
      return null;
    }

    if (!machine) {
      return null;
    }

    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
  }, [state]);

  useEffect(() => {
    if (!state) {
      return;
    }

    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }
    if (isLock) {
      return;
    }

    if (message.degree !== undefined) {
      setCurrentAngle(Number(message.degree));
    }
    if (message.done) {
      setLock(true);
    }
  }, [socketContext.messageHistory]);

  const backHandler = () => {
    socketContext.clearMessageHistory();
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    handleBack();
  };

  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);

    setTimeout(() => {
      setLock(false);
      setState(1);
      socketContext.sendJsonMessage({
        request: socketActions.GET_DEGREE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }, 2000);
  };

  const nextHandler = () => {
    onDataChange(
      {
        angle: currentAngle,
      },
      currentSide
    );

    handleNext();
  };

  if (!setupMachineData) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={noImage} />
          </Box>
          <Typography variant='body1' component='div'>
            1. Tighten chain tightener. Caution: Do not over tighten!
            <br />
            2. Disengage the angle selector and palpitate 3-6 times through pain-free ROM.
            <br />
            3. Place movement arm at the target angle.
            <br />
            4. Select “Exercise” on the angle selector.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={noImage} />
          </Box>
          <Box>
            <div style={{ maxHeight: 270 }}>
              <GaugeSlider
                value={currentAngle}
                targetValue={Number(currentSide === 1 ? rangeMotionMin : rangeMotionMax)}
                minValue={-96}
                maxValue={96}
                isLock={isLock}
                bottomInfo={`Range of Motion: ${rangeMotionMin}° - ${rangeMotionMax}°`}
              />
            </div>
          </Box>
        </Box>
        <br />
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableNext={!isLock}
        handleUndo={redoHandler}
      />
    </Box>
  );
};

SetAngleSelector.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.array,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  socketContext: PropTypes.object,
  firstSide: PropTypes.number,
  rightSide: PropTypes.object,
  leftSide: PropTypes.object,
  rangeOfMotionMode: PropTypes.number,
};

export default SetAngleSelector;
