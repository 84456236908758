import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import socketActions from 'components/WebSocketProvider/constants.js';

import { useEffect, useRef, useState } from 'react';
import DynamicRechart from '../../../../../../../components/Charts/DynamicReChart';
import { useParams, useSearchParams } from 'react-router-dom';
import { putActivityNote, resetActivityProperties } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'modules/Patients/components/Machines/Actions';
import { MACHINE_TESTING_ANGLE } from '../../../../../constants';
import pip from 'assets/audio/bip.mp3';
import { generateYAxisArray } from 'components/Charts/utils';
import ActivityQuestion from 'modules/Patients/containers/Machines/ActivityQuestion/index.jsx';
import { openDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import FlipSwitch from 'components/FlipSwitch';
import isNil from 'lodash/isNil';

const DynamicExercise = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  setupMachineData,
  machine,
  onDataChange,
  phase,
  phase1,
  phase2,
  firstKnee,
  testMode,
  kneeMode,
  socketContext,
  sessionId,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isExtension, setExtension] = useState(false);
  const timeCounter = useRef(null);
  const a = useRef(null);

  const [readyForNext, setReadyForNext] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [pounds, setPounds] = useState(0);
  const [disableNext, setDisableNext] = useState(true);
  const [target, setTarget] = useState(undefined);
  const putActivityError = useSelector((state) => state.patients.putActivityError);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);
  const [rangeMotionMin, setRangeMotionMin] = useState(undefined);
  const [rangeMotionMax, setRangeMotionMax] = useState(undefined);
  const [data, setData] = useState([]);
  const [isReview, setIsReview] = useState(false);
  const [testKnee, setTestKnee] = useState();
  const [weight, setWeight] = useState('');
  const [time, setTime] = useState(0);
  const [reps, setReps] = useState(0);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);
  const [x, setX] = useState(null);
  const [point, setPoint] = useState(0);

  useEffect(() => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    return () => {
      socketContext.clearMessageHistory();
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    setPoint(data[0].name);
  }, [isExtension]);

  useEffect(() => {
    if (x === null || !disableNext || !data.length) {
      return;
    }

    if (point <= rangeMotionMin - 6) {
      if (x >= rangeMotionMin - 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }
    if (point >= rangeMotionMin + 6) {
      if (x <= rangeMotionMin + 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point >= rangeMotionMax + 6) {
      if (x <= rangeMotionMax + 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point <= rangeMotionMax - 6) {
      if (x >= rangeMotionMax - 6) {
        a.current.play();
        setPoint(x);
      }
    }
  }, [x, point, isExtension]);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    if ((phase === 1 && firstKnee === 1) || (phase === 2 && firstKnee === 2)) {
      setTestKnee(1);
      setRangeMotionMin(setupMachineData.left_range_motion_min ?? 0);
      setRangeMotionMax(setupMachineData.left_range_motion_max ?? 0);
    } else if ((phase === 1 && firstKnee === 2) || (phase === 2 && firstKnee === 1)) {
      setTestKnee(2);
      setRangeMotionMin(setupMachineData.right_range_motion_min ?? 0);
      setRangeMotionMax(setupMachineData.right_range_motion_max ?? 0);
    }
  }, [setupMachineData, firstKnee, phase]);

  useEffect(() => {
    if (!phase) {
      return;
    }

    if (phase === 1) {
      if (phase1.dynamicData.length === 0 && phase1.time === 0) {
        if (timeCounter.current) {
          clearInterval(timeCounter.current);
          timeCounter.current = null;
        }
        setPounds(0);
        setDisableNext(true);
        setTime(0);
        setReps(0);
        setData([]);
        setIsReview(false);
      } else {
        setData(phase1.dynamicData);
        setPounds(Math.abs(phase1.torq));
        setDisableNext(false);
        setReps(phase1.reps);
        setTime(phase1.time);
        setIsReview(true);
      }
      setWeight(phase1.weight !== null ? phase1.weight : '');
      setTarget(phase1.angle);
      setYAxis(generateYAxisArray(phase1.weight, 6));
    }

    if (phase === 2) {
      if (phase2.dynamicData.length === 0 && phase2.time === 0) {
        if (timeCounter.current) {
          clearInterval(timeCounter.current);
          timeCounter.current = null;
        }
        setPounds(0);
        setDisableNext(true);
        setTime(0);
        setReps(0);
        setData([]);
      } else {
        setData(phase2.dynamicData);
        setPounds(Math.abs(phase2.torq));
        setDisableNext(false);
        setReps(phase2.reps);
        setTime(phase2.time);
      }
      setWeight(phase2.weight !== null ? phase2.weight : '');
      setYAxis(generateYAxisArray(phase2.weight, 6));
      setTarget(phase2.angle);
    }
  }, [phase, phase1, phase2]);

  useEffect(() => {
    if (putActivityError === false && isUpdatingActivity === false) {
      if (timeCounter.current) {
        clearInterval(timeCounter.current);
        timeCounter.current = null;
      }

      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }
    if (putActivityError && isUpdatingActivity === false) {
      setIsNext(false);
    }
  }, [putActivityError, isUpdatingActivity]);

  useEffect(() => {
    if (isNext && readyForNext && !isUpdatingActivity) {
      socketContext.clearMessageHistory();
      dispatch(resetActivityProperties());

      onDataChange({
        dynamicData: data,
        time,
        reps,
        torq: pounds,
      });

      handleNext();
    }
  }, [readyForNext, isNext, isUpdatingActivity]);

  useEffect(() => {
    if (target === undefined) {
      return;
    }
    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      requestStartExercise();
    }
  }, [target]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    setReadyForNext(message?.saved === undefined ? true : message?.saved);
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (!message?.angle_degrees || (message?.angle_degrees && message.angle_degrees.length === 0)) {
      return;
    }
    if (!message?.pounds || (message?.pounds && message.pounds.length === 0)) {
      return;
    }
    if (timeCounter.current === null) {
      timeCounter.current = setInterval(() => {
        setTime((prevState) => {
          return prevState + 1;
        });
      }, 1000);
    }

    const index = message.angle_degrees.length - 1;
    setPounds(Math.abs(message.pounds[index]));
    setReps(message.reps);

    let newValue;
    let currValue = message.angle_degrees[index];

    if (message.angle_degrees[index] > 108) {
      currValue = 108;
    }
    if (message.angle_degrees[index] < 6) {
      currValue = 6;
    }

    if (message?.is_flexion) {
      setExtension(false);
      newValue = {
        name: currValue,
        flexion: Math.abs(message.pounds[index]),
      };
    } else {
      setExtension(true);
      newValue = {
        name: currValue,
        extension: Math.abs(message.pounds[index]),
      };
    }

    setX(currValue);

    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      setData((prevState) => {
        return [newValue, ...prevState];
      });
    }

    if (message.done || message.signal === 'PONG') {
      setDisableNext(false);
      clearInterval(timeCounter.current);
    }
  }, [socketContext.messageHistory]);

  const requestStartExercise = () => {
    const activityId = localStorage.getItem('activity_id');

    if (!activityId) {
      return null;
    }
    if (!activityId) {
      return null;
    }
    if (!setupMachineData) {
      return null;
    }
    if (target === undefined) {
      return;
    }
    if (!testKnee) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.DYNAMIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: sessionId,
      activity_id: activityId,
      target_angle: !isNil(rangeMotionMax) ? rangeMotionMax : 0,
      min_angle: !isNil(rangeMotionMin) ? rangeMotionMin : 0,
      weight: !isNil(weight) ? weight : '0',
      knee_test_mode: testKnee,
    });
  };

  const backHandler = () => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    setReps(0);
    setPounds(0);
    setTime(0);
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
    handleBack();
  };

  const nextHandler = () => {
    if (isReview) {
      handleNext();
      return null;
    }
    if (kneeMode < 3 || (kneeMode > 3 && testMode < 3 && phase === 1 && Number(step) === 7)) {
      openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
      return null;
    }

    if (phase === 2 && Number(step) === 6) {
      openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
      return null;
    }

    finishQuestionHandler({});
  };

  const finishQuestionHandler = (question) => {
    const hcpId = localStorage.getItem('hcpId');
    const activityId = localStorage.getItem('activity_id');
    let data;
    if (testKnee === 1) {
      data = {
        test_duration_left: time,
      };
    } else {
      data = {
        test_duration_right: time,
      };
    }

    if (kneeMode < 3 || (kneeMode > 3 && testMode < 3)) {
      data.test_duration = time;
    }
    data = { ...data, ...question };

    dispatch(
      putActivityNote({
        hcpId,
        patientId: atob(id),
        sessionId,
        activitiesId: activityId,
        data,
      })
    );
    setIsNext(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box>
          <Typography variant='body1' component='div'>
            1. Instruct the patient to slowly begin exerting backward force against the resistance pad.
            <br />
            2. <FlipSwitch /> - When the patient begins to start the exercise and exert force.
            <br />
            3. Remind the patient to exercise through their full ROM and maintain proper speed.
            <br />
            4. Have patient perform a dynamic exercise warm-up for 6-8 reps.
            <br />
            5. Have patient return to fully flex position.
            <br />
            6. <FlipSwitch /> - to end exercise.
            <br />
            7. Quickly loosen the chain tightener (counterclockwise), then engage the movement arm lock.
            <br />
            8. Quickly loosen seatbelt with seatbelt adjustment.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
          <Box sx={{ textAlign: 'center' }}>
            <b>{testKnee === 1 ? 'Left' : 'Right'} Leg Extension</b>{' '}
          </Box>

          <DynamicRechart
            activeLine={disableNext ? (isExtension ? 'extension' : 'flexion') : ''}
            xTicks={MACHINE_TESTING_ANGLE.KNEE_TESTING_ANGLE}
            tooltip={false}
            yTicks={yAxis}
            data={data}
            balanceMode={true}
          />
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '0 35px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Torq: `} </span> {pounds}
            </div>
          </Box>
        </Box>
      </Box>
      <audio style={{ visibility: 'hidden', height: '1px' }} className='my_audio' ref={a} controls preload='none'>
        <source src={pip} type='audio/mpeg' />
      </audio>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableNext={disableNext}
        handleCancel={handleCancel}
      />
      <ActivityQuestion onFinish={finishQuestionHandler} />
    </Box>
  );
};

DynamicExercise.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  value: PropTypes.array,
  firstKnee: PropTypes.number,
  phase: PropTypes.number,
  phase1: PropTypes.object,
  phase2: PropTypes.object,
  testMode: PropTypes.number,
  kneeMode: PropTypes.number,
  sessionId: PropTypes.string,
  socketContext: PropTypes.object,
};

export default DynamicExercise;
