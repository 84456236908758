import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const ExerciseInstructions = ({ step, handleCancel, handleNext, subStep, handleBack, phase, disableFirstStep }) => {
  const contentRender = () => {
    if (phase === 2) {
      return (
        <Typography variant='body1' component='div'>
          1. If patient is already warmed up, then skip to <b style={{ color: '#ED9A00' }}>step 4</b>.
          <br />
          2. Provide the following dynamic warm-up instructions:
          <Box sx={{ padding: '0 20px' }}>
            • Exercise through the full range of motion.
            <br />
            • Exercise is both positive and negative.
            <br />
            • Perform each repetition slowly (2-1-4) second count.
            <br />
            • Visual feedback is provided on the monitor.
            <br />
            • You will get an audible warning when you have completed the full range.
            <br />
            • Demonstrate how to push against the resistance pad.
            <br />
            • Avoid holding your breath, exhale when building force.
            <br />
            • Maintain a loose grip on the handlebars.
            <br />
            • Maintain contact with the seatback.
            <br />
            • Maintain ankle dorsi flexion.
            <br />
            •Exert force only when clinician instructs to do so.
          </Box>
        </Typography>
      );
    }

    return (
      <Typography variant='body1' component='div'>
        1. Provide the following dynamic warm-up instructions:
        <Box sx={{ padding: '0 20px' }}>
          • Exercise through the full range of motion.
          <br />
          • Exercise is both positive and negative.
          <br />
          • Perform each repetition slowly (2-1-4) second count.
          <br />
          • Visual feedback is provided on the monitor.
          <br />
          • You will get an audible warning when you have completed the full range.
          <br />
          • Demonstrate how to push against the resistance pad.
          <br />
          • Avoid holding your breath, exhale when building force.
          <br />
          • Maintain a loose grip on the handlebars.
          <br />
          • Maintain contact with the seatback.
          <br />
          • Maintain ankle dorsi flexion.
          <br />• Exert force only when clinician instructs to do so.
        </Box>
      </Typography>
    );
  };
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        {contentRender()}
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        handleBack={handleBack}
        subStep={subStep}
        handleNext={handleNext}
        handleCancel={handleCancel}
        disableBack={disableFirstStep}
      />
    </>
  );
};

ExerciseInstructions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  phase: PropTypes.number,
  disableFirstStep: PropTypes.bool,
};

export default ExerciseInstructions;
