import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValidBase64 } from 'helpers';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DynamicTestView from 'modules/Patients/components/Machines/CervicalRotation/DynamicTest';

import { fetchMachineSetup, fetchPatient, requestInitActivity } from 'modules/Patients/slice';
import { fetchHcpConfiguredMachines } from 'modules/Machines/slice';
import { getDynamicNavigate } from './dynamicNavigateItems';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import axios from 'axios';

const DYNAMIC_TEST = 2;

export default function DynamicTest() {
  const socketContext = useSocketDataContext();

  const patient = useSelector((state) => state.patients.patient);
  const machines = useSelector((state) => state.machines.hcpConfiguredMachines);
  const setupMachineData = useSelector((state) => state.patients.setup);

  const { isLoading } = useSelector((state) => state.ui);
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState('1');
  const [subStep, setSubStep] = useState('0');
  const [machine, setMachine] = useState(null);
  const [testMode, setTestMode] = useState(0);
  const [firstSide, setFirstSide] = useState(0);
  const [rangeOfMotionMode, setRangeOfMotionMode] = useState(1);
  const [disableFirstStep, setDisableFirstStep] = useState(false);
  const [navigationItems, setNavigationItems] = useState([]);

  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  const patientId = id;

  useEffect(() => {
    const machineId = searchParams.get('machineId');
    const machineType = searchParams.get('machineType');
    if (machineId) {
      dispatch(fetchHcpConfiguredMachines({ hcpId: localStorage.getItem('hcpId'), machineType: machineType }));
    }

    if (!patientId || !localStorage.getItem('hcpId') || !sessionId) {
      return;
    }

    dispatch(
      fetchMachineSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(patientId),
        machineId: Number(machineId),
        setupId,
      })
    );

    if (localStorage.getItem('activity_id')) {
      return;
    }

    dispatch(
      requestInitActivity({
        patientId: atob(patientId),
        hcpId: localStorage.getItem('hcpId'),
        sessionId,
        data: {
          activity_type: DYNAMIC_TEST,
          name: 'Dynamic Exercise',
          setup_id: setupId,
        },
      })
    );
  }, []);

  // get patient by id
  useEffect(() => {
    if (patientId && isValidBase64(patientId)) {
      const data = {
        patientId: atob(patientId),
        hcpId: localStorage.getItem('hcpId'),
      };
      dispatch(fetchPatient(data));
    }
  }, [patientId]);

  useEffect(() => {
    const machineId = searchParams.get('machineId');

    if (machineId && machines.length > 0) {
      const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));
      setMachine(machine);
    }
  }, [machines]);
  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    if (!setupMachineData.data) {
      return null;
    }
    if (setupMachineData && setupMachineData.data) {
      setNavigationItems(getDynamicNavigate({ torsoTestMode: testMode }));
      return;
    }

    setNavigationItems(getDynamicNavigate({ torsoTestMode: 1 }));
  }, [setupMachineData]);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setNavigationItems(
      getDynamicNavigate({
        torsoTestMode: testMode,
        firstSide,
      })
    );
  }, [testMode, firstSide]);

  const handleSelectItem = (item) => {
    setStep(item.step);
    setSubStep(item.subStep);
  };

  const skipNextSteps = (step) => {
    let nextStep = parseInt(step, 10) + 1;
    let next = navigationItems.find((item) => item.id === `${nextStep}`);

    while (next && next.skipped) {
      nextStep = parseInt(nextStep, 10) + 1;
      next = navigationItems.find((item) => item.id === `${nextStep}`);
    }

    return { nextStep, next };
  };

  const skipNextSubSteps = (step, subStep) => {
    const parent = navigationItems.find((item) => item.id === `${step}`);
    let nextSubStep = parseInt(subStep, 10) + 1;
    let childStep = parent.children.find((item) => item.id === `${step}-${nextSubStep}`);

    while (childStep && childStep.skipped) {
      nextSubStep = parseInt(nextSubStep, 10) + 1;
      childStep = parent.children.find((item) => item.id === `${step}-${nextSubStep}`);
    }

    return nextSubStep;
  };

  //handle skip item in sidebar
  const handleSkipItem = (currentItem) => {
    if (currentItem.id === '1') {
      setDisableFirstStep(true);
    }

    navigationItems.forEach((n) => {
      if (n.id === currentItem.id) {
        n.skipped = true;
      }
    });

    let nextSubStep;
    const nextStep = parseInt(currentItem.id, 10) + 1;

    const next = navigationItems.find((item) => item.id === `${nextStep}`);

    if (next && next.children) {
      nextSubStep = '1';
    } else {
      nextSubStep = '0';
    }

    handleSelectItem({
      step: `${nextStep}`,
      subStep: nextSubStep,
    });
  };

  //handle back
  const handleBack = () => {
    let previousStep = step;
    let previousSubStep = subStep;

    if (step === '1' && subStep === '1') {
      navigate(`/patient/${btoa(patient.patient_id)}&tab=1`);
      return;
    }

    const current = navigationItems.find((item) => item.id === `${step}`);

    if (current && !current.children) {
      previousStep = skipBackSteps(step).previousStep;

      const { previous } = skipBackSteps(step);

      if (previous && previous.children) {
        previousSubStep = previous.children.length;
      } else {
        previousSubStep = '0';
      }
    } else {
      if (subStep === '1') {
        previousStep = skipBackSteps(step).previousStep;
        const { previous } = skipBackSteps(step);

        if (previous && previous.children) {
          previousSubStep = previous.children.length;
        } else {
          previousSubStep = '0';
        }
      } else {
        previousSubStep = skipBackSubSteps(step, subStep);
      }
    }

    setSubStep(previousSubStep.toString());
    setStep(previousStep.toString());
  };

  const skipBackSteps = (step) => {
    let previousStep = parseInt(step, 10) - 1;
    let previous = navigationItems.find((item) => item.id === `${previousStep}`);

    while (previous && previous.skipped) {
      previousStep = parseInt(previousStep, 10) - 1;
      previous = navigationItems.find((item) => item.id === `${previousStep}`);
    }

    return { previousStep, previous };
  };

  const skipBackSubSteps = (step, subStep) => {
    const parent = navigationItems.find((item) => item.id === `${step}`);
    let prevSubStep = parseInt(subStep, 10) - 1;
    let childStep = parent.children.find((item) => item.id === `${step}-${prevSubStep}`);

    while (childStep && childStep.skipped) {
      prevSubStep = parseInt(prevSubStep, 10) - 1;
      childStep = parent.children.find((item) => item.id === `${step}-${prevSubStep}`);
    }

    return prevSubStep;
  };

  const handleNext = () => {
    let nextStep = step;
    let nextSubStep = subStep;

    const current = navigationItems.find((item) => item.id === `${step}`);

    if (current && !current.children) {
      nextStep = skipNextSteps(step).nextStep;
      const { next } = skipNextSteps(step);

      if (next && next.children) {
        nextSubStep = '1';
      } else {
        nextSubStep = '0';
      }
    } else {
      if (subStep === `${current.children.length}`) {
        nextStep = skipNextSteps(step).nextStep;
        const { next } = skipNextSteps(step);

        if (next && next.children) {
          nextSubStep = '1';
        } else {
          nextSubStep = '0';
        }
      } else {
        nextSubStep = skipNextSubSteps(step, subStep);
      }
    }

    setStep(nextStep.toString());
    setSubStep(nextSubStep.toString());
  };

  const resetNav = () => {
    const newItems = [...navigationItems];
    newItems.forEach((n) => {
      n.skipped = false;
      if (n.id === '1') {
        n.hasSkip = true;
      }
    });
    setNavigationItems(newItems);
  };

  const handleRemoveSkip = (step) => {
    const newItems = [...navigationItems];
    newItems.forEach((n) => {
      if (n.id === step) {
        n.hasSkip = false;
      }
    });
  };

  if (!patient || !machine)
    return (
      <Container
        sx={{
          minHeight: 'calc(100vh - 70px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ wordBreak: 'break-all', color: 'gray' }} variant='h5' component='div'>
          {isLoading ? 'Loading...' : t('common:errors.noResults')}
        </Typography>
      </Container>
    );

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <DynamicTestView
            patient={patient}
            machine={machine}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSkipItem={handleSkipItem}
            disableFirstStep={disableFirstStep}
            handleSelectItem={handleSelectItem}
            handleRemoveSkip={handleRemoveSkip}
            step={step}
            subStep={subStep}
            setStep={setStep}
            setSubStep={setSubStep}
            setupId={setupId}
            resetNav={resetNav}
            navigationItems={navigationItems}
            setupMachineData={setupMachineData}
            firstSide={firstSide}
            setFirstSide={setFirstSide}
            testMode={testMode}
            setTestMode={setTestMode}
            socketContext={socketContext}
            setRangeOfMotionMode={setRangeOfMotionMode}
            rangeOfMotionMode={rangeOfMotionMode}
          />
        </Box>
      </Box>
    </Container>
  );
}
