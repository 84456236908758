import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';

export const UserSlice = createSlice({
  name: 'userSlice',
  initialState: {
    token: '',
    errorCode: 0,
    error: null,
  },
  reducers: {
    requestChangeUserPassword: (state) => {
      state.errorCode = 0;
    },
    requestChangeUserPasswordSuccess: () => { },
    requestChangeUserPasswordFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestUpdateProfile: (state) => {
      state.errorCode = 0;
    },
    requestUpdateProfileSuccess: () => { },
    requestUpdateProfileFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestUpdateHcpUserProfile: (state) => {
      state.error = null;
    },
    requestUpdateHcpUserProfileSuccess: () => { },
    requestUpdateHcpUserProfileFailure: (state, action) => {
      state.error = action.payload;
    },
    requestChangeHcpUserPassword: (state) => {
      state.errorCode = 0;
    },
    requestChangeHcpUserPasswordSuccess: () => { },
    requestChangeHcpUserPasswordFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.errorCode = 0;
      state.error = null;
    },
  },
});
export const {
  requestChangeUserPassword,
  requestChangeUserPasswordSuccess,
  requestChangeUserPasswordFailure,
  requestUpdateProfile,
  requestUpdateProfileSuccess,
  requestUpdateProfileFailure,
  requestChangeHcpUserPassword,
  requestChangeHcpUserPasswordSuccess,
  requestChangeHcpUserPasswordFailure,
  requestUpdateHcpUserProfile,
  requestUpdateHcpUserProfileSuccess,
  requestUpdateHcpUserProfileFailure,
} = UserSlice.actions;

export default UserSlice.reducer;
