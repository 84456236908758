import { useSearchParams } from 'react-router-dom';
import LumbarMachineSetup from 'modules/Patients/containers/Machines/Lumbar/MachineSetup';
import CervicalMachineSetup from 'modules/Patients/containers/Machines/Cervical/MachineSetup';
import KneeMachineSetup from 'modules/Patients/containers/Machines/Knee/MachineSetup';
import TorsoMachineSetup from 'modules/Patients/containers/Machines/Torso/MachineSetup';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearPatientSetup } from '../../slice';
import CervicalRotationMachineSetup from './CervicalRotation/MachineSetup';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
const MachineSetup = () => {
  const [searchParams] = useSearchParams();
  const socketContext = useSocketDataContext();
  const dispatch = useDispatch();
  const machineType = searchParams.get('machineType');
  useEffect(() => {
    return () => {
      localStorage.removeItem('setupId');
      dispatch(clearPatientSetup());
      socketContext.clearMessageHistory();
    };
  }, []);
  switch (machineType) {
    case 'Lumbar':
      return <LumbarMachineSetup />;
    case 'Cervical':
      return <CervicalMachineSetup />;
    case 'Knee':
      return <KneeMachineSetup />;
    case 'Torso Rotation':
      return <TorsoMachineSetup />;
    case 'Cervical Rotation':
      return <CervicalRotationMachineSetup />;
    default:
      return null;
  }
};

export default MachineSetup;
