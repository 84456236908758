import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';

const InstructionForPatient = ({ step, subStep, handleCancel, handleBack, handleNext, disableFirstStep }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Typography variant='body1' component='div'>
          1. Provide the following Dynamic Exercise instructions:
          <br />
          <div style={{ padding: '0 20px' }}>
            • Exercise through the full range of motion.
            <br />
            • Exercise is both positive and negative.
            <br />
            • Perform each repetition slowly (2-1-4) second count.
            <br />
            • Visual feedback is provided on the monitor.
            <br />
            • You will get an audible warning when you have completed the full range.
            <br />
            • Demonstrate how to push against the resistance pad.
            <br />
            • Avoid holding your breath, exhale when building force.
            <br />
            • Keep hands relaxed in lap or resting loosely on gate assembly.
            <br />
            • Keep feet crossed and refrain from swinging legs.
            <br />
            • Maintain contact between head and resistance pad.
            <br />• Exert force only when clinician instructs to do so.
          </div>
        </Typography>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
        disableBack={disableFirstStep}
      />
    </>
  );
};

InstructionForPatient.propTypes = {
  step: PropTypes.string,

  subStep: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disableFirstStep: PropTypes.bool,
};

export default InstructionForPatient;
