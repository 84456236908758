import PropTypes from 'prop-types';
import { useState } from 'react';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import socketActions from 'components/WebSocketProvider/constants.js';
import { SessionInfo } from 'modules/Patients/components/Info';
import SideBar from 'modules/Patients/components/Machines/SetupSideBar';
import AlertDialog from 'components/AlertDialog/index.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { clearMachineSetup, requestDeleteSetup } from 'modules/Patients/slice';
import { cloneElement } from 'react';

export function clearLocalPatientSetupData() {
  // clear setupId on localstorage
  localStorage.removeItem('setupId');
  localStorage.removeItem('selectedMachineId');
  localStorage.removeItem('selectedMachineTypeId');
}

export default function MachineSetupView({
  patient,
  machine,
  handleNext,
  handleBack,
  handleSaveData,
  handleSelectItem,
  step,
  subStep,
  socketContext,
  children,
  navigationItems,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const setupMachineData = useSelector((state) => state.patients.setup);
  const setupId = searchParams.get('setupId');
  const handleCancel = () => {
    setOpenAlert(true);
  };

  const handleSubmitSecondaryAction = () => {
    // request stop receiving data
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    // clear setup data on redux store
    dispatch(clearMachineSetup());
    // clear setup data on cloud
    dispatch(
      requestDeleteSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: patient.patient_id,
        machineId: machine.machine.id,
        setupId: localStorage.getItem('setupId'),
      })
    );

    clearLocalPatientSetupData();

    // back to patient details page
    navigate(`/patient/${btoa(patient.patient_id)}&tab=1&setupId=${setupId}`);
  };

  const handleSubmitAlertDialog = () => {
    setOpenAlert(false);
  };

  const onDataChange = (data) => {
    if (!data) {
      return null;
    }
    handleSaveData(data);
  };

  if (!setupId) {
    return null;
  }

  return (
    <Box>
      <SessionInfo machine={machine} patient={patient} title={t('customer:patientSession.machineSetup')} />
      <Box
        sx={{
          display: 'flex',
          pt: 5,
        }}
      >
        <SideBar navigationItems={navigationItems} step={step} subStep={subStep} handleSelectItem={handleSelectItem} />

        <Box sx={{ width: '70%', ml: 4 }}>
          <Box>
            {cloneElement(children, {
              step,
              subStep,
              handleCancel,
              handleBack,
              machine,
              setupMachineData,
              onDataChange,
              handleNext,
            })}
            {/* <StepsComponent
              step={step}
              subStep={subStep}
              handleCancel={handleCancel}
              handleBack={handleBack}
              machine={machine}
              setupMachineData={setupMachineData}
              onDataChange={onDataChange}
              handleNext={handleNext}
            /> */}
          </Box>
        </Box>
        <AlertDialog
          secondaryActionText='End setup'
          primaryActionText='Return to setup'
          handleSubmitAlertDialog={handleSubmitAlertDialog}
          handleSubmitSecondaryAction={handleSubmitSecondaryAction}
          openAlertDialog={openAlert}
          message={<Box sx={{ maxWidth: '315px' }}>{t('machine:alertDialog')}</Box>}
        />
      </Box>
    </Box>
  );
}

MachineSetupView.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSaveData: PropTypes.func,
  handleSelectItem: PropTypes.func,
  step: PropTypes.string,
  subStep: PropTypes.string,
  socketContext: PropTypes.object,
  children: PropTypes.element,
  navigationItems: PropTypes.array,
};
