import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PatientInfo, MachineInfo } from 'modules/Patients/components/Info';

export default function SessionInfo({ patient, title, machine, isDownloading, currentDay }) {
  if (!machine || !patient) {
    return <></>;
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: isDownloading ? '75%' : '100%' }}>
      <Typography variant='h4' component='div'>
        {title}
      </Typography>
      <MachineInfo machine={machine.machine} />
      <PatientInfo currentDay={currentDay} patient={patient} />
    </Box>
  );
}

SessionInfo.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  title: PropTypes.string,
  isDownloading: PropTypes.bool,
  currentDay: PropTypes.string
};
