import { put, takeLatest } from 'redux-saga/effects';
import { requestDialog, requestDialogSuccess } from 'modules/Dialogs/slice';
import { clearStoreError } from 'modules/Dialogs/actions';

function* updateDialog(action) {
  const data = {
    fromNavbar: action.payload.fromNavbar,
    type: action.payload.type,
    open: action.payload.open,
  };
  yield put(clearStoreError());
  yield put(requestDialogSuccess(data));
}

function* dialogsSaga() {
  yield takeLatest(requestDialog, updateDialog);
}

export default dialogsSaga;
