import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getCountries: {
    url: `${ENV_VARIABLES.API_SERVER}location/countries?limit=300`,
  },
  getRegions: {
    url: (countryCode) => `${ENV_VARIABLES.API_SERVER}location/countries/${countryCode}/regions?limit=50`,
  },
  getCitiesFromRegions: {
    url: (countryCode, regionCode) =>
      `${ENV_VARIABLES.API_SERVER}location/countries/${countryCode}/regions/${regionCode}/cities?limit=50`,
  },
  addPatient: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients`,
  },
  editPatient: {
    url: (customerId, patientId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}`,
  },
  getPatient: {
    url: (customerId, patientId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}`,
  },
  archivePatient: {
    url: (customerId, patientId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/archive`,
  },
  searchPatient: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients`,
  },
  initMachineSetup: {
    url: (customerId, patientId, machineId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/machines/${machineId}/setups`,
  },
  getNormData: {
    url: (testType, normType, setup_id) =>
      `${ENV_VARIABLES.API_SERVER}norm-data?test_type=${testType}&norm_type=${normType}&setup_id=${setup_id}`,
  },
  initActivity: {
    url: (customerId, patientId, sessionId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions/${sessionId}/activities`,
  },
  getMachineSetup: {
    url: (customerId, patientId, machineId, setupId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/machines/${machineId}/setups/${setupId}`,
  },
  listMachineSetup: {
    url: (customerId, patientId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/setups`,
  },
  saveMachineSetup: {
    url: (customerId, patientId, machineId, setupId, finished) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/machines/${machineId}/setups/${setupId}?finished=${finished}`,
  },
  deleteMachineSetup: {
    url: (customerId, patientId, machineId, setupId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/machines/${machineId}/setups/${setupId}`,
  },
  getPatientSessions: {
    url: (customerId, patientId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions`,
  },
  getPatientSession: {
    url: (customerId, patientId, sessionId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions/${sessionId}`,
  },
  createPatientSessions: {
    url: (customerId, patientId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions`,
  },
  endPatientSession: {
    url: (customerId, patientId, sessionId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions/${sessionId}/end`,
  },

  getActivities: {
    url: (customerId, patientId, sessionId, activitiesId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions/${sessionId}/activities/${activitiesId}`,
  },
  updateActivitiesNotes: {
    url: (customerId, patientId, sessionId, activitiesId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions/${sessionId}/activities/${activitiesId}`,
  },
  getHcpInformation: {
    url: (customerId, patientId, sessionId, activitiesId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/patients/${patientId}/sessions/${sessionId}/activities/${activitiesId}`,
  },
  postDemographic: {
    url: (hcpId, patientId, sessionId) =>
      `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/patients/${patientId}/sessions/${sessionId}/demographic`,
  },
  getAllIsoActivities: {
    url: (hcpId, patientId, activityType, machineType) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/patients/${patientId}/activities/${activityType}/${machineType}`,
  }
};

// MACHINE
export const MACHINE_TESTING_ANGLE = Object.freeze({
  LUMBAR_TESTING_ANGLE: [0, 12, 24, 36, 48, 60, 72],
  CERVICAL_TESTING_ANGLE: [0, 18, 36, 54, 72, 90, 108, 126],
  KNEE_TESTING_ANGLE: [6, 24, 42, 60, 78, 96, 108],
  CERVICAL_ROTATION_TESTING_ANGLE: [-84, -72, -48, -24, 0, 24, 48, 72, 84],
  ROTARY_TORSO_TESTING_ANGLE: [-60, -36, -18, 0, 18, 36, 60],
});

export const MACHINE_ROM = Object.freeze({
  LUMBAR_ROM: [0, 72],
  CERVICAL_ROM: [0, 126],
  KNEE_ROM: [6, 108],
  CERVICAL_ROTATION_ROM: [-84, 84],
  ROTARY_TORSO_ROM: [-60, 60],
});

export const MACHINE_STEP = Object.freeze({
  LUMBAR_STEP: 3,
  CERVICAL_STEP: 3,
  KNEE_STEP: 6,
  CERVICAL_ROTATION_STEP: 6,
  ROTARY_TORSO_STEP: 6,
});

export const ISOMETRIC_YAXIS = Object.freeze({
  LUMBAR: [0, 75, 150, 225, 300, 375, 450],
  CERVICAL: [0, 92, 184, 276, 368, 460, 552],
  KNEE: [0, 40, 80, 120, 160, 200, 240],
  ROTARY_TORSO: [0, 37, 74, 111, 148, 185, 222],
  CERVICAL_ROTATION: [0, 49, 98, 147, 196, 245, 294],
});

export const FORMAT_DATE = 'h:mm aa, LLL d yyyy';
