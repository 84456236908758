export const DIALOGS = {
  CHANGE_PASSWORD: 'change_password_dialog',
  CHANGE_PROFILE: 'change_profile_dialog',
  CHANGE_HCP_USER_PROFILE: 'change_hcp_user_profile_dialog',
  INVITE_USER: 'invite_user_dialog',
  CONFIRM_INVITE_USER: 'confirm_invite_user_dialog',
  CONFIRM_DELETE_DIALOG: 'confirm_delete_user_dialog',
  CONFIRM_SUSPEND_DIALOG: 'confirm_suspend_user_dialog',
  CONFIRM_RESTORE_DIALOG: 'confirm_restore_user_dialog',
  INVITE_CUSTOMER: 'invite_customer_dialog',
  CONFIRM_DELETE_CUSTOMER_DIALOG: 'confirm_delete_customer_dialog',
  CONFIRM_SUSPEND_CUSTOMER_DIALOG: 'confirm_suspend_customer_dialog',
  CONFIRM_RESTORE_CUSTOMER_DIALOG: 'confirm_restore_customer_dialog',
  INVITE_HCP_USERS_DIALOG: 'invite_hcp_users_dialog',
  CONFIRM_DELETE_HCP_USER_DIALOG: 'confirm_delete_hcp_users_dialog',
  ADD_MACHINE_DIALOG: 'add_machine_dialog',
  CONFIGURE_MACHINE_DIALOG: 'configure_machine_dialog',
  ASSIGN_MACHINE_DIALOG: 'assign_machine_dialog',
  CONFIRM_REMOVE_MACHINE_DIALOG: 'confirm_remove_machine_dialog',
  ADD_PATIENT_DIALOG: 'add_patient_dialog',
  EDIT_PATIENT_DIALOG: 'edit_patient_dialog',
  SEARCH_PATIENT_DIALOG: 'search_patient_dialog',
  DEBUG_MACHINE_DIALOG: 'debug_machine_dialog',
  NEW_ROLE: 'new_role_dialog',
  CONFIRM_REMOVE_ROLE_DIALOG: 'confirm_remove_role_dialog',
  OPEN_SYNC_MODAL: 'OPEN_SYNC_MODAL',
  OPEN_HEALTH_INFORMATION: 'OPEN_HEALTH_INFORMATION',
  OPEN_MILITARY_INFORMATION: 'OPEN_MILITARY_INFORMATION',
  OPEN_CIVILIAN_INFORMATION: 'OPEN_CIVILIAN_INFORMATION',
  OPEN_ATHLETE_INFORMATION: 'OPEN_ATHLETE_INFORMATION',
  OPEN_DEMO_GRAPHIC: 'OPEN_DEMO_GRAPHIC',
  OPEN_ACTIVITY_QUESTION: 'OPEN_ACTIVITY_QUESTION',
};
