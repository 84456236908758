import { useSearchParams } from 'react-router-dom';
import LumbarDynamicTest from 'modules/Patients/containers/Machines/Lumbar/DynamicTest';
import CervicalDynamicTest from 'modules/Patients/containers/Machines/Cervical/DynamicTest';
import KneeDynamicTest from 'modules/Patients/containers/Machines/Knee/DynamicTest';
import TorsoDynamicTest from 'modules/Patients/containers/Machines/Torso/DynamicTest';
import CervicalRotationDynamicTest from 'modules/Patients/containers/Machines/CervicalRotation/DynamicTest';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearActivities } from '../../slice';
const Dynamic = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const machineType = searchParams.get('machineType');

  useEffect(() => {
    return () => {
      localStorage.removeItem('activity_id');
      dispatch(clearActivities());
    };
  }, []);

  switch (machineType) {
    case 'Lumbar':
      return <LumbarDynamicTest />;
    case 'Cervical':
      return <CervicalDynamicTest />;
    case 'Knee':
      return <KneeDynamicTest />;
    case 'Torso Rotation':
      return <TorsoDynamicTest />;
    case 'Cervical Rotation':
      return <CervicalRotationDynamicTest />;
    default:
      return null;
  }
};

export default Dynamic;
