import { useSearchParams } from 'react-router-dom';
import SetupSummary from './MachineSetupSummary';
import KneeSummary from './KneeSummary';
import TorsoSetupSummary from './TorsoSetupSummary';
import CervicalRotationSummary from './CervicalRotationSummary';
const MachineSetupSummary = () => {
  const [searchParams] = useSearchParams();
  const machineType = searchParams.get('machineType');
  switch (machineType) {
    case 'Lumbar':
      return <SetupSummary />;
    case 'Cervical':
      return <SetupSummary />;
    case 'Knee':
      return <KneeSummary />;
    case 'Torso Rotation':
      return <TorsoSetupSummary />;
    case 'Cervical Rotation':
      return <CervicalRotationSummary />;
    default:
      return null;
  }
};

export default MachineSetupSummary;
