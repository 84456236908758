import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Questions = ({
  step,
  handleCancel,
  handleNext,
  subStep,
  setupMachineData,
  firstSide,
  setFirstSide,
  testMode,
  setTestMode,
}) => {
  const [leftCheck, setLeftCheck] = useState(false);
  const [rightCheck, setRightCheck] = useState(false);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }
    setLeftCheck(false);
    setRightCheck(false);
    setDisable(false);
    return null;
  }, [setupMachineData]);

  useEffect(() => {
    if (disable) {
      return;
    }
    if (!leftCheck && !rightCheck) {
      setFirstSide(0);
      return null;
    }

    if (leftCheck === false && firstSide === 1) {
      setFirstSide(rightCheck ? 2 : 0);
      return null;
    }

    if (rightCheck === false && firstSide === 2) {
      setFirstSide(leftCheck ? 1 : 0);
      return null;
    }

    if (firstSide === 1 && leftCheck) {
      return null;
    }

    if (firstSide === 2 && rightCheck) {
      return null;
    }

    setFirstSide(leftCheck ? 1 : 2);
  }, [leftCheck, rightCheck]);

  useEffect(() => {
    if (testMode !== 0) {
      setDisable(true);
      if (testMode === 1) {
        setLeftCheck(true);
      }
      if (testMode === 2) {
        setRightCheck(true);
      }
      if (testMode === 3) {
        setRightCheck(true);
        setLeftCheck(true);
      }
    }
  }, [testMode]);

  const nextHandler = () => {
    if (leftCheck && rightCheck) {
      setTestMode(3);
    } else if (leftCheck) {
      setTestMode(1);
    } else {
      setTestMode(2);
    }

    handleNext();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <div>
            <div> 1. Which side(s) would you like to test?</div>
            <div style={{ padding: '0 20px' }}>◦ It is recommended that initial tests include testing both sides.</div>
          </div>

          <br />
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <label style={{ width: '80px' }}>
                <strong>Left Side:</strong>
              </label>
              <Checkbox disabled={disable} onClick={() => setLeftCheck(!leftCheck)} checked={leftCheck} />
            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <label style={{ width: '80px' }}>
                <strong>Right Side:</strong>
              </label>
              <Checkbox disabled={disable} onClick={() => setRightCheck(!rightCheck)} checked={rightCheck} />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>2. Which side would you like to test first?</Box>
          <div style={{ padding: '0 20px' }}>
            ◦ It is recommended that you test the affect side (aka the side that’s in pain) first.
          </div>
          <br />
          <Box sx={{ paddingLeft: '20px' }}>
            <Select
              disabled={disable || (!leftCheck && !rightCheck)}
              style={{ minWidth: '100px' }}
              value={firstSide}
              onChange={(v) => setFirstSide(Number(v.target.value))}
            >
              {leftCheck && <MenuItem value={1}>Left</MenuItem>}
              {rightCheck && <MenuItem value={2}>Right</MenuItem>}
              {!leftCheck && !rightCheck && (
                <MenuItem value={0}>
                  <em>--</em>
                </MenuItem>
              )}
            </Select>
          </Box>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleNext={nextHandler}
        handleCancel={handleCancel}
        disableNext={!leftCheck && !rightCheck}
      />
    </>
  );
};

Questions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
  firstSide: PropTypes.number,
  setFirstSide: PropTypes.func,
  testMode: PropTypes.number,
  setTestMode: PropTypes.func,
};

export default Questions;
