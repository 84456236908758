import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';
import Reps from '../../Reps';
import { useEffect, useRef, useState } from 'react';
import IsometricRechart from 'components/Charts/IsometricReChart';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useSearchParams } from 'react-router-dom';
import Actions from 'modules/Patients/components/Machines/Actions';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';
import { clearMessageQueueHandler } from 'helpers';
import FlipSwitch from 'components/FlipSwitch';

const AngleTest = ({
  step,
  handleCancel,
  handleBack,
  handleNext,
  subStep,
  machine,
  socketContext,
  firstSide,
  testMode,
  preSetAnglePractice,
  setPreSetAnglePractice,
}) => {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const [currentTarget, setCurrentTarget] = useState(0);
  const [currentGaugeValue, setCurrentGaugeValue] = useState(0);
  const [peak, setPeak] = useState(0);
  const [xAxis, setXAxis] = useState([]);
  const [isLock, setLock] = useState(false);
  const [state, setState] = useState(undefined);
  const elementTop = useRef(null);
  const elementBottom = useRef(null);
  const sessionId = searchParams.get('sessionId');
  const machineType = searchParams.get('machineType');

  const [currentSide, setCurrentSide] = useState(0);

  const [isDisableControl, setDisableControl] = useState(false);

  useEffect(() => {
    return () => {
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
      socketContext.clearMessageHistory();
    };
  }, []);

  useEffect(() => {
    if (testMode === 3) {
      setCurrentSide(firstSide === 1 ? 2 : 1);
      return null;
    }
    setCurrentSide(firstSide);
  }, [firstSide]);

  useEffect(() => {
    if (!preSetAnglePractice || !subStep) {
      return null;
    }

    setXAxis([preSetAnglePractice.s3Angle.name, preSetAnglePractice.s2Angle.name, preSetAnglePractice.s1Angle.name]);

    switch (subStep) {
      case '3':
        setData([preSetAnglePractice.s1Angle]);
        setCurrentGaugeValue(
          preSetAnglePractice.s1Angle.targetValue === undefined ? 0 : preSetAnglePractice.s1Angle.targetValue
        );
        setCurrentTarget(preSetAnglePractice.s1Angle.name);
        setState(preSetAnglePractice.s1Angle.targetValue === undefined ? 1 : undefined);
        setPeak(preSetAnglePractice.s1Angle.peak);
        setLock(preSetAnglePractice.s1Angle.targetValue !== undefined);
        setDisableControl(preSetAnglePractice.s1Angle.targetValue === undefined);
        break;
      case '4':
        setData([preSetAnglePractice.s1Angle, preSetAnglePractice.s2Angle]);
        setCurrentGaugeValue(
          preSetAnglePractice.s2Angle.targetValue === undefined ? 0 : preSetAnglePractice.s2Angle.targetValue
        );
        setCurrentTarget(preSetAnglePractice.s2Angle.name);
        setState(preSetAnglePractice.s2Angle.targetValue === undefined ? 1 : undefined);
        setPeak(preSetAnglePractice.s2Angle.peak);
        setLock(preSetAnglePractice.s2Angle.targetValue !== undefined);
        setDisableControl(preSetAnglePractice.s2Angle.targetValue === undefined);
        break;
      case '5':
        setData([preSetAnglePractice.s1Angle, preSetAnglePractice.s2Angle, preSetAnglePractice.s3Angle]);
        setCurrentGaugeValue(
          preSetAnglePractice.s3Angle.targetValue === undefined ? 0 : preSetAnglePractice.s3Angle.targetValue
        );
        setCurrentTarget(preSetAnglePractice.s3Angle.name);
        setState(preSetAnglePractice.s3Angle.targetValue === undefined ? 1 : undefined);
        setPeak(preSetAnglePractice.s3Angle.peak);
        setLock(preSetAnglePractice.s3Angle.targetValue !== undefined);
        setDisableControl(preSetAnglePractice.s3Angle.targetValue === undefined);
        break;
    }
  }, [preSetAnglePractice, subStep]);

  useEffect(() => {
    if (!state) {
      return null;
    }

    if (state === 1 && !isLock) {
      if (elementBottom.current) {
        elementBottom.current.scrollIntoView();
      }
      socketContext.sendJsonMessage({
        request: socketActions.GET_DEGREE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
      return null;
    }

    if (state === 2) {
      if (elementTop.current) {
        setTimeout(() => {
          elementTop.current.scrollIntoView();
        }, 500);
      }
      getIsometricTestHandler();
      return null;
    }
    if (state === 3) {
      const cloneData = JSON.parse(JSON.stringify(data));
      cloneData.map((data) => {
        if (data?.active) {
          data.stored = data.active;
          delete data.active;
        }
      });
      setData(cloneData);
    }

    if (state > 3) {
      setTimeout(() => {
        if (elementBottom.current) {
          elementBottom.current.scrollIntoView();
        }
      }, 500);
      const cloneData = JSON.parse(JSON.stringify(data));

      cloneData.map((data) => {
        if (Number(data.name) === Number(currentTarget)) {
          if (data?.active) {
            delete data.active;
          }

          if (data?.max) {
            data.peak = data.max;
            delete data.max;
          }
        }
      });

      setData(cloneData);
      setDisableControl(false);
      return null;
    }
  }, [state, isLock]);

  useEffect(() => {
    if (!state) {
      return;
    }

    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message) {
      return;
    }
    if (message.degree !== undefined) {
      setCurrentGaugeValue(Number(message.degree));
    } else if (message.pound) {
      const d = JSON.parse(JSON.stringify(data));

      switch (state) {
        case 2:
          for (let i = 0; i < d.length; i += 1) {
            if (Number(d[i].name) === Number(currentTarget)) {
              d[i].active = Number(Math.abs(message.pound));
            }
          }
          setData(d);
          break;
        case 3:
          setPeak(Math.abs(message.max_pound));
          for (let i = 0; i < d.length; i += 1) {
            if (Number(d[i].name) === Number(currentTarget)) {
              d[i].max = Math.abs(Number(message.max_pound));
              d[i].active = Math.abs(Number(message.pound));
            }
          }
          setData(d);
          break;

        default:
          break;
      }
    }
    if (message.done) {
      setLock(true);
      setState(state + 1);
    }
  }, [socketContext.messageHistory]);

  const nextHandler = () => {
    const newValue = JSON.parse(JSON.stringify(data));

    newValue.map((d) => {
      if (d?.active) {
        delete d.active;
      }

      if (d?.max) {
        d.peak = d.max;
        delete d.max;
      }

      if (d.name === currentTarget) {
        d.targetValue = currentGaugeValue;
      }
    });

    switch (subStep) {
      case '3':
        setPreSetAnglePractice((prevState) => ({
          ...prevState,
          s1Angle: newValue[0],
        }));
        break;
      case '4':
        setPreSetAnglePractice((prevState) => ({
          ...prevState,
          s2Angle: newValue[1],
        }));
        break;
      case '5':
        setPreSetAnglePractice((prevState) => ({
          ...prevState,
          s3Angle: newValue[2],
        }));
        break;
    }

    handleNext();
  };

  const backHandler = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
    handleBack();
  };

  const lastTestContentRender = () => {
    if (Number(subStep) === 5) {
      return (
        <Box>
          8. Disengage angle selector and move to 0°.
          <br />
          9. Engage angle selector and quickly loosen torso and head restraint.
        </Box>
      );
    }

    return '';
  };

  const getIsometricTestHandler = () => {
    const activityId = localStorage.getItem('activity_id');
    if (!machine) {
      return;
    }
    if (state > 3) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.ISOMETRIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: Number(step) === 5 ? sessionId : '',
      activity_id: Number(step) === 5 ? activityId : '',
      angle_degree: currentTarget,
    });
  };

  const chartRender = () => {
    if (!data || !xAxis) {
      return;
    }

    if (data.length === 0 || xAxis.length === 0) {
      return;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <Box sx={{ alignSelf: 'center' }}>
          <b>{currentSide === 1 ? 'Left Side' : 'Right Side'}</b>
        </Box>
        <IsometricRechart
          yAxisRange={ISOMETRIC_YAXIS.CERVICAL_ROTATION}
          xAxisRange={xAxis}
          data={data}
          reversed={currentSide === 2}
          machineType={machineType}
        />
      </div>
    );
  };
  const undoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);

    setTimeout(() => {
      setState(undefined);
      const newValue = JSON.parse(JSON.stringify(data));

      switch (subStep) {
        case '3':
          setPreSetAnglePractice((prevState) => ({
            ...prevState,
            s1Angle: {
              name: newValue[0].name,
              targetValue: undefined,
              stored: 0,
              peak: 0,
            },
          }));
          break;
        case '4':
          setPreSetAnglePractice((prevState) => ({
            ...prevState,
            s2Angle: {
              name: newValue[1].name,
              targetValue: undefined,
              stored: 0,
              peak: 0,
            },
          }));
          break;
        case '5':
          setPreSetAnglePractice((prevState) => ({
            ...prevState,
            s3Angle: {
              name: newValue[2].name,
              targetValue: undefined,
              stored: 0,
              peak: 0,
            },
          }));
          break;
      }
    }, 2000);
  };

  const orderRender = () => {
    const ordinalNumber = Number(subStep) - 2;
    let ordinals = '';

    switch (ordinalNumber) {
      case 1:
        ordinals = 'st';
        break;
      case 2:
        ordinals = 'nd';
        break;
      case 3:
        ordinals = 'rd';
        break;
      default:
        ordinals = 'th';
        break;
    }

    return `${ordinalNumber}${ordinals} practice`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box ref={elementTop}>{chartRender()}</Box>
      <Box>
        <Typography variant='body1' component='div'>
          1. Disengage the angle selector and palpitate 3-6 times through pain-free ROM. <br />
          2. Engage the angle selector at {orderRender()} practice test angle. <br /> 3.
          <FlipSwitch /> - to capture current angle.
          <br />
          4. Ask patient to relax and take a deep breath.
          <br />
          5. <FlipSwitch /> - to capture stored energy while patient is relaxed.
          <br />
          6. Ask patient to slowly begin to build force: push 25%, 50% and relax.
          <br />
          7. <FlipSwitch /> - to capture peak torque.
          <br />
          {lastTestContentRender()}
          <br />
          <br />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ maxHeight: 270 }}>
          <GaugeSlider
            value={currentGaugeValue}
            targetValue={currentTarget}
            minValue={-96}
            maxValue={96}
            isLock={isLock}
          />
        </div>

        <div>
          <Reps value={peak} title={'Peak Torque'} />
        </div>
      </Box>
      <Box ref={elementBottom}>
        <Actions
          step={step}
          subStep={subStep}
          handleCancel={handleCancel}
          handleNext={nextHandler}
          handleBack={backHandler}
          disableNext={isDisableControl}
          handleUndo={undoHandler}
        />
      </Box>
    </Box>
  );
};

AngleTest.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  testStack: PropTypes.array,
  socketContext: PropTypes.object,
  firstSide: PropTypes.number,
  testMode: PropTypes.number,
  preSetAnglePractice: PropTypes.object,
  setPreSetAnglePractice: PropTypes.func,
};

export default AngleTest;
