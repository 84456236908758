import Box from '@mui/material/Box';
import headRest from 'assets/images/SVG/Cervical/Head-rest.svg';
import torsoRestraints from 'assets/images/SVG/Cervical/Torso-restraints.svg';
import seatBelt from 'assets/images/SVG/Cervical/Chair_Seat-belt.svg';

import PropTypes from 'prop-types';
import { SingleImageStep } from 'helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';

export function Restraints({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Box>
                <SingleImageStep img={seatBelt} />
              </Box>

              <Box>
                1. Help the patient fasten and snug the seat belt and shoulder restraints.
                <br />
                2. Do not tighten completely at this point, leave enough slack to allow for seat height adjustment.
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 3 }}>
              <Box>
                <SingleImageStep img={headRest} />
              </Box>

              <Box>
                3. Adjust headrest by swinging headrest downward. <br />
                4. Patient’s head should rest against the headrest.
                <br />
                5. Patient’s hand should be crossed in their lap. Feet should be crossed at the ankles and remain still.
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 3 }}>
              <Box>
                <SingleImageStep img={torsoRestraints} />
              </Box>

              <Box>
                6. Close the gate and snug the torso restraint pads (Titan clockwise, loosen counter). Rotating pads
                should be positioned to conform with the contour of the patient’s upper.
                <br />
                7. Snug the shoulder restraints once again.
                <br />
                8. <b style={{ color: '#ed9a00' }}>Check</b>: Confirm with patient that they cannot lift their
                shoulders.
              </Box>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
Restraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
