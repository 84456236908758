import { Box, Container } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const MachineSetupContainer = ({ children }) => {
  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          {children}
        </Box>
      </Box>
    </Container>
  );
};

MachineSetupContainer.propTypes = { children: PropTypes.element };
export default MachineSetupContainer;
