import PropTypes from 'prop-types';
import MilitaryInformation from 'modules/Patients/containers/Session/DemoGraphicInformation/MilitaryInformation.jsx';
import { styled } from '@mui/material/styles';
import CivilianInformation from 'modules/Patients/containers/Session/DemoGraphicInformation/CivilianInformation.jsx';
import AthleteInformation from 'modules/Patients/containers/Session/DemoGraphicInformation/AthleteInformation.jsx';

const Container = styled('div')`
  .title {
    font-weight: 600;
    font-size: 14px;
    color: #666666;
  }
  .value {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
  }
  .row {
    width: 100%;
    display: flex;
    align-items: start;
    gap: 24px;
    padding: 48px 0;
  }
  .main-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }
`;

const DemoGraphicInformation = ({ demoGraphicInformation, patientType, onEdit }) => {
  const contentRender = () => {
    switch (patientType) {
      case 'MILITARY':
        return <MilitaryInformation demoGraphicInformation={demoGraphicInformation} onEdit={onEdit} />;
      case 'CIVILIAN':
        return <CivilianInformation demoGraphicInformation={demoGraphicInformation} onEdit={onEdit} />;
      case 'ATHLETE':
        return <AthleteInformation demoGraphicInformation={demoGraphicInformation} onEdit={onEdit} />;
      default:
        return null;
    }
  };
  return <Container>{contentRender()}</Container>;
};

DemoGraphicInformation.propTypes = {
  demoGraphicInformation: PropTypes.object,
  patientType: PropTypes.string,
  onEdit: PropTypes.func,
};

export default DemoGraphicInformation;
