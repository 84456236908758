import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { TextareaAutosize } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { clearMachineSetup, fetchMachineSetup, fetchPatient, requestSaveSetup } from '../../../slice';
import IconButton from '@mui/material/IconButton';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { Modal } from 'components/Modal';
import { InputText } from 'components/InputText';
import styled from '@emotion/styled';
import { fetchHcpMachines } from '../../../../Machines/slice';
import { SessionInfo } from '../../../components/Info';
import { isValidBase64 } from 'helpers';
import Container from '@mui/material/Container';
import SummaryControl from '../../SummaryControl';
import useCurrentUser from 'hooks/useCurrentUser';

const SummaryContainer = styled('div')`
  & .notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & .notes:hover {
    border-color: black;
  }
  & .notes:focus {
    outline-color: #ed9a00;
  }
`;

export const MachineSetupSummary = () => {
  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const patientId = id;
  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  const machineId = searchParams.get('machineId');
  const tab = searchParams.get('tab');
  const mode = searchParams.get('mode');
  const isCloseDuplicate = searchParams.get('isCloseDuplicate');
  const isBack = searchParams.get('isBack');
  const hcpId = localStorage.getItem('hcpId');
  const type = searchParams.get('type');

  const value = useSelector((state) => state.patients.setup);
  const machines = useSelector((state) => state.machines.hcpMachines);
  const patient = useSelector((state) => state.patients.patient);

  const [isEditNotes, setIsEditNotes] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [error, setError] = useState(false);
  const [setupName, setSetupName] = useState('');
  const [notes, setNote] = useState('');
  const [editModal, setEditModal] = useState(false);

  const { permissions: currentPermissions } = useCurrentUser();

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('EXECUTE_ACTIVITIES');
  };

  const hasViewActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_ACTIVITIES');
  };

  useEffect(() => {
    dispatch(fetchHcpMachines({ hcpId }));
    dispatch(
      fetchMachineSetup({
        hcpId: hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
      })
    );
    if (patientId && isValidBase64(patientId) && !patient) {
      const data = {
        patientId: atob(id),
        hcpId,
      };
      dispatch(fetchPatient(data));
    }

    return () => {
      dispatch(clearMachineSetup());
    };
  }, []);
  useEffect(() => {
    if (!value) {
      return;
    }
    setSetupName(value.setup_name || '');
    setNote(value.notes || '');
  }, [value]);

  if (!value || !machines) {
    return null;
  }

  const onNoteCancelHandler = () => {
    setIsEditNotes(false);

    setNote(value.notes || '');
  };

  const onSetupNameCancelHandler = () => {
    setIsEditName(false);
    setError(false);
    const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));
    setSetupName(
      value.setup_name ||
      `${machine.machine.machine_type}  ${machine.machine.name} - ${machine.machine.serial_no}` ||
      ''
    );
  };

  const onSaveHandler = () => {
    const data = {
      notes,
      setup_name: setupName,
    };

    const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));

    dispatch(
      requestSaveSetup({
        hcpId,
        patientId: atob(id),
        machineId: machine.machine.id,
        setupId,
        finished: true,
        data,
      })
    );

    if (!isUpdating) {
      setIsEditNotes(false);
      setIsEditName(false);
    }
  };

  const cloneHandler = () => {
    const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));
    navigate(
      `/patient/${patientId}/machine/${machine.machine.id
      }/setups/${setupId}/summary-edit?sessionId=${sessionId}&machineType=${machine.machine.machine_type}&tab=${tab || 2
      }&mode=${mode}&type=${type}`
    );
  };

  const machineNameRender = () => {
    if (!value) {
      return null;
    }
    if (!isEditName) {
      return (
        <Box sx={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            {setupName}
          </Typography>
        </Box>
      );
    }

    const saveHandler = () => {
      if (!setupName || setupName.trim().length === 0) {
        setError(true);
      } else {
        setError(false);
        onSaveHandler();
      }
    };
    const changeHandler = (value) => {
      if (!value || value.length === 0) {
        setError(true);
      } else {
        setError(false);
      }

      setSetupName(value);
    };

    return (
      <Box sx={{ display: 'flex', width: '50%', gap: 5, justifyContent: 'space-between', alignItems: 'baseline' }}>
        <InputText
          onChange={changeHandler}
          value={setupName}
          required={true}
          size={'small'}
          maxLength={255}
          errorSmg={'This field is required.'}
          isWidthFull={true}
          error={error}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onSetupNameCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
            {t('common:cancel')}
          </Button>

          <LoadingButton
            disabled={value.setup_name === setupName || (!value.setup_name && !setupName)}
            loading={isUpdating}
            onClick={saveHandler}
            size={'small'}
            variant='contained'
          >
            {t('common:save')}
          </LoadingButton>
        </Box>
      </Box>
    );
  };

  const dataRender = (data) => {
    return (
      <Box sx={{ display: 'flex', padding: '15px 0' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            {data[0].title}
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data[0].value === null ? 'N/A' : data[0].value}
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            {data[1].title}
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data[1].value === null ? 'N/A' : data[1].value}
          </Typography>
        </div>
      </Box>
    );
  };

  const sessionInfoRender = () => {
    const machine = machines.find((m) => Number(m.machine.id) === Number(machineId));
    return <SessionInfo machine={machine} patient={patient} title={t('customer:patientSession.machineSetupSummary')} />;
  };

  const footerRender = () => {
    const machine = machines.find((m) => Number(m.machine.id) === Number(machineId));
    return (
      <SummaryControl
        isBack={isBack === 'true'}
        machine={machine}
        patientId={id}
        setupId={setupId}
        sessionId={sessionId}
        disableControl={isEditName || isEditNotes}
        type={'MACHINE_SETUP'}
        setupValue={value}
        mode={Number(mode)}
      />
    );
  };
  const seatRender = () => {
    if (!value || (value && !value.data)) {
      return null;
    }

    if (value.machine_type === 'Cervical') {
      return dataRender([
        { value: value.data.seat_pos, title: 'Seat Height' },
        { value: value.data.seat_pad, title: 'Seat Pad' },
      ]);
    }

    if (value.machine_type === 'Lumbar') {
      return dataRender([
        { value: value.data.femur_restraint, title: 'Femur Restraint Position' },
        { value: value.data.seat_pad, title: 'Seat Pad' },
      ]);
    }
    return null;
  };

  if (!hasViewActivitiesPermission()) {
    return <></>;
  }
  if (!value || (value && !value.data)) {
    return null;
  }

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <Box>
            {sessionInfoRender()}
            <Box
              sx={{
                display: 'flex',
                pt: 5,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box>
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'start', padding: '15px 0 15px 0' }}>
                      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                            Name
                          </Typography>
                          {hasExecuteActivitiesPermission() && (
                            <IconButton disabled={isEditNotes} onClick={() => setIsEditName(true)} fontSize={'small'}>
                              <EditIcon fontSize={'small'} sx={isEditNotes ? { color: 'gray' } : { color: 'black' }} />
                            </IconButton>
                          )}
                        </Box>
                        {machineNameRender()}
                      </Box>

                      {isCloseDuplicate === 'false' && hasExecuteActivitiesPermission() && (
                        <Button
                          sx={{ color: '#fff', background: 'black', whiteSpace: 'nowrap' }}
                          onClick={() => setEditModal(true)}
                          disabled={isEditNotes || isEditName}
                          size={'small'}
                          variant={'contained'}
                        >
                          <span style={{ padding: '0 10px' }}>{t('common:duplicateAndEdit')}</span>
                        </Button>
                      )}
                    </Box>
                    <Box sx={{ width: '50%' }}>
                      <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                        <Typography variant='h6' sx={{ wordBreak: 'break-all', fontWeight: '500' }} component='div'>
                          Test Type
                        </Typography>
                        <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                          {t('customer:patientSession.machineSetup')}
                        </Typography>
                      </Box>

                      {seatRender()}

                      {dataRender(
                        [
                          { value: `${value.data.range_motion_min}°`, title: 'Max Extension Angle' },
                          { value: `${value.data.range_motion_max}°`, title: 'Max Flexion Angle' },
                        ],
                        '°'
                      )}
                      {dataRender([
                        {
                          value: `${value.data.dead_center === null ? 'N/A' : `${value.data.dead_center}°`}`,
                          title: 'TDC at Rest Angle',
                        },
                        { value: value.data.counterbalance_gauge, title: 'Counterweight Gauge Reading' },
                      ])}
                      <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                            Notes
                          </Typography>
                          {hasExecuteActivitiesPermission() && (
                            <IconButton disabled={isEditName} onClick={() => setIsEditNotes(true)} fontSize={'small'}>
                              <EditIcon fontSize={'small'} sx={isEditName ? { color: 'gray' } : { color: 'black' }} />
                            </IconButton>
                          )}
                        </Box>
                        {isEditNotes ? (
                          <Box>
                            <SummaryContainer>
                              <TextareaAutosize
                                onChange={(v) => {
                                  setNote(v.target.value);
                                }}
                                className={'notes'}
                                value={notes}
                                minRows={5}
                                maxLength={3000}
                                style={{
                                  width: '100%',
                                  borderColor: '#DDDFE1',
                                  borderRadius: 5,
                                  fontFamily: 'Roboto, Montserrat, sans-serif',
                                  fontSize: 16,
                                }}
                              />
                            </SummaryContainer>

                            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={onNoteCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
                                  {t('common:cancel')}
                                </Button>

                                <LoadingButton
                                  loading={isUpdating}
                                  onClick={onSaveHandler}
                                  size={'small'}
                                  variant='contained'
                                >
                                  {t('common:save')}
                                </LoadingButton>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                            {notes || '_'}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    {footerRender()}

                    <Modal
                      showModal={editModal}
                      isClose={false}
                      keep={true}
                      onClose={() => setEditModal(false)}
                      content={() =>
                        "A copy of this setup will be created for you to edit. The original setup won't be affected. Do you want to continue?"
                      }
                      extra={[
                        <Button onClick={() => setEditModal(false)} variant='outlined' key={'btn-1'}>
                          {t('common:cancel')}
                        </Button>,
                        <Button variant='contained' onClick={cloneHandler} key={'btn-2'}>
                          {t('common:ok')}
                        </Button>,
                      ]}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MachineSetupSummary;
