import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';

import Reps from '../../Reps';
import { useEffect, useRef, useState } from 'react';
import IsometricRechart from 'components/Charts/IsometricReChart';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useSearchParams } from 'react-router-dom';

import Actions from 'modules/Patients/components/Machines/Actions';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';
import { clearMessageQueueHandler } from 'helpers';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import FlipSwitch from 'components/FlipSwitch';

const SOCKET_DELAY = 500;
const MAPPING_ARRAY = [126, 108, 90, 72, 54, 36, 18, 0];

const testStackMapping = {
  3: 126,
  4: 108,
  5: 90,
  6: 72,
  7: 54,
  8: 36,
  9: 18,
  10: 0,
};
const AngleTest = ({
  step,
  handleCancel,
  handleBack,
  handleNext,
  value,
  subStep,
  machine,
  onDataChange,
  testStack,
  setupMachineData,
  sessionId,
}) => {
  const socketContext = useSocketDataContext();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();

  const [currentTarget, setCurrentTarget] = useState(0);
  const [currentGaugeValue, setCurrentGaugeValue] = useState(0);
  const [peak, setPeak] = useState(0);
  const [xAxis, setXAxis] = useState([]);
  const [isLock, setLock] = useState(false);
  const [state, setState] = useState(undefined);

  const elementTop = useRef(null);
  const elementBottom = useRef(null);

  const [isDisableControl, setDisableControl] = useState(false);
  useEffect(() => {
    return () => {
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
      socketContext.clearMessageHistory();
    };
  }, []);

  useEffect(() => {
    if (!value || !step || !testStack || !value[step]) {
      return null;
    }

    const xAxisValue = [];
    const cloneValue = JSON.parse(JSON.stringify(value[step]));
    let cloneData;
    if (Number(step) === 4) {
      cloneData = cloneValue.value;
    } else if (Number(step) === 5) {
      cloneData = JSON.parse(JSON.stringify(cloneValue.value.filter((v) => testStack.includes(v.name))));
    }

    cloneData.map((e) => {
      xAxisValue.push(e.name);
    });
    setXAxis(xAxisValue);
    setData(cloneData);
  }, [value, step, testStack]);

  useEffect(() => {
    if (!data || !subStep) {
      return null;
    }

    if (data.length === 0) {
      return null;
    }
    if (state) {
      return;
    }
    let currentData;

    if (Number(step) === 4) {
      currentData = data[Number(subStep) - 3];
    } else if (Number(step) === 5) {
      const currentTargetValue = MAPPING_ARRAY[Number(subStep) - 3];
      currentData = data.find((d) => Number(d.name) === currentTargetValue);
    }

    if (!currentData) {
      return;
    }

    setCurrentTarget(currentData.name);
    setPeak(currentData.peak);
    setCurrentGaugeValue(currentData.targetValue === undefined ? 0 : currentData.targetValue);

    setLock(currentData.targetValue !== undefined);
    setState(currentData.targetValue === undefined ? 1 : undefined);
    setDisableControl(currentData.targetValue === undefined);
  }, [data, subStep]);

  useEffect(() => {
    if (!state) {
      return;
    }

    if (state > 3) {
      setTimeout(() => {
        if (elementBottom.current) {
          elementBottom.current.scrollIntoView();
        }
      }, 500);
      const cloneData = JSON.parse(JSON.stringify(data));

      cloneData.map((data) => {
        if (Number(data.name) === Number(currentTarget)) {
          if (data?.active) {
            delete data.active;
          }

          if (data?.max) {
            data.peak = data.max;
            delete data.max;
          }
        }
      });

      setData(cloneData);
      setDisableControl(false);
      return;
    }
    switch (state) {
      case 1:
        if (!isLock) {
          if (elementBottom.current) {
            elementBottom.current.scrollIntoView();
          }
          setTimeout(() => {
            getDegreeHandler();
          }, SOCKET_DELAY);
        }

        break;
      case 2:
        if (elementTop.current) {
          setTimeout(() => {
            elementTop.current.scrollIntoView();
          }, 500);
        }
        setTimeout(() => {
          getIsometricTestHandler();
        }, SOCKET_DELAY);
        break;
      case 3:
        // eslint-disable-next-line no-case-declarations
        const cloneData = JSON.parse(JSON.stringify(data));
        cloneData.map((data) => {
          if (data?.active) {
            data.stored = data.active;
            delete data.active;
          }
        });
        setData(cloneData);
        break;
      default:
        break;
    }
  }, [state]);

  useEffect(() => {
    if (!state) {
      return;
    }

    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message) {
      return;
    }
    if (message.degree !== undefined) {
      setCurrentGaugeValue(Number(message.degree));
    } else if (message.pound) {
      const d = JSON.parse(JSON.stringify(data));

      switch (state) {
        case 2:
          for (let i = 0; i < d.length; i += 1) {
            if (Number(d[i].name) === Number(currentTarget)) {
              d[i].active = Number(Math.abs(message.pound));
            }
          }
          setData(d);
          break;
        case 3:
          setPeak(Math.abs(message.max_pound));
          for (let i = 0; i < d.length; i += 1) {
            if (Number(d[i].name) === Number(currentTarget)) {
              d[i].max = Math.abs(Number(message.max_pound));
              d[i].active = Math.abs(Number(message.pound));
            }
          }
          setData(d);
          break;

        default:
          break;
      }
    }
    if (message.done) {
      setLock(true);
      setState(state + 1);
    }
  }, [socketContext.messageHistory]);

  const getDegreeHandler = () => {
    if (isLock) {
      return;
    }

    if (!machine) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
  };

  const nextHandler = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    const newValue = JSON.parse(JSON.stringify(data));

    newValue.map((d) => {
      if (d?.active) {
        delete d.active;
      }

      if (d?.max) {
        d.peak = d.max;
        delete d.max;
      }

      if (d.name === currentTarget) {
        d.targetValue = currentGaugeValue;
      }
    });

    onDataChange({
      step: step,
      subStep: subStep,
      value: newValue,
    });

    handleNext();
  };

  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);
    setTimeout(() => {
      setState(undefined);
      const newValue = JSON.parse(JSON.stringify(value[step].value));
      for (let i = 0; i < newValue.length; i += 1) {
        if (Number(newValue[i].name) === Number(currentTarget)) {
          newValue[i].stored = 0;
          newValue[i].peak = 0;
          newValue[i].targetValue = undefined;
          break;
        }
      }

      onDataChange({
        step: step,
        subStep: subStep,
        value: newValue,
      });
    }, 2000);
  };

  const backHandler = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    handleBack();
  };

  const lastTestContentRender = () => {
    if (Number(step) === 4 && Number(subStep) === 5) {
      return '8. Move the patient to a comfortable upright position, engage the angle selector and loosen restraints.';
    }

    if (Number(step) === 5 && testStackMapping[Number(subStep)] === testStack[testStack.length - 1]) {
      return '8. Move the patient to a comfortable upright position, engage the angle selector and loosen restraints.';
    }
    return '';
  };

  const getIsometricTestHandler = () => {
    const activityId = localStorage.getItem('activity_id');
    if (!machine) {
      return;
    }
    if (state > 3) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.ISOMETRIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: Number(step) === 5 ? sessionId : '',
      activity_id: Number(step) === 5 ? activityId : '',
      angle_degree: currentTarget,
    });
  };

  const chartRender = () => {
    if (!data || !xAxis) {
      return;
    }

    if (data.length === 0 || xAxis.length === 0) {
      return;
    }
    if (currentTarget === undefined) {
      return;
    }

    const dataSrc = data.filter((d) => Number(d.name) >= Number(currentTarget));
    return (
      <div>
        <IsometricRechart
          machineType={machine?.machine.machine_type}
          yAxisRange={ISOMETRIC_YAXIS.CERVICAL}
          xAxisRange={xAxis}
          data={dataSrc}
        />
      </div>
    );
  };

  const orderRender = () => {
    const ordinalNumber = Number(subStep) - 2;
    let ordinals = '';
    let testType = '';

    switch (ordinalNumber) {
      case 1:
        ordinals = 'st';
        break;
      case 2:
        ordinals = 'nd';
        break;
      case 3:
        ordinals = 'rd';
        break;
      default:
        ordinals = 'th';
        break;
    }

    if (Number(step) === 4) {
      testType = 'practice';
    } else if (Number(step) === 5) {
      testType = 'actual';
    }

    return `${ordinalNumber}${ordinals} ${testType}`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box ref={elementTop}> {chartRender()}</Box>
      <Box>
        <Typography variant='body1' component='div'>
          1. Palpate client 3-6 times through pain-free ROM. <br />
          2. Place angle selector at {orderRender()} test angle. <br />
          3. <FlipSwitch /> - to capture current angle.
          <br />
          4. Ask patient to relax and take a deep breath.
          <br />
          5. <FlipSwitch /> - to capture stored energy while patient is relaxed.
          <br />
          6. Ask patient to slowly begin to build force: push {Number(step) === 5
            ? '25%, 50%, 75%, 100%'
            : '25%, 50%'}{' '}
          and relax.
          <br />
          7. <FlipSwitch /> - to capture peak torque.
          <br />
          {lastTestContentRender()}
          <br />
          <br />
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ maxHeight: 270 }}>
          <GaugeSlider value={currentGaugeValue} targetValue={currentTarget} maxValue={126} isLock={isLock} />
        </div>

        <div>
          <Reps value={peak} title={'Peak Torque'} />
        </div>
      </Box>
      <Box ref={elementBottom}>
        <Actions
          step={step}
          subStep={subStep}
          handleCancel={handleCancel}
          handleNext={nextHandler}
          handleBack={backHandler}
          disableNext={isDisableControl}
          handleUndo={redoHandler}
        />
      </Box>
    </Box>
  );
};

AngleTest.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  testStack: PropTypes.array,
  sessionId: PropTypes.string,
};

export default AngleTest;
