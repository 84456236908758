import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';

export const MachinesSlice = createSlice({
  name: 'machinesSlice',
  initialState: {
    error: null,
    detectedMachines: [],
    machines: [],
    hcpConfiguredMachines: [],
    hcpMachines: [],
    createdMachine: null,
    isUpdatingMachine: false,

    isUpdateSoftwareVersion: false,
    updateSoftwareVersionError: null,
    isFetchHcpConfigureMachine: false
  },
  reducers: {
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdMachine = null;
    },
    fetchMachines: (state) => {
      state.error = null;
    },
    fetchMachinesSuccess: (state, action) => {
      state.machines = action.payload;
    },
    fetchMachinesFailure: (state, action) => {
      state.machines = [];
      state.error = action.payload;
    },

    updateMachines: (state) => {
      state.error = null;
      state.isUpdatingMachine = true;
    },
    updateMachinesSuccess: (state) => {
      state.isUpdatingMachine = false;
    },
    updateMachinesFailure: (state, action) => {
      state.error = action.payload;
      state.isUpdatingMachine = false;
    },

    fetchDetectedMachines: (state) => {
      state.error = null;
    },
    fetchDetectedMachinesSuccess: (state, action) => {
      state.detectedMachines = action.payload;
    },
    fetchDetectedMachinesFailure: (state, action) => {
      state.detectedMachines = [];
      state.error = action.payload;
    },
    requestAddMachine: (state) => {
      state.error = null;
    },
    requestAddMachineSuccess: (state, action) => {
      state.createdMachine = action.payload.data;
    },
    requestAddMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestEditMachine: (state) => {
      state.isUpdatingMachine = true;
      state.error = null;
    },
    requestEditMachineSuccess: (state) => {
      state.isUpdatingMachine = false;
      state.error = null;
      // state.createdMachine = action.payload.data;
    },
    requestEditMachineFailure: (state, action) => {
      state.isUpdatingMachine = false;
      state.error = action.payload;
    },
    requestAssignMachine: (state) => {
      state.error = null;
    },
    requestAssignMachineSuccess: (state, action) => {
      state.createdMachine = action.payload.data;
    },
    requestAssignMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestRemoveMachine: (state) => {
      state.errorCode = 0;
    },
    requestRemoveMachineSuccess: () => { },
    requestRemoveMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestConfigurePreAddedMachine: (state) => {
      state.errorCode = 0;
      state.error = null;
      state.isUpdatingMachine = true;
    },
    requestConfigurePreAddedMachineSuccess: (state) => {
      state.error = null;
      state.isUpdatingMachine = false;
    },
    requestConfigurePreAddedMachineFailure: (state, action) => {
      state.error = action.payload;
      state.isUpdatingMachine = false;
    },
    // for HCP Configured Machines
    fetchHcpConfiguredMachines: (state) => {
      state.error = null;
      state.isFetchHcpConfigureMachine = true;
    },
    fetchHcpConfiguredMachinesSuccess: (state, action) => {
      state.hcpConfiguredMachines = action.payload;
      state.isFetchHcpConfigureMachine = false;
    },
    fetchHcpConfiguredMachinesFailure: (state, action) => {
      state.hcpConfiguredMachines = [];
      state.error = action.payload;
      state.isFetchHcpConfigureMachine = false;
    },
    // for HCPs Machines
    fetchHcpMachines: (state) => {
      state.error = null;
    },
    fetchHcpMachinesSuccess: (state, action) => {
      state.hcpMachines = action.payload;
    },
    fetchHcpMachinesFailure: (state, action) => {
      state.hcpMachines = [];
      state.error = action.payload;
    },

    requestUpdateSoftware: (state) => {
      state.isUpdateSoftwareVersion = true;
      state.updateSoftwareVersionError = null;
    },
    requestUpdateSoftwareSuccess: (state) => {
      state.isUpdateSoftwareVersion = false;
      state.updateSoftwareVersionError = null;
    },
    requestUpdateSoftwareFailure: (state, action) => {
      state.isUpdateSoftwareVersion = false;
      state.updateSoftwareVersionError = action.payload;
    },
    clearMachine: (state) => {
      state.machines = [];
    },
    requestLoggingRecordAction: (state) => {
    },
    requestLoggingRecordActionSuccess: (state) => {
    },
    requestLoggingRecordActionFailed: (state, action) => {
      state.requestLoggingRecordActionError = action.payload;
    }
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.error = null;
      state.createdMachine = null;
    },
  },
});
export const {
  clearSuccessAndError,
  fetchMachines,
  fetchMachinesSuccess,
  fetchMachinesFailure,
  fetchDetectedMachines,
  fetchDetectedMachinesSuccess,
  fetchDetectedMachinesFailure,
  requestAddMachine,
  requestAddMachineSuccess,
  requestAddMachineFailure,
  requestEditMachine,
  requestEditMachineSuccess,
  requestEditMachineFailure,
  requestAssignMachine,
  requestAssignMachineSuccess,
  requestAssignMachineFailure,
  requestRemoveMachine,
  requestRemoveMachineSuccess,
  requestRemoveMachineFailure,
  requestConfigurePreAddedMachine,
  requestConfigurePreAddedMachineSuccess,
  requestConfigurePreAddedMachineFailure,
  fetchHcpConfiguredMachines,
  fetchHcpConfiguredMachinesSuccess,
  fetchHcpConfiguredMachinesFailure,
  fetchHcpMachines,
  fetchHcpMachinesSuccess,
  fetchHcpMachinesFailure,
  requestUpdateSoftware,
  requestUpdateSoftwareSuccess,
  requestUpdateSoftwareFailure,
  clearMachine,
  requestLoggingRecordAction,
  requestLoggingRecordActionSuccess,
  requestLoggingRecordActionFailed,
} = MachinesSlice.actions;

export default MachinesSlice.reducer;
