import { ACCESS_TOKEN, DEVICE_UUID } from 'config/constants';
class Auth {
  static loggedIn() {
    return !!localStorage[ACCESS_TOKEN];
  }

  // save role in local storage to redirect to correct login page on force logout
  static setRole(user) {
    if (!user) return;
    localStorage.setItem('role', user.role.name);
  }

  //set hcp id to local storage so that it can be used when fetching user info
  static setHcpId(id) {
    localStorage.setItem('hcpId', id);
  }

  static setAuth(token) {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  static setHcpDeviceUuid(deviceUuid) {
    const date = new Date();
    // Get unix milliseconds at current time plus number of days
    date.setTime(+ date + (30 * 86400000)); //24 * 60 * 60 * 1000
    document.cookie = `${DEVICE_UUID}=${deviceUuid}; expires=${date.toUTCString()}; path=/`
  }

  static logOut() {
    localStorage.removeItem(ACCESS_TOKEN);
  }
}

export default Auth;
