import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';
import { useEffect, useRef, useState } from 'react';
import socketActions from 'components/WebSocketProvider/constants.js';
import { SingleImageStep } from 'helpers';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import Actions from 'modules/Patients/components/Machines/Actions';
import { clearMessageQueueHandler } from 'helpers';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
export default function SetAngle({
  setupMachineData,
  machine,
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  value,
  onDataChange,
}) {
  const socketContext = useSocketDataContext();
  const [v, setValue] = useState(0);
  const [isDisableControl, setDisable] = useState(false);

  const isRequesting = useRef(false);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (value['3'].currentTarget === undefined) {
      setValue(0);
      return;
    }

    setValue(Number(value['3'].currentTarget));
  }, [value]);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    if (value['3'].currentTarget !== undefined) {
      return;
    }

    getData();
  }, [setupMachineData, value]);

  useEffect(() => {
    return () => {
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, []);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && message.degree === undefined)) {
      return;
    }
    if (!isRequesting.current) {
      return;
    }

    setValue(message.degree);

    if (message.done) {
      setDisable(false);
      isRequesting.current = false;
    }
  }, [socketContext.messageHistory]);

  const getData = () => {
    setDisable(true);
    if (isRequesting.current) {
      return;
    }
    if (!machine) {
      return;
    }
    socketContext.clearMessageHistory();
    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    isRequesting.current = true;
  };

  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);
    setTimeout(() => {
      isRequesting.current = false;
      getData();
    }, 2000);
  };

  const nextHandler = () => {
    onDataChange({
      step: 2,
      subStep: 3,
      currentTarget: v,
    });
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    handleNext();
  };

  const backHandler = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    handleBack();
  };
  if (!setupMachineData) {
    return;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Box sx={{ display: 'flex' }}>
        <SingleImageStep img={angleSelector} />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography variant='body1' component='div'>
            1. Disengage the angle selector and passively move the patient 6-12 times through pain-free ROM.
            <br />
            2. Slowly go through this passive ROM.
            <br />
            3. Place angle selector at the maximum flexion, engage angle selector.
            <br />
            4. Unlock movement arm with Move Arm Lock (to down position).
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ maxHeight: 270 }}>
          <GaugeSlider
            value={v}
            targetValue={Number(setupMachineData.range_motion_max)}
            maxValue={126}
            isLock={isDisableControl !== undefined ? !isDisableControl : false}
            bottomInfo={`Range of Motion: ${setupMachineData.range_motion_min}°- ${setupMachineData.range_motion_max}°`}
          />
        </div>
      </Box>
      <br />
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        handleBack={backHandler}
        disableNext={isDisableControl}
        handleUndo={redoHandler}
      />
    </Box>
  );
}

SetAngle.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
};
