import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import pip from 'assets/audio/bip.mp3';
import { useEffect, useRef, useState } from 'react';
import socketActions from 'components/WebSocketProvider/constants.js';
import DynamicRechart from 'components/Charts/DynamicReChart';
import Actions from 'modules/Patients/components/Machines/Actions';
import { MACHINE_TESTING_ANGLE } from '../../../../../../constants';
import { generateYAxisArray } from 'components/Charts/utils';
import FlipSwitch from 'components/FlipSwitch';
import isNil from 'lodash/isNil';

export default function Exercise({
  setupMachineData,
  machine,
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  socketContext,
  defaultValue,
  setData,
  testMode,
  handleRemoveSkip,
  firstSide,
}) {
  const timeCounter = useRef(null);

  const [reps, setReps] = useState(0);
  const [time, setTime] = useState(0);
  const [pounds, setPounds] = useState(0);
  const [isExtension, setIsExtension] = useState(false);

  const [dataChart, setDataChart] = useState([]);
  const [isDisableControl, setDisable] = useState(false);
  const [x, setX] = useState(null);
  const [point, setPoint] = useState(0);

  const [currentSide, setCurrentSide] = useState(0);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);

  const a = useRef(null);
  const isRequesting = useRef(false);

  useEffect(() => {
    if (!defaultValue || !setupMachineData) {
      return;
    }
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    setDisable(false);
    setDataChart(defaultValue.data);
    setReps(defaultValue.reps);
    setPounds(Math.abs(defaultValue.pounds));
    setTime(defaultValue.time || 0);
    if (defaultValue.weight !== null) {
      setYAxis(generateYAxisArray(defaultValue.weight, 6));
    }

    if (defaultValue.data.length === 0 && defaultValue.time === '') {
      if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
        getData();
      }
    }
  }, [defaultValue, setupMachineData, subStep]);
  useEffect(() => {
    if (firstSide === 1) {
      setCurrentSide(subStep === '4' ? 1 : 2);
      return null;
    }

    setCurrentSide(subStep === '4' ? 2 : 1);
  }, [firstSide]);

  useEffect(() => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    return () => {
      socketContext.clearMessageHistory();
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, []);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (message.angle_degrees === undefined) {
      return;
    }

    if (timeCounter.current === null) {
      timeCounter.current = setInterval(() => {
        setTime((prevState) => {
          return Number(prevState) + 1;
        });
      }, 1000);
    }

    const index = message.angle_degrees.length - 1;

    setReps(message?.reps || 0);
    setPounds(Math.abs(message.pounds[index]));

    let newValue;

    let currValue = message.angle_degrees[index];
    if (Math.abs(message.angle_degrees[index]) > 60) {
      if (message.angle_degrees[index] < 0) {
        currValue = -60;
      } else {
        currValue = 60;
      }
    }

    if (message?.is_flexion) {
      setIsExtension(false);
      newValue = {
        name: currValue,
        flexion: Math.abs(message.pounds[index]),
      };
    } else {
      setIsExtension(true);
      newValue = {
        name: currValue,
        extension: Math.abs(message.pounds[index]),
      };
    }
    setX(currValue);

    if ((machine?.machine?.u12_id.toString() === socketContext.u12Id) && (machine.machine.mac_addr === socketContext.macAddress)) {
      setDataChart((prevState) => {
        return [newValue, ...prevState];
      });
    }

    if (message.done) {
      clearInterval(timeCounter.current);
      setDisable(false);
      isRequesting.current = false;
    }
  }, [socketContext.messageHistory]);

  useEffect(() => {
    if (dataChart.length === 0) {
      return;
    }

    setPoint(dataChart[0].name);
  }, [isExtension]);

  useEffect(() => {
    if (x === null || !isDisableControl || !dataChart.length || !setupMachineData) {
      return;
    }

    if (point <= setupMachineData.range_motion_min - 6) {
      if (x >= setupMachineData.range_motion_min - 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }
    if (point >= setupMachineData.range_motion_min + 6) {
      if (x <= setupMachineData.range_motion_min + 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point >= setupMachineData.range_motion_max + 6) {
      if (x <= setupMachineData.range_motion_max + 6) {
        a.current.play();
        setPoint(x);
        return;
      }
    }

    if (point <= setupMachineData.range_motion_max - 6) {
      if (x >= setupMachineData.range_motion_max - 6) {
        a.current.play();
        setPoint(x);
      }
    }
  }, [x, point, isExtension]);

  const getData = () => {
    setDisable(true);
    if (isRequesting.current) {
      return;
    }
    if (!machine) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.DYNAMIC_TEST,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
      session_id: '',
      activity_id: '',
      target_angle: !isNil(setupMachineData.range_motion_max) ? (rangeMotionMax - 6) : -6,
      min_angle: !isNil(setupMachineData.range_motion_min) ? (setupMachineData.range_motion_min + 6) : 6,
      weight: '',
    });
    isRequesting.current = true;
  };

  const nextHandler = () => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }
    const data = {
      data: dataChart,
      pounds,
      reps,
      time,
    };

    setData((preState) => ({ ...preState, ...data }));

    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    if ((testMode !== 3 && subStep === '4') || (testMode === 3 && subStep === '7')) {
      handleRemoveSkip(step);
    }

    handleNext();
  };
  const backHandler = () => {
    if (timeCounter.current) {
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();

    handleBack();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Typography variant='body1' component='div'>
          1. Instruct the patient to slowly begin exerting rotational force against the torso pad.
          <br />
          2. <FlipSwitch /> - When the patient begins to start the exercise and exert force.
          <br />
          3. Remind the patient to exercise through their full ROM and maintain proper speed.
          <br />
          4. Have patient perform a dynamic exercise warm-up for 6-8 reps.
          <br />
          5. Have patient return to fully rotated position.
          <br />
          6. <FlipSwitch /> - to end exercise.
          <br />
          7. Lock movement arm, then disengage angle selector and move to 0°.
          <br />
          8. Engage angle selector and quickly loosen torso and footboard restraint.
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ alignSelf: 'center' }}>
            <b>{currentSide === 1 ? 'Left Side' : 'Right Side'}</b>
          </Box>

          <Box>
            <DynamicRechart
              xTicks={MACHINE_TESTING_ANGLE.ROTARY_TORSO_TESTING_ANGLE}
              activeLine={isDisableControl ? (isExtension ? 'extension' : 'flexion') : ''}
              yTicks={yAxis}
              tooltip={false}
              data={dataChart}
              balanceMode={true}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '10px ' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Torq: `} </span> {pounds}
            </div>
          </Box>
        </div>
      </Box>
      <audio style={{ visibility: 'hidden', height: '1px' }} className='my_audio' ref={a} controls preload='none'>
        <source src={pip} type='audio/mpeg' />
      </audio>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        disableNext={isDisableControl}
        handleBack={backHandler}
      />
    </Box>
  );
}

Exercise.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
  socketContext: PropTypes.object,
  defaultValue: PropTypes.object,
  firstSide: PropTypes.number,
  testMode: PropTypes.number,
  setData: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
};
