export const IS_INJURY_ACUTE_OPTIONS = [
  { value: 'ACUTE', label: 'Acute' },
  { value: 'CHRONIC', label: 'Chronic' },
  { value: 'NOT_APPLICABLE', label: 'Not applicable' },
];

export const IS_CONDITION_COMPLEX_OPTIONS = [
  { value: 'COMPLEX', label: 'Complex' },
  { value: 'NON_COMPLEX', label: 'Non-complex' },
];

export const IS_SYMPTOMATIC_OPTIONS = [
  { value: 'SYMPTOMATIC', label: 'Symptomatic' },
  { value: 'A_SYMPTOMATIC', label: 'A-symptomatic' },
];

export const ACTIVITY_LEVEL_OPTIONS = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'AVERAGE', label: 'Average' },
  { value: 'SEDENTARY', label: 'Sedentary' },
];

export const HOUR_FOR_SLEEP_OPTIONS = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: 'MORE', label: 'More' },
];

export const MILITARY_RANK_OPTION = {
  ARMY: [
    { value: 'PRIVATE_(E-1)', label: 'Private (E-1)' },
    { value: 'PRIVATE_SECOND_CLASS_(E-2)', label: 'Private Second Class (E-2)' },
    { value: 'PRIVATE_FIRST_CLASS_(E-3)', label: 'Private First Class (E-3)' },
    { value: 'SPECIALIST/CORPORAL_(E-4)', label: 'Specialist/Corporal (E-4)' },
    { value: 'SERGEANT_(E-5)', label: 'Sergeant (E-5)' },
    { value: 'STAFF_SERGEANT_(E-6)', label: 'Staff Sergeant (E-6)' },
    { value: 'SERGEANT_FIRST_CLASS_(E-7)', label: 'Sergeant First Class (E-7)' },
    { value: 'MASTER_SERGEANT/FIRST_SERGEANT_(E-8)', label: 'Master Sergeant/First Sergeant (E-8)' },
    { value: 'SERGEANT_MAJOR/COMMAND_SERGEANT_MAJOR_(E-9)', label: 'Sergeant Major/Command Sergeant Major (E-9)' },
    { value: 'WARRANT_OFFICER_1_(W-1)', label: 'Warrant Officer 1 (W-1)' },
    { value: 'CHIEF_WARRANT_OFFICER_2_(W-2)', label: 'Chief Warrant Officer 2 (W-2)' },
    { value: 'CHIEF_WARRANT_OFFICER_3_(W-3)', label: 'Chief Warrant Officer 3 (W-3)' },
    { value: 'CHIEF_WARRANT_OFFICER_4_(W-4)', label: 'Chief Warrant Officer 4 (W-4)' },
    { value: 'CHIEF_WARRANT_OFFICER_5_(W-5)', label: 'Chief Warrant Officer 5 (W-5)' },
    { value: 'SECOND_LIEUTENANT_(O-1)', label: 'Second Lieutenant (O-1)' },
    { value: 'FIRST_LIEUTENANT_(O-2)', label: 'First Lieutenant (O-2)' },
    { value: 'CAPTAIN_(O-3)', label: 'Captain (O-3)' },
    { value: 'MAJOR_(O-4)', label: 'Major (O-4)' },
    { value: 'LIEUTENANT_COLONEL_(O-5)', label: 'Lieutenant Colonel (O-5)' },
    { value: 'COLONEL_(O-6)', label: 'Colonel (O-6)' },
    { value: 'BRIGADIER_GENERAL_(O-7)', label: 'Brigadier General (O-7)' },
    { value: 'MAJOR_GENERAL_(O-8)', label: 'Major General (O-8)' },
    { value: 'LIEUTENANT_GENERAL_(O-9)', label: 'Lieutenant General (O-9)' },
    { value: 'GENERAL_(O-10)', label: 'General (O-10)' },
  ],
  NAVY: [
    { value: 'SEAMAN_RECRUIT_(E-1)', label: 'Seaman Recruit (E-1)' },
    { value: 'SEAMAN_APPRENTICE_(E-2)', label: 'Seaman Apprentice (E-2)' },
    { value: 'SEAMAN_(E-3)', label: 'Seaman (E-3)' },
    { value: 'PETTY_OFFICER_THIRD_CLASS_(E-4)', label: 'Petty Officer Third Class (E-4)' },
    { value: 'PETTY_OFFICER_SECOND_CLASS_(E-5)', label: 'Petty Officer Second Class (E-5)' },
    { value: 'PETTY_OFFICER_FIRST_CLASS_(E-6)', label: 'Petty Officer First Class (E-6)' },
    { value: 'CHIEF_PETTY_OFFICER_(E-7)', label: 'Chief Petty Officer (E-7)' },
    { value: 'SENIOR_CHIEF_PETTY_OFFICER_(E-8)', label: 'Senior Chief Petty Officer (E-8)' },
    { value: 'MASTER_CHIEF_PETTY_OFFICER_(E-9)', label: 'Master Chief Petty Officer (E-9)' },
    { value: 'CHIEF_WARRANT_OFFICER_2_(W-2)', label: 'Chief Warrant Officer 2 (W-2)' },
    { value: 'CHIEF_WARRANT_OFFICER_3_(W-3)', label: 'Chief Warrant Officer 3 (W-3)' },
    { value: 'CHIEF_WARRANT_OFFICER_4_(W-4)', label: 'Chief Warrant Officer 4 (W-4)' },
    { value: 'CHIEF_WARRANT_OFFICER_5_(W-5)', label: 'Chief Warrant Officer 5 (W-5)' },
    { value: 'ENSIGN_(O-1)', label: 'Ensign (O-1)' },
    { value: 'LIEUTENANT_JUNIOR_GRADE_(O-2)', label: 'Lieutenant Junior Grade (O-2)' },
    { value: 'LIEUTENANT_(O-3)', label: 'Lieutenant (O-3)' },
    { value: 'LIEUTENANT_COMMANDER_(O-4)', label: 'Lieutenant Commander (O-4)' },
    { value: 'COMMANDER_(O-5)', label: 'Commander (O-5)' },
    { value: 'CAPTAIN_(O-6)', label: 'Captain (O-6)' },
    { value: 'REAR_ADMIRAL_LOWER_HALF_(O-7)', label: 'Rear Admiral Lower Half (O-7)' },
    { value: 'REAR_ADMIRAL_UPPER_HALF_(O-8)', label: 'Rear Admiral Upper Half (O-8)' },
    { value: 'VICE_ADMIRAL_(O-9)', label: 'Vice Admiral (O-9)' },
    { value: 'ADMIRAL_(O-10)', label: 'Admiral (O-10)' },
  ],
  MARINES: [
    { value: 'AIRMAN_BASIC_(E-1)', label: 'Airman Basic (E-1)' },
    { value: 'AIRMAN_(E-2)', label: 'Airman (E-2)' },
    { value: 'AIRMAN_FIRST_CLASS_(E-3)', label: 'Airman First Class (E-3)' },
    { value: 'SENIOR_AIRMAN_(E-4)', label: 'Senior Airman (E-4)' },
    { value: 'STAFF_SERGEANT_(E-5)', label: 'Staff Sergeant (E-5)' },
    { value: 'TECHNICAL_SERGEANT_(E-6)', label: 'Technical Sergeant (E-6)' },
    { value: 'MASTER_SERGEANT_(E-7)', label: 'Master Sergeant (E-7)' },
    { value: 'SENIOR_MASTER_SERGEANT_(E-8)', label: 'Senior Master Sergeant (E-8)' },
  ],
  AIR_FORCE: [
    { value: 'PRIVATE_(E-1)', label: 'Private (E-1)' },
    { value: 'PRIVATE_FIRST_CLASS_(E-2)', label: 'Private First Class (E-2)' },
    { value: 'LANCE_CORPORAL_(E-3)', label: 'Lance Corporal (E-3)' },
    { value: 'CORPORAL_(E-4)', label: 'Corporal (E-4)' },
    { value: 'SERGEANT_(E-5)', label: 'Sergeant (E-5)' },
    { value: 'STAFF_SERGEANT_(E-6)', label: 'Staff Sergeant (E-6)' },
    { value: 'GUNNERY_SERGEANT_(E-7)', label: 'Gunnery Sergeant (E-7)' },
    { value: 'MASTER_SERGEANT/FIRST_SERGEANT_(E-8)', label: 'Master Sergeant/First Sergeant (E-8)' },
    { value: 'MASTER_GUNNERY_SERGEANT/SERGEANT_MAJOR_(E-9)', label: 'Master Gunnery Sergeant/Sergeant Major (E-9)' },
    { value: 'WARRANT_OFFICER_1_(W-1)', label: 'Warrant Officer 1 (W-1)' },
    { value: 'CHIEF_WARRANT_OFFICER_2_(W-2)', label: 'Chief Warrant Officer 2 (W-2)' },
    { value: 'CHIEF_WARRANT_OFFICER_3_(W-3)', label: 'Chief Warrant Officer 3 (W-3)' },
    { value: 'CHIEF_WARRANT_OFFICER_4_(W-4)', label: 'Chief Warrant Officer 4 (W-4)' },
    { value: 'CHIEF_WARRANT_OFFICER_5_(W-5)', label: 'Chief Warrant Officer 5 (W-5)' },
    { value: 'SECOND_LIEUTENANT_(O-1)', label: 'Second Lieutenant (O-1)' },
    { value: 'FIRST_LIEUTENANT_(O-2)', label: 'First Lieutenant (O-2)' },
    { value: 'CAPTAIN_(O-3)', label: 'Captain (O-3)' },
    { value: 'MAJOR_(O-4)', label: 'Major (O-4)' },
    { value: 'LIEUTENANT_COLONEL_(O-5)', label: 'Lieutenant Colonel (O-5)' },
    { value: 'COLONEL_(O-6)', label: 'Colonel (O-6)' },
    { value: 'BRIGADIER_GENERAL_(O-7)', label: 'Brigadier General (O-7)' },
    { value: 'MAJOR_GENERAL_(O-8)', label: 'Major General (O-8)' },
    { value: 'LIEUTENANT_GENERAL_(O-9)', label: 'Lieutenant General (O-9)' },
    { value: 'GENERAL_(O-10)', label: 'General (O-10)' },
  ],
  COAST_GUARD: [
    { value: 'SEAMAN_RECRUIT_(SR)', label: 'Seaman Recruit (SR)' },
    { value: 'SEAMAN_APPRENTICE_(SA)', label: 'Seaman Apprentice (SA)' },
    { value: 'SEAMAN_(SN)', label: 'Seaman (SN)' },
    { value: 'PETTY_OFFICER_THIRD_CLASS_(PO3)', label: 'Petty Officer Third Class (PO3)' },
    { value: 'PETTY_OFFICER_SECOND_CLASS_(PO2)', label: 'Petty Officer Second Class (PO2)' },
    { value: 'PETTY_OFFICER_FIRST_CLASS_(PO1)', label: 'Petty Officer First Class (PO1)' },
    { value: 'CHIEF_PETTY_OFFICER_(CPO)', label: 'Chief Petty Officer (CPO)' },
    { value: 'SENIOR_CHIEF_PETTY_OFFICER_(SCPO)', label: 'Senior Chief Petty Officer (SCPO)' },
    { value: 'MASTER_CHIEF_PETTY_OFFICER_(MCPO)', label: 'Master Chief Petty Officer (MCPO)' },
    { value: 'COMMAND_MASTER_CHIEF_PETTY_OFFICER_(CMC)', label: 'Command Master Chief Petty Officer (CMC)' },
    {
      value: 'MASTER_CHIEF_PETTY_OFFICER_OF_THE_COAST_GUARD_(MCPOCG)',
      label: 'Master Chief Petty Officer of the Coast Guard (MCPOCG)',
    },
    { value: 'WARRANT_OFFICER_1_(WO1)', label: 'Warrant Officer 1 (WO1)' },
    { value: 'CHIEF_WARRANT_OFFICER_2_(CWO2)', label: 'Chief Warrant Officer 2 (CWO2)' },
    { value: 'CHIEF_WARRANT_OFFICER_3_(CWO3)', label: 'Chief Warrant Officer 3 (CWO3)' },
    { value: 'CHIEF_WARRANT_OFFICER_4_(CWO4)', label: 'Chief Warrant Officer 4 (CWO4)' },
    { value: 'CHIEF_WARRANT_OFFICER_5_(CWO5)', label: 'Chief Warrant Officer 5 (CWO5)' },
    { value: 'ENSIGN_(ENS)', label: 'Ensign (ENS)' },
    { value: 'LIEUTENANT_JUNIOR_GRADE_(LTJG)', label: 'Lieutenant Junior Grade (LTJG)' },
    { value: 'LIEUTENANT_(LT)', label: 'Lieutenant (LT)' },
    { value: 'LIEUTENANT_COMMANDER_(LCDR)', label: 'Lieutenant Commander (LCDR)' },
    { value: 'COMMANDER_(CDR)', label: 'Commander (CDR)' },
    { value: 'CAPTAIN_(CAPT)', label: 'Captain (CAPT)' },
    { value: 'REAR_ADMIRAL_LOWER_HALF_(RDML)', label: 'Rear Admiral Lower Half (RDML)' },
    { value: 'REAR_ADMIRAL_UPPER_HALF_(RADM)', label: 'Rear Admiral Upper Half (RADM)' },
    { value: 'VICE_ADMIRAL_(VADM)', label: 'Vice Admiral (VADM)' },
    { value: 'ADMIRAL_(ADM)', label: 'Admiral (ADM)' },
  ],
};

export const MILITARY_ROLE_OPTION = {
  ARMY: [
    { value: 'INFANTRY', label: 'Infantry' },
    { value: 'ARMOR', label: 'Armor' },
    { value: 'ARTILLERY', label: 'Artillery' },
    { value: 'AVIATION', label: 'Aviation' },
    { value: 'ENGINEERING', label: 'Engineering' },
    { value: 'MILITARY_POLICE', label: 'Military Police' },
    { value: 'INTELLIGENCE', label: 'Intelligence' },
    { value: 'LOGISTICS', label: 'Logistics' },
    { value: 'SIGNAL_CORPS', label: 'Signal Corps' },
    { value: 'SPECIAL_OPERATIONS', label: 'Special Operations' },
  ],
  NAVY: [
    { value: 'SURFACE_WARFARE', label: 'Surface Warfare' },
    { value: 'SUBMARINE_WARFARE', label: 'Submarine Warfare' },
    { value: 'AVIATION', label: 'Aviation' },
    { value: 'SPECIAL_OPERATIONS', label: 'Special Operations' },
    { value: 'INTELLIGENCE', label: 'Intelligence' },
    { value: 'INFORMATION_WARFARE', label: 'Information Warfare' },
    { value: 'LOGISTICS', label: 'Logistics' },
    { value: 'MEDICAL_CORPS', label: 'Medical Corps' },
    { value: 'NAVY_SEALS', label: 'Navy SEALs' },
    { value: 'EXPLOSIVE_ORDNANCE_DISPOSAL_(EOD)', label: 'Explosive Ordnance Disposal (EOD)' },
  ],
  MARINES: [
    { value: 'INFANTRY', label: 'Infantry' },
    { value: 'ARMOR', label: 'Armor' },
    { value: 'AVIATION', label: 'Aviation' },
    { value: 'ARTILLERY', label: 'Artillery' },
    { value: 'COMBAT_ENGINEERS', label: 'Combat Engineers' },
    { value: 'COMMUNICATIONS', label: 'Communications' },
    { value: 'INTELLIGENCE', label: 'Intelligence' },
    { value: 'LOGISTICS', label: 'Logistics' },
    { value: 'RECONNAISSANCE', label: 'Reconnaissance' },
    { value: 'SPECIAL_OPERATIONS', label: 'Special Operations' },
  ],
  AIR_FORCE: [
    { value: 'AIRCRAFT_MAINTENANCE', label: 'Aircraft Maintenance' },
    { value: 'AIR_TRAFFIC_CONTROL', label: 'Air Traffic Control' },
    { value: 'COMMUNICATIONS', label: 'Communications' },
    { value: 'CYBER_OPERATIONS', label: 'Cyber Operations' },
    { value: 'INTELLIGENCE', label: 'Intelligence' },
    { value: 'LOGISTICS', label: 'Logistics' },
    { value: 'MEDICAL_CORPS', label: 'Medical Corps' },
    { value: 'PARARESCUE', label: 'Pararescue' },
    { value: 'PILOT', label: 'Pilot' },
    { value: 'SECURITY_FORCES', label: 'Security Forces' },
  ],
  COAST_GUARD: [
    { value: 'AVIATION', label: 'Aviation' },
    { value: "BOATSWAIN'S_MATE", label: "Boatswain's Mate" },
    { value: 'ENGINEERING', label: 'Engineering' },
    { value: 'INTELLIGENCE', label: 'Intelligence' },
    { value: 'MARITIME_LAW_ENFORCEMENT', label: 'Maritime Law Enforcement' },
    { value: 'MARITIME_SAFETY_AND_SECURITY', label: 'Maritime Safety and Security' },
    { value: 'MEDICAL_CORPS', label: 'Medical Corps' },
    { value: 'NAVIGATION_AND_SEAMANSHIP', label: 'Navigation and Seamanship' },
    { value: 'OPERATIONS_SPECIALIST', label: 'Operations Specialist' },
    { value: 'SEARCH_AND_RESCUE', label: 'Search and Rescue' },
  ],
};

export const MILITARY_BRANCH_OF_SERVICE_OPTION = [
  { value: 'ARMY', label: 'Army' },
  { value: 'NAVY', label: 'Navy' },
  { value: 'MARINES', label: 'Marines' },
  { value: 'AIR_FORCE', label: 'Air Force' },
  { value: 'COAST_GUARD', label: 'Coast Guard' },
];

export const CIVILIAN_EMPLOYMENT_STATUS_OPTION = [
  { value: 'FULL-TIME', label: 'Full-time' },
  { value: 'PART-TIME', label: 'Part-time' },
  { value: 'TEMPORARY', label: 'Temporary' },
  { value: 'CONTRACT', label: 'Contract' },
];

export const CIVILIAN_OCCUPATION_OPTION = [
  { value: 'FIREFIGHTER', label: 'Firefighter' },
  { value: 'POLICE_OFFICER', label: 'Police officer' },
  { value: 'CORRECTIONAL_OFFICER', label: 'Correctional officer' },
  { value: 'EMERGENCY_MEDICAL_TECHNICIAN', label: 'Emergency medical technician' },
  { value: 'PARAMEDIC', label: 'Paramedic' },
  { value: 'SECURITY_GUARD', label: 'Security guard' },
  { value: 'ASSEMBLER', label: 'Assembler' },
  { value: 'PRODUCTION_WORKER', label: 'Production worker' },
  { value: 'WAREHOUSE_WORKER', label: 'Warehouse worker' },
  { value: 'CONSTRUCTION_WORKER', label: 'Construction worker' },
  { value: 'ELECTRICIAN', label: 'Electrician' },
  { value: 'CARPENTER', label: 'Carpenter' },
  { value: 'PLUMBER', label: 'Plumber' },
  { value: 'WELDER', label: 'Welder' },
  { value: 'ROOFER', label: 'Roofer' },
  { value: 'MASON', label: 'Mason' },
  { value: 'HEAVY_EQUIPMENT_OPERATOR', label: 'Heavy equipment operator' },
  { value: 'PAINTER', label: 'Painter' },
  { value: 'LANDSCAPER', label: 'Landscaper' },
  { value: 'HVAC_TECHNICIAN', label: 'HVAC technician' },
  { value: 'MACHINIST', label: 'Machinist' },
  { value: 'IRONWORKER', label: 'Ironworker' },
  { value: 'PIPEFITTER', label: 'Pipefitter' },
  { value: 'SHEET_METAL_WORKER', label: 'Sheet metal worker' },
  { value: 'BOILERMAKER', label: 'Boilermaker' },
  { value: 'STEELWORKER', label: 'Steelworker' },
  { value: 'CEMENT_MASON', label: 'Cement mason' },
  { value: 'GLAZIER', label: 'Glazier' },
  { value: 'INSULATOR', label: 'Insulator' },
  { value: 'MATERIAL_HANDLER', label: 'Material handler' },
  { value: 'GENERAL_LABORER', label: 'General laborer' },
  { value: 'FARM_WORKER', label: 'Farm worker' },
  { value: 'AUTOMOTIVE_TECHNICIAN', label: 'Automotive technician' },
  { value: 'MECHANIC', label: 'Mechanic' },
  { value: 'PLASTERER', label: 'Plasterer' },
  { value: 'TILE_SETTER', label: 'Tile setter' },
  { value: 'CONCRETE_FINISHER', label: 'Concrete finisher' },
  { value: 'DRYWALL_INSTALLER', label: 'Drywall installer' },
  { value: 'DEMOLITION_WORKER', label: 'Demolition worker' },
  { value: 'OIL_RIG_WORKER', label: 'Oil rig worker' },
  { value: 'MINER', label: 'Miner' },
  { value: 'LOGGING_WORKER', label: 'Logging worker' },
  { value: 'TRUCK_DRIVER', label: 'Truck driver' },
  { value: 'DELIVERY_DRIVER', label: 'Delivery driver' },
  { value: 'WASTE_MANAGEMENT_WORKER', label: 'Waste management worker' },
  { value: 'REFUSE_COLLECTOR', label: 'Refuse collector' },
  { value: 'MOVER', label: 'Mover' },
  { value: 'JANITOR', label: 'Janitor' },
  { value: 'HOUSEKEEPER', label: 'Housekeeper' },
  { value: 'GROUNDSKEEPER', label: 'Groundskeeper' },
  { value: 'EMERGENCY_MEDICAL_TECHNICIAN', label: 'Emergency medical technician' },
  { value: 'NURSE', label: 'Nurse' },
  { value: 'PAINTER', label: 'Painter' },
  { value: 'CARPENTER', label: 'Carpenter' },
  { value: 'HEAVY_EQUIPMENT_OPERATOR', label: 'Heavy equipment operator' },
  { value: 'OTHER', label: 'Other' },
];

export const CIVILIAN_JOB_FUNC_STATUS = [
  { value: 'ADMINISTRATIVE', label: 'Administrative' },
  { value: 'MANAGEMENT', label: 'Management' },
  { value: 'MECHANIC', label: 'Mechanic' },
  { value: 'TECHNICIAN', label: 'Technician' },
  { value: 'MANUFACTURER', label: 'Manufacturer' },
  { value: 'SKILLED_WORKER', label: 'Skilled Worker' },
  { value: 'LABORER', label: 'Laborer' },
];

export const CIVILIAN_FIRER_STATUS = [
  {
    value: 'PROBATIONARY_FIREFIGHTER_(SOMETIMES_REFERRED_TO_AS_RECRUIT)',
    label: 'Probationary Firefighter (sometimes referred to as Recruit)',
  },
  { value: 'FIREFIGHTER', label: 'Firefighter' },
  { value: 'DRIVER/ENGINEER', label: 'Driver/Engineer' },
  { value: 'LIEUTENANT', label: 'Lieutenant' },
  { value: 'CAPTAIN', label: 'Captain' },
  { value: 'BATTALION_CHIEF', label: 'Battalion Chief' },
  { value: 'DEPUTY_CHIEF', label: 'Deputy Chief' },
  { value: 'ASSISTANT_CHIEF', label: 'Assistant Chief' },
  { value: 'FIRE_CHIEF', label: 'Fire Chief' },
  { value: 'CADET', label: 'Cadet'}
];

export const ATHLETE_POSITION_OPTIONS = {
  'FOOTBALL_(AMERICAN)': [
    { value: 'QUARTERBACK', label: 'Quarterback' },
    { value: 'RUNNING_BACK', label: 'Running Back' },
    { value: 'WIDE_RECEIVER', label: 'Wide Receiver' },
    { value: 'TIGHT_END', label: 'Tight End' },
    { value: 'OFFENSIVE_LINEMAN', label: 'Offensive Lineman' },
    { value: 'DEFENSIVE_LINEMAN', label: 'Defensive Lineman' },
    { value: 'LINEBACKER', label: 'Linebacker' },
    { value: 'CORNERBACK', label: 'Cornerback' },
    { value: 'SAFETY', label: 'Safety' },
    { value: 'KICKER', label: 'Kicker' },
    { value: 'PUNTER', label: 'Punter' },
  ],
  BASKETBALL: [
    { value: 'POINT_GUARD', label: 'Point Guard' },
    { value: 'SHOOTING_GUARD', label: 'Shooting Guard' },
    { value: 'SMALL_FORWARD', label: 'Small Forward' },
    { value: 'POWER_FORWARD', label: 'Power Forward' },
    { value: 'CENTER', label: 'Center' },
  ],
  BASEBALL: [
    { value: 'PITCHER', label: 'Pitcher' },
    { value: 'CATCHER', label: 'Catcher' },
    { value: 'FIRST_BASEMAN', label: 'First Baseman' },
    { value: 'SECOND_BASEMAN', label: 'Second Baseman' },
    { value: 'THIRD_BASEMAN', label: 'Third Baseman' },
    { value: 'SHORTSTOP', label: 'Shortstop' },
    { value: 'OUT_FIELDER', label: 'Out Fielder' },
    { value: 'DESIGNATED_HITTER', label: 'Designated Hitter' },
  ],
  SOFTBALL: [
    { value: 'PITCHER', label: 'Pitcher' },
    { value: 'CATCHER', label: 'Catcher' },
    { value: 'FIRST_BASEMAN', label: 'First Baseman' },
    { value: 'SECOND_BASEMAN', label: 'Second Baseman' },
    { value: 'THIRD_BASEMAN', label: 'Third Baseman' },
    { value: 'SHORTSTOP', label: 'Shortstop' },
    { value: 'LEFT_FIELDER', label: 'Left Fielder' },
    { value: 'CENTER_FIELDER', label: 'Center Fielder' },
    { value: 'RIGHT_FIELDER', label: 'Right Fielder' },
  ],
  SOCCER: [
    { value: 'GOALKEEPER', label: 'Goalkeeper' },
    { value: 'DEFENDER', label: 'Defender' },
    { value: 'MIDFIELDER', label: 'Midfielder' },
    { value: 'FORWARD', label: 'Forward' },
    { value: 'ICE_HOCKEY', label: 'Ice Hockey' },
    { value: 'GOALTENDER', label: 'Goaltender' },
    { value: 'DEFENSEMAN', label: 'Defenseman' },
    { value: 'CENTER', label: 'Center' },
    { value: 'WINGER', label: 'Winger' },
  ],
  VOLLEYBALL: [
    { value: 'SETTER', label: 'Setter' },
    { value: 'OUTSIDE_HITTER', label: 'Outside Hitter' },
    { value: 'MIDDLE_BLOCKER', label: 'Middle Blocker' },
    { value: 'OPPOSITE_HITTER', label: 'Opposite Hitter' },
    { value: 'LIBERO', label: 'Libero' },
  ],
  'TRACK_&_FIELD': [
    { value: 'SPRINTS', label: 'Sprints' },
    { value: 'MIDDLE_DISTANCE', label: 'Middle Distance' },
    { value: 'LONG_DISTANCE', label: 'Long Distance' },
    { value: 'HURDLES', label: 'Hurdles' },
    { value: 'JUMPS', label: 'Jumps' },
    { value: 'THROWS', label: 'Throws' },
    { value: 'HEPTATHLETE', label: 'Heptathlete' },
    { value: 'DECATHLETE', label: 'Decathlete' },
    { value: 'RACEWALKER', label: 'Racewalker' },
  ],
  LACROSSE: [
    { value: 'GOALIE', label: 'Goalie' },
    { value: 'DEFENSEMAN', label: 'Defenseman' },
    { value: 'MIDFIELDER', label: 'Midfielder' },
    { value: 'ATTACKMAN', label: 'Attackman' },
  ],
  CHEERLEADING: [
    { value: 'FLYER', label: 'Flyer' },
    { value: 'BASE', label: 'Base' },
    { value: 'BACKSPOT', label: 'Backspot' },
  ],
  RUGBY: [
    { value: 'PROP', label: 'Prop' },
    { value: 'HOOKER', label: 'Hooker' },
    { value: 'LOCK', label: 'Lock' },
    { value: 'FLANKER', label: 'Flanker' },
    { value: 'NUMBER_8', label: 'Number 8' },
    { value: 'SCRUM-HALF', label: 'Scrum-half' },
    { value: 'FLY-HALF', label: 'Fly-half' },
    { value: 'CENTER', label: 'Center' },
    { value: 'WING', label: 'Wing' },
    { value: 'FULLBACK', label: 'Fullback' },
  ],
  WATER_POLO: [
    { value: 'GOALKEEPER', label: 'Goalkeeper' },
    { value: 'LEFT/RIGHT_DRIVER', label: 'Left/Right Driver' },
    { value: 'LEFT/RIGHT_WING', label: 'Left/Right Wing' },
    { value: 'CENTER_FORWARD', label: 'Center Forward' },
    { value: 'CENTER_BACK', label: 'Center Back' },
  ],
  ROWING: [
    { value: 'COXSWAIN', label: 'Coxswain' },
    { value: 'STROKE', label: 'Stroke' },
    { value: 'BOW', label: 'Bow' },
  ],
};

export const ATHLETE_PRACTICE_SPORT_OPTION = [
  {
    value: 'FOOTBALL_(AMERICAN)',
    label: 'Football (American)',
  },
  {
    value: 'BASKETBALL',
    label: 'Basketball',
  },
  {
    value: 'BASEBALL',
    label: 'Baseball',
  },
  {
    value: 'SOFTBALL',
    label: 'Softball',
  },
  {
    value: 'SOCCER',
    label: 'Soccer',
  },
  {
    value: 'TENNIS',
    label: 'Tennis',
  },
  {
    value: 'GOLF',
    label: 'Golf',
  },
  {
    value: 'WRESTLING',
    label: 'Wrestling',
  },
  {
    value: 'BOXING',
    label: 'Boxing',
  },
  {
    value: 'MMA',
    label: 'MMA',
  },
  {
    value: 'VOLLEYBALL',
    label: 'Volleyball',
  },
  {
    value: 'TRACK_&_FIELD',
    label: 'Track & Field',
  },
  {
    value: 'AUTO_RACING',
    label: 'Auto Racing',
  },
  {
    value: 'SWIMMER',
    label: 'Swimmer',
  },
  {
    value: 'GYMNASTICS',
    label: 'Gymnastics',
  },
  {
    value: 'LACROSSE',
    label: 'Lacrosse',
  },
  {
    value: 'BOWLING',
    label: 'Bowling',
  },
  {
    value: 'CYCLING',
    label: 'Cycling',
  },
  {
    value: 'FIGURE_SKATING',
    label: 'Figure Skating',
  },
  {
    value: 'CHEERLEADING',
    label: 'Cheerleading',
  },
  {
    value: 'DANCE',
    label: 'Dance',
  },
  {
    value: 'RUGBY',
    label: 'Rugby',
  },
  {
    value: 'WATER_POLO',
    label: 'Water Polo',
  },
  {
    value: 'ROWING',
    label: 'Rowing',
  },
  {
    value: 'EQUESTRIAN',
    label: 'Equestrian',
  },
  {
    value: 'SKIING_/_SNOWBOARDING',
    label: 'Skiing / Snowboarding',
  },
];

export const ATHLETE_LEVEL_OPTIONS = [
  { value: 'HIGH_SCHOOL', label: 'High School' },
  { value: 'COLLEGE', label: 'College' },
  { value: 'AMATEUR', label: 'Amateur' },
  { value: 'PROFESSIONAL', label: 'Professional' },
  { value: 'HIGHEST', label: 'Highest' },
  { value: 'PROFESSIONAL_LEVEL', label: 'Professional Level' },
];
