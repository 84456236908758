import { useSearchParams } from 'react-router-dom';
import EditSummary from './EditSummary';
import KneeEditSummary from './KneeEditSummary';
import TorsoEditSummary from './TorsoEditSummary';
import CervicalRotationEditSummary from './CervicalRotationEditSummary';
const DuplicateAndEdit = () => {
  const [searchParams] = useSearchParams();
  const machineType = searchParams.get('machineType');
  switch (machineType) {
    case 'Lumbar':
      return <EditSummary />;
    case 'Cervical':
      return <EditSummary />;
    case 'Knee':
      return <KneeEditSummary />;
    case 'Torso Rotation':
      return <TorsoEditSummary />;
    case 'Cervical Rotation':
      return <CervicalRotationEditSummary />;

    default:
      return null;
  }
};

export default DuplicateAndEdit;
