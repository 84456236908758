import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';

export const PermsSlice = createSlice({
  name: 'permsSlice',
  initialState: {
    errorCode: 0,
    error: null,
    hcpRoles: [],
    itemPerPage: 10,
    totalUsers: 0,
    createdRole: null,
  },
  reducers: {
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdRole = null;
    },
    fetchHcpRoles: (state) => {
      state.errorCode = 0;
    },
    fetchHcpRolesSuccess: (state, action) => {
      state.hcpRoles = action.payload;
    },
    fetchHcpRolesFailure: (state, action) => {
      state.hcpRoles = [];
      state.errorCode = action.payload.code;
    },
    requestCreateRole: (state) => {
      state.error = null;
    },
    requestCreateRoleSuccess: (state, action) => {
      state.createdRole = action.payload.data;
    },
    requestCreateRoleFailure: (state, action) => {
      state.error = action.payload;
    },
    requestEditRole: (state) => {
      state.error = null;
    },
    requestEditRoleSuccess: (state, action) => {
      state.createdRole = action.payload.data;
    },
    requestEditRoleFailure: (state, action) => {
      state.error = action.payload;
    },
    requestRemoveRole: (state) => {
      state.errorCode = 0;
    },
    requestRemoveRoleSuccess: () => {},
    requestRemoveRoleFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.errorCode = 0;
      state.error = null;
      state.createdRole = null;
    },
  },
});
export const {
  clearSuccessAndError,
  fetchHcpRoles,
  fetchHcpRolesSuccess,
  fetchHcpRolesFailure,
  requestCreateRole,
  requestCreateRoleSuccess,
  requestCreateRoleFailure,
  requestEditRole,
  requestEditRoleSuccess,
  requestEditRoleFailure,
  requestRemoveRole,
  requestRemoveRoleSuccess,
  requestRemoveRoleFailure,
} = PermsSlice.actions;

export default PermsSlice.reducer;
