import Box from '@mui/material/Box';
import { SingleImageStep } from 'helpers';
import Typography from '@mui/material/Typography';
import seatBelt from 'assets/images/SVG/Cervical/Chair_Seat-belt.svg';
import torsoRestraints from 'assets/images/SVG/Cervical/Torso-restraints.svg';
import PropTypes from 'prop-types';
import Actions from 'modules/Patients/components/Machines/Actions';

export default function PatientAdjustments({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box>
          <SingleImageStep img={seatBelt} />
        </Box>

        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant='body1' component='div'>
            1. Make sure patient is snug in the machine.
            <br />
            2. Snug the shoulder restraints once again.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          my: 4,
          display: 'flex',
        }}
      >
        <Box>
          <SingleImageStep img={torsoRestraints} />
        </Box>

        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant='body1' component='div'>
            3. Snug the torso restraint pads.
            <br />
            4. Confirm with patient that they cannot lift their shoulders.
          </Typography>
        </Box>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </>
  );
}
PatientAdjustments.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
