import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useEffect, useState } from 'react';
import step1 from 'assets/images/png/preparation/step1.png';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
import { clearMessageQueueHandler } from 'helpers';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import FlipSwitch from 'components/FlipSwitch';
const SetAngleSelector = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  machine,
  onDataChange,
  setupMachineData,
  value,
}) => {
  const socketContext = useSocketDataContext();
  const [currentAngle, setCurrentAngle] = useState(0);
  const [isLock, setLock] = useState(false);

  useEffect(() => {
    return () => {
      socketContext.clearMessageHistory();
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, []);

  useEffect(() => {
    if (!machine || (machine && Object.keys(machine).length === 0)) {
      return null;
    }

    if (value.angle !== undefined) {
      setLock(true);
      setCurrentAngle(value.angle);
      return null;
    }

    setLock(false);
    setCurrentAngle(0);
    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
  }, [value, machine]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }
    if (message.degree !== undefined) {
      setCurrentAngle(Number(message.degree));
    }
    if (message.done) {
      setLock(true);
    }
  }, [socketContext.messageHistory]);

  const backHandler = () => {
    socketContext.clearMessageHistory();
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    handleBack();
  };
  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);
    setTimeout(() => {
      setLock(false);
      socketContext.sendJsonMessage({
        request: socketActions.GET_DEGREE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }, 2000);
  };

  const nextHandler = () => {
    onDataChange({
      angle: currentAngle,
    });

    handleNext();
  };

  if (!setupMachineData) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step1} />
          </Box>
          <Typography variant='body1' component='div'>
            1. Disengage the angle selector and palpitate 6-12 times through pain-free ROM.
            <br />
            2. Slowly go through this passive ROM.
            <br />
            3. Place angle selector at the maximum flexion.
            <br />
            4.
            <FlipSwitch /> - to confirm angle.
            <br />
            5. Unlock movement arm with Move Arm Lock.
          </Typography>
        </Box>

        <Box>
          <div style={{ maxHeight: 270 }}>
            <GaugeSlider
              value={currentAngle}
              targetValue={Number(setupMachineData.range_motion_max)}
              maxValue={72}
              isLock={isLock}
              bottomInfo={`Range of Motion: ${setupMachineData.range_motion_min}°- ${setupMachineData.range_motion_max}°`}
            />
          </div>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableNext={!isLock}
        handleUndo={redoHandler}
      />
    </Box>
  );
};

SetAngleSelector.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
};

export default SetAngleSelector;
