import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dateParserWithTimezone, getTimeDate } from 'helpers/datetime';
import ConfirmDialog from 'components/ConfirmDialog/index.jsx';
import useCurrentUser from 'hooks/useCurrentUser';

import Box from '@mui/material/Box';
import { Button } from 'components/Button/index.jsx'; //custom button component
import Typography from '@mui/material/Typography';
import {
  fetchPatientSession,
  fetchPatientSessions,
  requestCreateSession,
  updateDemoGraphicQuestion,
} from 'modules/Patients/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';

import { format } from 'date-fns';
import { removeSensitiveData } from 'helpers';
import DemoGraphicModal from 'modules/DemoGraphic/index.jsx';

export const CallOutWrapper = styled('div')`
  width: 100%;
  display: flex;
  padding: 8px;
  margin: 8px 0px;
  border: 1px solid #e2e4e7;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px solid orange;
    background-color: #fff0d5;
  }
`;

function SessionItem({ session, handleOpenSessionDetails, disableHover }) {
  return (
    <CallOutWrapper style={disableHover ? { cursor: 'default' } : {}} onClick={() => handleOpenSessionDetails(session)}>
      <Box>
        <Typography sx={{ p: 0.5 }} variant='p' component='div'>
          <strong>{session.name}</strong>
        </Typography>
        <Typography sx={{ p: 0.5, fontSize: 14 }} variant='p' component='div'>
          Start: {getTimeDate(session.initiated_at)} {session.ended_at && `- End: ${getTimeDate(session.ended_at)}`}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'right', ml: 'auto' }}>
        <Typography sx={{ p: 0.5 }} variant='p' component='div'>
          {session.total_activities} Activit{session.total_activities > 0 ? 'ies' : 'y'}
        </Typography>
        <Typography sx={{ p: 0.5, fontSize: 14 }} variant='p' component='div'>
          Total time: {session.total_time} min{session.total_time > 1 ? 's' : ''}
        </Typography>
      </Box>
    </CallOutWrapper>
  );
}

SessionItem.propTypes = {
  session: PropTypes.object,
  handleOpenSessionDetails: PropTypes.func,
  disableHover: PropTypes.bool,
};

function SessionList({ sessions, handleOpenSessionDetails, disableHover }) {
  if (sessions.length > 0) {
    return sessions.map((s) => (
      <SessionItem
        disableHover={disableHover}
        key={s.id}
        session={s}
        handleOpenSessionDetails={handleOpenSessionDetails}
      />
    ));
  }

  return (
    <Typography sx={{ color: 'gray' }} variant='p' component='div'>
      No Sessions
    </Typography>
  );
}

SessionList.propTypes = {
  sessions: PropTypes.array,
  handleOpenSessionDetails: PropTypes.func,
  disableHover: PropTypes.bool,
};

export default function PatientSessions({ patient }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessions = useSelector((state) => state.patients.sessions);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  const [initSessionTime, setInitSessionTime] = useState('');
  const [latestSession, setLatestSession] = useState('');
  const [healthInformation, setHealthInformation] = useState(null);
  const [specialInformation, setSpecialInformation] = useState(null);

  const [reUseData, setReUseData] = useState(null);
  const [isDisableReuse, setIsDisableReuse] = useState(true);

  const [sortedSessions, setSortedSessions] = useState([]);
  const { permissions: currentPermissions, user: { hcp: { enabled_feature: enabledFeature } } } = useCurrentUser();

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('EXECUTE_ACTIVITIES');
  };

  const hasViewPatientActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_ACTIVITIES');
  };

  const hcpId = localStorage.getItem('hcpId');
  const getSessions = () => {
    const data = {
      patientId: patient.patient_id,
      hcpId,
    };
    dispatch(fetchPatientSessions(data));
  };

  const createSession = () => {
    dispatch(
      requestCreateSession({
        patientId: patient.patient_id,
        hcpId,
        callback: (initTime, sessionId) => {
          const localDate = dateParserWithTimezone(initTime);
          setLatestSession(sessionId);
          setInitSessionTime(format(localDate, 'h:mm aa, LLL d yyyy'));

          setSpecialInformation(null);
          setHealthInformation(null);

          if (enabledFeature.demographic) {
            openDialog(dispatch, DIALOGS.OPEN_DEMO_GRAPHIC);
          }
        },
      })
    );
  };

  useEffect(() => {
    getSessions();
  }, []);

  const isIn30Days = (timestamp) => {
    const inputTimestamp = new Date(timestamp);
    const currentDate = new Date();

    const thirtyDaysLater = new Date(currentDate);
    thirtyDaysLater.setDate(currentDate.getDate() - 30);

    return !(inputTimestamp < thirtyDaysLater);
  };

  useEffect(() => {
    if (!reUseData) {
      return;
    }
    if (!isIn30Days(reUseData.initiated_at)) {
      setIsDisableReuse(true);
      return;
    }
    if (!reUseData.demographic.health_information && !reUseData.demographic.special_information) {
      setIsDisableReuse(true);
      return;
    }
    if (reUseData.demographic.patient_type !== patient.type) {
      setIsDisableReuse(true);
      return;
    }
    setIsDisableReuse(false);
  }, [reUseData]);

  useEffect(() => {
    if (sessions) {
      const _session = JSON.parse(JSON.stringify(sessions));
      _session.sort((a, b) => new Date(b.initiated_at) - new Date(a.initiated_at));

      if (_session.length > 0 && !reUseData) {
        dispatch(
          fetchPatientSession({
            hcpId,
            patientId: patient.patient_id,
            sessionId: _session[0].id,
            callback: (data) => {
              if (!data) {
                return;
              }

              setReUseData(data);
            },
          })
        );
      }
      setSortedSessions(_session);

      setLoading(false);
    }
  }, [sessions]);

  const handleSubmitConfirmDialog = () => {
    setOpenAlert(false);
    createSession();
  };

  const handleCloseConfirmDialog = () => {
    setOpenAlert(false);
  };

  const handleNewSession = () => {
    if (sessions.length === 0) {
      createSession();
    } else {
      const unfinished = sessions.find((s) => !s.ended_at);
      if (unfinished) {
        setOpenAlert(true);
      } else {
        createSession();
      }
    }
  };
  const onFinishHandler = ({ type, healthInformation, specialInformation, callback }) => {
    if (!latestSession || !patient) {
      return;
    }

    const _healthInformation = removeSensitiveData(healthInformation);
    const _specialInformation = removeSensitiveData(specialInformation);

    dispatch(
      updateDemoGraphicQuestion({
        hcpId: hcpId,
        patientId: patient.patient_id,
        sessionId: latestSession,
        data: {
          patient_type: type,
          health_information: _healthInformation,
          special_information: _specialInformation,
        },
        callback: (success) => {
          callback(success);
        },
      })
    );
  };

  const handleOpenSessionDetails = (session) => {
    if (hasViewPatientActivitiesPermission() || hasExecuteActivitiesPermission()) {
      navigate(`/patient/${btoa(patient.patient_id)}/patient-detail?sessionId=${session.id}`);
    }
  };

  if (loading) return <></>;
  return (
    <Box sx={{ bgcolor: 'white', p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ wordBreak: 'break-all' }} variant='h4' component='div'>
          {t('customer:patientSession.patientsSession')}
        </Typography>
        {hasExecuteActivitiesPermission() && (
          <Button onClick={handleNewSession} size='md' sx={{ ml: 'auto', color: 'white' }} variant='contained'>
            {t('customer:patientSession.newSession')}
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
        <SessionList
          disableHover={!hasViewPatientActivitiesPermission() && !hasExecuteActivitiesPermission()}
          sessions={sortedSessions}
          handleOpenSessionDetails={handleOpenSessionDetails}
        />
      </Box>
      <ConfirmDialog
        message={'The latest session will be ended if you create a new session. Do you want to continue?'}
        confirmText='Ok'
        handleCloseConfirmDialog={handleCloseConfirmDialog}
        openConfirmDialog={openAlert}
        handleSubmitConfirmDialog={handleSubmitConfirmDialog}
      />

      {open && (
        <DemoGraphicModal
          date={initSessionTime}
          setHealthData={setHealthInformation}
          healthData={healthInformation}
          setSpecialData={setSpecialInformation}
          specialData={specialInformation}
          onFinish={onFinishHandler}
          isDisabledReuse={isDisableReuse}
          reuseData={reUseData}
          showReuse={true}
        />
      )}
    </Box>
  );
}

PatientSessions.propTypes = {
  patient: PropTypes.object,
};
