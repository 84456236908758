import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';

import step1 from 'assets/images/png/preparation/step1.png';
import step2 from 'assets/images/png/isometric-test/3a.png';
import step3 from 'assets/images/png/physical-positioning/3-2.png';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '',
    img: step1,
  },
  {
    id: 2,
    text: '',
    img: step2,
  },
  {
    id: 3,
    text: '',
    img: step3,
  },
];
const InstructionForPatient = ({ step, subStep, handleCancel, handleBack, handleNext, disableBack }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Typography variant='body1' component='div'>
          1. Provide instruction to the Patient on the following:
          <br />
          <div style={{ padding: '0 20px' }}>
            • Tests will be conducted at multiple positions throughout the ROM.
            <br />
            • A brief rest period (approx. 10 seconds) will follow each test.
            <br />
            • Demonstrate how to push against the resistance pad.
            <br />
            • Visual feedback is provided by the monitor,
            <br />
            • Build maximum force slowly (3-1-3 second count).
            <br />
            • Avoid holding breath. Exhale when building force.
            <br />
            • Maintain a loose grip on the handlebars.
            <br />
            • Maintain contact with the headrest pads.
            <br />
            • Maximum effort should be given in each test.
            <br />• Exert force only on clinician’s instructions.
          </div>
        </Typography>
        <Box sx={{ display: 'flex', gap: 5, paddingTop: 5, paddingBottom: 5 }}>{getInstructions(instructions)}</Box>
      </Box>

      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
        disableBack={disableBack}
      />
    </>
  );
};

InstructionForPatient.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disableBack: PropTypes.bool,
};

export default InstructionForPatient;
