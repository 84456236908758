import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useSummaryPage } from 'modules/Patients/hooks/useSummaryPage';
import { useNavigateItems } from 'modules/Patients/hooks/useMachineSetup';
import StepsContainer from '../../Base/StepsContainer';
import MachineSetupViewContainer from 'modules/Patients/components/Machines/Base/MachineSetupView';
import Preparation from './Steps/Preparation';
import BriefPatient from './Steps/BriefPatient';
import SeatingAndFootboard from './Steps/SeatingAndFootboard';
import GatePosition from './Steps/GatePosition';
import TightenRestraints from './Steps/TightenRestraints';
import { SetAngleRender } from './Steps/SetAngle';
import Limiters from './Steps/Limiters';
import { requestSaveSetup } from 'modules/Patients/slice';

export function clearLocalPatientSetupData() {
  localStorage.removeItem('setupId');
  localStorage.removeItem('selectedMachineId');
  localStorage.removeItem('selectedMachineTypeId');
}

export default function MachineSetupView(props) {
  const { url, machineId, setupId, patientId } = useSummaryPage();
  const navigationItemsContext = useNavigateItems();
  const { Step } = StepsContainer;
  const isFinish = useRef(null);
  const { step, subStep } = props;
  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const dispatch = useDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    if (!step || !subStep) {
      return;
    }

    let currentStep = `${step}`;

    if (currentStep === '5') {
      if (isFinish.current === true || isUpdating) {
        return;
      }
      isFinish.current = true;
      dispatch(
        requestSaveSetup({
          hcpId: localStorage.getItem('hcpId'),
          patientId: atob(patientId),
          machineId: machineId,
          finished: true,
          setupId,
        })
      );
      return;
    }

  }, [step, subStep]);

  useEffect(() => {
    if (isUpdating || !isFinish.current) {
      return;
    }

    navigator(
      url
    );
  }, [isUpdating]);

  return (
    <MachineSetupViewContainer {...props} navigationItems={navigationItemsContext.navigationItems}>
      <StepsContainer>
        {/* 1 */}
        <Step name={'Preparation'}>
          <Preparation />
        </Step>
        {/* 2 */}
        <Step name={'Brief The Patient'}>
          <BriefPatient />
        </Step>
        {/* 3 */}
        <Step name={'Patient Setup'}>
          <Step name='Seating / Footboard'>
            <SeatingAndFootboard />
          </Step>
          <Step name='Gate Position'>
            <GatePosition />
          </Step>
          <Step name='Tighten Restraints'>
            <TightenRestraints />
          </Step>
        </Step>
        {/* 4 */}
        <Step name='Find Range of Motion (RoM)'>
          <Step name='Find Max Left ROM'>
            <SetAngleRender />
          </Step>
          <Step name='Find Max Right ROM'>
            <SetAngleRender />
          </Step>
          <Step name='Limiters'>
            <Limiters />
          </Step>
        </Step>
      </StepsContainer>
    </MachineSetupViewContainer>
  );
}

MachineSetupView.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSaveData: PropTypes.func,
  handleSelectItem: PropTypes.func,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleSetStep: PropTypes.func,
  handleSetSubStep: PropTypes.func,
  navigationItems: PropTypes.array,
  socketContext: PropTypes.object,
};
