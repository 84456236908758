import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import adjustable from 'assets/images/knee/adjustable leg support.svg';
import seatBack from 'assets/images/knee/seatback.svg';
import Handlebars from 'assets/images/knee/Handlebars.svg';
import Actions from 'modules/Patients/components/Machines/Actions';
import { SingleImageStep } from 'helpers';

export default function Instructions({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={adjustable} />
            </Box>
            <Box>
              1. Engage the adjustable leg support to accommodate the leg not involved in the patient setup.
              <br />
              2. Have patient position their non involved leg on top of the adjustable leg support.
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={seatBack} />
            </Box>
            <Box>
              3. Instruct patient to maintain contact with their back and the seatback.
              <br />
              4. Ask a patient to breathe normally, and avoid holding their breath.
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={Handlebars} />
            </Box>
            <Box>
              5. Instruction patient to use handlebars on each side of seat to limit upper body movement during all
              testing and exercise procedures. Instruct patient to maintain loose grip on handlebars.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

Instructions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
