import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import TextField from '@mui/material/TextField';

export default function Limiters({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData }) {
  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              1. Immediately disengage the angle selector and move the patient to 0°, and then reengage the angle
              selector.
              <br />
              2. Quickly loosen torso restraint and footboard restraint.
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              3. It’s recommended that the clinician reposition the Range of Motion Limiter
              <br />
              4. Please apply the Range of Motion Limiter to the following:
              <br />
              <br />
              <Box sx={{ display: 'flex', gap: 5 }}>
                <Box>
                  <b>Left Angle:</b>
                  <Box sx={{ width: '100px' }}>
                    <TextField
                      value={setupMachineData.limiter_max ?? 0}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <b>Right Angle:</b>
                  <Box sx={{ width: '100px' }}>
                    <TextField
                      value={setupMachineData.limiter_min ?? 0}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

Limiters.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
