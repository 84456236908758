import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';
import { REMAINING_ATTEMPT_DEFAULT } from './constants';

export const AuthSlice = createSlice({
  name: 'authSlice',
  initialState: {
    token: null,
    error: null,
    errorCode: null,
    forcedLogout: false,
    invitedUser: null,
    isRequireTwoFa: false,
    remaining_attempts: REMAINING_ATTEMPT_DEFAULT,
    openModalReachedFirstLimit: false,
    openModalReachedLastLimit: false
  },
  reducers: {
    fetchUserInfo: (state) => {
      state.errorCode = 0;
    },
    fetchUserInfoSuccess: (state, action) => {
      state.currentUser = action.payload;
    },
    fetchUserInfoFailure: (state, action) => {
      state.currentUser = false;
      state.token = '';
      state.errorCode = action.payload.code;
    },
    requestHcpUserLogout: (state) => {
      state.forcedLogout = false;
    },
    requestHcpUserLogoutSuccess: (state) => {
      state.currentUser = null;
      state.token = '';
    },

    forceLogout: () => { },
    forceLogoutSuccess: (state) => {
      state.forcedLogout = true;
    },

    // for two fa
    fetchRequireTwoFaSuccess: (state, action) => {
      state.isRequireTwoFa = action.payload;
    },
    resetRequireTwoFa: (state, action) => {
      state.isRequireTwoFa = false;
    },
    requestCustomerVerifyOtp: (state) => {
    },
    requestCustomerVerifyOtpSuccess: (state, action) => {
      state.is_success = action.payload;
    },
    requestCustomerVerifyOtpFailure: (state, action) => {
      state.reason = action.payload.reason;
      state.is_success = action.payload.is_success;
      state.remaining_attempts = action.payload.remaining_attempts
    },
    resetReasonLogin: (state, action) => {
      state.reason = null;
    },
    resetRemainingAttempt: (state, action) => {
      state.remaining_attempts = REMAINING_ATTEMPT_DEFAULT;
    },
    requestSetOpenModalReachedFirstLimit: (state, action) => {
      state.openModalReachedFirstLimit = action.payload;
    },
    requestSetOpenModalReachedLastLimit: (state, action) => {
      state.openModalReachedLastLimit = action.payload
    },
    requestCustomerResendOtp: (state) => {
    },
    requestCustomerResendOtpSuccess: (state, action) => {
      state.is_sent = action.payload.is_sent;
      state.resend_attempts = action.payload.remaining_attempts
    },
    requestCustomerResendOtpFailure: (state, action) => {
    },


    // for bionex users
    requestAcceptInvite: (state) => {
      state.errorCode = 0;
    },
    requestAcceptInviteSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestAcceptInviteFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },

    requestCheckInviteToken: (state) => {
      state.invitedUser = null;
      state.errorCode = 0;
    },
    requestCheckInviteTokenSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestCheckInviteTokenFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },

    // for hcp and hcp users
    requestCustomerLogin: (state) => {
      state.invitedUser = null;
      state.errorCode = 0;
      state.status = 0;
    },
    requestCustomerLoginSuccess: (state, action) => {
      state.token = action.payload;
      state.remaining_attempts = REMAINING_ATTEMPT_DEFAULT;
    },
    requestCustomerLoginFailure: (state, action) => {
      state.errorCode = action.payload.code;
      state.status = action.payload.error.status;
      state.error = action.payload.error;
    },
    requestCustomerAcceptInvite: (state) => {
      state.errorCode = 0;
    },
    requestCustomerAcceptInviteSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestCustomerAcceptInviteFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestCustomerCheckInviteToken: (state) => {
      state.invitedUser = null;
      state.errorCode = 0;
    },
    requestCustomerCheckInviteTokenSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestCustomerCheckInviteTokenFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestHcpUsersForgotPassword: (state) => {
      state.errorCode = 0;
      state.status = 0;
    },
    requestHcpUsersForgotPasswordSuccess: () => { },
    requestHcpUsersForgotPasswordFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestHcpUsersCheckForgotPasswordToken: (state) => {
      state.errorCode = 0;
      state.status = 0;
    },
    requestHcpUsersCheckForgotPasswordTokenSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestHcpUsersCheckForgotPasswordTokenFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestResetHcpUserPassword: (state) => {
      state.errorCode = 0;
    },
    requestResetHcpUserPasswordSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestResetHcpUserPasswordFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    clearError: (state) => {
      state.errorCode = 0;
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.errorCode = 0;
      state.forcedLogout = false;
      state.status = 0;
    },
  },
});
export const {
  clearError,
  requestCustomerLogin,
  requestCustomerLoginSuccess,
  requestCustomerLoginFailure,
  fetchRequireTwoFaSuccess,
  resetRequireTwoFa,
  resetReasonLogin,
  resetRemainingAttempt,
  requestSetOpenModalReachedFirstLimit,
  requestSetOpenModalReachedLastLimit,
  requestCustomerVerifyOtp,
  requestCustomerVerifyOtpSuccess,
  requestCustomerVerifyOtpFailure,
  requestCustomerResendOtp,
  requestCustomerResendOtpSuccess,
  requestCustomerResendOtpFailure,
  fetchUserInfo,
  fetchUserInfoSuccess,
  fetchUserInfoFailure,
  requestHcpUserLogout,
  requestHcpUserLogoutSuccess,
  forceLogout,
  forceLogoutSuccess,
  requestAcceptInvite,
  requestAcceptInviteSuccess,
  requestAcceptInviteFailure,
  requestCheckInviteToken,
  requestCheckInviteTokenSuccess,
  requestCheckInviteTokenFailure,
  requestCustomerAcceptInvite,
  requestCustomerAcceptInviteSuccess,
  requestCustomerAcceptInviteFailure,
  requestCustomerCheckInviteToken,
  requestCustomerCheckInviteTokenSuccess,
  requestCustomerCheckInviteTokenFailure,
  requestHcpUsersForgotPassword,
  requestHcpUsersForgotPasswordSuccess,
  requestHcpUsersForgotPasswordFailure,
  requestHcpUsersCheckForgotPasswordToken,
  requestHcpUsersCheckForgotPasswordTokenSuccess,
  requestHcpUsersCheckForgotPasswordTokenFailure,
  requestResetHcpUserPassword,
  requestResetHcpUserPasswordSuccess,
  requestResetHcpUserPasswordFailure,
} = AuthSlice.actions;

export default AuthSlice.reducer;
