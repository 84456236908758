import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { END_POINT } from './constants';
import { requestToast } from '../Ui/slice';
import { TOAST_TYPE } from '../Ui/constants';
import { requestDownloadFile, requestDownloadFileFailure, requestDownloadFileSuccess } from './slice';

function* downloadFile(action) {
  try {
    const { data } = yield axios.get(END_POINT.downloadFile.url(action.payload.hcpId));

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'bionex_local.tar.gz');
    document.body.appendChild(link);
    link.click();

    yield put(requestDownloadFileSuccess());
    yield put(
      requestToast({
        type: TOAST_TYPE.SUCCESS,
        message: `"Download successfully.`,
      })
    );
  } catch (e) {
    yield put(requestDownloadFileFailure());
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Download failure.`,
      })
    );
  }
}

function* offlineSagas() {
  yield takeLatest(requestDownloadFile, downloadFile);
}

export default offlineSagas;
