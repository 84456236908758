import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import noImg from 'assets/images/png/noImage.png';

import { SingleImageStep } from 'helpers';
import GaugeSlider from 'components/GaugeSlider';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useEffect, useRef, useState } from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import { requestSaveSetup } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { textFieldRender } from 'helpers';
import { clearMessageQueueHandler } from 'helpers';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import FlipSwitch from 'components/FlipSwitch';

export function SetAngleRender({ step, subStep, machine, handleCancel, handleBack, handleNext, setupMachineData }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const socketContext = useSocketDataContext();

  const patientId = id;
  const setupIdParams = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const isUpdate = useRef(null);

  const [value, setValue] = useState(0);
  const [isLock, setLock] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    handleStopData();

    return () => {
      handleStopData();
    };
  }, []);

  useEffect(() => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    if (!setupMachineData) {
      return;
    }

    let currAngle = null;
    switch (subStep) {
      case '1':
        currAngle = setupMachineData.range_motion_max;
        break;
      case '2':
        currAngle = setupMachineData.range_motion_min;
        break;
      default:
        break;
    }
    if (currAngle !== null) {
      setValue(currAngle);
      setLock(true);
      setIsReady(false);
      return;
    }
    setValue(0);
    setLock(false);
    getData();
  }, [setupMachineData, step, subStep]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!isReady) {
      return;
    }
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (message.degree !== undefined) {
      setValue(message.degree);
    }

    if (message.done) {
      setLock(true);
    }
  }, [socketContext.messageHistory]);

  useEffect(() => {
    if (!isUpdate.current) {
      return null;
    }

    if (isUpdating) {
      return null;
    }

    handleNext();
  }, [isUpdating]);

  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);

    setTimeout(() => {
      setLock(false);
      getData();
    }, 2000);
  };

  const handleStopData = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
  };
  const getData = () => {
    setLock(false);
    setIsReady(true);
    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
  };

  const nextHandler = () => {
    let currentAngle = null;
    let data;
    switch (subStep) {
      case '1':
        currentAngle = setupMachineData.range_motion_max;
        data = { range_motion_max: value };
        break;
      case '2':
        currentAngle = setupMachineData.range_motion_min;
        data = { range_motion_min: value };
        break;
      default:
        break;
    }

    if (currentAngle === value) {
      handleNext();
      return null;
    }

    const setupId = localStorage.getItem('setupId') || setupIdParams;
    isUpdate.current = true;
    dispatch(
      requestSaveSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(patientId),
        machineId: machineId,
        finished: false,
        setupId,
        data,
      })
    );
  };

  const backHandler = () => {
    handleStopData();
    handleBack();
  };

  const direction = subStep === '1' ? 'Left' : 'Right';

  return (
    <Box key={`select-angle-${step}-${subStep}`} sx={{ overflow: 'hidden' }}>
      <div>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Typography variant='body1' component='div'>
            1. Disengage the angle selector and slowly palpate through patient’s pain-free range of motion 2-3 times
            max.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={noImg} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              <div style={{ fontWeight: 'bold' }}>2. Find Max {direction} ROM:</div>
              {direction === 'Right' ? 'Disengage the angle selector and to rotate' : 'Ask the patient to rotate'} as
              far as possible to the {direction.toLowerCase()}. Lightly assist patient to find Max {direction} Range of
              motion.
              <br />• Rotate till you find tightness rather than the pain-free range of motion.
              <br />• Back off 6° from tightness and engage the angle selector and verify the angle selected corresponds
              with the angle displayed on the monitor.
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={noImg} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              3. Turn left Range of Motion limiters counterclockwise until resistance is met; then back off 1/2 turn.
            </Typography>
          </Box>

          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider value={value} minValue={-96} maxValue={96} isLock={isLock} />
            </div>

            <Box>
              <Typography sx={{ fontSize: '1rem', pt: 2, fontWeight: 500, whiteSpace: 'nowrap' }} variant='h2'>
                {/*{t('machine:debugDialog.title')}*/}
                <span style={{ margin: 0 }}> {direction} Angle:</span>
              </Typography>
              <Box sx={{ mt: 1 }}>{textFieldRender(value, isLock)}</Box>
              <FlipSwitch />
            </Box>
          </div>
        </OverlayScrollbarsComponent>

        <Actions
          step={step}
          subStep={subStep}
          handleCancel={handleCancel}
          handleBack={backHandler}
          handleNext={nextHandler}
          disableNext={!isLock}
          nextLoading={isUpdating}
          handleUndo={redoHandler}
        />
      </div>
    </Box>
  );
}
SetAngleRender.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  page: PropTypes.string,
  values: PropTypes.object,
  onDataChange: PropTypes.func,

  setupMachineData: PropTypes.object,
};
