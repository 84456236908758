import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputText from 'modules/DemoGraphic/Shared/InputText.jsx';
import { SingleSelect } from 'modules/DemoGraphic/Shared/SingleSelect.jsx';
import { useEffect, useState } from 'react';
import InputDate from 'modules/DemoGraphic/Shared/InputDate.jsx';
import MultipleSelect from 'modules/DemoGraphic/Shared/MultipleSelect.jsx';
import InputTextarea from 'modules/DemoGraphic/Shared/TextArea.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ACTIVITY_LEVEL_OPTIONS,
  HOUR_FOR_SLEEP_OPTIONS,
  IS_CONDITION_COMPLEX_OPTIONS,
  IS_INJURY_ACUTE_OPTIONS,
  IS_SYMPTOMATIC_OPTIONS,
} from 'modules/DemoGraphic/Shared/options.js';

export default function HealthInformation({ data, setData, reuseLoading }) {
  const [isCurrentInjured, setIsCurrentInjured] = useState(false);
  const [isSurgery, setIsSurgery] = useState(false);
  const [isTypeInjuredNote, setIsTypeInjuredNote] = useState(false);
  const [isAreaInjuredNote, setIsAreaInjuredNote] = useState(false);
  const [isSensitivitiesNote, setIsSensitivitiesNote] = useState(false);
  const [isComorbidityOptionsNote, setIsComorbidityOptionsNote] = useState(false);

  useEffect(() => {
    setIsCurrentInjured(data.currentlyInjured === 'YES');
    setIsSurgery(data.hasSurgery === 'YES' && data.currentlyInjured === 'YES');

    if (data.typeInjured.length >= 0) {
      setIsTypeInjuredNote(false);
      data.typeInjured.map((e) => {
        if (e.value === 'OTHER') {
          setIsTypeInjuredNote(true);
        }
      });
    }

    if (data.areaInjured.length >= 0) {
      setIsAreaInjuredNote(false);
      data.areaInjured.map((e) => {
        if (e.value === 'OTHER') {
          setIsAreaInjuredNote(true);
        }
      });
    }

    if (data.sensitivities.length >= 0) {
      setIsSensitivitiesNote(false);
      data.sensitivities.map((e) => {
        if (e.value === 'OTHER') {
          setIsSensitivitiesNote(true);
        }
      });
    }

    if (data.comorbidityOptions.length >= 0) {
      setIsComorbidityOptionsNote(false);
      data.comorbidityOptions.map((e) => {
        if (e.value === 'OTHER') {
          setIsComorbidityOptionsNote(true);
        }
      });
    }
  }, [data]);

  const onChangeHandler = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  if (!data) {
    return null;
  }
  if (reuseLoading) {
    return (
      <Box
        className={'modal-content'}
        sx={{
          height: 'calc(100vh - 50px)',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={'modal-content'}>
      <Box className={'row'}>
        <span className={'label'}>Please input your height</span>
        <InputText
          type={'number'}
          placeholder={'Inches'}
          field={'height'}
          value={data.height}
          onChange={onChangeHandler}
          width={120}
        />
      </Box>

      <Box className={'row'}>
        <span className={'label'}>Please input your weight</span>
        <InputText
          type={'number'}
          field={'weight'}
          placeholder={'Pounds'}
          value={data.weight}
          onChange={onChangeHandler}
          width={120}
        />
      </Box>
      <div className={'hr'} />

      {/*current injured*/}
      <Box className={'row'}>
        <span className={'label'}>Are you currently injured?</span>
        <SingleSelect
          value={data.currentlyInjured}
          onChange={onChangeHandler}
          width={160}
          field={'currentlyInjured'}
          options={[
            { value: 'YES', label: 'Yes' },
            { value: 'NO', label: 'No' },
          ]}
        />
      </Box>

      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>What type of injury?</span>
          <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', justifyContent: 'end', width: '250px' }}>
            <MultipleSelect
              value={data.typeInjured}
              onChange={onChangeHandler}
              width={250}
              field={'typeInjured'}
              options={[
                { value: 'BURN_INJURIES', label: 'Burn injuries' },
                { value: 'CUTS', label: 'Cuts' },
                { value: 'BRUISE', label: 'Bruise' },
                { value: 'JOINT_DISLOCATIONS', label: 'Joint dislocations' },
                { value: 'WHIPLASH', label: 'Whiplash' },
                { value: 'FRACTURES', label: 'Fractures (broken bones)' },
                { value: 'SPRAINS_AND_STRAINS', label: 'Sprains and strains' },
                { value: 'CONCUSSIONS', label: 'Concussions' },
                { value: 'REPETITIVE_USE', label: 'Repetitive use' },
                { value: 'OTHER', label: 'Other' },
              ]}
            />
            {isTypeInjuredNote && (
              <InputTextarea
                width={250}
                field={'typeInjuredNote'}
                value={data.typeInjuredNote}
                onChange={onChangeHandler}
              />
            )}
          </Box>
        </Box>
      )}

      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>What area of the body is injured?</span>
          <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', justifyContent: 'end', width: '250px' }}>
            <MultipleSelect
              value={data.areaInjured}
              onChange={onChangeHandler}
              width={250}
              field={'areaInjured'}
              options={[
                { value: 'BACK_INJURY', label: 'Back Injury' },
                { value: 'NECK_INJURY', label: 'Neck Injury' },
                { value: 'SHOULDER_INJURY', label: 'Shoulder Injury' },
                { value: 'KNEE_INJURY', label: 'Knee Injury' },
                { value: 'LEG_INJURY', label: 'Leg Injury' },
                { value: 'ANKLE_INJURY', label: 'Ankle Injury' },
                { value: 'FOOT_INJURY', label: 'Foot Injury' },
                { value: 'WRIST_INJURY', label: 'Wrist Injury' },
                { value: 'ARM_INJURY', label: 'Arm Injury' },
                { value: 'HAND_INJURY', label: 'Hand Injury' },
                { value: 'HEAD_INJURY', label: 'Head Injury' },
                { value: 'OTHER', label: 'Other' },
              ]}
            />
            {isAreaInjuredNote && (
              <InputTextarea
                width={250}
                field={'areaInjuredNote'}
                value={data.areaInjuredNote}
                onChange={onChangeHandler}
              />
            )}
          </Box>
        </Box>
      )}

      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>Is your injury work related? </span>
          <SingleSelect
            value={data.isInjuryWorkRelated}
            onChange={onChangeHandler}
            width={160}
            field={'isInjuryWorkRelated'}
            options={[
              { value: 'YES', label: 'Yes' },
              { value: 'NO', label: 'No' },
            ]}
          />
        </Box>
      )}

      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>Is injury Acute / Chronic / NA?</span>
          <SingleSelect
            value={data.isInjuryAcute}
            onChange={onChangeHandler}
            width={160}
            field={'isInjuryAcute'}
            options={IS_INJURY_ACUTE_OPTIONS}
          />
        </Box>
      )}

      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>Is your condition Complex or Non-complex?</span>
          <SingleSelect
            value={data.isConditionComplex}
            onChange={onChangeHandler}
            width={160}
            field={'isConditionComplex'}
            options={IS_CONDITION_COMPLEX_OPTIONS}
          />
        </Box>
      )}

      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>Is the injury Symptomatic vs A-symptomatic?</span>
          <SingleSelect
            value={data.isSymptomatic}
            onChange={onChangeHandler}
            width={160}
            field={'isSymptomatic'}
            options={IS_SYMPTOMATIC_OPTIONS}
          />
        </Box>
      )}

      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>Have you previously injured this Anatomical area?</span>
          <SingleSelect
            value={data.hasPreviousInjured}
            onChange={onChangeHandler}
            width={160}
            field={'hasPreviousInjured'}
            options={[
              { value: 'YES', label: 'Yes' },
              { value: 'NO', label: 'No' },
            ]}
          />
        </Box>
      )}
      {isCurrentInjured && <div className={'hr'} />}

      {/*hasSurgery*/}
      {isCurrentInjured && (
        <Box className={'row'}>
          <span className={'label'}>Have you had surgery to this anatomical region?</span>
          <SingleSelect
            value={data.hasSurgery}
            onChange={onChangeHandler}
            width={160}
            field={'hasSurgery'}
            options={[
              { value: 'YES', label: 'Yes' },
              { value: 'NO', label: 'No' },
            ]}
          />
        </Box>
      )}
      {isSurgery && (
        <Box className={'row'}>
          <span className={'label'}>What was the surgery type?</span>
          <InputText
            placeholder={'Input surgery type'}
            field={'surgeryType'}
            value={data.surgeryType}
            onChange={onChangeHandler}
            width={250}
          />
        </Box>
      )}

      {/*hold*/}
      {isSurgery && (
        <Box className={'row'}>
          <span className={'label'}>What was the surgery date?</span>
          <InputDate value={data.surgeryDate} onChange={onChangeHandler} width={250} field={'surgeryDate'} />
        </Box>
      )}
      {/*hasSurgery*/}

      {/*current injured*/}
      <div className={'hr'} />

      <Box className={'row'}>
        <span className={'label'}>What is your activity level?</span>
        <SingleSelect
          value={data.activityLevel}
          onChange={onChangeHandler}
          field={'activityLevel'}
          width={160}
          options={ACTIVITY_LEVEL_OPTIONS}
        />
      </Box>
      <div className={'hr'} />

      <Box className={'row'}>
        <span className={'label'}>How many hours of sleep per night do you sleep?</span>
        <SingleSelect
          value={data.hourForSleep}
          onChange={onChangeHandler}
          field={'hourForSleep'}
          width={160}
          options={HOUR_FOR_SLEEP_OPTIONS}
        />
      </Box>
      <div className={'hr'} />

      <Box className={'row'}>
        <span className={'label'}>What allergies or sensitivities do you have?</span>
        <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', justifyContent: 'end', width: '250px' }}>
          <MultipleSelect
            value={data.sensitivities}
            onChange={onChangeHandler}
            width={250}
            field={'sensitivities'}
            options={[
              {
                value: 'NONE',
                label: 'None',
              },
              {
                value: 'FOOD_ALLERGIES_OR_SENSITIVITIES',
                label: 'Food allergies or sensitivities',
              },
              {
                value: 'SEASONAL_ALLERGIES',
                label: 'Seasonal allergies',
              },
              {
                value: 'MEDICATION_ALLERGIES',
                label: 'Medication allergies',
              },
              {
                value: 'ENVIRONMENTAL_ALLERGIES',
                label: 'Environmental allergies',
              },
              {
                value: 'LATEX_ALLERGIES',
                label: 'Latex allergies',
              },
              {
                value: 'ALLERGIC_REACTIONS_TO_SPECIFIC_CHEMICALS_OR_SUBSTANCES',
                label: 'Allergic reactions to specific chemicals or substances',
              },
              {
                value: 'ALLERGIC_REACTIONS_TO_SPECIFIC_MATERIALS',
                label: 'Allergic reactions to specific materials',
              },
              {
                value: 'ALLERGIES_TO_STINGS_OR_BITES_FROM_INSECTS',
                label: 'Allergies to stings or bites from insects',
              },
              {
                value: 'SENSITIVITIES_TO_CERTAIN_TYPES_OF_LIGHT',
                label: 'Sensitivities to certain types of light',
              },
              {
                value: 'SENSITIVITIES_TO_CERTAIN_SOUNDS_OR_NOISES',
                label: 'Sensitivities to certain sounds or noises',
              },
              {
                value: 'OTHER',
                label: 'Other',
              },
            ]}
          />
          {isSensitivitiesNote && (
            <InputTextarea
              width={250}
              field={'sensitivitiesNote'}
              value={data.sensitivitiesNote}
              onChange={onChangeHandler}
            />
          )}
        </Box>
      </Box>
      <div className={'hr'} />

      <Box className={'row'}>
        <span className={'label'}>Please select the comorbidity options that pertain to you</span>
        <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', justifyContent: 'end', width: '250px' }}>
          <MultipleSelect
            value={data.comorbidityOptions}
            onChange={onChangeHandler}
            field={'comorbidityOptions'}
            width={250}
            options={[
              {
                value: 'HIGH_BLOOD_PRESSURE',
                label: 'High blood pressure',
              },
              {
                value: 'DIABETES',
                label: 'Diabetes',
              },
              {
                value: 'HEART_DISEASE',
                label: 'Heart disease',
              },
              {
                value: 'ASTHMA',
                label: 'Asthma',
              },
              {
                value: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_(COPD)',
                label: 'Chronic obstructive pulmonary disease (COPD)',
              },
              {
                value: 'ARTHRITIS',
                label: 'Arthritis',
              },
              {
                value: 'DEPRESSION',
                label: 'Depression',
              },
              {
                value: 'ANXIETY',
                label: 'Anxiety',
              },
              {
                value: 'CANCER',
                label: 'Cancer',
              },
              {
                value: 'KIDNEY_DISEASE',
                label: 'Kidney disease',
              },
              {
                value: 'LIVER_DISEASE',
                label: 'Liver disease',
              },
              {
                value: 'THYROID_DISEASE',
                label: 'Thyroid disease',
              },
              {
                value: "NEUROLOGICAL_CONDITIONS_SUCH_AS_PARKINSON'S_DISEASE_OR_MULTIPLE_SCLEROSIS",
                label: "Neurological conditions such as Parkinson's disease or multiple Sclerosis",
              },
              {
                value: 'SLEEP_APNEA',
                label: 'Sleep apnea',
              },
              {
                value: 'OBESITY',
                label: 'Obesity',
              },
              {
                value: 'SUBSTANCE_ABUSE_OR_ADDICTION',
                label: 'Substance abuse or addiction',
              },
              {
                value: 'SMOKER_OR_TOBACCO_USER',
                label: 'Smoker or tobacco user',
              },
              {
                value: 'ACCESS_ALCOHOL_USE',
                label: 'Access alcohol use',
              },
              {
                value: 'EXTREME_SPORTS',
                label: 'Extreme sports',
              },
              {
                value: 'OTHER',
                label: 'Other',
              },
            ]}
          />
          {isComorbidityOptionsNote && (
            <InputTextarea
              width={250}
              field={'comorbidityOptionsNote'}
              value={data.comorbidityOptionsNote}
              onChange={onChangeHandler}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

HealthInformation.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  reuseLoading: PropTypes.bool,
};
