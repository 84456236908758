import Instructions from './SetupReview/Instructions';
import TopDeadCenter from './SetupReview/TopDeadCenter';
import LevelAndLock from './SetupReview/LevelAndLock';
import SetCounterBalance from './SetupReview/SetCounterBalance';
import SeatingPosition from './SetupReview/SeatingPosition';
import Restraints from './SetupReview/Restraints';
import DynamicWarmUpInstructions from './DynamicWarmUp/Instructions';
import PatientAdjustments from './DynamicWarmUp/PatientAdjustments';
import SetAngle from './DynamicWarmUp/SetAngle';
import Exercise from './DynamicWarmUp/Exercise';
import InstructionForPatient from './InstructionForPatient';
import PreSetAngles from './PracticeTest/PreSetAngles';
import { ActualTestInstruction, PracticeTestInstruction } from './SimpleSteps';
import AngleTest from './PracticeTest/AngleTest';
import IsometricPracticeSummary from './PracticeTest/IsometricPracticeSummary';
import ActualPreSetAngles from './ActualTest/ActualPreSetAngles';
import { putActivityNote } from '../../../../../slice';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import ActivityQuestion from 'modules/Patients/containers/Machines/ActivityQuestion/index.jsx';
import { openDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';

const Steps = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  onDataChange,
  machine,
  setupMachineData,
  handleUndoAngle,
  defaultAngles,
  testStack,
  handleRemoveSkip,
  testValue,
  disableActualPreSetAngle,
  disablePracticePreSetAngle,
  setDisablePracticePreSetAngle,
  setDisableActualPreSetAngle,
  stepData,
  isFirstStepSkipped,
  isSecondStepSkipped,
  handleRemoveAngle,
  setRemovedAngles,
  removedAngles,
  setIsModalSaved,
  isModalSaved,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const setupId = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');
  const machineType = searchParams.get('machineType');

  const [nextStep, setNextStep] = useState('');

  const isFinish = useRef(false);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);
  const activityId = localStorage.getItem('activity_id');

  useEffect(() => {
    return () => {
      handleUndoAngle(true);
      localStorage.removeItem('activity_id');
    };
  }, []);

  useEffect(() => {
    if (isUpdatingActivity || !isFinish.current) {
      return;
    }

    navigator(
      `/patient/${id}/isometric-test/summary?setupId=${setupId}&mode=1&sessionId=${sessionId}&machineType=${machineType}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=false`
    );
  }, [isUpdatingActivity]);

  useEffect(() => {
    if (!step || !subStep) {
      return;
    }

    let currentStep = `${step}`;
    if (subStep !== '0') {
      currentStep = `${step}-${subStep}`;
    }

    if (currentStep === '5-11') {
      openDialog(dispatch, DIALOGS.OPEN_ACTIVITY_QUESTION);
      return;
    }
    setNextStep(currentStep);
  }, [step, subStep]);

  const finishQuestionHandler = (question) => {
    isFinish.current = true;
    dispatch(
      putActivityNote({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(id),
        sessionId,
        activitiesId: activityId,
        data: question,
      })
    );
  };

  const props = {
    step,
    subStep,
    handleCancel,
    handleBack,
    handleNext,
    machine: machine,
    onDataChange,
    value: testValue,
    defaultData: stepData.find((s) => s.step === `${step}` && s.subStep === `${subStep}`),
    testStack,
    setupMachineData: setupMachineData ? setupMachineData.data ?? {} : {},
    handleRemoveSkip,
    disableActualPreSetAngle,
    disablePracticePreSetAngle,
    setDisablePracticePreSetAngle,
    setDisableActualPreSetAngle,
    sessionId: setupMachineData ? setupMachineData.session_id : '',
  };

  const getStepRender = () => {
    switch (nextStep) {
      case '1-1':
        return <Instructions {...props} />;
      case '1-2':
        return <TopDeadCenter {...props} />;
      case '1-3':
        return <LevelAndLock {...props} />;
      case '1-4':
        return <SetCounterBalance {...props} />;
      case '1-5':
        return <SeatingPosition {...props} />;
      case '1-6':
        return <Restraints {...props} />;
      case '2-1':
        return <DynamicWarmUpInstructions {...{ ...props, disableBack: isFirstStepSkipped }} />;
      case '2-2':
        return <PatientAdjustments {...props} />;
      case '2-3':
        return <SetAngle {...props} />;
      case '2-4':
        return <Exercise {...props} />;
      case '3':
        return <InstructionForPatient {...props} disableBack={isFirstStepSkipped && isSecondStepSkipped} />;
      case '4-1':
        return <PreSetAngles {...{ ...props, machineSetup: setupMachineData }} />;
      case '4-2':
        return <PracticeTestInstruction {...props} />;
      case '4-3':
        return <AngleTest key={`practice-test-${nextStep}`} {...props} />;
      case '4-4':
        return <AngleTest key={`practice-test-${nextStep}`} {...props} />;
      case '4-5':
        return <AngleTest key={`practice-test-${nextStep}`} {...props} />;
      case '4-6':
        return <IsometricPracticeSummary {...props} />;
      case '5-1':
        return (
          <ActualPreSetAngles
            {...{
              ...props,
              defaultAngles: defaultAngles,
              handleRemoveAngle: handleRemoveAngle,
              handleUndoAngle: handleUndoAngle,
              setRemovedAngles: setRemovedAngles,
              removedAngles: removedAngles,
              setIsModalSaved: setIsModalSaved,
              isModalSaved: isModalSaved,
            }}
          />
        );
      case '5-2':
        return <ActualTestInstruction {...props} />;

      case '5-3':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;
      case '5-4':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;
      case '5-5':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;
      case '5-6':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;
      case '5-7':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;
      case '5-8':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;
      case '5-9':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;
      case '5-10':
        return <AngleTest key={`practice-actual-${nextStep}`} {...props} />;

      default:
        return <></>;
    }
  };

  return (
    <>
      {getStepRender()}
      <ActivityQuestion onFinish={finishQuestionHandler} />
    </>
  );
};

Steps.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  onDataChange: PropTypes.func,
  setupMachineData: PropTypes.object,
  handleUndoAngle: PropTypes.func,
  handleRemoveAngle: PropTypes.func,
  removedAngles: PropTypes.array,
  setRemovedAngles: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  setIsModalSaved: PropTypes.func,
  isModalSaved: PropTypes.bool,
  defaultAngles: PropTypes.array,
  testStack: PropTypes.array,
  isFirstStepSkipped: PropTypes.bool,
  isSecondStepSkipped: PropTypes.bool,
  testValue: PropTypes.object,
  disableActualPreSetAngle: PropTypes.bool,
  disablePracticePreSetAngle: PropTypes.bool,
  setDisablePracticePreSetAngle: PropTypes.func,
  setDisableActualPreSetAngle: PropTypes.func,
  stepData: PropTypes.array,
};
export default Steps;
