import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import seadPat from 'assets/images/SVG/Cervical/Seat-pad.svg';
import frontView from 'assets/images/SVG/Cervical/Chair tightener_2.svg';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '',
    img: frontView,
  },
  {
    id: 2,
    text: '',
    img: seadPat,
  },
];
const SeatingPosition = ({ step, setupMachineData, subStep, handleCancel, handleBack, handleNext }) => {
  if (!setupMachineData) {
    return null;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Box sx={{ display: 'flex', gap: 5 }}>
          <Box>{getInstructions([instructions[0]])}</Box>
          <Typography variant='body1' component='div'>
            1. Set seat height by adjusting seat adjuster.
            <br />
            <br />
            <div>
              <b>Patient Setup Seat Height:</b>
              <div
                style={{
                  padding: 2,
                  border: '1px solid green',
                  fontWeight: 500,
                  borderRadius: '8px',
                  width: '100px',
                  textAlign: 'center',
                  color: 'green',
                }}
              >{`${setupMachineData.seat_pos ?? 0}`}</div>
            </div>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 5 }}>
          <Box>{getInstructions([instructions[1]])}</Box>

          <Typography variant='body1' component='div'>
            2. Check seat data to see if patient needs a seat pad. Ad pad, if needed.
            <br />
            <br />
            <div>
              <b>Patient Setup Seat Pad:</b>
              <br />0 = no seat pad. 1 = 1 seat pad, and 2 = 2 seats pads.
              <div
                style={{
                  padding: 2,
                  border: '1px solid green',
                  fontWeight: 500,
                  borderRadius: '8px',
                  width: '100px',
                  textAlign: 'center',
                  color: 'green',
                }}
              >{`${setupMachineData.seat_pad}`}</div>
            </div>
          </Typography>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </>
  );
};

SeatingPosition.propTypes = {
  step: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default SeatingPosition;
