import { useSocketDataContext } from 'components/WebSocketProvider';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchMachineSetup, fetchPatient, requestInitSetup, requestSaveSetup } from '../slice';
import { isValidBase64 } from 'helpers';
import { fetchHcpConfiguredMachines } from 'modules/Machines/slice';

const navigationItemsContext = createContext({ registerNavigation: (input) => input, navigationItems: [] });

export function useMachineSetup() {
  const patient = useSelector((state) => state.patients.patient);
  const setupMachineData = useSelector((state) => state.patients.setup);
  const socketContext = useSocketDataContext();
  const isInitSetups = useSelector((state) => state.patients.isInitSetups);
  const machines = useSelector((state) => state.machines.hcpConfiguredMachines);
  const { isLoading } = useSelector((state) => state.ui);

  const { id } = useParams();
  const patientId = id;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState('');
  const [subStep, setSubStep] = useState('');
  const [machine, setMachine] = useState(null);

  const [registeredNavItems, setRegisteredNavItems] = useState({});
  const [navigationItems, setNavigationItems] = useState([]);

  const machineId = searchParams.get('machineId');
  const machineType = searchParams.get('machineType');

  const sessionId = searchParams.get('sessionId');

  const setupIdParams = searchParams.get('setupId');
  const setupId = localStorage.getItem('setupId') ?? setupIdParams;

  useEffect(() => {
    setNavigationItems(Object.values(registeredNavItems));
  }, [registeredNavItems]);

  useEffect(() => {
    const data = {
      hcpId: localStorage.getItem('hcpId'),
      patientId: atob(patientId),
      machineId: Number(machineId),
    };

    const s = searchParams.get('step');
    const subS = searchParams.get('subStep');

    setStep(s ?? '1');

    setSubStep(subS ?? '0');
    if (setupId) {
      dispatch(fetchMachineSetup({ ...data, setupId: setupId }));
    }

    if (!setupId && !isInitSetups) {
      dispatch(requestInitSetup(data));
    }
  }, []);

  useEffect(() => { }, [setupMachineData]);

  //update the url with the current step (every time the step or substep changes)
  useEffect(() => {
    const { pathname } = location;

    if (!patientId && !isValidBase64(patientId)) {
      navigate(`/`);
      return;
    }

    if (!sessionId) {
      if (patientId && isValidBase64(patientId)) {
        navigate(`/patient/${patientId}`);
      } else {
        navigate(`/`);
      }

      return null;
    }

    // if (setupMachineData && setupMachineData.data) {
    //   if (!setupMachineData.data.knee_test_mode && step !== '1') {
    //     setSubStep('0');
    //     setStep('1');
    //   }
    // }
    if (setupId) {
      const currentLocation = `${pathname}?machineId=${machineId}&machineType=${machineType}&sessionId=${sessionId}&setupId=${setupId}`;

      if (step || (subStep && subStep !== '0')) {
        // add step and substep to query params
        navigate(`${currentLocation}&step=${step}&subStep=${subStep}`);
      }
    }
  }, [step, subStep, isInitSetups]);

  // get patient by id
  useEffect(() => {
    if (patientId && isValidBase64(patientId)) {
      const data = {
        patientId: atob(patientId),
        hcpId: localStorage.getItem('hcpId'),
      };
      dispatch(fetchPatient(data));
    }
  }, [patientId]);

  useEffect(() => {
    const machineId = searchParams.get('machineId');
    const machineType = searchParams.get('machineType');

    if (machineId && machines.length > 0) {
      const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));
      setMachine(machine);
    }

    if (machineId && machines.length === 0) {
      dispatch(fetchHcpConfiguredMachines({ hcpId: localStorage.getItem('hcpId'), machineType: machineType }));
    }
  }, [machines]);

  //handle select item in sidebar
  const handleSelectItem = (item) => {
    setStep(item.step);
    setSubStep(item.subStep);
  };

  // handle setStep
  const handleSetStep = (value) => {
    setStep(value)
  }

  // handle setSubStep
  const handleSetSubStep = (value) => {
    setSubStep(value)
  }

  //handle back
  const handleBack = () => {
    const path = `/patient/${btoa(patient.patient_id)}/session`;

    let previousStep = step;
    let previousSubStep = subStep;

    if (step === '1') {
      navigate(`${path}/select-machine?sessionId=${sessionId}`);
      return;
    }

    const current = navigationItems.find((item) => item.id === `${step}`);

    if (current && !current.children) {
      previousStep = parseInt(step, 10) - 1;

      const previous = navigationItems.find((item) => item.id === `${previousStep}`);

      if (previous && previous.children) {
        previousSubStep = previous.children.length;
      } else {
        previousSubStep = '0';
      }
    } else {
      if (subStep === '1') {
        previousStep = parseInt(step, 10) - 1;
        const previous = navigationItems.find((item) => item.id === `${previousStep}`);

        if (previous && previous.children) {
          previousSubStep = previous.children.length;
        } else {
          previousSubStep = '0';
        }
      } else {
        previousSubStep = parseInt(subStep, 10) - 1;
      }
    }

    setSubStep(previousSubStep.toString());
    setStep(previousStep.toString());
  };

  const handleNext = () => {
    let nextStep = step;
    let nextSubStep = subStep;

    const current = navigationItems.find((item) => item.id === `${step}`);

    if (current && !current.children) {
      nextStep = parseInt(step, 10) + 1;

      const next = navigationItems.find((item) => item.id === `${nextStep}`);

      if (next && next.children) {
        nextSubStep = '1';
      } else {
        nextSubStep = '0';
      }
    } else {
      if (subStep === `${current.children.length}`) {
        nextStep = parseInt(step, 10) + 1;

        const next = navigationItems.find((item) => item.id === `${nextStep}`);

        if (next && next.children) {
          nextSubStep = '1';
        } else {
          nextSubStep = '0';
        }
      } else {
        nextSubStep = parseInt(subStep, 10) + 1;
      }
    }

    setSubStep(nextSubStep.toString());
    setStep(nextStep.toString());
  };

  const handleSaveData = (data) => {
    // const setupId = localStorage.getItem('setupId');

    if (setupId) {
      dispatch(
        requestSaveSetup({
          hcpId: localStorage.getItem('hcpId'),
          patientId: atob(patientId),
          machineId: machine.machine.id,
          finished: data.counterbalance_gauge !== null,
          setupId,
          data,
        })
      );
    }
  };
  /**
   * add navigation items
   * @param {{id:string; children?:string, name:string}} input
   */
  const registerNavigation = (input) => {
    registeredNavItems[input.id] = input;
    setRegisteredNavItems({ ...registeredNavItems });
  };

  return {
    handleBack,
    handleNext,
    handleSaveData,
    handleSelectItem,
    machineId,
    machine,
    isLoading,
    step,
    subStep,
    handleSetStep,
    handleSetSubStep,
    socketContext,
    Provider: navigationItemsContext.Provider,
    patient,
    registerNavigation,
    navigationItems,
    setupMachineData,
    setupId,
    machineType,
    sessionId,
    setNavigationItems,
  };
}

export function useNavigateItems() {
  const navigationItemsContextData = useContext(navigationItemsContext);
  return navigationItemsContextData;
}
