import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function MachineInfo({ machine }) {
  if (!machine) {
    return <></>;
  }
  const name = `${machine.machine_type} ${machine.name}`;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
      <Typography title={name} sx={{ wordBreak: 'break-all' }} variant='body1' component='div'>
        {name.length > 30 ? `${name.substr(0, 30)}...` : name}
      </Typography>
      <Typography sx={{ wordBreak: 'break-all', fontSize: '0.8rem' }} variant='body1' component='div'>
        Serial Number: {machine.serial_no}
      </Typography>
    </Box>
  );
}

MachineInfo.propTypes = {
  machine: PropTypes.object,
};
