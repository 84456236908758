import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Typography from '@mui/material/Typography';

import torsoRestraints from 'assets/images/SVG/Cervical/Torso-restraints.svg';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import movementArmLock from 'assets/images/SVG/Cervical/Movement-arm-lock.svg';

import PropTypes from 'prop-types';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

export function TightenRestraints({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={movementArmLock} />
            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              1. Instruct the patient to return to the most flexed position, “Chin to chest .”
              <br />
              2. Engage the movement arm lock (to up position).
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={angleSelector} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography sx={{ flex: 1 }} variant='body1' component='div'>
                3. Disengage the angle selector and follow the patient back to a comfortable upright position. Engage
                the angle selector.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={torsoRestraints} />
            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              4. Once again tighten seatbelt and shoulder harness until very snug and secure. <br />
              5. Once again, tighten torso restraint until patient can no longer shrug shoulders easily or
            </Typography>
          </Box>
        </OverlayScrollbarsComponent>
      </Box>
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
TightenRestraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
