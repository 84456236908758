import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import { useEffect, useRef, useState } from 'react';
import { requestSaveSetup } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { InputText } from '../../../../../../../components/InputText';

const requireSmg = '*This field is required.';
export default function GatePosition({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const patientId = id;
  const setupIdParams = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const isUpdate = useRef(null);

  const [gatePos, setGatePos] = useState(null);
  const [errorSmg, setErrorSmg] = useState('');

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setGatePos(setupMachineData.torso_pad_pos ?? null);
  }, [setupMachineData]);

  useEffect(() => {
    if (!isUpdate.current) {
      return null;
    }

    if (isUpdating) {
      return null;
    }

    handleNext();
  }, [isUpdating]);

  const onNextHandler = () => {
    if (!setupMachineData) {
      return null;
    }

    if (gatePos === setupMachineData.torso_pad_pos) {
      handleNext();
      return;
    }

    const setupId = localStorage.getItem('setupId') || setupIdParams;
    isUpdate.current = true;
    dispatch(
      requestSaveSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(patientId),
        machineId: machineId,
        finished: false,
        setupId,
        data: {
          torso_pad_pos: gatePos,
        },
      })
    );
  };

  const onChangeHandler = (value) => {
    setGatePos(value);
    if (value === '') {
      setErrorSmg(requireSmg);
      return;
    }

    setErrorSmg('');
  };

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              1. Use the Gate Height Adjustment to raise or lower the Torso Restraints to the correct position. The top
              of the Toros Restraint pads should be slightly above the shoulder while the post holding the pad should be
              level with the shoulder joint.
              <br />
              2. Rotate the Gate Lock inwards to lock the gate, and tighten the Torso Restraints. Avoid over tightening
              which might restrict blood flow.
              <br />
              3. Input the Gate Position to the nearest .5.
              <Box>
                <div>
                  <b>Gate Position</b>
                  <br />
                  Enter the number with decimal point.
                </div>

                <Box sx={{ width: '200px' }}>
                  <InputText
                    isPositive={true}
                    errorSmg={errorSmg}
                    error={errorSmg !== ''}
                    onChange={onChangeHandler}
                    value={gatePos}
                    type={'number'}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              4. Have patient turn heels slightly out and toes slightly in on the footboard.
              <br />
              5. Have patient squeeze the pad in between their knees (with their knees) while tightening footboard.
              <br />
              6. Tighten footboard adjustment until patient is very snug and they cannot lift their heels of off
              footboard.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={onNextHandler}
        nextLoading={isUpdating}
        disableNext={gatePos === '' || gatePos === null || Number(gatePos) < 0 || errorSmg !== ''}
      />
    </Box>
  );
}

GatePosition.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
