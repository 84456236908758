import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IsometricRechart from 'components/Charts/IsometricReChart';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Actions from 'modules/Patients/components/Machines/Actions';
import { ISOMETRIC_YAXIS } from 'modules/Patients/constants';
import { useSearchParams } from 'react-router-dom';

const IsometricPracticeSummary = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  testMode,
  firstSide,
  preSetAnglePractice,
  handleRemoveSkip,
}) => {
  const [xAxis, setXAxis] = useState([]);
  const [data, setData] = useState(null);
  const [showLine, setShowLine] = useState(true);
  const [currentSide, setCurrentSide] = useState(0);
  const [searchParams] = useSearchParams();
  const machineType = searchParams.get('machineType');
  useEffect(() => {
    if (testMode === 3) {
      setCurrentSide(firstSide === 1 ? 2 : 1);
      return null;
    }
    setCurrentSide(firstSide);
  }, [firstSide]);

  useEffect(() => {
    if (!preSetAnglePractice) {
      return;
    }

    setXAxis([preSetAnglePractice.s3Angle.name, preSetAnglePractice.s2Angle.name, preSetAnglePractice.s1Angle.name]);
    setData([preSetAnglePractice.s1Angle, preSetAnglePractice.s2Angle, preSetAnglePractice.s3Angle]);
  }, [preSetAnglePractice]);

  const nextHandler = () => {
    handleRemoveSkip(step);
    handleNext();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        justifyContent: 'space-between',
      }}
    >
      {xAxis && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ textAlign: 'center' }}>
            <b>{currentSide === 1 ? 'Left' : 'Right'} Side</b>{' '}
          </Box>
          <IsometricRechart
            showLine={showLine}
            yAxisRange={ISOMETRIC_YAXIS.CERVICAL_ROTATION}
            showActive={false}
            xAxisRange={xAxis}
            data={data || []}
            reversed={currentSide === 1}
            machineType={machineType}
          />

          <Box sx={{ textAlign: 'end' }}>
            <FormControlLabel
              control={<Checkbox checked={showLine} onChange={() => setShowLine(!showLine)} />}
              label='Overlay Line Graph'
            />
          </Box>
        </div>
      )}
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleCancel={handleCancel}
        handleNext={nextHandler}
      />
    </Box>
  );
};

IsometricPracticeSummary.propTypes = {
  machineSetup: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
  preSetAnglePractice: PropTypes.object,
  firstSide: PropTypes.number,
  testMode: PropTypes.number,
};
export default IsometricPracticeSummary;
