import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import noImg from 'assets/images/png/noImage.png';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { getInstructions } from 'helpers';

const instructions = [
  {
    id: 1,
    text: '4. Disengage angle selector. SingleSelect 0° and engaged angle selector.',
    img: noImg,
  },
  {
    id: 2,
    text: '5. Turn both range of motion limiters clockwise until they stop.',
    img: noImg,
  },
  {
    id: 3,
    text: '6. Loosen chain tightener.',
    img: noImg,
  },
];

export default function Preparation({ step, handleCancel, handleNext, subStep }) {
  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Typography variant='body1' component='div'>
          1. Before starting, make sure you have all medical information about the patient updated.
          <br />
          2. Ask patient if they need to use the restroom as the bladder area will be compressed.
          <br />
          3. Have patient remove belts or restrictive garments and empty object from pockets.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>{getInstructions(instructions)}</Box>
      </OverlayScrollbarsComponent>

      <Actions step={step} subStep={subStep} handleCancel={handleCancel} handleNext={handleNext} />
    </Box>
  );
}

Preparation.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
