import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const ExerciseInstructions = ({ step, handleCancel, handleNext, subStep, handleBack }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box>
          1. Provide the following dynamic exercise instructions:
          <div style={{ padding: '0 20px' }}>
            • Exercise through the full range of motion.
            <br />
            • Exercise is both positive and negative.
            <br />
            • Perform each repetition slowly (2-1-4) second count.
            <br />
            • Visual feedback is provided on the monitor.
            <br />
            • You will get an audible warning when you have completed the full range.
            <br />
            • Demonstrate how to rotate and push against the resistance pad.
            <br />
            • Avoid holding your breath, exhale when building force.
            <br />
            • Maintain hands resting in your lap.
            <br />
            • Maintain contact with the seatback.
            <br />
            • Exert force only when clinician instructs to do so.
            <br />• Turn your head in the opposite direction of the applied force and position your head in a neutral
            forward position during essentric movement.
          </div>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        handleBack={handleBack}
        subStep={subStep}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
};

ExerciseInstructions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  phase: PropTypes.number,
};

export default ExerciseInstructions;
