import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import WestIcon from '@mui/icons-material/West';
import Typography from '@mui/material/Typography';
import ConfirmDialog from 'components/ConfirmDialog/index.jsx';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  clearPatientSessions,
  clearPatientSetup,
  fetchPatientSession,
  fetchPatientSessions,
  fetchPatientSetups,
  requestEndSession,
  fetchPatient,
  updateDemoGraphicQuestion,
} from '../../slice';
import Container from '@mui/material/Container';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import TableSessionSetups from './TableSessionSetups';
import useCurrentUser from 'hooks/useCurrentUser';
import { PatientInfo } from 'modules/Patients/components/Info';
import { Modal } from 'components/Modal/index.jsx';
import { fetchHcpMachines } from '../../../Machines/slice';
import ActivitiesTable from './ActivitiesTable';
import { dateParserWithTimezone } from 'helpers/datetime.js';
import { format } from 'date-fns';
import { FORMAT_DATE } from 'modules/Patients/constants.js';
import HealthInformation from './HealthInformation.jsx';
import DemoGraphicInformation from 'modules/Patients/containers/Session/DemoGraphicInformation/index.jsx';
import { removeSensitiveData } from 'helpers';
import DemoGraphicModal from 'modules/DemoGraphic/index.jsx';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import { openDialog } from 'helpers/dialog.js';
import PushEMRModal from 'modules/EMR/components/PushEMRModal.jsx';
import { getEMRSettings } from 'modules/EMR/slice.js';
import { clearLocalPatientSetupData } from 'modules/Patients/utils';

export default function PatientsSessionDetails() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const sessionId = searchParams.get('sessionId');
  const hcpId = localStorage.getItem('hcpId');
  const { t } = useTranslation();
  const { id } = useParams();
  const patientId = id;
  const tabId = searchParams.get('tab');
  const navigate = useNavigate();
  const {
    permissions: currentPermissions,
    user: {
      hcp: { enabled_feature: enabledFeature },
    },
  } = useCurrentUser();
  const patient = useSelector((state) => state.patients.patient);
  const patientSetups = useSelector((state) => state.patients.setups);
  const sessions = useSelector((state) => state.patients.sessions);
  const _session = useSelector((state) => state.patients.session);
  const machines = useSelector((state) => state.machines.hcpMachines);
  const s = useSelector((state) => state.patients.session);
  const { emr } = useSelector((state) => state.emrSettings);
  const [showModal, setShowModal] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [tab, setTab] = useState('1');
  const [anchorEl, setAnchorEl] = useState(null);

  const [healthInformation, setHealthInformation] = useState(null);
  const [specialInformation, setSpecialInformation] = useState(null);

  const [defaultHealthInformation, setDefaultHealthInformation] = useState(null);
  const [defaultSpecialInformation, setDefaultSpecialInformation] = useState(null);

  const [initSessionTime, setInitSessionTime] = useState('');
  const [defaultSteps, setDefaultSteps] = useState(1);

  const [openPushEMR, setOpenPushEMR] = useState(false);
  const [disablePushing, setDisablePushing] = useState(true);

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions.includes('EXECUTE_ACTIVITIES');
  };

  const hasViewHistoryPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_HISTORY');
  };

  // const hasViewActivitiesPermission = () => {
  //   return currentPermissions.includes('VIEW_PATIENT_ACTIVITIES');
  // };

  useEffect(() => {
    if (!sessions || (sessions && sessions.length === 0)) {
      const data = {
        patientId: atob(patientId),
        hcpId,
      };
      dispatch(fetchPatient(data));
      dispatch(fetchPatientSessions(data));
    }
    dispatch(getEMRSettings({ hcpId }));

    dispatch(fetchHcpMachines({ hcpId }));
    if (patientSetups === null) {
      dispatch(fetchPatientSetups({ hcpId, patientId: atob(patientId) }));
    }
    return () => {
      dispatch(clearPatientSessions());
      dispatch(clearPatientSetup());
    };
  }, []);

  useEffect(() => {
    if (!sessions) {
      return null;
    }
    const session = sessions.filter((s) => s.id.toString() === sessionId.toString())[0];
    setCurrentSession(session);
    dispatch(
      fetchPatientSession({
        hcpId,
        patientId: atob(id),
        sessionId,
      })
    );
  }, [sessions]);

  useEffect(() => {
    if (currentSession) {
      const path = `/patient/${patientId}/patient-detail?sessionId=${currentSession.id}`;
      let tab = '1';
      if (tabId && hasViewHistoryPermission()) {
        tab = tabId;
      } else {
        navigate('/');
      }

      navigate(`${path}&tab=${tab}`);
      setTab(tab);
    }
  }, [currentSession]);
  useEffect(() => {
    if (!_session) {
      return;
    }
    const localDate = dateParserWithTimezone(_session.initiated_at);
    setInitSessionTime(format(localDate, 'h:mm aa, LLL d yyyy'));

    setDefaultHealthInformation(_session.demographic.health_information);
    setDefaultSpecialInformation(_session.demographic.special_information);
  }, [_session]);

  const onFinishHandler = ({ type, healthInformation, specialInformation, callback }) => {
    if (!_session || !patient) {
      return;
    }

    const _healthInformation = removeSensitiveData(healthInformation);
    const _specialInformation = removeSensitiveData(specialInformation);

    const payload = {
      patient_type: type,
      health_information: _healthInformation,
      special_information: _specialInformation,
    };

    dispatch(
      updateDemoGraphicQuestion({
        hcpId: hcpId,
        patientId: patient.patient_id,
        sessionId: _session.id,
        data: JSON.parse(JSON.stringify(payload)),
        callback: (success) => {
          callback(success);
          dispatch(
            fetchPatientSession({
              hcpId,
              patientId: atob(id),
              sessionId,
            })
          );
        },
      })
    );
  };

  const onPushingDoneHandler = () => {
    setOpenPushEMR(false);
    setDisablePushing(true);
  };

  const handleChange = (event, newValue) => {
    const session = sessions.filter((s) => s.id.toString() === sessionId.toString())[0];
    const path = `/patient/${patientId}/patient-detail?sessionId=${session.id}`;
    navigate(`${path}&tab=${newValue}`);
    setTab(newValue);
  };

  const handleButtonClick = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleSelectClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const createPatientMachineSetup = () => {
    clearLocalPatientSetupData();
    navigate(`/patient/${patientId}/session/select-machine?sessionId=${sessionId}`);
  };

  const openEndMachineSetup = () => {
    setOpenAlert(true);
  };
  const handleCloseConfirmDialog = () => {
    setOpenAlert(false);
  };
  const handleSubmitConfirmDialog = () => {
    setOpenAlert(false);

    dispatch(requestEndSession({ hcpId, patientId: atob(patientId), sessionId }));
  };

  const activityRender = () => {
    return (
      <Box sx={{ height: '45vh' }}>
        <ActivitiesTable
          session={s}
          emptySmg={'No Activities'}
          machines={machines}
          setDisablePushEMR={setDisablePushing}
        />
      </Box>
    );
  };

  const patientMachineSetupList = () => {
    const machineRender = ({ cellData }) => {
      return (
        <>
          <Typography variant='body1' component='div'>
            {cellData.name}
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }} variant='body2' component='div'>
            SN: {cellData.serial_no}
          </Typography>
        </>
      );
    };

    const columns = [
      {
        title: 'Date & Time',
        dataKey: 'date',
        key: 'column-1',
        width: 0.3,
      },
      {
        title: 'Setup',
        dataKey: 'name',
        key: 'column-2',

        width: 0.3,
      },
      {
        title: 'Machines Type',
        dataKey: 'type',
        key: 'column-3',
        width: 0.2,
      },
      {
        title: 'Machines',
        dataKey: 'originalMachineData',
        key: 'column-4',
        cellRenderer: machineRender,
        width: 0.2,
      },
    ];

    return (
      <Box sx={{ height: '45vh' }}>
        <Box
          sx={{
            fontSize: '14px',
            textAlign: 'end',
            width: '100%',
            display: 'flex',
            gap: 1,
            justifyContent: 'end',
          }}
        >
          <span>
            <InfoIcon fontSize={'small'} />
          </span>

          <span>All setups belong to the removed machines will be disabled.</span>
        </Box>
        <TableSessionSetups
          disableExtra={true}
          tab={tab}
          session={session}
          columns={columns}
          machines={machines}
          type={'machine'}
          emptySmg={'No Patient Machines Setup'}
        />
      </Box>
    );
  };
  const session = sessions.filter((s) => s.id.toString() === sessionId.toString())[0];

  const onNewActivitiesClicked = (type) => {
    dispatch(
      fetchPatientSetups({
        hcpId,
        patientId: atob(patientId),
        callback: (length) => {
          if (!length) {
            setShowModal(true);
            return;
          }
          navigate(`/patient/${patientId}/setups?type=${type}&sessionId=${sessionId}&disableExtra=false`);
        },
      })
    );
  };
  const onEditDemographicOpenHandler = (step) => {
    setDefaultSteps(step);
    setHealthInformation(null);
    setSpecialInformation(null);
    openDialog(dispatch, DIALOGS.OPEN_DEMO_GRAPHIC);
  };

  const getPatientTypeHandler = () => {
    if (!_session && !patient) {
      return null;
    }

    if (_session && _session.demographic && _session.demographic.patient_type) {
      return _session.demographic.patient_type;
    }

    return patient.type;
  };

  if (!patient) return <></>;
  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          minHeight: 'calc(100vh - 118px)',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} maxWidth='lg'>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', width: ' 100%', pb: 5, alignItems: 'start', gap: 2 }}>
                <IconButton
                  onClick={() => {
                    clearLocalPatientSetupData();
                    navigate(`/patient/${patientId}&tab=1`);
                  }}
                >
                  <WestIcon />
                </IconButton>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h5' component='div'>
                    {currentSession?.name || ''}
                  </Typography>
                  <span>
                    {currentSession?.initiated_at
                      ? format(dateParserWithTimezone(currentSession.initiated_at), FORMAT_DATE)
                      : ''}
                  </span>
                </Box>

                <Box
                  sx={{
                    ml: 'auto',
                    '.MuiAvatar-root': { display: 'none' },
                    '.MuiTypography-root': {
                      color: '#000',
                      fontSize: '16px',
                    },
                  }}
                >
                  <PatientInfo patient={patient} />
                </Box>
              </Box>
            </Box>

            <TabContext sx={{ border: 'none' }} value={tab}>
              <Box sx={{ display: 'flex' }}>
                <TabList onChange={handleChange}>
                  <Tab label='Activities' value='1' />
                  <Tab label='Patient machine setup list' value='2' />
                  {enabledFeature?.demographic && [
                    <Tab label='Health Information' value='3' key='3' />,
                    <Tab label='Demographic Information' value='4' key='4' />,
                  ]}
                </TabList>
                <Box sx={{ ml: 'auto' }}>
                  {tab === '1' && !!Object.entries(enabledFeature.emr).filter(([key, value]) => value).length && (
                    <Button
                      onClick={() => setOpenPushEMR(true)}
                      disabled={!emr || (emr && emr.length === 0)}
                      sx={{ alignSelf: 'baseline', margin: '0 10px' }}
                      variant='outlined'
                      color='secondary'
                    >
                      Push remaining data
                    </Button>
                  )}

                  {tab === '1' && hasExecuteActivitiesPermission() && (
                    <Button
                      disabled={!patientSetups || !!session?.ended_at}
                      id='drop-down-button-detail'
                      aria-controls={openMenu ? 'drop-down-menu-detail' : undefined}
                      aria-haspopup='true'
                      aria-expanded={openMenu ? 'true' : undefined}
                      onClick={handleButtonClick}
                      endIcon={<ArrowDropDownOutlinedIcon />}
                      variant='contained'
                    >
                      {t('common:newActivity')}
                    </Button>
                  )}
                  <Menu
                    id='drop-down-menu-detail'
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleSelectClose}
                    MenuListProps={{
                      'aria-labelledby': 'drop-down-button-detail',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        onNewActivitiesClicked('Isometric');
                      }}
                      key={1}
                    >
                      {t('customer:patientSession:isometricTest')}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        onNewActivitiesClicked('Dynamic');
                      }}
                      key={2}
                    >
                      {t('customer:patientSession:dynamicExercise')}
                    </MenuItem>
                  </Menu>
                  {tab === '2' && hasExecuteActivitiesPermission() && (
                    <Button disabled={!!session?.ended_at} variant='contained' onClick={createPatientMachineSetup}>
                      NEW SETUP
                    </Button>
                  )}
                </Box>
              </Box>
              <TabPanel sx={{ paddingLeft: 0, paddingRight: 0, minHeight: '50vh' }} value='1'>
                {activityRender()}
              </TabPanel>
              <TabPanel sx={{ paddingLeft: 0, paddingRight: 0, minHeight: '50vh' }} value='2'>
                {patientMachineSetupList()}
              </TabPanel>
              {enabledFeature?.demographic && [
                <TabPanel sx={{ paddingLeft: 0, paddingRight: 0, minHeight: '50vh' }} value='3' key='healthinfo'>
                  <HealthInformation
                    healthInformation={_session ? _session.demographic.health_information : null}
                    onEdit={onEditDemographicOpenHandler}
                  />
                </TabPanel>,
                <TabPanel sx={{ paddingLeft: 0, paddingRight: 0, minHeight: '50vh' }} value='4' key='demographic'>
                  <DemoGraphicInformation
                    demoGraphicInformation={_session ? _session.demographic.special_information : null}
                    patientType={getPatientTypeHandler()}
                    onEdit={onEditDemographicOpenHandler}
                  />
                </TabPanel>,
              ]}
              {hasExecuteActivitiesPermission() && (
                <Box sx={{ display: 'flex', padding: '15px 0' }}>
                  <Button
                    sx={{ ml: 'auto' }}
                    disabled={!!session?.ended_at}
                    variant='outlined'
                    onClick={openEndMachineSetup}
                  >
                    End Session
                  </Button>
                </Box>
              )}
            </TabContext>
          </Box>
        </Box>
      </Box>

      <Modal
        keep={true}
        showModal={showModal}
        isClose={true}
        onClose={() => setShowModal(false)}
        content={() => 'To start an activity, you need to create a Patient Machines Setup first.'}
        extra={[
          <Button onClick={() => setShowModal(false)} variant='outlined' key={'btn-1'}>
            {t('common:cancel')}
          </Button>,
          <Button
            variant='contained'
            onClick={() => {
              clearLocalPatientSetupData();
              navigate(`/patient/${patientId}/session/select-machine?sessionId=${sessionId}`);
            }}
            key={'btn-2'}
          >
            CREATE NEW SETUP
          </Button>,
        ]}
      />
      <ConfirmDialog
        message={'Are you sure you want to end this latest session?'}
        confirmText='Ok'
        handleCloseConfirmDialog={handleCloseConfirmDialog}
        openConfirmDialog={openAlert}
        handleSubmitConfirmDialog={handleSubmitConfirmDialog}
      />
      <DemoGraphicModal
        date={initSessionTime}
        setHealthData={setHealthInformation}
        healthData={healthInformation}
        setSpecialData={setSpecialInformation}
        specialData={specialInformation}
        onFinish={onFinishHandler}
        isEdit={true}
        patientType={getPatientTypeHandler()}
        defaultStep={defaultSteps}
        defaultSpecialData={defaultSpecialInformation}
        defaultHealthData={defaultHealthInformation}
      />
      <PushEMRModal
        onClose={() => setOpenPushEMR(false)}
        open={openPushEMR}
        sessionId={sessionId}
        onDone={onPushingDoneHandler}
      />
    </Container>
  );
}

PatientsSessionDetails.propTypes = {
  session: PropTypes.object,
  handleCloseSessionDetails: PropTypes.func,
};
