import PropTypes from 'prop-types';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { END_POINT } from 'modules/Patients/constants';

import debounce from 'lodash/debounce';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { allowAlphaNumericSpace } from 'helpers';

const drawerWidth = '50%';

export default function EditPatientDialog({
  patient,
  defaultCountry,
  defaultState,
  defaultCity,
  anchor,
  error,
  onSubmitEditPatientDialog,
  openEditPatientDialog,
  handleCloseEditPatientDialog,
}) {
  const defaultValues = {
    address: patient.address,
    firstName: patient.fname,
    lastName: patient.lname,
    dob: patient.dob,
    gender: patient.gender,
    mobile: patient.mobile_no,
    homePhone: patient.home_no,
    email: patient.email,
    country: defaultCountry,
    state: defaultState,
    city: defaultCity,
    zipCode: patient.zip_code,
    note: patient.notes,
    type: patient.type,
  };

  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const [countries, setCountryOptions] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();

  const [selectedState, setSelectedState] = useState();
  const [states, setStateOptions] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [stateKeyword, setSearchStateKeyword] = useState();

  const [cities, setCitiesOptions] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [cityKeyword, setSearchCityKeyword] = useState();

  const [customError, setCustomError] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (openEditPatientDialog) {
      if (defaultCountry) {
        setSelectedCountry(defaultCountry);
      }
      if (defaultState) {
        setSelectedState(defaultState);
      }
    }
  }, [openEditPatientDialog]);

  useEffect(() => {
    if (error && error.code === 400 && error.subcode === 400.1) {
      setError('email', {
        type: 'email_exists',
      });
    }
    if (error && error.code === 400 && error.subcode === 400.2) {
      setError('mobile', {
        type: 'mobile_exists',
      });
    }
  }, [error]);

  const getPatientAlreadyExistMessage = () => {
    if (error && error.code === 400 && error.subcode === 400.3) return t('customer:addPatientDialog.errors.patientExists');
  };

  useEffect(() => {
    if (customError) {
      setError(customError.field, {
        type: customError.type,
      });
    }
  }, [customError]);

  useEffect(() => {
    if (selectedCountry) {
      getStates();
    }
  }, [selectedCountry, stateKeyword]);

  useEffect(() => {
    if (selectedState) {
      getCities();
    }
  }, [selectedState, cityKeyword]);

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return null;
  };

  const closeModal = () => {
    if (handleCloseEditPatientDialog) {
      handleCloseEditPatientDialog();
    }
    reset(defaultValues);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    const submitData = { ...data, patientId: patient.patient_id };
    if (onSubmitEditPatientDialog) {
      onSubmitEditPatientDialog(submitData);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  const getDate = (dateTime) => {
    if (dateTime && (typeof dateTime === 'string' || dateTime instanceof String)) {
      const [year, month, day] = dateTime.split('/');
      return `${year}-${month}-${day}`;
    }

    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const getCountries = () => {
    setLoadingCountries(true);

    const keyword = '';
    axios.get(`${END_POINT.getCountries.url}&name=${encodeURIComponent(keyword.trim())}`).then((resp) => {
      const countries = resp.data;
      setCountryOptions(countries);
      setLoadingCountries(false);
    });
  };

  const getStates = () => {
    setLoadingStates(true);

    const keyword = stateKeyword || '';
    axios
      .get(`${END_POINT.getRegions.url(selectedCountry.iso2)}&name=${encodeURIComponent(keyword.trim())}`)
      .then((resp) => {
        let states = [{ name: 'None', iso2: -1 }];
        if (resp.data) {
          states = [...states, ...resp.data];
        }

        setStateOptions(states);
        setLoadingStates(false);
      });
  };

  const getCities = () => {
    if (selectedState.iso2 === -1) return setCitiesOptions([{ name: 'None', id: -1 }]);

    setLoadingCities(true);

    const keyword = cityKeyword || '';
    axios
      .get(
        `${END_POINT.getCitiesFromRegions.url(selectedCountry.iso2, selectedState.iso2)}&name=${encodeURIComponent(
          keyword.trim()
        )}`
      )
      .then((resp) => {
        let cities = [{ name: 'None', id: -1 }];
        if (resp.data) {
          cities = [...cities, ...resp.data];
        }
        setCitiesOptions(cities);
        setLoadingCities(false);
      });
  };

  const handleCountryChange = (item) => {
    setValue('state', null, { shouldValidate: false });
    setValue('city', null, { shouldValidate: false });
    setSelectedState(null);
    setSelectedCountry(item);
  };

  const handleStateChange = (item) => {
    setValue('city', null, { shouldValidate: false });
    setSelectedState(item);
  };

  const handleStateSearch = (e, value, reason) => {
    if (reason === 'reset') return;
    setSearchStateKeyword(value);
  };

  const debouncedHandleStateTextChange = debounce(handleStateSearch, 1000);

  const handleCitySearch = (e, value, reason) => {
    if (reason === 'reset') return;
    setSearchCityKeyword(value);
  };

  const debouncedHandleCityTextChange = debounce(handleCitySearch, 1000);

  const disableEdit = () => {
    if (!patient.type) {
      return false;
    }

    return !isDirty;
  };

  const getEmailErrorMessage = (error) => {
    if (error?.type === 'pattern') return t('customer:addPatientDialog.errors.invalidEmailPattern');
    if (error?.type === 'email_exists') return t('customer:addPatientDialog.errors.emailExists');
  };

  const getNameErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:addPatientDialog.errors.invalidNamePattern');
  };

  const getDateErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:addPatientDialog.errors.invalidDatePattern');
    if (error?.type === 'dob_future') {
      return t('customer:addPatientDialog.errors.futureDate');
    }
  };



  const renderContent = () => (
    <Box sx={{ width: '65%', position: 'relative' }} role='presentation'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography sx={{ fontSize: '1.5rem', pt: 2, fontWeight: 500 }} variant='h2'>
            {t('customer:addPatientDialog.title')}
          </Typography>

          <TextField margin='normal' fullWidth id='patientId' value={patient.patient_id} label='Patient ID' disabled />

          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', padding: '10px 0' }}>
            <span> This patient's occupation is:*</span>

            <Controller
              name='type'
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  name='radio-buttons-group'
                  sx={{ flexDirection: 'row', gap: 1 }}
                  onChange={onChange}
                  value={value ?? 'Military'}
                >
                  <FormControlLabel value='MILITARY' control={<Radio />} label='Military' />
                  <FormControlLabel value='CIVILIAN' control={<Radio />} label='Civilian' />
                  <FormControlLabel value='ATHLETE' control={<Radio />} label='Athlete' />
                </RadioGroup>
              )}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '48%' }}>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z0-9 ]*$/,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={!!errors.firstName}
                    helperText={getNameErrorMessage(errors.firstName)}
                    margin='normal'
                    fullWidth
                    id='firstName'
                    value={value}
                    label={t('customer:addPatientDialog.firstName')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: '48%' }}>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z0-9 ]*$/,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={!!errors.lastName}
                    helperText={getNameErrorMessage(errors.lastName)}
                    margin='normal'
                    fullWidth
                    value={value}
                    id='lastName'
                    label={t('customer:addPatientDialog.lastName')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                width: '48%',
                '.MuiTextField-root': {
                  width: '100%',
                },
              }}
            >
              <Controller
                name='dob'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    pattern: (v) => {
                      if (v && (typeof v === 'string' || v instanceof String)) return true;
                      const s = getDate(v);
                      if (!s || s === 'NaN-NaN-NaN') return false;
                      return s.split('-')[2].length === 4;
                    },
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      defaultValue={null}
                      id='dob'
                      label={t('customer:addPatientDialog.dob')}
                      value={value}
                      onChange={onChange}
                      minDate={new Date('1900-01-01')}
                      onError={(error) => {
                        if (error === 'disableFuture') {
                          setCustomError({
                            field: 'dob',
                            type: 'dob_future',
                          });
                        }
                      }}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          helperText={getDateErrorMessage(errors.dob)}
                          margin='normal'
                          {...params}
                          error={!!errors.dob}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Box>
            <Box sx={{ width: '48%' }}>
              <Controller
                name='gender'
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl error={!!errors.gender} sx={{ width: '100%' }} margin='normal'>
                    <InputLabel>{t('customer:addPatientDialog.gender')}</InputLabel>
                    <Select value={value} label={t('customer:addPatientDialog.gender')} onChange={onChange}>
                      <MenuItem value={'1'}>Male</MenuItem>
                      <MenuItem value={'0'}>Female</MenuItem>
                    </Select>
                    <FormHelperText error={!!errors.gender}>
                      {errors.gender && t('common:errors.required')}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
          </Box>
          <Controller
            name='address'
            control={control}
            rules={{
              required: true,
              validate: {
                required: (v) => !!v.trim(),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={!!errors.address}
                helperText={errors.address && t('common:errors.required')}
                margin='normal'
                fullWidth
                id='address'
                value={value}
                label={t('customer:addPatientDialog.address')}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^[a-zA-Z0-9 .&,\-]*$/.test(input)) {
                    onChange(input);
                  }
                }}
                inputProps={{
                  maxLength: 255,
                }}
              />
            )}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '48%' }}>
              <Controller
                name='country'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    getOptionLabel={(option) => (option ? `${option.name}` : '')}
                    isOptionEqualToValue={(option, value) =>
                      value === undefined || value === '' || option.iso2 === value.iso2
                    }
                    renderOption={(props, option) => (
                      <Box component='li' {...props} key={option.iso2}>
                        {option.name}
                      </Box>
                    )}
                    value={value}
                    // onInputChange={debouncedhandleTextChange}
                    onChange={(event, item) => {
                      onChange(item);
                      handleCountryChange(item);
                    }}
                    options={countries}
                    noOptionsText={t('common:noDataFound')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete='country'
                        error={!!errors.country}
                        margin='normal'
                        helperText={!!errors.country && t('common:errors.required')}
                        label={t('customer:addPatientDialog.country')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingCountries ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: '48%' }}>
              <Controller
                name='state'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    getOptionLabel={(option) => (option ? `${option.name}` : '')}
                    isOptionEqualToValue={(option, value) =>
                      value === undefined || value === '' || option.iso2 === value.iso2
                    }
                    renderOption={(props, option) => (
                      <Box component='li' {...props} key={option.iso2}>
                        {option.name}
                      </Box>
                    )}
                    disabled={!selectedCountry}
                    value={value}
                    onInputChange={debouncedHandleStateTextChange}
                    onChange={(event, item) => {
                      onChange(item);
                      handleStateChange(item);
                    }}
                    options={states}
                    noOptionsText={t('common:noDataFound')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete='state'
                        error={!!errors.state}
                        margin='normal'
                        helperText={!!errors.state && t('common:errors.required')}
                        label={t('customer:addPatientDialog.state')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingStates ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                width: '48%',
                '& .Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #d32f2f',
                },
              }}
            >
              <Controller
                name='city'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    getOptionLabel={(option) => (option ? `${option.name}` : '')}
                    isOptionEqualToValue={(option, value) =>
                      value === undefined || value === '' || option.id === value.id
                    }
                    renderOption={(props, option) => (
                      <Box component='li' {...props} key={option.id}>
                        {option.name}
                      </Box>
                    )}
                    disabled={!selectedState}
                    noOptionsText={t('common:noDataFound')}
                    options={cities}
                    value={value}
                    onInputChange={debouncedHandleCityTextChange}
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete='city'
                        error={!!errors.city}
                        margin='normal'
                        helperText={!!errors.city && t('common:errors.required')}
                        label={t('customer:addPatientDialog.city')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingCities ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                width: '48%',
              }}
            >
              <Controller
                name='zipCode'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowAlphaNumericSpace(e);
                    }}
                    error={!!errors.zipCode}
                    helperText={getNameErrorMessage(errors.zipCode)}
                    margin='normal'
                    fullWidth
                    id='zipCode'
                    value={value}
                    label={t('customer:addPatientDialog.zipCode')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <FormControl
            error={!!errors.mobile}
            fullWidth
            sx={{
              mt: 2,

              '&:focus-within': {
                '.special-label': {
                  color: '#ED9A00',
                },

                '& .error': {
                  input: {
                    boxShadow: '0 0 0 1px #d32f2f !important',
                  },
                },
              },

              '.form-control:focus': {
                boxShadow: '0 0 0 1px #ED9A00 !important',
                borderColor: '#ED9A00 !important',
              },

              '& .error': {
                input: {
                  // boxShadow: '0 0 0 1px #d32f2f !important',
                  boxShadow: 'none !important',
                  border: '1px solid #d32f2f !important',
                },
                '.special-label': {
                  color: '#d32f2f',
                },
              },

              '.search-box': {
                height: '40px',
                width: '250px',
              },
            }}
          >
            <Controller
              name='mobile'
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  inputStyle={{
                    width: '100%',
                    height: '56px',
                    fontSize: '15px',
                    paddingLeft: '60px',
                    borderRadius: '4px',
                  }}
                  id='mobile'
                  country={'us'}
                  value={value}
                  containerClass={errors.mobile ? 'error' : ''}
                  enableSearch
                  onChange={onChange}
                  searchNotFound={'No data found.'}
                  specialLabel={t('customer:addPatientDialog.mobile')}
                  placeholder={''}
                />
              )}
            />
            <FormHelperText error={!!errors.mobile}>
              {errors.mobile && t('customer:addPatientDialog.errors.mobileExists')}
            </FormHelperText>
          </FormControl>
          <FormControl
            error={!!errors.homePhone}
            fullWidth
            sx={{
              mt: 2,
              '&:focus-within': {
                '.special-label': {
                  color: '#ED9A00',
                },
                '& .error': {
                  input: {
                    boxShadow: '0 0 0 1px #d32f2f !important',
                  },
                },
              },
              '.form-control:focus': {
                boxShadow: '0 0 0 1px #ED9A00 !important',
                borderColor: '#ED9A00 !important',
              },
              '& .error': {
                input: {
                  // boxShadow: '0 0 0 1px #d32f2f !important',
                  boxShadow: 'none !important',
                  border: '1px solid #d32f2f !important',
                },
                '.special-label': {
                  color: '#d32f2f',
                },
              },
              '.search-box': {
                height: '40px',
                width: '250px',
              },
            }}
          >
            <Controller
              name='homePhone'
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  inputStyle={{
                    width: '100%',
                    height: '56px',
                    fontSize: '15px',
                    paddingLeft: '60px',
                    borderRadius: '4px',
                  }}
                  id='homePhone'
                  country={'us'}
                  value={value}
                  containerClass={errors.homePhone ? 'error' : ''}
                  enableSearch
                  onChange={onChange}
                  searchNotFound={'No data found.'}
                  specialLabel={t('customer:addPatientDialog.homePhone')}
                  placeholder={''}
                />
              )}
            />
            <FormHelperText error={!!errors.homePhone}>
              {errors.homePhone && t('common:errors.required')}
            </FormHelperText>
          </FormControl>
          <Controller
            name='email'
            rules={{
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={!!errors.email}
                helperText={getEmailErrorMessage(errors.email)}
                margin='normal'
                fullWidth
                id='email'
                value={value}
                label={t('common:emailAddress')}
                inputProps={{
                  maxLength: 255,
                }}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name='note'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin='normal'
                fullWidth
                id='note'
                multiline
                rows={4}
                value={value}
                label={t('customer:addPatientDialog.notes')}
                onChange={onChange}
                inputProps={{
                  maxLength: 650,
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            color: '#d32f2f',
            justifyContent: 'flex-start',
            width: '100%',
            my: 2
          }}
        >
          <Typography
            sx={{
              width: '100%',
              fontSize: '1.1rem',
            }}
          >
            {getPatientAlreadyExistMessage()}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end' }}>
          <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            disabled={disableEdit()}
            variant='contained'
          >
            {t('common:save')}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      anchor={anchor}
      open={openEditPatientDialog}
      onClose={onClose}
    >
      {renderContent()}
    </Drawer>
  );
}

EditPatientDialog.propTypes = {
  patient: PropTypes.object,
  defaultCountry: PropTypes.object,
  defaultState: PropTypes.object,
  defaultCity: PropTypes.object,
  stateOptions: PropTypes.array,
  anchor: PropTypes.string,
  error: PropTypes.any,
  openEditPatientDialog: PropTypes.bool,
  handleCloseEditPatientDialog: PropTypes.func,
  onSubmitEditPatientDialog: PropTypes.func,
};
