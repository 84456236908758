import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import GaugeSlider from 'components/GaugeSlider';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '',
    img: angleSelector,
  },
];
const TopDeadCenter = ({ step, setupMachineData, subStep, handleCancel, handleBack, handleNext }) => {
  if (!setupMachineData) {
    return null;
  }
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', gap: 5 }}>
          <Box>{getInstructions(instructions)}</Box>

          <Typography variant='body1' component='div'>
            1. Set Top Dead Center (TDC) by engaging the angle selector to the Target Angle.
            <br />
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 5, paddingBottom: 5 }}>
              <span style={{ fontWeight: 500 }}> Patient Setup TDC:</span>
              <div
                style={{
                  padding: 2,
                  border: '1px solid green',
                  fontWeight: 500,
                  borderRadius: '8px',
                  width: '100px',
                  textAlign: 'center',
                  color: 'green',
                }}
              >{`Angle ${setupMachineData.dead_center}°`}</div>
            </div>
          </Typography>
        </Box>

        <div style={{ height: '300px', padding: '20px 0' }}>
          <GaugeSlider isLock={true} maxValue={126} value={setupMachineData.dead_center}></GaugeSlider>
        </div>
      </OverlayScrollbarsComponent>

      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </>
  );
};

TopDeadCenter.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default TopDeadCenter;
