import React, { Suspense } from 'react';

const CustomerDashboard = React.lazy(() => import('modules/DashBoard/containers/Dashboard/CustomerDashboard'));
export default [
  {
    path: '/',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CustomerDashboard />
      </Suspense>
    ),
    private: true,
  },
];
