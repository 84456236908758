import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import { useTranslation } from 'react-i18next';
import angleSelector from 'assets/images/SVG/Cervical/Angle-selector.svg';
import counterWeight from 'assets/images/SVG/Cervical/Counter-Weight.svg';
import chainTight from 'assets/images/SVG/Cervical/Chair-tightener.svg';
import { getInstructions } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

const instructions = [
  {
    id: 1,
    text: '4. Place angle selector at 36°.',
    img: angleSelector,
  },
  {
    id: 2,
    text: '5. Walk over to the other side of the machine and unlock counterweight.',
    img: counterWeight,
  },
  {
    id: 3,
    text: '6. Loosen chain tightener.',
    img: chainTight,
  },
];

export default function Preparation({ step, subStep, handleCancel, handleNext }) {
  // const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Typography variant='body1' component='div'>
        1. Before starting, make sure you have all medical information about the patient updated.
        <br />
        2. Ask patient if they need to use the restroom as the bladder area will be compressed.
        <br />
        3. Have patient remove belt or restrictive garments and empty object from pockets.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>{getInstructions(instructions)}</Box>
      {/* actions */}
      <Actions step={step} subStep={subStep} handleNext={handleNext} handleCancel={handleCancel} />
    </Box>
  );
}

Preparation.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
