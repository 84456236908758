import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { SingleSelect } from 'modules/DemoGraphic/Shared/SingleSelect.jsx';
import InputDate from 'modules/DemoGraphic/Shared/InputDate.jsx';
import InputText from 'modules/DemoGraphic/Shared/InputText.jsx';
import PropTypes from 'prop-types';
import {
  CIVILIAN_EMPLOYMENT_STATUS_OPTION,
  CIVILIAN_FIRER_STATUS,
  CIVILIAN_JOB_FUNC_STATUS,
  CIVILIAN_OCCUPATION_OPTION,
} from 'modules/DemoGraphic/Shared/options.js';

export default function CivilianInformation({ data, setData }) {
  const onChangeHandler = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Box className={'modal-content'}>
      <Box className={'row'}>
        <span className={'label'}>What is your employment status?</span>
        <SingleSelect
          value={data.employmentStatus}
          onChange={onChangeHandler}
          width={160}
          field={'employmentStatus'}
          options={CIVILIAN_EMPLOYMENT_STATUS_OPTION}
        />
      </Box>

      <Box className={'row'}>
        <span className={'label'}>What is your occupation?</span>
        <SingleSelect
          value={data.occupation}
          onChange={onChangeHandler}
          width={160}
          field={'occupation'}
          options={CIVILIAN_OCCUPATION_OPTION}
        />
      </Box>

      <Box className={'row'}>
        <span className={'label'}>Date you entered the occupation?</span>
        <InputDate value={data.enteredDate} onChange={onChangeHandler} width={250} field={'enteredDate'} />
      </Box>
      <Divider sx={{
        borderBottomWidth: '2px',
        borderColor: '#f5f5f5',
      }}/>

      {data.occupation !== 'FIREFIGHTER' ? (
        <Box className={'row'}>
          <span className={'label'}>What is your job function?</span>
          <SingleSelect
            value={data.jobFunc}
            onChange={onChangeHandler}
            width={160}
            field={'jobFunc'}
            options={CIVILIAN_JOB_FUNC_STATUS}
          />
        </Box>
      ) : (
        <Box className={'row'}>
          <span className={'label'}>What is your current Firefighter Title?</span>
          <SingleSelect
            value={data.FirefighterTitle}
            onChange={onChangeHandler}
            width={160}
            field={'FirefighterTitle'}
            options={CIVILIAN_FIRER_STATUS}
          />
        </Box>
      )}

      <Box className={'row'}>
        <span className={'label'}>Years in that Position or with that Title?</span>
        <InputText
          type={'number'}
          max={99999}
          placeholder={'Input'}
          field={'yearInPosition'}
          value={data.yearInPosition}
          onChange={onChangeHandler}
          width={250}
        />
      </Box>
    </Box>
  );
}

CivilianInformation.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  date: PropTypes.string,
  onFinish: PropTypes.func,
};
