import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { END_POINT } from './constants';
import { getNotificationsSuccess, getNotificationsFailure, getNotifications, readNotification, readNotificationSuccess } from './slice';

function* getNotificationsHandler(action) {
  try {
    const { data } = yield axios.get(END_POINT.getNotifications.url(action.payload.hcpId, action.payload.page));
    if (data.code === 200) {
      yield put(getNotificationsSuccess(data.data));
      return;
    }
  
    yield put(getNotificationsFailure());
  } catch (error) {
    yield put(getNotificationsFailure());
  }
}

function* readNotificationHandle(action) {
  try {
    const { data } = yield axios.put(END_POINT.readNotifications.url(action.payload.hcpId, action.payload.notiId));
    if (data.code === 200) {
      yield put(readNotificationSuccess(data.data));
      return;
    }
  
    yield put(getNotificationsFailure());
  } catch (error) {
    yield put(getNotificationsFailure());
  }
}

function* notiSaga() {
  yield takeLatest(getNotifications, getNotificationsHandler);
  yield takeLatest(readNotification, readNotificationHandle);
}

export default notiSaga;