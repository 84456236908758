import PropTypes from 'prop-types';
import { Modal as MuiModal } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { style } from 'modules/DataSync/Modal/styled';
import { useEffect, useState } from 'react';
import SyncProgress from 'modules/DataSync/Modal/SyncProgress';
import SyncOption from 'modules/DataSync/Modal/SyncOption';
import { SelectFile } from 'modules/DataSync/Modal/SelectFile';

const SyncModal = ({ isClose = true, topSmg = '', open = false, onClose }) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    return () => {
      setPage(() => 1);
    };
  }, [open]);

  const closeBtnRender = () => {
    if (!isClose) {
      return <div></div>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'end',
        }}
      >
        <IconButton sx={{ padding: 0 }} onClick={() => onClose()}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
    );
  };

  const contentRender = () => {
    switch (page) {
      case 1:
        return <SyncOption isClose={isClose} setPage={setPage} />;
      case 2:
        return <SelectFile setPage={setPage} isClose={isClose} />;
      case 3:
        return <SyncProgress setPage={setPage} onClose={() => onClose()} />;
    }
  };

  return (
    <MuiModal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={{ ...style }}>
        <Box>
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>{page === 2 ? 'Update Data File From Computer' : 'Data Synchronization'}</Box>
            <Box>{closeBtnRender()}</Box>
          </Box>

          <div
            style={{
              fontSize: '18px',
              textAlign: 'center',
              paddingBottom: '20px',
            }}
          >
            {topSmg}
          </div>

          <Box>
            <div id='child-modal-description'>{contentRender()}</div>
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
};

SyncModal.propTypes = {
  isClose: PropTypes.bool,
  open: PropTypes.bool,
  topSmg: PropTypes.string,
  onClose: PropTypes.func,
};

export default SyncModal;
