import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DynamicRechart from '../../../../../../../components/Charts/DynamicReChart';
import Actions from 'modules/Patients/components/Machines/Actions';
import { MACHINE_TESTING_ANGLE } from '../../../../../constants';
import { generateYAxisArray } from 'components/Charts/utils';

const OneLegSummary = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  setupMachineData,
  phase,
  phase1,
  phase2,
  firstKnee,
}) => {
  const [pounds, setPounds] = useState(0);
  const [disableNext, setDisableNext] = useState(true);

  const [data, setData] = useState([]);
  const [testKnee, setTestKnee] = useState(0);
  const [time, setTime] = useState(0);
  const [reps, setReps] = useState(0);
  const [rangeMotionMin, setRangeMotionMin] = useState(0);
  const [rangeMotionMax, setRangeMotionMax] = useState(0);
  const [seatPosition, setSeatPosition] = useState(0);
  const [yAxis, setYAxis] = useState([0, 100, 200, 300, 400, 500, 600]);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    if ((phase === 1 && firstKnee === 1) || (phase === 2 && firstKnee === 2)) {
      setTestKnee(1);
      setRangeMotionMin(setupMachineData.left_range_motion_min ?? 0);
      setRangeMotionMax(setupMachineData.left_range_motion_max ?? 0);
    } else if ((phase === 1 && firstKnee === 2) || (phase === 2 && firstKnee === 1)) {
      setTestKnee(2);
      setRangeMotionMin(setupMachineData.right_range_motion_min ?? 0);
      setRangeMotionMax(setupMachineData.right_range_motion_max ?? 0);
    }
    setSeatPosition(setupMachineData.seat_pos ?? 0);
  }, [setupMachineData, firstKnee, phase]);

  useEffect(() => {
    if (!phase) {
      return;
    }
    if (phase === 1) {
      if (phase1.dynamicData.length === 0 && phase1.time === 0) {
        setPounds(0);
        setDisableNext(true);
        setTime(0);
        setReps(0);
        setData([]);
      } else {
        setData(phase1.dynamicData);
        setPounds(Math.abs(phase1.torq));
        setDisableNext(false);
        setReps(phase1.reps);
        setTime(phase1.time);
      }
      if (phase1.weight !== null) {
        setYAxis(generateYAxisArray(phase1.weight, 6));
      }
    }
    if (phase === 2) {
      if (phase2.dynamicData.length === 0 && phase2.time === 0) {
        setPounds(0);
        setDisableNext(true);
        setTime(0);
        setReps(0);
        setData([]);
      } else {
        setData(phase2.dynamicData);
        setPounds(Math.abs(phase2.torq));
        setDisableNext(false);
        setReps(phase2.reps);
        setTime(phase2.time);
      }
      if (phase2.weight !== null) {
        setYAxis(generateYAxisArray(phase2.weight, 6));
      }
    }
  }, [phase, phase1, phase2]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
          <Box sx={{ textAlign: 'center', fontWeight: 700 }}> {testKnee === 1 ? 'Left' : 'Right'} Leg Extension</Box>

          <DynamicRechart
            xTicks={MACHINE_TESTING_ANGLE.KNEE_TESTING_ANGLE}
            tooltip={false}
            yTicks={yAxis}
            data={data}
          />
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '0 35px' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {reps}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {time || 0}s
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{`Torq: `} </span> {pounds}
            </div>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', padding: '0 35px' }}>
            <b style={{ fontSize: '18px' }}>{testKnee === 1 ? 'LEFT' : 'RIGHT'} LEG</b>
            <span> Max Flexion Angle: {rangeMotionMax !== null ? `${rangeMotionMax}°` : 'N/A'}</span>
            <span> Max Extension Angle: {rangeMotionMin !== null ? `${rangeMotionMin}°` : 'N/A'}</span>
            <span> Seat Position: {seatPosition ?? 'N/A'}</span>
          </Box>
        </Box>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        disableNext={disableNext}
        handleCancel={handleCancel}
      />
    </Box>
  );
};

OneLegSummary.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  value: PropTypes.array,
  firstKnee: PropTypes.number,
  phase: PropTypes.number,
  phase1: PropTypes.object,
  phase2: PropTypes.object,
};

export default OneLegSummary;
