import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';

export default function AlertDialog({
  message,
  primaryActionText,
  secondaryActionText,
  openAlertDialog,
  handleSubmitSecondaryAction,
  handleSubmitAlertDialog,
  backdropProps,
}) {
  const { t } = useTranslation();

  const closeModal = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
  };

  const onSubmit = () => {
    if (handleSubmitAlertDialog) {
      handleSubmitAlertDialog();
    }
  };

  const onSubmitSecondaryAction = () => {
    if (handleSubmitSecondaryAction) {
      handleSubmitSecondaryAction();
    }
  };

  return (
    <Dialog maxWidth='xs' BackdropProps={backdropProps} onClose={closeModal} open={openAlertDialog}>
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'center' }}>
        <WarningIcon sx={{ fontSize: '42px', color: '#D80000' }} />
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {secondaryActionText ? (
          <Button variant='text' onClick={onSubmitSecondaryAction}>
            {secondaryActionText || t('common:cancel')}
          </Button>
        ) : null}
        <Button variant='contained' onClick={onSubmit} autoFocus>
          {primaryActionText || t('common:agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  handleSubmitSecondaryAction: PropTypes.func,
  handleSubmitAlertDialog: PropTypes.func,
  message: PropTypes.any,
  primaryActionText: PropTypes.string,
  openAlertDialog: PropTypes.bool,
  secondaryActionText: PropTypes.string,
  backdropProps: PropTypes.object,
};
