import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function toLetters(num) {
  var mod = num % 26,
    pow = (num / 26) | 0,
    out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  return pow ? toLetters(pow) + out : out;
}

function isFinished(item, selectedItem) {
  const idsA = selectedItem.split('-');
  const parentIdA = idsA[0];
  const childIdA = idsA[1];

  const idsB = item.split('-');
  const parentIdB = idsB[0];
  const childIdB = idsB[1];

  if (childIdA && childIdB) {
    if (parentIdA === parentIdB) {
      return childIdA > childIdB;
    }

    return parentIdA > parentIdB;
  }

  if (childIdA && !childIdB) {
    return parentIdA > parentIdB;
  }

  if (childIdB && !childIdA) {
    return parentIdA > parentIdB;
  }

  return parentIdA > parentIdB;
}

function isChildSelected(parent, selectedItem) {
  const idsA = selectedItem.split('-');
  const parentIdA = idsA[0];
  const childIdA = idsA[1];

  return !!(parentIdA === parent.id && childIdA);
}

function CustomCheckBoxIcon() {
  return <CheckBoxIcon sx={{ color: 'green' }} />;
}

function ListItem({ item, index, selectedItem, handleSelect }) {
  if (item.children) {
    return <CollapsibleListItem item={item} index={index} selectedItem={selectedItem} handleSelect={handleSelect} />;
  }
  const onClick = (e, id) => {
    if (e?.code === 'Enter') {
      return;
    }
    handleSelect(id);
  };

  const done = isFinished(item.id, selectedItem);
  const isSelected = selectedItem === item.id;
  const classNames = done ? 'finished' : isSelected ? 'selected' : '';
  // List item without children
  return (
    <ListItemButton
      sx={{
        pointerEvents: isFinished(item.id, selectedItem) ? '' : 'none',
      }}
      className={classNames}
      onClick={(e) => onClick(e, item.id)}
    >
      <ListItemIcon sx={{ opacity: done ? 1 : 0 }}>
        <CustomCheckBoxIcon />
      </ListItemIcon>
      <ListItemText
        sx={{
          pointerEvents: 'none',
          color: done ? '#000' : '',
          span: {
            fontWeight: done ? 'bold' : 'normal',
          },
        }}
        primary={`${index}. ${item.name}`}
      />
    </ListItemButton>
  );
}

ListItem.propTypes = {
  item: PropTypes.object,
  selectedItem: PropTypes.string,
  index: PropTypes.number,
  handleSelect: PropTypes.func,
};

function CollapsibleListItem({ item, index, selectedItem, handleSelect }) {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    setOpen(!open);
  };

  const done = isFinished(item.id, selectedItem);
  const isSelected = selectedItem === item.id;
  const halfDone = isChildSelected(item, selectedItem); // means a child step is selected (in progress)
  const classNames = done ? 'finished' : isSelected ? 'selected' : '';

  // open the parent if a child is selected
  useEffect(() => {
    if (halfDone) {
      setOpen(true);
    }
  }, [halfDone]);

  return (
    <>
      <ListItemButton className={classNames} onClick={handleClick}>
        <ListItemIcon sx={{ opacity: done ? 1 : 0 }}>
          <CustomCheckBoxIcon />
        </ListItemIcon>
        <ListItemText
          sx={{
            color: done || halfDone ? '#000' : '',
            span: {
              fontWeight: done || halfDone ? 'bold' : 'normal',
            },
          }}
          primary={`${index}. ${item.name}`}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {item.children.map((c, index) => (
            <CollapsibleListChildItem
              key={c.id}
              item={c}
              index={index}
              selectedItem={selectedItem}
              handleSelect={handleSelect}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}

CollapsibleListItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  selectedItem: PropTypes.string,
  handleSelect: PropTypes.func,
};

function CollapsibleListChildItem({ item, index, handleSelect, selectedItem }) {
  const done = isFinished(item.id, selectedItem);
  const isSelected = selectedItem === item.id;
  const classNames = done ? 'finished' : isSelected ? 'selected' : '';
  const onClick = (e, id) => {
    if (e.code === 'Enter') {
      return;
    }
    handleSelect(id);
  };

  return (
    <ListItemButton
      className={classNames}
      onClick={(e) => onClick(e, item.id)}
      key={item.id}
      sx={{ pl: 4, pointerEvents: isFinished(item.id, selectedItem) ? '' : 'none' }}
    >
      <ListItemIcon sx={{ opacity: done ? 1 : 0 }}>
        <CustomCheckBoxIcon />
      </ListItemIcon>
      <ListItemText
        sx={{
          color: done ? '#666' : '',
          span: {
            fontWeight: done ? 'bold' : 'normal',
          },
        }}
        primary={`${toLetters(index + 1).toLowerCase()}. ${item.name}`}
      />
    </ListItemButton>
  );
}

CollapsibleListChildItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  selectedItem: PropTypes.string,
  handleSelect: PropTypes.func,
};

export default function SideBar({ step, subStep, handleSelectItem, navigationItems }) {
  const [selectedItem, setSelectedItem] = useState('1');
  useEffect(() => {
    if (subStep && subStep !== '0') {
      setSelectedItem(`${step}-${subStep}`);
    } else {
      setSelectedItem(`${step}`);
    }
  }, [step, subStep]);

  // Only allow selecting item that is before the current selected item
  const handleSelect = (id) => {
    if (isFinished(id, selectedItem)) {
      handleSelectItem({
        step: id.split('-')[0],
        subStep: id.split('-')[1] || '0',
      });
    }
  };

  return (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: { autoHide: 'scroll' },
        paddingAbsolute: true,
      }}
      style={{ height: 'calc(100vh - 225px)' }}
    >
      <List
        sx={{
          pt: 0,
          width: '100%',
          minWidth: 350,
          maxWidth: 400,
          color: '#666666',
          bgcolor: 'background.paper',
          '.MuiListItemIcon-root': { minWidth: 0, mr: 1 },
          '.MuiListItemButton-root': {
            mb: 1,
            py: '2px',
            border: '1px solid transparent',
            borderRadius: '12px',
            '&:hover': { border: '1px solid #D4D4D4', bgcolor: '#FDFDFD' },
            '&.selected': {
              border: '1px solid #D4D4D4',
              bgcolor: '#FDFDFD',
              color: 'primary.main',
              span: { fontWeight: 'bold' },
            },
          },
        }}
        component='nav'
      >
        {navigationItems.map((item, index) => (
          <ListItem
            key={`${item.id}-${index}`}
            item={item}
            index={index + 1}
            selectedItem={selectedItem}
            handleSelect={handleSelect}
          />
        ))}
      </List>
    </OverlayScrollbarsComponent>
  );
}

SideBar.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleSelectItem: PropTypes.func,
  navigationItems: PropTypes.array,
};
