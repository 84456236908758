import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';

import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SessionInfo } from 'modules/Patients/components/Info';
import SideBar from 'modules/Patients/components/Machines/TestSideBar';
import AlertDialog from 'components/AlertDialog/index.jsx';
import Instructions from './Steps/Instructions';
import Questions from './Steps/Question';
import Preparation from './Steps/Preparation';
import Instruction1 from './Steps/Instruction1';
import Restraints from './Steps/Restraints';
import Instruction2 from './Steps/Instruction2';
import ExerciseInstructions from './Steps/ExerciseInstructions';
import MachineSetup from './Steps/MachineSetup';
import PatientsAdjustment from './Steps/PatientsAdjustment';
import SetAngleSelector from './Steps/SetAngleSelector';
import DynamicExercise from './Steps/DynamicExercise';
import OneLegSummary from './Steps/OneLegSummary';
import EquipmentSwap from './Steps/EquipmentSwap';
import { useDispatch, useSelector } from 'react-redux';
import { clearActivities, putActivityNote } from '../../../../slice';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export default function Index({
  patient,
  machine,
  handleNext,
  handleBack,
  handleSelectItem,
  step,
  subStep,
  setupId,
  handleSkipItem,
  navigationItems,
  resetNav,
  setupMachineData,
  phase,
  nextPhase,
  firstKnee,
  setFirstKnee,
  topSmg,
  testMode,
  setTestMode,
  handleRemoveSkip,
  socketContext,
  disableFirstStep,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const arrMachinesConfigured = useSelector((state) => state.machines.hcpConfiguredMachines);
  const printRef = useRef();
  const machineId = Number(searchParams.get('machineId'));
  const machineType = searchParams.get('machineType');
  const sessionId = searchParams.get('sessionId');
  const [openAlert, setOpenAlert] = useState(false);
  const [kneeMode, setKneeMode] = useState(1);
  const [phase1, setPhase1] = useState({
    weight: '',
    angle: undefined,
    dynamicData: [],
    reps: 0,
    time: 0,
    torq: 0,
  });
  const [phase2, setPhase2] = useState({
    weight: '',
    angle: undefined,
    dynamicData: [],
    reps: 0,
    time: 0,
    torq: 0,
  });

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    if (!setupMachineData.data) {
      return null;
    }

    setKneeMode(setupMachineData.data.knee_test_mode);
  }, [setupMachineData]);

  useEffect(() => {
    if (!isNil(machineId) && !isNil(machineType) && !isEmpty(arrMachinesConfigured)) {
      socketContext.handleFilterMachine({ machineId, machineType, machines: arrMachinesConfigured });
    }
  }, [machineId, machineType, arrMachinesConfigured]);


  useEffect(() => {
    return () => {
      socketContext.handleResetMachine();
    }
  }, []);

  function contentRender() {
    let nextStep = `${step}`;

    if (subStep !== '0') {
      nextStep = `${step}-${subStep}`;
    }
    const props = {
      step,
      subStep,
      handleCancel,
      handleBack,
      handleNext,
      machine,
      onDataChange,
      setupMachineData: setupMachineData ? setupMachineData.data ?? {} : {},
      setFirstKnee,
      firstKnee,
      testMode,
      setTestMode,
      phase1,
      phase2,
      phase,
      kneeMode,
      socketContext,
      disableFirstStep,
      sessionId: setupMachineData ? setupMachineData.session_id : '',
    };
    const activityId = localStorage.getItem('activity_id');
    switch (nextStep) {
      case '1':
        return <Questions {...props} />; // p1
      case '1-1':
        return <EquipmentSwap {...props} />; // p2
      case '1-2':
        return <Instructions {...props} handleRemoveSkip={handleRemoveSkip} />; // p2
      case '2':
        return <ExerciseInstructions {...props} />; // p2
      case '2-1':
        return <Preparation {...props} />; // p1
      case '2-2':
        return <Instruction1 {...props} />; // p1
      case '2-3':
        return <Restraints {...props} />; // p1
      case '2-4':
        return <Instruction2 {...props} handleRemoveSkip={handleRemoveSkip} />; // p1
      case '3':
        if (phase === 1) {
          return <ExerciseInstructions {...props} />; // p1
        }
        return <MachineSetup {...props} />; // p2
      case '4':
        if (phase === 1) {
          return <MachineSetup {...props} />; // p1
        }
        return <PatientsAdjustment {...props} />; //p1
      case '5':
        if (phase === 1) {
          return <PatientsAdjustment {...props} />; //p1
        }
        return <SetAngleSelector {...props} />;
      case '6':
        if (phase === 1) {
          return <SetAngleSelector {...props} />; // p1
        }
        return <DynamicExercise {...props} />; //p2

      case '7':
        if (phase === 1) {
          return <DynamicExercise {...props} />;
        }
        localStorage.removeItem('activity_id');
        resetNav();
        return (
          <Navigate
            to={`/patient/${id}/dynamic-test/summary?setupId=${setupId}&sessionId=${sessionId}&mode=1&machineType=${machineType}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=false`}
          />
        );

      case '8':
        if (kneeMode < 3 || (kneeMode > 3 && testMode < 3)) {
          localStorage.removeItem('activity_id');
          resetNav();
          return (
            <Navigate
              to={`/patient/${id}/dynamic-test/summary?setupId=${setupId}&sessionId=${sessionId}&mode=1&machineType=${machineType}&machineId=${machineId}&activitiesId=${activityId}&viewOnly=false`}
            />
          );
        }

        return <OneLegSummary {...props} />;
      case '9':
        nextPhase();
        break;
      default:
        return <></>;
    }
  }

  const handleCancel = () => {
    setOpenAlert(true);
  };
  const handleSubmitSecondaryAction = () => {
    const hcpId = localStorage.getItem('hcpId');
    const activityId = localStorage.getItem('activity_id');
    resetNav();
    localStorage.removeItem('activity_id');
    dispatch(
      putActivityNote({
        hcpId,
        patientId: atob(id),
        sessionId: sessionId,
        activitiesId: activityId,
        data: { test_duration: 0 },
      })
    );
    navigate(`/patient/${btoa(patient.patient_id)}&tab=1`);
  };

  const handleSubmitAlertDialog = () => {
    setOpenAlert(false);
  };

  const onDataChange = (data) => {
    if (!data) {
      return null;
    }

    if (phase === 1) {
      setPhase1((prevState) => ({
        ...prevState,
        ...data,
      }));
    }
    if (phase === 2) {
      setPhase2((prevState) => ({
        ...prevState,
        ...data,
      }));
    }
  };

  return (
    <Box ref={printRef}>
      <SessionInfo machine={machine} patient={patient} title={'Dynamic Exercise'} />
      <Box
        sx={{
          display: 'flex',
          pt: 5,
        }}
      >
        <SideBar
          navigationItems={navigationItems}
          step={step}
          subStep={subStep}
          handleSkipItem={handleSkipItem}
          handleSelectItem={handleSelectItem}
          topSmg={topSmg}
        />

        {/* main content */}
        <Box sx={{ width: '60%', ml: 4 }}>
          <Box>{contentRender()}</Box>
        </Box>
        <AlertDialog
          secondaryActionText='End'
          primaryActionText='Return'
          handleSubmitAlertDialog={handleSubmitAlertDialog}
          handleSubmitSecondaryAction={handleSubmitSecondaryAction}
          openAlertDialog={openAlert}
          message={
            <Box sx={{ maxWidth: '315px' }}>
              Are you sure you want to cancel this Dynamic Exercise ? All data will be deleted.
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

Index.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSelectItem: PropTypes.func,
  navigationItems: PropTypes.array,
  handleSkipItem: PropTypes.func,
  resetNav: PropTypes.func,
  step: PropTypes.string,
  subStep: PropTypes.string,
  setupId: PropTypes.string || PropTypes.number,
  handleRemoveSkip: PropTypes.func,
  phase: PropTypes.number,
  nextPhase: PropTypes.func,
  setupMachineData: PropTypes.object,
  firstKnee: PropTypes.number,
  setFirstKnee: PropTypes.func,
  topSmg: PropTypes.string,
  testMode: PropTypes.number,
  setTestMode: PropTypes.func,
  socketContext: PropTypes.object,
  disableFirstStep: PropTypes.bool,
};
