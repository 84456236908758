import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValidBase64 } from 'helpers';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MachineSelectView from 'modules/Patients/components/MachineSelect';

import { fetchPatient } from 'modules/Patients/slice';
import { fetchHcpConfiguredMachines } from 'modules/Machines/slice';
import useCurrentUser from 'hooks/useCurrentUser';

export default function MachineSelect() {
  const patient = useSelector((state) => state.patients.patient);
  const machines = useSelector((state) => state.machines.hcpConfiguredMachines);
  const { isLoading } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { user: { hcp: { enabled_feature: { enabled_machines: enabledMachines } } } } = useCurrentUser();

  const { id } = useParams();
  const sessionId = searchParams.get('sessionId');
  const patientId = id;

  useEffect(() => {
    if (patientId && isValidBase64(patientId)) {
      const data = {
        patientId: atob(patientId),
        hcpId: localStorage.getItem('hcpId'),
      };
      dispatch(fetchPatient(data));
    }
  }, [patientId]);

  const getConfiguredMachine = (data) => {
    dispatch(fetchHcpConfiguredMachines({ hcpId: localStorage.getItem('hcpId'), machineType: data.name }));
  };

  if (!patient)
    return (
      <Container
        sx={{
          minHeight: 'calc(100vh - 70px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ wordBreak: 'break-all', color: 'gray' }} variant='h5' component='div'>
          {isLoading ? 'Loading...' : t('common:errors.noResults')}
        </Typography>
      </Container>
    );

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          minHeight: 'calc(100vh - 118px)',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <MachineSelectView
            sessionId={sessionId}
            patient={patient}
            getConfiguredMachine={getConfiguredMachine}
            machines={machines}
            enabledMachines={enabledMachines}
          />
        </Box>
      </Box>
    </Container>
  );
}
