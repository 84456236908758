import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/Dialogs/actions';

export const AuditLogsSlice = createSlice({
  name: 'auditLogsSlice',
  initialState: {
    error: null,
    numberRecord: null,
    isRequestCountRecordSuccess: false,
    isDownloadSuccess: false
  },
  reducers: {
    requestExample: (state) => {
      state.isFetchExample = true;
    },
    requestExampleSuccess: (state) => {
      state.isFetchExample = false;
    },
    requestExampleFailure: (state, action) => {
      state.isFetchExample = false;
      state.fetchExampleError = action.payload;
    },
    requestExportAuditLogAction: (state) => {
    },
    requestExportAuditLogActionSuccess: (state, action) => {
      state.isDownloadSuccess = true;
    },
    requestExportAuditLogActionFailed: (state, action) => {
      state.requestExportAuditLogError = action.payload;
    },
    requestCountRecordAuditLog: (state) => {
    },
    requestCountRecordAuditLogSuccess: (state, action) => {
      state.numberRecord = action.payload.rows;
      state.isRequestCountRecordSuccess = true;
    },
    requestCountRecordAuditLogFailed: (state, action) => {
      state.isRequestCountRecordFailed = true;
    }
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.error = null;
    },
  },
});
export const {
  requestExample,
  requestExampleSuccess,
  requestExampleFailure,
  requestExportAuditLogAction,
  requestExportAuditLogActionSuccess,
  requestExportAuditLogActionFailed,
  requestCountRecordAuditLog,
  requestCountRecordAuditLogSuccess,
  requestCountRecordAuditLogFailed
} = AuditLogsSlice.actions;

export default AuditLogsSlice.reducer;
