import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  fetchActivities,
  fetchMachineSetup,
  fetchPatient,
  putActivityNote,
  resetActivityProperties,
} from 'modules/Patients/slice';
import DynamicRechart from 'components/Charts/DynamicReChart';
import SummaryControl from '../../SummaryControl';
import { SessionInfo } from '../../../components/Info';
import Container from '@mui/material/Container';
import useCurrentUser from 'hooks/useCurrentUser';

import { MACHINE_TESTING_ANGLE } from '../../../constants';
import Divider from '@mui/material/Divider';
import { generateYAxisArray } from 'components/Charts/utils';
import { getActivityQuestionLabel } from 'helpers';
import PushEMRModal from 'modules/EMR/components/PushEMRModal.jsx';
import { dateParseMonthDayYear } from 'helpers/datetime';

const DynamicSummaryContainer = styled('div')`
  & .notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & .notes:hover {
    border-color: black;
  }
  & .notes:focus {
    outline-color: #ed9a00;
  }
`;

const TorsoDynamicSummary = ({ activitiesId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const machineId = searchParams.get('machineId');
  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  // const activitiesId = searchParams.get('activitiesId');
  const viewOnly = searchParams.get('viewOnly');
  const mode = searchParams.get('mode');

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const setupData = useSelector((state) => state.patients.setup);
  const value = useSelector((state) => state.patients.activities);
  const patientInfo = useSelector((state) => state.patients.patient);
  const isUpdatingActivity = useSelector((state) => state.patients.isUpdatingActivity);

  const [dataLeft, setDataLeft] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [repsLeft, setRepsLeft] = useState(0);
  const [targetLeft, setTargetLeft] = useState(undefined);
  const [dataRight, setDataRight] = useState([]);
  const [timeRight, setTimeRight] = useState(null);
  const [repsRight, setRepsRight] = useState(0);
  const [targetRight, setTargetRight] = useState(undefined);
  const [xAxis, setXAxis] = useState([]);
  const [isEditNotes, setIsEditNotes] = useState(false);
  const [notes, setNote] = useState('');
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [yAxisLeft, setYAxisLeft] = useState([0, 100, 200, 300, 400, 500, 600]);
  const [yAxisRight, setYAxisRight] = useState([0, 100, 200, 300, 400, 500, 600]);
  const { emr } = useSelector((state) => state.emrSettings);

  const [openPushEMR, setOpenPushEMR] = useState(false);
  const [disablePushing, setDisablePushing] = useState(false);

  const printRef = useRef(null);

  const { permissions: currentPermissions, user: { hcp: { enabled_feature: enabledFeature } } } = useCurrentUser();

  const hasExecuteActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('EXECUTE_ACTIVITIES');
  };

  const hasViewActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('VIEW_PATIENT_ACTIVITIES');
  };

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const imgWidth = 208;
    const pageHeight = 275;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF('p', 'mm');
    doc.addImage(canvas, 'PNG', 20, 20, imgWidth, imgHeight, '', 'FAST');
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, 'PNG', 20, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save('Dynamic Summary Index.pdf');
  };

  useEffect(() => {
    if (!id || !machineId || !setupId) {
      return null;
    }

    const hcpId = localStorage.getItem('hcpId');

    dispatch(
      fetchPatient({
        patientId: atob(id),
        hcpId: hcpId,
      })
    );

    dispatch(
      fetchActivities({
        hcpId,
        patientId: atob(id),
        sessionId,
        activitiesId,
      })
    );
    dispatch(
      fetchMachineSetup({
        hcpId: hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
      })
    );
  }, [activitiesId]);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (!value.emr_status || Object.keys(value.emr_status).length === 0) {
      setDisablePushing(false);
    } else {
      if (value.emr_status.caspio.status === 'Done' || value.emr_status.caspio.status === 'Processing') {
        setDisablePushing(true);
      } else {
        setDisablePushing(false);
      }
    }

    const dLeft = [];
    let repsLeft = 0;

    const dRight = [];
    let repsRight = 0;

    (value.data_left ?? []).map((line) => {
      if (line.is_flexion) {
        line.angle_degrees?.map((degree, index) => {
          let de = degree;
          if (de < -60) {
            de = -60;
          }
          if (de > 60) {
            de = 60;
          }
          dLeft.push({
            name: de,
            flexion: Math.abs(line.pounds[index]),
          });
        });
      } else {
        line.angle_degrees?.map((degree, index) => {
          let de = degree;
          if (de < -60) {
            de = -60;
          }
          if (de > 60) {
            de = 60;
          }
          dLeft.push({
            name: de,
            extension: Math.abs(line.pounds[index]),
          });
        });
      }
      repsLeft = line.reps > repsLeft ? line.reps : repsLeft;
    });

    (value.data_right ?? []).map((line) => {
      if (line.is_flexion) {
        line.angle_degrees?.map((degree, index) => {
          let de = degree;
          if (de < -60) {
            de = -60;
          }
          if (de > 60) {
            de = 60;
          }
          dRight.push({
            name: de,
            flexion: Math.abs(line.pounds[index]),
          });
        });
      } else {
        line.angle_degrees?.map((degree, index) => {
          let de = degree;
          if (de < -60) {
            de = -60;
          }
          if (de > 60) {
            de = 60;
          }
          dRight.push({
            name: de,
            extension: Math.abs(line.pounds[index]),
          });
        });
      }
      repsRight = line.reps > repsRight ? line.reps : repsRight;
    });

    setRepsLeft(repsLeft);
    setRepsRight(repsRight);
    setTimeLeft(value?.test_duration_left);
    setTimeRight(value?.test_duration_right);

    if (value?.data_left[0] && value.data_left[0]?.weight !== null) {
      setTargetLeft(value.data_left[0].weight);
      setYAxisLeft(generateYAxisArray(value.data_left[0].weight, 6));
    }

    if (value?.data_right[0] && value.data_right[0]?.weight !== null) {
      setTargetRight(value.data_right[0].weight);
      setYAxisRight(generateYAxisArray(value.data_right[0].weight, 6));
    }

    setNote(value.notes || '');

    setXAxis(MACHINE_TESTING_ANGLE.ROTARY_TORSO_TESTING_ANGLE);

    setDataLeft([...dLeft]);
    setDataRight([...dRight]);
  }, [value]);

  useEffect(() => {
    if (!isUpdatingActivity) {
      setIsEditNotes(false);
      dispatch(resetActivityProperties());
    }
  }, [isUpdatingActivity]);

  const onNoteCancelHandler = () => {
    setNote(value.notes || '');
    setIsEditNotes(false);
  };

  const onSaveHandler = () => {
    if (!isUpdating) {
      const hcpId = localStorage.getItem('hcpId');
      dispatch(
        putActivityNote({
          hcpId,
          patientId: atob(id),
          sessionId,
          activitiesId,
          data: {
            notes: notes || '',
          },
        })
      );
    }
  };

  const onPushDataDoneHandler = () => {
    const hcpId = localStorage.getItem('hcpId');
    dispatch(
      fetchActivities({
        hcpId,
        patientId: atob(id),
        sessionId,
        activitiesId,
      })
    );
    setOpenPushEMR(false);
  };

  const sessionInfoRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    const formattedDate = dateParseMonthDayYear(value?.initiated_at);

    return (
      <SessionInfo
        isDownloading={isDownloadingPdf}
        machine={{ machine }}
        patient={patientInfo}
        title={'Dynamic Exercise Summary'}
        currentDay={formattedDate}
      />
    );
  };

  const footerRender = () => {
    if (!setupData) {
      return null;
    }
    const machine = {
      serial_no: setupData.serial_no,
      machine_type: setupData.machine_type,
      name: setupData.machine_name,
    };

    return (
      <SummaryControl
        isBack={viewOnly === 'true'}
        machine={{ machine }}
        patientId={id}
        setupId={setupId}
        sessionId={sessionId}
        disableControl={isEditNotes}
        type={'ACTIVITY'}
        setupValue={setupData}
        mode={Number(mode)}
      />
    );
  };

  const leftLegRender = () => {
    if (!dataLeft || (dataLeft && dataLeft.length === 0)) {
      return null;
    }
    const setupRender = () => {
      let minRom = 'N/A';
      let maxRom = 'N/A';
      let gatePos = 'N/A';
      let footboardRestraint = 'N/A';
      let seatPad = 'N/A';

      if (setupData && setupData.data) {
        minRom = setupData.data.range_motion_min;
        maxRom = setupData.data.range_motion_max;
        seatPad = setupData.data.seat_pad;
        footboardRestraint = setupData.data.footboard_pos;
        gatePos = setupData.data.torso_pad_pos;
      }

      return (
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            fontSize: '20px',
            paddingBottom: '20px',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
            <span>
              <b>Max Left ROM: </b> {maxRom}°
            </span>
            <span>
              <b> Max Right ROM: </b>
              {minRom}°
            </span>

            <span>
              <b>Gate Position: </b> {gatePos}
            </span>
            <span>
              <b> Footboard Restraint: </b> {footboardRestraint}
            </span>
            <span>
              <b>Seat pad: </b> {seatPad}
            </span>
          </Box>
        </Box>
      );
    };
    return (
      <>
        <Box sx={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '10px',
              }}
            >
              <b style={{ fontSize: '20px' }}> Left Side</b>
              <br />
              <DynamicRechart yTicks={yAxisLeft} xTicks={xAxis} tooltip={false} data={dataLeft} balanceMode={true} />
            </div>
          </Box>
          <Divider sx={{
            borderBottomWidth: '2px',
            borderColor: '#f5f5f5'
          }} />
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  fontSize: '20px',
                  paddingBottom: '20px',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {repsLeft}
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {timeLeft}s
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>{`Weight: `} </span> {targetLeft}
                </div>
              </Box>
            </Box>
            {setupRender()}
          </Box>
        </Box>
      </>
    );
  };

  const rightLegRender = () => {
    if (!dataRight || (dataRight && dataRight.length === 0)) {
      return null;
    }

    const setupRender = () => {
      let minRom = 'N/A';
      let maxRom = 'N/A';
      let gatePos = 'N/A';
      let footboardRestraint = 'N/A';
      let seatPad = 'N/A';

      if (setupData && setupData.data) {
        minRom = setupData.data.range_motion_min;
        maxRom = setupData.data.range_motion_max;
        seatPad = setupData.data.seat_pad;
        footboardRestraint = setupData.data.footboard_pos;
        gatePos = setupData.data.torso_pad_pos;
      }

      return (
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            fontSize: '20px',
            paddingBottom: '20px',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
            <span>
              <b>Max Left ROM:</b> {maxRom}°
            </span>
            <span>
              <b>Max Right ROM: </b> {minRom}°
            </span>

            <span>
              <b>Gate Position: </b> {gatePos}
            </span>
            <span>
              <b> Footboard Restraint: </b> {footboardRestraint}
            </span>
            <span>
              <b>Seat pad: </b> {seatPad}
            </span>
          </Box>
        </Box>
      );
    };

    return (
      <>
        <Box sx={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '10px',
              }}
            >
              <b style={{ fontSize: '20px' }}>Right Side</b>
              <br />
              <DynamicRechart yTicks={yAxisRight} xTicks={xAxis} tooltip={false} data={dataRight} balanceMode={true} />
            </div>
          </Box>
          <Divider sx={{
            borderBottomWidth: '2px',
            borderColor: '#f5f5f5'
          }} />
          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  fontSize: '20px',
                  paddingBottom: '20px',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <span style={{ fontWeight: 'bold' }}>{`Rep counter: `}</span> {repsRight}
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>{`Time underloaded: `}</span> {timeRight}s
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>{`Weight: `} </span> {targetRight}
                </div>
              </Box>
            </Box>
            {setupRender()}
          </Box>
        </Box>
      </>
    );
  };

  if (!setupData || !hasViewActivitiesPermission()) {
    return <></>;
  }

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <Box ref={printRef}>
            {sessionInfoRender()}
            <Box
              sx={{
                pt: 5,
                width: '90%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '40px',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', gap: 5, width: '100%', flexDirection: 'column' }}>

                  <Box data-html2canvas-ignore sx={{ alignSelf: 'end', display: 'flex', gap: 1 }}>
                    {!!Object.entries(enabledFeature.emr).filter(([key, value]) => value).length &&
                      <Button
                        onClick={() => setOpenPushEMR(true)}
                        disabled={isEditNotes || disablePushing || !emr || (emr && emr.length === 0)}
                        sx={{ alignSelf: 'baseline' }}
                        variant='outlined'
                        color='secondary'
                      >
                        Push Data
                      </Button>}

                    <Button
                      onClick={() => {
                        setIsDownloadingPdf(true);
                        setTimeout(() => {
                          handleDownloadPdf();
                          setIsDownloadingPdf(false);
                        }, 100);
                      }}
                      disabled={isEditNotes}
                      sx={{ alignSelf: 'baseline', color: '#FFF' }}
                      variant='contained'
                    >
                      Export Report
                    </Button>
                  </Box>
                  {leftLegRender()}
                  {timeLeft && timeRight ? <Divider /> : <div></div>}
                  {rightLegRender()}
                  <Divider sx={{
                    borderBottomWidth: '2px',
                    borderColor: '#f5f5f5'
                  }} />
                  <Box
                    sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1, padding: '20px 0 20px 0' }}
                  >
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        The subjective pain level
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_1 === null ? 'N/A' : getActivityQuestionLabel('answer_1', value.answer_1))}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        The patient perceived exertion rate
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_2 === null ? 'N/A' : getActivityQuestionLabel('answer_2', value.answer_2))}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        How many more reps could you do before failure?
                      </Typography>

                      <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                        {value &&
                          (value.answer_3 === null ? 'N/A' : getActivityQuestionLabel('answer_3', value.answer_3))}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{
                    borderBottomWidth: '2px',
                    borderColor: '#f5f5f5'
                  }} />
                  <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Notes
                      </Typography>

                      {hasExecuteActivitiesPermission() && (
                        <IconButton data-html2canvas-ignore onClick={() => setIsEditNotes(true)} fontSize={'small'}>
                          <EditIcon fontSize={'small'} sx={{ color: 'black' }} />
                        </IconButton>
                      )}
                    </Box>
                    {isEditNotes ? (
                      <Box>
                        <DynamicSummaryContainer>
                          <TextareaAutosize
                            onChange={(v) => {
                              setNote(v.target.value);
                            }}
                            className={'notes'}
                            value={notes}
                            minRows={5}
                            maxLength={3000}
                            style={{
                              width: '100%',
                              borderColor: '#DDDFE1',
                              borderRadius: 5,
                              fontFamily: 'Roboto, Montserrat, sans-serif',
                              fontSize: 16,
                            }}
                          />
                        </DynamicSummaryContainer>

                        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={onNoteCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
                              {t('common:cancel')}
                            </Button>

                            <LoadingButton
                              loading={isUpdating}
                              onClick={onSaveHandler}
                              size={'small'}
                              variant='contained'
                            >
                              {t('common:save')}
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Typography sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }} variant='p' component='div'>
                        {notes || '_'}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <PushEMRModal
                  onClose={() => setOpenPushEMR(false)}
                  open={openPushEMR}
                  activityId={activitiesId}
                  onDone={onPushDataDoneHandler}
                />

                <Box data-html2canvas-ignore>{footerRender()}</Box>
                <Box
                  data-html2canvas-ignore
                  sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}
                >
                  <div></div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

TorsoDynamicSummary.propTypes = {
  machineSetup: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  machine: PropTypes.object,
  value: PropTypes.object,
  printRef: PropTypes.object,
  onDataChange: PropTypes.func,
  setIsDownloading: PropTypes.func,
  activitiesId: PropTypes.string,
};
export default TorsoDynamicSummary;
