import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useAuthDataContext } from 'components/AuthProvider/index.jsx';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UI from 'modules/Ui/UI';
import CurrentUser from 'modules/Auth/containers/currentUser';
import Dialogs from 'modules/Dialogs/containers/Dialogs';
import { Main } from './Main/Main';
import { DrawerHeader } from './DrawerHeader/DrawerHeader';
import { HcpLinks } from './Links';
import CustomerHeader from './CustomerHeader';
import Version from './version';
import RequestSyncData from 'modules/DataSync/RequestSyncData';
import { useSocketDataContext } from 'components/WebSocketProvider/index';
import socketActions from 'components/WebSocketProvider/constants.js';
import NotificationBell from 'modules/Notifications';

const drawerWidth = 300;

// some paths do not have a drawer
const excludePaths = [
  'select-machine',
  'select-activity',
  'machine-setup',
  'isometric-test',
  'dynamic-test',
  'setups',
  'summary',
  'patient-detail',
  'summary-edit',
];

function handleKeyDown(socketContext, event) {
  if (event.keyCode === 121) {
    if (!socketContext || (socketContext && (!socketContext.u12Id || !socketContext.macAddress))) {
      return;
    }
    socketContext.sendJsonMessage({
      request: socketActions.FLIP_SWITCH,
      u12_id: socketContext.u12Id,
      mac_address: socketContext.macAddress,
    });
  }
}

export default function AppLayout({ children }) {
  const socketContext = useSocketDataContext();
  const theme = useTheme();
  const location = useLocation();
  const context = useAuthDataContext();
  const [open, setDrawerOpen] = useState(false);
  const [showDrawerIcon, setShowDrawerIcon] = useState(true);
  const currentUser = context.currentUser;

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown.bind(this, socketContext));

    return () => {
      window.removeEventListener('keydown', handleKeyDown.bind(this, socketContext));
    };
  }, [socketContext.macAddress, socketContext.u12Id]);

  useEffect(() => {
    const afterLastSlash = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (!currentUser || (currentUser && !currentUser.id) || excludePaths.includes(afterLastSlash)) {
      handleDrawerClose();
      setShowDrawerIcon(false);
    } else {
      // open the drawer on all pages
      handleDrawerOpen();
      setShowDrawerIcon(true);
    }
  }, [currentUser, location]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getHeader = () => {
    if (!currentUser || (currentUser && !currentUser.id)) {
      return null;
    }

    return (
      <CustomerHeader
        showDrawerIcon={showDrawerIcon}
        drawerWidth={drawerWidth}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        notification={<NotificationBell />}
      />
    );
  };

  const getLinks = () => {
    return <HcpLinks currentUser={currentUser} />;
  };

  return (
    <Box sx={{ display: 'flex', scrollBehavior: 'smooth' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: 'black',
            color: 'white',
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <DrawerHeader>
          <IconButton
            sx={{
              color: 'white',
            }}
            size='large'
            onClick={handleDrawerClose}
          >
            {theme.direction === 'ltr' ? <MenuOpenIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <RequestSyncData />
        <Divider />
        <List>{getLinks()}</List>
      </Drawer>
      <UI />
      <Main open={open}>
        <CurrentUser />
        <Dialogs />
        {getHeader(currentUser)}
        {children}
      </Main>
      <Version version='v2.30.4' />
    </Box>
  );
}

AppLayout.propTypes = {
  children: PropTypes.element,
};
