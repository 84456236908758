import React, { Suspense } from 'react';

const ManageHcpUsers = React.lazy(() => import('../DashBoard/containers/ManageUsers'));
export default [
  {
    path: '/hcp/manage/users',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ManageHcpUsers />
      </Suspense>
    ),
    private: true,
  },
];
