import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MachineSetupView from 'modules/Patients/components/Machines/Lumbar/MachineSetup';

import { useMachineSetup } from 'modules/Patients/hooks/useMachineSetup';
import MachineSetupContainer from 'modules/Patients/components/MachineSetupContainer';
import NoResults from 'modules/Patients/components/NoResults';
import { useEffect } from 'react';

export default function MachineSetup() {
  const {
    Provider,
    handleBack,
    handleNext,
    handleSaveData,
    handleSelectItem,
    isLoading,
    machine,
    registerNavigation,
    socketContext,
    patient,
    step,
    subStep,
    handleSetStep,
    handleSetSubStep,
    navigationItems,
    setupMachineData
  } = useMachineSetup();

  useEffect(() => {
    if (!machine) return null;

    if (step) {
      handleSetStep(step);
    } else {
      handleSetStep('1');
    }

    if (subStep) {
      handleSetSubStep(subStep);
    } else {
      handleSetSubStep('0');
    }
  }, [machine, setupMachineData]);

  if (!patient || !machine) return <NoResults isLoading={isLoading} />;

  return (
    <Provider value={{ registerNavigation: registerNavigation, navigationItems }}>
      <MachineSetupContainer>
        <MachineSetupView
          patient={patient}
          machine={machine}
          handleSaveData={handleSaveData}
          handleNext={handleNext}
          handleBack={handleBack}
          handleSelectItem={handleSelectItem}
          step={step}
          subStep={subStep}
          handleSetStep={handleSetStep}
          handleSetSubStep={handleSetSubStep}
          socketContext={socketContext}
        />
      </MachineSetupContainer>
    </Provider>
  );
}
