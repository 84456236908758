import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

export const MultipleSelectContainer = styled('div')`
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 2px;
  }

  .css-lfn2w0-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: #d4d4d4;
  }

  .css-1tszr1j-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: #d4d4d4;
  }

  //.css-1tvguit-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  //  color: #737373;
  //}
  label {
    color: #737373 !important;
  }
`;

export default function MultipleSelect({ value, options, width, onChange, field }) {
  const [values, setValues] = useState([]);

  useEffect(() => {
    return () => {
      setValues([]);
      onChange(field, []);
    };
  }, []);

  useEffect(() => {
    if (value.length === 0) {
      setValues([]);
      return;
    }

    const _values = value.map((v) => v.value);
    setValues(_values);
  }, [value]);
  const onChangeHandler = (e, v) => {
    onChange(field, v);
  };

  const renderLabel = (option) => {
    return option.label;
  };

  return (
    <Autocomplete
      multiple
      id='tags-standard'
      options={options}
      getOptionLabel={renderLabel}
      getOptionDisabled={(option) => values.includes(option.value)}
      defaultValue={[...value]}
      onChange={onChangeHandler}
      disableClearable
      clearIcon={false}
      renderInput={(params) => (
        <MultipleSelectContainer>
          <TextField
            sx={{ width: width }}
            {...params}
            label={[...value]?.length === 0 ? 'Select' : ``}
            InputLabelProps={{ shrink: false }}
          />
        </MultipleSelectContainer>
      )}
    />
  );
}

MultipleSelect.propTypes = {
  field: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.array,
  width: PropTypes.number,
};
