import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  customerLogin: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/session`,
    method: 'POST',
  },
  customerVerifyOpt: {
    url: `${ENV_VARIABLES.API_SERVER}challenge/totp/verify`,
    method: 'POST',
  },
  customerResendOpt: {
    url: `${ENV_VARIABLES.API_SERVER}challenge/totp/re-send`,
    method: 'POST',
  },
  hcpUserlogout: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/session`,
    method: 'DELETE',
  },
  profile: {
    url: `${ENV_VARIABLES.API_SERVER}users/me`,
  },
  customerProfile: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/me`,
  },

  checkInviteToken: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}users/invite-token?token=${token}`,
  },
  acceptInvite: {
    url: `${ENV_VARIABLES.API_SERVER}users/accept-invite`,
  },
  customersCheckInviteToken: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}hcps/invite-token?token=${token}`,
  },
  customersAcceptInvite: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/accept-invitation`,
  },
  hcpUsersforgot: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/password`,
  },
  checkHcpUsersForgotPasswordToken: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}hcps/password?token=${token}`,
  },
  checkHcpUsersChangeEmailToken: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}hcps/me/email?token=${token}`,
  },
  resetHcpUserPassword: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}hcps/password?token=${token}`,
  },
  checkSubdomain: {
    url: (subdomain) => `${ENV_VARIABLES.API_SERVER}hcps/subdomain?subdomain=${subdomain}`,
  },
};

export const OTP_CODE = {
  SUCCESS_CODE: 0,
  EXPIRED_CODE: 1,
  INCORRECT_CODE: 2
};

export const NUMBER_ATTEMPT = {
  REACHED_FIRST_LIMIT: 2,
  REACHED_LAST_LIMIT: 0
}

export const REMAINING_ATTEMPT_DEFAULT = 5;

export const COUNTDOWN_RESEND_FIRST = 180;
export const COUNTDOWN_RESEND_LAST = 300;
export const NUMBER_DAY_REMEMBER_DEVICE = 30;
