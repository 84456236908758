import { put, takeLatest } from 'redux-saga/effects';
import {
  beginLoading,
  endLoading,
  beginLoadingSuccess,
  endLoadingSuccess,
  requestToast,
  requestToastSuccess,
  requestToastFailure,
} from 'modules/Ui/slice';

function* startLoading() {
  yield put(beginLoadingSuccess());
}

function* stopLoading() {
  yield put(endLoadingSuccess());
}

function* updateToast(action) {
  try {
    const data = {
      type: action.payload.type,
      message: action.payload.message,
      horizontal: action.payload.horizontal,
    };
    yield put(requestToastSuccess(data));
  } catch (e) {
    yield put(requestToastFailure(e.message));
  }
}

function* uiSaga() {
  yield takeLatest(beginLoading, startLoading);
  yield takeLatest(endLoading, stopLoading);
  yield takeLatest(requestToast, updateToast);
}

export default uiSaga;
