import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function BasicTable({ headers, data, onRowClick }) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const getHeaders = () => {
    return headers.map((header) => (
      <TableCell
        sx={{
          width: header.width,
        }}
        padding={header.disablePadding ? 'none' : 'normal'}
        align={header.align}
        key={header.title}
      >
        {header.title}
      </TableCell>
    ));
  };

  const getRows = () => {
    const onClickHandler = (row) => {
      if (row.disable) {
        return;
      }
      handleRowClick(row);
    };

    data.sort((a, b) => {
      if (a.disable && !b.disable) {
        return 1;
      } else if (!a.disable && b.disable) {
        return -1;
      } else {
        return 0;
      }
    });

    return data.map((row, i) => (
      <TableRow
        hover={!row.disable}
        onClick={() => onClickHandler(row)}
        key={row.name + i}
        sx={!row.disable ? { cursor: 'pointer' } : { cursor: 'pointer', opacity: 0.5 }}
      >
        {Object.keys(row).map((key) => {
          return headers.map((header) => {
            if (header.dataKey === key) {
              return (
                <TableCell key={key}>
                  {header.cellRenderer ? header.cellRenderer({ cellData: row[key] }) : row[key]}
                </TableCell>
              );
            }
          });
        })}
      </TableRow>
    ));
  };

  return (
    <TableContainer sx={{ maxHeight: '100%' }} component={Paper}>
      <Table stickyHeader sx={{ minWidth: '650' }} aria-label='simple table'>
        {isLoading && data && !data.length && <caption>{t('common:errors.noResults')}</caption>}
        <TableHead>
          <TableRow>{getHeaders()}</TableRow>
        </TableHead>
        <TableBody>{getRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  overrideGetActions: PropTypes.func,
  onRowClick: PropTypes.func,
};
