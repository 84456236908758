export const defaultNavigateItems = [
  {
    id: '1',
    name: 'Questions',
  },
  {
    id: '2',
    hasSkip: true,
    name: 'Patient Setup',
    children: [
      {
        id: '2-1',
        name: 'Preparation',
      },
      {
        id: '2-2',
        name: 'Instructions',
      },
      {
        id: '2-3',
        name: 'Seating / Footboard',
      },
      {
        id: '2-4',
        name: 'Gate Position',
      },
      {
        id: '2-5',
        name: 'Tighten Restraints',
      },
    ],
  },

  {
    id: '3',
    name: 'Exercise Instructions',
  },
  {
    id: '4',
    name: 'Dynamic Exercise',
  },
];

export const oneSideNavigateItems = (side) => [
  {
    id: '1',
    name: 'Questions',
  },
  {
    id: '2',
    hasSkip: true,
    name: 'Patient Setup',
    children: [
      {
        id: '2-1',
        name: 'Preparation',
      },
      {
        id: '2-2',
        name: 'Instructions',
      },
      {
        id: '2-3',
        name: 'Seating / Footboard',
      },
      {
        id: '2-4',
        name: 'Gate Position',
      },
      {
        id: '2-5',
        name: 'Tighten Restraints',
      },
    ],
  },

  {
    id: '3',
    name: 'Exercise Instructions',
  },
  {
    id: '4',
    name: `${side === 1 ? 'Left' : 'Right'} Side Dynamic Exercise`,
    children: [
      {
        id: '4-1',
        name: 'Machine Setup',
      },
      {
        id: '4-2',
        name: 'Patient Adjustments',
      },
      {
        id: '4-3',
        name: 'Set Angle Selector',
      },
      {
        id: '4-4',
        name: 'Exercise',
      },
    ],
  },
];

export const bothSideNavigateItems = (firstSide) => [
  {
    id: '1',
    name: 'Questions',
  },
  {
    id: '2',
    hasSkip: true,
    name: 'Patient Setup',
    children: [
      {
        id: '2-1',
        name: 'Preparation',
      },
      {
        id: '2-2',
        name: 'Instructions',
      },
      {
        id: '2-3',
        name: 'Seating / Footboard',
      },
      {
        id: '2-4',
        name: 'Gate Position',
      },
      {
        id: '2-5',
        name: 'Tighten Restraints',
      },
    ],
  },

  {
    id: '3',
    name: 'Exercise Instructions',
  },
  {
    id: '4',
    name: `${firstSide === 1 ? 'Left' : 'Right'} Side Dynamic Exercise`,
    children: [
      {
        id: '4-1',
        name: 'Machine Setup',
      },
      {
        id: '4-2',
        name: 'Patient Adjustments',
      },
      {
        id: '4-3',
        name: 'Set Angle Selector',
      },
      {
        id: '4-4',
        name: 'Exercise',
      },
      {
        id: '4-5',
        name: 'Summary',
      },
    ],
  },
  {
    id: '5',
    name: `${firstSide === 1 ? 'Right' : 'Left'} Side Dynamic Exercise`,
    children: [
      {
        id: '5-1',
        name: 'Machine Setup',
      },
      {
        id: '5-2',
        name: 'Patient Adjustments',
      },
      {
        id: '5-3',
        name: 'Set Angle Selector',
      },
      {
        id: '5-4',
        name: 'Exercise',
      },
    ],
  },
];

export const getDynamicNavigate = ({ torsoTestMode, firstSide }) => {
  if (!firstSide || !torsoTestMode) {
    return defaultNavigateItems;
  }

  if (torsoTestMode < 3) {
    return oneSideNavigateItems(torsoTestMode);
  }

  return bothSideNavigateItems(firstSide);
};
