import { put, takeLatest } from 'redux-saga/effects';
import axios, { all } from 'axios';

import {
  syncStatus,
  uploadFileFailure,
  uploadFile,
  syncStatusSuccess,
  uploadFileSuccess,
  syncFailure,
  syncData,
  syncSuccess,
  requestDownloadFile,
  requestDownloadFileSuccess,
  requestDownloadFileFailure,
} from './slice';
import { END_POINT } from 'modules/DataSync/constants.js';
import { requestToast } from 'modules/Ui/slice.js';
import { TOAST_TYPE } from 'modules/Ui/constants.js';

function* fetchSyncStatus(action) {
  try {
    const { data } = yield axios.get(`${END_POINT.getSyncStatus.url(action.payload.hcpId, action.payload.requestKey)}`);

    yield put(syncStatusSuccess(data));
  } catch (e) {
    console.warn(e);
  }
}

function* uploadLocalFile(action) {
  try {
    const { data } = yield axios.post(`${END_POINT.postUploadFile.url(action.payload.hcpId)}`, action.payload.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(uploadFileSuccess(data));
  } catch (e) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: 'Upload failure.',
      })
    );
    yield put(uploadFileFailure());
    console.warn(e);
  }
}

function* syncDataHandler() {
  try {
    yield axios.get(`${END_POINT.syncFromCloud.url}`);
    yield put(syncSuccess());
  } catch (e) {
    yield put(syncFailure());
  }
}

function* downloadFileHandler(action) {
  try {
    const { data } = yield axios.get(END_POINT.downloadFile.url);

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear()).slice(-2);

    const fileName = `${action.payload.u12_id}_${action.payload.mac_addr}_${day + month + year}.gz`;

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    yield put(requestDownloadFileSuccess());
    yield put(
      requestToast({
        type: TOAST_TYPE.SUCCESS,
        message: `Download successfully.`,
      })
    );
  } catch (e) {
    yield put(requestDownloadFileFailure());
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Download failure.`,
      })
    );
  }
}

function* syncSaga() {
  yield takeLatest(syncStatus, fetchSyncStatus);
  yield takeLatest(uploadFile, uploadLocalFile);
  yield takeLatest(syncData, syncDataHandler);
  yield takeLatest(requestDownloadFile, downloadFileHandler);
}

export default syncSaga;
