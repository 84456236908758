import PropTypes from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ScrollDialog from 'components/ScrollDialog/index.jsx';
import Actions from 'modules/Patients/components/Machines/Actions';

const manual = () => (
  <Box sx={{ width: '100%', height: '100vh' }}>
    <iframe
      src='https://drive.google.com/file/d/1A47JpU1LGT-ISlRT8kWEMUM00BbyK4Fz/preview'
      width='100%'
      height='100%'
      allow='autoplay'
      frameBorder='0'
    ></iframe>
  </Box>
);

export function PatientSetup({ step, subStep, handleCancel, handleNext }) {
  // const { t } = useTranslation();

  return (
    <>
      <Typography sx={{ fontSize: '20px' }} variant='body1' component='div'>
        Put patient into the Lumbar machine, using instructions from previous setup.
      </Typography>
      <Typography variant='body1' component='div'>
        *Input previous setup instructions here.
      </Typography>
      {/* actions */}
      <Actions step={step} subStep={subStep} handleCancel={handleCancel} handleNext={handleNext} />
    </>
  );
}
PatientSetup.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function PatientInstruction({ step, subStep, handleCancel, handleBack, handleNext }) {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1' component='div'>
          Give patient Isometric Testing instruction
        </Typography>
        <Button onClick={() => setOpenModal(true)} sx={{ ml: 'auto', textDecoration: 'underline' }} variant='text'>
          VIEW MANUAL
        </Button>
      </Box>
      <ScrollDialog
        fullWidth={true}
        message={manual()}
        title='ISOMETRIC MANUAL'
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        handleSubmitModal={handleSubmitModal}
      />
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
PatientInstruction.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function PracticeTestInstruction({ step, subStep, handleCancel, handleBack, handleNext }) {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Typography variant='body1' component='div'>
        1. Have the patient rest after dynamic warm-up while practice test instructions are explained.
        <br />
        2. Provide following instructions to the patient:
        <br />
        <div style={{ padding: '0 20px' }}>
          • Tests will be conducted at 3 random test angles positions throughout the ROM.
          <br />
          • Only exert force as requested by clinician.
          <br />• Only exert up to 50% max force during practice test to the cadence: push 25%, 50%, and relax.
        </div>
        3. Make sure the patient is snug in the machine: Adjust shoulder restraints and torso restraint.
        <br />
        4. Confirm with patient that they cannot lift their shoulders.
        <br />
        5. Patient’s hand should be crossed in their lap. Feet should be crossed at the ankles and remain still.
        <br />
      </Typography>
      <ScrollDialog
        fullWidth={true}
        message={manual()}
        title='ISOMETRIC MANUAL'
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        handleSubmitModal={handleSubmitModal}
      />
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
PracticeTestInstruction.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export function ActualTestInstruction({ step, handleCancel, handleBack, handleNext, subStep }) {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Typography variant='body1' component='div'>
        1. Provide following instructions to the patient:
        <div style={{ padding: '0 20px' }}>
          • Tests will be conducted at pre-set test angles positions selected on the previous screen, which are within
          your pain-free ROM.
          <br />
          • Only exert force as requested by clinician.
          <br />• Gradually exert your max force during practice test to the cadence: push 25%, 50%, 75%, 100% and
          relax.
        </div>
        2. Make sure the patient is snug in the machine: Adjust shoulder restraints and torso restraint.
        <br />
        3. Confirm with patient that they cannot lift their shoulders.
        <br />
        4. Patient’s hand should be crossed in their lap. Feet should be crossed at the ankles and remain still.
      </Typography>
      <ScrollDialog
        fullWidth={true}
        message={manual()}
        title='ISOMETRIC MANUAL'
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        handleSubmitModal={handleSubmitModal}
      />
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
}
ActualTestInstruction.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
