import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SessionInfo } from 'modules/Patients/components/Info';
import SideBar from 'modules/Patients/components/Machines/TestSideBar';
import AlertDialog from 'components/AlertDialog/index.jsx';

import { useDispatch, useSelector } from 'react-redux';

import { clearActivities, fetchMachineSetup } from '../../../../slice';

import Steps from './Steps';
import ActivityQuestion from 'modules/Patients/containers/Machines/ActivityQuestion/index.jsx';
import { useSocketDataContext } from 'components/WebSocketProvider';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export default function Index({
  patient,
  machine,
  handleNext,
  handleBack,
  handleSelectItem,
  handleSkipItem,
  step,
  subStep,
  setupId,
  navigationItems,
  defaultAngles,
  handleRemoveAngle,
  handleUndoAngle,
  handleRemoveSkip,
  setRemovedAngles,
  removedAngles,
  setIsModalSaved,
  isModalSaved,
  testStack,
  isFirstStepSkipped,
  isSecondStepSkipped,
}) {
  const navigate = useNavigate();
  const socketContext = useSocketDataContext();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const setupMachineData = useSelector((state) => state.patients.setup);
  const arrMachinesConfigured = useSelector((state) => state.machines.hcpConfiguredMachines);

  const [openAlert, setOpenAlert] = useState(false);
  const [disablePracticePreSetAngle, setDisablePracticePreSetAngle] = useState(false);
  const [disableActualPreSetAngle, setDisableActualPreSetAngle] = useState(false);
  const [testValue, setTestValue] = useState(() => {
    const initActualTestValue = [];
    for (let i = 72; i >= 0; i -= 12) {
      initActualTestValue.push({
        name: i,
        stored: 0,
        peak: 0,
        targetValue: undefined,
      });
    }

    return {
      3: {
        value: 0,
        isDone: false,
        currentTarget: undefined,
        dataChart: [],
        time: 0,
        pounds: 0,
      },
      4: {
        value: [],
      },
      5: {
        value: initActualTestValue,
      },
    };
  });
  const [stepData, setStepData] = useState([]);

  const machineId = searchParams.get('machineId');
  const machineType = searchParams.get('machineType');
  const patientId = id;

  useEffect(() => {
    const data = {
      hcpId: localStorage.getItem('hcpId'),
      patientId: atob(patientId),
      machineId: Number(machineId),
    };
    if (!setupMachineData && setupId) {
      dispatch(fetchMachineSetup({ ...data, setupId: setupId }));
    }

    return () => {
      handleUndoAngle(true);
      dispatch(clearActivities());
    };
  }, []);

  useEffect(() => {
    if (!isNil(machineId) && !isNil(machineType) && !isEmpty(arrMachinesConfigured)) {
      socketContext.handleFilterMachine({ machineId, machineType, machines: arrMachinesConfigured });
    }
  }, [machineId, machineType, arrMachinesConfigured]);


  useEffect(() => {
    return () => {
      socketContext.handleResetMachine();
    }
  }, []);

  const handleCancel = () => {
    setOpenAlert(true);
  };

  const handleSubmitSecondaryAction = () => {
    handleUndoAngle(true);
    localStorage.removeItem('activity_id');
    dispatch(clearActivities());
    navigate(`/patient/${btoa(patient.patient_id)}&tab=1`);
  };

  const handleSubmitAlertDialog = () => {
    setOpenAlert(false);
  };

  const onDataChange = (data) => {
    if (!data) {
      return null;
    }

    if (Number(data?.step) === 2) {
      const value = { ...testValue };

      switch (Number(data?.subStep)) {
        case 2:
          value['3'].weight = data.value;
          break;
        case 3:
          value['3'].currentTarget = data.currentTarget;
          // handleRemoveSkip(step);
          setTestValue(value);
          break;
        case 4:
          value['3'].dataChart = [...data.dataChart];
          value['3'].value = data.value;
          value['3'].time = data.time;
          value['3'].pounds = data.pounds;
          value['3'].isDone = data.isDone;
          handleRemoveSkip(step);
          setTestValue(value);
          break;
        default:
          break;
      }
    }

    if (Number(data?.step) === 4 && Number(data?.subStep) === 1) {
      const value = { ...testValue };
      testValue[data.step].value = [
        {
          name: data.value.s1Angle,
          stored: 0,
          peak: 0,
          targetValue: undefined,
        },
        {
          name: data.value.s2Angle,
          stored: 0,
          peak: 0,
          targetValue: undefined,
        },
        {
          name: data.value.s3Angle,
          stored: 0,
          peak: 0,
          targetValue: undefined,
        },
      ];
      setTestValue(value);
      return;
    } else if ((Number(data?.step) === 4 && Number(data?.subStep) >= 3) || Number(data?.step) === 5) {
      const value = { ...testValue };
      testValue[data.step].value = [...data.value];

      if (Number(data?.subStep) === 5) {
        handleRemoveSkip(step);
      }

      setTestValue((prevState) => ({ ...prevState, value }));
    }

    //temporarily save data of steps into the state as an array
    const newValue = stepData.filter((s) => s.step !== data.step && s.subStep !== data.subStep);
    newValue.push(data);

    setStepData(newValue);
  };

  const printRef = useRef();

  return (
    <Box ref={printRef}>
      <SessionInfo machine={machine} patient={patient} title={'Isometric Test'} />
      <Box
        sx={{
          display: 'flex',
          pt: 5,
        }}
      >
        {
          <SideBar
            navigationItems={navigationItems}
            step={step}
            subStep={subStep}
            handleSelectItem={handleSelectItem}
            handleSkipItem={handleSkipItem}
          />
        }
        <Box sx={{ width: '60%', ml: 4 }}>
          <Box>
            <Steps
              step={step}
              subStep={subStep}
              handleCancel={handleCancel}
              handleBack={handleBack}
              handleNext={handleNext}
              onDataChange={onDataChange}
              machine={machine}
              setupMachineData={setupMachineData}
              handleUndoAngle={handleUndoAngle}
              defaultAngles={defaultAngles}
              testStack={testStack}
              handleRemoveSkip={handleRemoveSkip}
              testValue={testValue}
              disableActualPreSetAngle={disableActualPreSetAngle}
              disablePracticePreSetAngle={disablePracticePreSetAngle}
              setDisablePracticePreSetAngle={setDisablePracticePreSetAngle}
              setDisableActualPreSetAngle={setDisableActualPreSetAngle}
              stepData={stepData}
              isFirstStepSkipped={isFirstStepSkipped}
              isSecondStepSkipped={isSecondStepSkipped}
              handleRemoveAngle={handleRemoveAngle}
              setRemovedAngles={setRemovedAngles}
              removedAngles={removedAngles}
              setIsModalSaved={setIsModalSaved}
              isModalSaved={isModalSaved}
            />
          </Box>
        </Box>

        <AlertDialog
          secondaryActionText='End'
          primaryActionText='Return'
          handleSubmitAlertDialog={handleSubmitAlertDialog}
          handleSubmitSecondaryAction={handleSubmitSecondaryAction}
          openAlertDialog={openAlert}
          message={
            <Box sx={{ maxWidth: '315px' }}>
              Are you sure you want to cancel this Isometric Test? All data will be deleted.
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

Index.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSelectItem: PropTypes.func,
  handleSkipItem: PropTypes.func,
  handleRemoveAngle: PropTypes.func,
  removedAngles: PropTypes.array,
  setRemovedAngles: PropTypes.func,
  handleUndoAngle: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
  setIsModalSaved: PropTypes.func,
  isModalSaved: PropTypes.bool,
  navigationItems: PropTypes.array,
  defaultAngles: PropTypes.array,
  step: PropTypes.string,
  subStep: PropTypes.string,
  setupId: PropTypes.string || PropTypes.number,
  testStack: PropTypes.array,
  isFirstStepSkipped: PropTypes.bool,
  isSecondStepSkipped: PropTypes.bool,
};
