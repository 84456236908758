import { BasicTable } from 'components/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { dateParserWithTimezone } from 'helpers/datetime.js';
import { FORMAT_DATE } from 'modules/Patients/constants.js';
import { format } from 'date-fns';
import caspioLogo from 'assets/images/logo/caspio.svg';
import Box from '@mui/material/Box';
import { SkeletonPatientActivities } from 'components/Loading';
const machineRender = ({ cellData }) => {
  return (
    <>
      <Typography variant='body1' component='div'>
        {cellData.name}
      </Typography>
      <Typography sx={{ fontSize: '0.8rem' }} variant='body2' component='div'>
        SN: {cellData.serial_no}
      </Typography>
      {cellData.isRemoved && '(Machine removed)'}
    </>
  );
};

const EMRRender = ({ cellData }) => {
  if (!cellData) {
    return '';
  }

  return (
    <Box
      component='img'
      sx={{
        cursor: 'pointer',
        height: 'auto',
        display: { xs: 'none', md: 'block' },
        maxWidth: '250px',
      }}
      src={caspioLogo}
    />
  );
};

const columns = [
  {
    title: 'Date & Time',
    dataKey: 'date',
    key: 'column-1',
    width: 0.15,
  },
  {
    title: 'Activities',
    dataKey: 'name',
    key: 'column-2',
    width: 0.15,
  },
  {
    title: 'Setup Applied',
    dataKey: 'setupName',
    key: 'column-3',
    width: 0.15,
  },
  {
    title: 'Machines Type',
    dataKey: 'type',
    key: 'column-4',
    width: 0.15,
  },
  {
    title: 'Machines',
    dataKey: 'originalMachineData',
    key: 'column-5',
    cellRenderer: machineRender,
    width: 0.25,
  },
  {
    title: 'Pushed to EMR',
    dataKey: 'caspio',
    key: 'column-6',
    cellRenderer: EMRRender,
    width: 0.15,
  },
];

export default function ActivitiesTable({ emptySmg = [], machines, session, setDisablePushEMR }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const patientId = id;

  const isFetchPatientSession = useSelector((state) => state.patients.isFetchPatientSession);
  const [dataSrc, setDataSrc] = useState([]);

  const isPushedToCaspio = (activity) => {
    if (!activity.emr_status) {
      setDisablePushEMR(false);
      return false;
    }
    if (!activity.emr_status.caspio) {
      setDisablePushEMR(false);
      return false;
    }

    if (activity.emr_status.caspio.status === 'Done') {
      return true;
    }

    if (activity.emr_status.caspio.status !== 'Processing') {
      setDisablePushEMR(false);
    }

    setDisablePushEMR(false);
    return false;
  };

  useEffect(() => {
    if (!session) {
      return null;
    }

    const { activities } = session;
    const data = [];
    activities.map((activity) => {
      const localDate = dateParserWithTimezone(activity.initiated_at);
      data.push({
        id: activity.id,
        date: format(localDate, FORMAT_DATE),
        machineId: activity.machine_id,
        name: activity?.name,
        setupName: activity.setup_name,
        type: activity.machine_type,
        serialNumber: activity.serial_no,
        originalMachineData: {
          name: activity.machine_name,
          serial_no: activity.serial_no,
          isRemoved: !!activity.deleted_at,
        },
        activityType: activity.activity_type,
        setupId: activity.setup_id,
        disable: !activity.total_isometric_data && !activity.total_dynamic_data,
        caspio: isPushedToCaspio(activity),
      });
    });
    setDataSrc([...data]);
  }, [session]);

  const onRowClickHandler = (rowData) => {
    if (rowData.activityType === 1) {
      if (!rowData?.setupId || !rowData.machineId) {
        return null;
      }

      navigate(
        `/patient/${patientId}/isometric-test/summary?setupId=${rowData.setupId}&machineType=${rowData.type}&sessionId=${session.id}&machineId=${rowData.machineId}&activitiesId=${rowData.id}&viewOnly=true`
      );
    } else {
      navigate(
        `/patient/${patientId}/dynamic-test/summary?setupId=${rowData.setupId}&machineType=${rowData.type}&sessionId=${session.id}&machineId=${rowData.machineId}&activitiesId=${rowData.id}&viewOnly=true`
      );
    }
  };


  if (isFetchPatientSession) return <SkeletonPatientActivities />;

  if ((dataSrc?.length === 0) && !isFetchPatientSession) {
    return <Typography>{emptySmg || 'No data available'}</Typography>;
  }

  if ((dataSrc?.length > 0) && !isFetchPatientSession) {
    return <BasicTable onRowClick={onRowClickHandler} headers={columns} data={dataSrc} />;
  }

  return <></>;

}

ActivitiesTable.propTypes = {
  emptySmg: PropTypes.string,
  session: PropTypes.object,
  disableExtra: PropTypes.bool,
  type: PropTypes.string,
  machines: PropTypes.array,
  setDisablePushEMR: PropTypes.func,
};
