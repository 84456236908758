import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { SingleImageStep } from 'helpers';
import doorOpen from 'assets/images/SVG/Cervical/Cervical-extension_door-open.svg';
import adamApple from 'assets/images/SVG/Cervical/Measure_adams-apple.svg';
import chainTight from 'assets/images/SVG/Cervical/Chair-tightener.svg';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';

export function SeatingPosition({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box>1. Here is a step by step through setting up your patient in the machine.</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Box>
                <SingleImageStep img={doorOpen} />
              </Box>

              <Box>2. Have the patient sit upright in the machine with their back against the seatback.</Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 3 }}>
              <Box>
                <SingleImageStep img={chainTight} />
              </Box>

              <Box>
                3. Have the patient placed their right hand, palm down across their Adam’s apple to help alignment.
                Please add a seat pad if needed to achieve this height.
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 3 }}>
              <Box>
                <SingleImageStep img={adamApple} />
              </Box>

              <Box>
                4. Have the patient placed their right hand, palm down across their Adam’s apple to help alignment.
              </Box>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
SeatingPosition.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
