import { all } from 'redux-saga/effects';
import authSaga from 'modules/Auth/sagas';
import profileSaga from 'modules/Profile/sagas';
import cutomersSaga from 'modules/DashBoard/sagas';
import hcpUsersSaga from 'modules/HcpUsers/sagas';
import machinesSaga from 'modules/Machines/sagas';
import patientsSaga from 'modules/Patients/sagas';
import permissionsSaga from 'modules/Permissions/sagas';
import dialogsSaga from 'modules/Dialogs/sagas';
import uiSaga from 'modules/Ui/sagas';
import offlineSagas from './modules/Offline/sagas';
import syncSagas from './modules/DataSync/sagas';
import emrSaga from 'modules/EMR/sagas';
import notiSaga from 'modules/Notifications/sagas';
import auditLogsSaga from 'modules/AuditLogs/sagas';

export default function* rootSaga() {
  yield all([
    authSaga(),
    profileSaga(),
    uiSaga(),
    dialogsSaga(),
    cutomersSaga(),
    machinesSaga(),
    hcpUsersSaga(),
    patientsSaga(),
    permissionsSaga(),
    offlineSagas(),
    syncSagas(),
    emrSaga(),
    notiSaga(),
    auditLogsSaga()
  ]);
}
