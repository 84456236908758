import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { demoGraphicOptionsLabel, getLabel, getToggleOptionLabel } from 'helpers';
import {
  ACTIVITY_LEVEL_OPTIONS,
  HOUR_FOR_SLEEP_OPTIONS,
  IS_CONDITION_COMPLEX_OPTIONS,
  IS_INJURY_ACUTE_OPTIONS,
  IS_SYMPTOMATIC_OPTIONS,
} from 'modules/DemoGraphic/Shared/options.js';
import { dateParserWithTimezone } from 'helpers/datetime.js';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import BorderColor from '@mui/icons-material/BorderColor.js';
import { openDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import { useDispatch } from 'react-redux';

const Container = styled('div')`
  .title {
    font-weight: 600;
    font-size: 14px;
    color: #666666;
  }
  .value {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
  }
  .row {
    width: 100%;
    display: flex;
    align-items: start;
    gap: 24px;
    padding: 48px 0;
    border-top: 2px solid #f5f5f5;
  }
  .main-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }
`;

const HealthInformation = ({ healthInformation, onEdit }) => {
  const onEditHandler = () => {
    onEdit(1);
  };
  const contentRender = () => {
    if (!healthInformation || (healthInformation && Object.keys(healthInformation).length === 0)) {
      return (
        <Box sx={{ width: '100%', padding: '0 15px ' }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <span className={'main-title'}> Health Information</span>
              <div style={{ cursor: 'pointer' }} onClick={() => onEditHandler()}>
                <IconButton sx={{ padding: 0 }} color='secondary'>
                  <BorderColor />
                </IconButton>
                <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
              </div>
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '15%' }}>
              <span className={'title'}>Height (Inches)</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <span className={'title'}>Weight (Pounds)</span>
              <span className={'value'}>{'N/A'}</span>
            </Box>
          </Box>

          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Currently injured or not?</span>
                  <span className={'value'}>{'N/A'}</span>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <span className={'title'}>Activity level</span>
                <span className={'value'}>N/A</span>
              </Box>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <span className={'title'}>Hours of sleep per night</span>
                <span className={'value'}>{'N/A'}</span>
              </Box>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <span className={'title'}>Allergies or sensitivities</span>
                <span className={'value'}>{'N/A'}</span>
              </Box>

              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <span className={'title'}>Comorbidity options</span>
                <span className={'value'}>{'N/A'}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ width: '100%', padding: '0 15px ' }}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <span className={'main-title'}> Health Information</span>
            <div style={{ cursor: 'pointer' }} onClick={() => onEditHandler()}>
              <IconButton sx={{ padding: 0 }} color='secondary'>
                <BorderColor />
              </IconButton>
              <span style={{ fontWeight: 500, padding: '9px' }}>Edit</span>
            </div>
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'start', paddingBottom: '48px' }}>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '15%' }}>
            <span className={'title'}>Height (Inches)</span>
            <span className={'value'}>{healthInformation.height ?? 'N/A'}</span>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <span className={'title'}>Weight (Pounds)</span>
            <span className={'value'}>{healthInformation.weight ?? 'N/A'}</span>
          </Box>
        </Box>

        <Box className={'row'}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                <span className={'title'}>Currently injured or not?</span>
                <span className={'value'}>{getToggleOptionLabel(healthInformation.currentlyInjured)}</span>
              </Box>
              {healthInformation.currentlyInjured === 'YES' && (
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '67%' }}>
                  <span className={'title'}>Type of injured</span>
                  <span className={'value'}>
                    {demoGraphicOptionsLabel(healthInformation.typeInjured, healthInformation.typeInjuredNote)}
                  </span>
                </Box>
              )}
            </Box>

            {healthInformation.currentlyInjured === 'YES' && (
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%', paddingRight: '2%' }}>
                  <span className={'title'}>Area of the body injured</span>
                  <span className={'value'}>
                    {demoGraphicOptionsLabel(healthInformation.areaInjured, healthInformation.areaInjuredNote)}
                  </span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%', paddingRight: '2%' }}>
                  <span className={'title'}>Is patient’s injury work related?</span>
                  <span className={'value'}>{getToggleOptionLabel(healthInformation.isInjuryWorkRelated)}</span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '30%' }}>
                  <span className={'title'}>Is injury Acute/Chronic/NA?</span>
                  <span className={'value'}>{getLabel(healthInformation.isInjuryAcute, IS_INJURY_ACUTE_OPTIONS)}</span>
                </Box>
              </Box>
            )}

            {healthInformation.currentlyInjured === 'YES' && (
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%', paddingRight: '2%' }}>
                  <span className={'title'}>Is your condition Complex or Non-complex?</span>
                  <span className={'value'}>
                    {getLabel(healthInformation.isConditionComplex, IS_CONDITION_COMPLEX_OPTIONS)}
                  </span>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%', paddingRight: '2%' }}>
                  <span className={'title'}>Is the injury Symptomatic vs A-symptomatic?</span>
                  <span className={'value'}>{getLabel(healthInformation.isSymptomatic, IS_SYMPTOMATIC_OPTIONS)}</span>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '30%' }}>
                  <span className={'title'}>Have you previously injured this Anatomical area?</span>
                  <span className={'value'}>{getToggleOptionLabel(healthInformation.hasPreviousInjured)}</span>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {healthInformation.currentlyInjured === 'YES' && (
          <Box className={'row'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', width: '100%' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                  <span className={'title'}>Have the patient had surgery to this anatomical region</span>
                  <span className={'value'}>{getToggleOptionLabel(healthInformation.hasSurgery)}</span>
                </Box>

                {healthInformation.hasSurgery === 'YES' && (
                  <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                    <span className={'title'}>Surgery type</span>
                    <span className={'value'}>{healthInformation.surgeryType ?? 'N/A'}</span>
                  </Box>
                )}
                {healthInformation.hasSurgery === 'YES' && (
                  <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', flexBasis: '33%' }}>
                    <span className={'title'}>Surgery date</span>
                    <span className={'value'}>
                      {healthInformation.surgeryDate
                        ? format(dateParserWithTimezone(healthInformation.surgeryDate), 'dd/MM/yyyy')
                        : 'N/A'}
                    </span>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}

        <Box className={'row'}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <span className={'title'}>Activity level</span>
              <span className={'value'}>{getLabel(healthInformation.activityLevel, ACTIVITY_LEVEL_OPTIONS)}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <span className={'title'}>Hours of sleep per night</span>
              <span className={'value'}>{getLabel(healthInformation.hourForSleep, HOUR_FOR_SLEEP_OPTIONS)}</span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <span className={'title'}>Allergies or sensitivities</span>
              <span className={'value'}>
                {demoGraphicOptionsLabel(healthInformation.sensitivities, healthInformation.sensitivitiesNote)}
              </span>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <span className={'title'}>Comorbidity options</span>
              <span className={'value'}>
                {demoGraphicOptionsLabel(
                  healthInformation.comorbidityOptions,
                  healthInformation.comorbidityOptionsNote
                )}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return <Container>{contentRender()}</Container>;
};

HealthInformation.propTypes = {
  healthInformation: PropTypes.object,
  onEdit: PropTypes.func,
};

export default HealthInformation;
