import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { dateParserWithTimezone } from 'helpers/datetime';
import { format } from 'date-fns';
import ListActivityAvailableCompare from '../ListActivityAvailableCompare';

const Container = styled('div')`
  table,
  td,
  th {
    border: 1px solid;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;

const TablePreviousActivity = ({ onFetchPrevActivity, onOpen, onClose, open, activeNet, activeTested, dataPrevActivity, currentDataActivity }) => {
  let dataIsoComparePercentRender = [];
  let dataIsoTestRender = [];
  let initTime = null;

  const formattedDate = (originalDate) => {
    if (!isNil(originalDate)) {
      const localDate = dateParserWithTimezone(originalDate);
      return format(localDate, "MMM d yyyy, hh:mm a");
    }
    return;
  };

  if (!isEmpty(dataPrevActivity?.data) && !isNil(currentDataActivity)) {
    initTime = formattedDate(dataPrevActivity?.initTime);
    if (activeNet) {
      dataIsoComparePercentRender = currentDataActivity?.net?.map((c, index) => {
        if (!isNil(dataPrevActivity?.data?.[index])) {
          const isMatchCondition = Math.abs(dataPrevActivity?.data?.[index]?.peak_torque) - Math.abs(dataPrevActivity?.data?.[index]?.stored_energy) > 0;
          if (isMatchCondition) {
            return Math.round(((c - (Math.abs(dataPrevActivity?.data?.[index]?.peak_torque) - Math.abs(dataPrevActivity?.data?.[index]?.stored_energy))) / (Math.abs(dataPrevActivity?.data?.[index]?.peak_torque) - Math.abs(dataPrevActivity?.data?.[index]?.stored_energy))) * 100);
          }
        }
      })
    } else {
      dataIsoComparePercentRender = currentDataActivity?.peak?.map((c, index) => {
        if (!isNil(dataPrevActivity?.data?.[index]) && Math.abs(dataPrevActivity?.data?.[index]?.peak_torque) !== 0) {
          return Math.round(((c - Math.abs(dataPrevActivity?.data?.[index]?.peak_torque)) / Math.abs(dataPrevActivity?.data?.[index]?.peak_torque)) * 100);
        } else {
          return '-';
        }
      })
    }
    dataIsoTestRender = dataPrevActivity?.data;
  }

  const renderPositiveNegativePercent = (number) => {
    if (!isNaN(number)) {
      if (number >= 0) {
        return `+${number}%`;
      }
      return `${number}%`;
    }
    return '-';
  }

  return (
    <>
      <ListActivityAvailableCompare
        type={'prev'}
        openModal={open}
        onCloseModal={onClose}
        onFetchPrevActivity={onFetchPrevActivity}
      />
      <Container>
        <table style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr style={{ color: 'black' }}>
              <th style={{ width: '275px', background: activeTested ? '#E03131' : '#fff', color: activeTested ? '#fff' : '#000', borderColor: 'black', textAlign: 'left', paddingLeft: 5, fontSize: '14px', fontWeight: 600 }}>
                Tested Functional Torque
              </th>
              {dataIsoTestRender?.map((c, index) => (
                <th style={{ background: activeTested ? '#FFE3E3' : '#fff', textAlign: 'center', fontSize: '14px', fontWeight: 400 }} key={`header-${index}`}>{!isNaN(c?.peak_torque) ? Math.abs(`${c?.peak_torque}`) : '-'}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr style={{ color: 'black' }}>
              <td style={{ textAlign: 'left', paddingLeft: 5, fontSize: '14px', fontWeight: 600 }} key={`stored-name}`}>Stored Energy</td>
              {dataIsoTestRender?.map((ele, index) => (
                <td style={{ textAlign: 'center', fontSize: '14px', fontWeight: 400 }} key={`stored-${index}`}>{!isNaN(ele?.stored_energy) ? Math.abs(`${ele?.stored_energy}`) : '-'}</td>
              ))}
            </tr>
            <tr style={{ color: 'black' }}>
              <td style={{ textAlign: 'left', paddingLeft: 5, fontSize: '14px', fontWeight: 600, background: activeNet ? '#E03131' : '#fff', color: activeNet ? '#fff' : '#000', borderColor: '#000' }} key={`net-name}`}>Net Muscular Torque</td>
              {dataIsoTestRender?.map((ele, index) => (
                <td style={{ textAlign: 'center', fontSize: '14px', fontWeight: 400, background: activeNet ? '#FFE3E3' : '#fff' }} key={`net-${index}`}>{!isNaN(ele?.peak_torque - ele?.stored_energy) ? Math.abs(`${ele?.peak_torque - ele?.stored_energy}`) : '-'}</td>
              ))}
            </tr>
            <tr style={{ color: 'black' }}>
              <td style={{ textAlign: 'left', paddingLeft: 5, fontSize: '14px', fontWeight: 600 }} key={`percent-name}`}>Compared to Current Test</td>
              {dataIsoComparePercentRender?.map((d, index) => (
                <td style={{ textAlign: 'center', fontSize: '14px', fontWeight: (d >= 0) ? 600 : 400 }} key={`peak-${index}`}>{renderPositiveNegativePercent(d)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </Container>
    </>
  );
};

TablePreviousActivity.propTypes = {
  onFetchPrevActivity: PropTypes.func,
};

export default TablePreviousActivity;
