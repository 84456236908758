export const getActualData = ({ pureData, subStep, angles }) => {
  const chart = [];
  let currentData;
  for (const key in pureData) {
    if (key) {
      if (angles.includes(Number(pureData[key].name)) && Number(pureData[key].index) <= Number(subStep)) {
        chart.push(pureData[key]);
        if (Number(pureData[key].index) === Number(subStep)) {
          currentData = pureData[key];
        }
      }
    }
  }
  return [chart, currentData];
};
