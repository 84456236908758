import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { TextareaAutosize } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { clearMachineSetup, fetchMachineSetup, fetchPatient, requestSaveSetup } from '../../../slice';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputText } from 'components/InputText';
import styled from '@emotion/styled';
import { fetchHcpMachines } from '../../../../Machines/slice';
import { SessionInfo } from '../../../components/Info';
import { isValidBase64 } from 'helpers';
import Container from '@mui/material/Container';
import SummaryControl from '../../SummaryControl';
import useCurrentUser from 'hooks/useCurrentUser';
import { Modal } from '../../../../../components/Modal';

const SummaryContainer = styled('div')`
  & .notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & .notes:hover {
    border-color: black;
  }
  & .notes:focus {
    outline-color: #ed9a00;
  }
`;

export const TorsoSetupSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const patientId = id;
  const setupId = searchParams.get('setupId');
  const sessionId = searchParams.get('sessionId');
  const machineId = searchParams.get('machineId');
  const mode = searchParams.get('mode');
  const tab = searchParams.get('tab');
  const isCloseDuplicate = searchParams.get('isCloseDuplicate');
  const isBack = searchParams.get('isBack');
  const hcpId = localStorage.getItem('hcpId');
  const type = searchParams.get('type');

  const patient = useSelector((state) => state.patients.patient);
  const machines = useSelector((state) => state.machines.hcpMachines);
  const setupMachineData = useSelector((state) => state.patients.setup);
  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const [isEditNotes, setIsEditNotes] = useState(false);
  const [setupName, setSetupName] = useState('');
  const [error, setError] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [notes, setNote] = useState('');
  const [editModal, setEditModal] = useState(false);

  const { permissions: currentPermissions } = useCurrentUser();

  useEffect(() => {
    dispatch(fetchHcpMachines({ hcpId }));
    dispatch(
      fetchMachineSetup({
        hcpId: hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
      })
    );
    if (patientId && isValidBase64(patientId) && !patient) {
      const data = {
        patientId: atob(id),
        hcpId,
      };
      dispatch(fetchPatient(data));
    }

    return () => {
      dispatch(clearMachineSetup());
      localStorage.removeItem('setupId');
    };
  }, []);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    setSetupName(setupMachineData.setup_name || '');
    setNote(setupMachineData.notes ?? '');
  }, [setupMachineData]);
  const hasExecuteActivitiesPermission = () => {
    return currentPermissions && currentPermissions.includes('EXECUTE_ACTIVITIES');
  };
  const sessionInfoRender = () => {
    const machine = machines.find((m) => Number(m.machine.id) === Number(machineId));
    return <SessionInfo machine={machine} patient={patient} title={t('customer:patientSession.machineSetupSummary')} />;
  };
  const onNoteCancelHandler = () => {
    if (!setupMachineData) {
      return null;
    }

    setNote(setupMachineData.notes ?? '');
    setIsEditNotes(false);
  };

  const onSetupNameCancelHandler = () => {
    setIsEditName(false);
    setError(false);
    setSetupName(setupMachineData.setup_name || '');
  };

  const onSaveHandler = () => {
    const data = {
      notes,
      setup_name: setupName,
    };

    dispatch(
      requestSaveSetup({
        hcpId,
        patientId: atob(id),
        machineId: machineId,
        setupId,
        finished: true,
        data,
      })
    );

    if (!isUpdating) {
      setIsEditNotes(false);
      setIsEditName(false);
    }
  };

  const cloneHandler = () => {
    const machine = machines.find((m) => m.machine.id === parseInt(machineId, 10));
    navigate(
      `/patient/${patientId}/machine/${machine.machine.id
      }/setups/${setupId}/summary-edit?sessionId=${sessionId}&machineType=${machine.machine.machine_type}&tab=${tab || 2
      }&mode=${mode}&type=${type}`
    );
  };

  const dataRender = () => {
    if (!setupMachineData) {
      return null;
    }

    if (!setupMachineData.data) {
      return null;
    }

    const data = setupMachineData.data;

    return (
      <Box sx={{ display: 'flex', padding: '15px 0' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            Max Left ROM
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data.range_motion_max !== null ? `${data.range_motion_max}°` : 'N/A'}
          </Typography>
          <br />
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            Gate Position
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data.torso_pad_pos !== null ? data.torso_pad_pos : 'N/A'}
          </Typography>
          <br />

          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            Seat pad
          </Typography>
          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data.seat_pad !== null ? data.seat_pad : 'N/A'}
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            Max Right ROM
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data.range_motion_min !== null ? `${data.range_motion_min}°` : 'N/A'}
          </Typography>
          <br />

          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            Footboard Restraint
          </Typography>

          <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
            {data.footboard_pos !== null ? data.footboard_pos : 'N/A'}
          </Typography>
        </div>
      </Box>
    );
  };

  const machineNameRender = () => {
    if (!setupMachineData) {
      return null;
    }
    if (!isEditName) {
      return (
        <Box sx={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
          <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
            {setupName}
          </Typography>
        </Box>
      );
    }

    const saveHandler = () => {
      if (!setupName || setupName.trim().length === 0) {
        setError(true);
      } else {
        setError(false);
        onSaveHandler();
      }
    };
    const changeHandler = (value) => {
      if (!value || value.length === 0) {
        setError(true);
      } else {
        setError(false);
      }

      setSetupName(value);
    };

    return (
      <Box sx={{ display: 'flex', gap: 5, width: '50%', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <InputText
          onChange={changeHandler}
          value={setupName}
          required={true}
          size={'small'}
          maxLength={255}
          errorSmg={'This field is required.'}
          isWidthFull={true}
          error={error}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onSetupNameCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
            {t('common:cancel')}
          </Button>

          <LoadingButton
            disabled={setupMachineData.setup_name === setupName || (!setupMachineData.setup_name && !setupName)}
            loading={isUpdating}
            onClick={saveHandler}
            size={'small'}
            variant='contained'
          >
            {t('common:save')}
          </LoadingButton>
        </Box>
      </Box>
    );
  };

  const footerRender = () => {
    const machine = machines.find((m) => Number(m.machine.id) === Number(machineId));
    return (
      <SummaryControl
        isBack={isBack === 'true'}
        machine={machine}
        patientId={id}
        setupId={setupId}
        sessionId={sessionId}
        disableControl={isEditName || isEditNotes}
        type={'MACHINE_SETUP'}
        setupValue={setupMachineData}
        mode={Number(mode)}
      />
    );
  };

  const duplicateAndEditRender = () => {
    if (isCloseDuplicate === 'false' && hasExecuteActivitiesPermission()) {
      return (
        <Button
          sx={{ color: '#fff', background: 'black', whiteSpace: 'nowrap' }}
          onClick={() => setEditModal(true)}
          disabled={isEditNotes || isEditName}
          size={'small'}
          variant={'contained'}
        >
          <span style={{ padding: '0 10px' }}>{t('common:duplicateAndEdit')}</span>
        </Button>
      );
    }

    return <Box></Box>;
  };

  return (
    <Container
      sx={{
        backgroundColor: 'grey1',
        p: 3,
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          height: 'calc(100vh - 118px)',
          overflow: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
          maxWidth='lg'
        >
          <Box>{sessionInfoRender()}</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '50px 0' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                    Name
                  </Typography>
                  {hasExecuteActivitiesPermission() && (
                    <IconButton disabled={isEditNotes} onClick={() => setIsEditName(true)} fontSize={'small'}>
                      <EditIcon fontSize={'small'} sx={isEditNotes ? { color: 'gray' } : { color: 'black' }} />
                    </IconButton>
                  )}
                </Box>
                {machineNameRender()}

                <Box sx={{ paddingTop: '20px' }}>
                  <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                    Test Type
                  </Typography>
                  <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                    {t('customer:patientSession.machineSetup')}
                  </Typography>
                </Box>
              </Box>
              <Box>{duplicateAndEditRender()}</Box>
            </Box>

            <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column', width: '50%' }}>
              {dataRender()}
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                  Notes
                </Typography>
                {hasExecuteActivitiesPermission() && (
                  <IconButton disabled={isEditName} onClick={() => setIsEditNotes(true)} fontSize={'small'}>
                    <EditIcon sx={isEditName ? { color: 'gray' } : { color: 'black' }} fontSize={'small'} />
                  </IconButton>
                )}
              </Box>
              <Box>
                {isEditNotes ? (
                  <Box>
                    <SummaryContainer>
                      <TextareaAutosize
                        onChange={(v) => {
                          setNote(v.target.value);
                        }}
                        className={'notes'}
                        value={notes}
                        minRows={5}
                        maxLength={3000}
                        style={{
                          width: '100%',
                          borderColor: '#DDDFE1',
                          borderRadius: 5,
                          fontFamily: 'Roboto, Montserrat, sans-serif',
                          fontSize: 16,
                        }}
                      />
                    </SummaryContainer>

                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onNoteCancelHandler} size={'small'} sx={{ mr: 2 }} variant='outlined'>
                          {t('common:cancel')}
                        </Button>

                        <LoadingButton loading={isUpdating} onClick={onSaveHandler} size={'small'} variant='contained'>
                          {t('common:save')}
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                    {notes || '_'}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          {footerRender()}
          <Modal
            showModal={editModal}
            isClose={false}
            keep={true}
            onClose={() => setEditModal(false)}
            content={() =>
              "A copy of this setup will be created for you to edit. The original setup won't be affected. Do you want to continue?"
            }
            extra={[
              <Button onClick={() => setEditModal(false)} variant='outlined' key={'btn-1'}>
                {t('common:cancel')}
              </Button>,
              <Button variant='contained' onClick={cloneHandler} key={'btn-2'}>
                {t('common:ok')}
              </Button>,
            ]}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default TorsoSetupSummary;
