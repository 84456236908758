import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Actions from 'modules/Patients/components/Machines/Actions';

const Instructions = ({ step, subStep, handleCancel, handleBack, handleNext, disableBack }) => {
  return (
    <>
      <Typography variant='body1' component='div'>
        1. Provide following instructions to the patient:
        <div style={{ padding: '0 20px' }}>
          • Tests will be conducted at pre-set test angles positions selected on the previous screen, which are within
          your pain-free ROM.
          <br />
          • Only exert force as requested by clinician.
          <br />• Gradually exert your max force during test to the cadence: push 25%, 50%, 75%, 100% and relax.
        </div>
        2. Snug seat belt.
        <br />
        3. Snug the toroso restraint.
        <br />
        4. Snug the head restraint.
        <br />
        5. Remind patient to maintain hands resting in their lap and to maintain contact with the seatback.
      </Typography>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handleBack={handleBack}
        disableBack={disableBack}
      />
    </>
  );
};

Instructions.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disableBack: PropTypes.bool,
};

export default Instructions;
