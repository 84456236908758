import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GaugeSlider from 'components/GaugeSlider';
import socketActions from 'components/WebSocketProvider/constants.js';
import goinometer from 'assets/images/knee/goinometer-ROM gauge 1.svg';
import { useEffect, useState } from 'react';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
import { clearMessageQueueHandler } from 'helpers';
import FlipSwitch from 'components/FlipSwitch';

const SetAngleSelector = ({
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  machine,
  onDataChange,
  setupMachineData,
  firstKnee,
  phase,
  phase1,
  phase2,
  socketContext,
}) => {
  const [currentAngle, setCurrentAngle] = useState(0);
  const [isLock, setLock] = useState(false);
  const [state, setState] = useState(undefined);
  const [rangeMotionMin, setRangeMotionMin] = useState(0);
  const [rangeMotionMax, setRangeMotionMax] = useState(0);

  useEffect(() => {
    return () => {
      socketContext.sendJsonMessage({
        request: socketActions.STOP_GET_VALUE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, []);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }
    if ((phase === 1 && firstKnee === 1) || (phase === 2 && firstKnee === 2)) {
      setRangeMotionMin(setupMachineData.left_range_motion_min ?? 0);
      setRangeMotionMax(setupMachineData.left_range_motion_max ?? 0);
    } else if ((phase === 1 && firstKnee === 2) || (phase === 2 && firstKnee === 1)) {
      setRangeMotionMin(setupMachineData.right_range_motion_min ?? 0);
      setRangeMotionMax(setupMachineData.right_range_motion_max ?? 0);
    }
  }, [setupMachineData, firstKnee, phase]);

  useEffect(() => {
    if (!phase) {
      return null;
    }
    if (phase === 1) {
      setLock(phase1.angle !== undefined);
      setCurrentAngle(phase1.angle ?? 0);
      setState(phase1.angle !== undefined ? undefined : 1);
      return null;
    }

    if (phase === 2) {
      setLock(phase2.angle !== undefined);
      setCurrentAngle(phase2.angle ?? 0);
      setState(phase2.angle !== undefined ? undefined : 1);
      return null;
    }
  }, [phase, phase1, phase2]);

  useEffect(() => {
    if (!state) {
      return null;
    }

    if (isLock) {
      return null;
    }
    if (!machine) {
      return null;
    }
    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
  }, [state]);

  useEffect(() => {
    if (!state) {
      return;
    }

    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message) {
      return;
    }
    if (message.degree !== undefined) {
      setCurrentAngle(Number(message.degree));
    }
    if (message.done) {
      setLock(true);
    }
  }, [socketContext.messageHistory]);

  const backHandler = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    handleBack();
  };

  const nextHandler = () => {
    onDataChange({
      angle: currentAngle,
    });

    handleNext();
  };

  if (!setupMachineData) {
    return null;
  }

  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);
    setTimeout(() => {
      setLock(false);
      setState(1);
      socketContext.sendJsonMessage({
        request: socketActions.GET_DEGREE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }, 2000);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', alignItems: 'start' }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={goinometer} />
          </Box>
          <Typography variant='body1' component='div'>
            1. Disengage the movement arm lock and palpitate 3-6 times through pain-free ROM. Slowly go through this
            passive ROM.
            <br />
            2. Tighten chain tightener (clockwise) until you fully reach the maximum flexion angle.
            <br />
            3. This very different from all other BioneX machines. Use the chain tightener to achieve the target angle
            shown below.
            <br />
            4. <FlipSwitch /> - to lock in target angle.
          </Typography>
        </Box>

        <Box>
          <div style={{ maxHeight: 270 }}>
            <GaugeSlider
              value={currentAngle}
              targetValue={Number(rangeMotionMax)}
              minValue={0}
              maxValue={120}
              isLock={isLock}
              bottomInfo={`Range of Motion: ${rangeMotionMin}°- ${rangeMotionMax}°`}
            />
          </div>
        </Box>
      </Box>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableNext={!isLock}
        handleUndo={redoHandler}
      />
    </Box>
  );
};

SetAngleSelector.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.array,
  subStep: PropTypes.string,
  onDataChange: PropTypes.func,
  defaultData: PropTypes.object,
  firstKnee: PropTypes.number,
  phase: PropTypes.number,
  phase1: PropTypes.object,
  phase2: PropTypes.object,
  socketContext: PropTypes.object,
};

export default SetAngleSelector;
