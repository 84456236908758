import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { SingleImageStep } from 'helpers';
import bubbleLevel from 'assets/images/SVG/Cervical/Bubble-level.svg';
import Actions from 'modules/Patients/components/Machines/Actions';
export function LevelAndLockCounterWeight({ step, subStep, handleCancel, handleBack, handleNext }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box>
          <SingleImageStep img={bubbleLevel} />
        </Box>

        <Typography sx={{ flex: 1 }} variant='body1' component='div'>
          1.Confirm bubble level is in the center and lock Counterweight lock.
        </Typography>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
}
LevelAndLockCounterWeight.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,

  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
