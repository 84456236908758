import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import bubbleLevel from 'assets/images/SVG/Cervical/Bubble-level.svg';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

const instructions = [
  {
    id: 1,
    text: '',
    img: bubbleLevel,
  },
];
const LevelAndLock = ({ step, subStep, handleCancel, handleBack, handleNext }) => {
  return (
    <>
      <Box sx={{ display: 'flex', gap: 5 }}>
        <Box>{getInstructions(instructions)}</Box>

        <Typography variant='body1' component='div'>
          1. Confirm bubble level is in the center and lock Counterweight lock.
          <br />
        </Typography>
      </Box>

      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </>
  );
};

LevelAndLock.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
};

export default LevelAndLock;
