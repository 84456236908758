import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getCustomers: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/`,
  },
  getFilteredCustomers: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/list`,
  },
  getStates: {
    url: `${ENV_VARIABLES.API_SERVER}location/countries/US/regions?limit=500`,
  },
  getCities: {
    url: `${ENV_VARIABLES.API_SERVER}location/cities`,
  },
  hcpInfo: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}`,
  },
  manageCustomer: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}`,
  },
  hcpRoles: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/roles?hide_owner_role=true`,
  },
  hcpUsers: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/users`,
  },
  updateUsers: {
    url: (hcpId, userId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/users/${userId}`,
  },
  archiveHcpUser: {
    url: (hcpId, userId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/users/${userId}/archive`,
  },
};
