import { ContentContainer } from 'modules/DataSync/Modal/styled';
import PropTypes from 'prop-types';
import ProcessBar from 'components/ProcessBar';
import Box from '@mui/material/Box';
import { Item } from 'modules/DataSync/Modal/Item';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { syncStatus } from 'modules/DataSync/slice';

export const SyncProgress = ({ onClose }) => {
  const dispatch = useDispatch();

  const syncTables = useSelector((state) => state.sync.syncTables);
  const requestKey = useSelector((state) => state.sync.requestKey);
  const [status, setStatus] = useState(null);
  const timeCounter = useRef(null);
  const [requestStatus, setRequestStatus] = useState({
    dynamic_data: true,
    isometric_data: true,
    patient_activities: true,
    patient_sessions: true,
    patient_setups: true,
    patients: true,
  });

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const hcpId = localStorage.getItem('hcpId');
    if (timeCounter.current === null && requestKey !== '') {
      timeCounter.current = setInterval(() => {
        dispatch(syncStatus({ requestKey, hcpId }));
      }, 5000);
    }

    return () => {
      clearInterval(timeCounter.current);
    };
  }, [requestKey]);

  useEffect(() => {
    if (!syncTables || (syncTables && syncTables.length === 0)) {
      return;
    }

    const _status = { ...requestStatus };

    if (syncTables.dynamic_data === 'done') {
      _status.dynamic_data = false;
    }
    if (syncTables.isometric_data === 'done') {
      _status.isometric_data = false;
    }
    if (syncTables.patient_activities === 'done') {
      _status.patient_activities = false;
    }
    if (syncTables.patient_sessions === 'done') {
      _status.patient_sessions = false;
    }
    if (syncTables.patient_setups === 'done') {
      _status.patient_setups = false;
    }
    if (syncTables.patients === 'done') {
      _status.patients = false;
    }

    let isDone = 0;

    const statusKeys = Object.keys(_status);

    for (let i = 0; i < statusKeys.length; i += 1) {
      if (_status[statusKeys[i]] === false) {
        isDone += 1;
      }
    }

    if (isDone === 6) {
      setStatus(1);
      clearInterval(timeCounter.current);
      timeCounter.current = null;
    }

    setPercent((isDone / statusKeys.length) * 100);
    setRequestStatus(() => ({ ..._status }));
  }, [syncTables]);

  const onDoneHandler = () => {
    window.location.reload();
    onClose(true);
  };

  const topSmgRender = () => {
    if (status === 1) {
      return <Box sx={{ fontSize: '18px', fontWeight: '500' }}>Successfully synced data!</Box>;
    }

    return (
      <Box sx={{ fontSize: '18px', fontWeight: '500' }}>
        Synchronizing data. Please do not close the browser or exit Bionex.
      </Box>
    );
  };

  const btnRender = () => {
    if (status === 1) {
      return (
        <Box className={'btn-ok'} onClick={onDoneHandler}>
          OK
        </Box>
      );
    }
  };

  return (
    <ContentContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
        <Box>
          {topSmgRender()}
          <br />
          <ProcessBar percent={percent} />
        </Box>

        <Box
          sx={{
            width: '100%',
            height: '170px',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'start',

            overflow: 'scroll',
          }}
        >
          <Item key={'item-6'} status={requestStatus.patients} smg={'Patients Information'} />
          <Item key={'item-5'} status={requestStatus.patient_sessions} smg={'Patient Sessions'} />
          <Item key={'item-4'} status={requestStatus.patient_setups} smg={'Patient Machine Setup'} />
          <Item key={'item-3'} status={requestStatus.patient_activities} smg={'Patient Activities'} />
          <Item key={'item-1'} status={requestStatus.dynamic_data} smg={'Dynamic Exercise'} />
          <Item key={'item-2'} status={requestStatus.isometric_data} smg={'Isometric Testing'} />
        </Box>
      </Box>
      <ContentContainer>{btnRender()}</ContentContainer>
    </ContentContainer>
  );
};

SyncProgress.propTypes = {
  smg: PropTypes.string,
  setPage: PropTypes.func,
  onClose: PropTypes.func,
  file: PropTypes.string,
};

export default SyncProgress;
