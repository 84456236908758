import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import step1 from 'assets/images/png/preparation/step1.png';
import angleSelector from 'assets/images/png/machine-setup/6b.png';
import { SingleImageStep, textFieldRenderMaxExtension } from 'helpers';
import GaugeSlider from 'components/GaugeSlider';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import { textFieldRender } from 'helpers';
import onTarget from 'assets/audio/bip.mp3';
import { clearMessageQueueHandler } from 'helpers';
import { useSocketDataContext } from 'components/WebSocketProvider/index.jsx';
import tick from 'assets/images/SVG/tick.svg';
import FlipSwitch from 'components/FlipSwitch';

const PageRender = ({ page, payload, step, subStep }) => {
  const audio = useRef(null);
  useEffect(() => {
    if (page !== 'COUNTER_WEIGHT_ADJUST') {
      return;
    }

    if (payload.value === 0 || payload.value === -1 || payload.value === 1) {
      audio.current.play();
    }
  }, [payload.value]);
  const maxExtensionRender = () => {
    return (
      <div key={page}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Typography variant='body1' component='div'>
            1. With patient sitting in the lumbar machine, slowly move the angle arm on the machine so the patient is
            freely moving back and forth without any pain or discomfort.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={step1} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              <div style={{ fontWeight: 'bold' }}>2. ROM - Find Max Extension:</div>
              Move angle selector to patient's furthest extended position available. (Should be pain free) (most of the time it’s 0°)
              <br />
              Passively move the patient. Palpate patient multiple times. Ask patient how they are feeling (okay, comfortable, pain- ful, etc)
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider value={payload.value} maxValue={72} isLock={payload.isLock} />
            </div>

            <Box>
              <Typography sx={{ fontSize: '1rem', pt: 2, fontWeight: 500, whiteSpace: 'nowrap' }} variant='h2'>
                {/*{t('machine:debugDialog.title')}*/}
                <span style={{ margin: 0 }}> Extension Angle:</span>
              </Typography>
              <Box sx={{ mt: 1 }}>{textFieldRender(payload.value, payload.isLock)}</Box>
              <FlipSwitch />
            </Box>
          </div>
        </OverlayScrollbarsComponent>
        <Actions
          step={step}
          subStep={subStep}
          handleBack={payload.handleBack}
          handleCancel={payload.handleCancel}
          handleNext={payload.handleNext}
          handleUndo={payload.redoHandler}
          disableNext={!payload.isLock}
        />
      </div>
    );
  };

  const maxFlexionRender = () => {
    return (
      <div key={page}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Typography variant='body1' component='div'>
            1. With patient sitting in the lumbar machine, slowly move the angle arm on the machine so the patient is
            freely moving back and forth without any pain or discomfort.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={step1} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              <div style={{ fontWeight: 'bold' }}>2. ROM - Find Max Flexion:</div>
              Move Angle selector to Max Flexion (most of the time it’s 72°)
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider value={payload.value} maxValue={72} isLock={payload.isLock} />
            </div>

            <Box>
              <Typography sx={{ fontSize: '1rem', pt: 2, fontWeight: 500, whiteSpace: 'nowrap' }} variant='h2'>
                {/*{t('machine:debugDialog.title')}*/}
                <span style={{ margin: 0 }}> Flexion Angle:</span>
              </Typography>
              <Box sx={{ mt: 1 }}>{textFieldRender(payload.value, payload.isLock)}</Box>
              <FlipSwitch />
            </Box>
          </div>
        </OverlayScrollbarsComponent>
        <Actions
          step={step}
          subStep={subStep}
          handleBack={payload.handleBack}
          handleCancel={payload.handleCancel}
          handleNext={payload.handleNext}
          handleUndo={payload.redoHandler}
          disableNext={!payload.isLock}
        />
      </div>
    );
  };

  const topDeadCenter = () => {
    return (
      <div key={page}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={step1} />
            </Box>

            <Typography variant='body1' component='div'>
              1. Disengage angle selector, ask patient move into a position in which they feel completely upright. As if
              they could balance a book on their head and the book would not forward or backwards.
              <br />
              2. Find the closest angle when patient is in this final position.
              <br />
              <br />
              <b style={{ margin: 0 }}> 3. Find Top Dead Center (TDC):</b> <br />
              Engage angle selector to the angle corresponding to this final position.
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider value={payload.value} maxValue={72} isLock={payload.isLock} />
            </div>

            <form>
              <Box>
                <b> TDC at Rest Angle:</b>
                <Box sx={{ mt: 1 }}>{textFieldRender(payload.value, payload.isLock)}</Box>
                <FlipSwitch />
              </Box>
            </form>
          </div>
        </OverlayScrollbarsComponent>
        <Actions
          step={step}
          subStep={subStep}
          handleBack={payload.handleBack}
          handleCancel={payload.handleCancel}
          handleNext={payload.handleNext}
          handleUndo={payload.redoHandler}
          disableNext={!payload.isLock}
        />
      </div>
    );
  };

  const counterBalance = () => {
    if (!payload) {
      return;
    }
    return (
      <div key={page}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          1. Ask patient to rest neck on head pad, and disengage the Angle Selector.
          <Box
            sx={{
              mt: 4,
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={angleSelector} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              <b> 2. Select Max Extension Angle</b>:
              <br />
              {`Engage the Angle Selector at the patient’s maximum extension angle. This maximum extension angle data was
              collected during Step 4: [ ${textFieldRenderMaxExtension(payload.value, payload.isLock)} ]`}
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider
                value={payload.value}
                targetValue={payload.targetValue}
                maxValue={72}
                isLock={payload.isLock}
              />
            </div>
            <Box>
              <b>Machine Angle Reading</b>
              <Box sx={{ mt: 1 }}>{textFieldRender(payload.value, payload.isLock)}</Box>
              <FlipSwitch />
            </Box>
          </div>
        </OverlayScrollbarsComponent>
        <Actions
          step={step}
          subStep={subStep}
          handleBack={payload.handleBack}
          handleCancel={payload.handleCancel}
          handleNext={payload.handleNext}
          handleUndo={payload.redoHandler}
          disableNext={!payload.isLock}
        />
      </div>
    );
  };

  const counterweightAdjust = () => {
    return (
      <div key={page}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box>
                <SingleImageStep img={angleSelector} />
              </Box>

              <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <Typography variant='body1' component='div'>
                  <b> 1. Set Counterbalance</b>: Zero the mass by adjusting the Counterweight Adjuster.
                  <div style={{ padding: '0 20px' }}>
                    • Adjust the position of the counterweight until a zero torque is displayed.
                    <br />
                    • If the torque reading is negative, raise the counter weight by turning the counterweight adjuster
                    clockwise.
                    <br />• If the torque reading is positive, lower the counter wave by turning the counterweight
                    adjuster counterclockwise.
                    <br />• The target value is 0. However, -1 or 1 is an acceptable value.
                  </div>
                </Typography>
                <br />
                <label>
                  <strong>2. Adjust the position of the counter weight to “0”</strong>
                </label>
                <audio
                  style={{ visibility: 'hidden', height: '1px' }}
                  className='my_audio'
                  ref={audio}
                  controls
                  preload='none'
                >
                  <source src={onTarget} type='audio/mpeg' />
                </audio>
                <Box style={{ paddingTop: '20px' }}>
                  <div
                    style={{
                      width: '200px',
                      padding: '17px 14px',
                      border: '2px solid lightgrey',
                      borderRadius: '5px',
                      fontSize: '25px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontWeight: 700,
                      borderColor: payload.value >= -1 && payload.value <= 1 ? 'green' : 'lightgrey',
                      color: payload.value >= -1 && payload.value <= 1 ? 'green' : 'black',
                      backgroundColor: payload.value >= -1 && payload.value <= 1 ? '#EBFBEE' : '#E9ECEF',
                    }}
                  >
                    <div>{payload.value !== null ? payload.value : '---'}</div>
                    {payload.value >= -1 && payload.value <= 1 ? (
                      <div>
                        <Box
                          component='img'
                          sx={{
                            cursor: 'pointer',
                            height: 'auto',
                            display: { xs: 'none', md: 'block' },
                            width: '25px',
                          }}
                          src={tick}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Box>
                <FlipSwitch />
                <br />
                <Box>
                  <b>3. Release Patient</b>:
                  <br />
                  <div style={{ padding: '0 20px' }}>
                    • Move the patient to a comfortable upright position, approximately 12°. <br /> • Quickly loosen the
                    belt and foot adjuster.
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </OverlayScrollbarsComponent>
        <Actions
          step={step}
          subStep={subStep}
          handleBack={payload.handleBack}
          handleCancel={payload.handleCancel}
          handleNext={payload.handleNext}
          handleUndo={payload.redoHandler}
          disableNext={!payload.isLock}
        />
      </div>
    );
  };

  switch (page) {
    case 'MAX_EXTENSION':
      return maxExtensionRender();
    case 'MAX_FLEXION':
      return maxFlexionRender();
    case 'TOP_DEAD_CENTER':
      return topDeadCenter();
    case 'COUNTER_BALANCE':
      return counterBalance();
    case 'COUNTER_WEIGHT_ADJUST':
      return counterweightAdjust();
  }
};
PageRender.prototype = {
  page: PropTypes.string,
  payload: PropTypes.object,
};

const getCurrentAngle = (page, payload) => {
  if (!payload) {
    return null;
  }

  switch (page) {
    case 'MAX_EXTENSION':
      return payload.range_motion_min;
    case 'MAX_FLEXION':
      return payload.range_motion_max;
    case 'TOP_DEAD_CENTER':
      return payload.dead_center;
    case 'COUNTER_BALANCE':
      return payload.counterbalance_angle;
    case 'COUNTER_WEIGHT_ADJUST':
      return payload.counterbalance_torque;
  }
};

export function SetAngleRender({ step, machine, handleCancel, handleBack, handleNext, page, onDataChange, subStep }) {
  const isRequesting = useRef('');
  const socketContext = useSocketDataContext();
  const setupMachineData = useSelector((state) => state.patients.setup);
  const [value, setValue] = useState(0);
  const [isLock, setLock] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const range = [6, 24];

  const handleStopData = () => {
    isRequesting.current = '';
    setIsReady(false);
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
  };

  useEffect(() => {
    return () => {
      handleStopData();
    };
  }, [step, subStep]);

  useEffect(() => {
    if (!setupMachineData || (setupMachineData && !setupMachineData.data)) {
      return;
    }
    const currAngle = getCurrentAngle(page, setupMachineData.data);
    if (currAngle !== null) {
      setValue(currAngle);
      setLock(true);
      return;
    }
    setValue(0);
    getData();
  }, [setupMachineData, page, step, subStep]);

  useEffect(() => {
    if (isRequesting.current !== page) {
      return;
    }
    if (!isReady) {
      return;
    }

    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }
    if (page === 'COUNTER_WEIGHT_ADJUST') {
      if (message.pound !== undefined) {
        setValue(message.pound);
      }
    } else {
      if (message.degree !== undefined) {
        setValue(message.degree);
      }
    }
    if (message.done) {
      setLock(true);
      isRequesting.current = '';
    }
  }, [socketContext.messageHistory]);

  const getData = () => {
    if (isRequesting.current) {
      return;
    }

    setIsReady(true);
    setLock(false);
    if (page === 'COUNTER_WEIGHT_ADJUST') {
      socketContext.sendJsonMessage({
        request: socketActions.COUNTER_BALANCE,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
      setValue(null);
    } else {
      socketContext.sendJsonMessage({
        request: socketActions.GET_DEGREE_BY_SWITCH,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }
    isRequesting.current = page;
  };

  const nextHandler = () => {
    const currentAngle = getCurrentAngle(page, setupMachineData.data);
    if (currentAngle === value) {
      handleNext();
      return;
    }
    let data;
    switch (page) {
      case 'MAX_EXTENSION':
        data = {
          range_motion_min: value,
          range_motion_max: null,
          dead_center: null,
          counterbalance_angle: null,
          counterbalance_torque: null,
          counterbalance_gauge: null,
        };
        break;
      case 'MAX_FLEXION':
        data = {
          range_motion_max: value,
          dead_center: null,
          counterbalance_angle: null,
          counterbalance_torque: null,
          counterbalance_gauge: null,
        };
        break;
      case 'TOP_DEAD_CENTER':
        data = {
          dead_center: value,
          counterbalance_angle: null,
          counterbalance_torque: null,
          counterbalance_gauge: null,
        };
        break;
      case 'COUNTER_BALANCE':
        data = {
          counterbalance_angle: value,
          counterbalance_torque: null,
          counterbalance_gauge: null,
        };
        break;
      case 'COUNTER_WEIGHT_ADJUST':
        data = {
          counterbalance_torque: value,
          counterbalance_gauge: null,
        };
        break;
    }
    onDataChange(data);
    handleNext();
  };

  const backHandler = () => {
    handleStopData();
    handleBack();
  };

  const redoHandler = () => {
    clearMessageQueueHandler(socketContext, machine);

    setTimeout(() => {
      isRequesting.current = '';
      getData();
    }, 2000);
  };

  return (
    <div key={page}>
      <PageRender
        payload={{
          handleCancel,
          handleBack: backHandler,
          handleNext: nextHandler,
          getData,
          redoHandler,
          value,
          isLock,
          range,
          targetValue: setupMachineData?.data.range_motion_min,
        }}
        subStep={subStep}
        page={page}
        step={step}
      />
    </div>
  );
}
SetAngleRender.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  page: PropTypes.string,
  values: PropTypes.object,
  onDataChange: PropTypes.func,
  subStep: PropTypes.string,
};
