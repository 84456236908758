import { Modal as MuiModal } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PropTypes from 'prop-types';
import { modalBaseStyle } from 'modules/EMR/containers/styled';
import Container from '../containers/styled.js';
import { SingleSelect } from 'modules/DemoGraphic/Shared/SingleSelect';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { SYSTEM_OPTIONS } from 'modules/EMR/constants.js';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { putAllActivity, putSingleActivity } from 'modules/EMR/slice.js';
const PushEMRModal = ({ open, onClose, activityId, onDone, sessionId, activeNet }) => {
  const dispatch = useDispatch();

  const { isPushing } = useSelector((state) => state.emrSettings);

  const hcpId = localStorage.getItem('hcpId');
  const [data, setData] = useState({
    system: 'CASPIO',
  });

  const onChange = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const onPushingHandler = () => {
    if (activityId) {
      if (activeNet) {
        dispatch(
          putSingleActivity({
            hcpId,
            activityId,
            emrName: 'Caspio',
            isometric_type: 'net',
            callback: (success) => {
              if (!success) {
                return;
              }
              onDone();
            },
          })
        );
        return
      } else {
        dispatch(
          putSingleActivity({
            hcpId,
            activityId,
            emrName: 'Caspio',
            isometric_type: '',
            callback: (success) => {
              if (!success) {
                return;
              }
              onDone();
            },
          })
        );
        return;
      }
    }
    dispatch(
      putAllActivity({
        hcpId,
        sessionId,
        emrName: 'Caspio',
        callback: (success) => {
          if (!success) {
            return;
          }
          onDone();
        },
      })
    );
  };

  return (
    <MuiModal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={{ ...modalBaseStyle }}>
        <Box>
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 0',
            }}
          >
            <Box sx={{ width: '100%' }}>Push data into integrated EMR System</Box>
            <Box></Box>
          </Box>

          <Box>
            <div id='child-modal-description'>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Container>
                  <Box className={'wrapper'}>
                    <Box className={'row'}>
                      <span className={'label'}>Select system to push</span>
                      <SingleSelect
                        value={'CASPIO'}
                        onChange={onChange}
                        width={'100%'}
                        field={'system'}
                        options={SYSTEM_OPTIONS}
                      />
                    </Box>
                    Patient data and this activity data will be push into the selected EMR System
                    <Box className={'group-btn'}>
                      <Button onClick={onClose}>Cancel</Button>
                      <LoadingButton loading={isPushing} onClick={() => onPushingHandler()} variant={'contained'}>
                        Start pushing
                      </LoadingButton>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
};

PushEMRModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activityId: PropTypes.string,
  sessionId: PropTypes.string,
  onDone: PropTypes.func,
  activeNet: PropTypes.bool
};

export default PushEMRModal;
