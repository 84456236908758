import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './config/process.js';
import HttpsRedirect from 'components/HttpRedirect';

ReactDOM.render(
  <React.Fragment>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </React.Fragment>,
  document.getElementById('root')
);
