import BaseModal from 'modules/DemoGraphic/Shared/Modal.jsx';
import { closeDialog } from 'helpers/dialog.js';
import { DIALOGS } from 'modules/Dialogs/constants.js';
import PropTypes from 'prop-types';
import HealthInformation from 'modules/DemoGraphic/HealthInformation/index.jsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MilitaryInformation from 'modules/DemoGraphic/MilitaryInformation/index.jsx';
import CivilianInformation from 'modules/DemoGraphic/CivilianInformation/index.jsx';
import AthleteInformation from 'modules/DemoGraphic/AthleteInformation/index.jsx';
const defaultHealthInformationData = {
  height: '',
  weight: '',
  currentlyInjured: '',

  typeInjured: [],
  areaInjured: [],
  isInjuryWorkRelated: '',
  isInjuryAcute: '',
  isConditionComplex: '',
  isSymptomatic: '',
  hasPreviousInjured: '',

  hasSurgery: '',

  surgeryType: '',
  surgeryDate: '',

  activityLevel: '',
  hourForSleep: '',
  sensitivities: [],
  comorbidityOptions: [],

  // note
  typeInjuredNote: '',
  areaInjuredNote: '',
  sensitivitiesNote: '',
  comorbidityOptionsNote: '',
};
const defaultMilitaryData = {
  branchOfService: '',
  enteredDate: '',
  rank: '',
  role: '',
  yearInRole: '',
  typeOfAirFrame: '',
  hourFly: '',
  totalHourFly: '',
  totalFee: '',
};

const defaultCivilianData = {
  employmentStatus: '',
  occupation: '',
  enteredDate: '',
  jobFunc: '',
  FirefighterTitle: '',
  yearInPosition: '',
};

const defaultAthleteData = {
  practiceSport: '',
  position: '',
  level: '',
  practiceYear: '',
};
export default function DemoGraphicModal({
  date,
  specialData,
  setSpecialData,
  setHealthData,
  healthData,
  onFinish,
  isEdit,
  patientType,
  defaultStep,
  defaultHealthData,
  defaultSpecialData,
  isDisabledReuse,
  reuseData,
  showReuse,
}) {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('Health Information - 1/2');
  const [step, setStep] = useState(1);
  const [healthInformation, setHealthInformation] = useState(defaultHealthInformationData);
  const [militaryInformation, setMilitaryInformation] = useState(defaultMilitaryData);
  const [civilianInformation, setCivilianInformation] = useState(defaultCivilianData);
  const [athleteInformation, setAthleteInformation] = useState(defaultAthleteData);
  const [loading, setLoading] = useState(false);
  const [reuseLoading, setReuseLoading] = useState(false);

  const open = useSelector((state) => state.dialogs[DIALOGS.OPEN_DEMO_GRAPHIC]);

  const patient = useSelector((state) => state.patients.patient);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!isEdit) {
      setStep(1);
      setTitle('Health Information - 1/2');
      setHealthInformation(defaultHealthInformationData);
      setMilitaryInformation(defaultMilitaryData);
      setCivilianInformation(defaultCivilianData);
      setAthleteInformation(defaultAthleteData);
      setLoading(false);
      return;
    }

    setHealthInformation({ ...defaultHealthInformationData, ...defaultHealthData });

    switch (patientType) {
      case 'MILITARY':
        setMilitaryInformation({ ...defaultMilitaryData, ...defaultSpecialData });
        setTitle('Military Information - 2/2');
        break;
      case 'CIVILIAN':
        setCivilianInformation({ ...defaultCivilianData, ...defaultSpecialData });
        setTitle('Civilian Information - 2/2');
        break;
      default:
        setAthleteInformation({ ...defaultAthleteData, ...defaultSpecialData });
        setTitle('Athlete Information - 2/2');
        break;
    }
    if (defaultStep === 1) {
      setTitle('Health Information - 1/2');
    }

    setStep(defaultStep);
    setLoading(false);
  }, [open, isEdit, patientType, defaultStep, defaultHealthData, defaultSpecialData]);

  useEffect(() => {
    if (!patient) {
      return;
    }

    if (healthData) {
      setHealthInformation(healthData);
    }

    if (specialData && step === 2) {
      switch (patientType ?? patient.type) {
        case 'MILITARY':
          setMilitaryInformation(specialData);
          break;
        case 'CIVILIAN':
          setCivilianInformation(specialData);
          break;
        default:
          setAthleteInformation(specialData);
          break;
      }
    }
  }, [healthData, setSpecialData, patient, step, defaultStep]);
  const onContinueHandler = () => {
    if (step === 1) {
      switch (patientType ?? patient.type) {
        case 'MILITARY':
          setTitle('Military Information - 2/2');
          break;
        case 'CIVILIAN':
          setTitle('Civilian Information - 2/2');
          break;
        default:
          setTitle('Athlete Information - 2/2');
          break;
      }

      setHealthData(healthInformation);
      setStep(2);
      return;
    }

    let _specialData;
    switch (patientType ?? patient.type) {
      case 'MILITARY':
        setSpecialData(militaryInformation);
        _specialData = militaryInformation;
        break;
      case 'CIVILIAN':
        setSpecialData(civilianInformation);
        _specialData = civilianInformation;
        break;
      default:
        setSpecialData(athleteInformation);
        _specialData = athleteInformation;
        break;
    }

    setLoading(true);
    onFinish({
      type: patientType ?? patient.type,
      healthInformation,
      specialInformation: _specialData,
      callback: (success) => {
        if (success) {
          closeDialog(dispatch, DIALOGS.OPEN_DEMO_GRAPHIC);
        }
        setLoading(false);
      },
    });
  };
  const onPreviousHandler = () => {
    switch (patientType ?? patient.type) {
      case 'MILITARY':
        setSpecialData(militaryInformation);
        break;
      case 'CIVILIAN':
        if (defaultStep) {
          setSpecialData(civilianInformation);
        } else {
          setSpecialData(civilianInformation);
        }

        break;
      default:
        setSpecialData(athleteInformation);
        break;
    }

    setTitle('Health Information - 1/2');
    setStep(1);
  };

  const onReuseHandler = () => {
    setReuseLoading(true);
    const healthReuseData = JSON.parse(JSON.stringify(reuseData.demographic.health_information));
    const specialReuseData = JSON.parse(JSON.stringify(reuseData.demographic.special_information));

    setHealthData({ ...defaultHealthInformationData, ...healthReuseData });
    switch (reuseData.patient_type) {
      case 'MILITARY':
        setSpecialData({ ...defaultMilitaryData, ...specialReuseData });
        break;
      case 'CIVILIAN':
        setSpecialData({ ...defaultCivilianData, ...specialReuseData });
        break;
      default:
        setSpecialData({ ...defaultAthleteData, ...specialReuseData });
        break;
    }

    setTimeout(() => {
      setReuseLoading(false);
    }, 500);
  };

  const contentRender = () => {
    if (step === 1) {
      return <HealthInformation data={healthInformation} setData={setHealthInformation} reuseLoading={reuseLoading} />;
    }

    switch (patientType ?? patient.type) {
      case 'MILITARY':
        return <MilitaryInformation data={militaryInformation} setData={setMilitaryInformation} />;
      case 'CIVILIAN':
        return <CivilianInformation data={civilianInformation} setData={setCivilianInformation} />;
      default:
        return <AthleteInformation data={athleteInformation} setData={setAthleteInformation} />;
    }
  };

  return (
    <BaseModal
      showModal={open}
      title={title}
      key={'DEMO_GRAPHIC'}
      content={contentRender}
      onClose={() => {
        closeDialog(dispatch, DIALOGS.OPEN_DEMO_GRAPHIC);
      }}
      isPrevious={step === 2}
      submitText={step === 1 ? 'Next' : 'Finish'}
      date={date}
      onSubmit={onContinueHandler}
      onPrevious={onPreviousHandler}
      isLoading={loading}
      isDisabledReuse={isDisabledReuse}
      onReuseData={onReuseHandler}
      showReuse={showReuse}
    />
  );
}

DemoGraphicModal.propTypes = {
  date: PropTypes.string,
  healthData: PropTypes.object,
  setHealthData: PropTypes.func,
  specialData: PropTypes.object,
  setSpecialData: PropTypes.func,
  onFinish: PropTypes.func,
  defaultHealthData: PropTypes.object,
  defaultSpecialData: PropTypes.object,
  isDisabledReuse: PropTypes.bool,
  reuseData: PropTypes.object,
  showReuse: PropTypes.bool,
};
