import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';

export default function SeatingAndFootboard({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData }) {
  const [seatPad, setSeatPad] = useState(-1);
  const [footboardPosition, setFootboardPosition] = useState(-1);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setSeatPad(setupMachineData.seat_pad ?? -1);
    setFootboardPosition(setupMachineData.footboard_pos ?? -1);
  }, [setupMachineData]);

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <br />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              1. Instruct patient to sit upright in the seat and scoot back as far as possible. Close gate (but don’t
              lock it).
              <br />
              2. Add seat pad if needed according to information below:
              <br />
              <Box>
                <div>
                  <b>Patient setup seat pad:</b>
                </div>

                <Select
                  disabled={true}
                  style={{ minWidth: '100px' }}
                  value={seatPad}
                  onChange={(v) => setSeatPad(Number(v.target.value))}
                >
                  <MenuItem value={-1}>
                    <em>--</em>
                  </MenuItem>

                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              3. Set footboard position, in according to information below: ◦ Position 1 is the closest slot to patient.
              <br />
              <Box>
                <div>
                  <b>Patient setup Footboard Position:</b>
                </div>

                <Select
                  disabled={true}
                  style={{ minWidth: '100px' }}
                  value={footboardPosition}
                  onChange={(v) => setFootboardPosition(Number(v.target.value))}
                >
                  <MenuItem value={-1}>
                    <em>--</em>
                  </MenuItem>

                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>4. Lower the femur restraint on to patient’s quads and lock with the femur restraint lock.</Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

SeatingAndFootboard.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
