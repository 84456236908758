import PropTypes from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import step3 from 'assets/images/png/preparation/step3.png';
import Button from '@mui/material/Button';
import ScrollDialog from 'components/ScrollDialog/index.jsx';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';

export default function ActualPreSetAngles({
  defaultAngles,
  step,
  subStep,
  handleCancel,
  handleBack,
  handleNext,
  handleRemoveAngle,
  handleUndoAngle,
  setRemovedAngles,
  removedAngles,
  setIsModalSaved,
  isModalSaved,
  disableActualPreSetAngle,
  setDisableActualPreSetAngle,
}) {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = () => {
    if (removedAngles.length > 0) {
      handleRemoveAngle(removedAngles);
      setIsModalSaved(true);
    }
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (!isModalSaved) {
      setTimeout(() => {
        setRemovedAngles([]);
      }, 500);
    }
  };

  const nextHandler = () => {
    setDisableActualPreSetAngle(true);
    handleNext();
  };

  const backHandler = () => {
    handleBack();
  };
  const undoHandler = () => {
    //reset items in modal
    setRemovedAngles([]);

    //reset saved state
    setIsModalSaved(false);

    handleUndoAngle();
  };

  const renderAngles = (angle) => {
    return (
      <Box key={angle.name} sx={{ minWidth: '100px', mb: 2, mr: 5, color: angle.skipped ? '#ccc' : '' }}>
        <Typography sx={{ fontWeight: 400 }} variant='h6'>
          {angle.shortName}
        </Typography>
        <Typography sx={{ fontWeight: 400 }} variant='h6'>
          {angle.value}°
        </Typography>
      </Box>
    );
  };

  const onRemoveAngle = (angle) => {
    const angles = removedAngles.filter((r) => r.id !== angle.id);
    angles.push(angle);
    setRemovedAngles(angles);
  };

  const renderDialogContent = () => {
    const angles = [];

    defaultAngles.forEach((d) => {
      const found = removedAngles.find((r) => r.id === d.id);
      if (!found) {
        angles.push(d);
      }
    });

    return angles.map((angle) => (
      <Box key={angle.shortName}>
        <Box sx={{ display: 'flex', p: 2 }}>
          <Typography sx={{ fontWeight: 400, width: '200px' }} variant='h6'>
            {angle.shortName}
          </Typography>
          <Box sx={{ display: 'flex', width: '100px', justifyContent: 'flex-end' }}>
            <Typography sx={{ fontWeight: 400, mr: 2 }} variant='h6'>
              {angle.value}
              <span>&#176;</span>
            </Typography>
            <IconButton
              disabled={angles.length <= 1}
              onClick={() => onRemoveAngle(angle)}
              sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
              size='small'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider orientation='horizontal' />
      </Box>
    ));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step3} />{' '}
          </Box>

          <Typography variant='p'>
            1. Loosen chain tightener.
            <br />
            2. Machine will automatically select your pre-set test angles, within the patient’s pain-free ROM.
            <br />
            3.You can manually delete any test angle outside of the pain-free ROM.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step3} />{' '}
          </Box>

          <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
            <Typography variant='h6'>Pre-Set Angles</Typography>
            <Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{defaultAngles.map((a) => renderAngles(a))}</Box>
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <Button disabled={disableActualPreSetAngle} onClick={() => setOpenModal(true)} variant='outlined'>
                  EDIT
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: '50px' }}>
        <ScrollDialog
          hasCloseIcon={false}
          message={renderDialogContent()}
          handleCloseModal={handleCloseModal}
          openModal={openModal}
          secondaryActionText='Cancel'
          primaryActionText='Save'
          handleSubmitModal={handleSubmitModal}
          handleSubmitSecondaryAction={handleCloseModal}
        />
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleBack={backHandler}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        handleUndo={undoHandler}
        disableRedo={disableActualPreSetAngle}
      />
    </Box>
  );
}

ActualPreSetAngles.propTypes = {
  defaultAngles: PropTypes.array,
  removedAngles: PropTypes.array,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
  handleRemoveAngle: PropTypes.func,
  handleUndoAngle: PropTypes.func,
  setRemovedAngles: PropTypes.func,
  setIsModalSaved: PropTypes.func,
  isModalSaved: PropTypes.bool,
  disableActualPreSetAngle: PropTypes.bool,
  setDisableActualPreSetAngle: PropTypes.func,
};
