import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import lumbar from 'assets/images/png/lumbar.png';
import cervical from 'assets/images/png/Cervical.png';
import knee from 'assets/images/png/knee/font-view.png';
import cervicalRotation from 'assets/images/Cervical_Rotation/Cervical Rotation Front View (2).svg';
import torsoRotation from 'assets/images/SVG/Torso/torso_rotation.svg';

import { SelectMachineDialog } from 'modules/Patients/components/Dialogs';
import { PatientInfo } from 'modules/Patients/components/Info';
import { useSelector } from 'react-redux';

const machineTypes = [
  {
    id: 1,
    name: 'Lumbar',
    label: 'Lumbar',
    img: lumbar,
    disabled: false,
  },
  {
    id: 2,
    name: 'Cervical',
    label: 'Cervical',
    img: cervical,
    disabled: false,
  },
  {
    id: 3,
    name: 'Knee',
    label: 'Knee',
    img: knee,
    disabled: true,
  },
  {
    id: 4,
    name: 'Torso Rotation',
    img: torsoRotation,
    disabled: true,
  },
  {
    id: 5,
    name: 'Cervical Rotation',
    img: cervicalRotation,
    disabled: true,
  },
];

const getMachineSelections = (types, action) => {
  return types.map((type) => {
    return (
      <Box
        onClick={() => {
          if (!type.disabled) {
            action(type);
          }
        }}
        key={type.id}
        sx={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            width: '170px',
            height: '170px',
            border: '1px solid #D4D4D4',
            background: type.disabled ? '#D4D4D4' : '#fff',
            borderRadius: '6px',
            p: 1,
            mb: 1,
            '&:hover': {
              border: '1px solid #00A8FF',
            },
          }}
        >
          {type.img && <img src={type.img} width={'100%'} height={'100%'} alt={type.name} loading='lazy' />}
        </Box>
        <Typography sx={{ color: type.disabled ? '#D4D4D4' : '' }} variant='body1' component='div'>
          {type.name}
        </Typography>
      </Box>
    );
  });
};

export default function MachineSelectView({ patient, getConfiguredMachine, machines, sessionId, enabledMachines }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSelectMachineDialog, setOpenSelectMachineDialog] = useState(false);
  const isFetchHcpConfigureMachine = useSelector((state) => state.machines.isFetchHcpConfigureMachine);
  const [machineUse, setMachineUse] = useState(machineTypes);
  const handleCloseSelectMachineDialog = () => {
    setOpenSelectMachineDialog(false);
  };

  const handleOpenSelectMachineDialog = (type) => {
    if (getConfiguredMachine) {
      getConfiguredMachine(type);
    }
    setOpenSelectMachineDialog(true);

    // save the selected machine type into localstorage
    localStorage.setItem('selectedMachineTypeId', type.id);
  };
  useEffect(() => {
    if (enabledMachines) {
      const newMachineType = [...machineTypes];
      newMachineType.map(m => {
        if (m.name in enabledMachines) {
          m.disabled = !enabledMachines[m.name]
        }
        return m;
      })
      setMachineUse(newMachineType);
    }
  }, [enabledMachines]);

  const handleCancel = () => {
    navigate(`/patient/${btoa(patient.patient_id)}/patient-detail?sessionId=${sessionId}&tab=2`);
  };

  useEffect(() => {
    const machineId = localStorage.getItem('selectedMachineId');
    const typeId = localStorage.getItem('selectedMachineTypeId');

    // reopen the machine select dialog on page refresh or on back from another page
    if (typeId && machineId) {
      const type = machineUse.find((m) => m.id === parseInt(typeId, 10));
      console.warn('machineTypes', machineUse, typeId);

      handleOpenSelectMachineDialog(type);
    }
  }, []);

  return (
    <Box>
      <SelectMachineDialog
        sessionId={sessionId}
        machines={machines}
        patient={patient}
        openDialog={openSelectMachineDialog && !isFetchHcpConfigureMachine}
        handleCloseDialog={handleCloseSelectMachineDialog}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ wordBreak: 'break-all' }} variant='h4' component='div'>
          {t('customer:patientSession.selectMachine')}
        </Typography>
        <PatientInfo patient={patient} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#F2F2F2',
          border: '1px solid #D4D4D4',
          borderRadius: '6px',
          p: 2,
          mt: 4,
        }}
      >
        {getMachineSelections(machineUse, handleOpenSelectMachineDialog)}
      </Box>
      {/*<GaugeSlider range={[6, 24]} value={26} maxValue={72} isLock={true} />*/}
      {/* actions */}
      <Box sx={{ display: 'flex', mt: 6, justifyContent: 'space-between' }}>
        <div></div>
        <Button onClick={handleCancel} variant='outlined'>
          {t('common:cancel')}
        </Button>
      </Box>
    </Box>
  );
}

MachineSelectView.propTypes = {
  patient: PropTypes.object,
  getConfiguredMachine: PropTypes.func,
  machines: PropTypes.array,
  enabledMachines: PropTypes.array,
  sessionId: PropTypes.string || PropTypes.number,
};
