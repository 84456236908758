import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import step1 from 'assets/images/png/preparation/step1.png';
import step3 from 'assets/images/png/preparation/step3.png';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
export default function PreSetAngles({
  setupMachineData,
  step,
  handleCancel,
  handleBack,
  handleNext,
  onDataChange,
  value,
  subStep,
  disablePracticePreSetAngle,
  setDisablePracticePreSetAngle,
}) {
  const [preValue, setPreValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const arrStandardNumber = [0, 12, 24, 36, 48, 60, 72];

  const defaultValues = {
    s1Angle: 0,
    s2Angle: 0,
    s3Angle: 0,
  };

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  const [s1Angle, s2Angle, s3Angle] = watch(['s1Angle', 's2Angle', 's3Angle']);

  useEffect(() => {
    return () => {
      reset({
        s1Angle: 0,
        s2Angle: 0,
        s3Angle: 0,
      });
    };
  }, []);

  useEffect(() => {
    if (
      Number(s1Angle) <= Number(setupMachineData.range_motion_max) &&
      Number(s1Angle) > Number(s2Angle) &&
      Number(s1Angle) > Number(s3Angle) &&
      Number(s1Angle) % 3 === 0
    ) {
      clearErrors('s1Angle');
    }

    if (Number(s2Angle) < Number(s1Angle) && Number(s2Angle) > Number(s3Angle) && Number(s2Angle) % 3 === 0) {
      clearErrors('s2Angle');
    }

    if (
      Number(s3Angle) >= Number(setupMachineData.range_motion_min) &&
      Number(s3Angle) < Number(s1Angle) &&
      Number(s3Angle) < Number(s2Angle) &&
      Number(s3Angle) % 3 === 0
    ) {
      clearErrors('s3Angle');
    }
  }, [s1Angle, s2Angle, s3Angle]);

  useEffect(() => {
    if (
      !setupMachineData ||
      (setupMachineData && setupMachineData.range_motion_min === null) ||
      setupMachineData.range_motion_max === null
    ) {
      return;
    }
    if (!value) {
      return;
    }
    if (value[step] && value[step].value.length > 0) {
      reset({
        s1Angle: value[step].value[0].name,
        s2Angle: value[step].value[1].name,
        s3Angle: value[step].value[2].name,
      });

      setPreValue({
        s1Angle: value[step].value[0].name,
        s2Angle: value[step].value[1].name,
        s3Angle: value[step].value[2].name,
      });
      return;
    }
    const randomRange = [2, 3, 4, 5];

    // let s1 = Math.abs(setupMachineData.range_motion_max - randomRange[Math.floor(Math.random() * 4)]);
    // while (arrStandardNumber.includes(s1)) {
    //   s1 = Math.abs(setupMachineData.range_motion_max - randomRange[Math.floor(Math.random() * 4)]);
    // }

    // let s3 = Math.abs(randomRange[Math.floor(Math.random() * 4)] + setupMachineData.range_motion_min);

    // while (arrStandardNumber.includes(s3)) {
    //   s3 = Math.abs(randomRange[Math.floor(Math.random() * 4)] + setupMachineData.range_motion_min);
    // }

    let s1 = Math.abs(setupMachineData.range_motion_max - randomRange[Math.floor(Math.random() * 4)]);
    while (arrStandardNumber.includes(s1)) {
      s1 = Math.abs(setupMachineData.range_motion_max - randomRange[Math.floor(Math.random() * 4)]);
    }

    let s3 = Math.abs(randomRange[Math.floor(Math.random() * 4)] + setupMachineData.range_motion_min);

    while (arrStandardNumber.includes(s3)) {
      s3 = Math.abs(randomRange[Math.floor(Math.random() * 4)] + setupMachineData.range_motion_min);
    }

    while (s1 % 3 !== 0) {
      s1 -= 1;
    };

    while (arrStandardNumber.includes(s1)) {
      s1 -= 1;
    };

    while (s3 % 3 !== 0) {
      s3 += 1;
    };

    while (arrStandardNumber.includes(s3)) {
      s3 += 1;
    };

    let avg = Math.round(Math.abs(s1 + s3) / 2);

    while (arrStandardNumber.includes(avg)) {
      avg -= 1;
    };

    while (avg % 3 !== 0) {
      avg -= 1
    };

    reset({ s1Angle: s1, s2Angle: avg, s3Angle: s3 });

    onDataChange({
      step: 4,
      subStep: 1,
      value: {
        s1Angle: s1,
        s2Angle: avg,
        s3Angle: s3,
      },
    });

    setPreValue({
      s1Angle: s1,
      s2Angle: avg,
      s3Angle: s3,
    });
  }, [setupMachineData, value]);

  const onSaveHandler = (value) => {
    if (Object.keys(errors).length !== 0) {
      return;
    }
    const prettierValue = { ...value };
    Object.keys(prettierValue).map((key) => {
      prettierValue[key] = Math.round(Number(prettierValue[key]));
    });
    setPreValue(prettierValue);
    setIsEdit(false);
    onDataChange({
      step: 4,
      subStep: 1,
      value: prettierValue,
    });
  };
  const nextHandler = () => {
    setDisablePracticePreSetAngle(true);
    handleNext();
  };
  const backHandler = () => {
    handleBack();
  };

  const onCancelHandler = () => {
    reset({
      s1Angle: preValue.s1Angle,
      s2Angle: preValue.s2Angle,
      s3Angle: preValue.s3Angle,
    });
    clearErrors();
    setIsEdit(false);
  };

  if (!setupMachineData) {
    return null;
  }

  return (
    <Box key={`${step}-${subStep}`}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step3} />
          </Box>

          <Typography variant='body1' component='div'>
            1. If patient doesn’t need a Practice Test, then skip to <b style={{ color: '#ED9A00' }}>step 5</b>.
            <br />
            2. Loosen chain tightener.
            <br />
            3. Machine will automatically select 3 random angles, within the patient’s pain-free ROM (random angles =
            near full extension, middle of range, near full flexion).
            <br />
            4. You can manually change these angles if needed.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box>
            <SingleImageStep img={step1} />
          </Box>

          <form onSubmit={handleSubmit(onSaveHandler)}>
            <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Pre-set Angles</div>

              <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'space-between', width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  <div style={{ fontSize: '20px', fontWeight: 500 }}>1st Angle (Biggest)</div>
                  {isEdit ? (
                    <Controller
                      name='s1Angle'
                      rules={{
                        required: true,
                        validate: {
                          required: (value) =>
                            Number(value) <= Number(setupMachineData.range_motion_max) &&
                            Number(value) > Number(getValues('s3Angle')) &&
                            Number(value) > Number(getValues('s2Angle')),
                          div: (value) => Number(value) % 3 === 0,
                        },
                      }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          error={!!errors.s1Angle}
                          type={'number'}
                          helperText={
                            <div>
                              {errors.s1Angle?.type === 'required' ? t('common:errors.isometricError') : ''}
                              {errors.s1Angle?.type === 'div' ? '*The angle value should be divided by 3.' : ''}
                              <br />
                            </div>
                          }
                          onChange={(v) => {
                            if (Number(v.target.value) < 0 || Number(v.target.value) > 72) {
                              return;
                            }
                            onChange(v);
                          }}
                          value={value}
                        />
                      )}
                    />
                  ) : (
                    <div style={{ fontSize: '24px', fontWeight: 400 }}>{getValues('s1Angle')}°</div>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <div style={{ fontSize: '20px', fontWeight: 500 }}>2nd Angle (Middle)</div>
                  {isEdit ? (
                    <Controller
                      name='s2Angle'
                      rules={{
                        required: true,
                        validate: {
                          required: (value) =>
                            Number(value) < Number(getValues('s1Angle')) &&
                            Number(value) > Number(getValues('s3Angle')),
                          div: (value) => Number(value) % 3 === 0,
                        },
                      }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          error={!!errors.s2Angle}
                          type={'number'}
                          helperText={
                            <div>
                              {errors.s2Angle?.type === 'required' && t('common:errors.isometricError')}
                              {errors.s2Angle?.type === 'div' && '*The angle value should be divided by 3.'}
                              <br />
                            </div>
                          }
                          onChange={(v) => {
                            if (Number(v.target.value) < 0 || Number(v.target.value) > 72) {
                              return;
                            }
                            onChange(v);
                          }}
                          value={value}
                        />
                      )}
                    />
                  ) : (
                    <div style={{ fontSize: '24px', fontWeight: 400 }}>{getValues('s2Angle')}°</div>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <div style={{ fontSize: '20px', fontWeight: 500 }}>3rd Angle (Smallest)</div>
                  {isEdit ? (
                    <Controller
                      name='s3Angle'
                      rules={{
                        required: true,
                        validate: {
                          required: (value) =>
                            Number(value) >= Number(setupMachineData.range_motion_min) &&
                            Number(value) < Number(getValues('s1Angle')) &&
                            Number(value) < Number(getValues('s2Angle')),

                          div: (value) => Number(value) % 3 === 0,
                        },
                      }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          error={!!errors.s3Angle}
                          type={'number'}
                          helperText={
                            <div>
                              {errors.s3Angle?.type === 'required' && t('common:errors.isometricError')}
                              {errors.s3Angle?.type === 'div' && '*The angle value should be divided by 3.'}
                              <br />
                            </div>
                          }
                          onChange={(v) => {
                            if (Number(v.target.value) < 0 || Number(v.target.value) > 72) {
                              return;
                            }
                            onChange(v);
                          }}
                          value={value}
                        />
                      )}
                    />
                  ) : (
                    <div style={{ fontSize: '24px', fontWeight: 400 }}>{getValues('s3Angle')}°</div>
                  )}
                </Box>
              </Box>

              {isEdit ? (
                <Box sx={{ display: 'flex', gap: '20px' }}>
                  <Button onClick={onCancelHandler} variant='outlined'>
                    {t('common:cancel')}
                  </Button>
                  <Button type={'submit'} variant='contained'>
                    {t('common:save')}
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', gap: '20px' }}>
                  <Button disabled={disablePracticePreSetAngle} onClick={() => setIsEdit(true)} variant='outlined'>
                    EDIT PRE-SET ANGLES
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        </Box>
      </Box>

      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={backHandler}
        handleNext={nextHandler}
        disableControl={isEdit}
        disableNext={isEdit}
      />
    </Box>
  );
}

PreSetAngles.propTypes = {
  setupMachineData: PropTypes.object,
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  value: PropTypes.object,
  onDataChange: PropTypes.func,
  disablePracticePreSetAngle: PropTypes.bool,
  setDisablePracticePreSetAngle: PropTypes.func,
};
