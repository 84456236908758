import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';

export default function TightenRestraints({ step, subStep, handleCancel, handleBack, handleNext, handleRemoveSkip }) {
  const nextHandler = () => {
    handleRemoveSkip(step);
    handleNext();
  };
  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>1. Tighten hip restraints, so patient cannot wiggle hips at all.</Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>2. Tighten torso restraint, so patient cannot move or shrug their shoulders.</Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              3. Instruct patient to maintain contact with their back and the seatback.
              <br />
              4. Instruction patient to rest hands in their lap and keep their face pointed forward.
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={nextHandler}
      />
    </Box>
  );
}

TightenRestraints.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleRemoveSkip: PropTypes.func,
};
