import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getHcpUsers: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/users`,
  },
  getRoles: {
    url: `${ENV_VARIABLES.API_SERVER}users/hcp-roles`,
  },
  inviteHcpUser: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/users`,
  },
  editHcpUser: {
    url: (hcpId, hcpUserId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/users/${hcpUserId}`,
  },
  deleteHcpUser: {
    url: (hcpId, hcpUserId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/users/${hcpUserId}`,
  },
};
