import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { SingleImageStep } from 'helpers';
import noImage from 'assets/images/png/noImage.png';
import Box from '@mui/material/Box';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const Preparation = ({ step, handleCancel, handleNext, subStep, handleBack, setupMachineData }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Typography variant='body1' component='div'>
          1. If patient is already set up in the Torso Rot machine, then skip to <b>step 3</b> - “Exercise
          Instructions.”
          <br />
          2. Before starting, make sure you have all medical information about the patient updated.
          <br />
          3. Ask patient if they need to use the restroom as the bladder area will be compressed.
          <br />
          4. Have patient remove belts or restrictive garments and empty object from pockets.
          <br />
        </Typography>
        <Box>
          <Box
            sx={{
              my: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={noImage} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                5. Disengage angle selector. Select 0° and engaged angle selector.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              my: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={noImage} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                6. Adjust both Range of Motion limiters to:
              </Typography>
              <Box>
                <b> Patient Setup ROM Limiters</b>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <div
                    style={{
                      padding: 2,
                      border: '1px solid green',
                      fontWeight: 500,
                      borderRadius: '8px',
                      width: '100px',
                      textAlign: 'center',
                      color: 'green',
                    }}
                  >
                    {`Right ${setupMachineData.limiter_min}`}
                  </div>
                  <div
                    style={{
                      padding: 2,
                      border: '1px solid green',
                      fontWeight: 500,
                      borderRadius: '8px',
                      width: '100px',
                      textAlign: 'center',
                      color: 'green',
                    }}
                  >
                    {`Left ${setupMachineData.limiter_max}`}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              my: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={noImage} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                7. Loosen chain tightener.
              </Typography>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        handleBack={handleBack}
        subStep={subStep}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
};

Preparation.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};

export default Preparation;
