export const defaultKnee = [
  {
    id: '1',
    name: 'Preparation',
  },
  {
    id: '2',
    name: 'Brief the Patient',
  },
  {
    id: '3',
    name: 'Physical Positioning',
    children: [
      {
        id: '3-1',
        name: 'Seating Position',
      },
      {
        id: '3-2',
        name: 'Restraints',
      },
    ],
  },
  {
    id: '4',
    name: 'Instructions',
  },
  {
    id: '5',
    name: 'Find Range of Motion (RoM)',
  },
];

const getBothKnee = (first) => {
  const firstSubText = first === 4 ? 'Left' : 'Right';
  const secondSubText = firstSubText === 'Left' ? 'Right' : 'Left';

  return [
    {
      id: '5-1',
      name: `${firstSubText} - Find Max Extension Angle`,
    },
    {
      id: '5-2',
      name: `${firstSubText} - Find Max Flexion Angle`,
    },
    {
      id: '5-3',
      name: 'Patient Release',
    },
    {
      id: '5-4',
      name: 'Equipment Swap',
    },
    {
      id: '5-5',
      name: 'Restraint',
    },
    {
      id: '5-6',
      name: `${secondSubText} - Find Max Extension Angle`,
    },
    {
      id: '5-7',
      name: `${secondSubText} - Find Max Flexion Angle`,
    },
    {
      id: '5-8',
      name: 'Patient Release',
    },
  ];
};
export const bothKnee = [
  {
    id: '5-1',
    name: 'Left - Find Max Extension Angle',
    state: 'left',
  },
  {
    id: '5-2',
    name: 'Left - Find Max Flexion Angle',
    state: 'left',
  },
  {
    id: '5-3',
    name: 'Patient Release',
    state: 'left',
  },
  {
    id: '5-4',
    name: 'Equipment Swap',
    state: 'both',
  },
  {
    id: '5-5',
    name: 'Restraint',
    state: 'both',
  },
  {
    id: '5-6',
    name: 'Right - Find Max Extension Angle',
    state: 'right',
  },
  {
    id: '5-7',
    name: 'Right - Find Max Flexion Angle',
    state: 'right',
  },
  {
    id: '5-8',
    name: 'Patient Release',
    state: 'right',
  },
];

const leftKnee = [
  {
    id: '5-1',
    name: 'Left - Find Max Extension Angle',
    state: 'left',
  },
  {
    id: '5-2',
    name: 'Left - Find Max Flexion Angle',
    state: 'left',
  },
  {
    id: '5-3',
    name: 'Patient Release',
    state: 'left',
  },
];

const rightKnee = [
  {
    id: '5-1',
    name: 'Right - Find Max Extension Angle',
    state: 'right',
  },
  {
    id: '5-2',
    name: 'Right - Find Max Flexion Angle',
    state: 'right',
  },
  {
    id: '5-3',
    name: 'Patient Release',
    state: 'right',
  },
];

// state 1 2 3 4 5
// state l r b b-l b-r
export const getNavigate = (state) => {
  if (!state) {
    return defaultKnee;
  }

  if (state === 3) {
    return defaultKnee;
  }

  if (state < 3) {
    const navWithBoth = JSON.parse(JSON.stringify(defaultKnee));
    navWithBoth[4].children = state === 1 ? leftKnee : rightKnee;

    return navWithBoth;
  }

  const navWithBoth = JSON.parse(JSON.stringify(defaultKnee));
  navWithBoth[4].children = getBothKnee(state);

  return navWithBoth;
};
