import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchMachineSetup, requestInitSetup } from '../../../slice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TextareaAutosize } from '@mui/material';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { fetchHcpMachines } from '../../../../Machines/slice';

const EditContainer = styled('div')`
  & #notes {
    width: 100%;
    border: 1px solid #dddfe1;
    border-radius: 5px;
    font-family: Roboto, Montserrat, sans-serif;
    font-size: 16px;
  }

  & #notes:hover {
    border-color: black;
  }
  & #notes:focus {
    outline-color: #ed9a00;
  }
`;

export default function CervicalRotationEditSummary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const { id, machineId, setupId } = useParams();

  const sessionId = searchParams.get('sessionId');
  const machineType = searchParams.get('machineType');
  const mode = searchParams.get('mode');
  const type = searchParams.get('type');

  const setupData = useSelector((state) => state.patients.setup);
  const isInitSetups = useSelector((state) => state.patients.isInitSetups);
  const machines = useSelector((state) => state.machines.hcpMachines);

  const [requestingSetup, setRequestingSetup] = useState(false);
  const [maxMachineRange, setMaxMachineRange] = useState(undefined);

  const defaultValues = {
    setup_name: '',
    seat_pad: '',
    range_motion_min: '',
    range_motion_max: '',
    temporal_adjustment_pos: '',
    seat_pos: '',
    notes: '',
  };

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (!id || !machineId || !setupId) {
      return null;
    }
    clearErrors();
    const hcpId = localStorage.getItem('hcpId');

    const data = {
      hcpId: hcpId,
      patientId: btoa(id),
      machineId: machineId,
    };

    dispatch(fetchHcpMachines({ hcpId }));
    dispatch(fetchMachineSetup({ ...data, setupId: setupId }));
  }, []);

  useEffect(() => {
    if (!machines || (machines && machines.length === 0)) {
      return null;
    }

    for (let i = 0; i < machines.length; i++) {
      if (Number(machines[i].machine.id) === Number(machineId)) {
        setMaxMachineRange(machines[i].machine.max_angle);
        break;
      }
    }
  }, [machines]);

  useEffect(() => {
    if (requestingSetup && !isInitSetups) {
      const setup_id = localStorage.getItem('setupId');

      if (setup_id && id) {
        navigator(
          `/patient/${id}/machine-setup/summary?setupId=${setup_id}&isBack=true&machineType=${machineType}&type=${type}&sessionId=${sessionId}&machineId=${machineId}&isCloseDuplicate=false&mode=${mode}`
        );
      }
    }
  }, [isInitSetups, requestingSetup, setupData]);

  useEffect(() => {
    if (!setupData || (setupData && !setupData.data)) {
      return;
    }
    setValue('setup_name', setupData.setup_name + '-copy' ?? '');
    setValue('seat_pad', setupData.data.seat_pad);
    setValue('temporal_adjustment_pos', setupData.data.temporal_adjustment_pos);
    setValue('range_motion_min', setupData.data.range_motion_min);
    setValue('range_motion_max', setupData.data.range_motion_max);
    setValue('seat_pos', setupData.data.seat_pos);
    setValue('notes', setupData.notes ?? '');
  }, [setupData]);

  if (!setupData || (setupData && !setupData.data)) {
    return null;
  }

  const onSaveHandler = (data) => {
    Object.keys(data).map((key) => {
      if (key !== 'notes' && key !== 'setup_name' && key !== 'seat_pos') {
        data[key] = Number(data[key]);
      }
    });

    if (data && data.setup_name && data.setup_name.length > 255) {
      setError('setup_name', {
        type: 'max_length',
      });
    }

    dispatch(
      requestInitSetup({
        data,
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(id),
        machineId,
        setupId,
      })
    );
    setRequestingSetup(true);
  };

  const getNameErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'max_length') return 'Max length is 255 chars';
  };

  const getAngleErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'dividedByThree') return 'The angle value should be divided by 6.';
    if (error?.type === 'numberLaw')
      return '*Please enter a value in the range of 0 to 8.5. With decimal figure divided by 0.25.';
  };
  const getPosErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
  };
  return (
    <EditContainer>
      <Container
        sx={{
          backgroundColor: 'grey1',
          p: 3,
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            minHeight: 'calc(100vh - 118px)',
            p: 3,
            display: 'flex',
            justifyContent: 'center',
            bgcolor: 'background.paper',
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }} maxWidth='lg'>
            <form onSubmit={handleSubmit(onSaveHandler)}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '15px 0 15px 0' }}></Box>
                <Box sx={{ width: '50%' }}>
                  <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Name
                      </Typography>
                    </Box>

                    <Controller
                      name='setup_name'
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          error={!!errors.setup_name}
                          helperText={getNameErrorMessage(errors.setup_name)}
                          fullWidth
                          id='setup_name'
                          value={value}
                          onChange={(v) => {
                            onChange(v);
                          }}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                    <Typography variant='h6' sx={{ wordBreak: 'break-all', fontWeight: '500' }} component='div'>
                      Test Type
                    </Typography>
                    <Typography sx={{ wordBreak: 'break-all' }} variant='p' component='div'>
                      {t('customer:patientSession.machineSetup')}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', padding: '15px 0' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Max Left ROM
                      </Typography>

                      <Controller
                        name='range_motion_max'
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            dividedByThree: (v) => v % 6 === 0,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={!!errors.range_motion_max}
                            helperText={getAngleErrorMessage(errors.range_motion_max)}
                            type={'number'}
                            sx={{ width: '150px' }}
                            fullWidth
                            id='range_motion_max'
                            value={value}
                            onChange={(data) => {
                              if (!data.target.value) {
                                onChange(data);
                                return;
                              }
                              if (data.target.value < -maxMachineRange || data.target.value > maxMachineRange) {
                                return;
                              }
                              onChange(data);
                            }}
                          />
                        )}
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Max Right ROM
                      </Typography>

                      <Controller
                        name='range_motion_min'
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            dividedByThree: (v) => v % 6 === 0,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={!!errors.range_motion_min}
                            helperText={getAngleErrorMessage(errors.range_motion_min)}
                            type={'number'}
                            sx={{ width: '150px' }}
                            fullWidth
                            id='range_motion_min'
                            value={value}
                            onChange={(data) => {
                              if (!data.target.value) {
                                onChange(data);
                                return;
                              }
                              if (data.target.value < -maxMachineRange || data.target.value > maxMachineRange) {
                                return;
                              }
                              onChange(data);
                            }}
                          />
                        )}
                      />
                    </div>
                  </Box>

                  <Box sx={{ display: 'flex', padding: '15px 0' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Back Pad
                      </Typography>

                      <Controller
                        name='seat_pad'
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={!!errors.seat_pad}
                            helperText={getAngleErrorMessage(errors.seat_pad)}
                            sx={{ width: '150px' }}
                            fullWidth
                            id='seat_pad'
                            value={value}
                            onChange={(data) => {
                              if (!data.target.value) {
                                onChange(data);
                                return;
                              }
                              if (data.target.value < 0 || data.target.value > 2) {
                                return;
                              }

                              onChange(data);
                            }}
                            type={'number'}
                          />
                        )}
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Seat Position
                      </Typography>

                      <Controller
                        name='seat_pos'
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{ width: '150px' }}
                            fullWidth
                            error={!!errors.seat_pos}
                            helperText={getPosErrorMessage(errors.seat_pos)}
                            id='seat_pos'
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </Box>
                  <Box sx={{ display: 'flex', padding: '15px 0' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                      <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                        Temporal Pad Position
                      </Typography>

                      <Controller
                        name='temporal_adjustment_pos'
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            match: (v) => v >= 0 || v <= 2,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            error={!!errors.temporal_adjustment_pos}
                            helperText={errors.temporal_adjustment_pos && t('common:errors.required')}
                            type={'number'}
                            sx={{ width: '150px' }}
                            fullWidth
                            id='temporal_adjustment_pos'
                            value={value}
                            onChange={(data) => {
                              if (!data.target.value) {
                                onChange(data);
                                return;
                              }
                              if (data.target.value < 1 || data.target.value > 3) {
                                return;
                              }
                              onChange(data);
                            }}
                          />
                        )}
                      />
                    </div>
                  </Box>
                  <Box sx={{ display: 'flex', padding: '10px 0', flexDirection: 'column' }}>
                    <Typography sx={{ wordBreak: 'break-all', fontWeight: '500' }} variant='h6' component='div'>
                      Notes
                    </Typography>

                    <Box>
                      <Controller
                        name='notes'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextareaAutosize
                            value={value}
                            minRows={5}
                            id={'notes'}
                            onChange={(data) => {
                              if (!data.target.value) {
                                onChange(data);
                                return;
                              }
                              if (data.target.value.length > 3000) {
                                return;
                              }
                              onChange(data);
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}>
                  <Button onClick={() => navigator(-1)} variant='outlined'>
                    {t('common:cancel')}
                  </Button>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button disabled={isInitSetups} type={'submit'} variant='contained'>
                      {t('common:save')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Container>
    </EditContainer>
  );
}
