import * as ProsType from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';

export const Item = ({ status, smg }) => {
  const iconRender = () => {
    if (status) {
      return <CircularProgress style={{ color: '#5FCB97' }} size={13.33} />;
    }

    return <CheckIcon fontSize={'18px'} style={{ color: '#5FCB97' }} />;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      {iconRender()}
      <Box sx={{ fontSize: '13.33px', fontWeight: 400 }}>{smg}</Box>
    </Box>
  );
};

Item.propTypes = {
  status: ProsType.bool,
  smg: ProsType.string,
};
