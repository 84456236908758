import ENV_VARIABLES from 'config/variables';

export const DETECTED_MACHINE_STATUS = {
  FAILED: -1,
  READY: 0,
  CONFIGURING: 1,
  COMPLETED: 2,
};

export const CALIBRATE_ACTION_TYPE = {
  POTENTIOMETER: "machine_calibration_potentiometer",
  STRAIN_GAUGE: "machine_calibration_strain_gauge",
  DEBUG: "machine_calibration_debug",
  SPECIFICATION_SETTING: "machine_calibration_specification_setting"
}

export const END_POINT = {
  getMachines: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/machines?hcp_id=${hcpId}`,
  },
  updateMachines: {
    url: `${ENV_VARIABLES.API_SERVER}machines/`,
  },
  getDetectedMachines: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/machines/detected_machines?hcp_id=${hcpId}`,
  },
  addMachine: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/machines/`,
  },
  editMachine: {
    url: (id, hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/machines?id=${id}&hcp_id=${hcpId}`,
  },
  assignMachine: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/assignments`,
  },
  removeMachine: {
    url: (id) => `${ENV_VARIABLES.API_SERVER}machines/?id=${id}`,
  },
  configureMachine: {
    url: (machineId, hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/machines/${machineId}/configure?hcp_id=${hcpId}`,
  },
  getHcpMachines: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}machines/hcps/${hcpId}`,
  },
  getHcpConfiguredMachines: {
    url: `${ENV_VARIABLES.API_SERVER}machines/configured`,
  },
  requestUpdateSoftware: {
    url: (machineId, hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/update-version/${machineId}?hcp_id=${hcpId}`,
  },
  requestLoggingRecordAction: {
    url: `${ENV_VARIABLES.API_SERVER}logging/record`,
    method: 'POST'
  }
};
