import styled from '@emotion/styled';

export const Container = styled('div')`
  width: 100%;
  height: calc(100vh - 70px);
  overflow: hidden;
  background: #f4f5f7;
  & .header {
    width: 100%;
    background: #fff;
    padding: 32px 32px 0 32px;
    display: flex;
    flex-direction: column;
  }
  & .header-name {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #212529;
  }
  & .patient-info {
    margin-left: auto;

    .MuiAvatar-root {
      display: none;
    }
    .MuiTypography-root {
      color: #000;
      font-size: 16px;
    }
  }

  & .MuiTabs-flexContainer {
    gap: 20px;
  }

  & .MuiTab-root {
    padding-left: 0;
    padding-right: 0;
  }
`;
