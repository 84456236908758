import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import seatBack from 'assets/images/knee/seatback adjustment.svg';
import goinometerROM from 'assets/images/knee/goinometer-ROM gauge 1.svg';
import chaintightner from 'assets/images/knee//Chain tightener.svg';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { getInstructions } from 'helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useRef, useState } from 'react';
import { requestSaveSetup } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

const instructions = [
  {
    id: 1,
    text: '4. Unlock movement arm. Use Operator Control handle to select 72°. Lock movement arm.',
    img: goinometerROM,
  },
  {
    id: 2,
    text: '5. Use seatback adjustment (counterclockwise) to move seat as far back as it will go.',
    img: seatBack,
  },
  {
    id: 3,
    text: '6. Loosen chain tightener completely.',
    img: chaintightner,
  },
];

export default function Preparation({ step, handleCancel, handleNext, subStep, setupMachineData }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const patientId = id;
  const setupIdParams = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const isUpdate = useRef(null);
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (!setupMachineData) {
      return;
    }

    if (!setupMachineData.knee_test_mode) {
      setValue(1);
      return;
    }

    setValue(setupMachineData.knee_test_mode > 3 ? 3 : setupMachineData.knee_test_mode);
  }, [setupMachineData]);

  useEffect(() => {
    if (!isUpdate.current) {
      return null;
    }

    if (isUpdating) {
      return null;
    }

    handleNext();
  }, [isUpdating]);

  const kneeSelectHandler = (v) => {
    setValue(v.target.value);
  };

  const nextHandler = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!setupMachineData?.knee_test_mode) {
      handleNext();
      return null;
    }

    const setupId = localStorage.getItem('setupId') || setupIdParams;
    isUpdate.current = true;
    dispatch(
      requestSaveSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(patientId),
        machineId: machineId,
        finished: false,
        setupId,
        data: {
          knee_test_mode: value,
        },
      })
    );
  };

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Typography variant='body1' component='div'>
          1. Before starting, make sure you have all medical information about the patient updated.
          <br />
          2. Ask patient if they need to use the restroom as the bladder area will be compressed.
          <br />
          3. Have patient remove belts or restrictive garments and empty object from pockets.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>{getInstructions(instructions)}</Box>
        <br />
        7. Place patient in Knee machine.
        <br />
        8. Is this patient setup for left, right, or both legs?
        <div style={{ fontSize: '14px', padding: '0 20px' }}>
          *It is recommended that initial tests include range of motion strength level determination (flexion and
          extension) for both legs.
        </div>
        <div style={{ padding: '10px' }}>
          <Select
            disabled={!!setupMachineData?.knee_test_mode}
            style={{ minWidth: '100px' }}
            value={value}
            onChange={kneeSelectHandler}
          >
            <MenuItem value={3}>Both</MenuItem>
            <MenuItem value={1}>Left</MenuItem>
            <MenuItem value={2}>Right</MenuItem>
          </Select>
        </div>
      </OverlayScrollbarsComponent>
      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={nextHandler}
        nextLoading={isUpdating}
      />
    </Box>
  );
}

Preparation.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
