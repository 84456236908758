import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

export default function AccountAvatar({ user, setAnchorEl }) {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Tooltip title='Account settings'>
      <Button variant='text' onClick={handleClick} sx={{ color: 'white' }}>
        <Avatar sx={{ width: 40, height: 40 }}>
          {user.first_name.split('')[0]}
          {user.last_name.split('')[0]}
        </Avatar>
      </Button>
    </Tooltip>
  );
}

AccountAvatar.propTypes = {
  user: PropTypes.object,
  setAnchorEl: PropTypes.func,
};
