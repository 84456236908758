import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import goinometer from 'assets/images/knee/goinometer-ROM gauge 1.svg';
import seatbackAdjustment from 'assets/images/knee/seatback adjustment.svg';
import chainTightener from 'assets/images/knee/Chain tightener.svg';
import Box from '@mui/material/Box';
import { SingleImageStep } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const Preparation = ({ step, handleCancel, handleNext, subStep, handleBack, setupMachineData }) => {
  return (
    <>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Typography variant='body1' component='div'>
          1. If patient is already set up in the Knee machine, then skip to <b>step 3</b> - “Exercise Instructions.”
          <br />
          2. Before starting, make sure you have all medical information about the patient updated.
          <br />
          3. Ask patient if they need to use the restroom as the bladder area will be compressed.
          <br />
          4. Have patient remove belts or restrictive garments and empty object from pockets.
          <br />
        </Typography>
        <Box>
          <Box
            sx={{
              my: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={goinometer} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                5. Unlock movement arm. Use Operator Control handle to select 72°. Lock movement arm.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              my: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={seatbackAdjustment} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                6. Use seatback adjustment dail-in seat position.
              </Typography>
              <Box>
                <b> Patient Setup Seat Position</b>

                <div
                  style={{
                    padding: 2,
                    border: '1px solid green',
                    fontWeight: 500,
                    borderRadius: '8px',
                    width: '100px',
                    textAlign: 'center',
                    color: 'green',
                  }}
                >
                  {setupMachineData.seat_pos}
                </div>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              my: 4,
              display: 'flex',
            }}
          >
            <SingleImageStep img={chainTightener} />
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Typography variant='body1' component='div'>
                7. Loosen chain tightener completely.
                <br />
                8. Place patient in Knee machine.
              </Typography>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        handleBack={handleBack}
        subStep={subStep}
        handleNext={handleNext}
        handleCancel={handleCancel}
      />
    </>
  );
};

Preparation.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};

export default Preparation;
