import React, { Suspense } from 'react';

const ManageAuditLogs = React.lazy(() => import('modules/AuditLogs/containers/ManageAuditLogs'));
export default [
  {
    path: '/hcp/manage/audit-log',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ManageAuditLogs />
      </Suspense>
    ),
    private: true,
  },
];
