import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useRef, useState } from 'react';
import { requestSaveSetup } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { allowDecimalNumber } from 'helpers';

const seatData = [
  {
    id: 0,
    value: 0,
  },
  {
    id: 1,
    value: 1,
  },
  {
    id: 2,
    value: 2,
  },
];
const temporalData = [
  {
    id: 1,
    value: 1,
  },
  {
    id: 2,
    value: 2,
  },
  {
    id: 3,
    value: 3,
  },
];
export default function HeadAlignment({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const patientId = id;
  const setupIdParams = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const isUpdate = useRef(null);
  const defaultValues = {
    seat_pos: '',
    seat_pad: '',
    temporal_adjustment_pos: '',
  };

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  const [stepData, setStepData] = useState(defaultValues);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setValue('seat_pos', setupMachineData.seat_pos === null ? '' : setupMachineData.seat_pos);
    setValue('seat_pad', setupMachineData.seat_pad === null ? '' : setupMachineData.seat_pad);
    setValue(
      'temporal_adjustment_pos',
      setupMachineData.temporal_adjustment_pos === null ? '' : setupMachineData.temporal_adjustment_pos
    );

    setStepData({
      seat_pos: setupMachineData.seat_pos,
      seat_pad: setupMachineData.seat_pad,
      temporal_adjustment_pos: setupMachineData.temporal_adjustment_pos,
    });
  }, [setupMachineData]);

  const handleSeatChange = (value) => {
    setStepData((prevState) => ({ ...prevState, seat_pad: value }));
  };
  const handleSeatPosChange = (value) => {
    setStepData((prevState) => ({ ...prevState, seat_pos: value }));
  };
  const handleTemporalChange = (value) => {
    setStepData((prevState) => ({ ...prevState, temporal_adjustment_pos: value }));
  };

  const inputSeatHeightHandler = (event, onchange) => {
    if (!event.target.value) {
      onchange(event);
      setStepData((prevState) => ({
        ...prevState,
        seat_pos: event.target.value,
      }));
      return;
    }

    onchange(event);
    setStepData((prevState) => ({
      ...prevState,
      seat_pos: event.target.value,
    }));
  };

  const isDisable = () => {
    if (!stepData || !!errors.seat_pos) {
      return true;
    }

    if (
      stepData.seat_pos === null ||
      stepData.seat_pos === '' ||
      stepData.seat_pos === undefined ||
      stepData.seat_pad === null ||
      stepData.seat_pad === '' ||
      stepData.seat_pad === undefined ||
      stepData.temporal_adjustment_pos === '' ||
      stepData.temporal_adjustment_pos === undefined ||
      stepData.temporal_adjustment_pos === null
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!isUpdate.current) {
      return null;
    }

    if (isUpdating) {
      return null;
    }

    handleNext();
  }, [isUpdating]);

  const getPosErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
  };

  const onNextHandler = () => {
    if (!setupMachineData) {
      return null;
    }

    if (
      stepData.seat_pad === setupMachineData.seat_pad &&
      stepData.temporal_adjustment_pos === setupMachineData.temporal_adjustment_pos &&
      stepData.seat_pos === setupMachineData.seat_pos
    ) {
      handleNext();
      return;
    }

    const setupId = localStorage.getItem('setupId') || setupIdParams;
    isUpdate.current = true;
    dispatch(
      requestSaveSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(patientId),
        machineId: machineId,
        finished: false,
        setupId,
        data: {
          seat_pad: stepData.seat_pad,
          temporal_adjustment_pos: stepData.temporal_adjustment_pos,
          seat_pos: stepData.seat_pos,
        },
      })
    );
  };

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              1. See if patient’s ears are aligned horizontally with the ear hole of the Head Restraint Pad. If patient
              is seated to far back, then add a Back Pad.
              <br />
              2. Input the BackPad information. 0 (no pad), 1, or 2.
              <br />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <label>
                  <b>Back Pad</b>
                </label>
                <Controller
                  name='seat_pad'
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ maxWidth: 150, mt: 1 }}>
                      <Select
                        value={value}
                        onChange={(event) => {
                          onChange(event);
                          handleSeatChange(event.target.value);
                        }}
                      >
                        <MenuItem value=''>
                          <em>--</em>
                        </MenuItem>
                        {seatData.map((s) => (
                          <MenuItem key={s.id} value={s.id}>
                            {s.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              3. Use Seat Adjustment to center-align ears vertically with the ear hole of the Head Restraint Pad.
              <br />
              4. Adjust height of Head Restraint Pads up or down, with Temporal Adjustment to center-align ears, if
              necessary.
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              5. Input the Temporal Adjustment Position. 1, 2, or 3 (top position).
              <br />
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <label>
                    <b>Temporal Adjustment Position</b>
                  </label>
                  <Controller
                    name='temporal_adjustment_pos'
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ maxWidth: 150, mt: 1 }}>
                        <Select
                          value={value}
                          onChange={(event) => {
                            onChange(event);
                            handleTemporalChange(event.target.value);
                          }}
                        >
                          <MenuItem value=''>
                            <em>--</em>
                          </MenuItem>
                          {temporalData.map((s) => (
                            <MenuItem key={s.id} value={s.id}>
                              {s.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
              <br />
              6. Input the Seat Position from the Seat Position Gauge.
              <br />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <label>
                  <strong>Seat Position</strong>
                  <br />
                  Enter the entire number on the gauge as a whole number.
                </label>
                <Controller
                  name='seat_pos'
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ maxWidth: 150, mt: 1 }}>
                      <TextField
                        onKeyPress={(e) => {
                          allowDecimalNumber(e);
                        }}
                        error={!!errors.seat_pos}
                        helperText={getPosErrorMessage(errors.seat_pos)}
                        placeholder='---'
                        type={'text'}
                        value={value}
                        onChange={(event) => {
                          inputSeatHeightHandler(event, onChange);
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={onNextHandler}
        nextLoading={isUpdating}
        disableNext={isDisable()}
      />
    </Box>
  );
}

HeadAlignment.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
