import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export function InputText({
  value,
  onChange,
  isWidthFull,
  size,
  variant,
  errorSmg,
  maxLength,
  error,
  type,
  disable,
  placeholder,
  isPositive,
  numberRange,
  step,
}) {
  const [isError, setError] = useState(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    let newValue = value;
    if (value === textValue) {
      return;
    }

    if ((!value && type !== 'number') || (value === 0 && type === 'number')) {
      newValue = '';
    }

    setTextValue(newValue);
  }, [value]);

  useEffect(() => {
    setError(error);
  }, [error]);

  const onChangeHandler = (e) => {
    let newValue = e.target.value;

    if (type === 'number' && isPositive && Number(newValue) < 0) {
      return;
    }

    if (numberRange && Number(newValue) > numberRange[1]) {
      newValue = numberRange[1];
    }

    setTextValue(newValue);
    onChange(newValue);
  };

  // change inputted value into the smallest allowed value if inputted value is lower
  const onChangeMinHandler = (e) => {
    let newValue = e.target.value;

    // if input type is positive number and inputted value is < 0 OR < min numberRange
    if (
      numberRange &&
      type === 'number' &&
      newValue !== '' &&
      isPositive &&
      (Number(newValue) < 0 || Number(newValue) < numberRange[0])
    ) {
      newValue = numberRange[0];
    }

    setTextValue(newValue);
    onChange(newValue);
  };

  return (
    <TextField
      disabled={disable}
      onChange={onChangeHandler}
      onBlur={onChangeMinHandler}
      value={textValue}
      fullWidth={isWidthFull}
      size={size}
      placeholder={placeholder}
      variant={variant || 'outlined'}
      inputProps={{
        maxLength: maxLength,
        step: step ?? '1',
      }}
      type={type}
      error={isError}
      helperText={isError ? errorSmg : ' '}
    />
  );
}

InputText.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  isWidthFull: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  maxLength: PropTypes.number,
  errorSmg: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
  disable: PropTypes.bool,
  placeholder: PropTypes.any,
  isPositive: PropTypes.bool,
  numberRange: PropTypes.array,
  step: PropTypes.string,
};
