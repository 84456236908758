import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
export default function HeadAlignment({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData }) {
  const defaultValues = {
    seat_pos: '',
    seat_pad: '',
    temporal_adjustment_pos: '',
  };

  const {
    control,
    setValue,
    formState: {},
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setValue('seat_pos', setupMachineData.seat_pos === null ? '' : setupMachineData.seat_pos);
    setValue('seat_pad', setupMachineData.seat_pad === null ? '' : setupMachineData.seat_pad);
    setValue(
      'temporal_adjustment_pos',
      setupMachineData.temporal_adjustment_pos === null ? '' : setupMachineData.temporal_adjustment_pos
    );
  }, [setupMachineData]);

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              1. See if patient’s ears are aligned horizontally with the ear hole of the Head Restraint Pad.
              <br />
              2. Add back pad if needed according to the information below.
              <br />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <label>
                  <b>Patient Setup Back Pad</b>
                </label>
                <Controller
                  name='seat_pad'
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { value } }) => (
                    <FormControl disabled={true} sx={{ maxWidth: 150, mt: 1 }}>
                      <Select value={value}>
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              3. Use Seat Adjustment to center-align ears vertically with the ear hole of the Head Restraint Pad.
              <br />
              4. Adjust the Seat Position according to information below.
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <label>
                  <strong>Patient Setup Seat Position</strong>
                </label>
                <Controller
                  name='seat_pos'
                  control={control}
                  render={({ field: { value } }) => (
                    <FormControl disabled={true} sx={{ maxWidth: 150, mt: 1 }}>
                      <TextField disabled={true} placeholder='---.-' value={value} />
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              5. Adjust height of Head Restraint Pads up or down, with Temporal Adjustment to center-align ears, if
              necessary.
              <br />
              6. Adjust Temporal Adjustment according to information below.
              <br />
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <label>
                    <b>Patient Setup Temporal Pad Postion</b>
                  </label>
                  <Controller
                    name='temporal_adjustment_pos'
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { value } }) => (
                      <FormControl disabled={true} sx={{ maxWidth: 150, mt: 1 }}>
                        <Select value={value}>
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Box>
  );
}

HeadAlignment.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
