import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from 'components/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { clearLocalPatientSetupData } from '../utils';

export const SummaryControl = ({
  isBack,
  patientId,
  setupId,
  sessionId,
  machine,
  disableControl,
  type,
  setupValue,
  mode,
  hideIsometric,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const prevUrl = useSelector((state) => state.patients.prevUrl);
  const setupType = searchParams.get('type');
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeSelected, setTypeSelected] = useState('');
  const open = Boolean(anchorEl);
  const tab = searchParams.get('tab');
  const [showModal, setShowModal] = useState(false);

  const handleBack = () => {
    if (type === 'ACTIVITY') {
      navigate(-1);
    }
    if (prevUrl) {
      navigate(prevUrl);
    }

    navigate(`/patient/${patientId}/patient-detail?sessionId=${sessionId}&tab=${tab ?? '1'}`);
  };
  const getDataFromSetup = () => {
    const { session_id, id, finished_at, initiated_at, ...rest } = setupValue;
    return rest;
  };
  const onUseThisSetup = () => {
    if (!sessionId || !machine || !setupValue) {
      return null;
    }

    const { id, machine_type } = machine.machine;
    if (!id || !machine_type) {
      return null;
    }
    const activityType = mode === 1 ? typeSelected : setupType;

    if (!activityType) {
      return null;
    }

    if (activityType === 'Isometric') {
      navigate(
        `/patient/${patientId}/session/isometric-test?machineId=${id}&machineType=${machine_type}&setupId=${setupId}&sessionId=${sessionId}`
      );
    } else {
      navigate(
        `/patient/${patientId}/session/dynamic-test?machineId=${id}&machineType=${machine_type}&setupId=${setupId}&sessionId=${sessionId}`
      );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onBackToSessionListHandler = () => {
    // back to patient sessions page
    navigate(`/patient/${patientId}&tab=1`);
  };

  const createPatientMachineSetup = () => {
    navigate(`/patient/${patientId}/session/select-machine?sessionId=${sessionId}`);
  };

  const doIsometricHandler = () => {
    switch (type) {
      case 'ACTIVITY':
        clearLocalPatientSetupData();
        navigate(`/patient/${patientId}/setups?type=Isometric&sessionId=${sessionId}`);
        break;
      case 'MACHINE_SETUP':
        setTypeSelected('Isometric');
        setShowModal(true);
        break;
      default:
        break;
    }
  };

  const doDynamicHandler = () => {
    switch (type) {
      case 'ACTIVITY':
        clearLocalPatientSetupData();
        navigate(`/patient/${patientId}/setups?type=Dynamic&sessionId=${sessionId}`);
        break;
      case 'MACHINE_SETUP':
        setTypeSelected('Dynamic');
        setShowModal(true);
        break;
      default:
        break;
    }
  };

  const selectAnotherSetupHandler = () => {
    clearLocalPatientSetupData();
    navigate(`/patient/${patientId}/setups?type=${typeSelected}&sessionId=${sessionId}`);
  };

  const backButtonRender = () => {
    if (!isBack) {
      return <div></div>;
    }

    return (
      <Button disabled={disableControl} onClick={handleBack} size={'small'} variant='outlined'>
        {type === 'ACTIVITY' ? 'Back' : 'Back to setup list'}
      </Button>
    );
  };

  const controlRender = () => {
    if (mode === 1) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <>
            <Button
              disabled={disableControl}
              onClick={onBackToSessionListHandler}
              size={'small'}
              sx={{ mr: 2 }}
              variant='outlined'
            >
              {t('common:backToSessionList')}
            </Button>
            <div>
              <div>
                <Button
                  disabled={disableControl}
                  id='drop-down-button'
                  sx={{ color: '#fff' }}
                  aria-controls={open ? 'drop-down-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  endIcon={<ArrowDropDownOutlinedIcon />}
                  variant='contained'
                >
                  {t('common:newAction')}
                </Button>
                <Menu
                  id='drop-down-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'drop-down-button',
                  }}
                >
                  <MenuItem key={1} onClick={createPatientMachineSetup}>
                    {t('customer:patientSession:createPatientSetup')}
                  </MenuItem>

                  {!hideIsometric && (
                    <MenuItem key={2} onClick={doIsometricHandler}>
                      {t('customer:patientSession:doIsometricTest')}
                    </MenuItem>
                  )}

                  <MenuItem key={3} onClick={doDynamicHandler}>
                    {t('customer:patientSession:doDynamicExercise')}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        </Box>
      );
    }
    if (mode === 2) {
      return (
        <Button
          key={'footerExtraRender'}
          disabled={disableControl}
          onClick={onUseThisSetup}
          size={'small'}
          variant='contained'
        >
          {t('customer:patientSession:useThisSetup')}
        </Button>
      );
    }

    return <></>;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 0' }}>
        {backButtonRender()}
        {controlRender()}
      </Box>
      <Modal
        showModal={showModal}
        isClose={true}
        onClose={() => setShowModal(false)}
        content={() => 'Do you want to use this setup for the new activity?'}
        extra={[
          <Button onClick={selectAnotherSetupHandler} variant='outlined' key={'btn-1'}>
            {t('customer:patientSession:selectAnotherSetup')}
          </Button>,
          <Button onClick={onUseThisSetup} variant='contained' key={'btn-2'}>
            {t('customer:patientSession:useThisSetup')}
          </Button>,
        ]}
      />
    </Box>
  );
};

SummaryControl.propTypes = {
  isBack: PropTypes.bool,
  patientId: PropTypes.string,
  setupId: PropTypes.string,
  sessionId: PropTypes.string,
  machine: PropTypes.object,
  disableControl: PropTypes.bool,
  type: PropTypes.string,
  setupValue: PropTypes.object,
  mode: PropTypes.number,
  hideIsometric: PropTypes.bool,
};
export default SummaryControl;
