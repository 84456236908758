import { formatInTimeZone } from 'date-fns-tz';

export function getDateTime(string) {
  if (!string) return '';
  const date = new Date(string);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = formatInTimeZone(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
    timezone,
    `MM/dd/yyyy 'at' h:mmaaa`
  );

  return localDate;
}

export function getTimeDate(string) {
  if (!string) return '';
  const date = new Date(string);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = formatInTimeZone(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
    timezone,
    'h:mm aa, LLL d yyyy'
  );

  return localDate;
}

export const formatDate = (string) => {
  if (!string) {
    return;
  }
  if (string.includes('#')) {
    return string;
  }
  if (string.includes('/')) {
    const [year, month, day] = string.split('/');
    return `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
  }

  if (string.includes('-')) {
    const [year, month, day] = string.split('-');
    return `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
  }
};

export const dateParserWithTimezone = (d) => {
  if (!d) {
    return;
  }
  const date = new Date(d);
  const utcDate = new Date(date);
  const offset = new Date().getTimezoneOffset();
  return new Date(utcDate.getTime() - offset * 60000);
};

// export const dateParseMonthDayYear = () => {

//   const currentDate = new Date();
//   // Options for formatting the date
//   const options = {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric'
//   };

//   // Format the date string
//   const formattedDate1 = currentDate.toLocaleDateString('en-US', options);

//   const utcDate = utcToZonedTime(currentDate, 'UTC');
//   const formattedDate = format(utcDate, 'MMMM dd, yyyy', { timeZone: 'UTC' });

//   return formattedDate;
// }


// this feature include emr, that why we must sync together
export const dateParseMonthDayYear = (date) => {

  if (!date) {
    return;
  }

  const currentDate = new Date(date);

  const monthNames = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  // Get the month, day, and year components
  const month = monthNames[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  // Format the date string
  const formattedDate = `${month} ${day < 10 ? '0' : ''}${day}, ${year}`;

  return formattedDate
}


