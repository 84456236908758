import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isNil from 'lodash/isNil';

const Container = styled('div')`
  table,
  td,
  th {
    border: 1px solid;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;
const TableRomPainPreviousActivity = ({ currentPainLevel, currentMaxExtension, currentMaxFlexion, prevSelectedActivityId, activeNet, activeTested, dataPrevRomPain, listDataMachineSetup, dataPrevActivity }) => {

  const getPainValue = (valueAnswer) => {
    return valueAnswer - 1;
  }
  const checkPainValue = (valueAnswer) => {
    if (valueAnswer >= 0 || isNaN(valueAnswer)) {
      return valueAnswer;
    } else {
      return '-';
    }
  }

  const checkIsExistNull = (label) => {
    if (label.includes('null')) {
      return true;
    }
    return false;
  };

  const renderPositiveNegativePercent = (number) => {
    if (!isNaN(number)) {
      if (number >= 0) {
        return `+${number}%`;
      }
      return `${number}%`;
    }
    return '-';
  }

  let romPercent = null;
  let painPercent = null;
  let dataIsoRomPainRender = {};
  let pain = null;
  let romLabel = null;
  let romValue;
  let calculateRomPercent = null;
  let calculatePainPercent = null;


  if (dataPrevActivity) {
    const dataSetup = prevSelectedActivityId ? listDataMachineSetup[prevSelectedActivityId] : undefined;

    pain = !isNil(prevSelectedActivityId) ? getPainValue(dataSetup?.answer_1) : getPainValue(dataPrevRomPain?.answer_1);

    romLabel = !isNil(prevSelectedActivityId) ? `${dataSetup?.machine_setup_data?.range_motion_min}° - ${dataSetup?.machine_setup_data?.range_motion_max}°` : `${dataPrevRomPain?.setup?.range_motion_min}° - ${dataPrevRomPain?.setup?.range_motion_max}°`;

    if (checkIsExistNull(romLabel)) {
      romLabel = '-';
    }

    romValue = !isNil(prevSelectedActivityId) ? (dataSetup?.machine_setup_data?.range_motion_max - dataSetup?.machine_setup_data?.range_motion_min) : (dataPrevRomPain?.setup?.range_motion_max - dataPrevRomPain?.setup?.range_motion_min);

    calculateRomPercent = Math.round((Number((currentMaxFlexion - currentMaxExtension) - romValue) / Number(romValue)) * 100)
    romPercent = (isNil(currentMaxExtension) || isNil(currentMaxFlexion) || romLabel === '-') ? '-' : renderPositiveNegativePercent(calculateRomPercent);

    calculatePainPercent = Math.round((Number(currentPainLevel - pain) / Number(pain)) * 100)
    painPercent = (pain <= 0 || isNaN(currentPainLevel) || currentPainLevel < 0) ? '-' : renderPositiveNegativePercent(calculatePainPercent);

    dataIsoRomPainRender = { romLabel: isNaN(romValue) ? '-' : romLabel, pain: checkPainValue(pain), painPercent, romPercent };
  }
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th style={{ width: '85px', background: activeTested ? '#FFE3E3' : '#fff', fontSize: '14px', fontWeight: 400 }}>{dataIsoRomPainRender?.romLabel}</th>
            <th style={{ background: activeTested ? '#FFE3E3' : '#fff', fontSize: '14px', fontWeight: 400, minWidth: '78px' }}>{dataIsoRomPainRender?.pain}</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ color: 'black' }}>
          </tr>
          <tr style={{ color: 'black' }}>
            <td style={{ width: '85px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
            <td style={{ minWidth: '78px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
          </tr>
          <tr style={{ color: 'black', background: activeNet ? '#FFE3E3' : '#fff' }}>
            <td style={{ width: '85px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
            <td style={{ minWidth: '78px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}>-</td>
          </tr>
          <tr>
            <td style={{ width: '85px', fontWeight: (calculateRomPercent >= 0) ? 600 : 400, fontSize: '14px', textAlign: 'center' }}>{dataIsoRomPainRender?.romPercent}</td>
            <td style={{ fontWeight: (calculatePainPercent < 0) ? 600 : 400, fontSize: '14px', textAlign: 'center', minWidth: '78px' }}>{dataIsoRomPainRender?.painPercent}</td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

TableRomPainPreviousActivity.propTypes = {
  currentPainLevel: PropTypes.any,
  currentMaxExtension: PropTypes.any,
  currentMaxFlexion: PropTypes.any,
  prevSelectedActivityId: PropTypes.string
};

export default TableRomPainPreviousActivity;