import { useSearchParams } from 'react-router-dom';
import DynamicSummary from './DynamicSummary';
import KneeDynamicSummary from './KneeDynamicSummary';
import TorsoDynamicSummary from './TorsoDynamicSummary';
import CervicalRotationDynamicSummary from './CervicalRotationDynamicSummary';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearActivities } from '../../../slice';
import { getEMRSettings } from 'modules/EMR/slice.js';
const DynamicTestSummary = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const machineType = searchParams.get('machineType');
  useEffect(() => {
    const hcpId = localStorage.getItem('hcpId');
    dispatch(getEMRSettings({ hcpId }));
    return () => {
      dispatch(clearActivities());
    };
  }, []);
  const activitiesId = searchParams.get('activitiesId');
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(value + 1);
  }, [activitiesId]);
  switch (machineType) {
    case 'Lumbar':
      return <DynamicSummary isLumbar={true} activitiesId={activitiesId} />;
    case 'Cervical':
      return <DynamicSummary isCervical={true} activitiesId={activitiesId} />;
    case 'Knee':
      return <KneeDynamicSummary activitiesId={activitiesId} />;
    case 'Torso Rotation':
      return <TorsoDynamicSummary activitiesId={activitiesId} />;
    case 'Cervical Rotation':
      return <CervicalRotationDynamicSummary activitiesId={activitiesId} />;
    default:
      return null;
  }
};

export default DynamicTestSummary;
