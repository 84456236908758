import { Modal as MuiModal } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { modalBaseStyle } from 'modules/EMR/containers/styled';
import Container from '../containers/styled.js';
import { SingleSelect } from 'modules/DemoGraphic/Shared/SingleSelect';
import InputText from 'modules/DemoGraphic/Shared/InputText';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { SYSTEM_OPTIONS } from 'modules/EMR/constants.js';
import LoadingButton from '@mui/lab/LoadingButton';
const AddEMRModal = ({ open, onClose, isEdit, editableData, onSave, loading }) => {
  const [data, setData] = useState({
    system: 'CASPIO',
    token: '',
    clientId: '',
    clientSecret: '',
    autoPush: false
  });
  useEffect(() => {
    if (!open) {
      return;
    }

    if (!isEdit) {
      setData({
        system: 'CASPIO',
        token: '',
        clientId: '',
        clientSecret: '',
        autoPush: false
      });
      return;
    }

    setData(editableData);
  }, [open, editableData]);

  const onChange = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const isSaveDisabled = () => {
    return Object.keys(data).filter((key) => data[key] === '').length !== 0;
  };

  const onSaveHandler = () => {
    onSave(data);
  };

  return (
    <MuiModal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={{ ...modalBaseStyle }}>
        <Box>
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 0',
            }}
          >
            <Box sx={{ width: '100%' }}>Integrate Settings</Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'end',
                }}
              >
                <IconButton sx={{ padding: 0 }} onClick={() => onClose()}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Box>
            <div id='child-modal-description'>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Container>
                  <Box className={'wrapper'}>
                    <Box className={'row'}>
                      <span className={'label'}>Select system to integrate</span>
                      <SingleSelect
                        value={'CASPIO'}
                        onChange={onChange}
                        width={'100%'}
                        field={'system'}
                        options={SYSTEM_OPTIONS}
                      />
                    </Box>

                    <Box className={'row'}>
                      <span className={'label'}>Token Endpoint URL</span>
                      <InputText
                        field={'token'}
                        value={data.token}
                        maxRows={5}
                        onChange={onChange}
                        width={536}
                        maxLength={2048}
                      />
                    </Box>

                    <Box className={'row'}>
                      <span className={'label'}>Client ID</span>
                      <InputText
                        field={'clientId'}
                        value={data.clientId}
                        onChange={onChange}
                        width={536}
                        patten={/^[a-zA-Z0-9]+$/}
                      />
                    </Box>

                    <Box className={'row'}>
                      <span className={'label'}>Client Secret</span>
                      <InputText
                        field={'clientSecret'}
                        value={data.clientSecret}
                        onChange={onChange}
                        width={536}
                        patten={/^[a-zA-Z0-9]+$/}
                      />
                    </Box>
                    <Box className={'column'}>
                      <label>
                        <Checkbox checked={data.autoPush}  onClick={() => onChange('autoPush', !data.autoPush)}/>
                        <span className={'label'}>Automatically push data into this system.</span>
                      </label>
                      
                    </Box>

                    <Box className={'group-btn'}>
                      <Button onClick={onClose}>Cancel</Button>
                      <LoadingButton
                        loading={loading}
                        disabled={isSaveDisabled()}
                        onClick={() => onSaveHandler()}
                        variant={'contained'}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
};

AddEMRModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  editableData: PropTypes.object,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
};

export default AddEMRModal;
