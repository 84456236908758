import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SessionInfo } from 'modules/Patients/components/Info';
import SideBar from 'modules/Patients/components/Machines/TestSideBar';
import AlertDialog from 'components/AlertDialog/index.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { clearActivities, fetchMachineSetup } from '../../../../slice';

import Steps from './Steps';
import { useSocketDataContext } from 'components/WebSocketProvider';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export default function Index({
  patient,
  machine,
  handleNext,
  handleBack,
  handleSelectItem,
  step,
  subStep,
  setupId,
  handleSkipItem,
  disableFirstStep,
  navigationItems,
  resetNav,
  handleRemoveSkip,
}) {
  const navigate = useNavigate();
  const socketContext = useSocketDataContext();

  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const printRef = useRef();

  const setupMachineData = useSelector((state) => state.patients.setup);
  const arrMachinesConfigured = useSelector((state) => state.machines.hcpConfiguredMachines);
  const machineId = Number(searchParams.get('machineId'));
  const machineType = searchParams.get('machineType');

  const [openAlert, setOpenAlert] = useState(false);
  const [stateData, setStateData] = useState({
    angle: undefined,
    data: [],
    time: 0,
    reps: undefined,
    weight: '',
    pounds: 0,
  });

  const patientId = id;
  useEffect(() => {
    const data = {
      hcpId: localStorage.getItem('hcpId'),
      patientId: atob(patientId),
      machineId: Number(machineId),
    };
    if (!setupMachineData && setupId) {
      dispatch(fetchMachineSetup({ ...data, setupId: setupId }));
    }
  }, []);

  const handleCancel = () => {
    setOpenAlert(true);
  };

  useEffect(() => {
    if (!isNil(machineId) && !isNil(machineType) && !isEmpty(arrMachinesConfigured)) {
      socketContext.handleFilterMachine({ machineId, machineType, machines: arrMachinesConfigured })
    }
  }, [machineId, machineType, arrMachinesConfigured]);

  useEffect(() => {
    return () => {
      socketContext.handleResetMachine();
    }
  }, []);


  const handleSubmitSecondaryAction = () => {
    resetNav();
    localStorage.removeItem('activity_id');
    dispatch(clearActivities());
    navigate(`/patient/${btoa(patient.patient_id)}&tab=1`);
  };

  const handleSubmitAlertDialog = () => {
    setOpenAlert(false);
  };

  const onDataChange = (data) => {
    if (!data) {
      return null;
    }

    setStateData((prevState) => ({ ...prevState, ...data }));
  };

  return (
    <Box ref={printRef}>
      <SessionInfo
        machine={machine}
        patient={patient}
        title={step === '7' ? 'Dynamic Exercise Index' : 'Dynamic Exercise'}
      />
      <Box
        sx={{
          display: 'flex',
          pt: 5,
        }}
      >
        <SideBar
          navigationItems={navigationItems}
          step={step}
          subStep={subStep}
          handleSkipItem={handleSkipItem}
          handleSelectItem={handleSelectItem}
        />

        <Box sx={{ width: '60%', ml: 4 }}>
          <Box>
            <Steps
              step={step}
              subStep={subStep}
              handleBack={handleBack}
              handleCancel={handleCancel}
              handleNext={handleNext}
              machine={machine}
              setupMachineData={setupMachineData}
              onDataChange={onDataChange}
              disableFirstStep={disableFirstStep}
              handleRemoveSkip={handleRemoveSkip}
              resetNav={resetNav}
              stateData={stateData}
            />
          </Box>
        </Box>
        <AlertDialog
          secondaryActionText='End'
          primaryActionText='Return'
          handleSubmitAlertDialog={handleSubmitAlertDialog}
          handleSubmitSecondaryAction={handleSubmitSecondaryAction}
          openAlertDialog={openAlert}
          message={
            <Box sx={{ maxWidth: '315px' }}>
              Are you sure you want to cancel this Dynamic Exercise ? All data will be deleted.
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

Index.propTypes = {
  patient: PropTypes.object,
  machine: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSelectItem: PropTypes.func,
  navigationItems: PropTypes.array,
  handleSkipItem: PropTypes.func,
  resetNav: PropTypes.func,
  disableFirstStep: PropTypes.bool,
  step: PropTypes.string,
  subStep: PropTypes.string,
  setupId: PropTypes.string || PropTypes.number,
  handleRemoveSkip: PropTypes.func,
};
