import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SingleImageStep } from 'helpers';
import GaugeSlider from 'components/GaugeSlider';
import socketActions from 'components/WebSocketProvider/constants.js';
import { useEffect, useState } from 'react';
import noImage from 'assets/images/png/noImage.png';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import { textFieldRender } from 'helpers';
import FlipSwitch from 'components/FlipSwitch';

export function MotionLimiter({
  step,
  subStep,
  machine,
  handleCancel,
  handleBack,
  handleNext,
  socketContext,
  setLimiter,
  limiter,
}) {
  const [value, setValue] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isLock, setLock] = useState(false);

  useEffect(() => {
    return () => {
      handleStopData();
    };
  }, [step, subStep]);

  useEffect(() => {
    if (limiter !== null) {
      setValue(limiter);
      setLock(true);
      return;
    }

    setValue(0);
    setLock(false);
    getData();
  }, [step, subStep]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};

    if (isLock) {
      return;
    }
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (message.degree !== undefined) {
      setValue(message.degree);
    }

    if (message.done) {
      setLock(true);
    }
  }, [socketContext.messageHistory]);

  const handleStopData = () => {
    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
  };
  const getData = () => {
    setLock(false);
    setIsReady(true);
    socketContext.sendJsonMessage({
      request: socketActions.GET_DEGREE_BY_SWITCH,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
  };

  const nextHandler = () => {
    setLimiter(value);
    handleNext();
  };

  const backHandler = () => {
    handleBack();
  };

  const direction = subStep === '2' ? 'Left' : 'Right';

  return (
    <Box key={`select-angle-${step}-${subStep}`} sx={{ overflow: 'hidden' }}>
      <div>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'scroll' },
            paddingAbsolute: true,
          }}
          style={{ maxHeight: 'calc(100vh - 325px)' }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box>
              <SingleImageStep img={noImage} />
            </Box>

            <Typography sx={{ flex: 1 }} variant='body1' component='div'>
              1. Disengage the angle selector and turn movement arm to the{' '}
              <span style={{ fontWeight: 'bold' }}>Max {direction} ROM</span>, engage the angle selector on target
              angle.
              <br />
              2. Turn {direction.toLowerCase()} Range of Motion limiters counterclockwise until resistance is met; then
              back off 1/2 turn.
              <br />
              3. <FlipSwitch /> - to confirm.
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 30 }}>
            <div style={{ maxHeight: 350 }}>
              <GaugeSlider value={value} minValue={-96} maxValue={96} isLock={isLock} />
            </div>

            <Box>
              <Typography sx={{ fontSize: '1rem', pt: 2, fontWeight: 500, whiteSpace: 'nowrap' }} variant='h2'>
                {/*{t('machine:debugDialog.title')}*/}
                <span style={{ margin: 0 }}> {direction} Angle:</span>
              </Typography>

              <Box sx={{ mt: 1 }}>{textFieldRender(value, isLock)}</Box>
            </Box>
          </div>
        </OverlayScrollbarsComponent>

        <Actions
          step={step}
          subStep={subStep}
          handleCancel={handleCancel}
          handleBack={backHandler}
          handleNext={nextHandler}
          disableNext={!isLock}
        />
      </div>
    </Box>
  );
}
MotionLimiter.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  machine: PropTypes.object,
  page: PropTypes.string,
  values: PropTypes.object,
  onDataChange: PropTypes.func,
  setLimiter: PropTypes.func,
  limiter: PropTypes.any,
  setupMachineData: PropTypes.object,
  socketContext: PropTypes.object,
};
