function handleKeyDown(event) {
  if (event.keyCode === 13) {
    const element = document.getElementById('next-activity-btn');
    if (element) {
      element.click();
    }
  }
}

window.addEventListener('keydown', handleKeyDown);
