import styled from '@emotion/styled';

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 5px;

  .btn-ok {
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    padding: 9px 24px;
    color: #212529;
    border: none;
    font-size: 14px;
    display: flex;
    gap: 5px;
    background: #ed9a00;
  }

  .btn-ok:hover {
    background: rgb(165, 107, 0);
  }

  .btn {
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    align-items: center;
    padding: 9px 24px;
    background: #f1f3f5;
    color: #212529;
    border: none;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    gap: 8px;
    svg {
      color: #868e96;
      fill: #868e96;
    }
  }
  .btn:hover {
    background: #fef3e0;
    color: #ed9a00;
    svg {
      color: #ed9a00;
      fill: #ed9a00;
    }
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    max-width: 450px;
    font-size: 18px;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;

    /* 10px 20px */
  }

  .inputfile:focus + label,
  .inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
  }

  .inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
  }
`;

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '4px',
  outline: 'none',
};
