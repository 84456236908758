import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Actions from 'modules/Patients/components/Machines/Actions';
import noImg from 'assets/images/png/noImage.png';
import { SingleImageStep } from 'helpers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useRef, useState } from 'react';
import { requestSaveSetup } from '../../../../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

export default function SeatingAndFootboard({ step, subStep, handleCancel, handleBack, handleNext, setupMachineData }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const patientId = id;
  const setupIdParams = searchParams.get('setupId');
  const machineId = searchParams.get('machineId');

  const isUpdating = useSelector((state) => state.patients.isUpdatingSetup);
  const isUpdate = useRef(null);

  const [seatPad, setSeatPad] = useState(-1);
  const [footboardPosition, setFootboardPosition] = useState(-1);

  useEffect(() => {
    if (!setupMachineData) {
      return null;
    }

    setSeatPad(setupMachineData.seat_pad ?? -1);
    setFootboardPosition(setupMachineData.footboard_pos ?? -1);
  }, [setupMachineData]);

  useEffect(() => {
    if (!isUpdate.current) {
      return null;
    }

    if (isUpdating) {
      return null;
    }

    handleNext();
  }, [isUpdating]);

  const onNextHandler = () => {
    if (!setupMachineData) {
      return null;
    }

    if (seatPad === setupMachineData.seat_pad && footboardPosition === setupMachineData.footboard_pos) {
      handleNext();
      return;
    }

    const setupId = localStorage.getItem('setupId') || setupIdParams;
    isUpdate.current = true;
    dispatch(
      requestSaveSetup({
        hcpId: localStorage.getItem('hcpId'),
        patientId: atob(patientId),
        machineId: machineId,
        finished: false,
        setupId,
        data: {
          seat_pad: seatPad,
          footboard_pos: footboardPosition,
        },
      })
    );
  };

  return (
    <Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          paddingAbsolute: true,
        }}
        style={{ maxHeight: 'calc(100vh - 325px)' }}
      >
        <Box> 1. Here is a step by step through setting up your patient in the machine.</Box>
        <br />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              2. Instruct patient to sit upright in the seat and scoot back as far as possible. Close gate (but don’t
              lock it).
              <br />
              3. See if hip joint height is aligned with bar that is attached to the hip restraint pad. If patient is
              seated to low then add the seat pad.
              <br />
              <Box>
                <div>
                  <b>Seat Pad</b>
                </div>

                <Select
                  style={{ minWidth: '100px' }}
                  value={seatPad}
                  onChange={(v) => setSeatPad(Number(v.target.value))}
                >
                  <MenuItem value={-1}>
                    <em>--</em>
                  </MenuItem>

                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>
              4. Set footboard position, so patient can rest the heels and balls of their feet against the foot board
              with slighty bent knees.
              <br />
              5. Input the Footboard postition. Position 1 is the closest slot to patient.
              <Box>
                <div>
                  <b>Footboard Position</b>
                </div>

                <Select
                  style={{ minWidth: '100px' }}
                  value={footboardPosition}
                  onChange={(v) => setFootboardPosition(Number(v.target.value))}
                >
                  <MenuItem value={-1}>
                    <em>--</em>
                  </MenuItem>

                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box>
              <SingleImageStep img={noImg} />
            </Box>
            <Box>6. Lower the femur restraint on to patient’s quads and lock with the femur restraint lock.</Box>
          </Box>
        </Box>
      </OverlayScrollbarsComponent>
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleBack={handleBack}
        handleNext={onNextHandler}
        nextLoading={isUpdating}
        disableNext={seatPad === -1 || footboardPosition === -1}
      />
    </Box>
  );
}

SeatingAndFootboard.propTypes = {
  step: PropTypes.string,
  subStep: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setupMachineData: PropTypes.object,
};
