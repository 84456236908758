import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import counterbalance from 'assets/images/SVG/Cervical/Counterbalance-adjuster.svg';
import Box from '@mui/material/Box';
import { getInstructions } from 'helpers';
import Actions from 'modules/Patients/components/Machines/Actions';
const instructions = [
  {
    id: 1,
    text: '',
    img: counterbalance,
  },
];
const SetCounterBalance = ({ step, setupMachineData, subStep, handleCancel, handleBack, handleNext }) => {
  if (!setupMachineData) {
    return null;
  }
  return (
    <>
      <Box sx={{ display: 'flex', gap: 5 }}>
        <Box>{getInstructions(instructions)}</Box>

        <Typography variant='body1' component='div'>
          1. Set Counterbalance by adjusting the Counterweight Adjuster.
          <br />
          <span style={{ paddingLeft: '20px' }}>
            • Raise the counterweight by turning the counterweight adjuster clockwise.
          </span>
          <br />
          <span style={{ paddingLeft: '20px' }}>
            • Lower the counterweight by turning the counterweight adjuster counterclockwise.
          </span>
          <br />
          <br />
          <div>
            <div style={{ fontWeight: 500 }}>Patient Setup Gauge Reading:</div>
            <div
              style={{
                padding: 2,
                border: '1px solid green',
                fontWeight: 500,
                borderRadius: '8px',
                width: '100px',
                textAlign: 'center',
                color: 'green',
              }}
            >{`${setupMachineData.counterbalance_gauge}`}</div>
          </div>
        </Typography>
      </Box>

      {/* actions */}
      <Actions
        step={step}
        subStep={subStep}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </>
  );
};

SetCounterBalance.propTypes = {
  step: PropTypes.string,
  handleCancel: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  subStep: PropTypes.string,
  setupMachineData: PropTypes.object,
};

export default SetCounterBalance;
